import {makeAutoObservable} from "mobx";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {
    AiCategorizationJobStatusEnum,
    AiSupplierParentIdentificationJobStatusSerializer
} from "../../services/ApiTypes";
import {SupplierNormalizationStore} from "./SupplierNormalizationStore";
import {SupplierNormalizationState} from "../../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {isAxiosError} from "axios";

// Responsible for communicating with backend regarding SupplierParentIdentificationJob
export class SupplierParentIdentificationJobDelegate {
    // Initial value is set to block submitting new ai job, until explicitly queried
    jobRunning?: boolean;
    databagId?: number;
    timeout?: NodeJS.Timeout;
    latestStatus?: AiSupplierParentIdentificationJobStatusSerializer;

    constructor(
        private api: MithraMaterializedApi,
        private supplierNormalizationStore: SupplierNormalizationStore,
    ) {
        makeAutoObservable(this, {timeout: false});
    }

    init(databagId: number) {
        if (this.databagId === undefined || this.databagId !== databagId) {
            this.databagId = databagId;

            // Initial value of jobRunning is unknown
            this.jobRunning = undefined;

            this.scheduleStatusCheck(1);
        }
    }

    get hasResults() {
        if(this.databagId === undefined) return null;
        if(this.latestStatus === undefined) return null;
        return this.latestStatus.databags_with_results.includes(this.databagId);
    }

    onNoJobExists() {
        this.jobRunning = false;
        this.latestStatus = undefined;
    }

    onJobCreated() {
        this.jobRunning = true
        if (this.supplierNormalizationStore.state === SupplierNormalizationState.START) {
            this.supplierNormalizationStore.state = SupplierNormalizationState.RUNNING
        }
        this.scheduleStatusCheck();
    }

    onJobStatusReceived(latest: AiSupplierParentIdentificationJobStatusSerializer) {
        this.latestStatus = latest;
        switch (latest.status.status) {
            case AiCategorizationJobStatusEnum.LIVE:
            case AiCategorizationJobStatusEnum.ERROR:
                // End states
                this.jobRunning = false;
                if (this.supplierNormalizationStore.state === SupplierNormalizationState.RUNNING) {
                    this.supplierNormalizationStore.state = SupplierNormalizationState.START
                    this.supplierNormalizationStore.refreshTableData()
                }
                break;
            default:
                // States that should change later on
                this.jobRunning = true;
                this.scheduleStatusCheck();
                break;
        }
    }

    createJob() {
        if (this.databagId === undefined) return;
        if (this.jobRunning)
            throw Error("Can't create a new job. Previous job is still running!", {
                cause: 'API Error'
            });
        this.api.createSupplierParentIdentificationJob(this.databagId)
            .then(() => this.onJobCreated())
    }

    scheduleStatusCheck(miliSeconds = 30 * 1000) {
        if (this.timeout !== undefined) {
            clearTimeout(this.timeout);
        }

        const databagId = this.databagId;
        if (databagId === undefined) return;
        this.timeout = setTimeout(() => this.api.latestSupplierParentIdentificationJob(databagId)
                .then(r => this.onJobStatusReceived(r.data))
                .catch((error) => {
                    if (isAxiosError(error) && error.response && error.response.status === 404) {
                        // No Job found
                        this.onNoJobExists()
                    }
                    this.scheduleStatusCheck();
                }),
            miliSeconds,
        );
    }
}