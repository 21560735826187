import React from "react";
import {observer} from "mobx-react-lite";
import './MultiDashBoards.scss'
import {useStores} from "../../stores";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {DashBoardCard} from "../dashboard/DashBoardCard";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {DashboardHeader} from "../dashboard/DashboardHeader";
import {SupplierNormalizationV2Icon} from "../../components/icons/SupplierNormalizationV2Icon";

export const SingleCleaningDashboard: React.FC = observer(() => {
    const {bagStore, categorizationReviewStore, supplierNormalizationStore, taxonomyManagerStore} = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.statistics?.standalone_spend;
    return <>
        <DashboardHeader/>
        <Grid container
              className="dashboard single-cleaning-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={drawOpportunityValue(pendingSupplierNormalization)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.single_cleansing_sup_nor, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.single_cleansing_cat_review, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.single_cleansing_taxonomy_builder, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
