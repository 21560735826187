import './ContractOpportunityPage.scss';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {routes} from "../../../routing/routes";
import React, {useEffect, useState} from "react";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {Add, Delete, Edit, Visibility} from "@mui/icons-material";
import {ColSpec, MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {generatePath} from "react-router";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

type Props = {}
export const ContractOpportunities: React.FC<Props> = observer(() => {
    const {bagStore} = useStores();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // Simulate 2 seconds loading time

        return () => clearTimeout(timeout);
    }, []);

    const data = [
        {
            id: 1,
            opportunityName: "Out of scope purchases",
            opportunityDescription: "Review out of scope purchases and decision to include or stop those",
            associatedSpend: 9000000,
            opportunityStatus: "Open",
            opportunityOwner: "Sarah Jones"
        },
        {
            id: 2,
            opportunityName: 'Existing products / services negotiation',
            opportunityDescription: 'Actual consumption vs. contractual agreement',
            associatedSpend: 1300000,
            opportunityStatus: 'Open',
            opportunityOwner: "Sarah Jones"
        },
        {
            id: 3,
            opportunityName: 'Supplier agreement revision',
            opportunityDescription: 'Extend and negotiate contract parties across business unites and potential child',
            associatedSpend: 3800000,
            opportunityStatus: 'In Progress',
            opportunityOwner: "Sarah Jones"
        },
        {
            id: 4,
            opportunityName: 'Payment terms opportunity',
            opportunityDescription: 'Current payment terms vs. Industry benchmark / company policy',
            associatedSpend: 1200000,
            opportunityStatus: 'Closed',
            opportunityOwner: "Sarah Jones"
        },
    ];
    const COLUMNS: ColSpec[] = [
        {cls: 'col-number', txt: '', align: 'center'},
        {cls: 'col-opportunity-name', txt: "Opportunity's name"},
        {cls: 'col-opportunity-description', txt: 'Description'},
        {cls: 'col-associated-spend', txt: 'Associated Spend'},
        {cls: 'col-opportunity-status', txt: 'Status', align: 'center'},
        {cls: 'col-opportunity-owner', txt: 'Owner'},
        {cls: 'col-button', txt: '', align: 'center'}
    ];

    const LoadingSkeleton = () => (
        <TableRow>
            <TableCell colSpan={COLUMNS.length}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Skeleton height={40}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton height={40}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton height={40}/>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );


    const makeChip = (row) => {
        if (row.opportunityStatus === 'Open') {
            return <Chip label="Open" color="success" className={'chip-success'}/>
        } else if (row.opportunityStatus === 'In Progress') {
            return <Chip label="In Progress" color="primary" className={'chip-primary'}/>
        } else {
            return <Chip label="Closed" color="error" className={'chip-error'}/>
        }
    }


    return <KoiFacade
        title={'Contract compliance & Opportunities'}
        intro={<></>}
        back={generatePath(routes.demo_cleanup_analytics_contract_analytics, {
            id: bagStore.bagId,
            contract: -1
        })}
        className="contract-opportunity-page"
    >
        <Grid container alignItems="center" justifyContent="space-between"
              style={{marginTop: '4em', marginBottom: '2em'}}>
            <Grid item>
                <Grid container direction="column">
                    <Typography variant="h6" style={{color: '#091344'}}>{isLoading ?
                        <Skeleton/> : 'Opportunities List'}</Typography>
                    <Typography variant="caption" style={{color: '#091344'}}>{isLoading ?
                        <Skeleton/> : '4 Items'}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Button endIcon={<Add/>} variant="outlined">
                    ADD NEW OPPORTUNITY
                </Button>
            </Grid>
        </Grid>

        <TableContainer>
            <Table cellPadding="0">
                <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
                <TableBody className="contract-opportunity-table-body">
                    {isLoading ? (
                        <LoadingSkeleton/>
                    ) : (
                        <>
                            {data ? (
                                <>
                                    {data.map((row, index) => {
                                        let i = 0;
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {row.id}
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {row.opportunityName || 'opportunityName'}
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {row.opportunityDescription || 'opportunityDescription'}
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        <CurrencyComponent v={row.associatedSpend} nDigits={3}/>
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {makeChip(row)}
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {row.opportunityOwner || 'effective_date'}
                                                    </MithraTableCell>
                                                    <MithraTableCell c={COLUMNS[i++]}>
                                                        {row.opportunityStatus === 'Closed' && <IconButton>
                                                            <Visibility style={{color: '#091344'}}/>
                                                        </IconButton>}
                                                        {row.opportunityStatus !== 'Closed' && <IconButton>
                                                            <Edit style={{color: '#091344'}}/>
                                                        </IconButton>}
                                                        {row.opportunityStatus !== 'Closed' && <IconButton>
                                                            <Delete style={{color: '#FF3435'}}/>
                                                        </IconButton>}
                                                    </MithraTableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        );
                                    })}
                                    {data.length === 0 && (
                                        <TableRow className="no-data">
                                            <TableCell colSpan={COLUMNS.length}>No data</TableCell>
                                        </TableRow>
                                    )}
                                </>
                            ) : (
                                <TableRow className="no-data-yet"/>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </KoiFacade>
});
