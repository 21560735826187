/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
export const C: AppConfiguration = {
    package: 'cleanup',
    dashboardVariant: 'simple',
    menu: 'single',
    profile: {
        p_name_col_name: 'Invoice No.',
        p_name_col_width: '7em',
        p_description_col_name: 'Description',
        p_description_col_width: '15em',

        s_col_name: 'Supplier Name',
        s_name_col_width: '100px',

        p_context_1_col_name: '',
        p_context_2_col_name: '',
        p_context_3_col_name: '',
        p_context_pref: [],

        p_ddate_pref: [1],
        p_ddate_1_col_name: 'Invoice Date',
        p_ddate_1_col_type: 'date',

        defaultPartContextColNames: [
        ],

        showUncategorizedSubBars: true,
        showReasonColumn: true,

        categorizationReviewSubRowRelationData: [{
            relation: 'part',
            columns: [
                {db_column: 'c_description', colSpec: {cls: '', txt: 'Company Name', width: '2em'}},
                {db_column: 'c_code', colSpec: {cls: '', txt: 'Company Id', width: '2em'}},
                {db_column: 'c_address', colSpec: {cls: '', txt: 'Receiver Address', width: '2em'}},
                {db_column: 'p_inv_line_description', colSpec: {cls: '', txt: 'Line Item Desc.', width: '25em'}},
                {db_column: 'p_inv_line_number', colSpec: {cls: '', txt: 'Invoice Line No.', width: '1em'}},
                {db_column: 'p_customer_id', colSpec: {cls: '', txt: 'Id', width: '2em'}},
                {db_column: 'p_local_currency', colSpec: {cls: '', txt: 'Currency', width: '2em'}},
            ]
        }],
        searchConfiguration: 'default_p_desc',
        categorizationShowScore: false,

        locale: 'en-en',
        currency: 'EUR',

        useAiTaxonomyHealthCheck: true,

        enableDataIngestion: true,
        enableTaxonomyIngestion: true,
    },
}
