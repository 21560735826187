import React from "react";
import {MIXED_CATEGORIES_LABEL, UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";
import {Chip, Tooltip} from "@mui/material";


type Props = {
    cats: string[] | false | 'UNCATEGORIZED' | 'MIXED_CATEGORIES'
    isEditAllowed?: boolean
    trimTo?: number
}
export const PartCategorizationComponent: React.FC<Props> = ({
                                                                 cats,
                                                                 isEditAllowed,
                                                                 trimTo,
                                                             }) => {
    if (cats === false || (Array.isArray(cats) && cats.every(c => c === UNCATEGORIZED_VALUE) || cats === 'UNCATEGORIZED')) {
        return <>{UNCATEGORIZED_LABEL}</>;
    }
    if (cats === 'MIXED_CATEGORIES') {
        return <>{MIXED_CATEGORIES_LABEL}</>;
    }
    // Check if trimTo is greater than or equal to 1 and shorter than the length of cats
    if (trimTo !== undefined && (trimTo < 1 || trimTo >= cats.length)) {
        throw new Error("Invalid trimTo value. It should be greater than or equal to 1 and shorter than cats length.");
    }


    const trim = trimTo != null ? trimTo : cats.length; // Default to full length if trimTo is not specified

    const trimmedCats = cats.slice(-trim);
    const displayCats = trim === cats.length ? cats : ['...', ...trimmedCats];

    return (
        <>
            {displayCats.map((c, i) => {
                const categoryLabel = c === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : c;
                const tooltipText = trim === cats.length ? categoryLabel : cats.join(' > ');

                return (
                    <Tooltip key={i} title={tooltipText}>
            <span>
              <Chip label={categoryLabel} variant='outlined' size='small'/>
                {i < displayCats.length - 1 && <span> {" > "} </span>}
            </span>
                    </Tooltip>
                );
            })}
        </>
    );
};