import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {TaxonomyMapperIcon} from "../../../../components/icons/TaxonomyMapperIcon";
import {environment} from "../../../../env";
import {SynergySuppliersIcon} from "../../../../components/icons/SynergySuppliersIcon";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {TaxonomyMapperStore} from "../../../taxonomy-mapper/store/TaxonomyMapperStore";
import {QueryStats} from "@mui/icons-material";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 * The Combined version using in the Simple dashboard variant
 * This contains more cards in the same dashboard
 */
export const MergeXMergeCombinedDashboard: React.FC = observer(() => {
    const stores = useStores();
    const {
        p,
        bagStore,
        cmpStore,
        categorizationReviewStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;
    const supplierNormalizationKpi = masterSupplierNormalizationStore.statistics?.parent_suppliers;

    // const unm1N = ((stores as any).sub1_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    // const unm1O = ((stores as any).sub1_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm2N = ((stores as any).sub2_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    // const unm2O = ((stores as any).sub2_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm3N = ((stores as any).sub3_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    // const unm3O = ((stores as any).sub3_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;

    return <>
        {environment.isTest && <h1>MergeXMergeCombinedDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard merge-dashboard merge-combined-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                // title="Suppliers"
                className="suppliers-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_suppliers)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                <DashBoardCard
                    cardKey="synergy-suppliers"
                    avatar={<SynergySuppliersIcon/>}
                    title="Common Suppliers"
                    //  The supplier normalization store is initialized, based on the SupplierNormalizationPage.tsx
                    //  initialization script. That is too late to show it on this dashboard, causes: CAT-883 CAT-868
                    // value={supplierNormalizationKpi !== undefined ? supplierNormalizationKpi : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    subtitle="suppliers"
                    link={generatePath(routes.merge_x_merge_common_suppliers, {id: bagId})}
                    badge="Ai"
                />

                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Synergy Dashboard"
                    // subtitle=""
                    link={generatePath(routes.merge_x_merge_advanced_dashboard, {id: bagId})}
                    // badge="preview"
                    hidden={p.p.mergeXmergeOpportunityDashboardBagId !== undefined}
                />

            </DashboardRow>
            <DashboardRow
                title="Synergy"
                className="categories-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_categories)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Mapper"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle="unmapped categories"*/}
                {/*    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Mapper (old holdings)"*/}
                {/*    value={unm1O === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm1O}*/}
                {/*    subtitle="unmapped categories"*/}
                {/*    link={generatePath(routes.merge_x_merge_taxonomy_mapper_baseline_1, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Mapper (new proposal)"*/}
                {/*    value={unm1N === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm1N}*/}
                {/*    subtitle="unmapped categories"*/}
                {/*    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target_1, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Mapper (old holdings)"*/}
                {/*    value={unm2O === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm2O}*/}
                {/*    subtitle="unmapped categories"*/}
                {/*    link={generatePath(routes.merge_x_merge_taxonomy_mapper_baseline_2, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (Energy)"
                    value={unm2N === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm2N}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target_2, {id: bagId})}
                    badge="Ai"
                    disabled={true}
                />
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Mapper (old holdings)"*/}
                {/*    value={unm3O === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm3O}*/}
                {/*    subtitle="unmapped categories"*/}
                {/*    link={generatePath(routes.merge_x_merge_taxonomy_mapper_baseline_3, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    subtitle="unmapped categories"
                    value={unm3N === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm3N}
                    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target_3, {id: bagId})}
                    badge="Ai"
                    disabled={true}
                />

            </DashboardRow>
            <DashboardRow
                // title="Spend"
                className="spend-row"
                // subtitle={bagStore.kpi.common
                //     ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                {/*<DashBoardCard*/}
                {/*    cardKey="merge-improve-cats"*/}
                {/*    avatar={<ClassificationIcon/>}*/}
                {/*    title="Improve Categorization"*/}
                {/*    value={drawOpportunityValue(unclassifiedEgoSpend)}*/}
                {/*    valueColor={unclassifiedEgoSpend*/}
                {/*        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="uncategorized"*/}
                {/*    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    // link={generatePath(routes.merge_x_taxonomy_builder, {id: bagId})} //SHV
                    link={generatePath(routes.merge_x_master_taxonomy_builder_target, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
