import '../../taxonomy-editor/approval/table/TaxApprovalTable.scss'
import {ColSpec, MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Chip, IconButton, Table, TableBody, TableContainer, TableRow, TextField, Typography} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {
    Add,
    ArrowRightAlt,
    DeleteOutlined,
    Edit,
    ExpandMore,
    KeyboardArrowRight,
    RestoreFromTrash
} from "@mui/icons-material";
import {ApprovalChangeChip} from "../../taxonomy-editor/approval/component/ApprovalChangeChip";
import {ApprovalHierarchyNode} from "../../../stores/approval/TaxonomyApprovalDelegate";
import {toJS} from "mobx";
import {TaxonomyApprovalReviewAddL1Row} from "./TaxonomyApprovalReviewAddL1Row";
import {AuthorChip} from "../../taxonomy-editor/approval/component/AuthorChip";
import {TAX_APP_PRE_COLUMNS} from "../../taxonomy-editor/approval/table/TaxApprovalPreviewTable";

export const TAX_APP_REV_COLUMNS: ColSpec[] = [
    {txt: '', cls: 'open-close'},
    {txt: 'Category', cls: 'navigation'},
    {txt: '', align: 'center', cls: 'edit-actions', columnFieldId: 'edit-actions'},
    {txt: 'Authors', cls: ''},
    {txt: 'Changes', cls: ''},
    {txt: 'Received notes', cls: 'notes-column'},
    {txt: 'Approver notes', cls: 'notes-column'},
]
export const EDIT_ACTIONS_INDEX = TAX_APP_REV_COLUMNS.findIndex(c => c.columnFieldId === 'edit-actions');
const READONLY_TAX_APP_REV_COLUMNS = TAX_APP_REV_COLUMNS.filter(c => c.columnFieldId !== 'edit-actions');

type TableRowProps = {
    node: ApprovalHierarchyNode
    level: number
    open: boolean
    setOpen: (open: boolean) => void
}
export const TaxApprovalReviewTableRow: React.FC<TableRowProps> = observer(({node, level, open, setOpen}) => {
    const {approvalStore} = useStores();
    const readOnly = approvalStore.taxonomyApproval.readOnly;
    const data = node.data;
    const approval = approvalStore.taxonomyApproval;
    const isOpenAble = node.data.values.isOpenAble;
    const nChanges = data.values.descendantChangeMap;
    const changes = data.values.changes || [];
    let i = 0;
    const clickProps = isOpenAble ? {onClick: () => setOpen(!open), className: "clickable"} : {};

    const textDecoration = data.removed ? 'line-through' : '';

    // consumeTickTree
    toJS(approvalStore.taxonomyApproval.tickTree);

    const C = readOnly ? READONLY_TAX_APP_REV_COLUMNS : TAX_APP_REV_COLUMNS;
    return <TableRow className="">
        <MithraTableCell c={C[i++]} {...clickProps}>
            {isOpenAble &&
                <IconButton aria-label="expand row" size="small" tabIndex={-1}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            }
        </MithraTableCell>
        <MithraTableCell c={C[i++]}  {...clickProps}>
            <span style={{marginLeft: `${(level - 1) * 1.5}em`}}>
                {level > 1 && '└─'}
            </span>
            <span style={{textDecoration}}>
                {data.label}
            </span>
        </MithraTableCell>
        {!readOnly &&
            <MithraTableCell c={C[i++]}>
                <IconButton
                    tabIndex={-1}
                    title="Edit category"
                    disabled={node.data.removed}
                    onClick={() => approval.openEditModal(node)}>
                    <Edit/>
                </IconButton>
                <IconButton
                    tabIndex={-1}
                    title="Move category"
                    disabled={node.data.removed}
                    onClick={() => approval.openMoveModal(node)}>
                    <ArrowRightAlt/>
                </IconButton>
                {node.data.removed
                    ? <IconButton
                        tabIndex={-1}
                        title="Restore category"
                        onClick={() => approval.onSaveRestore(node)}>
                        <RestoreFromTrash/>
                    </IconButton>
                    : <IconButton
                        tabIndex={-1}
                        title="Delete category"
                        onClick={() => approval.openDeleteModal(node)}>
                        <DeleteOutlined/>
                    </IconButton>
                }
                <IconButton
                    tabIndex={-1}
                    title={`Add L${level + 1}`}
                    disabled={node.data.removed}
                    onClick={() => approval.openCreateModal(node)}>
                    <Add/>
                </IconButton>
            </MithraTableCell>
        }
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}>
            {node.data.values.authors?.map(authorId =>
                <AuthorChip key={authorId} authorId={authorId}/>
            )}
        </MithraTableCell>
        <MithraTableCell c={C[i++]}>
            {changes.filter(c => !c.reverted).map((c, i) =>
                <ApprovalChangeChip
                    key={`${c.type}_${i}`}
                    change={c}
                    node={data}
                    onRevert={approval.canRevert(node, c) ? () => approval.doRevert(node, c) : undefined}
                    readOnly={readOnly}
                />
            )}
            {!open && nChanges.size > 0 &&
                <Chip
                    icon={<Add/>}
                    label={Array.from(nChanges.entries()).map(e => `${e[1]} ${e[0]}`).join(', ')}
                    color="default"
                    variant="outlined"
                    size="small"
                />
            }
        </MithraTableCell>
        <MithraTableCell c={C[i++]}>
            {changes &&
                <Typography variant="body2">
                    {data.values.approval_note || ''}
                </Typography>
            }
        </MithraTableCell>
        <MithraTableCell c={C[i++]}>
            {readOnly &&
                <Typography variant="body2">
                    {data.values.approval_feedback_note || ''}
                </Typography>
            }
            {!readOnly && changes && !data.removed &&
                <TextField
                    id={`taxonomy-approval-notes-node-${data.id}`}
                    className="category-notes-field"
                    fullWidth
                    multiline
                    placeholder="Add optional notes for the reviewer"
                    variant="standard"
                    value={approvalStore.taxonomyApproval.categoryFeedbackNotes.get(data.id) ?? (data.values.approval_feedback_note || '')}
                    onChange={e => approvalStore.taxonomyApproval.setFeedbackNote(data.id, e.target.value)}
                    // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
                />
            }
        </MithraTableCell>
    </TableRow>;
})

export const TaxApprovalReviewTableRowComponent: React.FC<{
    node: ApprovalHierarchyNode
    level: number
}> = observer(({node, level}) => {
    const {approvalStore} = useStores();
    const [open, setOpen] = React.useState(!node.data.removed && Boolean(node.children && node.children.length > 0));

    // consumeTickTree
    toJS(approvalStore.taxonomyApproval.tickTree);

    if (!node.data.values.show) {
        return <></>
    }

    return <>
        <TaxApprovalReviewTableRow node={node} level={level} open={open} setOpen={setOpen}/>
        {open && node.children?.map(n =>
            // Recursive call
            <TaxApprovalReviewTableRowComponent key={n.data.id} node={n} level={level + 1}/>
        )}
    </>
})

export const TaxApprovalReviewTable: React.FC = observer(() => {
    const {approvalStore} = useStores();
    const node = approvalStore.taxonomyApproval.root;
    const readOnly = approvalStore.taxonomyApproval.readOnly;
    if (!node) return <></>

    // consumeTickTree
    toJS(approvalStore.taxonomyApproval.tickTree);

    const C = readOnly ? READONLY_TAX_APP_REV_COLUMNS : TAX_APP_REV_COLUMNS;
    return <TableContainer className="taxonomy-approval-table taxonomy-approval-review">
        <Table stickyHeader>
            <MithraTableHeadColumns
                columns={C}
                // enableLoading isLoading={approvalStore.taxonomyApproval.isLoading}
            />
            <TableBody>
                {node.children?.map(n =>
                    <TaxApprovalReviewTableRowComponent key={n.data.id} node={n} level={1}/>
                )}
                {!readOnly && <TaxonomyApprovalReviewAddL1Row/>}
            </TableBody>
        </Table>
    </TableContainer>
})
