import React from "react";
import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Add, Clear, Search} from "@mui/icons-material";
import {generatePath, useNavigate} from "react-router-dom";
import {sitRoutes} from "../../routing/sitRoutes";
import {useSitStores} from "../../../stores";
import {Category, category_readable_str, Company, DoiStatus} from "../../ApiTypes";

export const InitiativeFilters: React.FC = observer(() => {
    const {initiativeListStore, ccStore} = useSitStores();
    const navigate = useNavigate();

    return <Grid container spacing={1} columns={24} className="initiative-filters-component">
        <Grid item xs={4}>
            <FormControl>
                <TextField
                    id='initiative-search'
                    variant='standard'
                    label='Search for initiatives'
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    disabled={(initiativeListStore.search === '')}
                                    onClick={() => initiativeListStore.setSearch('')}>
                                    <Clear/>
                                </IconButton>
                                <IconButton
                                    aria-label="search"
                                    onClick={() => initiativeListStore.reloadData()}>
                                    <Search/>
                                </IconButton>
                            </InputAdornment>
                    }}
                    value={initiativeListStore.search}
                    onChange={e => initiativeListStore.setSearch(e.target.value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            <FormControl fullWidth>
                <InputLabel id="show-label">View</InputLabel>
                <Select
                    labelId="show-label"
                    id="show"
                    value={initiativeListStore.my_initiatives}
                    label='View'
                    onChange={(e) => {
                        if (typeof e.target.value === 'number')
                            initiativeListStore.setMyInitiatives(e.target.value);
                    }}>
                    <MenuItem value={0}>All initiatives</MenuItem>
                    <MenuItem value={1}>My initiatives</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="category"
                    limitTags={1}
                    options={ccStore.categoryRM.result ?? ([] as Category[])}
                    getOptionLabel={(option) => category_readable_str(option)}
                    value={initiativeListStore.categories}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Category" placeholder="All"/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={initiativeListStore.categories.find((c) => c.id === option.id) !== undefined}/>
                            {category_readable_str(option)}
                        </li>}
                    onChange={(_e, value) => initiativeListStore.setCategories(value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="company"
                    limitTags={1}
                    options={ccStore.companyRM.result ?? ([] as Company[])}
                    getOptionLabel={(option) => option.title}
                    value={initiativeListStore.companies}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Company" placeholder="All"/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={initiativeListStore.companies.find((c) => c.id === option.id) !== undefined}/>
                            {option.title}
                        </li>}
                    onChange={(_e, value) => initiativeListStore.setCompanies(value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
            <FormControl fullWidth>
                <InputLabel id="doi-stage-label">Stage</InputLabel>
                <Select
                    labelId="doi-stage-label"
                    id="doi-stage"
                    value={initiativeListStore.doi}
                    label="Stage"
                    onChange={(e) => {
                        if (typeof e.target.value === 'number') initiativeListStore.setDoi(e.target.value)
                    }}>
                    <MenuItem value={0}>All</MenuItem>
                    {DoiStatus.map((value, index) =>
                        <MenuItem
                            key={index+1}
                            value={index+1}>
                            {index + 1}: {value}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            {/*<FormControl fullWidth margin="dense">*/}
            {/*    <Button variant="text"*/}
            {/*            onClick={() => {*/}
            {/*                // TODO CAT-2025 Save filters for user*/}
            {/*            }}>*/}
            {/*        Save filters*/}
            {/*    </Button>*/}
            {/*</FormControl>*/}
        </Grid>
        <Grid item xs={1}>
            {/*<FormControl margin="dense">*/}
            {/*    <IconButton>*/}
            {/*        <FilterAlt color="primary"/>*/}
            {/*    </IconButton>*/}
            {/*</FormControl>*/}
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth margin="dense">
                <Button
                    variant="outlined"
                    startIcon={<Add/>}
                    onClick={() => {
                        navigate(generatePath(sitRoutes.sit_initiative, {initiativeId: 'new'}))
                    }}>
                    Add new initiative
                </Button>
            </FormControl>
        </Grid>
    </Grid>
})