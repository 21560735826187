import {Firestore} from "@firebase/firestore";
import MithraMaterializedApi from "../MithraMaterializedApi";
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {FirebaseContextType, MithraFirebaseClaims} from "./FirebaseProvider";
import {getFirestore} from "firebase/firestore";


export class FirebaseStore {
    db?: Firestore;

    constructor(
        private readonly mithraApi: MithraMaterializedApi,
    ) {
    }

    init(firebase: FirebaseContextType): Promise<void> {
        if (this.db) return Promise.resolve();
        return this.mithraApi.http.get<{ token: string }>('/firebase/user_token/')
            .then((resp) => {
                const token = resp.data.token;
                const auth = getAuth(firebase.app)
                return signInWithCustomToken(auth, token)
                    .then((userCredential) => userCredential.user.getIdTokenResult()
                        .then(t => {
                            console.log('Firebase authentication succeeded', userCredential);
                            const claims = t.claims as MithraFirebaseClaims;
                            firebase.auth = {
                                user: userCredential.user,
                                claims: claims
                            }
                        }))
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.error('Cannot sign in to Firebase', errorCode, errorMessage)
                        throw new Error(`Authentication failed (${error.code})`);
                    })
                    .then(() => {
                        this.db = getFirestore(firebase.app);
                        firebase.db = this.db;
                        console.log('Firestore initialized');
                    });
            });
    }
}