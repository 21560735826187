import {observer} from "mobx-react-lite";
import {Alert, Card, TextField} from "@mui/material";
import React from "react";
import {useStores} from "../../../stores";
import {BcrHeader} from "./BcrHeader";


type Props = {}
export const SuccessFactorsComponent: React.FC<Props> = observer(() => {
    const {businessCaseGeneratorStore} = useStores();
    return <>
        <BcrHeader/>

        <div className="general-infos">
            <Alert severity="info">
                Add success factors and ESG opportunities.
            </Alert>
        </div>

        <Card className="success-factors-section success-factors-card" elevation={3}>
            <TextField
                id="success-factors-input-success"
                required
                fullWidth
                autoFocus
                label="Success factors"
                multiline
                rows={7}
                value={businessCaseGeneratorStore.successFactors || ''}
                onChange={(event) => businessCaseGeneratorStore.setSuccessFactors(event.target.value)}
                variant="outlined"
                placeholder="What you need for this business case to be successful, these could be resources, budget, or other prerequisites"
            />
            {/*</Card>*/}
            {/*<Card className="success-factors-section esg" elevation={3}>*/}
            <TextField
                id="success-factors-input-esg"
                fullWidth
                label="ESG Opportunities (Optional)"
                multiline
                rows={7}
                onChange={(event) => businessCaseGeneratorStore.setEsgOpportunities(event.target.value)}
                value={businessCaseGeneratorStore.esgOpportunities || ''}
                variant="outlined"
                placeholder="Identify and remove red flag existing suppliers or give more business to high performing ESG suppliers"
            />
        </Card>

    </>

});
