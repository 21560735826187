import {makeAutoObservable} from "mobx";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import {
    ClientArea,
    ClientColumn,
    MappingTableItemType,
    MithraArea,
    MithraColumn
} from "../pages/data-management/data-mapping/MappingTableTypes";
import {
    DataFrameSerializer,
    DataIngestionKpiSerializer,
    DataMappingResultTableSerializer,
    MithraColumnSerializer,
    TaxonomyMappingSerializer
} from '../services/ApiTypes';
import {v4 as uuid} from "uuid";
import {DataFileRouteMatch, routes} from "../routing/routes";
import AuthStore from "./AuthStore";
import {TaxonomyIngestionFile} from "../services/classes/IngestionClasses";
import {AxoisRequestManager} from "./managers/RequestManager";
import {from} from "rxjs";
import MithraDataIngestionApi, {canUploadFile} from "../services/MithraDataIngestionApi";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {NavigateFunction} from "react-router-dom";
import {Params} from "../routing/routeClasses";


type Page = 'taxonomy_upload'
    | 'taxonomy_mapping'
    | 'taxonomy_check'
    | 'taxonomy_finish'

type ResultTableRows = {
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
}


export default class TaxonomyIngestionStore {
    readonly taxonomyListManager = new AxoisRequestManager<void, TaxonomyIngestionFile[]>(
        () => from(this.ingestionApi.getTaxonomyIngestionList())
    )

    fileToUpload: File | undefined

    taxonomyUploadIsLoading = false

    taxonomyFile: TaxonomyIngestionFile | undefined
    taxonomyFileNotFound: boolean | undefined

    taxonomyName: string = ''
    taxonomyFileHeaderRow: number = 1
    taxonomyFileDataRow: number = 2

    taxonomyFileId: number | undefined

    taxonomyMapping: TaxonomyMappingSerializer[] | undefined
    taxonomyMappingResultKpi: DataIngestionKpiSerializer | undefined
    taxonomyMappingResultResponse: DataMappingResultTableSerializer | undefined

    mithraColumns: MithraColumnSerializer[] | undefined
    clientColumns: DataFrameSerializer | undefined
    allowedOperations
    clientAreaState: ClientArea = []
    mithraAreaState: MithraArea = []
    step = 0;
    page: Page | undefined = undefined;

    errorMsg = '';

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private ingestionApi: MithraDataIngestionApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    get taxonomyList() {
        return this.taxonomyListManager.result;
    }

    startTaxonomyMerge() {
        if (!this.taxonomyFileId) return Promise.reject('Data file id is not set')
        return this.ingestionApi.startTaxonomyMerge(this.taxonomyFileId)
    }

    setInitialStateMithraArea(mithraColumns: MithraColumnSerializer[], dataMapping) {
        const listOfMithraColumns = mithraColumns
        const mithraAreaToSet: MithraArea = []
        let itemArray: MappingTableItemType[] = []

        for (const mithraColumn of listOfMithraColumns) {
            for (const mithraItem of dataMapping) {
                if (mithraColumn.key === mithraItem.mithra_column_key) {
                    console.log('mithraItem');
                    console.log(mithraItem);
                    const tempItem: MappingTableItemType = {
                        name: mithraItem.client_column_name,
                        example: this.clientColumns?.data[0][mithraItem.client_column_index],
                        index: mithraItem.client_column_index,
                        id: uuid(),
                        parentList: mithraItem.client_column_name,
                        type: mithraItem.client_column_type,
                        ai_result: mithraItem.ai_result,
                        user_result: mithraItem.user_result,
                        // column_letter: mithraItem.column_letter,
                        column_letter: this.clientColumns?.columns_letters[mithraItem.client_column_index] || '',
                    }
                    itemArray.push(tempItem)
                }
            }

            const tempColumn: MithraColumn = {
                id: uuid(),
                items: itemArray,
                name: mithraColumn.name,
                type: mithraColumn.type,
                key: mithraColumn.key,
                description: mithraColumn.description,
                example: mithraColumn.example,
                disabled: false,
                is_required: mithraColumn.is_required,
                was_mapped: mithraColumn.was_mapped,
            }
            itemArray = []
            mithraAreaToSet.push(tempColumn)
        }

        this.mithraColumns = mithraColumns
        this.mithraAreaState = mithraAreaToSet
    }

    setInitialStateClientArea(clientColumns: DataFrameSerializer) {
        const copyClientColumns = clientColumns
        const clientAreaToSet: ClientArea = []

        for (let i = 0; i < copyClientColumns.columns.length; i++) {
            const temp: ClientColumn = {
                id: uuid(),
                name: copyClientColumns.columns[i],
                example: copyClientColumns.data[0][i],
                index: copyClientColumns.column_indexes[i],
                type: copyClientColumns.types[i],
                column_letter: copyClientColumns.columns_letters[i],
            }
            clientAreaToSet.push(temp)
        }
        this.clientColumns = clientColumns
        this.clientAreaState = clientAreaToSet
    }

    /**
     * Delete item from list, this function is passed to the child component via context
     * @param parentMithraColumn id of the parent list
     * @param itemId of the item to delete inside the parent list
     * @returns void
     */
    deleteItemFromList = (parentMithraColumn: string, itemId: string) => {
        const newMithraArea = [...this.mithraAreaState];
        const mithraColumn = this.mithraAreaState.findIndex((e) => e.id === parentMithraColumn);
        const itemToDeleteIndex = this.mithraAreaState[mithraColumn].items.findIndex((e) => e.id === itemId);
        newMithraArea[mithraColumn].items.splice(itemToDeleteIndex, 1);
        this.mithraAreaState = newMithraArea;
    }

    async postTaxonomyMappingsList() {
        const taxonomyFileId = this.taxonomyFileId;

        if (!taxonomyFileId) {
            throw new Error('Data file id is not set');
        }

        const mapping: TaxonomyMappingSerializer[] = []
        for (const mithraColumn of this.mithraAreaState) {
            for (const item of mithraColumn.items) {
                const m: TaxonomyMappingSerializer = {
                    taxonomy_file: taxonomyFileId,
                    client_column_index: item.index,
                    client_column_name: item.name,
                    client_column_type: item.type,
                    mithra_column_key: mithraColumn.key,
                    user_result: item.user_result,
                    ai_result: item.ai_result,
                }
                mapping.push(m)
            }
        }

        /*
        * 1) post mappings
        * 2) apply mappings
        * 3) get mapping result table
        * 4) get mapping result KPIs
        * */

        await this.ingestionApi.postTaxonomyMappingList(mapping);
        await this.ingestionApi.applyTaxonomyMapping(taxonomyFileId);
        await Promise.all([
            this.ingestionApi.getTaxonomyMappingResult(taxonomyFileId).then(d => this.setTaxonomyMappingResultResponse(d)),
            // this.ingestionApi.getKpiList(taxonomyFileId).then(d => this.setDataMappingResultKpi(d))
        ]);
    }

    setDataMappingResultKpi(d: DataIngestionKpiSerializer) {
        this.taxonomyMappingResultKpi = d;
    }

    setTaxonomyMappingResultResponse(d: DataMappingResultTableSerializer) {
        this.taxonomyMappingResultResponse = d

    }

    resetIngestionStore() {
        this.taxonomyListManager.cleanup()
        this.taxonomyName = ''
        this.taxonomyFileId = undefined
        this.taxonomyMapping = []
        this.allowedOperations = []
        this.clientAreaState = []
        this.mithraAreaState = []
        this.step = 0;
        this.page = 'taxonomy_upload';
        this.errorMsg = '';
    }


    navigateToPage(page: Page) {
        switch (page) {
            case "taxonomy_upload":
                this.step = 0;
                break;
            case "taxonomy_mapping":
                this.step = 1;
                break;
            case "taxonomy_check":
                this.step = 2;
                break;
            case "taxonomy_finish":
                this.step = 3;
                break;
        }
        this.page = page;
    }

    get all_required_columns_mapped(): boolean {
        for (const mithraColumn of this.mithraAreaState) {
            if (mithraColumn.items.length === 0 && mithraColumn.is_required) {
                return false
            }
        }
        return true;
    }

    get taxonomyMappingHeaders(): ColSpec[] {
        const arr: ColSpec[] = [
            {cls: 'col-id', txt: 'ID', columnFieldId: 'cat_id', width: 20},
        ]
        const L = this.taxonomyFile?.highest_mapped_level || 1;
        for (let i = 0; i < L; i++) {
            const l = i + 1;
            arr.push({cls: `col-l${l}`, txt: `L${l}`, width: 100, columnFieldId: `l${l}`})
        }
        arr.push({cls: 'col-description', txt: 'Description', columnFieldId: 'cat_description', width: 100})
        return arr;
    }

    get taxonomyMappingResultTable(): any[] | undefined {
        const result = this.taxonomyMappingResultResponse;
        if (!result) return undefined;
        return result.data.map((row) =>
            row.reduce((obj, value, index) => {
                const key = result.columns[index];
                obj[key] = value;
                return obj;
            }, {})
        );
    }


    initTaxonomyFile(
        navigate: NavigateFunction,
        params: Params<DataFileRouteMatch>,
    ) {
        const taxonomyFileId = Number(params.dataFileId);
        if (isNaN(taxonomyFileId)) {
            this.setTaxonomyFileNotFound()
            return
        }

        Promise.all([
            this.ingestionApi.getTaxonomyMappingList(taxonomyFileId),
            this.ingestionApi.getAllTaxonomyClientColumns(taxonomyFileId),
            this.ingestionApi.getAllMithraTaxonomyColumns(),
            this.ingestionApi.getAllowedOperations(),
            this.ingestionApi.getTaxonomyIngestion(taxonomyFileId),
        ])
            .then(([dataMapping, clientColumns, mithraColumns, allowedOperations, taxonomyFile]) => {
                this.setErrorMsg('')
                this.setTaxonomyFileAction(taxonomyFileId, dataMapping, clientColumns, mithraColumns, allowedOperations, taxonomyFile)
            })
            .catch(err => {
                const unAuth = this.authStore.authentication.catchApiError(err);
                if (err) {
                    this.setErrorMsg('Error loading file')
                    this.setTaxonomyFileNotFound()
                    navigate(routes.taxonomy_upload);
                    return
                } else if (unAuth) {
                    this.setTaxonomyFileNotFound()
                    navigate(routes.login);
                    return
                }
            })
    }

    setTaxonomyFileAction(
        taxonomyFileId: number,
        dataMapping: TaxonomyMappingSerializer[],
        clientColumns: DataFrameSerializer,
        mithraColumns: MithraColumnSerializer[],
        allowedOperations: any,
        taxonomyFile: TaxonomyIngestionFile,
    ) {
        //The order of the following two lines is important because the mithraAreaState depends on the clientAreaState
        this.setInitialStateClientArea(clientColumns)
        this.setInitialStateMithraArea(mithraColumns, dataMapping)
        this.allowedOperations = allowedOperations
        this.taxonomyFileId = taxonomyFileId
        this.taxonomyFile = taxonomyFile
        this.taxonomyFileNotFound = false;
    }

    setFileToUpload(file: File | undefined) {
        this.fileToUpload = file
        if (!file) return;

        const err = canUploadFile(file.name, file.size);
        if (err) {
            this.errorMsg = err;
        } else {
            this.errorMsg = '';
            if (!this.taxonomyName || this.taxonomyName.trim() === '') {
                this.setTaxonomyName(file.name);
            }
        }
    }

    setTaxonomyFileNotFound() {
        this.taxonomyFileNotFound = true;
        this.taxonomyFile = undefined;
        this.taxonomyFileId = undefined;
    }

    setErrorMsg(error: string) {
        this.errorMsg = error;
    }

    setTaxonomyFileId(taxonomyFileId: number) {
        this.taxonomyFileId = taxonomyFileId;
    }

    setTaxonomyName(taxonomyName: string) {
        this.taxonomyName = taxonomyName;
    }

    setTaxonomyUploadIsLoading(isLoading: boolean) {
        this.taxonomyUploadIsLoading = isLoading;
    }

    get uploadIsBusy() {
        return this.taxonomyUploadIsLoading;
    }

    setFileHeaderRow(d: number) {
        this.taxonomyFileHeaderRow = d;
    }

    setFileDataRow(d: number) {
        this.taxonomyFileDataRow = d;
    }

    async uploadFileDirectly() {
        const file = this.fileToUpload;
        if (!file) {
            console.warn('No file to upload')
            return
        }

        this.taxonomyUploadIsLoading = true;
        try {
            const resp = await this.ingestionApi.uploadTaxonomyIngestionFile({
                name: this.taxonomyName,
                input_file: file,
                input_df_column_row: this.taxonomyFileHeaderRow,
                input_df_data_row: this.taxonomyFileDataRow,
            })
            this.setErrorMsg('')
            this.setTaxonomyFileId(resp.id)
            return resp
        } catch (error) {
            console.error(error);
            this.setErrorMsg(`Error uploading file (${String(error)})`)
        } finally {
            this.setTaxonomyUploadIsLoading(false)
        }
    }
}