import {C} from "./configurations/active-configuration";
import {GROUP_READ_ONLY, GROUP_STAFF, GROUP_SUPER, GROUP_USER} from "./services/ApiTypes";
import {User} from "./services/classes/User";
import {B} from "./build";
import {SitRole} from "./sit/ApiTypes";

const staticIo = {
    inFilename: '20210409 Spend Analysis.xlsx',
    outFilename: '20210409 Spend Analysis_result.xlsx',
    outFileUrl: 'https://storage.googleapis.com/mithra-cat-production-static/demo/20210409%20Spend%20Analysis_result.xlsx',
}

const demoFiles = {
    spendClassificationResult: {
        name: 'LibertyGlobal-Categorization.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-Categorization.xlsx',
    },
    supplierHierarchy: {
        name: 'LibertyGlobal-SupplierHierarchy.xlsx',
        url: 'https://storage.googleapis.com/mithra-cat-production-static/demo/LibertyGlobal-SupplierHierarchy.xlsx',
    }
}

export const DEV_USER_DUMMY: User = {
    id: 2,
    username: 'dummy',
    email: 'dummy@mithra-ai.com',
    first_name: 'Demo',
    last_name: 'User',
    groups: [GROUP_USER, GROUP_SUPER, GROUP_STAFF],
    // groups: [GROUP_USER],
    is_staff: true,
    // "id": 17,
    // "username": "dennis4",
    // "email": "dennis@mithra-ai.com",
    // "first_name": "dennis@mithra-ai.com",
    // "last_name": "",
    // "groups": [
    //     "customer_readonly"
    // ],
    // "is_staff": false,
    sit_user_profile: {
        // Change this to 1 in case you want to test SIT initiative lock on dev, set to 2 otherwise
        id: 2,
        email: 'dummy@mithra-ai.com',
        name: 'Demo User',
        first_name: 'Demo',
        last_name: 'User',
        default_company: {
            "id": 1,
            "cluster": {"id": 1, "title": "Cluster 1"},
            "currency": {"id": 5, "name": "Swedish Krona", "code": "SEK"},
            "country": {"id": 5, "name": "Sweden", "code_a3": "SWE"},
            "title": "FFU HS",
            "code": "FFU",
            "fiscal_year_start_month": 8
        },
        companies: [1],
        role: 'admin' as SitRole,
        created: '09-25T10:58:56.794736+02:00',
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _x = [GROUP_READ_ONLY, GROUP_STAFF, GROUP_SUPER, GROUP_USER];

type FirebaseConfig = {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string,
}
const firebaseConfig: FirebaseConfig = {
    apiKey: B.FIREBASE_API_KEY,
    authDomain: B.FIREBASE_AUTH_DOMAIN,
    projectId: B.FIREBASE_PROJECT_ID,
    storageBucket: B.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: B.FIREBASE_MESSAGING_SENDER_ID,
    appId: B.FIREBASE_APP_ID,
    measurementId: B.FIREBASE_MEASUREMENT_ID,
}
if (!firebaseConfig.authDomain && firebaseConfig.projectId) {
    firebaseConfig.authDomain = `${firebaseConfig.projectId}.firebaseapp.com`;
}
if (!firebaseConfig.storageBucket && firebaseConfig.projectId) {
    firebaseConfig.storageBucket = `${firebaseConfig.projectId}.appspot.com`;
}

export const environment = {
    environmentName: B.APP_ENVIRONMENT,
    /**
     * TODO: CAT-712: Remove customer names from public source code
     * @deprecated
     */
    customerName: B.APP_CUSTOMER,
    locale: undefined as (string | undefined), // https://momentjs.com/ (en-GB, en-US, nl-NL)
    production: !B.APP_IS_DEV,
    testAuthentication: B.APP_ENVIRONMENT.endsWith('auth'),
    isTest: false,
    firebasePreviewConfiguration: B.PREVIEW_ID,
    showDebugInfoInMenu: B.APP_IS_DEV,
    devMenu: false,
    devLogin: B.APP_IS_DEV, // Automatically login with DEV_USER_ID
    // devLogin: false,
    authUrl: `${B.MATERIALIZED_HOST}/auth`,
    apiUrl: `${B.BACKEND_HOST}/api`,
    adminUrl: `${B.BACKEND_HOST}/admin`,
    materializedApiUrl: `${B.MATERIALIZED_HOST}/api`,
    apiDataIngestionUrl: `${B.INGESTION_HOST}/api`,
    sitApiUrl: `${B.MATERIALIZED_HOST}/api/sit`,
    staticFilesUrl: (B.DJANGO_GCP_STATIC_BUCKET
        ? `https://storage.googleapis.com/${B.DJANGO_GCP_STATIC_BUCKET}/static`
        : '') || 'http://localhost:8500/static',
    apiDelay: B.APP_IS_DEV ? 600 : 0,
    baseUrl: B.APP_URL,
    auth0ClientId: B.AUTH0_CLIENT_ID,
    auth0Domain: B.AUTH0_DOMAIN,
    auth0Organization: B.AUTH0_ORGANIZATION,
    staticIo,
    lookerPreviewUrl: `${process.env.REACT_APP_LOOKER_PREVIEW_URL}`,
    demoFiles,
    aiVmName: 'ai-workhorse-6-eu-west4-a-1',
    fakePPVData: false,
    jobOrderingNewToOld: false,
    defaultCategorizationSinglePageSize: 100,
    /**
     * Regarding the latest tests on SHV we get:
     * - 5: 1.3s
     * - 10: 1.9s
     * - 20: 2.6s
     * - 30: 3.6s
     */
    defaultCategorizationGroupedPageSize: 15,
    defaultCategorizationSublistPageSize: 50,
    defaultPpvTablePageSize: 50,
    defaultPpvSubTablePageSize: 20,
    aiResultFakeTimeout: 2500,
    package: C.package,
    dashboardVariant: C.dashboardVariant,
    overruleShowScore: false, // Only for SHV preview testing, TODO: Remove this
    isTestReviewPage: false,
    isTestDebugReviewPage: false,

    isDemo: C.package === 'sales_demo',
    firebaseConfig,

    // Google Analytics enabled
    //   When Firebase is not loaded but we do send events to GA we will get the following error:
    //   "No Firebase App '[DEFAULT]' has been created - call Firebase App.initializeApp() (app/no-app)."
    isGA: Boolean(firebaseConfig.measurementId),
};

//region Dennis Dev
if (B.APP_IS_DEV) {
    //
    environment.isTest = true;
    //
}
//endregion

export const HACKS = [
    // 'sankey_tax_switch',
];

if (B.APP_ENVIRONMENT === 'staging-cat-1188') {
    environment.overruleShowScore = true;
}

if (B.APP_ENVIRONMENT === 'staging-cat-1713-testing') {
    C.profile.mergeXmergeOpportunityDashboardBagId = 1480;
    C.profile.skipAiSupplierNormalizationModel = false; // We are still in the process of testing this in staging
}

export const TIMINGS = {
    'input_loading': [
        1, // Uploading
        15, // Parsing Excel
        7, // Optimizing Excel
        3, // Merging File(s)
    ], // seconds
}

if (!B.MATERIALIZED_HOST) {
    if (environment.production) {
        console.error('Misconfiguration error: Missing MATERIALIZED_HOST variable')
    } else {
        throw new Error('Misconfiguration error: Missing MATERIALIZED_HOST variable')
    }
}
if (!B.INGESTION_HOST) {
    if (environment.production) {
        console.error('Misconfiguration error: Missing INGESTION_HOST variable')
    } else {
        throw new Error('Misconfiguration error: Missing INGESTION_HOST variable')
    }
}
