import './BcrHeader.scss'
import {Card, Chip, Grid, Typography} from "@mui/material";
import React from "react";
import {
    AccessTimeOutlined,
    AccountCircleOutlined,
    CalendarTodayOutlined,
    DashboardOutlined,
    InfoOutlined,
    PublicOutlined,
    WorkOutlineOutlined
} from "@mui/icons-material";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";


const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;

const reportInfo = {
    reportId: 'A32-P980-132',
    date: formattedDate,
    initiator: 'Sarah Jones',
    category: 'Packaging',
    region: 'All',
    spendProfile: 'ERP1 & ERP2 & ERP3',
    timeSpan: '2022',
};

export const BcrHeader = observer(() => {
        const {businessCaseGeneratorStore} = useStores();
    const params = useParams();
    const location = useLocation();
    const isLastPage = location.pathname === routes.job_business_case_report_id;
        const statusChip = businessCaseGeneratorStore.isInitialLoading
            ? <Chip label="Generating" style={{backgroundColor: '#091344', color: 'white'}}/>
            : (isLastPage
                    ? <Chip label="Published" style={{backgroundColor: '#08A57A', color: 'white'}}/>
                    : <Chip label="Draft" style={{backgroundColor: '#091344', color: 'white'}}/>
            )

        return <Card className="bcr-header">
            <Grid container className="top-row">
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<InfoOutlined/>} title="Report id" text={reportInfo.reportId}/>
                </Grid>
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<CalendarTodayOutlined/>} title="Date" text={reportInfo.date}/>
                </Grid>
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<AccountCircleOutlined/>} title="Initiator" text={reportInfo.initiator}/>
                </Grid>
                <Grid item xs={3} className="status-bcr-header-card">
                    <BcrHeaderCard icon={undefined} title="Status" text={statusChip}/>
                </Grid>
            </Grid>
            <Grid container className="bottom-row">
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<DashboardOutlined/>} title="Category" text={reportInfo.category}/>
                </Grid>
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<AccessTimeOutlined/>} title={'Time span'} text={reportInfo.timeSpan}/>
                </Grid>
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<PublicOutlined/>} title="Region" text={reportInfo.region}/>
                </Grid>
                <Grid item xs={3}>
                    <BcrHeaderCard icon={<WorkOutlineOutlined/>} title="Spend profile" text={reportInfo.spendProfile}/>
                </Grid>
            </Grid>
        </Card>;
    }
)

const BcrHeaderCard = ({icon, title, text}) =>
    <Grid container className="bcr-header-card">
        {icon &&
            <Grid item container sm={4} md={3} xl={2} justifyContent="center" alignItems="center" className="avatar">
                <Grid item>
                    {icon}
                </Grid>
            </Grid>}
        <Grid item sm={8} md={9} xl={10}>
            <header>
                <Typography variant="caption" className="title">
                    {title}
                </Typography>
            </header>
            <section className="content">
                {typeof text === 'string'
                    ? <Typography variant="body1" component="p">{text}</Typography>
                    : text
                }
            </section>
        </Grid>
    </Grid>
