import {GoldenRecordStore} from "../../../stores/GoldenRecordStore";
import {makeAutoObservable} from "mobx";
import {SearchManager} from "../../../stores/managers/SearchManager";

export class GoldenRecordFilterController {
    isFilterModalOpen = false;

    selectedCategory: string[] = [];
    readonly supplierSearch = new SearchManager('supplier', () => this.refreshData());

    isFilterEnabled = false;

    constructor(private goldenRecordStore: GoldenRecordStore) {
        makeAutoObservable(this);
    }

    setIsFilterModalOpen(isOpen: boolean) {
        this.isFilterModalOpen = isOpen;
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    setSelectedCategory(selectedCategory: string[]) {
        const obj = {old: `${this.selectedCategory}`, new: `${selectedCategory}`};
        console.debug('GoldenRecordFilterController.setSelectedCategory', obj);

        this.selectedCategory = selectedCategory;
        this.refreshData()
    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    get hasRemainingSelectionLevels(): boolean {
        return this.selectedLevel < 1;
        // if (this.selectedLevel >= this.goldenRecordStore.taxonomySize) {
        //     return false
        // }
        // // noinspection RedundantIfStatementJS
        // if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
        //     // Do not allow to go deeper in uncategorized
        //     return false;
        // }
        // return true;
    }

    refreshData() {
        this.goldenRecordStore.refreshData();
    }
}