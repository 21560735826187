import "./TaxonomyMapping.scss";
import {Droppable} from "react-beautiful-dnd";
import React from "react";
import {TaxonomyMappingTableItem} from "./TaxonomyMappingTableItem";
import {ClientColumn} from "../../data-management/data-mapping/MappingTableTypes";


type Props = {
    items: ClientColumn[]
    className: string
    droppableId: string
}

// This method is needed for rendering clones of draggables
// This method define the behavior of the dragged object
const getRenderItem = (items) => (provided, snapshot, rubric) => {
    const item = items[rubric.source.index];
    return (
        <>
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={provided.draggableProps.style}
                className={snapshot.isDragging ? "taxonomy-item-dragging" : ""}
            >
                <div className='item-name'>{item.column_letter}: {item.name}</div>
                {/* This is commented since the example might need to change when it will be parsed in the mithra column */}
                {/* <div className="item-example">{item.example}</div> */}
            </div>
        </>
    );
};

export const TaxonomyMappingClientColumn: React.FC<Props> = ({items, className, droppableId,}) => {
    return (
        <Droppable
            renderClone={getRenderItem(items)}
            droppableId={droppableId}
            isDropDisabled={true}
        >
            {(provided, snapshot) => (
                <div ref={provided.innerRef} className={className}>
                    {items.map((item, index) => {
                        const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
                        return (
                            <React.Fragment key={item.id}>
                                {shouldRenderClone ? (
                                    //this is the element that will appear in place of the original when drag starts
                                    <TaxonomyMappingTableItem isClone={true} example={item.example} name={item.name}
                                                              index={index}
                                                              id={item.id}
                                                              parentList={droppableId + 'clone'}
                                                              column_letter={item.column_letter}></TaxonomyMappingTableItem>

                                ) : (
                                    <TaxonomyMappingTableItem name={item.name} example={item.example} index={index}
                                                              id={item.id}
                                                              parentList={droppableId}
                                                              column_letter={item.column_letter}></TaxonomyMappingTableItem>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
