import './PpvOpportunityDetailPage.scss'
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import React, {useEffect} from "react";
import {KoiFacade} from "../../../../components/koi-facade/KoiFacade";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {Grid, Tab, Tabs} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import {ppvOpportunityController} from "./PpvOpportunityController";
import {PpvOpportunityOverviewComponent} from "./components/PpvOpportunityOverviewComponent";
import {PpvOpportunityImpactComponent} from "./components/PpvOpportunityImpactComponent";
import {PpvOpportunityDataComponent} from "./components/PpvOpportunityDataComponent";
import {PpvOpportunityValidationComponent} from "./components/PpvOpportunityValidationComponent";


export const PpvOpportunityDetailPage: React.FC = observer(() => {
    const {bagStore} = useStores();
    const params = useParams();
    const location = useLocation();

    const opportunityId = Number(params.oppId);
    useEffect(() => {
        if(opportunityId) {
            ppvOpportunityController.ppvOpportunityRequest.request({opportunityId});
        }
    }, [opportunityId]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        ppvOpportunityController.setStep(newValue);
    };


    return <KoiFacade
        title="Opportunities Details"
        intro={<></>}
        back={generatePath(routes.koi_opportunities, {id: bagStore.bagId})}
        className="ppv-opportunity-detail-page"
    >
        <Tabs value={ppvOpportunityController.step} onChange={handleChange} className="root-stepper">
            <Tab label="Overview"  />
            <Tab label="Spend data"  />
            <Tab label="Impact Analysis"  />
            <Tab label="Validation"  />
        </Tabs>
        <Grid container>
            <Grid item xs={12} md={8} xl={6}>
                {ppvOpportunityController.step === 0 && <PpvOpportunityOverviewComponent/>}
                {ppvOpportunityController.step === 1 && <PpvOpportunityDataComponent/>}
                {ppvOpportunityController.step === 2 && <PpvOpportunityImpactComponent/>}
                {ppvOpportunityController.step === 3 && <PpvOpportunityValidationComponent/>}
            </Grid>
        </Grid>
    </KoiFacade>
});