import {ParetoOverviewVisualization} from "../../jobs/koi/spend-concentration/viz/ParetoOverviewVisualization";
import React from "react";
import {BarDataPoint} from "../visualization/BarChart";
import {Grid, Typography} from "@mui/material";
import {Options} from "../visualization/bar-chart/BarChart2";

type Props = {
    data: BarDataPoint[] | undefined,
    itemsCoveredByAi: string,
    percentageCoveredByAi: number,
}

const options: Partial<Options> = {
    vertical: false,
    width: 600,
    height: 'auto',
    labelMargin: 100, // Of the total width
    valueLabelPxH: 34,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    alwaysShowValues: true,
}
export const CoveredByAi: React.FC<Props> = ({data, itemsCoveredByAi, percentageCoveredByAi}) => {

    return (
        <Grid container justifyContent='center' direction='column'>
            <Grid item>
                <Typography variant="h5"
                            align="center"
                            className='key-values'>{itemsCoveredByAi + ' | ' + percentageCoveredByAi + '%'}</Typography>
            </Grid>
            <Grid item className={"custom-pareto-overview"}>
                {data && (
                    <ParetoOverviewVisualization
                        data={data}
                        options={{
                            ...options,
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
}
