import {PpvGroupFilter} from "../../jobs/koi/purchase-price-variance/classes/PpvApiClasses";

export enum OpportunityStatusEnum {
    PENDING_REVIEW = 0,
    IN_REVIEW = 1,
    QUALIFIED = 2,
    DISQUALIFIED = 3,
}

export type PpvOpportunity_PATCH = {
    status?: OpportunityStatusEnum,
    note?: string,
    scope?: PpvGroupFilter,
}

export type PpvOpportunity = {
    id: number
    ppv_group_key: string
    databag: number
    status: OpportunityStatusEnum
    scope: PpvGroupFilter
    note: string
    initial_expected_savings: number
    current_expected_savings: number
    modified: string
}
export type CreatePpvOpportunity = {
    ppv_group_key: string
    databag: number
    status: OpportunityStatusEnum
    scope: PpvGroupFilter
    note: string
    expected_savings: number
}

export type PpvOpportunityListFilter = {
    ppv_group_key?: string,
    databag?: number,
    page?: number,
    page_size?: number,
}

export type PpvStatsPerSupplier = {
    s_name: string
    s_country: string
    total_spend: string
    l1: string[]
}
export type PpvStatsPerPart = {
    s_name: string
    p_name: string
    total_spend: string
    avg_unit_price: number
}
export type PpvStatsPerQuarter = {
    quarter_date: string // Date of first day of quarter
    total_spend: number
}
