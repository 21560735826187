import {observer} from "mobx-react-lite";
import {Alert, Dialog, Grid, IconButton, Skeleton, TextField, Typography} from "@mui/material";
import {Delete, Help} from "@mui/icons-material";
import React from "react";
import {stores, useStores} from "../../../stores";
import {ClassificationHierarchyIcon} from "../../../components/icons/ClassificationHierarchyIcon";
import {BcrTable} from "./BcrTable";
import {ModalDeleteBCRTable} from "./modals/ModalDeleteBCRTable";
import {parseAsCurrency} from "../../../components/currency-component/CurrencyParser";
import {runInAction} from "mobx";
import {toCurrency} from "../../../components/currency-component/CurrencyClasses";
import {ModalMasterAgreementKPI} from "./modals/ModalMasterAgreementKPI";

type Props = {}
export const ReviewOpportunitiesTableSection: React.FC<Props> = observer(() => {
    const {p, businessCaseGeneratorStore} = useStores();
    return <>
        <Dialog
            open={businessCaseGeneratorStore.isDeleteTable1ModalOpen}
            onClose={() => businessCaseGeneratorStore.isDeleteTable1ModalOpen = false}
            aria-labelledby={"taxonomy-editor-modal-title"}
            maxWidth="lg"
        >
            {businessCaseGeneratorStore.isDeleteTable1ModalOpen &&
                <ModalDeleteBCRTable name={'Master supplier agreements'} onClose={() => {
                    businessCaseGeneratorStore.isDeleteTable1ModalOpen = false
                }} onConfirm={() => {
                    businessCaseGeneratorStore.isDeleteTable1ModalOpen = false
                    businessCaseGeneratorStore.isTable1Deleted = true
                }}/>}
        </Dialog>

        <Dialog
            open={businessCaseGeneratorStore.isDeleteTable2ModalOpen}
            onClose={() => businessCaseGeneratorStore.isDeleteTable2ModalOpen = false}
            aria-labelledby={"taxonomy-editor-modal-title"}
            maxWidth="lg"
        >
            {businessCaseGeneratorStore.isDeleteTable2ModalOpen &&
                <ModalDeleteBCRTable name={'One time tail spend'} onClose={() => {
                    businessCaseGeneratorStore.isDeleteTable2ModalOpen = false
                }} onConfirm={() => {
                    businessCaseGeneratorStore.isDeleteTable2ModalOpen = false
                    businessCaseGeneratorStore.isTable2Deleted = true
                }}/>}
        </Dialog>

        <Dialog
            open={businessCaseGeneratorStore.isDeleteTable3ModalOpen}
            onClose={() => businessCaseGeneratorStore.isDeleteTable3ModalOpen = false}
            aria-labelledby={"taxonomy-editor-modal-title"}
            maxWidth="lg"
        >
            {businessCaseGeneratorStore.isDeleteTable3ModalOpen &&
                <ModalDeleteBCRTable name={'Frequent tail spend'} onClose={() => {
                    businessCaseGeneratorStore.isDeleteTable3ModalOpen = false
                }} onConfirm={() => {
                    businessCaseGeneratorStore.isDeleteTable3ModalOpen = false
                    businessCaseGeneratorStore.isTable3Deleted = true
                }}/>}
        </Dialog>

        <Dialog
            open={businessCaseGeneratorStore.isKPIsModalOpen}
            onClose={() => runInAction(() => {
                businessCaseGeneratorStore.isKPIsModalOpen = false
            })}
            aria-labelledby={"kpi-modal-title"}
            maxWidth="md"
        >
            {businessCaseGeneratorStore.isKPIsModalOpen &&
                <ModalMasterAgreementKPI isOpen={businessCaseGeneratorStore.isKPIsModalOpen}
                                         onClose={() => runInAction(() => {
                                             businessCaseGeneratorStore.isKPIsModalOpen = false
                                         })}/>
            }
        </Dialog>

        {businessCaseGeneratorStore.hasLoaded && <div className="general-infos">
            <Alert severity="info">
                Please review detected key opportunities for the requested business case report.
            </Alert>
        </div>}

        {/******************************************************************************************************
         ** Master supplier agreements
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable1Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">
                        Master supplier agreements
                        <IconButton onClick={() => runInAction(() => {
                            businessCaseGeneratorStore.isKPIsModalOpen = true
                        })}>
                            <Help/>
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid item className="text-right flex-right">
                    <IconButton onClick={() => {
                        businessCaseGeneratorStore.isDeleteTable1ModalOpen = true
                    }}>
                        <Delete style={{color: 'red'}}/>
                    </IconButton>
                </Grid>
            </Grid>
            {businessCaseGeneratorStore.isInitialLoading ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Skeleton variant="rectangular" height={200} animation="wave"/>
                    </Grid>
                </Grid>
            ) : (
                <Grid className="table-header-kpis" container>
                    <Grid xs={4}>
                        <RowGrid title="Identified parent suppliers"
                                 number={businessCaseGeneratorStore.hasLoaded ? '4' : '-'}/>
                    </Grid>
                    <Grid xs={4}>
                        <RowGrid title="Number of child suppliers"
                                 number={businessCaseGeneratorStore.hasLoaded ? '12' : '-'}/>
                    </Grid>
                    <Grid xs={4}>
                        <RowGrid
                            title="Aggregated value of spend"
                            number={businessCaseGeneratorStore.hasLoaded ? toCurrency(p.currencySymbol, 18500000, 3) : '-'}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid className="ai-opportunity-values-wrapper"
                  container>
                <Grid item xs={2}>
                    {businessCaseGeneratorStore.isInitialLoading ?
                        <Skeleton variant="rectangular" height={30} animation="wave"/>
                        :
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table1-year1"
                                className="opp-savings-field"
                                label="Savings in year 1"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable1Year1ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable1Year1ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable1Year1ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable1YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    }
                </Grid>
                <Grid item xs={2}>
                    {businessCaseGeneratorStore.isInitialLoading ?
                        <Skeleton variant="rectangular" height={30} animation="wave"/>
                        :
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table1-year2"
                                className="opp-savings-field"
                                label="Savings in year 2"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable1Year2ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable1Year2ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable1Year2ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable1YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    }
                </Grid>
                <Grid item xs={2}>
                    {businessCaseGeneratorStore.isInitialLoading ?
                        <Skeleton variant="rectangular" height={30} animation="wave"/>
                        :
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table1-year3"
                                className="opp-savings-field"
                                label="Savings in year 3"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable1Year3ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable1Year3ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable1Year3ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable1YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    }
                </Grid>

                <Grid xs={4}>
                </Grid>

                <Grid xs={2}>


                    {businessCaseGeneratorStore.isInitialLoading ?
                        <Skeleton variant="rectangular" height={30} animation="wave"/>
                        :
                        <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                    }
                </Grid>
            </Grid>

            {businessCaseGeneratorStore.isInitialLoading ?
                <Skeleton variant="rectangular" height={300} animation="wave"/>
                :
                <BcrTable
                    className="advanced-opportunity-table"
                    headers={businessCaseGeneratorStore.OPPORTUNITY_TABLE_HEADERS}
                    data={businessCaseGeneratorStore.hasLoaded ? businessCaseGeneratorStore.masterSupplierAgreementData : undefined}
                    isLoading={businessCaseGeneratorStore.masterSupplierAgreementLoading}
                    onSelect={item => businessCaseGeneratorStore.setBcrSelected(item, !item.selected)}
                />
            }
        </section>}

        {/******************************************************************************************************
         ** One time tail spend
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable2Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">One time tail spend</Typography>
                </Grid>
                <Grid item className="text-right flex-right">
                    <IconButton onClick={() => {
                        businessCaseGeneratorStore.isDeleteTable2ModalOpen = true
                    }}><Delete style={{color: 'red'}}/></IconButton>
                </Grid>

                <Grid className="table-header-kpis"
                      item xs={12}
                      container>
                    <Grid xs={4}>
                        <RowGrid title="Identified parent suppliers"
                                 number={businessCaseGeneratorStore.hasLoaded ? '0' : '-'}/>
                    </Grid>
                    <Grid xs={4}>
                        <RowGrid title="Number of child suppliers"
                                 number={businessCaseGeneratorStore.hasLoaded ? '10' : '-'}/>
                    </Grid>
                    <Grid xs={4}>
                        <RowGrid title="Aggregated value of spend"
                                 number={businessCaseGeneratorStore.hasLoaded ? toCurrency(p.currencySymbol, 300000, 3) : '-'}/>
                    </Grid>
                </Grid>

                <Grid className="ai-opportunity-values-wrapper"
                      container
                      item xs={12}>
                    <Grid xs={2}>
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table2-year1"
                                className="opp-savings-field"
                                label="Savings in year 1"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable2Year1ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable2Year1ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable2Year1ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable2YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    </Grid>
                    <Grid xs={2}>
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table2-year2"
                                className="opp-savings-field"
                                label="Savings in year 2"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable2Year2ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable2Year2ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable2Year2ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable2YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    </Grid>
                    <Grid xs={2}>
                        <div className="opp-savings-field-wrapper">
                            <TextField
                                id="opp-table2-year3"
                                className="opp-savings-field"
                                label="Savings in year 3"
                                variant="standard"
                                fullWidth
                                value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable2Year3ExpectedSavings || 0, 3) : '-'}
                                onChange={e => {
                                    const v = parseAsCurrency(e.target.value)
                                    runInAction(() => businessCaseGeneratorStore.oppTable2Year3ExpectedSavings = v)
                                }}
                                helperText={
                                    businessCaseGeneratorStore.hasLoaded
                                        ? expectedSavingsHelperText(
                                            businessCaseGeneratorStore.oppTable2Year3ExpectedSavings,
                                            businessCaseGeneratorStore.oppTable2YearTotalValue
                                        )
                                        : ' '
                                }
                            />
                        </div>
                    </Grid>

                    <Grid xs={4}>
                    </Grid>

                    <Grid xs={2}>
                        <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                    </Grid>
                </Grid>

                <Grid container>
                    <BcrTable
                        className="advanced-opportunity-table 2nd-table"
                        headers={businessCaseGeneratorStore.OPPORTUNITY_TABLE_HEADERS}
                        data={businessCaseGeneratorStore.hasLoaded ? businessCaseGeneratorStore.oneTimeTailSpendData : undefined}
                        isLoading={businessCaseGeneratorStore.oneTimeTailSpendLoading}
                        onSelect={item => businessCaseGeneratorStore.setBcrSelected(item, !item.selected)}
                    />
                </Grid>

            </Grid>
        </section>}

        {/******************************************************************************************************
         ** Frequent tail spend
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable3Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">Frequent tail spend</Typography>
                </Grid>
                <Grid item className="text-right flex-right">
                    <IconButton onClick={() => {
                        businessCaseGeneratorStore.isDeleteTable3ModalOpen = true
                    }}>
                        <Delete style={{color: 'red'}}/>
                    </IconButton>
                </Grid>
            </Grid>

            <Grid className="table-header-kpis"
                  container>
                <Grid xs={4}>
                    <RowGrid title="Identified parent suppliers"
                             number={businessCaseGeneratorStore.hasLoaded ? '0' : '-'}/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Number of child suppliers"
                             number={businessCaseGeneratorStore.hasLoaded ? '17' : '-'}/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Aggregated value of spend"
                             number={businessCaseGeneratorStore.hasLoaded ? toCurrency(p.currencySymbol, 500000, 3) : '-'}/>
                </Grid>
            </Grid>

            <Grid className="ai-opportunity-values-wrapper"
                  container>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year1"
                            className="opp-savings-field"
                            label="Savings in year 1"
                            variant="standard"
                            fullWidth
                            value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable3Year1ExpectedSavings || 0, 3) : '-'}
                            onChange={e => {
                                const v = parseAsCurrency(e.target.value)
                                runInAction(() => businessCaseGeneratorStore.oppTable3Year1ExpectedSavings = v)
                            }}
                            helperText={
                                businessCaseGeneratorStore.hasLoaded
                                    ? expectedSavingsHelperText(
                                        businessCaseGeneratorStore.oppTable3Year1ExpectedSavings,
                                        businessCaseGeneratorStore.oppTable3YearTotalValue
                                    )
                                    : ' '
                            }
                        />
                    </div>
                </Grid>
                <Grid xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year2"
                            className="opp-savings-field"
                            label="Savings in year 2"
                            variant="standard"
                            fullWidth
                            value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable3Year2ExpectedSavings || 0, 3) : '-'}
                            onChange={e => {
                                const v = parseAsCurrency(e.target.value)
                                runInAction(() => businessCaseGeneratorStore.oppTable3Year2ExpectedSavings = v)
                            }}
                            helperText={
                                businessCaseGeneratorStore.hasLoaded
                                    ? expectedSavingsHelperText(
                                        businessCaseGeneratorStore.oppTable3Year2ExpectedSavings,
                                        businessCaseGeneratorStore.oppTable3YearTotalValue
                                    )
                                    : ' '
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year3"
                            className="opp-savings-field"
                            label="Savings in year 3"
                            variant="standard"
                            fullWidth
                            value={businessCaseGeneratorStore.hasLoaded ? toCurrency('', businessCaseGeneratorStore.oppTable3Year3ExpectedSavings || 0, 3) : '-'}
                            onChange={e => {
                                const v = parseAsCurrency(e.target.value)
                                runInAction(() => businessCaseGeneratorStore.oppTable3Year3ExpectedSavings = v)
                            }}
                            helperText={
                                businessCaseGeneratorStore.hasLoaded
                                    ? expectedSavingsHelperText(
                                        businessCaseGeneratorStore.oppTable3Year3ExpectedSavings,
                                        businessCaseGeneratorStore.oppTable3YearTotalValue
                                    )
                                    : ' '
                            }
                        />
                    </div>
                </Grid>

                <Grid xs={4}>
                </Grid>

                <Grid xs={2}>
                    <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                </Grid>
            </Grid>

            <BcrTable
                className="advanced-opportunity-table 3rd-table"
                headers={businessCaseGeneratorStore.OPPORTUNITY_TABLE_HEADERS}
                data={businessCaseGeneratorStore.hasLoaded ? businessCaseGeneratorStore.freqTailSpendData : undefined}
                isLoading={businessCaseGeneratorStore.freqTailSpendLoading}
                onSelect={item => businessCaseGeneratorStore.setBcrSelected(item, !item.selected)}
            />
        </section>}
    </>
});

export function expectedSavingsHelperText(val: number | null, total: number) {
    if (val === null) {
        return '';
    }
    const sVal = toCurrency(stores.p.currencySymbol, val)
    const sTotal = toCurrency(stores.p.currencySymbol, total)
    const vP = val / total;
    return `${sVal} (${Math.round(vP * 100)}% of ${sTotal})`;
}

const RowGrid = ({title, number}) => {
    return <Grid item className={`dashboard-row`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="row-title">
                <Typography variant="h6" component="h6">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="row-subtitle">
                <Typography variant="body1" component="p">
                    {number}
                </Typography>
            </Grid>
        </Grid>
    </Grid>;
}
