import './MergeXDashBoard.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {DashboardHeader} from "../../dashboard/DashboardHeader";
import {MergeXAnalyticsDashboard} from "./analytics/MergeXAnalyticsDashboard";
import {MergeXCleanupDashboard} from "./cleanup/MergeXCleanupDashboard";
import {MergeXMergeDashboard} from "./merge/MergeXMergeDashboard";
import {MergeXMasterDashboard} from "./master/MergeXMasterDashboard";
import {environment} from "../../../env";
import {AppState} from "../../../stores/RootAppStore";
import {MergeXMergeCombinedDashboard} from "./merge/MergeXMergeCombinedDashboard";

export const LANDING_ENVIRONMENT = 'cleanup' as const;

function getDashboardComponent(app: AppState): JSX.Element {
    if (app.dataType === 'master') {
        return <MergeXMasterDashboard/>
    }
    const env = app.dashboardEnvironment || LANDING_ENVIRONMENT;
    switch (env) {
        case 'cleanup':
            return <MergeXCleanupDashboard/>;
        case 'analytics':
            return <MergeXAnalyticsDashboard/>;
        case 'merge':
            if (environment.dashboardVariant === 'simple')
                return <MergeXMergeCombinedDashboard/>;
            return <MergeXMergeDashboard/>;
    }
    console.error('Cannot determine dashboard environment from environment', env);
    return <></>
}

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXDashboard: React.FC = observer(() => {
    const {
        rootAppStore,
        bagStore,
        cmpStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
    } = useStores();
    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore.init(masterBagId);
            baseSupplierNormalizationStore.init(baseBagId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])
    return <>
        <DashboardHeader/>
        {getDashboardComponent(rootAppStore.app)}
    </>
});
