import './AuthorChip.scss'
import React from "react";
import {Avatar, Chip, Tooltip, Typography} from "@mui/material";
import {useStores} from "../../../../stores";
import {UserDisplaySerializer} from "../../../../services/classes/User";
import {observer} from "mobx-react-lite";

type Props = {
    authorId: number
}
export const AuthorChip: React.FC<Props> = observer(({authorId}) => {
    const {authStore} = useStores();

    const user = authStore.allUsers.get(authorId) || null;

    const email = user?.email || '';

    const {color, abbreviation, fullName} = stringAvatar(authorId, user)
    const fgColor = invertColor(color);

    // https://mui.com/material-ui/guides/composition/
    const ChipRoot = React.useMemo(() => React.forwardRef<HTMLDivElement, any>(
        (props, ref) => <div ref={ref} {...props} tabIndex="-1"/>,
    ), []);
    const AvatarRoot = React.useMemo(() => React.forwardRef<HTMLDivElement, any>(
        (props, ref) => <div ref={ref} {...props} style={{color: fgColor}}/>,
    ), [fgColor]);

    if (authStore.allUsers.size === 0) {
        console.warn('Cannot render author chip as there are no users loaded');
        return <></>
    }
    const chip = <Chip
        avatar={<Avatar component={AvatarRoot} sx={{bgcolor: color, color: fgColor}}>{abbreviation}</Avatar>}
        className="author"
        label={fullName}
        color="default"
        variant="outlined"
        size="small"
        component={ChipRoot}
        // sx={{color: fgColor}}
    />;
    if (email && email !== fullName) {
        return <Tooltip
            title={
                <Typography variant="caption">
                    {email}
                </Typography>
            }
            placement="top">
            {chip}
        </Tooltip>
    } else {
        return chip;
    }
})

function stringAvatar(userId: number, user: UserDisplaySerializer | null): {
    color: string,
    abbreviation: string,
    fullName: string,
} {
    if (!user) {
        return {
            color: stringToColor(String(userId)),
            abbreviation: '?',
            fullName: 'Unknown',
        }
    }

    let fullName = user.first_name;
    if (user.last_name) {
        fullName += ' ' + user.last_name;
    }
    if (!fullName) {
        fullName = user.email;
    }
    if (!fullName) {
        return {
            color: stringToColor(String(userId)),
            abbreviation: '?',
            fullName: 'Unknown',
        }
    }

    let abbreviation: string
    if (fullName.includes(' ')) {
        const splitted = fullName.split(' ');
        abbreviation = splitted[0][0] + splitted[1][0];
    } else {
        abbreviation = fullName.substring(0, 2);
    }
    abbreviation = abbreviation.toUpperCase();
    return {
        color: stringToColor(fullName),
        abbreviation,
        fullName,
    }
}

function padZero(str: string, len = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    // ensure a weak hash still performs well on small numbers
    if (string.length < 5) {
        string = string + string + string + string + string
    }

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function invertColor(hex: string, bw = true): string {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    // pad each with zeros and return
    return "#"
        + padZero((255 - r).toString(16))
        + padZero((255 - g).toString(16))
        + padZero((255 - b).toString(16));
}
