import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Table, TableBody, TableContainer, TableRow, Typography} from "@mui/material";
import {FinancialFilters} from "./financialFilters";
import {PerCompanyBar} from "./perCompanyBar";
import {TargetVsActualBar} from "./targetVsActualBar";
import {TargetVsActualVertical} from "./targetVsActualVertical";
import {TargetVsActualDoubleBar} from "./TargetVsActualDoubleBar";
import {useSitStores} from "../../../stores";
import {ColSpec, MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

export const FinancialDashboard: React.FC = observer(() => {
    const {fbStore} = useSitStores();
    const [col_1, setCol1] = useState(0);
    const [col_2, setCol2] = useState(0);
    const [col_3, setCol3] = useState(0);
    const [col_4, setCol4] = useState(0);
    const columns: ColSpec[] = [
        {cls: 'col-cluster', txt: 'Cluster'},
        {cls: 'col-savings_projected', txt: 'Savings Projected'},
        {cls: 'col-cost_avoidance', txt: 'Cost Avoidance'},
        {cls: 'col-savings_realized', txt: 'Savings Realized'},
        {cls: 'col-target', txt: 'Target'},
    ]

    useEffect(() => {
        if (fbStore.targetDataPerCluster && fbStore.targetDataPerCluster.length > 0) {
            setCol1(fbStore.targetDataPerCluster.map(c => c.clusterId).reduce((total, clusterId) => {
                return total + (
                    fbStore.contributionPerClusterCumSumSavings?.find(c => c.clusterId === clusterId)?.data.find(dataPoint => dataPoint.month.getMonth() === 11)?.value ?? 0
                );
            }, 0));
            setCol2(fbStore.targetDataPerCluster.map(c => c.clusterId).reduce((total, clusterId) => {
                return total + (
                    fbStore.contributionPerClusterCumSumAvoidance?.find(c => c.clusterId === clusterId)?.data.find(dataPoint => dataPoint.month.getMonth() === 11)?.value ?? 0
                );
            }, 0));
            setCol3(fbStore.targetDataPerCluster.map(c => c.clusterId).reduce((total, clusterId) => {
                return total + (
                    fbStore.contributionPerClusterSavings
                        ?.find(c => c.clusterId === clusterId)  // Find the cluster with clusterId === 3
                        ?.doiData                       // Get the doiData array
                        .filter(doiEntry => doiEntry.doi === 5)  // Find the entry where the doi is 5
                        ?.reduce((sum, doiEntry) => {   // Sum up all December values within this cluster
                            return sum + doiEntry.data
                                .reduce((doiSum, dataPoint) => doiSum + dataPoint.value, 0);
                        }, 0) ?? 0
                );
            }));
            setCol4(fbStore.targetDataPerCluster.map(c => c.clusterId).reduce((total, clusterId) => {
                return total + (
                    fbStore.targetDataPerCluster
                        ?.find(c => c.clusterId === clusterId)
                        ?.data
                        ?.find(d => d.month.getMonth() === 11)
                        ?.value ?? 0
                );
            }, 0));
        }
    }, [fbStore.targetDataPerCluster]);

    return <div className="financial-dashboard-page">
        <FinancialFilters/>
        <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
                <Typography variant="h3" component="h3" className="dashboard-title">
                    Financial Perspective - Cumulated Actual vs Target
                </Typography>
                <TargetVsActualBar/>
                <TargetVsActualVertical/>

                <Typography variant="h3" component="h3" className="dashboard-title">
                    Cumulated Savings by Company
                </Typography>
                <PerCompanyBar/>

                <Typography variant="h3" component="h3" className="dashboard-title">
                    Procurement Savings Development vs Target
                </Typography>
                <TargetVsActualDoubleBar
                    actualData={fbStore.contributionPerMonth}
                    targetData={fbStore.cumulatedTargetData}
                    height={600}
                />

                <TableContainer className="target-vs-actual-table">
                    <Table>
                        <MithraTableHeadColumns columns={columns}/>
                        <TableBody>
                            {fbStore.targetDataPerCluster?.map(cluster_data => <TableRow key={cluster_data.clusterId}>
                                <MithraTableCell c={columns[0]}>
                                    {cluster_data.clusterTitle}
                                </MithraTableCell>
                                <MithraTableCell c={columns[1]}>
                                    <CurrencyComponent
                                        v={fbStore.contributionPerClusterCumSumSavings?.find(c => c.clusterId === cluster_data.clusterId)?.data.find(dataPoint => dataPoint.month.getMonth() === 11)?.value ?? 0}
                                        nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[2]}>
                                    <CurrencyComponent
                                        v={fbStore.contributionPerClusterCumSumAvoidance?.find(c => c.clusterId === cluster_data.clusterId)?.data.find(dataPoint => dataPoint.month.getMonth() === 11)?.value ?? 0}
                                        nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[3]}>
                                    <CurrencyComponent v={fbStore.contributionPerClusterSavings
                                        ?.find(c => c.clusterId === cluster_data.clusterId)
                                        ?.doiData                       // Get the doiData array
                                        .filter(doiEntry => doiEntry.doi === 5)  // Find the entry where the doi is 5
                                        ?.reduce((sum, doiEntry) => {   // Sum up all December values within this cluster
                                            return sum + doiEntry.data
                                                .reduce((doiSum, dataPoint) => doiSum + dataPoint.value, 0);
                                        }, 0) ?? 0} nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[4]}>
                                    <CurrencyComponent v={fbStore.targetDataPerCluster
                                        ?.find(c => c.clusterId === cluster_data.clusterId)  // Find the entry where the clusterId is 3
                                        ?.data                          // Get the data array
                                        ?.find(d => d.month.getMonth() === 11) // Find the entry where the month is December (month index is 11)
                                        ?.value ?? 0} nDigits={3}/>
                                </MithraTableCell>
                            </TableRow>)}
                            <TableRow style={{fontWeight: 'bold'}}>
                                <MithraTableCell c={columns[0]}>
                                    Grand Total
                                </MithraTableCell>
                                <MithraTableCell c={columns[1]}>
                                    <CurrencyComponent v={col_1} nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[2]}>
                                    <CurrencyComponent v={col_2} nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[3]}>
                                    <CurrencyComponent v={col_3} nDigits={3}/>
                                </MithraTableCell>
                                <MithraTableCell c={columns[4]}>
                                    <CurrencyComponent v={col_4} nDigits={3}/>
                                </MithraTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/*<Grid container>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" align="center" gutterBottom className="dashboard-title"*/}
                {/*                    style={{marginBottom: '2em'}}>*/}
                {/*            Home Solutions*/}
                {/*        </Typography>*/}
                {/*        <TargetVsActualDoubleBar*/}
                {/*            actualData={fbStore.contributionPerClusterTotalSavings?.find(c => c.clusterId === 1)?.data ?? null}*/}
                {/*            targetData={fbStore.targetDataPerCluster?.find(c => c.clusterId === 1)?.data ?? null}*/}
                {/*            height={300}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" align="center" gutterBottom className="dashboard-title"*/}
                {/*                    style={{marginBottom: '2em'}}>*/}
                {/*            Food Service Systems*/}
                {/*        </Typography>*/}
                {/*        <TargetVsActualDoubleBar*/}
                {/*            actualData={fbStore.contributionPerClusterTotalSavings?.find(c => c.clusterId === 2)?.data ?? null}*/}
                {/*            targetData={fbStore.targetDataPerCluster?.find(c => c.clusterId === 2)?.data ?? null}*/}
                {/*            height={300}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" align="center" gutterBottom className="dashboard-title"*/}
                {/*                    style={{marginBottom: '2em'}}>*/}
                {/*            Coffee Systems*/}
                {/*        </Typography>*/}
                {/*        <TargetVsActualDoubleBar*/}
                {/*            actualData={fbStore.contributionPerClusterTotalSavings?.find(c => c.clusterId === 3)?.data ?? null}*/}
                {/*            targetData={fbStore.targetDataPerCluster?.find(c => c.clusterId === 3)?.data ?? null}*/}
                {/*            height={300}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    </div>
});
