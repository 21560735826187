import React from "react";
import {observer} from "mobx-react-lite";
import {TextField} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {useStores} from "../../../../stores";

export const ConfirmSendForApproval: React.FC = observer(() => {
    const {categorizationReviewStore} = useStores();
    const approvalController = categorizationReviewStore.reviewPageController.reviewPageApprovalController;

    return <EditorModalComponent
        titleId={"review-modal-title"}
        className="review-model-component confirm-send-for-approval-component"
        title="Should the categorization be submitted for approval?"
        saveLabel={'Send'}
        canSave={!approvalController.isBusySendForApproval}
        onSave={() => {
            approvalController.sendForApproval(() => {
                // Reload the page when the approval is submitted
                categorizationReviewStore.reloadView(true);
            })
        }}
        isSaving={approvalController.isBusySendForApproval}
        onClose={() => approvalController.setSendForApprovalDialog(false)}
        isError={approvalController.isSendForApprovalError}
        largerErrorMessages
    >
        {/*<Typography variant="body1"></Typography>*/}
        <TextField
            multiline
            fullWidth
            id="review-editor-modal-approval-note-field"
            label="Notes for the reviewer (optional)"
            // error={false}
            // helperText={}
            variant="outlined"
            value={approvalController.approvalNotes}
            onChange={e => approvalController.setApprovalNotes(e.target.value)}
            disabled={approvalController.isBusySendForApproval}
        />
    </EditorModalComponent>
})
