import {single_bar_chart} from "../../../components/visualization/single-barchart/SingleBarChartBuilder";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";

import {PpvLevelStatisticsTreeSerializer} from "../../../services/classes/MaterializedClasses";
import {toCurrency} from "../../../components/currency-component/CurrencyClasses";

export type AiChangeChart = {
    values: single_bar_chart.DataPoint[];
    category: string
    label: string
};

export function convertSingleToChart(node: PpvLevelStatisticsTreeSerializer, currencySymbol: string): AiChangeChart {
    const category = node.label;
    const isUncat = category === UNCATEGORIZED_VALUE;
    const label = isUncat ? UNCATEGORIZED_LABEL : category
    const values = node.values;
    const chartValues: Array<AiChangeChart['values'][number]> = [];
    chartValues.push({
        dataCls: 'old',
        label: `${toCurrency(currencySymbol, values.total_ppv_opportunity)}`,
        value: Math.max(0, values.total_ppv_opportunity),
    })

    chartValues.push({
        dataCls: 'new',
        label: `${toCurrency(currencySymbol, values.total_ppv_opportunity)}`,
        value: Math.max(0, values.total_ppv_opportunity),
    })

    return ({
        category,
        label,
        values: chartValues,
    });
}

export function convertToChart(node: PpvLevelStatisticsTreeSerializer[], currencySymbol: string): AiChangeChart[] {
    const result = node.map(n => convertSingleToChart(n, currencySymbol));
    result.sort((a, b) => b.values[1].value - a.values[1].value)
    return result
}
