import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Grid, Typography} from "@mui/material";
import React from "react";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";

const CAT_FILTER_MODAL_ID = 'ppv-delete-group-modal';
export const CAT_FILTER_MODAL_TITLE_ID = CAT_FILTER_MODAL_ID + '-title';


export const PpvDeleteGroupModal: React.FC = observer(() => {
    const {ppvControllerStore} = useStores();

    return (
        <GenericModalVertical
            className={'ppv-delete-group-modal'}
            title="Delete Group"
            isOpen={ppvControllerStore.isDeleteGroupModalOpen}
            onSave={() => {
                ppvControllerStore.setDeleteGroupModalOpen(false);
                if (ppvControllerStore.ppvGroupToDelete && ppvControllerStore.ppvItemToDelete) {
                    ppvControllerStore.ppvPageController.ppvPageDataController.deletePpvItem(ppvControllerStore.ppvGroupToDelete, ppvControllerStore.ppvItemToDelete)
                }
            }}
            saveDisabled={false}
            saveTitle="Delete"
            // saveColor="error"
            isDanger
            onCancel={() => ppvControllerStore.setDeleteGroupModalOpen(false)}
            onClose={() => ppvControllerStore.setDeleteGroupModalOpen(false)}
        >
            <Grid container spacing={2} alignContent="center" alignItems="center" direction="row" xs={12}>
                <Grid item xs={12}>
                    <Typography style={{marginTop: '1em'}}>
                        By deleting this items you will delete the group as well. Are you sure you want to delete this
                        group?
                    </Typography>
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
});