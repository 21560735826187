import './GoldenRecordModals.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const GOLDEN_RECORD_REMOVE_MODAL_ID = 'golden-record-remove-modal';
export const GOLDEN_RECORD_FILTER_MODAL_TITLE_ID = GOLDEN_RECORD_REMOVE_MODAL_ID + '-title';

export const GoldenRecordRemoveModal: React.FC = observer(() => {
    const {goldenRecordStore} = useStores();
    return <Dialog
        open={goldenRecordStore.isRemoveModalOpen}
        onClose={() => goldenRecordStore.setRemoveModalOpen(false)}
        aria-labelledby={GOLDEN_RECORD_FILTER_MODAL_TITLE_ID}
        className="golden-record-modal golden-record-remove-modal"
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
            Remove transaction
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to remove this transaction from the golden record?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => goldenRecordStore.setRemoveModalOpen(false)}
                variant="text">
                Cancel
            </Button>
            <Button
                color="error"
                onClick={() => {
                    goldenRecordStore.removeSubject?.remove();
                    goldenRecordStore.setRemoveModalOpen(false)
                }}
                variant="contained">
                Remove
            </Button>
        </DialogActions>
    </Dialog>
})