import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {BusinessUnit} from "../../../../services/classes/MaterializedClasses";
import {getBusinessUnitIdHack} from "../../../../stores/hacks/display";

export const CategorizationWelcome: React.FC = observer(() => {
    const {bagStore, categorizationReviewStore, p} = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const keyValues = categorizationReviewStore.reviewPageController.reviewPageStatisticsController.summaryResultKeyValues;

    let className = 'categorization-welcome-page'
    if (p.p.disableBuFilter) {
        className = `${className} button-only`
    } else {
    }

    const continueButton =
        <Button
            id="continue-categorization-btn"
            color="primary"
            variant="outlined"
            endIcon={<ChevronRight/>}
            onClick={() => reviewPageController.reviewPageStateController.onClickNext()}>
            Continue<br/>
            review
        </Button>
    return <div className={className}>
        <Grid className="key-value-container wide-margin text-center font-bigger" container justifyContent="center">
            <Grid item>
                <Typography variant="caption">
                    Uncategorized
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value value-warning">
                    {keyValues
                        ? <CurrencyComponent
                            v={keyValues.after.unclassified_spend}
                            nDigits={3}
                            abbreviation={keyValues.abbreviation}
                            hideAlmostZero={true}
                        />
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
        </Grid>

        {bagStore.bu.businessUnitOptions && bagStore.bu.businessUnitOptions.length > 13 &&
            <Grid container justifyContent="center" style={{marginBottom: '3em'}}>
                <Grid item>
                    {continueButton}
                </Grid>
            </Grid>
        }

        {!p.p.disableBuFilter && bagStore.bu.businessUnitOptions &&
            <Grid container justifyContent="center" className="mb-3 mt-3">
                <Grid item>
                    <BusinessUnitSelector/>
                </Grid>
            </Grid>
        }

        <Grid container justifyContent="center" className="mb-3 mt-3">
            <Grid item>
                {continueButton}
            </Grid>
        </Grid>
    </div>
});


export const BusinessUnitSelector: React.FC = observer(() => {
    const {p, bagStore} = useStores();
    return <FormControl component="fieldset">
        <FormLabel component="legend">{p.p.bu_col_name} filter</FormLabel>
        <RadioGroup aria-label="business unit" name="business_unit"
                    value={bagStore.bu.selectedBusinessUnitOption.id || -1}
                    onChange={(e) => bagStore.bu.setSelectedBusinessUnitId(Number(e.target.value))}>
            {bagStore.bu.businessUnitOptions?.map(b =>
                <FormControlLabel
                    key={b.id}
                    value={b.id}
                    control={<Radio/>}
                    label={b.bu_name + (
                        (p.p.showBuWithId && b.id > 0) ? ` (${getBusinessUnitIdHack(b as BusinessUnit)})` : ''
                    )}
                />
            )}
        </RadioGroup>
    </FormControl>
})
