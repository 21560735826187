import React from "react";
import {observer} from "mobx-react-lite";
import {
    Alert,
    Autocomplete, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import {useSitStores} from "../../../../stores";
import {Category, category_readable_str, Cluster, Company, Division} from "../../../ApiTypes";

export const InitiativeScopeForm: React.FC = observer(() => {
    const {ccStore, initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        {initiativeInstanceStore.w_saving_split_removed_companies && <Grid item xs={12}>
            <Alert severity="warning" color="error">
                Initiative savings split has companies that are not in scope:&nbsp;
                {initiativeInstanceStore.w_saving_split_removed_companies.map((c_id) => ccStore.getCompanyTitle(c_id)).join(', ')}
            </Alert>
        </Grid>}
        {initiativeInstanceStore.w_saving_split_removed_categories && <Grid item xs={12}>
            <Alert severity="warning" color="error">
                Initiative savings split has categories that are not in scope:&nbsp;
                {initiativeInstanceStore.w_saving_split_removed_categories.map((c_id) => ccStore.getCategoryReadableStr(c_id)).join(', ')}
            </Alert>
        </Grid>}

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <Autocomplete
                    disabled={initiativeInstanceStore.disabled}
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="company"
                    limitTags={1}
                    options={ccStore.companyRM.result ?? ([] as Company[])}
                    getOptionLabel={(option) => option.title}
                    value={initiativeInstanceStore.f_c[0].companyValues}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Company"
                            required
                            placeholder="Select Company"
                            inputProps={{
                                ...params.inputProps,
                                required: initiativeInstanceStore.f_c[0].companyValues.length === 0
                            }}/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={(initiativeInstanceStore.f_c[0].instance.companies?.indexOf(option.id) ?? -1) > -1}/>
                            {option.title}
                        </li>}
                    onChange={(_event, value) =>
                        initiativeInstanceStore.f_c[0].companies = value.map((company) => company.id)
                    }/>
            </FormControl>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <Autocomplete
                    disabled={initiativeInstanceStore.disabled}
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="cluster"
                    limitTags={1}
                    options={ccStore.clusterRM.result ?? ([] as Cluster[])}
                    getOptionLabel={(option) => option.title}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={initiativeInstanceStore.f_c[0].combinedClusters}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Cluster"
                                   placeholder="Select Cluster"/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={initiativeInstanceStore.f_c[0].isCheckedClusters(option.id)}
                                indeterminate={initiativeInstanceStore.f_c[0].isIndeterminateCluster(option.id)}
                            />
                            {option.title}
                        </li>}
                    onChange={(_event, value, reason) =>
                        initiativeInstanceStore.f_c[0].setClusters(value, reason === 'removeOption')
                    }/>
            </FormControl>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <Autocomplete
                    disabled={initiativeInstanceStore.disabled}
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="division"
                    limitTags={1}
                    options={ccStore.divisionRM.result ?? ([] as Division[])}
                    getOptionLabel={(option) => option.title}
                    value={initiativeInstanceStore.f_c[0].divisionValues}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Division"
                            required
                            placeholder="Select Division"
                            inputProps={{
                                ...params.inputProps,
                                required: initiativeInstanceStore.f_c[0].divisionValues.length === 0
                            }}/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={(initiativeInstanceStore.f_c[0].instance.divisions?.indexOf(option.id) ?? -1) > -1}/>
                            {option.title}
                        </li>}
                    onChange={(_event, value) =>
                        initiativeInstanceStore.f_c[0].divisions = value.map((division) => division.id)
                    }/>
            </FormControl>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <InputLabel id="scope-label">Scope</InputLabel>
                <Select
                    disabled={initiativeInstanceStore.disabled}
                    labelId="scope-label"
                    id="scope"
                    value={initiativeInstanceStore.instance.local ? 1 : 2}
                    label="Scope"
                    onChange={(event) => {
                        if (typeof event.target.value === 'number')
                            initiativeInstanceStore.local = (event.target.value === 1);
                    }}>
                    <MenuItem value={1}>Local</MenuItem>
                    <MenuItem value={2}>Global</MenuItem>
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <Autocomplete
                    disabled={initiativeInstanceStore.disabled}
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="category"
                    limitTags={1}
                    options={ccStore.categoryRM.result ?? ([] as Category[])}
                    getOptionLabel={(option) => category_readable_str(option)}
                    value={initiativeInstanceStore.f_c[0].categoryValues}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Category"
                            required
                            placeholder="Select Category"
                            inputProps={{
                                ...params.inputProps,
                                required: initiativeInstanceStore.f_c[0].categoryValues.length === 0
                            }}/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={(initiativeInstanceStore.f_c[0].instance.categories?.indexOf(option.id) ?? -1) > -1}/>
                            {category_readable_str(option)}
                        </li>}
                    onChange={(_event, value) =>
                        initiativeInstanceStore.f_c[0].categories = value.map((category) => category.id)
                    }/>
            </FormControl>
        </Grid>
    </Grid>
});