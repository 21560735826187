import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {ApprovalTypeEnum} from "../../../services/classes/AiClasses";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import moment from "moment";
import {TaxonomyApprovalComponent} from "./TaxonomyApprovalComponent";


export const ApprovalTaxonomyPage: React.FC = observer(() => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {approvalStore} = useStores();

    // The magic that loads the data
    useEffect(() => approvalStore.onLoadApprovalPage(ApprovalTypeEnum.TAXONOMY, params, navigate),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const a = approvalStore.taxonomyApprovalRequest;
    const dateStr = moment(a?.current_status.timestamp).format('L LT')
    return <KoiFacade
        title="Review Taxonomy approval"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<Typography>
            {a && (
                `${ApprovalStore.showRequestType(a.request_type)} ―
                 ${a.created_by.first_name} ${a.created_by.last_name} ―
                 ${dateStr}`
            )}
        </Typography>}
        onBack={() => {
            approvalStore.taxonomyApproval.ensureApprovalNotesSaved().then(() => {
                navigate(routes.approval);
            });
        }}
        className="approval-page taxonomy-approval-page"
    >
        <TaxonomyApprovalComponent/>
    </KoiFacade>
})
