import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Checkbox, Dialog, Grid, TextField} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";

const GOLDEN_RECORD_FILTER_MODAL_ID = 'golden-record-filter-modal';
export const GOLDEN_RECORD_FILTER_MODAL_TITLE_ID = GOLDEN_RECORD_FILTER_MODAL_ID + '-title';

type Props = {}
/**
 * Taken from CategorizationFilterModal
 */
export const GoldenRecordFilterModal: React.FC<Props> = observer(() => {
    const {goldenRecordStore} = useStores();

    if (!goldenRecordStore.filterController.isFilterModalOpen) return <></>

    return <Dialog
        open={goldenRecordStore.filterController.isFilterModalOpen}
        onClose={() => {
            goldenRecordStore.filterController.setIsFilterModalOpen(false);
        }}
        aria-labelledby={GOLDEN_RECORD_FILTER_MODAL_TITLE_ID}
        maxWidth="lg"
    >
        <EditorModalComponent
            titleId={GOLDEN_RECORD_FILTER_MODAL_TITLE_ID}
            className="golden-record-filter-modal"
            title="Filter"
            saveLabel="Apply"
            canSave={true}
            onSave={() => {
                goldenRecordStore.filterController.setIsFilterModalOpen(false);
            }}
            onClose={() => {
                goldenRecordStore.filterController.setIsFilterModalOpen(false);
            }}
        >
            <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
                <Grid item xs="auto" className="checkbox-width">
                    <Checkbox
                        className="mb-4"
                        checked={true}
                        // onChange={() => reviewPageFilterController.toggleScoreFilterFieldEnabled()}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        id={GOLDEN_RECORD_FILTER_MODAL_ID + '-score-field-min'}
                        label="Minimal score"
                        // error={reviewPageFilterController.scoreLowFilterFieldError !== ''}
                        // helperText={reviewPageFilterController.scoreLowFilterFieldError || ' '}
                        variant="outlined"
                        // value={reviewPageFilterController.scoreLowFilterField}
                        // onChange={e => reviewPageFilterController.setScoreLowFilterField(e.target.value)}
                        // onClick={() => reviewPageFilterController.setScoreFilterFieldEnabled()}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        id={GOLDEN_RECORD_FILTER_MODAL_ID + '-score-field-max'}
                        label="Maximal score"
                        // error={reviewPageFilterController.scoreHighFilterFieldError !== ''}
                        // helperText={reviewPageFilterController.scoreHighFilterFieldError || ' '}
                        variant="outlined"
                        // value={reviewPageFilterController.scoreHighFilterField}
                        // onChange={e => reviewPageFilterController.setScoreHighFilterField(e.target.value)}
                        // onClick={() => reviewPageFilterController.setScoreFilterFieldEnabled()}
                    />
                </Grid>
            </Grid>
        </EditorModalComponent>
    </Dialog>
})