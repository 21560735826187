import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, CircularProgress, Grid} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {routes} from "../../routing/routes";
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {generatePath, useNavigate} from "react-router-dom";

type Props = {
    defaultPage: "taxonomy_upload" | "taxonomy_mapping" | "taxonomy_check" | "taxonomy_finish"
}
export const TaxonomyIngestionStepper: React.FC<Props> = observer(({defaultPage}) => {
    const {taxonomyIngestionStore, p} = useStores();
    const navigate = useNavigate();


    useEffect(() => {
    }, [taxonomyIngestionStore, p])
    const [isLoading, setIsLoading] = useState(false);


    const page = taxonomyIngestionStore.page || defaultPage;
    return <div className="taxonomy-ingestion-stepper">
        <Grid container justifyContent="space-arround" style={{marginTop: "2em"}}>

            <Grid item xs={2} className="text-center align-with-stepper">
                {page === 'taxonomy_upload' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            navigate(generatePath(routes.taxonomy_ingestion))
                            taxonomyIngestionStore.resetIngestionStore();
                        }}>
                        Back
                    </Button>
                }
                {page === 'taxonomy_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            navigate(generatePath(routes.taxonomy_ingestion))
                            taxonomyIngestionStore.resetIngestionStore();
                        }}>
                        Back
                    </Button>
                }
                {page === 'taxonomy_check' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            taxonomyIngestionStore.navigateToPage('taxonomy_mapping')
                        }}
                        //FIXME: (FINE FOR NOW) for now we use the naviateToPage method, but we should use the navigate method with the dataFileId
                        // onClick={() =>  navigate(generatePath(routes.taxonomy_upload_mapping, {dataFileId: String(taxonomyIngestionStore.dataFileId)}))}
                    >
                        Back
                    </Button>
                }
            </Grid>

            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={taxonomyIngestionStore.step}>
                    <Step>
                        <StepLabel>Add file</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Data mapping</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Check</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Finish</StepLabel>
                    </Step>
                </Stepper>
            </Grid>

            <Grid item xs={2} className="text-center">
                {page === 'taxonomy_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={!taxonomyIngestionStore.all_required_columns_mapped || isLoading}
                        onClick={async () => {
                            setIsLoading(true);
                            try {
                                taxonomyIngestionStore.setErrorMsg('');
                                await taxonomyIngestionStore.postTaxonomyMappingsList();
                                taxonomyIngestionStore.navigateToPage('taxonomy_check')
                            } catch (error) {
                                // According to API endpoints, this should not happen
                                console.error(error);
                                taxonomyIngestionStore.setErrorMsg('Something went wrong');
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    >
                        {isLoading ? <CircularProgress size={24}/> : 'Next'}
                    </Button>
                }

                {page === 'taxonomy_check' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        onClick={() => {
                            setIsLoading(true);
                            taxonomyIngestionStore.startTaxonomyMerge()
                                .then(() => {
                                    taxonomyIngestionStore.navigateToPage('taxonomy_finish')
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                })
                        }}
                    >
                        {isLoading ? <CircularProgress size={24}/> : 'Next'}
                    </Button>
                }
            </Grid>
        </Grid>
    </div>
});
