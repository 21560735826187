import {observer} from "mobx-react-lite";
import {Alert, Button, Skeleton} from "@mui/material";
import React from "react";
import {useStores} from "../../../stores";
import {RiskAssessmentTable} from "./RiskAssessmentTable";
import {BcrHeader} from "./BcrHeader";
import {ModalNewRiskFactor} from "./modals/ModalNewRiskFactor";
import {ModalEditRiskFactor} from "./modals/ModalEditRiskFactor";
import {FakeLoader} from "./FakeLoader";
import {AutoAwesome} from "@mui/icons-material";


type Props = {}
export const RiskAssessmentComponent: React.FC<Props> = observer(() => {
    const {businessCaseGeneratorStore} = useStores();
    const [isLoading, setIsLoading] = React.useState(false);

    // useEffect(() => {
    //     if(!businessCaseGeneratorStore.risksHaveLoaded && !isLoading) {
    //         setIsLoading(true);
    //     }
    // }, [businessCaseGeneratorStore.risksHaveLoaded, isLoading]);

    // useEffect(() => {
    //     setIsLoading(false);
    //     businessCaseGeneratorStore.risksHaveLoaded = false
    // }, [businessCaseGeneratorStore.risksHaveLoaded]);

    return <section className="risk-assessment-component">
        {isLoading && <FakeLoader
            step1Name={'Searching data sources'}
            step1Size={10}
            step2Name={'Validating risks'}
            step2Size={22}
            step3Name={'Ranking results'}
            totalTimeMs={12000}
            onCompleted={() => {
                setIsLoading(false);
                businessCaseGeneratorStore.setRisksHaveLoaded();
            }}
        />}

        {/*<Dialog*/}
        {/*    open={businessCaseGeneratorStore.isEditRiskFactorModalOpen}*/}
        {/*    onClose={() => businessCaseGeneratorStore.isEditRiskFactorModalOpen = false}*/}
        {/*    aria-labelledby={"taxonomy-editor-modal-title"}*/}
        {/*    maxWidth="lg"*/}
        {/*>*/}
        {/*    {businessCaseGeneratorStore.isEditRiskFactorModalOpen && <ModalEditRiskFactor/>}*/}
        {/*</Dialog>*/}
        {businessCaseGeneratorStore.isEditRiskFactorModalOpen &&
            <ModalEditRiskFactor data={businessCaseGeneratorStore.itemToEdit}/>}
        <ModalNewRiskFactor/>

        <BcrHeader/>

        {(!businessCaseGeneratorStore.risksHaveLoaded) && <Button
            style={{
                textTransform: 'none',
                justifyContent: 'center',
                display: 'flex',
                margin: 'auto',
                minWidth: '30px',
                minHeight: '30px'
            }}
            color="primary"
            onClick={() => {
                window.scrollTo(0, 0)
                setIsLoading(true)
            }}
            startIcon={<AutoAwesome/>}
            variant="contained"
        >
            AI assistant
        </Button>}


        {(businessCaseGeneratorStore.risksHaveLoaded) && <div className="general-infos">
            <Alert severity="info">
                Please list and asses key risks that might imped this business case to be fully realized.
                These risks could be both internal and external.
            </Alert>
        </div>}

        <section className="risk-assesment-section">
            {/*<RiskAssessmentTable isEditable={true} fakeShowLoading={isLoading} data={ businessCaseGeneratorStore.risksHaveLoaded ? businessCaseGeneratorStore.riskFactorData : undefined}/>*/}

            {isLoading ? (
                // Show skeleton loader while data is loading
                <Skeleton variant="rectangular" height={500} animation="wave"/>
            ) : (
                // Show RiskAssessmentTable when data has loaded
                <RiskAssessmentTable
                    isEditable={true}
                    fakeShowLoading={isLoading}
                    data={businessCaseGeneratorStore.risksHaveLoaded ? businessCaseGeneratorStore.riskFactorData : undefined}
                />
            )}
        </section>

    </section>
});
