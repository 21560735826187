import {DashboardTypeItem, DashboardTypeMenu} from "../../../components/dashboard/DashboardTypeMenu";
import {DashboardEnvironmentV3} from "../../../stores/RootAppStore";
import {CmpDataBagRouteMatch, DatabagRouteMatch} from "../../../routing/routes";
import {generatePath} from "react-router";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {environment} from "../../../env";
import {generateDashboardPath, lookupHomePath} from "../../../routing/routing.utils";

type ItemKey = DashboardEnvironmentV3 | 'master'
type Item = DashboardTypeItem<ItemKey> & {
    // route: string,
}
const ITEMS: Item[] =
    environment.dashboardVariant === 'expanded' ? [
        {key: 'cleanup', label: 'Cleanup',},
        {key: 'analytics', label: 'Analytics',},
        {key: 'merge', label: 'Merge',},
        {key: 'master', label: 'Master',},
    ] : [
        {key: 'cleanup', label: 'Cleanup',},
        {key: 'analytics', label: 'Analytics',},
        {key: 'merge', label: 'Synergy',},
    ]

export const DashboardSwitcher: React.FC = observer(() => {
    const {p, rootAppStore, bagStore} = useStores();
    const params = useParams<CmpDataBagRouteMatch | DatabagRouteMatch | {}>();
    const location = useLocation();
    const navigate = useNavigate();
    let selectedKey: ItemKey;

    if (rootAppStore.app.dataType === 'master') {
        selectedKey = 'master';
    } else {
        if (!rootAppStore.app.dashboardEnvironment) {
            // console.error('Missing dashboardEnvironment in rootAppStore', rootAppStore.app.dashboardEnvironment);
            selectedKey = 'cleanup' // Some kind of fallback needs to be chosen
        } else {
            selectedKey = rootAppStore.app.dashboardEnvironment;
        }
    }

    return <DashboardTypeMenu
        items={ITEMS}
        selectedKey={selectedKey}
        onSelect={(key) => {
            const env = key as ItemKey;
            if (env !== 'master') {
                // Update the dropdown
                rootAppStore.setDashboardEnvironment(env)
            }

            if ('id2' in params || 'id' in params) {
                // If some ID is present, keep the selection in the URL
                navigate(generateDashboardPath(location, params, rootAppStore.app));
                return;
            }

            if (env !== 'merge' && env !== 'master') {
                // Case: change the dashboard, before a bag is selected
                // Only a redirection can be done for the merge and master cases
                return
            }

            // If no ID is present and we are going to a synergy / master dashboard just pick the first bag
            const bagId = bagStore.userBagDefaultOption?.id;
            if (!bagId) {
                console.warn('Cannot determine which bag to select for the dashboard, cannot switch dashboard')
                return;
            }

            const home = lookupHomePath(location, rootAppStore.app);
            navigate(generatePath(home, {id: String(bagId)}));
        }}
    />
})
