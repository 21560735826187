import React from "react";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../../routing/routes";
import {lookupHomePath} from "../../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../../components/main/MenuItems";

export const SalesDemoCleanupMainMenu: React.FC = () => {
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const hasId2 = params['id2'] !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, params) : routes.home,
            active: location.pathname === routes.home || location.pathname === routes.job_manage,
            className: '',
        },
        {
            label: 'Cleanup',
            link: hasId1 ? generatePath(routes.demo_cleanup_dashboard, params) : routes.home,
            active: (
                location.pathname === routes.home || location.pathname === routes.demo_cleanup_dashboard
                || location.pathname === routes.demo_cleanup_supplier_normalization
                || location.pathname === routes.demo_cleanup_categorization
                || location.pathname === routes.demo_cleanup_taxonomy_builder
                || location.pathname === routes.demo_cleanup_taxonomy_builder_id
            ),
            className: '',
        },
        {
            label: 'Analytics',
            link: hasId1 ? generatePath(routes.demo_cleanup_analytics_dashboard, params) : routes.home,
            active: (
                location.pathname === routes.demo_cleanup_analytics_dashboard
                || location.pathname === routes.demo_cleanup_analytics_koi_ss
                || location.pathname === routes.demo_cleanup_analytics_koi_kdf
                || location.pathname === routes.demo_cleanup_analytics_koi_ppv
                || location.pathname === routes.demo_cleanup_analytics_koi_sc
                || location.pathname === routes.demo_cleanup_analytics_adv_dashboard
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_cleanup_opportunities, params) : routes.home,
            active: !isHome && location.pathname === routes.demo_cleanup_opportunities,
            isTrial: false,
            className: 'menu-item-2nd-last',
        },
        // {
        //     label: 'Report',
        //     link: hasId1 ? generatePath(routes.job_report, params) : routes.home,
        //     active: !isHome && location.pathname === routes.job_report,
        //     isTrial: true,
        //     className: 'menu-item-last',
        // },
    ]
    return <MenuItems className="full-main-menu" items={menu}/>
}
