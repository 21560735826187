import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {PageResponse} from "../../services/ApiTypes";
import {makeAutoObservable} from "mobx";
import {Category, Company, FirstPageKpis, Initiative} from "../ApiTypes";
import AuthStore from "../../stores/AuthStore";

export class InitiativeListStore {
    // filters
    search: string = '';
    my_initiatives: number = 0;
    categories: Category[] = [];
    companies: Company[] = [];
    doi: number = 0;
    per_page = 10;
    page = 0;
    ordering = '';
    approval_per_page = 10;
    approval_page = 0;
    // Request managers
    readonly initiativeListRM = new AxoisRequestManager<{ filters: string[][] }, PageResponse<Initiative>>(
        ({filters}) => from(this.api.listInitiatives(filters)));
    readonly initiativeKpiRM = new AxoisRequestManager<{ filters: string[][] }, FirstPageKpis>(
        ({filters}) => from(this.api.firstPageKpis(filters)));
    readonly initiativeApprovalListRM = new AxoisRequestManager<{ filters: string[][] }, PageResponse<Initiative>>(
        ({filters}) => from(this.api.listInitiativesApproval(filters)));

    constructor(private api: SitApi, private authStore: AuthStore) {
        makeAutoObservable(this, {});
    }

    reloadData(onlyIfEmpty = false) {
        if (onlyIfEmpty && (this.initiativeKpiRM.result !== undefined || this.initiativeKpiRM.busy))
            return;
        const filters: [string, string][] = [];
        if (this.search.trim().length > 0) filters.push(['search', this.search.trim()]);
        if (this.my_initiatives && this.authStore.user?.sit_user_profile?.id) filters.push(['owner', String(this.authStore.user.sit_user_profile.id)]);
        if (this.companies.length > 0) filters.push(['financial__companies__id__in', String(this.companies.map(c => c.id))])
        if (this.categories.length > 0) filters.push(['financial__categories__id__in', String(this.categories.map(c => c.id))])
        if (this.doi > 0) filters.push(['doi_status', String(this.doi)]);
        this.initiativeKpiRM.request({filters});
        filters.push(['page_size', String(this.per_page)])
        if (this.page > 0) filters.push(['page', String(this.page + 1)]);
        if (this.ordering !== '') filters.push(['ordering', this.ordering]);
        this.initiativeListRM.request({filters});
    }

    reloadApprovalData(onlyIfEmpty = false) {
        if (onlyIfEmpty && (this.initiativeApprovalListRM.result !== undefined || this.initiativeApprovalListRM.busy))
            return;
        const filters: [string, string][] = [];
        filters.push(['page_size', String(this.approval_per_page)])
        if (this.approval_page > 0) filters.push(['page', String(this.approval_page + 1)]);
        this.initiativeApprovalListRM.request({filters});
    }

    setSearch(value: string) {
        this.search = value;
        this.reloadData();
    }

    setMyInitiatives(value: number) {
        this.my_initiatives = value;
        this.reloadData();
    }

    setCategories(value: Category[]) {
        this.categories = value;
        this.reloadData();
    }

    setCompanies(value: Company[]) {
        this.companies = value;
        this.reloadData();
    }

    setDoi(value: number) {
        this.doi = value;
        this.reloadData();
    }

    setPerPage(value: number) {
        if (value > 0) {
            this.per_page = value;
            this.page = 0;
            this.reloadData();
        }
    }

    setPage(value: number) {
        if (value >= 0) {
            this.page = value;
            this.reloadData();
        }
    }

    setOrdering(value: string) {
        this.ordering = value;
        this.reloadData();
    }

    setApprovalPerPage(value: number) {
        if (value > 0) {
            this.approval_per_page = value;
            this.approval_page = 0;
            this.reloadApprovalData();
        }
    }

    setApprovalPage(value: number) {
        if (value >= 0) {
            this.approval_page = value;
            this.reloadApprovalData();
        }
    }
}