import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Checkbox, Chip, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../../components/categorization/PartCategorizationComponent";
import {PartReCategorizationComponent} from "../../../../../components/categorization/PartReCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {environment} from "../../../../../env";
import {ReviewChoice} from "../../../../../services/classes/MaterializedClasses";
import {getSpecificCategorization} from "../../../../../services/classes/MatReviewHelpers";
import {getCatReviewSubColumns} from "../columns";
import {MithraTableCell} from "../../../../../components/table/MithraTableCell";
import {SingleReviewButtons} from "../../approval-acc-rej/SingleReviewButtons";
import {GoogleLink} from "../../../../../components/_tiny/GoogleLink";
import {AiScore} from "../../../../../components/_tiny/AiScore";
import moment from "moment/moment";
import {PartRowState} from "../../../classes/PartRowState";
import {getCategorizationStateClassName} from "../../../classes/CategorizationUtils";
import {MithraTooltip} from "../../tooltip/MithraTooltip";
import {SingleReviewLinkButton} from "../../approval-acc-rej/SingleReviewLinkButton";


type Props = {
    part: PartRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const GroupedCategorizationReviewSubRow: React.FC<Props> = observer(({part, controller}) => {
    const {
        p,
        authStore,
        bagStore,
        categorizationReviewStore,
    } = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const selectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const filterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const viewOnly = categorizationReviewStore.viewOnly;
    const COLUMNS = getCatReviewSubColumns(p, categorizationReviewStore.bagId, false, viewOnly);

    const combinedStateVisual = part.combinedStateVisual;
    const editAllowed = !viewOnly && combinedStateVisual.editAllowed;

    // FIXME: taxonomySize is coming from the bagStore, not sure what is the intended behavior
    const taxonomySize = bagStore.taxonomy_size;
    const leftCats = getSpecificCategorization(part.data, taxonomySize, combinedStateVisual.leftCat);
    const rightCats = getSpecificCategorization(part.data, taxonomySize, combinedStateVisual.rightCat);

    let categoryComponent;
    let onAccept: undefined | (() => void) = undefined;
    if (!editAllowed) {
        categoryComponent = <PartCategorizationComponent cats={rightCats} isEditAllowed={editAllowed}/>
    } else {
        onAccept = () => reviewPageController.onClickAcceptRejectPart(part, ReviewChoice.ACCEPT);
        if (categorizationReviewStore.categories.taxonomy_categories) {
            categoryComponent = <PartReCategorizationComponent
                cats={rightCats}
                handleOpenSearch={event => {
                    if (!controller) {
                        console.error('No controller set');
                        return;
                    }
                    const anchor = event.currentTarget;

                    // When the category component is opened, reset the selection to avoid confusion
                    reviewPageSelectionController.onDeselectAll();

                    controller.setOnSelect((newCats) => reviewPageController.onClickReCategorizePart(part, newCats))
                    controller.open(anchor)
                }}
                selectedL1Category={filterController.selectedL1Category}
            />
        } else {
            categoryComponent = <PartCategorizationComponent cats={rightCats} isEditAllowed={editAllowed}/>
        }
    }
    let i = 0;
    return <TableRow>
        {!viewOnly && <MithraTableCell c={COLUMNS[i++]} component="th" scope="row">
            <Checkbox
                checked={part.selected}
                onClick={event => selectionController.onClickToggleSelectionSubRow(part, event.shiftKey)}
            />
        </MithraTableCell>}
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.p_spend} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {moment(part.data.p_ddate_1).format('L')}
        </MithraTableCell>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="part" objs={[part.data]}>
                    {part.data.p_description}
                </GoogleLink>
            </MithraTableCell>
        }

        {p.partContextColNames.map(({dataField, colType}) =>
            <MithraTableCell key={dataField} c={COLUMNS[i++]}>
                {colType === 'date'
                    ? moment(part.data[dataField]).format('L')
                    : String(part.data[dataField])}
            </MithraTableCell>
        )}

        {p.p.categorizationReviewSubRowRelationData?.flatMap(related_data_item =>
            related_data_item.columns.filter(c => c.colSpec).map(column =>
                <MithraTableCell c={COLUMNS[i++]} key={related_data_item.relation + column.db_column}>
                    {part.data[related_data_item.relation]
                        ? String(part.data[related_data_item.relation][column.db_column])
                        : '-'
                    }
                </MithraTableCell>
            )
        )}

        {p.p.show_bu_name_in_review_pages && <MithraTableCell c={COLUMNS[i++]}>
            {part.data.bu_name}
        </MithraTableCell>}
        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={leftCats} isEditAllowed={editAllowed}/>
        </MithraTableCell>
        {p.showCategorizationScoreForBag(categorizationReviewStore.bagId) &&
            <MithraTableCell c={COLUMNS[i++]}>
                <AiScore score={part.data.p_suggestion_score}/>
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            {categoryComponent}

            {environment.isTestReviewPage && <>
                <br/>
                p_id=<b>{JSON.stringify(part.data.p_id)}</b> |
                review_user_id=<b>{JSON.stringify(part.data.review_user_id)}</b> ({'me=' + authStore.userId}) |
                review_choice=<b>{JSON.stringify(part.data.review_choice)}</b> |
                p_review_cat_l1=<b>{JSON.stringify(part.data.p_review_cat_l1)}</b> |
                p_suggested_cat_l1=<b>{JSON.stringify(part.data.p_suggested_cat_l1)}</b> |
                approval_applied=<b>{JSON.stringify(part.data.approval_applied)}</b> |
                approval=<b>{JSON.stringify(part.data.approval)}</b> |
                feedback_choice=<b>{JSON.stringify(part.data.feedback_choice)}</b> |
                {/*isLastSelected=<b>{'' + part.isLastSelected}</b>*/}
            </>}
        </MithraTableCell>
        {p.p.showReasonColumn &&
            <MithraTableCell c={COLUMNS[i++]}>
                {part.data.reasoning &&
                    <MithraTooltip>{part.data.reasoning}</MithraTooltip>
                }
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            <Chip label={combinedStateVisual.approvalState.replace('_', ' ')}
                  className={'chip ' + getCategorizationStateClassName(combinedStateVisual.approvalState)}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {
                categorizationReviewStore.reviewPageController.isMergedBag && !editAllowed
                    ? <SingleReviewLinkButton state={combinedStateVisual} part={part} />
                    : rightCats !== false && rightCats.every(cat => cat === '')
                        ? null
                        : (
                            // part.combinedStateLoading ?
                            //     <CircularProgress size={20}/>
                            //     : <SingleReviewButtons onAccept={onAccept} state={combinedStateVisual}/>
                            <SingleReviewButtons onAccept={onAccept} state={combinedStateVisual} editAllowed={editAllowed}
                                                 part={part}/>
                        )
            }
        </MithraTableCell>
    </TableRow>
})
