import React, {useRef, useState} from 'react';
import {Badge, Box, Button, IconButton, List, ListItem} from '@mui/material';
import ChatController from "./ChatController";
import {TextChatMessage} from "./message/TextChatMessage";
import {SmallTableChatMessage} from "./message/SmallTableChatMessage";
import {SimpleChartChatMessage} from "./message/SimpleChartChatMessage";
import {ChatInput} from "./ChatInput";
import {observer} from "mobx-react-lite";
import {Ellipsis} from "react-bootstrap/PageItem";
import {AddCircle} from "@mui/icons-material";
import {ChatOpportunitiesModal} from "./opportunity/ChatOpportunityModal";
import {MessageElement} from "./ChatTypes";
import {useStores} from "../../stores";

export const ChatComponent: React.FC = observer(() => {
    const {p} = useStores();
    const [inputValue, setInputValue] = useState<string>('');
    const inputV = inputValue.trim();
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    };

    const chatRef = useRef<HTMLUListElement>(null);
    const scrollToEnd = () => {
        chatRef.current?.scrollTo({top: chatRef.current.scrollHeight, behavior: 'smooth'});
    };

    const inputRef = useRef<HTMLInputElement>(null);
    // const setFocus = () => {
    //     inputRef.current?.focus();
    // }

    const handleSend = () => {
        if (inputV) {
            ChatController
                .onSendUserMessage(inputV)
                .finally(scrollToEnd);
            setInputValue('');
            setTimeout(scrollToEnd, 0);
        }
    }

    // const modalRef = useRef<HTMLDivElement | null>(null);
    // useEffect(() => {
    //     if (modalRef.current) {
    //         const resizeObserver = new ResizeObserver(() => {
    //             setChatHeight(modalRef.current?.offsetHeight ?? 0);
    //         });
    //
    //         resizeObserver.observe(modalRef.current);
    //
    //         return () => resizeObserver.disconnect();
    //     }
    // }, [ChatController.overlayOpened]);
    const [createOpportunityFrom, setCreateOpportunityFrom] = useState<MessageElement | null>(null);

    return <>
        {p.p.enableChatOpportunityDemo &&
            <ChatOpportunitiesModal
                createOpportunityFrom={createOpportunityFrom}
                handleClose={() => setCreateOpportunityFrom(null)}
            />
        }
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <List
                sx={{overflowY: 'auto', flex: 1, p: 1}}
                ref={chatRef}
            >
                {ChatController.history.map((message, index) => <React.Fragment key={index}>
                    {/*msg={'' + message.from}*/}
                    {message.elements.map((element, idx) => <React.Fragment key={idx}>
                        {/*el={'' + element.type}*/}
                        <ChatMessage key={index} isUser={message.from === 'user'}>
                            <Box key={idx} sx={{marginBottom: idx !== message.elements.length - 1 ? 2 : 0}}>
                                {(() => {
                                    switch (element.type) {
                                        case 'text':
                                            return <TextChatMessage msg={element}/>;
                                        case 'small_table':
                                            return <SmallTableChatMessage msg={element}/>;
                                        case 'simple_chart':
                                            return <SimpleChartChatMessage msg={element}/>;
                                        default:
                                            return null;
                                    }
                                })()}
                            </Box>
                        </ChatMessage>

                        {message.from === 'bot'
                            && p.p.enableChatOpportunityDemo
                            && (
                                element.ai_suggested_opportunity && element.ai_suggested_opportunity.length > 0
                                    ? element.ai_suggested_opportunity.map(o =>
                                        <Badge
                                            key={o.id}
                                            badgeContent={o.title}
                                            color="primary"
                                            onClick={() => setCreateOpportunityFrom(element)}
                                        >
                                            <AddCircle/>
                                        </Badge>
                                    ) : <IconButton onClick={() => setCreateOpportunityFrom(element)}>
                                        <AddCircle/>
                                    </IconButton>
                            )}
                    </React.Fragment>)}
                </React.Fragment>)}
                {ChatController.aiIsLoading &&
                    <ChatMessage isUser={false}>
                        <Ellipsis/>
                    </ChatMessage>
                }
            </List>

            {ChatController.history.length === 0 && inputV === '' && (
                <Box sx={{mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1}}>
                    {ChatController.suggestions.map((suggestion, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            onClick={() => {
                                ChatController
                                    .onClickSuggestion(suggestion)
                                    .finally(scrollToEnd);
                                setTimeout(scrollToEnd, 0);
                            }}
                            sx={{
                                borderRadius: '20px',
                                textTransform: 'none',
                                padding: '6px 12px',
                                fontSize: '0.875rem',
                                backgroundColor: '#e0e0e0',
                                border: '1px solid #ccc',
                                color: '#333',
                                '&:hover': {
                                    backgroundColor: '#ddd',
                                    borderColor: '#bbb',
                                },
                            }}
                        >
                            {suggestion.label}
                        </Button>
                    ))}
                </Box>
            )}

            <Box sx={{display: 'flex', mt: 2}}>
                <ChatInput
                    inputBaseProps={{
                        multiline: true,
                        autoFocus: true,
                        placeholder: 'Type your message...',
                        value: inputValue,
                        onChange: handleInputChange,
                        onKeyPress: (event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleSend();
                            }
                        },
                        inputRef: inputRef,
                    }}
                    onSend={handleSend}
                />
            </Box>
        </Box>
    </>;
});

const ChatMessage: React.FC<{ isUser: boolean }> = ({children, isUser}) => {
    return <ListItem sx={{p: 0}}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: isUser ? 'flex-end' : 'flex-start',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    marginBottom: 1,
                    maxWidth: isUser ? '75%' : '90%',
                    p: '10px 14px',
                    borderRadius: 4,
                    // borderTopLeftRadius: 4,
                    // borderTopRightRadius: 4,
                    borderBottomLeftRadius: isUser ? undefined : 0,
                    borderBottomRightRadius: isUser ? 0 : undefined,
                    // borderBottomRightRadius: isUser ? 0 : 4,
                    backgroundColor: isUser ? '#ebf4fc' : 'none',
                    color: isUser ? '#707070' : '#000',
                    textAlign: isUser ? 'right' : 'left',
                    // boxShadow: message.from === 'user' ? '0px 1px 4px rgba(0, 0, 0, 0.1)' : 'none',
                    wordBreak: 'break-word',
                    border: '1px solid #D1D6DD',
                }}
            >
                {children}
            </Box>
        </Box>
    </ListItem>;
}