import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {FormControl, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";
import {SearchManager} from "../stores/managers/SearchManager";

export const SearchComponent: React.FC<{
    sm: SearchManager,
    searchFieldLabel: string,
    componentId?: string,
    className?: string,
    fullWidth?: boolean,
    doNotClearOnMount?: true
}> = observer(({sm, searchFieldLabel, componentId, className, fullWidth, doNotClearOnMount}) => {
    useEffect(() => {
        if (doNotClearOnMount) {
            sm.clearSearch();
            sm.doSearch('auto-reset');
        } else {
            sm.doSearch('auto-reset');
        }
    }, [sm]);

    const searchString = sm.searchString;
    componentId = componentId || 'search-component';
    return <div
        className={'search-component' + (className ? ' ' + className : '')}>
        <FormControl
            className="typed-search-field"
            variant="standard"
            fullWidth={fullWidth}>
            <InputLabel htmlFor={componentId + '-field'}>
                {searchFieldLabel}
            </InputLabel>
            <Input
                id={componentId + '-field'}
                type="text"
                fullWidth={true}
                endAdornment={
                    <InputAdornment position="end">
                        {searchString &&
                            <IconButton
                                aria-label="clear search"
                                onClick={() => sm.clearSearch()}
                            >
                                <Clear/>
                            </IconButton>
                        }
                        <IconButton
                            aria-label="search"
                            onClick={() => sm.doSearch('click')}
                        >
                            <Search/>
                        </IconButton>
                    </InputAdornment>
                }
                value={searchString}
                onChange={e => sm.setSearch(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? sm.doSearch('enter') : undefined}
            />
        </FormControl>
    </div>
})