import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, ButtonGroup, Chip, Grid, IconButton, Typography} from "@mui/material";
import {routes} from '../../routing/routes';
import {generatePath, useNavigate} from "react-router-dom";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {ChevronRight, Delete, Refresh} from '@mui/icons-material';
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {IngestionFile} from "../../services/classes/IngestionClasses";
import {GenericModalVertical} from "../../components/modal/GenericModalVertical";
import moment from "moment/moment";


type Props = {}
export const DatasetOverviewPage: React.FC<Props> = observer(() => {
    const {dataIngestionStore, p} = useStores();
    const loading = dataIngestionStore.datasetList === undefined;
    const noData = dataIngestionStore.datasetList?.length === 0;
    const manyData = dataIngestionStore.datasetList && dataIngestionStore.datasetList.length > 9;
    const error = dataIngestionStore.datasetListManager.error;

    const [deleteDialogSubject, setDeleteDialogSubject] = React.useState<IngestionFile | undefined>(undefined);
    const [deleteIsLoading, setDeleteIsLoading] = React.useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        dataIngestionStore.datasetListManager.request()
    }, [dataIngestionStore, p])

    const uploadButton = <Button
        onClick={() => navigate(generatePath(routes.data_upload))}
        color="primary"
        variant="outlined"
        endIcon={<ChevronRight/>}
    >
        Upload new dataset
    </Button>;

    const actions = <Grid container className="dataset-overview-actions" justifyContent="center">
        <Grid item>
            {uploadButton}
        </Grid>
    </Grid>

    const table = <>
        <Grid container justifyContent="center" className="dataset-overview-table-titles" sx={{mb: 2}}>
            <Grid item xs={6}>
                <Typography variant="h6">Dataset name</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6">Dataset status</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6">Upload date</Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>

        <Grid container justifyContent="center" className="dataset-overview-table-content">
            {dataIngestionStore.datasetList?.map((dataset) => (
                <Grid container justifyContent="center" alignItems="center" key={'dataset' + dataset.id} sx={{mb: 2}}>
                    <Grid item xs={6}>
                        <Typography variant="body1">{dataset.name}</Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent="center">
                        <Typography variant="body1">
                            {dataset.databag_exists ?
                                <Chip label="Uploaded" color="success"
                                      className="dataset-uploaded"/> :
                                <Chip label="Pending" color="primary"
                                      className="dataset-pending"/>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1">{moment(dataset.date).format('YYYY-MM-DD')}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'right'}}>
                        <ButtonGroup variant="outlined" color="primary">
                            {!dataset.databag_exists &&
                                <Button color="error" onClick={() => setDeleteDialogSubject(dataset)}>
                                    <Delete/>
                                </Button>
                            }
                            <Button
                                onClick={() => navigate(generatePath(routes.data_upload_mapping, {dataFileId: String(dataset.id)}))}>
                                <ChevronRight/>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </>

    const deleteDialog = <GenericModalVertical
        className="dataset-modal"
        title={`Are you sure?`}
        isOpen={!!deleteDialogSubject}
        onSave={() => {
            if(!deleteDialogSubject) return;
            setDeleteIsLoading(true);
            dataIngestionStore.deleteIngestionFile(deleteDialogSubject)
                .then(() => setDeleteDialogSubject(undefined))
                .finally(() => setDeleteIsLoading(false))
        }}
        isLoading={deleteIsLoading}
        saveTitle="Delete"
        isDanger
        onCancel={() => setDeleteDialogSubject(undefined)}
        onClose={() => setDeleteDialogSubject(undefined)}
    >
        <Grid container spacing={2} alignContent="center" alignItems="center" direction="row" xs={12}>
            <Grid item xs={12}>
                <Typography style={{marginTop: '1em'}}>
                    Are you sure you want to remove the following dataset?
                </Typography>
                <Typography style={{marginTop: '1em'}} className="filename">
                    {deleteDialogSubject?.name}
                </Typography>
            </Grid>
        </Grid>
    </GenericModalVertical>

    return <KoiFacade
        title="Data Ingestion"
        onBack={() => navigate(routes.home)}
        className={`data-ingestion data-overview-page${noData ? ' no-data' : ''}`}
        next={manyData ? {type: 'component', node: uploadButton} : undefined}>
        {deleteDialog}
        {error
            ? <Grid container justifyContent="center" className="error-alert-container">
                <Grid item>
                    <Alert
                        severity="error"
                        action={<IconButton
                            size="small"
                            onClick={() => dataIngestionStore.datasetListManager.request()}
                            disabled={dataIngestionStore.datasetListManager.busy}>
                            <Refresh/>
                        </IconButton>
                        }>
                        {dataIngestionStore.datasetListManager.error}
                    </Alert>
                </Grid>
            </Grid>
            : loading
                ? <LoadingSpinnerPie/>
                : noData
                    ? actions
                    : <Grid container justifyContent="center">
                        <Grid item xs={8}>
                            {table}
                            {actions}
                        </Grid>
                    </Grid>
        }
    </KoiFacade>
});
