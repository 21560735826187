import {stores} from "../../../stores";
import {environment} from "../../../env";
import {makeAutoObservable} from "mobx";
import {GoldenRecordPartState} from "./GoldenRecordPartState";

export type GoldenRecordGroupedData = {
    gr_id: number
    gr_name: string
    gr_l1s: string[]
    gr_spend: number
    gr_s_count: number
    gr_p_count: number
    gr_note: string
}
export type GoldenRecordFields = keyof GoldenRecordGroupedData;

export class GoldenRecordGroupedState {
    open = false;
    isLoadingRows = false;
    page = 1
    pageSize = stores.p.p.categorizationSublistPageSize ?? environment.defaultCategorizationSublistPageSize;
    partStates: GoldenRecordPartState[] | undefined;

    get id(): number {
        return this.data.gr_id;
    }

    get partCount() {
        return this.data.gr_p_count || 0;
    }

    get hackTotalSpend() {
        return this.partStates?.reduce((acc, p) => acc + p.data.p_spend, 0) || 0;
    }

    get hackSupplierCount() {
        const set = new Set(this.partStates?.map(p => p.data.s_name) || []);
        return set.size;
    }

    get hackL1s() {
        return Array.from(new Set(this.partStates?.map(p => p.data.active_l[0]) || []))
    }

    constructor(
        public data: GoldenRecordGroupedData,
        public currentPageNumber: number,
    ) {
        makeAutoObservable(this)
    }

    public static build(data: GoldenRecordGroupedData, pageNumber: number, filter?: any) {
        // console.debug('Building grouped row state', {data, pageNumber});
        return new GoldenRecordGroupedState(data, pageNumber);
    }

    public setOpen(open: boolean) {
        this.open = open;
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoadingRows = isLoading;
    }

    public setPage(page: number) {
        this.page = page;
    }

    setPartStates(partStates: GoldenRecordPartState[], pageNumber: number) {
        this.partStates = partStates;
        this.page = pageNumber;
        this.isLoadingRows = false;
    }

    public handlePageChange(newPage: number) {
        // // Fetch data for the new page
        // this.setIsLoading(true)
        //
        // const profileStore: ProfileStore = stores.p;
        // const authStore: AuthStore = stores.authStore;
        // const api: MithraMaterializedApi = stores.materializedApi;
        //
        // const userId = authStore.userId;
        // api.listPartsReviewInGroup(
        //     this.data.mat_s_group_ids,
        //     newPage,
        //     this.pageSize,
        //     profileStore.p.categorizationReviewSubRowRelationData,
        // ).then(resp => {
        //     const partStates = resp.data.results.map(p => PartRowState.build(p, userId, this));
        //     this.setPartStates(partStates, newPage);
        // }).finally(() => {
        //     this.setIsLoading(false)
        // })
    }
}
