import {observer} from "mobx-react-lite";
import {Autocomplete, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank, CloseOutlined} from "@mui/icons-material";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {BagAdvancedInsightsManager} from "../../stores/managers/BagAdvancedInsightsManager";
import {countriesToContinents, Country, countryToContinent} from "../../components/visualization/bubble-map/countries";
import {SearchSupplierAutocomplete} from "./SearchSupplierAutocomplete";
import React, {ReactNode} from "react";
import {useStores} from "../../stores";
import {SearchParentSupplierAutocomplete} from "./SearchParentSupplierAutocomplete";
import {routes} from "../../routing/routes";
import {useLocation, useParams} from "react-router-dom";
import {ShadcnDateRangePicker} from "../../shadcn/components/ui/ShadcnDateRangePicker";
import moment from "moment";
import {C} from "../../configurations/active-configuration";
import {SearchBusinessUnitAutocomplete} from "./SearchBusinessUnitAutocomplete";
import {SearchCDescriptionAutocomplete} from "./SearchCDescriptionAutocomplete";
import {environment} from "../../env";

export const AdvancedDashboardHeaderFilters = observer(() => {
    const {p, bagStore} = useStores();
    const params = useParams();
    const location = useLocation();
    // const isMergeXMergePage = location.pathname === routes.merge_x_merge_advanced_dashboard;
    const isMergeXCSingleDashboard = environment.package === 'merge_x_cleanup' && location.pathname === routes.merge_xc_single_advanced_dashboard;
    const hideParents = p.p.hideParentSupplierOpportunityDashboard || isMergeXCSingleDashboard;

    const filterItems: (ReactNode | undefined)[] = [
        <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by date
            </Typography>
            <ShadcnDateRangePicker
                onUpdate={(values) => {
                    const startDate = moment(values.range.from).format('YYYY-MM-DD'); // Convert from Date to formatted string
                    const endDate = moment(values.range.to).format('YYYY-MM-DD'); // Convert to Date to formatted string, if available
                    console.log('startDate', startDate, values.range.from)
                    console.log('endDate', endDate, values.range.to)
                    bagStore.advanced.dateFilterChanged(startDate, endDate); // Call your dateFilterChanged function with the formatted dates
                }}
                dateFrom={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[0]}
                dateTo={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[1]}
                align="start"
            />
        </>,
        <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by country
            </Typography>

            <Autocomplete
                className="adv-dashboard-country-filter"
                multiple
                limitTags={1}
                value={bagStore.advanced.filters.country || []}
                options={bagStore.advanced.geoDataCountryStringArr}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : '-'}
                renderOption={(props, option, {selected}) =>
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small"/>}
                            checkedIcon={<CheckBox fontSize="small"/>}
                            style={{marginRight: 8}}
                            checked={selected}
                        />

                        <Grid container>
                            <Grid item xs={2}>
                                {option.toLowerCase() && <img
                                    crossOrigin="anonymous"
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                    alt=""
                                />}
                            </Grid>
                            <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                <CurrencyComponent
                                    v={BagAdvancedInsightsManager.getCountrySpend(bagStore.advanced.geoData, option)}
                                    nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                }
                onChange={(_, value) => {
                    bagStore.advanced.countryFilterChangedNew(value);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Countries"/>
                )}
            />
        </>,
        <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by continent
            </Typography>

            <Autocomplete
                className="adv-dashboard-filter-continent"
                multiple
                limitTags={1}
                value={countriesToContinents(bagStore.advanced.filters.country as Country[] || [])}
                options={bagStore.advanced.continentFilterData}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : '-'}
                renderOption={(props, option) => {
                    const s = bagStore.advanced.filters.country && bagStore.advanced.filters.country.some((selectedCountry) => {
                        const continent = countryToContinent(selectedCountry as Country);
                        return continent === option;
                    });
                    return (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                checkedIcon={<CheckBox fontSize="small"/>}
                                style={{marginRight: 8}}
                                checked={s}
                            />
                            <Grid container>
                                <Grid item xs={6}>
                                    {option ? option : '-'}
                                </Grid>
                                <Grid item xs={6}>
                                    {/*TODO: No calculation in render thread*/}
                                    <CurrencyComponent
                                        v={BagAdvancedInsightsManager.calculateContinentSpend(bagStore.advanced.geoData, option)}
                                        nDigits={3}/>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
                onChange={(_, i) => {
                    const newCountries = i.flatMap((continent) => {
                        return bagStore.advanced.geoData.filter((country) => {
                            return countryToContinent(country.country) === continent;
                        }).map((country) => country.country);
                    });
                    bagStore.advanced.countryFilterChanged(newCountries);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Continents"/>
                )}
            />
        </>,
        C.profile.disableAdvDashboardSearchBySupplier ? undefined : <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by supplier
            </Typography>
            <SearchSupplierAutocomplete wrapperClassName="supplier-search-autocomplete-wrapper autocomplete-wrapper" limitTags={1}/>
        </>,
        hideParents ? undefined : <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by parent supplier
            </Typography>
            <SearchParentSupplierAutocomplete wrapperClassName="parent-supplier-search-autocomplete-wrapper autocomplete-wrapper" limitTags={1}/>
        </>,
        p.p.synergyDashboardShowBusinessUnits ? <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by BU
            </Typography>
            <SearchBusinessUnitAutocomplete
                wrapperClassName="business-unit-search-autocomplete-wrapper autocomplete-wrapper"
                limitTags={1}
                label="Business Unit"
                noOptionsText="No business units found"
            />
        </> : undefined,
        p.p.synergyDashboardShowCDescriptionFilter ? <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by Company
            </Typography>
            <SearchCDescriptionAutocomplete
                wrapperClassName="c-description-search-autocomplete-wrapper autocomplete-wrapper"
                limitTags={1}
                label="Company"
                noOptionsText="No company description found"
            />
        </> : undefined,
        <>
            <div style={{marginBottom: '4em'}}></div>
            <Button
                variant="outlined"
                style={{
                    width: '3.5em',
                    height: '3.5em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => {
                    bagStore.advanced.reset()
                }}>
                <CloseOutlined/>
            </Button>
        </>,
    ]

    const items = filterItems.filter(n => n !== undefined) as ReactNode[];
    return <Grid container className={`filter-container header-filter-container`}>
        {items.map((item, j) =>
            <Grid item key={j}>
                {item}
            </Grid>
        )}
    </Grid>;
    // const renderItems: ReactNode[][] = items.length > 6 ? [items.slice(0, 4), items.slice(4)] : [items]
    // return <>
    //     {renderItems.map((rowItems, i) =>
    //         <Grid key={i} container
    //               className={`filter-container header-filter-container header-filter-container-row-${i}`}>
    //             {rowItems.map((item, j) =>
    //                 <Grid item key={j}>
    //                     {item}
    //                 </Grid>
    //             )}
    //         </Grid>
    //     )}
    // </>;
})
