import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableFooter, TablePagination, TableRow} from "@mui/material";
import {SynergyCommonSupplierReviewSubRow} from "./SynergyCommonSupplierReviewSubRow";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {CommonParentSupplier} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {useStores} from "../../../stores";
import {SYN_COMMON_SUPPLIER_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {findAbbreviation} from "../../../components/currency-component/CurrencyClasses";

type Props = {
    commonParentSupplier: CommonParentSupplier
};
export const SynergyCommonSupplierReviewSubTable: React.FC<Props> = observer(({commonParentSupplier}) => {
    const {synergyStore} = useStores();

    return <Table className="sub-table" size="small">
        <MithraTableHeadColumns columns={SYN_COMMON_SUPPLIER_COLUMNS} enableLoading
                                isLoading={synergyStore.commonSupplierRM.busy}/>
        <TableBody>
            {synergyStore.commonSupplierRM.result?.results?.map(supplier =>
                    <React.Fragment key={supplier.id}>
                        <CurrencyAbbreviationContext.Provider
                                value={findAbbreviation(commonParentSupplier.sp_total_spend)}>
                            <SynergyCommonSupplierReviewSubRow commonSupplier={supplier}/>
                        </CurrencyAbbreviationContext.Provider>
                    </React.Fragment>
            )}
        </TableBody>
        <TableFooter>
            <TableRow>
                <TablePagination
                        colSpan={SYN_COMMON_SUPPLIER_COLUMNS.length}
                        count={synergyStore.commonSupplierRM.result?.count ?? 0}
                        rowsPerPage={parseInt(synergyStore.commonSupplierFilters.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                        page={parseInt(synergyStore.commonSupplierFilters.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                        onPageChange={(event, page: number) => {
                            synergyStore.commonSupplierFilters.setFilters([['page', (page + 1).toString()]]);
                        }}
                        onRowsPerPageChange={(event) => {
                            synergyStore.commonSupplierFilters.setFilters([['page_size', (event.target.value).toString()]], false);
                            synergyStore.commonSupplierFilters.setFilters([['page', '1']]);
                        }}
                        ActionsComponent={MithraTablePaginationActions}
                />
            </TableRow>
        </TableFooter>
    </Table>
})
