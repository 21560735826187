import {AddTask, AdminPanelSettings, CheckCircleOutline, HighlightOff} from "@mui/icons-material";
import {OpportunityStatusEnum} from "../../../services/classes/OpportunityClasses";
import React from "react";

type VisualType = {
    border: string;
    color: string;
    icon: JSX.Element;
    label: string
};
export const OPPORTUNITY_STATUS_VISUAL: {[key in OpportunityStatusEnum]: VisualType} = {
    [OpportunityStatusEnum.PENDING_REVIEW]: {
        label: 'PENDING REVIEW',
        color: '#F4F4F4', // Light grey
        border: '2px solid #d2d6dd',
        icon: <AddTask style={{color: '#262626'}}/>
    },
    [OpportunityStatusEnum.IN_REVIEW]: {
        label: 'IN REVIEW',
        color: '#E0E1E6', // Dark grey
        border: '2px solid #787d98',
        icon: <AdminPanelSettings style={{color: '#091444'}}/>
    },
    [OpportunityStatusEnum.DISQUALIFIED]: {
        label: 'DISQUALIFIED',
        color: '#FCE4E5', // Red
        border: '2px solid #f78f8f',
        icon: <HighlightOff style={{color: '#f53436'}}/>
    },
    [OpportunityStatusEnum.QUALIFIED]: {
        label: 'QUALIFIED',
        color: '#DEEBFD', // Blue
        border: '2px solid #73a8f9',
        icon: <CheckCircleOutline style={{color: '#2962f2'}}/>
    }
}

export function getOpportunityStatusName(status: OpportunityStatusEnum): string {
    return OPPORTUNITY_STATUS_VISUAL[status].label || '';
}
