import React from "react";
import {observer} from "mobx-react-lite";
import {ppvOpportunityController} from "../PpvOpportunityController";
import {Button, Card, CardContent, Grid, TableCell, TableRow, Typography} from "@mui/material";
import {StarIcon} from "../../../../../components/icons/StartIcon";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {MithraTable} from "../../../../../components/data-table/MithraTable";

export const PpvOpportunityOverviewComponent: React.FC = observer(() => {
    const opp = ppvOpportunityController.ppvOpp;
    return <>
        <Grid container className="ppv-opp-overview-header" alignItems="center">
            <Grid item>
                <StarIcon/>
            </Grid>
            <Grid item>
                <Typography variant="caption">Opportunity</Typography>
                <Typography variant="h3" className="value">Purchase Price Variance</Typography>
            </Grid>
            <Grid item flexGrow="1">
                <Typography variant="caption">Name</Typography>
                <Typography variant="h3" className="value">{opp?.ppv_group_key}</Typography>
            </Grid>
            <Grid item>
                <Button variant="contained">
                    TODO: button
                </Button>
            </Grid>
        </Grid>
        <Grid container className="ppv-opp-overview-cards" spacing={4}>
            <Grid item xs={6}>
                <Card className="opp-card" variant="outlined">
                    <CardContent>
                        <Typography variant="caption">Opportunity value</Typography>
                        <Typography variant="h3" className="value"><CurrencyComponent v={opp?.current_expected_savings || 0}/></Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className="opp-card" variant="outlined">
                    <CardContent>
                        <Typography variant="caption">Potential savings</Typography>
                        <Typography variant="h3" className="value"><CurrencyComponent v={opp?.current_expected_savings || 0}/></Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        <MithraTable headers={[
            {headerText: 'Supplier'},
            {headerText: 'Total spend'},
            {headerText: 'Country'},
            {headerText: 'L1'},
        ]}>
            {ppvOpportunityController.supplierStats?.map(s =>
                <TableRow key={s.s_name}>
                <TableCell>
                    <Typography>{s.s_name}</Typography>
                </TableCell>
                <TableCell>
                    <CurrencyComponent v={Number(s.total_spend)}/>
                </TableCell>
                <TableCell>
                    <Typography>{s.s_country}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{s.l1.join(', ')}</Typography>
                </TableCell>
            </TableRow>
            )}
        </MithraTable>
    </>
});