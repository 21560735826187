import {makeAutoObservable} from "mobx";
import {PpvLevelStatisticsTreeSerializer} from "../../../services/classes/MaterializedClasses";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {AxoisRequestManager} from "../../../stores/managers/RequestManager";
import {from} from "rxjs";
import {PpvLevelStatisticsFilter} from "./classes/PpvApiClasses";


export class PpvPageStatisticsController {
    constructor(
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    reset() {
        this._ppvLevelStatistics.cleanup()
    }

    request(filter: PpvLevelStatisticsFilter) {
        this._ppvLevelStatistics.request(filter)
    }


    get reviewLevelStatistics(): PpvLevelStatisticsTreeSerializer | undefined {
        return this._ppvLevelStatistics.result?.at(0);
    }

    get spendTotalPPV(): number | undefined {
        return this.reviewLevelStatistics?.values.total_ppv_opportunity;
    }

    readonly _ppvLevelStatistics = new AxoisRequestManager<PpvLevelStatisticsFilter, [PpvLevelStatisticsTreeSerializer]>(
        (f) => from(this.api.listPpvLevelStatistics(f))
    );
}