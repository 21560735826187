import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {ContractUploadComponent} from "../components/ContractUploadComponent";

export const ContractUploadModal: React.FC = observer(() => {
    const {contractInsightsStore} = useStores();
    const handleClose = () => {
        contractInsightsStore.setContractUploadDialogIsOpen(false);
    }
    return <Dialog
        open={contractInsightsStore.contractUploadDialogIsOpen}
        onClose={handleClose}
        className="contract-upload-modal"
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
            Upload Contract
        </DialogTitle>
        <DialogContent>
            <ContractUploadComponent/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>
                Ok
            </Button>
        </DialogActions>
    </Dialog>
})
