import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {makeAutoObservable} from "mobx";
import {Attachment} from "../ApiTypes";
import {InitiativeInstanceStore} from "./InitiativeInstance/InitiativeInstanceStore";

export class AttachmentStore {
    readonly initiativeAttachmentsRM = new AxoisRequestManager<{ id: number }, Attachment[]>(
        ({id}) => from(this.api.initiativeAttachments(id)));

    constructor(private api: SitApi, private initiativeInstanceStore: InitiativeInstanceStore) {
        makeAutoObservable(this, {});
    }

    delete(id: number) {
        if ((this.initiativeInstanceStore.instance.id ?? 0) !== 0)
            this.api.deleteInitiativeAttachment(this.initiativeInstanceStore.instance.id!, id).then(() => {
                this.initiativeAttachmentsRM.request({id: this.initiativeInstanceStore.instance.id!});
            });
    }

    fileName(attachment: Attachment){
        return attachment.file.split('/').reverse()[0]
    }
}