import './TaxonomyEditorApprovalComponent.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Alert, Dialog, Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {ConfirmSendForApproval} from "./ConfirmSendForApproval";
import {TaxApprovalPreviewTable} from "./table/TaxApprovalPreviewTable";

const MINUTES = 60 * 1000;

export const TaxonomyEditorApprovalComponent: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const approvalDelegate = taxonomyEditorStore.approval;

    useEffect(() => {
        const intervalId = setInterval(() => {
            taxonomyEditorStore.approval.saveNotesToTaxonomy()
        }, 5 * MINUTES);
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="taxonomy-editor-approval-component">

        <Dialog
            open={approvalDelegate.isConfirmModalOpen}
            onClose={() => approvalDelegate.setConfirmModalOpen(false)}
            maxWidth="lg"
        >
            {approvalDelegate.isConfirmModalOpen && <ConfirmSendForApproval/>}
        </Dialog>

        {approvalDelegate.errorMessage && <Grid container justifyContent="center">
            <Grid item>
                <Alert severity="error">{approvalDelegate.errorMessage}</Alert>
            </Grid>
        </Grid>}

        {/*<Button onClick={() => taxonomyEditorStore.approval.saveNotesToTaxonomy()}>*/}
        {/*    Save {taxonomyEditorStore.approval.categoryNotes.size}*/}
        {/*</Button>*/}

        <Grid container justifyContent="center">
            <Grid item xs={8} component="section">
                <Typography component="p">
                    Please review the changes that will be send for approval.
                    Additionally, when sending for approval general notes can also be added.
                </Typography>
            </Grid>
        </Grid>

        <TaxApprovalPreviewTable/>
    </div>
})
