import {Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {Edit, ExpandMore, KeyboardArrowRight, Visibility} from "@mui/icons-material";
import {getContractInsightsColumns} from "./ContractInsightsColumns";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import React from "react";
import {environment} from "../../../env";
import {ContractRecord, showContractFieldValue} from "../classes";
import {CONTRACT_TABLE_FOLD_FIELDS} from "../data/ContractFields";
import {FlexibleLists} from "../components/FlexibleLists";

type Props = {
    contract: ContractRecord,
};

export const ContractMainRow: React.FC<Props> = observer(({contract}) => {
    const {p, contractInsightsStore} = useStores();
    const isDemo = environment.isDemo;
    const COLUMNS = getContractInsightsColumns(p);
    const className = `contract-row`;
    let i = 0;
    const d = contract.data;
    const collapseData = CONTRACT_TABLE_FOLD_FIELDS
        .filter(spec => spec)
        .map(spec => ({spec, values: showContractFieldValue(d, spec)}))
        .filter(v => v.values.length > 0)
    return <>
        <TableRow className={className}>
            <MithraTableCell c={COLUMNS[i++]}
                             onClick={() => contract.setOpen(!contract.open)}>
                <IconButton aria-label="expand row" size="small">
                    {/*id={contract.id}*/}
                    {contract.open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.file_name || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.contract_type || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {Array.isArray(d?.involved_company_legal_name) ? d.involved_company_legal_name.join(', ') : d.involved_company_legal_name || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {Array.isArray(d?.involved_supplier_legal_name) ? d.involved_supplier_legal_name.join(', ') : d.involved_supplier_legal_name || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.payment_terms || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.effective_date || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.expiration_date || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.evergreen_contract || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {d?.auto_renewal || ''}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {Array.isArray(d?.incoterms) ? d.incoterms.join(', ') : d.incoterms || ''}
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                {!isDemo && <>
                    <IconButton
                        className="button"
                        onClick={() => contractInsightsStore.setDetailModalData(contract)}>
                        <Visibility/>
                    </IconButton>
                    <IconButton
                        className="button"
                        onClick={() => contractInsightsStore.setEditModalData(contract)}>
                        <Edit/>
                    </IconButton>
                </>}
                {/*{isDemo && <>*/}
                {/*<IconButton className={'button'}>*/}
                {/*    <InsertChart onClick={() => {*/}
                {/*        navigate(generatePath(routes.demo_cleanup_analytics_contract_analytics, {*/}
                {/*            id: bagStore.bagId,*/}
                {/*            contract: contract.data?.contract_id ? contract.data.contract_id : -1*/}
                {/*        }));*/}
                {/*    }}/>*/}
                {/*</IconButton>*/}
                {/*</>}*/}
            </MithraTableCell>
        </TableRow>

        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {<Collapse in={contract.open} timeout="auto" unmountOnExit>
                    <TableContainer className={'tableClass'}>
                        <Table className="sub-table" size="small">
                            {/*<MithraTableHeadColumns columns={SUBCOLUMNS} enableLoading isLoading={false}/>*/}
                            <TableBody>
                                {collapseData.map(c =>
                                    <TableRow key={c.spec.field}>
                                        <TableCell style={{backgroundColor: '#F6F6F6'}}>
                                            <Typography
                                                variant='body1'
                                                style={{color: '#707070', margin: '5px 0 0 15px'}}>
                                                {c.spec.title}
                                            </Typography>
                                            {c.values.length === 1 &&
                                                <Typography
                                                    variant="subtitle2"
                                                    component="p"
                                                    style={{margin: '0 0 8px 15px'}}>
                                                    {String(c.values[0])}
                                                </Typography>}
                                            {c.values.length !== 1 &&
                                                <>
                                                    <FlexibleLists
                                                        v={c.values}
                                                        render={v => <Typography
                                                            variant="subtitle2"
                                                            component="p">
                                                            {String(v)}
                                                        </Typography>}
                                                    />
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>}
            </TableCell>
        </TableRow>
    </>
})
