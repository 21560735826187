import {Alert, Button, Grid} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";
import {Analytics, Dashboard, Gavel, Help, Info, Inventory, LocalShipping, RequestPage} from "@mui/icons-material";
import {
    CONTRACT_DELIVERY_FIELDS,
    CONTRACT_FINANCE_FIELDS,
    CONTRACT_INFO_FIELDS,
    CONTRACT_INVOICE_FIELDS,
    CONTRACT_OTHER_FIELDS,
    CONTRACT_PERFORMANCE_FIELDS,
    CONTRACT_PRODUCT_FIELDS,
    CONTRACT_TERMINATION_FIELDS,
    ContractFieldSpec,
    getRemainingFields
} from "../data/ContractFields";
import {ContractEditDataBlock} from "./ContractEditDataBlock";

export const ModalContractEdit: React.FC = observer(() => {
    const {contractInsightsStore} = useStores();

    const formRef = React.useRef<HTMLFormElement>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');

    const record = contractInsightsStore.modalContractsEditData;
    const d = record?.data;
    if (!d) {
        return <></>;
    }

    const remaining: ContractFieldSpec[] = getRemainingFields(d);

    const handleSave = () => {
        console.log('ModelContractEdit handleSave()');
        // Get the values from the React.FormEventHandler
        if (!formRef.current) return;
        setError('');
        setIsLoading(true);
        const formData = new FormData(formRef.current);
        const data = Array.from(formData.entries()) as [string, string][]; // No files are uploaded here
        contractInsightsStore.storeContractDetailsData(data, record!)
            .then(() => contractInsightsStore.setEditModalData(null))
            .catch(e => {
                console.warn(e);
                setError(String(e));
            })
            .finally(() => setIsLoading(false));
    };
    const handleDelete = () => {
        console.log('ModelContractEdit handleDelete()');
        contractInsightsStore.deleteContractRecord(record!)
            .then(() => contractInsightsStore.setEditModalData(null))
            .catch(e => {
                console.warn(e);
                setError(String(e));
            })
            .finally(() => setIsLoading(false));
    }
    return <GenericModalVertical
        className="contract-details-modal"
        isOpen={contractInsightsStore.modalContractsEditData !== null}
        onSave={() => handleSave()}
        saveTitle="Save"
        onCancel={() => contractInsightsStore.setEditModalData(null)}
        title="Edit Contract"
        maxWidth="md"
        isLoading={isLoading}
    >
        <Grid container component="form" onSubmit={e => {
            e.preventDefault();
            handleSave();
        }} ref={formRef}>
            <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                <ContractEditDataBlock
                    title="Contract Info" icon={<Info/>}
                    data={d} fields={CONTRACT_INFO_FIELDS}/>
                <ContractEditDataBlock
                    title="Financial Terms" icon={<RequestPage/>}
                    data={d} fields={CONTRACT_FINANCE_FIELDS}/>
                <ContractEditDataBlock
                    title="Product Details" icon={<Inventory/>}
                    data={d} fields={CONTRACT_INVOICE_FIELDS}/>
                <ContractEditDataBlock
                    title="Delivery & Stock" icon={<LocalShipping/>}
                    data={d} fields={CONTRACT_DELIVERY_FIELDS}/>
                <ContractEditDataBlock
                    title="Termination & Legal" icon={<Gavel/>}
                    data={d} fields={CONTRACT_TERMINATION_FIELDS}/>
                <ContractEditDataBlock
                    title="Performance & Compliance" icon={<Analytics/>}
                    data={d} fields={CONTRACT_PERFORMANCE_FIELDS}/>
                <ContractEditDataBlock
                    title="Group of Products" icon={<Dashboard/>}
                    data={d} fields={CONTRACT_PRODUCT_FIELDS}/>
                <ContractEditDataBlock
                    title="Other" icon={<Help/>}
                    data={d} fields={[...CONTRACT_OTHER_FIELDS, ...remaining]}/>
            </Grid>
            <Button type="submit" style={{display: 'none'}}/>
        </Grid>
        <Button onClick={handleDelete} variant="outlined" color="error">
            Delete
        </Button>
        {error &&
            <Grid container>
                <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                </Grid>
            </Grid>
        }
    </GenericModalVertical>
})

