import * as d3 from "d3";
import {scaleBand, scaleLinear} from "d3";
import React, {useEffect, useRef} from "react";
import {AdvancedSpendData} from "../../../services/ApiTypes";
import "./AdvancedBarChart.scss";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {TablePagination} from "@mui/material";
import {HashedLoadingDataWrapper} from "../../../utils/LoadingDataWrapper";
import {observer} from "mobx-react-lite";
import {toCurrencyWithP} from "../../currency-component/CurrencyClasses";
import {useStores} from "../../../stores";

const FONT_SIZE = 14;
const TOOLTIP_FONT_SIZE = 20;
const BAR_PADDING = 0.25;

export const AdvancedBarChart: React.FC<{
    dataWrapper: HashedLoadingDataWrapper<AdvancedSpendData>,
    selectedSuppliers?: string[],
    supplierFilterChanged: (supplier?: string[]) => void,
    pageSize?: number
    pageSizeOptions?: Array<number>
}> = observer(({dataWrapper, selectedSuppliers, supplierFilterChanged, pageSize, pageSizeOptions}) => {
    const {p} = useStores();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 50);
    const [rowsPerPageOptions] = React.useState(pageSizeOptions || [5, 12, 25, 50, {value: -1, label: 'All'}]); // Maybe add all

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const margin = {top: FONT_SIZE / 2 + 1, right: 0, bottom: FONT_SIZE / 2 + 1, left: 60};
    const width = 700 - margin.left - margin.right;
    const height = 415 - margin.top - margin.bottom;
    let data = dataWrapper.elements;
    const datahash = dataWrapper.hash + '-' + rowsPerPage;

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }
        if (!datahash) {
            return;
        }
        if (svgRef.current.dataset['datahash'] === datahash && svgRef.current.dataset['page'] === page.toString()) {
            // Don't re-render if the input data is already rendered
            // console.log('AdvancedBarChart skipping render, hash=' + datahash);
            return;
        }
        // console.log('AdvancedBarChart rendering, hash=' + datahash);
        svgRef.current.dataset['datahash'] = datahash;
        svgRef.current.dataset['page'] = page.toString();

        const svg = d3.select(svgRef.current);
        const tooltip = d3.select(tooltipRef.current);

        svg.html("");
        svg
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom);

        tooltip
            .style("opacity", 0);

        const showAll = rowsPerPage <= 0;
        data = showAll ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

        function mouseover(this: any) {
            tooltip.style("opacity", 1);
            d3.select(this).select('.bar').attr('fill-opacity', 0.5);
        }

        function mousemove(this: any, event: MouseEvent, d: AdvancedSpendData) {
            tooltip.text(d.group + ": " + toCurrencyWithP(d.spend, p.currencyFormat, p.currencySymbol))
                .style("font-size", TOOLTIP_FONT_SIZE + "px")
                .style("left", event.pageX + "px")
                .style("top", (event.pageY + TOOLTIP_FONT_SIZE) + "px");
        }

        function mouseleave(this: any) {
            tooltip.style("opacity", 0);
            d3.select(this).select('.bar').attr('fill-opacity', 1);
        }

        function click(this, event, d: AdvancedSpendData) {
            if (!selectedSuppliers) {
                selectedSuppliers = [d.s_id];
            } else if (!selectedSuppliers.includes(d.s_id)) {
                selectedSuppliers.push(d.s_id);
            } else {
                selectedSuppliers = selectedSuppliers.filter(supplier_id => supplier_id !== d.s_id);
            }

            supplierFilterChanged?.(selectedSuppliers);
        }

        const scaleX = scaleBand()
            .domain(data.map(({group}) => group))
            .range([0, width])
            .padding(BAR_PADDING);
        const bandWidth = scaleX.bandwidth();
        const spends = data.map(({spend}) => spend);
        const scaleY = scaleLinear()
            .domain([Math.min(0, Math.min(...spends)), Math.max(...spends)])
            .range([height, 0]);

        // Add X axis
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
            .call(d3.axisBottom(scaleX).tickFormat(() => '').tickSize(0));

        // Add Y axis
        svg.append("g")
            .attr("class", "y-axis")
            .style("font-size", FONT_SIZE + 'px')
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .call(d3.axisLeft(scaleY).ticks(5).tickFormat(v => d3.format("~s")(v)));


        const barWrapper = svg.selectAll(".bar")
            .data(data.values())
            .enter()
            .append("g")
            .classed("bar-wrapper", true)
            .attr("style", "cursor: pointer;")
            .attr('transform', (d: AdvancedSpendData) => `translate(${margin.left + (scaleX(d.group) as any)}, ${margin.top})`)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
            .on("click", click);

        barWrapper
            .append("rect")
            .classed("bar-background", true)
            .attr("style", "fill: transparent;")
            .attr("width", scaleX.step())
            .attr("height", height)

        barWrapper
            .append("rect")
            .classed("bar", true)
            .classed("negative-bar", (d) => d.spend < 0)
            .attr("key", (d) => `bar-${d.group}`)
            .attr("x", 0)
            .attr("y", (d: AdvancedSpendData) => d.spend > 0 ? scaleY(d.spend) : scaleY(0))
            .attr("width", bandWidth)
            .attr("height", (d) => d.spend > 0 ? scaleY(0) - scaleY(d.spend) : scaleY(d.spend) - scaleY(0))
            .attr("fill-opacity", 1)
            .attr("fill", "#193150")

        // //DEBUG: show margins
        // svg.append('rect')
        //     .attr('x', 0)
        //     .attr('y', 0)
        //     .attr('width', width)
        //     .attr('height', height);
        //
        // svg.append('rect')
        //     .attr('x', 0)
        //     .attr('y', 0)
        //     .attr("transform", `translate(${margin.left}, ${margin.top})`)
        //     .attr('width', width-margin.left)
        //     .attr('height', height-margin.top)
        //     .attr('fill', 'red')
    }, [page, data, datahash]);

    // return <div className="advanced-bar-chart-container"  style={{height: height + 'px'}}>
    // return <div className="advanced-bar-chart-container" style={{width: '100%', height: '600px'}}>
    return <div className="advanced-bar-chart-container" style={{width: '100%', height: height + 100 + 'px'}}>
        <div className='tooltip' ref={tooltipRef}/>
        <svg
            className="advanced-bar-chart"
            ref={svgRef}/>
        <table>
            <tbody>
            <tr>
                <TablePagination
                    colSpan={data.length}
                    count={data?.length || 0}
                    page={page}
                    showFirstButton={true}
                    showLastButton={true}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage="Suppliers in view"
                    SelectProps={{
                        inputProps: {'aria-label': 'Suppliers in view'},
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </tr>
            </tbody>
        </table>
    </div>;
});
