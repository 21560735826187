import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {CommonSuppliersDelegate} from "./synergy/CommonSuppliersDelegate";
import {CommonCategoriesDelegate} from "./synergy/CommonCategoriesDelegate";
import {AxoisRequestManager} from "./managers/RequestManager";
import {PageResponse} from "../services/ApiTypes";
import {
    CommonParentSupplier, SupplierReviewRow
} from "../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {from} from "rxjs";
import {initialListFilters, StringArrayFilter} from "../services/ApiHelpers";

export class SynergyStore {
    requestedSynergyId?: number = this.profileStore.p.hardcodedSynergyId || 1;
    openRowSpId: string = '';

    readonly datasetNameLeft = this.profileStore.p.hardcodedDatasetNameLeft || 'None'
    readonly datasetNameRight = this.profileStore.p.hardcodedDatasetNameRight || 'None'
    readonly commonSuppliersDelegate = new CommonSuppliersDelegate(this, this.bagStore, this.matApi, this.profileStore)
    readonly commonCategoriesDelegate = new CommonCategoriesDelegate(this, this.bagStore, this.matApi, this.profileStore)
    readonly commonParentSupplierRM = new AxoisRequestManager<{
        filters: string[][]
    }, PageResponse<CommonParentSupplier>>(
        ({filters}) => from(this.matApi.listCommonSupplier(filters)));
    commonParentSupplierFilters: StringArrayFilter = new StringArrayFilter(this.commonParentSupplierRM, initialListFilters(25, [['sp_n_suppliers__gt', '1']]));
    readonly commonSupplierRM = new AxoisRequestManager<{
        filters: string[][]
    }, PageResponse<SupplierReviewRow>>(
        ({filters}) => from(this.matApi.listSupplierReviewRows(filters)));
    commonSupplierFilters: StringArrayFilter = new StringArrayFilter(this.commonSupplierRM);

    constructor(
        private bagStore: BagStore,
        private profileStore: ProfileStore,
        private matApi: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }
}
