import {styled} from "@mui/material/styles";
import {observer} from "mobx-react-lite";
import {Button, Dialog, Grid, IconButton, PaperProps, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {AutoFixHigh, CallMerge, Close, CompareArrows, Delete, Edit, Layers} from "@mui/icons-material";
import React from "react";

export const BaseModal: React.FC<{
    className?: string

    isOpen: boolean,

    paperProps?: Partial<PaperProps>,

    onSave: () => void
    saveDisabled?: boolean
    saveTitle?: string,

    onCancel: () => void
    cancelDisabled?: boolean
    cancelTitle?: string,

    title: string
    titleId?: string

    isLoading?: boolean
}> = ({
          className,
          isOpen,
          onSave,
          saveDisabled,
          saveTitle,
          onCancel,
          cancelDisabled,
          cancelTitle,
          title,
          titleId,
          isLoading,
          paperProps,
          children,
      }) => {
    className = "edit-model-component" + (className ? ' ' + className : '');
    titleId = titleId || 'generic-modal';

    const {taxonomyEditorStore, taxonomyHealthCheckStore} = useStores();

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            aria-labelledby={titleId}
            maxWidth="sm"
            PaperProps={paperProps as any}
        >
            <Grid container className={className}>
                <Grid item xs={10} container direction="column" justifyContent="flex-start" alignItems="stretch"
                      spacing={3}>
                    <Grid container item>
                        <Grid item xs={11}>
                            <Typography id={titleId || ''} variant="h5">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => {
                                taxonomyHealthCheckStore.isModifyCategoryModalOpen = false;
                            }}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
                <Grid container item xs={10} spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                            // disabled={otherButtonDisabled || isLoading}
                            startIcon={<Edit/>}
                            onClick={() => {
                                taxonomyHealthCheckStore.isModifyCategoryModalOpen = false;
                                taxonomyHealthCheckStore.isRenameCategoryModalOpen = true;
                            }}
                            variant="outlined"
                        >
                            Rename
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                            // disabled={otherButtonDisabled || isLoading}
                            startIcon={<CallMerge style={{transform: 'rotate(90deg)'}}/>}
                            onClick={() => {
                                taxonomyHealthCheckStore.isModifyCategoryModalOpen = false;
                                taxonomyHealthCheckStore.isMergeCategoryModalOpen = true;
                            }}
                            variant="outlined"
                        >
                            Merge
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                            // disabled={otherButtonDisabled || isLoading}
                            startIcon={<CompareArrows/>}
                            onClick={() => {
                                taxonomyHealthCheckStore.isModifyCategoryModalOpen = false;
                                taxonomyHealthCheckStore.isMoveCategoryModalOpen = true;
                            }}
                            variant="outlined"
                        >
                            Move
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                            startIcon={<Layers/>}
                            // onClick={onOtherAction3}
                            disabled={true}
                            variant="outlined"
                        >
                            Exclude
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                            startIcon={<AutoFixHigh/>}
                            disabled={true}
                            // onClick={onOtherAction3}
                            variant="outlined"
                        >
                            Fix
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                color: 'red',
                                borderColor: 'red',
                            }}
                            // disabled={otherButtonDisabled || isLoading}
                            startIcon={<Delete/>}
                            onClick={() => {
                                taxonomyHealthCheckStore.isModifyCategoryModalOpen = false;
                                taxonomyHealthCheckStore.isDeleteCategoryModalOpen = true;
                            }}
                            variant="outlined"
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>


            </Grid>
        </Dialog>
    );
}

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent:
        `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(BaseModal)(({
                                                          theme
                                                      }) => ({
    // [`& .${classes.typography}`]:
    //     {
    //         padding: theme.spacing(1),
    //     }
    // ,
    //
    // [`& .${classes.popoverContent}`]:
    //     {
    //         margin: 10,
    //         background:
    //             'transparent',
    //     }
}));

export const ModalModifyCategory: React.FC = observer(() => {

    const {taxonomyHealthCheckStore} = useStores();


    return (
        // <StyledEditorModalComponent
        //     titleId={"run-ai-modal-title"}
        //     className="delete-category-modal-component"
        //     isOpen={taxonomyEditorStore.isModifyCategoryModalOpen}
        //     onSave={() => {
        //     }}
        //     saveTitle={"Delete"}
        //     onCancel={() => taxonomyEditorStore.isModifyCategoryModalOpen = false}
        //     title={"Modify category"}
        // >
        //     {/*<Typography variant={'body1'}>*/}
        //     {/*    Select the action to modify the category.*/}
        //     {/*</Typography>*/}
        //
        //
        //
        // </StyledEditorModalComponent>

        <BaseModal
            titleId={"run-ai-modal-title"}
            className="delete-category-modal-component"
            isOpen={taxonomyHealthCheckStore.isModifyCategoryModalOpen}
            onSave={() => {
            }}
            saveTitle={"Delete"}
            onCancel={() => taxonomyHealthCheckStore.isModifyCategoryModalOpen = false}
            title={"Modify category"}
        >

        </BaseModal>
    );
})
