import {makeAutoObservable, runInAction} from "mobx";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {ContractAnalyticsStore} from "./ContractAnalyticsStore";
import {ContractInsightsKpiSerializer} from "../../services/classes/ContractClasses";
import ProfileStore from "../ProfileStore";
import {AxoisRequestManager} from "../managers/RequestManager";
import {from} from "rxjs";
import {Firestore} from "@firebase/firestore";
import {collection, deleteDoc, doc, getDocs, query, updateDoc} from "firebase/firestore";
import {ContractData, ContractRecord} from "../../pages/contract-insights/classes";
import {MultiTypedSearchManager} from "../../components/search/MultiTypedSearchManager";


export type ContractOverviewFilter = {
    search: string
}

export class ContractInsightsStore {
    contracts: ContractRecord[] | null = null;

    // Contract upload
    contractUploadDialogIsOpen: boolean = false;
    errorsToShow: string[] = [];
    dataFileId: number | undefined = undefined;
    datasetName: string | undefined = undefined;

    kpi: ContractInsightsKpiSerializer | undefined = undefined;

    //Modals
    modalContractsDetailsData: ContractRecord | null = null;
    modalContractsEditData: ContractRecord | null = null;

    searchManager = new MultiTypedSearchManager(
        this.profile.supplierSearchSpecification,
        () => this.onSearch(),
    );

    readonly contractAnalytics: ContractAnalyticsStore = new ContractAnalyticsStore(this, this.api);

    readonly contractsDemoDataManager = new AxoisRequestManager<void, ContractData[]>(() => from(this.api.getSimpleContractData()));
    private db: Firestore | null = null;

    constructor(
        private api: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this);
    }

    resetErrorToShow() {
        this.errorsToShow = [];
    }

    pushErrorToShow(error: string) {
        this.errorsToShow.push(error);
    }

    setDataFileId(dataFileId: number) {
        this.dataFileId = dataFileId;
    }

    setDatasetName(datasetName: string) {
        this.datasetName = datasetName;
    }

    editContractOnSave() {
        //Call Api to save
        console.log('editContractOnSave');
    }

    onSearch() {
        console.log('onSearch');
    }

    setDetailModalData(contract: ContractRecord | null) {
        this.modalContractsDetailsData = contract
    }

    setEditModalData(contract: ContractRecord | null) {
        this.modalContractsEditData = contract
    }

    setContractUploadDialogIsOpen(contractUploadDialogIsOpen: boolean) {
        this.contractUploadDialogIsOpen = contractUploadDialogIsOpen;
    }


    setContractsData(data: ContractData[]) {
        this.contracts = data.map(d => new ContractRecord(d, d.id ?? d.contract_id ?? d.contract_title));
    }

    setContractRecords(contracts: ContractRecord[] | null) {
        this.contracts = contracts;
    }

    init(db: Firestore) {
        this.db = db;
        return this.getContractsData();
    }

    updateContractsData() {
        if (!this.db) throw new Error('Firestore not initialized')
        console.log('updating contract information')
        const contractsRef = collection(this.db, 'contract');
        return getDocs(query(contractsRef))
            .then(result => runInAction(() =>
                result.forEach(r => {
                    const data = r.data() as ContractData
                    const record = this.contracts?.find(c => c.id === r.id)
                    if (record) {
                        Object.assign(record.data, data)
                    } else {
                        this.contracts?.push(new ContractRecord(data, r.id))
                    }
                })
            ))
    }

    getContractsData() {
        if (!this.db) throw new Error('Firestore not initialized')
        const contractsRef = collection(this.db, 'contract');
        return getDocs(query(contractsRef))
            .then(result =>
                this.setContractRecords(result.docs.map(d =>
                    new ContractRecord(d.data() as ContractData, d.id)
                ))
            )
    }

    storeContractDetailsData(data: [string, string][], record: ContractRecord) {
        if (!this.db) throw new Error('Firestore not initialized')
        const ref = doc(this.db, 'contract', record.id)
        // Only update those fields that have changed
        const dataToUpdate: Record<string, string> = {}
        data.forEach(([k, v]) => {
            // eslint-disable-next-line eqeqeq
            if (record.data[k] != v) {
                dataToUpdate[k] = v
            }
        })
        console.log('storeContractDetailsData', record.id, dataToUpdate)
        return updateDoc(ref, dataToUpdate)
            .then(() => runInAction(() =>
                Object.assign(record.data, dataToUpdate)
            ))
    }

    deleteContractRecord(record: ContractRecord) {
        if (!this.db) throw new Error('Firestore not initialized')
        const ref = doc(this.db, 'contract', record.id)
        return deleteDoc(ref)
            .then(() => runInAction(() => {
                const index = this.contracts?.findIndex(c => c.id === record.id)
                if (index !== undefined) {
                    this.contracts?.splice(index, 1)
                }
            }))
    }

    deleteAllContracts() {
        if (!this.db) throw new Error('Firestore not initialized')
        const contractsRef = collection(this.db, 'contract');
        return getDocs(query(contractsRef))
            .then(result =>
                Promise.all(result.docs.map(d => deleteDoc(d.ref)))
            )
            .then(() => this.setContractRecords([]))
    }
}
