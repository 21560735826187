import './FinancialFilters.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {useSitStores} from "../../../stores";
import {CategoryLabel, Cluster, Company, DOI, DoiStatus, ValueType} from "../../ApiTypes";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {SIT_TEST} from "../../SitApit";

export const FinancialFilters: React.FC = observer(() => {
    const {ccStore, fbStore} = useSitStores();

    return <Grid container spacing={1} className="financial-filters-component" alignItems="center">
        {SIT_TEST && '<FinancialFilters/>'}
        {/*<div>*/}
        {/*    filters={JSON.stringify(fbStore.filters.filters)}*/}
        {/*</div>*/}
        <Grid item>
            <FormControl className="analysis-year-input">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Analysis year"
                        value={dayjs(new Date(fbStore.year, 0))}
                        onChange={(value) => {
                            if (value) {
                                fbStore.setYear(value.toDate().getFullYear());
                            }
                        }}
                        views={['year']}
                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="savings-input">
                <InputLabel id="savings-label">Savings</InputLabel>
                <Select
                    labelId="savings-label"
                    value={fbStore.activeValueType}
                    label="Savings"
                    onChange={(event) => {
                        fbStore.setActiveValueType(event.target.value as ValueType);
                    }}
                >
                    <MenuItem value="rolling">Rolling</MenuItem>
                    <MenuItem value="fiscal_year">Fiscal Year</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="probability-input">
                <FormControlLabel
                    control={
                        <Checkbox checked={fbStore.probabilityAdjusted} onChange={event => {
                            fbStore.setProbabilityAdjusted(event.target.checked)
                        }}/>
                    }
                    label="Probability adjusted"
                />
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="cluster-input">
                <Autocomplete
                    multiple
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="cluster"
                    limitTags={1}
                    options={ccStore.clusterRM.result ?? ([] as Cluster[])}
                    getOptionLabel={(option) => option.title}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={fbStore.combinedClusters}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Cluster"
                                   placeholder="Select Cluster"/>}
                    renderOption={(props, option, {selected}) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={fbStore.isCheckedClusters(option.id)}
                                indeterminate={fbStore.isIndeterminateCluster(option.id)}
                            />
                            {option.title}
                        </li>}
                    onChange={(event, value, reason) =>
                        fbStore.setClusters(value, reason === 'removeOption')
                    }/>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="company-input">
                <Autocomplete
                    multiple
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="company"
                    limitTags={1}
                    options={(ccStore.companyRM.result ?? []) as Company[]}
                    getOptionLabel={(option) => option.title}
                    value={fbStore.activeFilteredCompanies}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Company"
                                   placeholder="Select Company"/>}
                    renderOption={(props, option, {selected}) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={(fbStore.activeFilteredCompanyIds?.indexOf(option.id) ?? -1) > -1}
                            />
                            {option.title}
                        </li>}
                    onChange={(event, value) => {
                        fbStore.setActiveFilteredCompanies(value.map(c => c.id));
                    }}/>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="scope-input">
                <InputLabel id="savings-label">Scope</InputLabel>
                <Select
                    labelId="savings-label"
                    value={fbStore.scope}
                    label="Scope"
                    onChange={(event) => {
                        fbStore.setScope(event.target.value as 'local' | 'global');
                    }}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="local">Local</MenuItem>
                    <MenuItem value="global">Global</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="doi-input">
                <InputLabel id="savings-label">Stage</InputLabel>
                <Select
                    labelId="savings-label"
                    value={fbStore.doi ?? ''}
                    label="Stage"
                    onChange={(event) => {
                        const v = event.target.value;
                        fbStore.setDoiStage(v ? Number(v) as DOI : null);
                    }}>
                    <MenuItem value="">All</MenuItem>
                    {DoiStatus.map((value, index) =>
                        <MenuItem
                            key={index}
                            value={index + 1}>
                            {index + 1}: {value}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="lever-input">
                <InputLabel id="lever-group-label">Lever groups</InputLabel>
                <Select
                    labelId="lever-group-label"
                    disabled={!ccStore.leverGroupRM.result}
                    multiple
                    value={fbStore.activeLeverGroups.map(lg => lg.id)}
                    onChange={(event) => {
                        // On autofill we get a stringified value.
                        const value: string | number[] = event.target.value;
                        const v: number[] = (typeof value === 'string' ? value.split(',').map(v => Number(v)) : value);
                        fbStore.setActiveLeverGroupIds(v);
                    }}
                    input={<OutlinedInput label="Lever groups"/>}
                    renderValue={(selected) => fbStore.activeLeverGroups.map(lg => lg.title).join(', ')}
                    // MenuProps={MenuProps}
                >
                    {ccStore.leverGroupRM.result?.map((lever_group) =>
                        <MenuItem key={lever_group.id} value={lever_group.id}>
                            <Checkbox checked={fbStore.activeLeverGroups.findIndex(l => l.id === lever_group.id) > -1}/>
                            <ListItemText primary={lever_group.title}/>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            {/*<pre>*/}
            {/*    Active={JSON.stringify(fbStore.activeCategories)}*/}
            {/*</pre>*/}
            <FormControl className="category-input">
                <Autocomplete
                    multiple
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="category"
                    limitTags={1}
                    disabled={!ccStore.allCategories}
                    options={(ccStore.allCategories ?? []) as CategoryLabel[]}
                    getOptionLabel={(category) => category.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={fbStore.activeCategories}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Category"
                            placeholder="Select Category"
                        />
                    }
                    renderOption={(props, category) =>
                        <li key={category.id} {...props}>
                            <Checkbox checked={fbStore.activeCategories.findIndex(c => c.id === category.id) !== -1}/>
                            {category.label}
                        </li>
                    }
                    onChange={(event, value, reason) => fbStore.onChangeActiveCategory(value, reason)}/>
            </FormControl>
        </Grid>
        <Grid item>
            <FormControl className="approved-input">
                <InputLabel id="approved-label">Approval</InputLabel>
                <Select
                    labelId="approved-label"
                    value={fbStore.approved}
                    label="Approval"
                    onChange={(event) => {
                        fbStore.setApproved(event.target.value as '' | 'approved' | 'pending');
                    }}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    </Grid>
});