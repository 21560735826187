import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {MatSupplierFilter_V2} from "../services/classes/MatReviewClasses";
import {MatSupplierCategoryConcentrationStatistics} from "../services/classes/MaterializedClasses";
import {PageResponseManager} from "./managers/PageResponseManager";
import {UNCATEGORIZED_LABEL} from "../constants";
import {TaxonomyTableVizDelegate} from "./TaxonomyTableVizDelegate";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {taxonomy_suggester_v2} from "../services/classes/TaxonomySuggesterClassesV2";
import {ApiSuggestionTreeResponse} from "../services/ApiHelpers";
import {environment} from "../env";
import {mithra_http} from "../services/Http";
import {ChangeEvent} from "react";
import ExtendedD3HierarchyNode = taxonomy_suggester_v2.ExtendedD3HierarchyNode;

const hardcodedPaginatedTableRows = 30

export default class TaxonomySuggesterStoreV2 {
    taxonomySuggesterV2ApiResponse: taxonomy_suggester_v2.SuggesterV2ApiResponse | undefined = undefined

    selectedCategory: ExtendedD3HierarchyNode | undefined = undefined;

    taxonomyTableController: TaxonomyTableVizDelegate = new TaxonomyTableVizDelegate()
    SUGGESTER_V2_COLUMNS: ColSpec[] = [
        { cls: 'col-label', txt: 'Category', width: '35%' },
        { cls: 'col-spend', txt: 'Estimated Total spend', width: '10%' },
        { cls: 'col-p_count', txt: 'Estimated Suppliers', width: '10%' },
        { cls: 'col-s_count', txt: 'Estimated Transactions', width: '10%' },
        // { cls: 'col-control_buttons', txt: 'Suggester Status', width: '10%' },
        { cls: 'col-control_buttons', txt: 'Sources', width: '10%' },
        { cls: 'col-control_buttons', txt: '', width: '25%' },
    ]
    isDeleteCategoryModalOpen: boolean = false;
    isMoveCategoryModalOpen: boolean = false;
    isMergeCategoryModalOpen: boolean = false;
    isRenameCategoryModalOpen: boolean = false;
    isModifyCategoryModalOpen: boolean = false;
    steps: string[] = ['select', 'process', 'show'];
    activeStep: number = 0;

    /**
     * TODO: For now we re-use an spend concentration endpoint... this should be changed to a new endpoint
     */
    readonly _spendConcentrationSupplierRequestManager = new PageResponseManager<MatSupplierFilter_V2, MatSupplierCategoryConcentrationStatistics>(
        hardcodedPaginatedTableRows,
        (page, filter) => this.api.listPaginatedSpendConcentrationSupplier(filter, page, hardcodedPaginatedTableRows),
    );

    applyButtonText = "Apply"
    disableApply = false
    levelsNumber: number = 3;
    taxonomyList: string[] = ['ERP 1', 'ERP 2', 'ERP 3'];
    
    
    handleLevelChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const key = event.target.value;
        this.levelsNumber = parseInt(key); // Assuming levelsNumber is a number
    }
    
    handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0]; // Check if files exist before accessing the first file
        const fileName = file ? file.name : ''; // Retrieve the file name if it exists
        
        // Handle the uploaded file
        console.log('Uploaded file:', file);
        console.log('File name:', fileName);
        console.log(this);
        if (fileName != '') {
            const fileNameWithoutExtension = fileName.split(".")[0];
            this.taxonomyList.push(fileNameWithoutExtension);
            console.log("done")
        } else {
            console.log("nodone")
        }
    }
    
    handleAddTaxonomy = (taxonomyName: string) => {
        if (taxonomyName != '') {
            this.taxonomyList.push(taxonomyName);
            console.log("done")
        } 
    }
    
    numberofSuggestions: number = 1;
    processingText: string = 'Loading Inputs ...';
    increaseNumberofSuggestions () {
        this.numberofSuggestions += 1;
    }
    
    downloadFile() {
        if(environment.package !== 'sales_demo') {
            throw new Error('This function is only available in the sales demo environment')
        }

        // Define the file path
        console.log(window.location.href);
        const filePath = `${process.env.PUBLIC_URL}/sales_demo/taxonomy-suggestion/taxonomy_suggestion_v2.csv`;
    
        // Create a link element
        const link = document.createElement("a");
        link.href = filePath;
    
        // Set the download attribute to indicate the filename
        link.download = "taxonomy_suggestion.csv";
    
        // Programmatically trigger the click event on the link
        document.body.appendChild(link);
        link.click();
    
        // Clean up: remove the link from the document body
        document.body.removeChild(link);
    }
    
    
    // setActiveStep(newStep: any) {
    //     this.activeStep = newStep;
    // }
    setActiveStep(newStep: any) {
        if (newStep === 1) {
            this.activeStep = newStep;
            setTimeout(() => {
                this.processingText = 'Doing Health Checks ...'
            }, 2000);
            
            setTimeout(() => {
                this.processingText = 'Deduplicating ...'
            }, 10000);
            
            setTimeout(() => {
                this.processingText = 'Clustering ...'
            }, 40000);
            
            setTimeout(() => {
                this.processingText = 'Running the Main Algorithm ...'
            }, 300000);
        } else {
            // If newStep is not 1, directly update the activeStep
            this.activeStep = newStep;
        }
    }

    setApplyButtonText(applyButtonText: string) {
        this.applyButtonText = applyButtonText
    }

    setDisableApply(disableApply: boolean) {
        this.disableApply = disableApply
    }

    // noinspection JSUnusedLocalSymbols
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async getTaxonomySuggesterV2() {
        if(environment.package !== 'sales_demo') {
            throw new Error('This function is only available in the sales demo environment')
        }
        const taxonomySuggestionResp = await mithra_http.mockApi.get('sales_demo/taxonomy-suggestion/taxonomy_suggestion_v2.json');
        const suggestion: taxonomy_suggester_v2.SuggesterV2ApiResponse = {
            id: -1,
            taxonomy: -1,
            create_date: String(new Date()),
            last_update: String(new Date()),
            suggester_state_v2: taxonomySuggestionResp.data
        }
        this.setSuggesterV2TaxonomyApiResponse(suggestion)
    }

    // async putTaxonomySuggesterV2(data: taxonomy_suggester_v2.SuggesterV2ApiResponse) {
    //     if(!this.taxonomySuggesterV2ApiResponse?.id) return;
    //     const resp = await this.api.putTaxonomySuggesterV2(this.taxonomySuggesterV2ApiResponse.id, data);
    //     this.setSuggesterV2TaxonomyApiResponse(resp.data)
    // }

    // async applyTaxonomySuggesterV2(taxonomySuggesterV2Id: number) {
    //     return await this.api.applyTaxonomySuggesterV2(taxonomySuggesterV2Id).then(() => {
    //         console.log("changes applied!")
    //         return
    //     }).catch((error) => {
    //         console.error('Error while getting taxonomy suggester-v2');
    //         console.error(error);
    //         throw error;
    //     })
    // }

    get hasSuggester() {
        return this.taxonomySuggesterV2ApiResponse !== undefined && this.taxonomyTableController.data !== undefined;
    }

    get suggestionKpis(): {
        numberOfLabelFormatting: number,
        numberOfPrefixOverlaps: number, // TODO: Update kpi names to be aligned with health check design
        numberOfCategoryOverlap: number
    } {
        let numberOfPrefixOverlaps = 0;
        let numberOfLabelFormatting = 0;
        let numberOfCategoryOverlap = 0;
        const iterateSuggestions = (suggestion: ApiSuggestionTreeResponse<taxonomy_suggester_v2.APIValues>) => {
            if (suggestion.values.review_status?.label_formatting) {
                numberOfLabelFormatting += 1;
            }
            if (suggestion.values.review_status?.prefix_overlap) {
                numberOfPrefixOverlaps += 1;
            }
            if (suggestion.values.review_status?.category_overlap) {
                numberOfCategoryOverlap += 1;
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        if (this.taxonomySuggesterV2ApiResponse?.suggester_state_v2) {
            iterateSuggestions(this.taxonomySuggesterV2ApiResponse.suggester_state_v2);
        }
        return {numberOfPrefixOverlaps, numberOfLabelFormatting, numberOfCategoryOverlap};
    }

    /**
     * @param taxonomyApiResponse taxonomy_health_check.HealthCheckApiResponse
     */
    setSuggesterV2TaxonomyApiResponse(taxonomyApiResponse: taxonomy_suggester_v2.SuggesterV2ApiResponse) {
        this.taxonomyTableController.data = TaxonomyTableVizDelegate.initNodes(taxonomyApiResponse.suggester_state_v2);
        this.taxonomySuggesterV2ApiResponse = taxonomyApiResponse; // We probably do not need this...
    }

    resetSuggesterV2() {
        this.taxonomyTableController.data = undefined;
        this.taxonomySuggesterV2ApiResponse = undefined;
    }

    /**
     * Applies the filter, going down the tree
     */
    //FIXME: Consider refactoring this function to also show the filtered node as collapsed instead of hiding them (Consider using TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true) or CloseAll(node))
    applyFilter(node: ExtendedD3HierarchyNode, hideFilter: undefined | ((d: ExtendedD3HierarchyNode) => boolean), depth = 0, hideUncat?: boolean,): boolean {
        let hideThis = false;
        let canOpen = false;
        if (hideUncat) {
            hideThis = hideThis || (node.data.viz.label === UNCATEGORIZED_LABEL);
        }

        //Hide everything else
        if (hideFilter && !hideThis && depth > 0) {
            hideThis = hideThis || !hideFilter(node)
        }

        // Hide everything else
        node.data.viz.filtered = false;
        if (node._children) {
            let hasUnhiddenChildren = false;
            node._children.forEach(c => {
                if (!this.applyFilter(c, hideFilter, depth + 1, hideUncat)) {
                    hasUnhiddenChildren = true;
                    hideThis = false;
                } else {
                    if (hideThis) {
                        this.setHidden(node, true, depth + 1)
                        node.data.viz.filtered = true;
                    }
                }
            })
            if (hasUnhiddenChildren) {
                canOpen = true;
                node.data.viz.filtered = false;
            }
        }

        node.data.viz.canOpen = canOpen;
        return hideThis;
    }

    setHidden(d: ExtendedD3HierarchyNode, hidden: boolean, depth: number) {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }
    }

    setCategoryToModify(d: ExtendedD3HierarchyNode) {
        this.selectedCategory = d;
    }

    toggleDeleteCategoryModal(b: boolean) {
        this.isDeleteCategoryModalOpen = b;
    }

    toggleMoveCategoryModal(b: boolean) {
        this.isMoveCategoryModalOpen = b;
    }

    toggleMergeCategoryModal(b: boolean) {
        this.isMergeCategoryModalOpen = b;
    }

    toggleRenameCategoryModal(b: boolean) {
        this.isRenameCategoryModalOpen = b;
    }

    toggleModifyCategoryModal(b: boolean) {
        this.isModifyCategoryModalOpen = b;
    }

    createNewTaxonomySuggesterV2(taxonomyId: number) {
        return this.api.createNewTaxonomyHealthCheck(taxonomyId)
    }

    /**
     * The FE internal representation of the tree is converted to the JSON that needs to be send to the BE
     * @param node
     * @private
     */
    private static exportTree(node: ExtendedD3HierarchyNode): ApiSuggestionTreeResponse<taxonomy_suggester_v2.APIValues> {
        return {
            id: node.data.id,
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => TaxonomySuggesterStoreV2.exportTree(c)),
            values: node.data.apiValues,
            sources: node.data.sources,
        }
    }
}
