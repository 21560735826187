import {C} from "../configurations/active-configuration";
import {TaxonomyMapperStore} from "../pages/taxonomy-mapper/store/TaxonomyMapperStore";
import {stores} from "./index";
import {AppState} from "./RootAppStore";

if (C.package === 'merge_x') {
    // For now this is fixed to 3 datasets for SHV
    // (stores as any).sub1_new_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 1, 7, 'sub1NewTM');
    (stores as any).sub2_new_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 2, 7, 'sub2NewTM');
    (stores as any).sub3_new_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 3, 7, 'sub3NewTM');
    // (stores as any).sub1_old_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 1, 6, 'sub1OldTM');
    // (stores as any).sub2_old_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 2, 6, 'sub2OldTM');
    // (stores as any).sub3_old_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 3, 6, 'sub3OldTM');
    (stores as any).old_new_Store = new TaxonomyMapperStore(stores.materializedApi, stores.authStore, stores.p, stores.bagStore, stores.taxonomyManagerStore, 6, 7, 'oldNewTM');
}

/**
 * For the merge_x package offering we have multiple TaxonomyMapperStores
 * This function returns the correct one based on the current dataType from the URL
 * @param appState
 */
export function hackGetTaxonomyMapperStore(appState: AppState): TaxonomyMapperStore {
    if (C.package === 'merge_x') {
        console.warn(`hackGetTaxonomyMapperStore activated on dataType=${appState.dataType}`)
        switch (appState.dataType) {
            case 'sub1_new':
                return (stores as any).sub1_new_Store;
            case 'sub2_new':
                return (stores as any).sub2_new_Store;
            case 'sub3_new':
                return (stores as any).sub3_new_Store;
            case 'sub1_old':
                return (stores as any).sub1_old_Store;
            case 'sub2_old':
                return (stores as any).sub2_old_Store;
            case 'sub3_old':
                return (stores as any).sub3_old_Store;
            case 'old_new':
                return (stores as any).old_new_Store;
        }
    }
    return stores.taxonomyMapperStore;
}