import {makeAutoObservable} from "mobx";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";

export class InitiativeSkuController {
    editing = true;

    constructor(public iis: InitiativeInstanceStore, public index: number) {
        makeAutoObservable(this);
    }

    get p_id(): string {
        return this.iis.instance.skus![this.index];
    }

    set p_id(value: string) {
        this.iis.instance.skus![this.index] = value;
    }

    toggleEdit() {
        this.editing = !this.editing;
    }
}
