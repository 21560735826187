import {makeAutoObservable} from "mobx";
import {numOrDefault} from "../../../components/table/utils";
import ProfileStore from "../../ProfileStore";

export class ByYearFilterField {
    constructor(private profileStore: ProfileStore) {
        makeAutoObservable(this)
    }

    fieldValue = '';
    fieldError = '';

    // By default, it filters on "All" years
    // fieldEnabled = true;

    setFieldValue(value: string | number) {
        this.fieldValue = String(value);
        // this.fieldEnabled = true;
    }

    get value(): number | null {
        return numOrDefault(this.fieldValue, null)
    }

    // setFieldEnabled() {
    //     this.fieldEnabled = true;
    // }

    // toggleEnabled() {
    //     this.fieldEnabled = !this.fieldEnabled;
    // }

    validate() {
        this.fieldError = this.isFieldValid(this.fieldValue);
    }

    /**
     * Shows of this filter is enabled in this configuration or not
     */
    get filterFieldEnabled() {
        return this.profileStore.p.filterConfiguration?.type !== 'by_year';
    }

    /**
     * All options (including the "all" option)
     */
    get options(): { value: number | '', label: string }[] {
        const result = [
            {value: '', label: 'All'},
        ] as { value: number | '', label: string }[];

        const config = this.profileStore.p.filterConfiguration;
        if (config && config.type === 'by_year') {
            result.push(...config.options.map(year => ({value: year, label: String(year)})))
        }
        return result;
    }

    get isConfigured(): boolean {
        return this.profileStore.p.filterConfiguration?.type === 'by_year'
    }

    /**
     * @return string Error message
     */
    private isFieldValid(value: string): string {
        // Not really usefull, as we use the options to select a value...
        // But a good template for other fields

        if (value === '') {
            return '';
        }

        const num = Number(value);
        if (isNaN(num)) {
            return 'Not a number';
        }
        const option = this.options.find(o => o.value === num);
        if (!option) {
            return `Must be one of ${JSON.stringify(this.options.map(o => o.value))}`;
        }
        return ''; // Ok
    }
}
