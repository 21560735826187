import {ColSpec} from "../../../../components/table/MithraTableHeadColumns";
import ProfileStore from "../../../../stores/ProfileStore";

export function getCatReviewColumns(p: ProfileStore, viewOnly: boolean): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        viewOnly ? undefined : {cls: 'col-selection', txt: '', align: 'center'},
        {cls: 'col-dropdown', txt: '', align: 'center'},
        {cls: 'col-s_name', width: p.p.s_name_col_width, txt: p.supplierDataName},
        {cls: 'col-s_spend', txt: 'Total spend'},
        {cls: 'col-l1s', txt: 'L1(s) of vendor'},
        {cls: 'col-bus', txt: p.buDataName},
        {cls: 'col-s_cats', txt: 'Suggested categories'},
        {cls: 'col-review-status', txt: 'Approval', align: 'center' as const},
        {cls: 'col-review-buttons', txt: ''},
    ]
    return arr.filter(c => c) as ColSpec[];
}

/**
 * TODO: Move this to the config
 * TODO: DO NOT MERGE to develop
 */
export function getCatReviewSubColumns(p: ProfileStore, bagId: number | undefined, singular: boolean, viewOnly: boolean): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        viewOnly ? undefined : {cls: 'col-selection', txt: '', align: 'center' as const} as ColSpec,
        (!singular) ? undefined : {cls: 'col-s_name', width: p.p.s_name_col_width, txt: p.supplierDataName, columnFieldId: 's_name'},
        {cls: 'col-p_name', txt: p.p.p_name_col_name, width: p.p.p_name_col_width, columnFieldId: 'p_name'},
        {cls: 'col-p_spend', txt: 'Amount', width: 90, columnFieldId: 'p_spend'},
        {cls: 'col-p_ddate_1', txt: 'Date', width: p.p.p_ddate_1_col_width ?? 30, columnFieldId: 'p_ddate_1'},
        p.p.p_description_col_name !== false ? {
            cls: 'col-p_description',
            txt: p.p.p_description_col_name,
            width: p.p.p_description_col_width,
            columnFieldId: 'p_description'
        } : undefined,
    ].concat(p.partContextColNames.map(({colName, colClassName, dataField}) =>
        ({cls: colClassName, txt: colName, columnFieldId: dataField})
    )).concat(
        p.p.categorizationReviewSubRowRelationData && ([] as Array<ColSpec>).concat(
            ...p.p.categorizationReviewSubRowRelationData!.map(related_data_item =>
                related_data_item.columns
                    .map(column => column.colSpec)
                    .filter(c => c) as ColSpec[]
            )
        )
    ).concat([
        p.p.show_bu_name_in_review_pages
            ? {cls: 'col-p_bu_name', txt: p.buDataName, width: p.p.p_bu_name_col_width}
            : undefined,
        {cls: 'col-p_previous_l', width: p.p.p_previous_l_col_width, txt: 'Previous category'},
        p.showCategorizationScoreForBag(bagId) ? {
            cls: 'col-p_alg_score',
            width: '2em',
            txt: 'Score',
            columnFieldId: 'p_suggestion_score'
        } : undefined,
        {cls: 'col-p_suggested_l', width: p.p.p_suggested_l_col_width, txt: 'Suggested category'},
        p.p.showReasonColumn ? {cls: 'col-reason', txt: 'Reason'} : undefined,
        {cls: 'col-review-status', txt: 'Approval', align: 'center' as const} as ColSpec,
        {cls: 'col-review-buttons', txt: ''} as ColSpec,
    ]);
    return arr.filter(c => c) as ColSpec[];
}
