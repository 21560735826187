import './TaxonomyEditor.scss'
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useStores} from "../../../stores";
import {DEFAULT_TAXONOMY_EDITOR_OPTIONS, TaxonomyEditorOptions} from "./TaxonomyEditorOptions";
import {TaxonomyTreeEditorController} from "./taxonomy-tree/TaxonomyTreeEditorController";
import {observer} from "mobx-react-lite";

type Props = {
    /**
     * Initial input data
     */
    options?: Partial<TaxonomyEditorOptions>,
};
/**
 * Simple component to hook up a controller which will manage the control flow between d3.js, React and Mobx
 */
export const TaxonomyEditor: React.FC<Props> = observer(({options}) => {
    const {taxonomyEditorStore, taxonomyManagerStore, authStore} = useStores()
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [controller, setController] = useState<TaxonomyTreeEditorController | undefined>(undefined)
    const o = useMemo<TaxonomyEditorOptions>(() => ({
        ...DEFAULT_TAXONOMY_EDITOR_OPTIONS,
        ...options,
    }), [options])

    const taxonomy = taxonomyManagerStore.taxonomy;
    useEffect(() => {
        if (!taxonomy) {
            // If there is no taxonomy initialized, do not setup the controller yet
            return
        }
        if (!controller && wrapperRef.current) {
            const controller = new TaxonomyTreeEditorController(wrapperRef.current, taxonomy, o, taxonomyEditorStore, taxonomyManagerStore, authStore);
            setController(controller)
        } else if (controller) {
            controller.onDataUpdate(taxonomy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxonomy, controller])

    return <div ref={wrapperRef}/>
})
