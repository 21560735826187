import React from 'react';
import {styled} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Bag} from "../../services/classes/Bag";
import {Grid, IconButton, OutlinedInput} from "@mui/material";
import {Add, Check, Edit, Undo} from "@mui/icons-material";
import {SelectDatasetMenu} from "../../components/_tiny/SelectDatasetMenu";

const PREFIX = 'DataSetSelectEditComponent';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    formEditIcon: `${PREFIX}-formEditIcon`
};

const StyledGrid = styled(Grid)(({theme}) => ({
    [`& .${classes.formControl}`]: {
        margin: theme.spacing(1),
        minHeight: '4em',
    },

    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.formEditIcon}`]: {
        margin: theme.spacing(1),
    }
}));

const menuProps = {
    id: 'main-databag-menu',
};

type Props = {
    enableNew?: boolean,
    enableEditName?: boolean,
    bags?: Bag[],
    selectedBagId?: number,
    disabled?: boolean,
    onSelectBag: (b: Bag) => void,
    onPatchBag: (b: Bag) => void,
    onSaveNewBag: (name: string) => void,
    onClickNewBag?: () => void,
    onDiscardNewBag?: () => void,
}
export const DataSetSelectEditComponent: React.FC<Props> = (
    {
        enableNew,
        enableEditName,
        bags, selectedBagId,
        disabled,
        onSelectBag, onPatchBag, onSaveNewBag,
        onClickNewBag, onDiscardNewBag,
    }) => {

    const getBag = (id) => bags?.find(b => b.id === id) as Bag;
    const getSelectedBagName = () => {
        const b = getBag(selectedBagId);
        return b?.name || '';
    }

    const onSelectionChange = (event) => {
        const bagId = event.target.value;
        onSelectBag(getBag(bagId));
    };

    // Internal state:
    const [isEditingName, setIsEditingName] = React.useState<boolean>(false);
    const [isAddingNew, setIsAddingNew] = React.useState<boolean>(false);
    const [editName, setEditName] = React.useState<string>('');
    const [newEditName, setNewEditName] = React.useState<string>('');

    // Internal actions:
    const onEditNameChange = (event) => setEditName(event.target.value);
    const onClickEdit = () => {
        setEditName(getSelectedBagName());
        setIsEditingName(true)
    }
    const onClickAdd = () => {
        // On start of a new DataBag
        const currentNewBag = bags?.find(b => b.id === -1);
        setIsAddingNew(true);
        if (currentNewBag) {
            setEditName(currentNewBag.name);
        } else {
            setEditName(newEditName);
        }
        setIsEditingName(true);
        if (onClickNewBag) onClickNewBag();
    };
    const onClickBack = () => {
        setNewEditName(editName);
        setIsAddingNew(false);
        setIsEditingName(false);
        if (onDiscardNewBag) onDiscardNewBag();
    };
    const onClickSave = () => {
        if (isAddingNew) {
            // Trigger new bag id
            setNewEditName('');
            setIsAddingNew(false);
            onSaveNewBag(editName);
        } else {
            // Patch the current bag
            const oldName = getSelectedBagName();
            if (oldName !== editName) {
                const b = getBag(selectedBagId);
                const patchedBag: Bag = {...b, name: editName};
                onPatchBag(patchedBag);
            }
        }
        setIsEditingName(false)
    };

    const nameLabel = isAddingNew
        ? 'Name of new dataset:'
        : 'Name of existing dataset:'

    return <StyledGrid container className="form-element dataset-select-component dataset-select-edit-component">
        {enableNew && <Grid item>
            {!isAddingNew
                ? <IconButton
                    className={classes.formEditIcon}
                    aria-label="add new"
                    onClick={onClickAdd}
                    disabled={!bags || disabled}>
                    <Add/>
                </IconButton>
                : <IconButton
                    className={classes.formEditIcon}
                    aria-label="undo"
                    onClick={onClickBack}
                    disabled={!bags || disabled}>
                    <Undo/>
                </IconButton>
            }
        </Grid>}
        <Grid item>
            {isEditingName ? <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="dataset-name-label" htmlFor="dataset-name">
                        {nameLabel}
                    </InputLabel>
                    <form onSubmit={onClickSave}>
                        <OutlinedInput
                            id="dataset-name"
                            value={editName}
                            onChange={onEditNameChange}
                            label={nameLabel}
                            disabled={disabled}/>
                    </form>
                </FormControl>
                : bags ? <FormControl className={classes.formControl} variant="standard">
                    <InputLabel id="selected-bag-label">
                        Existing datasets
                    </InputLabel>
                    <SelectDatasetMenu
                        variant="standard"
                        labelId="selected-bag-label"
                        id="selected-bag"
                        value={selectedBagId || ''}
                        onChange={onSelectionChange}
                        // displayEmpty
                        className={classes.selectEmpty + ' select-bag-field'}
                        disabled={disabled}
                        bags={bags}
                        MenuProps={menuProps}
                    />
                    {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
                </FormControl> : <>
                    <InputLabel id="selected-bag-loading-label">
                        &nbsp;
                    </InputLabel>
                    <Select
                        variant="standard"
                        labelId="selected-bag-loading-label"
                        id="selected-bag-loading"
                        className="select-bag-field is-loading"
                        value="Loading..."
                        disabled>
                        <MenuItem value="Loading..."/>
                    </Select>
                </>
            }
        </Grid>
        <Grid item>
            {enableEditName && <>
                {!isEditingName && <IconButton
                    className={classes.formEditIcon}
                    aria-label="button edit"
                    onClick={onClickEdit}
                    disabled={!bags || disabled}>
                    <Edit/>
                </IconButton>}
                {isEditingName && <IconButton
                    className={classes.formEditIcon}
                    aria-label="button save"
                    onClick={onClickSave}
                    disabled={!bags || disabled}>
                    <Check/>
                </IconButton>}
            </>}
        </Grid>
    </StyledGrid>
}
