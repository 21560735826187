import {Alert, Button} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {
    CategorizationReviewPageSelectionController
} from "../../../../controllers/CategorizationReviewPageSelectionController";
import {useStores} from "../../../../../../stores";

type Props = {}
export const AllGroupedSelectionAlert: React.FC<Props> = observer(() => {
    const {categorizationReviewStore} = useStores()
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;


    if (!reviewPageSelectionController.isAnySelected) {
        return <></> // Do not show the label if nothing is selected
    }

    const nSelected = reviewPageSelectionController.nFullySelectedSuppliers;
    const sPlural = nSelected > 1;
    const _suppliers = `${CategorizationReviewPageSelectionController.SUPPLIER_NAME}${sPlural ? 's' : ''}`;
    const pPlural = reviewPageSelectionController.nSelectedParts > 1;
    const _parts = `${CategorizationReviewPageSelectionController.PART_NAME}${pPlural ? 's' : ''}`;

    let msg: JSX.Element;
    if (nSelected > 0 && reviewPageSelectionController.nSelectedParts > 0) {
        msg = <>
            You have {nSelected} {_suppliers} and {reviewPageSelectionController.nSelectedParts} {_parts} selected
        </>
    } else if (reviewPageSelectionController.nSelectedParts > 0) {
        msg = <>
            You have {reviewPageSelectionController.nSelectedParts} {_parts} selected
        </>;
    } else {
        const all = reviewPageSelectionController.isAllSelected ? 'all ' : '';
        msg = <>
            You have {all && <strong>{all}</strong>}
            {nSelected} {_suppliers} selected
        </>;
    }
    return <Alert
        className="tiny"
        severity="info"
        action={<>
            {reviewPageSelectionController.selectionState === 'all' &&
                <Button
                    variant="text" size="small"
                    onClick={() => reviewPageSelectionController.onSetAllSelected(false)}>
                    Deselect
                </Button>
            }
            {reviewPageSelectionController.selectionState === 'some' &&
                <Button
                    variant="outlined" size="small"
                    onClick={() => reviewPageSelectionController.onSetAllSelected(true)}>
                    Select All
                </Button>
            }
        </>}>
        {/*<p>*/}
        {/*    nSelected: {nSelected} nSelectedParts: {reviewPageSelectionController.nSelectedParts}*/}
        {/*</p>*/}
        {msg}
    </Alert>
})