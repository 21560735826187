import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {HelpComponent} from "../../../components/help-component/HelpComponent";
import SupplierNormalizationSSHelp from "../SupplierNormalizationSSHelp";
import {ChevronRight} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {generateHomePath} from "../../../routing/routing.utils";
import {SupplierNormalizationState} from "./SupplierNormalization.classes";

export const SupplierNormalizationApplyStep: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, supplierNormalizationStore, cmpStore} = useStores();
    const bagId = bagStore.bagId;
    const env = rootAppStore.app.dashEnv;
    const navigate = useNavigate();
    const location = useLocation();
    const isMaster = env === 'nestle' && location.pathname === routes.merge_1_supplier_normalization_master;
    const numberOfSuppliers = isMaster
        ? cmpStore.masterKpi?.n_suppliers
        : supplierNormalizationStore.statistics?.total_suppliers;

    /**
     * CAT-969
     * When threshold is lower than hideSmallStandaloneSpend, do not show standalone KPI's
     */
    const hideStandaloneOverview = p.p.hideSmallStandaloneSpend ? supplierNormalizationStore.statistics && (
        supplierNormalizationStore.statistics.standalone_suppliers <= p.p.hideSmallStandaloneSpend.minStandaloneSuppliers &&
        supplierNormalizationStore.statistics.standalone_spend <= p.p.hideSmallStandaloneSpend.minStandaloneSpend
    ) : false;

    return <section className="page-item step">
        <Grid container justifyContent="space-evenly">
            <Grid item xl={5} lg={8} xs={12}
                  container justifyContent="space-around" style={{alignContent: 'space-around'}}
                  alignItems="center">
                <Grid className="key-value-container text-center font-bigger"
                      item
                      container justifyContent="center">
                    {/* Row 1 */}
                    <Grid item xs={12}
                          container>
                        <Grid item xs={4}>
                            <Typography variant="caption">
                                Reviewed
                                <br/>spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {supplierNormalizationStore.statistics
                                    ? <CurrencyComponent v={supplierNormalizationStore.statistics.reviewed_spend}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{display: "inline-grid"}} className="key-value-with-help">
                                <Typography variant="caption">
                                    {isMaster ? <>
                                        Total
                                        <br/>Suppliers
                                    </> : <>
                                        Suppliers
                                        <br/>reviewed
                                    </>}
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {numberOfSuppliers
                                        ? numberOfSuppliers
                                        : <>&nbsp;</>}
                                </Typography>
                            </div>
                            <div style={{display: "inline-grid", marginLeft: "8px"}}>
                                <HelpComponent helpContent={<Typography component="p">
                                    {isMaster
                                        ? 'The number of suppliers in the dataset.'
                                        : 'The number of suppliers that have been shown in the review of the previous step.'
                                    }
                                </Typography>}/>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="caption">
                                Parent
                                <br/>suppliers
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {supplierNormalizationStore.statistics
                                    ? supplierNormalizationStore.statistics.parent_suppliers
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Row 2 */}
                    {!hideStandaloneOverview &&
                        <Grid item xs={8}
                              container>
                            <Grid item xs={6}>
                                <div style={{display: "inline-grid"}} className="key-value-with-help">
                                    <Typography variant="caption">
                                        Standalone
                                        <br/>suppliers
                                    </Typography>
                                    <Typography variant="h5" className="font-weight-bold key-value">
                                        {supplierNormalizationStore.statistics
                                            ? supplierNormalizationStore.statistics.standalone_suppliers
                                            : <>&nbsp;</>}
                                    </Typography>
                                </div>
                                <div style={{display: "inline-grid", marginLeft: "8px"}}>
                                    <HelpComponent helpContent={<SupplierNormalizationSSHelp/>}/>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{display: "inline-grid"}}>
                                    <Typography variant="caption">
                                        Standalone
                                        <br/>spend
                                    </Typography>
                                    <Typography variant="h5" className="font-weight-bold key-value">
                                        {supplierNormalizationStore.statistics
                                            ? <CurrencyComponent v={supplierNormalizationStore.statistics.standalone_spend}/>
                                            : <>&nbsp;</>}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>

        <div className="text-center page-item-actions">
            <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight/>}
                onClick={() => {
                    navigate(generateHomePath(location, {id: String(bagId)}, rootAppStore.app));
                    supplierNormalizationStore.goToState(SupplierNormalizationState.START);
                }}>
                {p.p.skipAiSupplierNormalizationModel ? 'To dashboard' : 'Back to dashboard'}
            </Button>
        </div>
    </section>
})
