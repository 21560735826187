import {makeAutoObservable, runInAction} from "mobx";
import ProfileStore, {CategorizationReviewSubRowRelationsData} from "../../../stores/ProfileStore";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import AuthStore from "../../../stores/AuthStore";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {environment} from "../../../env";
import {
    MatPartFilter,
    MatSupplierFilter,
    StorePartReviewManySerializer,
    StoreReviewSerializer,
} from "../../../services/classes/MatReviewClasses";
import {concatMap, from, map, mergeMap, of, repeat, retry, Subject, takeUntil, tap} from "rxjs";
import {GroupedRowState} from "../classes/GroupedRowState";
import {
    MatPartReviewRow,
    MatReviewSampleStatistic,
    MatSGroup,
    ReviewChoice,
} from "../../../services/classes/MaterializedClasses";
import {ReviewCatFields, setOnObj, StorePartReviewBySGroupsSerializer} from "../../../services/ApiHelpers";
import {Categories} from "../../../services/classes/AiClasses";
import {PipeManager} from "../../../stores/managers/PipeManager";
import {getUnique} from "../../../utils/js-utils";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import {PartRowState} from "../classes/PartRowState";

declare type CategorizationSupplierPageManager = PageResponseManager<MatSupplierFilter, GroupedRowState, {
    d: MatSGroup,
    f: MatSupplierFilter,
}>;
declare type CategorizationPartPageManager = PageResponseManager<MatPartFilter, PartRowState, MatPartReviewRow>;

declare type StorePartReviewPipe = { data: StoreReviewSerializer, part: PartRowState };
declare type StoreManyPartsReviewPipe = { data: StoreReviewSerializer, parts: PartRowState[] };
declare type StoreFilteredPartsReviewPipe = {
    data: StoreReviewSerializer,
    partsInView: PartRowState[],
    filterParams: URLSearchParams
};
declare type StoreFilteredSupplierReviewPipe = {
    data: StoreReviewSerializer,
    suppliersInView: GroupedRowState[],
    sGroupParams: URLSearchParams
};

/**
 * This reactive controller is responsible for the state of the grouped review table.
 */
export class CategorizationReviewPageDataController {

    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private matApi: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        // Start processing the pipe
        this.asyncGroupUpdatePipe.subscribe({
            next: (d) => console.debug(`Update supplier row ${d}`),
            error: (e) => console.error('Supplier update signal error', e),
            complete: () => console.debug('Supplier update signal completed')
        })

        this.onAnyReviewUpdatePipe.subscribe();

        this.cancelAsyncUpdatePipes.subscribe(() => {
            console.warn('Abandoning part updates is not implemented yet');
            // this.asyncPartUpdatePipe.reset()
            // this.asyncPartMultiUpdatePipe.reset()
        })
    }

    private readonly cancelAsyncUpdatePipes: Subject<void> = new Subject();

    private readonly onAnyReviewUpdateSubject: Subject<void> = new Subject();
    private readonly onAnyReviewUpdatePipe = this.onAnyReviewUpdateSubject.pipe(
        concatMap(() => {
            // TODO CAT-1580: This is request is repeated twice, databag initialization is messy!
            const databag = this.categorizationReviewPageController.bagId;
            const isValidation = this.categorizationReviewPageController.isValidationBag;
            if (isValidation) {
                return from(this.matApi.getMatReviewSampleStatistics(databag).then(r => r.data));
            } else {
                return of(null)
            }
        }),
        tap((data: MatReviewSampleStatistic | null) => {
            this.categorizationReviewPageController.reviewPageStatisticsController.setSampleStatistics(data);
        }),
    );

    private readonly asyncGroupUpdateSubject: Subject<GroupedRowState> = new Subject();
    private readonly asyncGroupUpdatePipe = this.asyncGroupUpdateSubject.pipe(
        takeUntil(this.cancelAsyncUpdatePipes),
        mergeMap((supplierRow: GroupedRowState) => {
            // TODO: Improve this query by just requesting 1 supplier row, instead of everything on this page

            const pageNumber = supplierRow.currentPageNumber as number;
            console.assert(pageNumber !== undefined, 'Page number should be defined when downloading the supplier row')
            const activeFilter = this.categorizationReviewPageController.reviewPageFilterController.selectedFilter;
            console.assert(activeFilter !== undefined, 'Active filter should be defined when downloading the supplier row')

            const pageSize = this.profile.p.categorizationGroupedPageSize ?? environment.defaultCategorizationGroupedPageSize;
            return from(this.matApi.listReviewGroupBySupplier(activeFilter, pageNumber, pageSize)).pipe(
                map(response => ({response, supplierRow}))
            )
        }),
        map(({response, supplierRow}) => {
            const row: GroupedRowState = supplierRow;

            // Execute a partial update of the view, with the new data from the API
            const supplierRowId = row.supplierId;

            const supplierRowData = response.data.results.find(s => s.supplier_id === supplierRowId)
            if (!supplierRowData) {
                console.error('Supplier data not found in resp', {supplierRowId})
                return;
            }

            // Call the action to update the state
            row.updateData(supplierRowData)


            return supplierRowId
        }),
        retry(), // retry when an error occurs
        repeat(), // restart the pipe when it's reset
    );

    private readonly asyncPartUpdateSubject: Subject<PartRowState> = new Subject();
    private readonly asyncPartUpdatePipe = new PipeManager<StorePartReviewPipe>(
        ({data, part}) => {
            const parentRow = part.parentRow;

            // First update the view
            runInAction(() => {
                if (parentRow) {
                    parentRow.combinedStateLoading = true
                } else {
                    // Only refresh in grouped mode
                    // console.log('asyncPartUpdatePipe setting to true')
                    // part.combinedStateLoading = true
                }
            })

            // Then call the API
            return from(this.matApi.storePartReview(part.id, data)).pipe(
                tap(() => {
                    // TODO: Process the response and update the part, now we trust our local update too much
                    if (parentRow) {
                        // And finally, update the supplier view again
                        this.asyncGroupUpdateSubject.next(parentRow)
                    } else {
                        // Only refresh in grouped mode
                        console.log('asyncPartUpdatePipe setting to false')
                        this.asyncPartUpdateSubject.next(part)
                        // console.log('data received', data)
                        // part.combinedStateLoading = false
                    }
                    this.onAnyReviewUpdateSubject.next();
                })
            );
        }
    )
    private readonly asyncPartMultiUpdatePipe = new PipeManager<StoreManyPartsReviewPipe>(
        ({data, parts}) => {
            const parentRows = getUnique(parts.map(p => p.parentRow))

            // First update the view
            if (parentRows.length > 0) {
                runInAction(() => {
                    parentRows.forEach(r => {
                        r.combinedStateLoading = true
                    })
                });
            }

            // Then call the API
            const req: StorePartReviewManySerializer = {
                ...data,
                parts: parts.map(p => p.id)
            }
            return from(this.matApi.storePartReviewMany(req)).pipe(tap(() => {
                // And finally, update the supplier view again

                // TODO: Minor speed improvement can be made here, but it's not a priority
                // When the user selects multiple parts, we can group the supplier requests into 1
                // But it's more wise to revise the architecture and approach first
                parentRows.forEach(parentRow => {
                    this.asyncGroupUpdateSubject.next(parentRow)
                })

                this.onAnyReviewUpdateSubject.next()
            }))
        }
    )
    private readonly asyncPartFilteredUpdatePipe = new PipeManager<StoreFilteredPartsReviewPipe>(
        ({data, partsInView, filterParams}) => {
            const parentRowsInView = getUnique(partsInView.map(p => p.parentRow))

            // First update the view
            if (parentRowsInView.length > 0) {
                runInAction(() => {
                    parentRowsInView.forEach(r => {
                        r.combinedStateLoading = true
                    })
                });
            }

            // Then call the API
            return from(this.matApi.storePartReviewByFilter(data, filterParams)).pipe(tap(() => {
                // And finally, update the supplier view again
                if (parentRowsInView.length > 0) {
                    // Refresh all parent rows
                    this.categorizationReviewPageController.requestPartPages()
                } else {
                    // Do not refresh the supplier view
                }
            }))
        }
    )
    private readonly asyncSuppliersFilteredUpdatePipe = new PipeManager<StoreFilteredSupplierReviewPipe>(
        ({data, suppliersInView, sGroupParams}) => {
            // First update the view
            runInAction(() => {
                suppliersInView.forEach(r => {
                    r.combinedStateLoading = true
                })
            });

            // Then call the API
            return from(this.matApi.storeSupplierReviewByFilter(data, sGroupParams)).pipe(tap(() => {
                // TODO: Maybe do not go to page 1 after this..
                this.categorizationReviewPageController.resetAndRequestSupplierPage()
            }))
        }
    )

    get anyStoreRequestBusy(): boolean {
        return this.asyncPartUpdatePipe.hasInPipe
            || this.asyncPartMultiUpdatePipe.hasInPipe
            || this.asyncPartFilteredUpdatePipe.hasInPipe
            || this.asyncSuppliersFilteredUpdatePipe.hasInPipe
    }

    /**
     * Note: That these error do not reset automatically, if one pipe fails the errors from other pipes are still there
     */
    get error(): string {
        return [
            this.asyncPartUpdatePipe.error,
            this.asyncPartMultiUpdatePipe.error,
            this.asyncPartFilteredUpdatePipe.error,
            this.asyncSuppliersFilteredUpdatePipe.error
        ].filter(e => e).join(', ')
    }

    resetErrors() {
        this.asyncPartUpdatePipe.setError('')
        this.asyncPartMultiUpdatePipe.setError('')
        this.asyncPartFilteredUpdatePipe.setError('')
        this.asyncSuppliersFilteredUpdatePipe.setError('')
    }

    // get numberOfBusyRequests(): number {
    //     return this.asyncPartUpdatePipe.nInPipe
    //         + this.asyncPartMultiUpdatePipe.nInPipe
    //         + this.asyncPartFilteredUpdatePipe.nInPipe
    //         + this.asyncSuppliersFilteredUpdatePipe.nInPipe
    // }

    /**
     * Keep track of the suppliers table by a single async request manager
     */
    readonly supplierPages: CategorizationSupplierPageManager = new PageResponseManager(
        this.profile.p.categorizationGroupedPageSize ?? environment.defaultCategorizationGroupedPageSize,
        (page, f) => {
            // When requesting a new page, cancel the processing of all other requests
            this.cancelAsyncUpdatePipes.next();

            const filterType = {...f};
            return this.matApi.listReviewGroupBySupplier(f, page, this.supplierPages.pageSize)
                .then(r => {
                    // Inject the type to ensure we can use typescript's dynamic type resolution
                    r.data.results = r.data.results.map(d => ({d, f: filterType})) as any
                    return r as any;
                });
        },
        ({d, f}) => GroupedRowState.build(d, this.supplierPages.page, f)
    )

    readonly partPages: CategorizationPartPageManager = new PageResponseManager(
        this.profile.p.categorizationSinglePageSize ?? environment.defaultCategorizationSinglePageSize,
        (page, f) => {
            // When requesting a new page, cancel the processing of all other requests
            this.cancelAsyncUpdatePipes.next();
            return this.matApi.listReviewParts(f, page, this.partPages.pageSize, this.relatedDataFields);
        },
        (data: MatPartReviewRow) => PartRowState.build(data, this.auth.userId, undefined)
    )


    /**
     * Is loading the review table (single or grouped)
     */
    get isLoadingParts(): boolean {
        if (this.categorizationReviewPageController.singleMode) {
            return this.partPages.isLoading;
        } else {
            return this.supplierPages.isLoading;
        }
    }

    get allPartRows(): PartRowState[] {
        if (this.categorizationReviewPageController.singleMode) {
            return this.partPages.data || [];
        } else {
            return this.supplierPages.data?.flatMap(s => s.partStates || []) || [];
        }
    }

    /**
     * Change the accept/reject data of a part
     * @param part
     * @param review_choice
     */
    sendPartReviewChoice(part: PartRowState, review_choice: ReviewChoice) {
        this.resetErrors()

        part.updateLocalOnReviewChange(review_choice);

        // Start the update flow
        const key = part.id
        const data: StorePartReviewPipe = {data: {review_choice}, part}
        this.asyncPartUpdatePipe.process(key, data);
    }

    /**
     * Change the category data of a part
     * @param part
     * @param category
     */
    sendPartReviewCategory(part: PartRowState, category: Categories) {
        this.resetErrors()

        // Assert: part.isOpenToReview()
        const review_choice = ReviewChoice.ACCEPT;
        part.updateLocalOnCategoryChange(category, review_choice);

        // Start the update flow
        const key = part.id
        const data: StorePartReviewPipe = {data: {review_choice}, part}
        setOnObj(data.data as any, 'p_review_cat', category);
        this.asyncPartUpdatePipe.process(key, data);
    }

    /**
     * Change the accept/reject data of multiple parts
     * @param parts
     * @param review_choice
     */
    sendPartsReviewChoice(parts: PartRowState[], review_choice: ReviewChoice) {
        this.resetErrors()

        console.assert(parts.length > 0)

        parts.forEach(p => p.updateLocalOnReviewChange(review_choice))

        // Start the update flow
        const key = parts[0].id
        const data: StoreManyPartsReviewPipe = {data: {review_choice}, parts}
        this.asyncPartMultiUpdatePipe.process(key, data);
    }

    /**
     * Change the category data of multiple parts
     * @param parts The parts to update; length > 0 and it may be mixed with open and closed parts for review
     * @param category
     */
    sendPartsReviewCategory(parts: PartRowState[], category: Categories) {
        this.resetErrors()

        console.assert(parts.length > 0)
        const review_choice = ReviewChoice.ACCEPT;

        parts.forEach(p => p.updateLocalOnCategoryChange(category, review_choice))

        // Start the update flow
        const key = parts[0].id
        const data: StoreManyPartsReviewPipe = {data: {review_choice}, parts}
        setOnObj(data.data as any, 'p_review_cat', category);
        this.asyncPartMultiUpdatePipe.process(key, data);
    }

    /**
     * Update the acc/rej data of all parts with the current filter applied
     */
    sendAllPartsReviewChoice(review_choice: ReviewChoice) {
        this.resetErrors()

        // TODO: Test this
        const partsInView = this.partPages.data || [];
        console.assert(partsInView.length > 0)

        partsInView.forEach(p => p.updateLocalOnReviewChange(review_choice))

        // Get the filter params without pagination keys
        const filterParams = this.categorizationReviewPageController.reviewPageFilterController.getPartUrlSearchParams();
        console.log('filterParams', filterParams)

        // Start the update flow
        const key = partsInView[0].id
        const data: StoreFilteredPartsReviewPipe = {data: {review_choice}, filterParams, partsInView}
        this.asyncPartFilteredUpdatePipe.process(key, data);
    }

    /**
     * Update the category data of all parts, also outside this page/table/view
     * @param category
     */
    sendAllPartsReviewCategory(category: Categories) {
        this.resetErrors()

        // TODO: Test this
        const review_choice = ReviewChoice.ACCEPT;

        const partsInView = this.partPages.data || [];
        console.assert(partsInView.length > 0)

        partsInView.forEach(p => p.updateLocalOnCategoryChange(category, review_choice))

        // Get the filter params without pagination keys
        const filterParams = this.categorizationReviewPageController.reviewPageFilterController.getPartUrlSearchParams();
        console.log('filterParams', filterParams) // the params are here

        // Start the update flow
        const key = partsInView[0].id
        const data: StoreFilteredPartsReviewPipe = {data: {review_choice}, filterParams, partsInView}
        setOnObj(data.data as any, 'p_review_cat', category);
        this.asyncPartFilteredUpdatePipe.process(key, data);
    }

    /**
     * Change the accept/reject data of a supplier
     * @param row
     * @param review_choice
     */
    sendSupplierReviewChoice(row: GroupedRowState, review_choice: ReviewChoice): void {
        this.resetErrors()

        row.updateLocalOnReviewChange(review_choice);

        // Start the update flow
        const data: StorePartReviewBySGroupsSerializer = {
            s_groups: row.data.mat_s_group_ids,
            review_choice,
        }
        this.matApi.patchReviewRowBySGroup(data)
            // Refresh the supplier
            .then(() => {
                this.asyncGroupUpdateSubject.next(row);
                this.onAnyReviewUpdateSubject.next()
            });
    }

    /**
     * Change the category data of a supplier
     * @param row
     * @param category
     */
    sendSupplierReviewCategory(row: GroupedRowState, category: Categories): void {
        this.resetErrors()

        const review_choice = ReviewChoice.ACCEPT;
        row.updateLocalOnCategoryChange(category, review_choice);

        // Start the update flow
        const p_review_cat_ls: ReviewCatFields = {};
        setOnObj(p_review_cat_ls as any, 'p_review_cat', category);
        const data: StorePartReviewBySGroupsSerializer = {
            s_groups: row.data.mat_s_group_ids,
            review_choice,
            ...p_review_cat_ls,
        }
        this.matApi.patchReviewRowBySGroup(data)
            // Refresh the supplier
            .then(() => {
                this.asyncGroupUpdateSubject.next(row);
                this.onAnyReviewUpdateSubject.next()
            });
    }

    /**
     * Change the accept/reject data of a supplier
     * @param rows
     * @param review_choice
     */
    sendSuppliersReviewChoice(rows: GroupedRowState[], review_choice: ReviewChoice): void {
        this.resetErrors()

        console.assert(rows.length > 0)

        rows.forEach(s => s.updateLocalOnReviewChange(review_choice))

        // Start the update flow
        const data: StorePartReviewBySGroupsSerializer = {
            s_groups: rows.flatMap(s => s.data.mat_s_group_ids),
            review_choice,
        }
        this.matApi.patchReviewRowBySGroup(data)
            // Refresh the supplier
            .then(() => {
                rows.forEach(row => this.asyncGroupUpdateSubject.next(row))
                this.onAnyReviewUpdateSubject.next();
            });
    }

    /**
     * Change the category data of a supplier
     * @param rows
     * @param category
     */
    sendSuppliersReviewCategory(rows: GroupedRowState[], category: Categories): void {
        this.resetErrors()

        console.assert(rows.length > 0)
        const review_choice = ReviewChoice.ACCEPT;

        rows.forEach(s => s.updateLocalOnCategoryChange(category, review_choice))

        // Start the update flow
        const p_review_cat_ls: ReviewCatFields = {};
        setOnObj(p_review_cat_ls as any, 'p_review_cat', category);
        const data: StorePartReviewBySGroupsSerializer = {
            s_groups: rows.flatMap(s => s.data.mat_s_group_ids),
            review_choice,
            ...p_review_cat_ls,
        }
        this.matApi.patchReviewRowBySGroup(data)
            // Refresh the supplier
            .then(() => {
                rows.forEach(row => this.asyncGroupUpdateSubject.next(row))
            });
    }

    /**
     * Update the category data of all supplier (and their parts), also outside this page/table/view
     */
    sendAllSuppliersReviewChoice(review_choice: ReviewChoice) {
        this.resetErrors()

        // TODO: Test this
        const suppliersInView = this.supplierPages.data || [];
        console.assert(suppliersInView.length > 0)

        suppliersInView.forEach(s => s.updateLocalOnReviewChange(review_choice))

        // Get the filter params without pagination keys
        const selectedFilter = this.categorizationReviewPageController.reviewPageFilterController.selectedFilter;
        const sGroupParams = MithraMaterializedApi.buildSGroupUrlSearchParams(selectedFilter);
        console.log('sendAllSuppliersReviewChoice.selectedFilter:', selectedFilter)
        console.log('sendAllSuppliersReviewChoice.sGroupParams:', sGroupParams)

        // Start the update flow
        const key = suppliersInView[0].id
        const data: StoreFilteredSupplierReviewPipe = {data: {review_choice}, suppliersInView, sGroupParams}
        this.asyncSuppliersFilteredUpdatePipe.process(key, data);
    }

    /**
     * Update the category data of all suppliers, also outside this page/table/view
     * @param category
     */
    sendAllSuppliersReviewCategory(category: Categories) {
        this.resetErrors()

        // TODO: Test this
        const review_choice = ReviewChoice.ACCEPT;

        const suppliersInView = this.supplierPages.data || [];
        console.assert(suppliersInView.length > 0)

        suppliersInView.forEach(s => s.updateLocalOnCategoryChange(category, review_choice))

        // Get the filter params without pagination keys
        const selectedFilter = this.categorizationReviewPageController.reviewPageFilterController.selectedFilter;
        const sGroupParams = MithraMaterializedApi.buildSGroupUrlSearchParams(selectedFilter);
        console.log('sendAllSuppliersReviewChoice.selectedFilter:', selectedFilter)
        console.log('sendAllSuppliersReviewChoice.sGroupParams:', sGroupParams)

        // Start the update flow
        const key = suppliersInView[0].id
        const data: StoreFilteredSupplierReviewPipe = {data: {review_choice}, suppliersInView, sGroupParams}
        setOnObj(data.data as any, 'p_review_cat', category);
        this.asyncSuppliersFilteredUpdatePipe.process(key, data);
    }

    get relatedDataFields(): CategorizationReviewSubRowRelationsData[] | undefined {
        let related = this.profile.p.categorizationReviewSubRowRelationData;
        if (this.categorizationReviewPageController.isMergedBag) {
            related = ProfileStore.addRelationData(related,
                {relation: 'part', columns: [{db_column: 'src_databag_id', colSpec: null}]}
            );
        }
        return related;
    }

}