import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {GoldenRecordGroupedState} from "../classes/GoldenRecordGroupedState";
import {CircularProgress, Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {Edit, ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {getCollapseAnimationTiming} from "../../../components/table/utils";
import {GOLDEN_RECORD_TABLE_COLUMNS} from "../classes/GoldenRecordTableColumns";
import {GoldenRecordTableSubTable} from "./GoldenRecordTableSubTable";

type Props = {
    gState: GoldenRecordGroupedState
}
export const GoldenRecordTableMainRow: React.FC<Props> = observer(({gState}) => {
    const {goldenRecordStore} = useStores();
    const COLUMNS = GOLDEN_RECORD_TABLE_COLUMNS;
    const className = 'golden-record-main-row';
    let i = 0;
    const open = gState.open;
    return <>
        <TableRow className={className}>
            <MithraTableCell c={COLUMNS[i++]} onClick={() => onOpenRow(gState, open)}>
                <IconButton aria-label="expand row" size="small">
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {gState.data.gr_name}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {gState.hackL1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <CurrencyComponent v={gState.hackTotalSpend} nDigits={3}/>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {gState.hackSupplierCount}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {gState.isLoadingRows
                    ? <CircularProgress size={24} className="table-height"/>
                    : <>
                        {/*<IconButton color="primary" onClick={() => {*/}
                        {/*    console.log('TODO: implement');*/}
                        {/*}}>*/}
                        {/*    <Visibility/>*/}
                        {/*</IconButton>*/}
                        <IconButton color="primary" onClick={() => goldenRecordStore.editController.onOpenEdit(gState)}>
                            <Edit/>
                        </IconButton>
                    </>
                }
            </MithraTableCell>
        </TableRow>
        <TableRow>
            {/*<p>*/}
            {/*    dataPage={'' + page}<br/>*/}
            {/*    dataCount={'' + row.partCount}<br/>*/}
            {/*</p>*/}
            <TableCell
                className="cell-sub-table"
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {gState.partStates !== undefined &&
                    <Collapse in={open} timeout={getCollapseAnimationTiming(gState.partStates)} unmountOnExit>
                        <GoldenRecordTableSubTable gState={gState}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})


function onOpenRow(gState: GoldenRecordGroupedState, isOpen: boolean): void {
    gState.setOpen(!isOpen);

    // // Set partStates to empty array, to display loading
    // runInAction(() => gState.partStates = [])
    // if (!isOpen) {
    //     // Reset the page
    //     gState.handlePageChange(1);
    // }
}