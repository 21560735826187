import ProfileStore from "../../../stores/ProfileStore";
import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export function getOpportunityTableColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-group_key', txt: p.groupKeyDataName},
        {cls: 'col-total_variance_cost', txt: 'Opportunity Amount', align: 'center'},
        {cls: 'col-opportunity-status', txt: 'Status', align: 'center'},
        {cls: 'col-button', txt: '', width: '150px'}
    ];

    return arr.filter(c => c) as ColSpec[];
}