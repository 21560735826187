import './GoldenRecordModals.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

const GOLDEN_RECORD_EDIT_MODAL_ID = 'golden-record-edit-modal';
export const GOLDEN_RECORD_FILTER_MODAL_TITLE_ID = GOLDEN_RECORD_EDIT_MODAL_ID + '-title';

export const GoldenRecordEditModal: React.FC = observer(() => {
    const {goldenRecordStore} = useStores();
    return <Dialog
        open={goldenRecordStore.editController.isEditModalOpen}
        onClose={() => goldenRecordStore.editController.setIsEditModalOpen(false)}
        aria-labelledby={GOLDEN_RECORD_FILTER_MODAL_TITLE_ID}
        className="golden-record-modal golden-record-edit-modal"
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
            Edit Golden Record
        </DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                label="Golden Record Name"
                disabled={!goldenRecordStore.editController.subject}
                value={goldenRecordStore.editController.subject?.data.gr_name || ''}
                onChange={e => goldenRecordStore.editController.setField('gr_name', e.target.value)}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Notes"
                multiline
                rows={7}
                disabled={!goldenRecordStore.editController.subject}
                value={goldenRecordStore.editController.subject?.data.gr_note || ''}
                onChange={e => goldenRecordStore.editController.setField('gr_note', e.target.value)}
                variant="outlined"
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => goldenRecordStore.editController.onCancel()}
                variant="text">
                Cancel
            </Button>
            <Button
                onClick={() => goldenRecordStore.editController.onSave()}
                variant="contained">
                Save
            </Button>
        </DialogActions>
    </Dialog>
})