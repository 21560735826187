import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import React from "react";
import {ChangeCategoryFooterButton} from "../../categorization/components/change-category/ChangeCategoryFooterButton";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {GOLDEN_RECORD_TABLE_COLUMNS} from "../classes/GoldenRecordTableColumns";
import {GoldenRecordTableMainRow} from "./GoldenRecordTableMainRow";

export const GoldenRecordTableComponent = observer(() => {
    const {goldenRecordStore} = useStores();
    const pageManager = goldenRecordStore.dataController.grGroups;
    return <TableContainer className="golden-record-table-component" component="section">
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={GOLDEN_RECORD_TABLE_COLUMNS}/>
            <TableBody className="categorization-review-table-body">
                {pageManager.data ? <>
                        {pageManager.data.map(gState => <React.Fragment key={gState.id}>
                            <GoldenRecordTableMainRow gState={gState}/>
                        </React.Fragment>)}
                        {pageManager.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={GOLDEN_RECORD_TABLE_COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={3}>
                        <ChangeCategoryFooterButton/>
                    </TableCell>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={GOLDEN_RECORD_TABLE_COLUMNS.length - 3}
                        count={pageManager.count}
                        rowsPerPage={pageManager.pageSize}
                        page={pageManager.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => pageManager.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
