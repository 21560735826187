import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowRight, CheckCircle} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {ParentSupplierReviewSubTable} from "./ParentSupplierReviewSubTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {getParentSupplierChoice, ParentSupplierReviewRow,} from "../SupplierNormalization.classes";
import {PARENT_SUPPLIER_COLUMNS} from "./columns";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {useStores} from "../../../../stores";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";
import {getCollapseAnimationTimingNumber} from "../../../../components/table/utils";
import {ParentSupplierChooser} from "../components/ParentSupplierChooser";
import {runInAction} from "mobx";

type Props = {
    row: ParentSupplierReviewRow,
    forceOpen?: boolean
};
export const ParentSupplierReviewMainRow: React.FC<Props> = observer(({row, forceOpen}) => {
    const {supplierNormalizationStore} = useStores();
    const [selected, setSelected] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setSelected(row.id === supplierNormalizationStore.selectedRowId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplierNormalizationStore.selectedRowId]);

    useEffect(() => {
        // Only set filters when current row is going to open, otherwise delays of setting this filter in multiple rows
        // would result in wrong API call
        if (row.id === supplierNormalizationStore.openRowId)
            supplierNormalizationStore.supplierFilters.setFilters([['parent_supplier_review_row', supplierNormalizationStore.openRowId.toString()]]);
        setOpen(row.id === supplierNormalizationStore.openRowId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplierNormalizationStore.openRowId]);

    return <>
        <TableRow className="main-row" selected={selected}>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[0]}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => runInAction(() => supplierNormalizationStore.openRowId = open ? 0 : row.id)}
                    disabled={row.sp_total_parts === 0}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[1]}>
                {row.sp_n_suppliers}
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[2]}>
                <GoogleLink type="parent_supplier" objs={[row]}>
                    <ParentSupplierChooser parentSupplierChoice={getParentSupplierChoice(row)} label={''}/>
                </GoogleLink>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[3]}>
                <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                    <CurrencyComponent v={row.sp_total_spend} abbreviation={abbreviation}/>
                }</CurrencyAbbreviationContext.Consumer>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[4]}>
                {row.sp_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[5]}>
                {row.sp_region}
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[6]}>
                {selected && <IconButton
                    onClick={() => supplierNormalizationStore.setSelectedRowId()}>
                    <CheckCircle color="primary"/>
                </IconButton>}
            </MithraTableCell>
        </TableRow>
        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={PARENT_SUPPLIER_COLUMNS.length}
                style={{paddingLeft: PARENT_SUPPLIER_COLUMNS[0].width}}>
                {row.sp_n_suppliers > 0 &&
                    <Collapse in={open || forceOpen} timeout={getCollapseAnimationTimingNumber(row.sp_n_suppliers)}
                              unmountOnExit>
                        <ParentSupplierReviewSubTable parentSupplier={row}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
