import {MatPartReviewRow, ReviewChoice} from "../../../services/classes/MaterializedClasses";
import {makeAutoObservable} from "mobx";
import {buildSinglePartReviewStateVisual, SinglePartReviewStateVisual} from "./CategorizationReviewVisualStates";
import {Categories} from "../../../services/classes/AiClasses";
import {setOnObj} from "../../../services/ApiHelpers";
import {GroupedRowState} from "./GroupedRowState";

export class PartRowState {
    get id() {
        return this.data.id;
    }

    constructor(
        /**
         * Shows information about the combined state of the parts below this supplier
         *
         * When the part data of this row changes, the combined state requires recalculation, in the meantime this will be undefined
         */
        public combinedStateVisual: SinglePartReviewStateVisual,
        public reviewMine: boolean,
        public feedbackMine: boolean,
        public selected: boolean,
        public parentRow: GroupedRowState | undefined,
        public data: MatPartReviewRow,
    ) {
        makeAutoObservable(this)
    }

    public static build(part: MatPartReviewRow, userId: number | undefined, parentRow: GroupedRowState | undefined) {
        const reviewMine = part.review_user_id === userId;
        return new PartRowState(
            buildSinglePartReviewStateVisual(part, reviewMine),
            reviewMine,
            part.feedback_user_id === userId,
            false,
            parentRow,  // This link can be created directly here
            part,
        )
    }

    /**
     * The part can be accepted
     *
     * This definition needs to be aligned with the BE
     * TODO: Align this with the documented logic when we open parts for review again https://mithra-ai.atlassian.net/browse/CAT-1450
     */
    isOpenToOnlyAccept() {
        // TODO [CAT-1457]: Allow previously approved to be reviewed again
        if (this.data.approval !== null) {
            // This is already send for approval
            return false;
        }
        if (this.data.review_choice !== null) return true;
        // noinspection RedundantIfStatementJS
        if (this.data.p_suggested_cat_l1 === '') {
            // If there is no suggestion, we can accept
            return false;
        } else {
            return true;
        }
    }

    updateLocalOnReviewChange(reviewChoice: ReviewChoice) {
        if (!this.isOpenToOnlyAccept()) {
            // Although the request is send to the BE, we will not update these elements in the FE
            // It's expected that the BE does the same
            return;
        }
        this.data.review_choice = reviewChoice;
        this.reviewMine = true;
        this.combinedStateVisual = buildSinglePartReviewStateVisual(this.data, this.reviewMine);
    }

    /**
     * The part can be categorized
     *
     * This definition needs to be aligned with the BE
     * TODO: Align this with the documented logic when we open parts for review again https://mithra-ai.atlassian.net/browse/CAT-1450
     */
    isOpenToRecat() {
        // TODO [CAT-1457]: Allow previously approved to be reviewed again
        // noinspection RedundantIfStatementJS
        if (this.data.approval !== null) {
            // This is already send for approval
            return true;
        }
        return true;
    }

    updateLocalOnCategoryChange(category: Categories, reviewChoice: ReviewChoice) {
        if (!this.isOpenToRecat()) {
            // Although the request is send to the BE, we will not update these elements in the FE
            // It's expected that the BE does the same
            return;
        }

        let caseApproved = this.data.review_choice !== null && this.data.feedback_choice !== null && this.data.approval == null && this.data.approval_applied
        let caseRejected = this.data.review_choice !== null && this.data.feedback_choice !== null && this.data.approval == null && this.data.approval_applied

        this.data.review_choice = reviewChoice;
        if (caseApproved || caseRejected) {
            this.data.feedback_choice = null
            this.data.approval_applied = false
        }
        this.reviewMine = true;
        setOnObj(this.data, 'p_review_cat', category);
        this.combinedStateVisual = buildSinglePartReviewStateVisual(this.data, this.reviewMine);
    }
}