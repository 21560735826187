import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import {Add, Check, Clear} from "@mui/icons-material";
import {useSitStores} from "../../../../stores";

export const InitiativeSuccessStepFrom: React.FC = observer(() => {
    const {initiativeInstanceStore} = useSitStores();
    const [newSuccessStep, setNewSuccessStep] = useState<string | undefined>(undefined);

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Table>
                <TableBody>
                    {initiativeInstanceStore.instance.success_step?.map((ss, index) =>
                        <TableRow key={index}>
                            <TableCell width="10">
                                <Checkbox
                                    disabled={initiativeInstanceStore.disabled}
                                    checked={ss.achieved}
                                    onChange={(_event) => {
                                        initiativeInstanceStore.setSuccessStepAchieved(ss);
                                    }}/>
                            </TableCell>
                            <TableCell>{ss.description}</TableCell>
                            <TableCell width="10">
                                <IconButton
                                    disabled={initiativeInstanceStore.disabled}
                                    onClick={() => {
                                        initiativeInstanceStore.removeSuccessStep(ss);
                                    }}>
                                    <Clear/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={3}>
                            {newSuccessStep === undefined &&
                                <Button
                                    disabled={initiativeInstanceStore.disabled}
                                    fullWidth
                                    variant="outlined"
                                    startIcon={<Add/>}
                                    onClick={() => setNewSuccessStep('')}
                                >Key Milestone</Button>
                            }
                            {newSuccessStep !== undefined &&
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        variant="standard"
                                        label="Success Step"
                                        value={newSuccessStep}
                                        onChange={(event) => {
                                            setNewSuccessStep(event.target.value);
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    disabled={initiativeInstanceStore.disabled}
                                                    onClick={() => {
                                                        initiativeInstanceStore.addSuccessStep({
                                                            id: 0,
                                                            initiative: initiativeInstanceStore.instance.id ?? 0,
                                                            description: newSuccessStep,
                                                            achieved: false,
                                                            created: new Date(),
                                                            updated: new Date(),
                                                        });
                                                        setNewSuccessStep(undefined);
                                                    }}>
                                                    <Check/>
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    </Grid>
});