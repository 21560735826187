import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import React from "react";
import {Button, CircularProgress, Grid, Typography,} from "@mui/material";
import {UploadFile} from "@mui/icons-material";
import {ReactComponent as NoData} from '../NoData.svg';
import {environment} from "../../../env";
import {ContractUploadComponent} from "./ContractUploadComponent";
import {CONTRACT_DEMO_DATA} from "../data/ContractDemoData";

export const ContractEmptyComponent: React.FC = observer(() => {
    const {contractInsightsStore, p} = useStores();
    const isDemo = environment.isDemo;

    let className = 'contract-upload-page'
    if (p.p.disableBuFilter) {
        className = `${className} button-only`
    }

    const [demoIsLoading, setDemoIsLoading] = React.useState(false);

    return <Grid container className={className} alignItems="center" justifyContent="center" direction="column">
        <Grid item>
            <NoData/>
        </Grid>

        <Grid item style={{marginTop: '3em', marginBottom: '3em'}}>
            <Typography variant="h5" className="mb-2">
                It’s all empty here...
            </Typography>
            <Typography variant="body1" className="mb-2">
                To start, try to upload or drag and drop a contract file here
            </Typography>
        </Grid>

        <Grid item>
            {isDemo
                ? <Button
                    disabled={demoIsLoading}
                    startIcon={demoIsLoading ? <CircularProgress size={20}/> : <UploadFile/>}
                    variant="outlined"
                    component="label"
                    className="upload-file-button">
                    Upload contract file
                    <input
                        hidden
                        accept="application/pdf"
                        type="file"
                        onChange={() => {
                            //fake call
                            setDemoIsLoading(true);
                            setTimeout(() => {
                                setDemoIsLoading(false);
                                contractInsightsStore.setContractsData(CONTRACT_DEMO_DATA);
                                console.log("Fake API call completed.");
                            }, 4000);
                        }}
                    />
                </Button>
                : <ContractUploadComponent/>
            }
        </Grid>

    </Grid>
});
