import {Grid, TextField} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../components/modal/GenericModalVertical";

type Props = {
    isOpen: boolean
    setOpen: (open: boolean) => void
}

export const CategorizationDetailModal: React.FC<Props> = observer(({isOpen, setOpen}) => {
    return (
        <GenericModalVertical
            titleId={""}
            className="categorization-detail-modal"
            isOpen={isOpen}
            onSave={() => {
                //     CALL API
                setOpen(false);
            }}
            saveTitle={"Done"}
            onCancel={() => {
                setOpen(false);
            }}
            title={"Category detail"}
        >
            <Grid container>
                <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Supplier name" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Ammount" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="SKU ID" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Invoice number" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Score" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Previous category" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Accountind document number" variant="outlined"
                                   fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Date" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Suggested category" variant="outlined" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Approval state" variant="outlined" fullWidth={true}/>
                    </Grid>
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
})
