import {Alert, Button} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {GroupedRowState} from "../../../../classes/GroupedRowState";
import {
    CategorizationReviewPageSelectionController
} from "../../../../controllers/CategorizationReviewPageSelectionController";
import {useStores} from "../../../../../../stores";

type Props = {
    groupedRow: GroupedRowState
}
export const SupplierSelectionAlert: React.FC<Props> = observer(({groupedRow}) => {
    const {categorizationReviewStore} = useStores()
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;

    // You have {groupedRow.selectedLabel} of {s_name}
    if (!groupedRow.hasSelection) {
        return <></> // Do not show the label if nothing is selected
    }
    const plural = groupedRow.nSelected > 1;
    const _parts = `${CategorizationReviewPageSelectionController.PART_NAME}${plural ? 's' : ''}`;
    const all = plural && groupedRow.selectionState === 'all' ? 'all ' : '';
    return <Alert
        className="tiny"
        severity="info"
        action={<>
            {groupedRow.selectionState === 'all' &&
                <Button
                    variant="text" size="small"
                    onClick={() => reviewPageSelectionController.onSetSelectionOfSupplierGroup(groupedRow, false)}>
                    Deselect
                </Button>
            }
            {groupedRow.selectionState === 'some' &&
                <Button
                    variant="outlined" size="small"
                    onClick={() => reviewPageSelectionController.onSetSelectionOfSupplierGroup(groupedRow, true)}>
                    Select All
                </Button>
            }
        </>}>
        {/*<p>*/}
        {/*    groupedRow.selectionState: {groupedRow.selectionState}<br/>*/}
        {/*    groupedRow.isAllSelected: {'' + groupedRow.isAllSelected}<br/>*/}
        {/*</p>*/}
        You have {all && <strong>{all}</strong>}
        {groupedRow.nSelected} {_parts} selected of {groupedRow.data.s_name}
    </Alert>
})