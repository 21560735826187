import React from "react";
import {observer} from "mobx-react-lite";
import {TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {SupplierReviewRow} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {useStores} from "../../../stores";
import {SYN_COMMON_SUPPLIER_COLUMNS} from "./columns";
import { GoogleLink } from "../../../components/_tiny/GoogleLink";

type Props = {
    commonSupplier: SupplierReviewRow
};
export const SynergyCommonSupplierReviewSubRow: React.FC<Props> = observer(({commonSupplier}) => {
    const {p} = useStores()
    const isBase = commonSupplier.supplier.s_context_3 === 'base';
    const isMaster = commonSupplier.supplier.s_context_3 === 'master';
    const className = 'sub-row' + (isBase ? ' base' : '') + (isMaster ? ' master' : '');

    let i = 0;
    return <TableRow className={className}>
        <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
            <GoogleLink type="supplier" objs={[commonSupplier]}>
                {commonSupplier.supplier.s_name}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={commonSupplier.s_total_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
            {commonSupplier.supplier.s_city}
        </MithraTableCell>
        <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
            {commonSupplier.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
            {isBase
                ? p.p.compareCompanyName : isMaster
                    ? p.p.masterCompanyName
                    : commonSupplier.supplier.s_context_3}
        </MithraTableCell>
        {/*{!environment.isTest &&*/}
        {/*    <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>*/}
        {/*        {'' + commonSupplier.review_user_id}*/}
        {/*    </MithraTableCell>*/}
        {/*}*/}
        {/*<MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>*/}
        {/*    {Math.round(commonSupplier.supplier.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
