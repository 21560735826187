import {useStores} from "../../../../stores";
import {Button, Dialog, FormControl, Grid, InputLabel, TextField} from "@mui/material";
import {VerticalModal} from "./VerticalModal"
import {styled} from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AutoFixHigh} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {showImpactString, showProbabilityString} from "../../models/RiskDegree";
import {RiskTableData} from "../../../../stores/BusinessCaseGeneratorStore";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(VerticalModal)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalEditRiskFactor = ({data}) => {
    const {businessCaseGeneratorStore} = useStores();
    const [editedData, setEditedData] = useState<RiskTableData>({...data});

    useEffect(() => {
        setEditedData({...data});
    }, [data]);

    const handleChange = (field, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSave = () => {
        // Apply edits to the store
        businessCaseGeneratorStore.editRiskFactor(editedData);
        businessCaseGeneratorStore.isEditRiskFactorModalOpen = false
    };

    return (
        <Dialog
            open={businessCaseGeneratorStore.isEditRiskFactorModalOpen}
            onClose={() => businessCaseGeneratorStore.isEditRiskFactorModalOpen = false}
            aria-labelledby={"taxonomy-editor-modal-title"}
            maxWidth="md"
        >
            <StyledEditorModalComponent
                titleId={'edit-risk-factors'}
                className="bcr-delete-table-modal"
                title="Edit risk factors"
                canSave={true}
                onSave={handleSave}
                onClose={() => (businessCaseGeneratorStore.isEditRiskFactorModalOpen = false)}
                saveLabel="Save"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="risk-factor"
                            label="Risk factor"
                            value={editedData.risk_factor}
                            onChange={(e) => handleChange('risk_factor', e.target.value)}
                            variant="standard"
                            style={{marginBottom: 16}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth style={{marginBottom: 16}}>
                            <InputLabel id="demo-simple-select-label">Impact</InputLabel>
                            <Select
                                labelId="risk-factor-impact-options"
                                id="demo-simple-select"
                                value={editedData.impact}
                                onChange={(e) => handleChange('impact', e.target.value)}
                                label="Impact"
                            >
                                <MenuItem value={1}>{showImpactString(1)}</MenuItem>
                                <MenuItem value={2}>{showImpactString(2)}</MenuItem>
                                <MenuItem value={3}>{showImpactString(3)}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth style={{marginBottom: 16}}>
                            <InputLabel id="demo-simple-select-label">Probability</InputLabel>
                            <Select
                                labelId="risk-factor-impact-options"
                                id="demo-simple-select"
                                value={editedData.probability}
                                label="Proability"
                                onChange={(e) => handleChange('probability', e.target.value as string)}
                            >
                                <MenuItem value={1}>{showProbabilityString(1)}</MenuItem>
                                <MenuItem value={2}>{showProbabilityString(2)}</MenuItem>
                                <MenuItem value={3}>{showProbabilityString(3)}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item
                          container alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                id="standard-multiline-static"
                                label="Description"
                                multiline
                                rows={4}
                                value={editedData.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                variant="standard"
                                style={{marginBottom: 16}}
                            />
                        </Grid>
                        <Grid item xs={2} className="text-right">
                            <Button variant="outlined">
                                <AutoFixHigh/>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="risk-factor-ref-link"
                            label="Reference link"
                            value={editedData.link || ''}
                            onChange={(e) => handleChange('link', e.target.value)}
                            variant="standard"
                            style={{marginBottom: 16}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="risk-factor-ref-ref-name"
                            label="Reference name"
                            value={editedData.reference || ''}
                            onChange={(e) => handleChange('reference', e.target.value)}
                            variant="standard"
                            style={{marginBottom: 16}}
                        />
                    </Grid>

                </Grid>
            </StyledEditorModalComponent>
        </Dialog>
    );
};