import './ContractHeader.scss'
import {observer} from "mobx-react-lite";
import {Card, Chip, Grid, Tooltip, Typography} from "@mui/material";
import {ApartmentOutlined, ArticleOutlined, CalendarTodayOutlined,} from "@mui/icons-material";
import {useState} from "react";

const contractInfo = {
    contractId: 'ID32914109',
    contractName: 'Supply Agreement for Packaging',
    partiesInvolved: ['BM Holding', 'BM Holding II', 'BM Holding III'],
    type: 'Partnership',
    startDate: '2023-01-15',
    endDate: '2025-01-15',
};

export const ContractHeader = observer(() => {
    return (
        <Card className="contract-analytics-header">
            <Grid container justifyContent="center" alignItems="center">
                <Grid container item xs={6} justifyContent="center" alignItems="center">
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                        <ArticleOutlined style={{width: '60%', height: '60%', color: '#D1D6DD'}}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid item xs={12} style={{marginTop: '1em', marginBottom: '0.5em'}}>
                            <Typography variant={'caption'}>{contractInfo.contractId}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'h6'}>{contractInfo.contractName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Chip label={contractInfo.type} variant='outlined' size='medium'
                                  style={{marginTop: '1em', marginBottom: '2em'}}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={6}>
                        <ContractHeaderCard icon={<ApartmentOutlined/>} title="Parties involved"
                                            text={contractInfo.partiesInvolved}/>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} style={{marginTop: '1em', marginBottom: '1em'}}></Grid>
                    <Grid item xs={6}>
                        <ContractHeaderCard icon={<CalendarTodayOutlined/>} title="Effective date"
                                            text={contractInfo.startDate}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ContractHeaderCard icon={<CalendarTodayOutlined/>} title="Expiration date"
                                            text={contractInfo.endDate}/>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    );
});


const ContractHeaderCard = ({icon, title, text}) => {
    const [hover, setHover] = useState(false);

    const handleHover = () => {
        setHover(!hover);
    };

    return (
        <Grid container className="contract-header-card">
            {icon && (
                <Grid item container sm={4} md={3} xl={2} justifyContent="center" alignItems="center"
                      className="avatar">
                    <Grid item>{icon}</Grid>
                </Grid>
            )}
            <Grid item sm={8} md={9} xl={10}>
                <header>
                    <Typography variant="caption" className="title">
                        {title}
                    </Typography>
                </header>
                <section className="content">
                    {Array.isArray(text) ? (
                        <Typography variant="body1" component="p" onMouseEnter={handleHover} onMouseLeave={handleHover}>
                            {text[0]}{' '}
                            {(
                                <Tooltip title={text.slice(1).join(', ')}>
                                    <span style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}>and {text.length - 1} more</span>
                                </Tooltip>
                            )}
                        </Typography>
                    ) : (
                        <Typography variant="body1" component="p">
                            {text}
                        </Typography>
                    )}
                </section>
            </Grid>
        </Grid>
    );
};
