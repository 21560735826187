import {makeAutoObservable} from "mobx";
import {ContractFieldSpec} from "./data/ContractFields";
import {Timestamp} from "firebase/firestore";
import moment from "moment";

/**
 * The simplest form of contract data.
 */
export type ContractData = {
    id: string
    contract_id: string
    contract_title: string
    [key: string]: any
};

/**
 * A displayable version of the contract data.
 * TODO: This type can be improved to be more flexible
 */
export type ContractDataDisplay = string | ContractDataDisplay[];

/**
 * An observable record of a contract.
 * This class should be used to interact with the contract data.
 */
export class ContractRecord {
    open = false

    constructor(
        public readonly data: ContractData,
        public readonly id: string,
    ) {
        makeAutoObservable(this)
    }

    public setOpen(open: boolean) {
        this.open = open;
    }
}

export function showContractFieldValue(contractData: ContractData | undefined, f: ContractFieldSpec): ContractDataDisplay[] {
    const d = contractData && contractData[f.field];

    if (!d) {
        return []
    } else {
        if (Array.isArray(d)) {
            // Expand array
            return d.map(p => showAnything(p))
        } else if (typeof d === 'object' && d.constructor.name === 'Object') {
            // Expand simple objects
            return Object.keys(d).map(k => `${k}: ${showAnything(d[k])}`)
        } else {
            return [showAnything(d)]
        }
    }
}

function showAnything(d: any): ContractDataDisplay {
    if (d === null || d === undefined) {
        return '-'
    } else if (Array.isArray(d)) {
        return d.map(p => showAnything(p)).join(', ')
    } else if (typeof d === 'string') {
        return d
    } else if (typeof d === 'number' || typeof d === 'boolean' || typeof d === 'bigint') {
        return String(d)
    } else if (typeof d === 'object') {
        if (d.constructor.name === 'Timestamp' && d.seconds) {
            const timestamp = d as Timestamp;
            return moment.unix(timestamp.seconds).format('YYYY-MM-DD HH:mm:ss')
        }
        if (d.constructor.name === 'Date') {
            return moment(d).format('YYYY-MM-DD HH:mm:ss')
        }
        if (d.constructor.name === 'Object') {
            return Object.keys(d).map(k => `${k}: ${showAnything(d[k])}`).join(', ')
        }
        return d.constructor.name + ': ' + Object.keys(d).map(k => `${k}: ${showAnything(d[k])}`).join(', ')
    } else if (typeof d === 'object' && d.constructor.name !== 'Object') {
        return d.constructor.name + ': ' + Object.keys(d).map(k => `${k}: ${showAnything(d[k])}`).join(', ')
    } else {
        console.warn('Uncaught type in showAnything() call:', typeof d, d.constructor.name);
        return JSON.stringify(d)
    }
}
