import Margin from "../../../utils/margin";
import {C} from "../../../configurations/active-configuration";
import {environment} from "../../../env";
import {B} from "../../../build";

export type BuilderMode = 'edit' | 'view-full' | 'view-fit';

export const IS_TE_DEVELOPMENT_MODE: string = B.APP_IS_DEV ?
    // 'click'
    // 'click-edit-bug'
    // 'edit-bug'
    ''
    // 'debug'
: '';

export const DEBUG_LIMIT_DATA = false

/**
 * Taxonomy label height (in pixels)
 *
 * Demo: 16
 * Wide: 14 (normal)
 * Very narrow: 9
 */
export const TAXONOMY_LABEL_HEIGHT = environment.isDemo
    ? 16
    : 14

export type TaxonomyEditorOptions = {
    hideRoot: boolean
    showNumberLevels: number
    width: number

    fitViewModeHeight: number
    fullViewModeDeepestLevel: number

    /**
     * TODO: TEST ME
     */
    defaultBuilderMode: BuilderMode

    /**
     * Margin to allow for navigation via siblings
     */
    margin: Margin
    /**
     * Width of the boxes
     */
    nodeWidth: number
    /**
     * Distance between boxes
     * Room for the text
     */
    nodeDistance: number
    siblingPadding: number
    transitionDuration: number
}

/**
 * Defaults as used in the builder
 */
export const DEFAULT_TAXONOMY_EDITOR_OPTIONS: TaxonomyEditorOptions = {
    hideRoot: true,
    showNumberLevels: 2,
    width: 1800,
    fitViewModeHeight: C.profile.taxonomyBuilderHeight || 700,
    fullViewModeDeepestLevel: 2,
    defaultBuilderMode: 'view-fit',
    margin: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 20,
    },
    nodeWidth: 50,
    nodeDistance: 320,
    siblingPadding: 0,
    transitionDuration: 350,
}
