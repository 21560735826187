import {C} from "../../configurations/active-configuration";

/**
 * TODO: Introduce a customer_s_id field in the BE
 * @deprecated
 */
export function getSupplierIdHack(data: any) {
    if (C.profile.showSupplierIdPrefixCutoffHack !== undefined) {
        return data.s_id?.substring(C.profile.showSupplierIdPrefixCutoffHack)
        // .replace('_', ' ')
    }
    return data.s_id;
}

/**
 * TODO: Introduce a customer_bu_id field in the BE
 * @deprecated
 */
export function getBusinessUnitIdHack(data: any) {
    if (C.profile.showBuIdPrefixCutoffHack !== undefined) {
        return data.bu_id?.substring(C.profile.showBuIdPrefixCutoffHack)
        // .replace('_', ' ')
    }
    return data.bu_id;
}
