import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {C} from "../../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from "../../../stores/hack";

// TODO: Move to company specific settings
const COLUMNS = [
    // {cls: 'col-dropdown', txt: ''},
    {cls: 'col-s_name', txt: 'Vendor name', align: undefined},
    {cls: 'col-s_spend', txt: 'Total spend'},
]

export const BaseTaxonomySupplierTable: React.FC = observer(() => {
    let {rootAppStore, taxonomyMapperStore} = useStores();
    if (C.package === 'merge_x') {
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }
    const isLoading = taxonomyMapperStore.selection.leftSelectionSupplierPages.isLoading;
    const tableClass = 'mithra-table x-design taxonomy-mapper-supplier-context-table' + (isLoading ? ' loading' : '');
    const data = taxonomyMapperStore.selection.leftSelectionSupplierPages.data;
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <TableHead>
                <TableRow>
                    {COLUMNS.map((c, i) =>
                        <TableCell key={i} className={c.cls} align={c.align}>{c.txt}</TableCell>
                    )}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={COLUMNS.length}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>

            </TableHead>
            <TableBody className="categorization-review-table-body">
                {data ?
                    <>{data.map(row => {
                        // Table data
                        const spend = row.sum_spend;
                        return <TableRow key={row.id} className="supplier-row">
                            {/*<TableCell className="col-dropdown" onClick={() => setOpen(!open)}>*/}
                            {/*    {canOpen &&*/}
                            {/*        <IconButton aria-label="expand row" size="small">*/}
                            {/*            {open ? <ExpandMore/> : <KeyboardArrowRight/>}*/}
                            {/*        </IconButton>*/}
                            {/*    }*/}
                            {/*    {nParts}*/}
                            {/*</TableCell>*/}
                            <TableCell className="col-s_name">
                                {row.s_name}
                            </TableCell>
                            <TableCell className="col-s_spend">
                                <CurrencyAbbreviationContext.Consumer>{abbreviation => {
                                    return <CurrencyComponent v={spend} abbreviation={abbreviation}/>;
                                }
                                }</CurrencyAbbreviationContext.Consumer>
                            </TableCell>
                        </TableRow>;
                    })}{data.length === 0 &&
                        <TableRow className="no-data">
                            <TableCell colSpan={COLUMNS.length}>
                                No data
                            </TableCell>
                        </TableRow>
                    }</>
                    : <TableRow className="no-data-yet">
                        <TableCell colSpan={COLUMNS.length}>
                            Select an category to see the vendors
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length}
                        count={taxonomyMapperStore.selection.leftSelectionSupplierPages.count}
                        rowsPerPage={taxonomyMapperStore.selection.leftSelectionSupplierPages.pageSize}
                        page={taxonomyMapperStore.selection.leftSelectionSupplierPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) =>
                            taxonomyMapperStore.selection.leftSelectionSupplierPages.changePage(page + 1)
                        }
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
});
