import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import MithraDataIngestionApi from "../services/MithraDataIngestionApi";
import AuthApi from "../services/AuthApi";
import AuthStore from "./AuthStore";
import {buildAxiosInstance} from "../services/Http";
import {environment} from "../env";
import SitApi from "../sit/SitApit";

// This file ensures that access to the API's are always wrapped with the authentication hooks.
// The http instances should only be imported from this file.

// We split the index.ts and init.ts files to ensure the http instances can be imported from the stores as well,
// without circular dependencies.

export const matHttp = buildAxiosInstance(environment.materializedApiUrl);
export const ingestionHttp = buildAxiosInstance(environment.apiDataIngestionUrl);
const sitHttp = buildAxiosInstance(environment.sitApiUrl);

export const p = new ProfileStore();

export const materializedApi = new MithraMaterializedApi(matHttp);
export const ingestionApi = new MithraDataIngestionApi(ingestionHttp);
export const sitApi = new SitApi(sitHttp);

export const authApi = new AuthApi(materializedApi.http);
export const authStore = new AuthStore(authApi, p);
authStore.authentication.wrapAuthorized(materializedApi.http, ingestionApi.http);
authStore.authentication.wrapAuthorized(materializedApi.http, sitApi.http);
