import {MergeXCleanupDashboardSwitcher} from "./MergeXCleanupDashboardSwitcher";
import React from "react";
import {SingleDropdownMenu} from "../../default-menus/SingleDropdownMenu";
import {observer} from "mobx-react-lite";

export const MergeXCleanupHeader: React.FC = observer(() => {
    return <>
        <div style={{flexGrow: 1}}/>
        <MergeXCleanupDashboardSwitcher/>
        <SingleDropdownMenu/>
    </>;
})
