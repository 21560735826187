import "./TaxonomySuggestorTable.scss"
import React from 'react';
import {Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {getCollapseAnimationTiming} from "../../components/table/utils";
import {Cancel, CheckCircle, ExpandMore, KeyboardArrowRight, Warning} from "@mui/icons-material";
import {useStores} from "../../stores";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ReviewStatusType, TaxSuggesterExtendedD3HierarchyNode} from "../../services/classes/TaxonomySuggestorClasses";


type TaxonomyTableProps = {
    columns: ColSpec[];
};

type TaxonomyRowProps = {
    d: TaxSuggesterExtendedD3HierarchyNode;
    columns: ColSpec[];
};

//TODO: ADD NO DATA FOUND
const TaxonomyTable: React.FC<TaxonomyTableProps> = ({columns}) => {
    const {taxonomySuggestorStore} = useStores();

    return (
        <TableContainer className={'suggestion-table-v2-component'} sx={{maxHeight: '34em'}}>
            <Table stickyHeader>
                <MithraTableHeadColumns columns={columns}/>
                <TableBody>
                    {taxonomySuggestorStore.taxonomyTableController.data &&
                        <TaxonomyTableRow d={taxonomySuggestorStore.taxonomyTableController.data as any} columns={columns}/>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TaxonomyTableRow: React.FC<TaxonomyRowProps> = ({d, columns}) => {
    const {taxonomySuggestorStore} = useStores();
    const {children} = d;

    // const label = d.data.viz.label;
    // const review_status = d.data.apiValues.review_status;
    // const values = d.data.apiValues.values;

    const [open, setOpen] = React.useState(false);

    const handleAccept = (d: TaxSuggesterExtendedD3HierarchyNode) => {
        taxonomySuggestorStore.handleAccept(d as any)
    }

    const handleReject = (d: TaxSuggesterExtendedD3HierarchyNode) => {
        taxonomySuggestorStore.handleReject(d as any)
    }

    const renderChildren = (childrenData: TaxSuggesterExtendedD3HierarchyNode[]) => {
        return childrenData.map(childData => (
            <TaxonomyTableRow d={childData} columns={columns}/>
        ));
    };

    const getSuggestionType = (review_status: ReviewStatusType) => {
        if (review_status.added) {
            return 'Added';
        } else if (review_status.deleted) {
            return 'Deleted';
        } else if (review_status.renamed) {
            return 'Renamed';
        }
    }

    if (d.data.viz.filtered) {
        return <></>
    }

    const indentation = `${d.depth - 1}rem`;
    const isRootNode = d.depth === 0;
    if (isRootNode && children && children.length > 0) {
        return <React.Fragment>{renderChildren(children)}</React.Fragment>;
    }

    let i = 0;
    return (
        <React.Fragment key={d.id}>
            {/*{'' + taxonomySuggestorStore.taxonomyTableController.filter}*/}
            {/*{'' + JSON.stringify(d.data.apiValues)}*/}
            <TableRow>
                <MithraTableCell c={columns[i++]} onClick={() => setOpen(!open)}>
                    <div style={{marginLeft: indentation}}>

                        {(children ? children.length > 0 : false) ?
                            <IconButton aria-label="expand row" size="small">
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton> :
                            <IconButton aria-label="expand row" size="small" style={{visibility: 'hidden'}}>
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton>}

                        {d.data.viz.hasChangesInChildren ?
                            <IconButton> <Warning style={{color: '#FAA915'}}/> </IconButton> : null}

                        {d.data.apiValues.review_status?.renamed ? (
                            d.data.apiValues.review_status?.accepted === false && d.data.apiValues.review_status?.rejected === false ? (
                                <span>{`${d.data.apiValues.review_status.oldLabel} → ${d.data.apiValues.review_status.newLabel}`}</span>
                            ) : d.data.apiValues.review_status?.accepted ? (
                                <span>{`${d.data.apiValues.review_status.newLabel} (${d.data.apiValues.review_status.oldLabel})`}</span>
                            ) : d.data.apiValues.review_status?.rejected ? (
                                <span>{`${d.data.apiValues.review_status.oldLabel} (${d.data.apiValues.review_status.newLabel})`}</span>
                            ) : null
                        ) : d.data.viz.label}
                    </div>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={d.data.apiValues?.p__spend__sum} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={(d.data.apiValues as any)?.p__spend__sum__a} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={(d.data.apiValues as any)?.p__spend__sum__b} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={(d.data.apiValues as any)?.p__spend__sum__c} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    {d.data.apiValues?.p__id__count}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    {d.data.apiValues?.s__id__nunique}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    {d.data.apiValues.review_status && (d.data.apiValues.review_status.added || d.data.apiValues.review_status.deleted || d.data.apiValues.review_status.renamed) &&
                        <Chip label={getSuggestionType(d.data.apiValues.review_status)}
                              className={'chip ' + getSuggestionType(d.data.apiValues.review_status)}
                              style={{width: '100%'}}/>}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} style={{width: '10%'}} className={'accept-reject-button'}>
                    {d.data.apiValues.review_status && (d.data.apiValues.review_status.added || d.data.apiValues.review_status.deleted || d.data.apiValues.review_status.renamed) &&
                        <>
                            <IconButton
                                className={d.data.apiValues.review_status.accepted ? 'accept-reject-button accepted' : 'accept-reject-button'}
                                onClick={() => handleAccept(d)}>
                                <CheckCircle/>
                            </IconButton>

                            <IconButton
                                className={d.data.apiValues.review_status.rejected ? 'accept-reject-button rejected' : 'accept-reject-button'}
                                onClick={() => handleReject(d)}>
                                <Cancel/>
                            </IconButton>
                        </>}
                </MithraTableCell>
            </TableRow>
            <TableRow className={open ? 'is-open' : ''}>
                <TableCell colSpan={columns.length} style={{padding: '0'}}>
                    {children && children.length > 0 && children.length &&
                        <Collapse in={open} timeout={getCollapseAnimationTiming(children)} unmountOnExit>
                            <Table>{renderChildren(children)}</Table>
                        </Collapse>}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


export default TaxonomyTable;
