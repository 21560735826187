import {ApprovalCategorization} from "../../../services/classes/MatReviewHelpers";
import {MatPartReviewRow, MatSGroupAggregate, ReviewChoice} from "../../../services/classes/MaterializedClasses";


/**
 * VISUAL LOGIC AS 20/11/2023 FOLLOWING MOGHAVI FIGMA DESIGN
 *
 * SINGULAR
 * suggested_l   |   review_l   |   review_choice   |   feedback_choice   |   approval_id  | approval_applied
 *      L1       |      ''      |        NULL       |         NULL        |       NULL     |             ->  N/A
 *      L1       |    L1/L1'    |      accepted     |         NULL        |       NULL     |             ->  NOT_SENT
 *      L1       |    L1/L1'    |      accepted     |         NULL        |         1      |       0     ->  PENDING
 *      L1       |    L1/L1'    |      accepted     |    0/1 (acc/rej)    |         1      |       1     ->  APPROVED/REJECTED
 * */


/**
 * GROUPED
 * FIXME: [CAT-1497] Need a better specification to define what's needed in the API call and determine what's the state
 * all_category_is_uncat|exists_invalid_suggestion|all_invalid_suggestion|exists_open_suggestion|all_is_reviewed|exists_approval_set|exists_approval_applied|all_approval_set|all_approval_applied
 *                      |                         |                      |                      |               |                   |                       |      true      |         true       -> APPROVED
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *                      |                         |                      |                      |               |                   |                       |                |
 *
 *
 *
 **/

export enum CatReviewPartStateEnum {
    N_A = 'N/A',
    NOT_SENT = 'NOT_SENT',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum CatReviewGroupStateEnum {
    N_A = 'N/A',
    NOT_SENT = 'NOT_SENT',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    MIXED = 'MIXED'
}

export type CatReviewPartState = CatReviewPartStateEnum.N_A |
    CatReviewPartStateEnum.NOT_SENT |
    CatReviewPartStateEnum.PENDING |
    CatReviewPartStateEnum.APPROVED |
    CatReviewPartStateEnum.REJECTED

export type CatReviewGroupState = CatReviewGroupStateEnum.N_A |
    CatReviewGroupStateEnum.NOT_SENT |
    CatReviewGroupStateEnum.PENDING |
    CatReviewGroupStateEnum.APPROVED |
    CatReviewGroupStateEnum.REJECTED |
    CatReviewGroupStateEnum.MIXED

/**
 * Defines how to render the visual elements of the review state of a single part
 */
export type SinglePartReviewStateVisual = {
    editAllowed: boolean //FIXME: Remove this to make file leaner
    reviewedByOther: boolean
    leftCat: ApprovalCategorization
    rightCat: ApprovalCategorization
    isHidden: boolean //FIXME: Remove this to make file leaner
    reviewChoice: ReviewChoice | null //FIXME: Remove this to make file leaner
    approvalState: CatReviewPartState
}


/**
 * Convert the aggregated data from the backend to something we can render directly
 */
export function buildSinglePartReviewStateVisual(row: MatPartReviewRow, reviewMine: boolean): SinglePartReviewStateVisual {
    let leftCat: SinglePartReviewStateVisual['leftCat'] = 'input';
    let rightCat: SinglePartReviewStateVisual['rightCat'] = 'review|ai';
    let reviewedByOther = row.review_user_id !== null && !reviewMine;
    let reviewChoice = row.review_choice;

    //@formatter:off
    let caseNA                 = row.review_choice === null && row.feedback_choice == null                 && row.approval == null && !row.approval_applied
    let caseNotSent            = row.review_choice !== null && row.feedback_choice == null                 && row.approval == null && !row.approval_applied
    let casePendingNotReviewed = row.review_choice !== null && row.feedback_choice == null                 && row.approval != null && !row.approval_applied //Pending, not yet approved
    let casePendingReviewed    = row.review_choice !== null && row.feedback_choice !== null                && row.approval != null && !row.approval_applied //Pending, apr/rej but not yet applied
    let caseApproved           = row.review_choice !== null && row.feedback_choice === ReviewChoice.ACCEPT && row.approval == null &&  row.approval_applied
    let caseRejected           = row.review_choice !== null && row.feedback_choice === ReviewChoice.REJECT && row.approval == null &&  row.approval_applied
    //@formatter:on

    let approvalState: SinglePartReviewStateVisual['approvalState'] = CatReviewPartStateEnum.N_A;
    let editAllowed = true;
    let isHidden = false;
    if (caseNA) {
        // default case
        isHidden = false
        reviewChoice = null
    } else if (caseNotSent) {
        approvalState = CatReviewPartStateEnum.NOT_SENT
        editAllowed = true;
    } else if (casePendingNotReviewed || casePendingReviewed) {
        approvalState = CatReviewPartStateEnum.PENDING
        editAllowed = false;
    } else if (caseApproved) {
        approvalState = CatReviewPartStateEnum.APPROVED
        editAllowed = true;
    } else if (caseRejected) {
        approvalState = CatReviewPartStateEnum.REJECTED
        editAllowed = true;
    } else {
        if (row.review_choice === null && row.feedback_choice !== null && row.approval === null && !row.approval_applied) {
            // This case is weird as there is no REVIEW but it has been ACCEPTED before
            // This is a special case that can occur during incomplete migrations
            console.error('Unexpected MatPartReviewRow state, hotfix part', row.databag, row.p_id)
            // TODO: Write down query to hotfix this
            // UPDATE m_review_matpartreviewrow
            // SET approval_applied = true
            // WHERE review_choice IS NULL
            //   AND feedback_choice IS NOT NULL
            //   AND approval_id IS NULL
            //   AND approval_applied = false
            //   and databag_id in (145099, 146099, 147099, 148099)
        } else {
            const errState = {
                review_choice: row.review_choice,
                feedback_choice: row.feedback_choice,
                approval: row.approval,
                approval_applied: row.approval_applied,
            }
            //UNCOMMENT JUST FOR DEBUGGING, DO NOT PUSH TO PROD
            // console.error('Unexpected MatPartReviewRow state ' + JSON.stringify(errState))
        }
        editAllowed = true;
    }

    return {editAllowed, reviewedByOther, leftCat, rightCat, approvalState, isHidden, reviewChoice};
}

/**
 * Defines how to render the visual elements of a grouped views
 */
export type CombinedPartReviewStateVisual = {
    hasUnclassifiedSuggestions: boolean
    editAllowed: boolean
    reviewedByOther: boolean
    cat: ApprovalCategorization
    combinedCategory: string[] | false | 'uncategorized'
    reviewChoice: ReviewChoice | null
    isHidden: boolean
    approvalState: CatReviewGroupState
    disableAcceptReason?: string
    disableEditReason?: string
};

/**
 * Convert the aggregated data from the backend to something we can render directly
 * @param aggregated_data
 */
export function buildCombinedPartReviewStateVisual(aggregated_data: MatSGroupAggregate): CombinedPartReviewStateVisual {
    // TODO: This function is quite convoluted and is missing a clear diagram
    //   As well, the `state` field should not be used anymore
    let combinedCategory: CombinedPartReviewStateVisual['combinedCategory'];
    if (aggregated_data.all_category_is_uncat) {
        combinedCategory = 'uncategorized';
    } else {
        combinedCategory = aggregated_data.category || false;
    }
    let hasUnclassifiedSuggestions = false;
    if (!aggregated_data) {
        return {
            isHidden: false,
            editAllowed: false,
            reviewedByOther: false,
            cat: 'none',
            combinedCategory,
            reviewChoice: null,
            hasUnclassifiedSuggestions,
            approvalState: CatReviewGroupStateEnum.N_A,
            disableAcceptReason: undefined,
            disableEditReason: undefined,
        };
    }
    let cat: CombinedPartReviewStateVisual['cat'] = 'review|ai';
    let editAllowed = true;
    let reviewedByOther = aggregated_data.reviewed_by_other;
    let approvalState: CatReviewGroupState = CatReviewGroupStateEnum.N_A;
    let isHidden = false;
    let reviewChoice: ReviewChoice | null = null
    hasUnclassifiedSuggestions = aggregated_data.exists_invalid_suggestion;

    let caseNA = !aggregated_data.all_is_reviewed && !aggregated_data.exists_approval_set && !aggregated_data.exists_approval_applied && !aggregated_data.all_approval_set && !aggregated_data.all_approval_applied  //ex 'open'
    let caseNotSent = aggregated_data.all_is_reviewed && !aggregated_data.exists_approval_set && !aggregated_data.exists_approval_applied
    let casePending = aggregated_data.all_is_reviewed && aggregated_data.all_approval_set && !aggregated_data.exists_approval_applied
    let caseApproved = aggregated_data.all_is_reviewed && !aggregated_data.exists_approval_set && aggregated_data.all_approval_applied && aggregated_data.all_feedback_choice_accept
    let caseRejected = aggregated_data.all_is_reviewed && !aggregated_data.exists_approval_set && aggregated_data.all_approval_applied && aggregated_data.all_feedback_choice_reject
    let caseMixed = (aggregated_data.exists_open_suggestion && !aggregated_data.all_is_reviewed) || (!aggregated_data.exists_approval_set && !aggregated_data.all_approval_set)

    if (caseNA) {
        approvalState = CatReviewGroupStateEnum.N_A
        editAllowed = true;
        isHidden = false
    } else if (caseNotSent) {
        approvalState = CatReviewGroupStateEnum.NOT_SENT
        editAllowed = true;
        isHidden = false
        reviewChoice = ReviewChoice.ACCEPT;
    } else if (casePending) {
        approvalState = CatReviewGroupStateEnum.PENDING
        editAllowed = true;
        isHidden = true
        reviewChoice = ReviewChoice.ACCEPT;
    } else if (caseApproved) {
        approvalState = CatReviewGroupStateEnum.APPROVED
        editAllowed = true;
        isHidden = false
        reviewChoice = ReviewChoice.ACCEPT;
    } else if (caseRejected) {
        approvalState = CatReviewGroupStateEnum.REJECTED
        editAllowed = true;
        isHidden = false
        reviewChoice = ReviewChoice.ACCEPT;
    } else if (caseMixed) {
        approvalState = CatReviewGroupStateEnum.MIXED
        editAllowed = true;
        isHidden = false;

        if (aggregated_data.all_is_reviewed) {
            approvalState = CatReviewGroupStateEnum.NOT_SENT;
            reviewChoice = ReviewChoice.ACCEPT;

            editAllowed = true;
        } else {
            isHidden = true;
            editAllowed = true;
        }
    } else {
        //UNCOMMENT JUST FOR DEBUGGING, DO NOT PUSH TO PROD
        //console.warn('Unexpected SGroup state, possible ERROR', aggregated_data)
        //console.error('Unexpected SGroup state, possible ERROR', aggregated_data.supplier_id, aggregated_data.mat_s_group_ids)
        editAllowed = true;
    }
    return {
        editAllowed,
        reviewedByOther,
        cat,
        combinedCategory,
        hasUnclassifiedSuggestions,
        approvalState,
        isHidden,
        reviewChoice
    };
}