import React from "react";
import {styled} from '@mui/material/styles';
import {observer} from "mobx-react-lite";
import {Autocomplete, createFilterOptions, Popover, TextField} from "@mui/material";
import {PartCategorizationComponent} from "./PartCategorizationComponent";
import {toJS} from "mobx";
import {TaxonomyCategory} from "../../stores/TaxonomyCategoryProvider";
import {PartReCategorizationControllerStore} from "./PartReCategorizationControllerStore";

const classes = {
    typography: `PartReCategorizationHandler-typography`,
    popoverContent: `PartReCategorizationHandler-popoverContent`,
    option: `PartReCategorizationHandler-option`
};

const StyledPopover = styled(Popover)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    },

    [`& .${classes.option}`]: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    }
}));


const filterOptions = createFilterOptions<TaxonomyCategory>({
    matchFrom: 'any',
    stringify: option => option.search,
});

type Props = {
    controller?: PartReCategorizationControllerStore
    cmpId?: string
    label?: string
}
export const PartReCategorizationHandler: React.FC<Props> = observer(({controller, cmpId, label}) => {
    if (!controller || !controller.taxonomy) {
        return <></>;
    }
    const autoId = cmpId ?? `part-recat-component`;
    const popId = controller.isOpen ? autoId + '-popover' : undefined;
    label = label || 'Search for a category';
    return <StyledPopover
        id={popId}
        open={controller.isOpen}
        anchorEl={controller.anchor}
        onClose={() => controller.close()}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <Autocomplete
            id={autoId}
            style={{width: 800}}
            options={controller.taxonomy}
            className={classes.popoverContent}
            classes={{
                option: classes.option,
            }}
            filterOptions={filterOptions}
            autoHighlight
            getOptionLabel={(option) => option.label} // Dennis: What does this do?
            renderOption={(props, option) =>
                <li {...props}>
                    <PartCategorizationComponent
                        cats={option.values}
                    />
                </li>
            }
            onChange={(_, newValue: any | null) => {
                const newCats = toJS(newValue.values) as string[];
                controller.onSelectCategory(newCats)
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    autoFocus
                    inputProps={{
                        ...params.inputProps,
                        // autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }
        />
    </StyledPopover>
})
