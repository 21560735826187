import '../SalesDemoDashboard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {useStores} from "../../../../stores";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {TaxonomyMapperIcon} from "../../../../components/icons/TaxonomyMapperIcon";
import {DashboardHeader} from "../../../dashboard/DashboardHeader";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {environment} from "../../../../env";
import {CompareArrowsOutlined} from "@mui/icons-material";

export const SalesDemoSynergyMergeDashboard: React.FC = observer(() => {
    const {bagStore, categorizationReviewStore, taxonomyMapperStore, taxonomyManagerStore} = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    return <>
        {environment.isTest && <h1>SalesDemoSynergyMergeDashboard</h1>}
        <DashboardHeader/>
        <Grid container
              className="dashboard synergy-analytics-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Viewer"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.demo_merge_taxonomy_viewer, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="taxonomy-suggester"
                    avatar={<div className="composite-icon-wrapper synergy-categories-icon hack-lighten-borders">
                        <ClassificationHierarchyIcon/>
                        <CompareArrowsOutlined className="second-icon" style={{marginLeft: '5px'}}/>
                    </div>}
                    title="Taxonomy Suggester"
                    // subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.demo_merge_taxonomy_suggester, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    value={taxonomyMapperStore.mapper.unMatchedCategories?.length
                        || DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
                    subtitle="unmapped categories"
                    link={generatePath(routes.demo_merge_taxonomy_mapper, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Harmonize Categories"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.demo_merge_cat_review, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
        </Grid>
    </>
});
