import {
    DEBUG_LIMIT_DATA,
    IS_TE_DEVELOPMENT_MODE,
    TAXONOMY_LABEL_HEIGHT,
    TaxonomyEditorOptions
} from "../TaxonomyEditorOptions";
import TaxonomyEditorStore, {Category, TaxonomyNodeValueMode} from "../../../../stores/TaxonomyEditorStore";
import TaxonomyManagerStore from "../../../../stores/TaxonomyManagerStore";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";
import {TaxonomyRenderer} from "./TaxonomyRenderer";
import {taxonomy_editor} from "../TaxonomyEditorTypes";
import {reaction} from "mobx";
import * as d3 from "d3";
import {UNCATEGORIZED_LABEL} from "../../../../constants";
import {HierarchyNode} from "d3-hierarchy";
import {Writeable} from "../../../../utils/ts-utils";
import {sum} from "../../../../utils/js-utils";
import AuthStore from "../../../../stores/AuthStore";

type Node = taxonomy_editor.GraphNode;

/**
 * For controlling the editing operations of the taxonomy
 * This controller combine the React, Mobx and d3.js frameworks
 */
export class TaxonomyTreeEditorController {
    private renderer: TaxonomyRenderer

    constructor(
        public wrapperRef: HTMLDivElement,
        data: m_taxonomy.FullSerializer,
        public options: TaxonomyEditorOptions,
        private taxonomyEditorStore: TaxonomyEditorStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private authStore: AuthStore,
    ) {
        const d = TaxonomyTreeEditorController.preProcessData(data, taxonomyEditorStore.valueMode);
        this.renderer = new TaxonomyRenderer(this, d)

        reaction(
            () => taxonomyManagerStore.historyState,
            () => this.renderer.setHistoryClasses(this.taxonomyManagerStore.historyState)
        )

        reaction(
            () => taxonomyEditorStore.builderMode,
            (curr, prev) => {
                const prevIsEdit = prev === 'edit';
                const currIsEdit = curr === 'edit';

                const prevIsFull = TaxonomyEditorStore.isFullViewMode(prev);
                const currIsFull = TaxonomyEditorStore.isFullViewMode(curr)

                const changeEdit = currIsEdit !== prevIsEdit;
                const expand = !prevIsFull && currIsFull ? 'expand' : prevIsFull && !currIsFull ? 'shrink' : 'keep';

                this.onChangeMode(changeEdit, expand)
            }
        )
        taxonomyEditorStore.registerController(this)
    }

    public onDataUpdate(data: m_taxonomy.FullSerializer) {
        console.log('[CONTROLLER] onDataUpdate')
        const d = TaxonomyTreeEditorController.preProcessData(data, this.taxonomyEditorStore.valueMode);
        const focusId = this.taxonomyEditorStore.focus?.data.id ?? null;
        const newFocus = this.renderer.drawNewHierarchy(d, focusId);
        this.taxonomyEditorStore.setFocus(newFocus)
    }

    private static preProcessData(data: taxonomy_editor.InputData, valueMode: TaxonomyNodeValueMode): HierarchyNode<taxonomy_editor.NodeData> {
        // TODO: Add sorting of the tree
        const tree = data.diff_tree
        if (!tree) throw Error('`diff_tree` not found in taxonomy data')
        const rootData: taxonomy_editor.NodeData = TaxonomyTreeEditorController.extractNodeData(tree);
        const rootHierarchy: HierarchyNode<taxonomy_editor.NodeData> = d3.hierarchy(rootData)
        TaxonomyTreeEditorController.applyValueMode(rootHierarchy, valueMode)

        if (IS_TE_DEVELOPMENT_MODE) {
            TaxonomyTreeEditorController.checkDuplicates(rootHierarchy)
        }

        return rootHierarchy
    }

    public static checkDuplicates(root: HierarchyNode<taxonomy_editor.NodeData>) {
        // Count the ids
        const idMap = new Map<number, taxonomy_editor.NodeData[]>()
        root.descendants().forEach(n => {
            const id = n.data.id;
            const matches = idMap.get(id) || []
            matches.push(n.data)
            idMap.set(id, matches)
        })
        const duplicates = Array.from(idMap.entries()).filter(([_, matches]) => matches.length > 1)
        if (duplicates.length > 0) {
            console.error('Found duplicate ids in the data of the taxonomy builder: ',
                duplicates.map(([id, count]) => `id=${id} (${count.map(c => `${c.label}`)})`)
            )
        } else {
            console.log(`No duplicates found in ${idMap.size} ids`)
        }
    }

    private static extractNodeData(inNode: m_taxonomy.Tree, depth = 1): taxonomy_editor.NodeData {
        let inChildren = inNode.children;
        if (DEBUG_LIMIT_DATA) {
            if (depth >= 3) {
                inChildren = []
            } else {
                inChildren = inChildren.filter((_, i) => i < 3)
            }
        }
        // Remove uncategorized
        inChildren = inChildren.filter(c => c.label !== UNCATEGORIZED_LABEL)

        const outNode: taxonomy_editor.NodeData = TaxonomyTreeEditorController.extractSingleNodeData(inNode);
        outNode.children.push(...inChildren.map<taxonomy_editor.NodeData>(c =>
            TaxonomyTreeEditorController.extractNodeData(c, depth + 1)
        ))
        return outNode
    }

    private static extractSingleNodeData(inputNode: m_taxonomy.Tree): taxonomy_editor.NodeData {
        let values: taxonomy_editor.Values
        const isLeaf = inputNode.children.length === 0
        if (isLeaf) {
            values = {...inputNode.values}
        } else {
            values = taxonomy_editor.extractIntermediateValues(inputNode.values)
        }
        return taxonomy_editor.buildNode({
            id: inputNode.id,
            label: inputNode.label,
            values,
            sources: inputNode.sources,
            removed: inputNode.removed,
        }, inputNode.values.authors || [])
    }

    private createNewNode(data: taxonomy_editor.AddNodeData): taxonomy_editor.NodeData {
        const authors = [this.authStore.getUserId()];
        return taxonomy_editor.buildNode({
            id: data.newId,
            label: data.label,
            values: taxonomy_editor.NO_VALUES(data.description, authors),
            sources: [],
            removed: false,
        }, authors);
    }

    private createMergedNode(data: taxonomy_editor.MergeNodeData): taxonomy_editor.NodeData {

        // Combine authors
        const authorsSet = new Set(data.sources.flatMap(n => n.data.values.authors || []));
        authorsSet.add(this.authStore.getUserId());
        const authors = Array.from(authorsSet);

        return taxonomy_editor.buildNode({
            id: data.destination.newId,
            label: data.destination.label,
            values: taxonomy_editor.mergeValues(
                data.sources.map(n => n.data.values),
                '',
            ),
            sources: data.sources.flatMap(n => n.data.sources),
            removed: false,
        }, authors);
    }

    get isEditMode() {
        return this.taxonomyEditorStore.isEditMode
    }

    get focusLevel() {
        return this.taxonomyEditorStore.focusLevel
    }

    backgroundClicked() {
        this.taxonomyEditorStore.setSelection([]);
    }

    onClickBar(n: Node) {
        console.log('[CONTROLLER] clicked bar', n.data.id)

        const prevFocus = this.taxonomyEditorStore.focus
        const newFocus = TaxonomyTreeEditorController.focusNavClick(n, prevFocus)
        this.changeFocus(prevFocus, newFocus)
    }

    onDoubleClickLabel(n: Node) {
        console.log('[CONTROLLER] double clicked label', n.data.id)
        if (this.taxonomyEditorStore.isEditMode) {
            this.taxonomyEditorStore.setSelected(n, true)
            this.renderer.drawSelection()
            if (this.taxonomyEditorStore.selection.length === 1) {
                this.taxonomyEditorStore.setUpdateCategoryMode(true)
            }
        }
    }

    onClickLabel(n: Node) {
        console.log('[CONTROLLER] clicked label', n.data.id)
        console.log('[CONTROLLER] clicked label - n: ', n.y0, n.y1)
        console.log('[CONTROLLER] clicked label - n.data.target: ', n.data.target.y0, n.data.target.y1)

        if (!this.taxonomyEditorStore.isEditMode) {
            const prevFocus = this.taxonomyEditorStore.focus
            const newFocus = TaxonomyTreeEditorController.focusNavClick(n, prevFocus)
            this.changeFocus(prevFocus, newFocus)
        } else {
            this.taxonomyEditorStore.toggleSelection(n)
            this.renderer.drawSelection()
        }
    }

    moveFocusUp() {
        const prevFocus = this.taxonomyEditorStore.focus;
        console.log('Go up one level', prevFocus?.depth)
        if (prevFocus && prevFocus.parent) {
            const newFocus = prevFocus.parent
            this.changeFocus(prevFocus, newFocus)
        }
    }

    public changeFocusAndSetSelection(nodeId: number) {
        const node = this.findNodeById(nodeId)
        if (!node || !node.parent) {
            console.warn('Cannot navigate to node, not found', nodeId)
            return false;
        }
        const newFocus = this.findNodeById(node.parent.data.id);
        if (!newFocus) {
            console.warn('Cannot navigate to node parent, not found', nodeId)
            return false;
        }
        const prevFocus = this.taxonomyEditorStore.focus;

        this.taxonomyEditorStore.setFocus(newFocus)
        this.taxonomyEditorStore.selectionIds.replace([nodeId]);
        this.renderer.setFocusClass(newFocus)

        const factor = TaxonomyRenderer.estDurationFactor(prevFocus, newFocus)
        this.renderer.animateTarget(newFocus, factor)
        this.renderer.drawSelection()

        return true;
    }

    private changeFocus(prevFocus: Node | undefined, newFocus: Node) {
        const _newFocus = this.findNodeById(newFocus.data.id);
        if (!_newFocus) {
            console.error('Could not find the new focus')
            return;
        }
        newFocus = _newFocus;

        this.taxonomyEditorStore.setFocusAndClearSelection(newFocus)
        this.renderer.drawSelection()
        this.renderer.setFocusClass(newFocus)

        const factor = TaxonomyRenderer.estDurationFactor(prevFocus, newFocus)
        this.renderer.animateTarget(newFocus, factor)
    }

    /**
     * TODO: Replace reDrawV1 with reDrawV2
     *
     * This function should properly animate the targets as well (WIP)
     */
    public reDrawV2() {
        const prevFocus = this.taxonomyEditorStore.focus;
        console.log('[TaxonomyRenderer] reDraw2', prevFocus?.data.id)

        // this.renderer.drawView() //  <<<<< DO NOT

        this.renderer.resetLayout();

        const newFocus = this.findFocus()
        this.taxonomyEditorStore.overwriteFocus(newFocus ?? undefined)

        const targetFocus = newFocus || this.renderer.root;
        const factor = TaxonomyRenderer.estDurationFactor(prevFocus, targetFocus)
        this.renderer.animateTarget(targetFocus, factor)

    }

    public onChangeMode(changeEdit: boolean, changeView: 'expand' | 'shrink' | 'keep') {
        console.log('[CONTROLLER] [MODE CHANGE] changeEdit=', changeEdit, 'changeView=', changeView)

        if (IS_TE_DEVELOPMENT_MODE) {
            this.checkFocus()
        }

        let p: Promise<void>;
        if (changeView !== 'keep') {
            p = this.changeViewMode()
        } else {
            p = Promise.resolve();
        }

        if (changeEdit) {
            p = p.then(() => this.changeEditMode())
        }
        p.then(() => this.renderer.setWrapperClasses(this.taxonomyEditorStore.builderMode))

    }

    private changeEditMode(): Promise<void> {
        return this.renderer.onChangeEditMode().end();
    }

    private changeViewMode(): Promise<void> {
        const editMode = this.taxonomyEditorStore.isEditMode;
        const fitViewMode = !this.taxonomyEditorStore.isFullViewMode;
        if (fitViewMode) {
            // The visualization has been shrunk
            console.log('[CONTROLLER] changeViewMode() -> shrink')

            const svgHeight = this.renderer.setGraphHeight(this.options.fitViewModeHeight);

            // Does not work:
            // this.renderer.reDraw(this.taxonomyEditorStore.focus)

            // Change the focus back
            const focus = this.taxonomyEditorStore.focus || this.renderer.root;
            const factor = TaxonomyRenderer.estDurationFactor(this.renderer.root, focus)
            const transition = this.renderer.animateTarget(focus, factor)

            return this.renderer.scrollWrapperToTop(transition)
                .end()
                .then(() => {
                    this.renderer.setSvgViewPort(this.options.width, svgHeight);
                })
        } else {
            // The visualization is expanded
            const nNodes = this.renderer.root.leaves().length;
            const newHeight = Math.ceil(nNodes * (TAXONOMY_LABEL_HEIGHT + 1) * 1.5);
            console.log('[CONTROLLER] changeViewMode() -> expand', nNodes)

            // Update the total graph height of the visualization
            const svgHeight = this.renderer.setGraphHeight(newHeight);

            // Update the view port of the SVG
            this.renderer.setSvgViewPort(this.options.width, svgHeight)
            this.renderer.setWrapperClasses(this.taxonomyEditorStore.builderMode)
            this.scrollWindowToBuilder();

            const focus = this.taxonomyEditorStore.focus || this.renderer.root;
            const factor = TaxonomyRenderer.estDurationFactor(focus, this.renderer.root)
            const transition = this.renderer.animateTarget(focus, factor)
            const duration = transition.duration();

            if (IS_TE_DEVELOPMENT_MODE) {
                this.checkFocus()
            }

            return this.renderer.scrollWrapperToFocus(duration, focus, newHeight);
        }
    }

    /**
     * Scroll the window to wrapper of the builder
     */
    private scrollWindowToBuilder() {
        const documentRect = document.body.getBoundingClientRect();
        const wrapperRect = this.wrapperRef.getBoundingClientRect();
        const buttonToolBarSize = 100;
        window.scrollTo({
            top: wrapperRect.top - documentRect.top - buttonToolBarSize,
            behavior: 'auto',
        })
    }

    isSelected(d: Node) {
        if (!d) {
            console.warn('Cannot determine selection of node', d)
            return false;
        }
        return this.taxonomyEditorStore.selection.findIndex(s => s.data.id === d.data.id) !== -1
    }

    isNavigateable(d: Node) {
        return TaxonomyTreeEditorController.isFocusNavClickable(d, this.taxonomyEditorStore.focus)
    }

    static isFocusNavClickable(node: Node, prevFocus?: Node): boolean {
        return Boolean(TaxonomyTreeEditorController.focusNavClick(node, prevFocus));
    }

    /**
     * Get the new focus
     */
    private static focusNavClick(clickedNode: Node, prevFocus?: Node): Node {
        if (!clickedNode.parent) {
            console.error('Expected user not to be able to click on the root')
            return clickedNode;
        }

        if (clickedNode.data.id === prevFocus?.data.id && clickedNode.parent) {
            // When the currently selected node is clicked
            // Go up one level
            return clickedNode.parent;
        } else if (clickedNode.height === 0) {
            // When the deepest node is clicked
            return clickedNode;

            // TODO: Do not limit the taxonomy size for now
            // // Limit the depth on which nodes can be added
            // // Go as far as we can
            // return clickedNode.parent;
        } else {
            return clickedNode;
        }
    }

    updateValueMode() {
        TaxonomyTreeEditorController.applyValueMode(this.renderer.root, this.taxonomyEditorStore.valueMode)
        this.renderer.reDrawV1(this.taxonomyEditorStore.focus)
    }

    addNode(data: taxonomy_editor.AddNodeData) {
        // Build a new node

        let parent: Node, index: number;
        const insert = this.getNewInsertPosition()
        console.log('Adding node', data, 'at', insert)
        if (!insert) return;
        [parent, index] = insert;
        const parentValue = parent.value || 0

        const newNodeData = this.createNewNode(data)

        let newValue: number = -1
        if (!parent.children) {
            parent.children = []
        }
        const siblings = parent.children.filter(n => !n.data.removed);

        switch (this.taxonomyEditorStore.valueMode.calc) {
            case 'leaf=1':
                newValue = 1

                const parentIsLeaf = parent.height === 0;
                if (!parentIsLeaf) {
                    parent.ancestors().filter(n => !n.data.removed).forEach(node => {
                        const _node = node as Writeable<typeof node>
                        _node.value = (_node.value || 0) + 1
                    })
                }
                // console.log('ancestors updated', newNode.ancestors().map(n => n.value))
                break
            // case 'equal':
            //     // Make the value pool bigger
            //     const nChildren = siblings.length
            //     const childValue = parentValue / nChildren
            //
            //     newValue = childValue;
            //     _parent.value = parentValue + childValue;
            //     break
            case 'avg_dist':
                const nChildren = siblings.length
                console.log('Adding value, nChildren=' + nChildren + ', parent=' + parentValue)
                if (nChildren === 0) {
                    newValue = parentValue
                } else {
                    const removeFraction = 1 / (nChildren + 1)
                    console.log('removeFraction=' + removeFraction)
                    newValue = parentValue * removeFraction
                    siblings.forEach(c => {
                        c.descendants().filter(n => !n.data.removed).forEach(node => {
                            const _node = node as Writeable<typeof node>
                            const oldValue = (node.value || 0)
                            _node.value = oldValue * (1 - removeFraction)
                        })
                    })
                }
                // Parent remains untouched
                break
            default:
                throw Error()
        }

        TaxonomyTreeEditorController.insertNewNode(newNodeData, newValue, parent, index);

        this.renderer.reDrawV1(this.taxonomyEditorStore.focus)
    }

    private static insertNewNode(data: taxonomy_editor._NodeData, value: number, parent: Node, index: number): Node {
        if (!parent.children) throw Error()
        const newHierarchy = d3.hierarchy(data)

        // The position is missing, but it will be set when the renderer re calculated the layout
        const newNode = newHierarchy as Node

        const _newNode = newNode as Writeable<typeof newNode>
        _newNode.depth = parent.depth + 1
        _newNode.height = 0
        _newNode.parent = parent
        _newNode.value = value
        if (index === -1) {
            // Just append
            parent.children.push(newNode)
        } else {
            parent.children.splice(index, 0, newNode)
        }
        return newNode;
    }

    private getNewInsertPosition(): [Node, number] | undefined {
        const focus = this.taxonomyEditorStore.focus;
        if (focus && focus.depth !== 0) {
            // // Option1: Try to insert next to the focus
            // const parent = focus.parent as Node
            // if(!parent.children) {
            //     console.warn('No children')
            //     return
            // }
            // const i = parent.children.indexOf(focus)
            // return [parent, i]

            // Try to insert below to the focus
            return [focus, -1]
        } else {
            // Try to insert at the highest level
            const parent = this.renderer.root
            return [parent, -1]
        }
    }

    mergeNodes(data: taxonomy_editor.MergeNodeData) {
        // Option1: Merge by creating a new node and moving everything to that node

        // // Optional: Change the order beforehand
        // const selection: taxonomy_editor.Node[] = r
        // const parentChildren = selection[0].parent?.children as Node[];
        // const i_s = selection.map(s => [
        //     s,
        //     parentChildren.findIndex(c => s.id === c.id)
        // ] as const)
        // i_s.sort(([_, a], [__, b]) => a - b)
        // const orderedSelection = i_s.map(([s,]) => s)

        // Note: destination.value is ignored for now...
        const destination = data.destination;
        const sources = data.sources;
        console.debug('Ignoring destination.value=', destination.value)

        const mainSubject = sources[0]
        const parent = mainSubject.parent as Node
        console.log('Merging into', mainSubject)
        // const otherSubjects = sources.slice(1)
        const parentChildren = parent.children;
        if (!parentChildren) return // should not happen
        const mainSubjectI = parentChildren.indexOf(mainSubject)

        let value = 0
        switch (this.taxonomyEditorStore.valueMode.calc) {
            // Note: for leaf=1, When recalculating the values the value is different from the merged values
            // So when the page is refreshed the visualization will be different
            case 'leaf=1':
            case 'avg_dist':
                value = sum(sources.map(s => s.value || 0))
                break
            // case 'equal':
            //     const oldChildren = parentChildren.length || 0
            //     const newChildren = oldChildren - (sources.length - 1)
            //     const factor = newChildren / oldChildren
            //     console.log('Merging -equal old=' + oldChildren + ' new=' + newChildren, factor)
            //
            //     const siblings = parentChildren.filter(c => !sources.some(s => s.id === c.id)).filter(n => !n.data.removed)
            //     siblings?.forEach(c => {
            //         c.descendants().forEach(d => {
            //             const oldValue = (d.value || 0)
            //             const newValue = oldValue * factor;
            //             (d as any).value = newValue
            //         })
            //     })
            //     value = (parent.value || 0) * (1 - factor)
            //     // Parent remains untouched
            //     break
            default:
                throw Error()
        }

        console.log('Merging with value=', value, sources.map(n => n.data.id), ' --- ', parent.data.id)

        const newNodeData = this.createMergedNode(data)
        let newValue = sum(sources.map(n => n.value || 0))
        const newNode = TaxonomyTreeEditorController.insertNewNode(newNodeData, newValue, parent, mainSubjectI);
        newNode.children = []

        for (let source of sources) {
            if (source.children) {
                // Move the children of this source to the new node, also the removed nodes
                newNode.data.children.push(...source.data.children)
                newNode.children.push(...source.children)
                source.children.forEach(c => c.parent = newNode)

                // FIXME: depth and height are not updated for now, seems to cause no problems thus far... (1/2 h)
            }

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                // Should not happen
                continue
            }
            // Remove this source from its own parent
            const sourceI = sPc.indexOf(source)
            console.assert(sourceI >= 0)
            sPc.splice(sourceI, 1)
            sPd.children.splice(sourceI, 1)
        }

        this.reDrawV2()
    }

    moveNodes(sources: Node[], parentDestinationSpec: Category): Node | undefined {
        const newParent = this.findNodeById(parentDestinationSpec.node_id)
        if (!newParent) {
            console.warn('Cannot move, destination not found',
                `destination=${parentDestinationSpec}`
            )
            return undefined
        }
        if (!newParent.children) {
            newParent.children = []
        }

        for (let source of sources) {
            const _source = source as Writeable<typeof source>
            const oldAncestors = source.ancestors();

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                // Should not happen
                continue
            }
            // Remove this source from its own parent
            const sourceI = sPc.indexOf(source)
            console.assert(sourceI >= 0)
            sPc.splice(sourceI, 1)
            sPd.children.splice(sourceI, 1)

            // Add the source to the new parent
            newParent.children.push(source)
            newParent.data.children.push(source.data)
            source.parent = newParent
            const newAncestors = source.ancestors()

            // FIXME: depth and height are not updated for now, seems to cause no problems thus far...
            // _source.id = parentDestination.id + '///' + source.data.label
            _source.depth = newParent.depth + 1

            m_taxonomy.addAuthor(source.data.values, this.authStore.getUserId());

            // Update the values
            let value = source.value || 0
            switch (this.taxonomyEditorStore.valueMode.calc) {
                case 'leaf=1':
                case 'avg_dist':
                    // Remove the value from the tree
                    oldAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) - value, 0)
                    })

                    // Add the value to the new tree
                    newAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) + value, 0)
                    })
                    break
                // case 'equal':
                //     // throw new Error('Not implemented yet...')
                //     break
                default:
                    throw Error()
            }
        }

        // Update the parent height
        TaxonomyTreeEditorController.updateHeightFromLeaf(newParent)

        this.renderer.reDrawV1(this.taxonomyEditorStore.focus)

        return newParent
    }

    deleteSelection() {
        this.deleteNodes(this.taxonomyEditorStore.selection)
    }

    deleteNodes(selection: Node[]) {
        for (let source of selection) {
            const oldAncestors = source.ancestors();

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                console.error('Cannot delete, parent not found', source.data.id) // Should not happen
                continue
            }

            // Denote as deleted
            source.descendants().forEach(n => {
                m_taxonomy.addAuthor(n.data.values, this.authStore.getUserId());
                n.data.removed = true;
            })

            // Values are not updated on delete // (source as Writeable<typeof source>).value = 0;

            // Update the values
            let value = source.value || 0
            switch (this.taxonomyEditorStore.valueMode.calc) {
                case 'leaf=1':
                case 'avg_dist':
                    // Remove the value from the tree
                    oldAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) - value, 0)
                    })
                    break
                // case 'equal':
                //     // throw new Error('Not implemented yet...')
                //     break
                default:
                    throw Error()
            }

            // Update the parent height
            TaxonomyTreeEditorController.updateHeightFromLeaf(source.parent)
        }

        this.renderer.reDrawV1(this.taxonomyEditorStore.focus)
    }

    updateNodeFields(node: Node, name: string, description: string) {
        console.log('[UPDATE] updateNodeFields', node.data.id, name, description)
        node.data.label = name
        node.data.values.description = description
        m_taxonomy.addAuthor(node.data.values, this.authStore.getUserId());

        if (IS_TE_DEVELOPMENT_MODE) {
            // TODO: Cleanup for production code
            const lookupNode = this.findNodeById(node.data.id)
            if (node !== lookupNode) {
                console.error('Update a node which has been re-drawn already, please update the selection when changing the root of the renderer')
            }
            // lookupNode.data.label = name
            // lookupNode.data.values.description = description
            // const lookups = this.renderer.root.descendants().filter(n => n.data.id === node.data.id)
            // const updateNode = node;
            // console.log('[UPDATE] updateNode =', updateNode?.data.id, updateNode?.data, updateNode?.data.label)
            // const node00 = this.renderer.root.children?.at(0)?.children?.at(0);
            // console.log('[UPDATE] root[0,0] =', node00?.data.id, node00?.data.label)
            // console.log('[UPDATE] lookups =', lookups.map(n => [n.data.id, n.data.label]))
        }

        this.renderer.reDrawV1(this.taxonomyEditorStore.focus)
    }

    public static getLabels(node: Node): string[] {
        return node.ancestors().reverse().map(a => a.data.label)
        // .slice(1)
    }

    exportTree(): m_taxonomy.Tree {
        return taxonomy_editor.exportTree(this.renderer.root)
    }

    private static updateHeightFromLeaf(node: Node | null) {
        if (!node) return
        if (!node.children) return;
        let newHeight: number;
        if (node.children.length === 0) {
            newHeight = 1
        } else {
            newHeight = Math.max(...node.children.map(c => c.height));
        }
        if (newHeight !== node.height) {
            const _node = node as Writeable<typeof node>
            _node.height = newHeight
            TaxonomyTreeEditorController.updateHeightFromLeaf(node.parent)
        }
    }

    private static applyValueMode(
        rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>,
        valueMode: TaxonomyNodeValueMode,
    ) {
        const calc = valueMode.calc
        if (valueMode.initCalcFromLeaf) {
            const key = valueMode.key
            rootHierarchy
                .sum(d => {
                    if (d.removed) return 0;

                    if (calc === 'leaf=1') {
                        return d.children.length === 0 ? 1 : 0
                    } else if (calc === 'avg_dist') {
                        if (key in d.values) {
                            return d.values[key] || 0
                        } else {
                            console.warn(`Could not find ${key} in values`,
                                '' + Object.keys(d.values))
                            return 0
                        }
                    }
                })
                .sort((a, b) =>
                    // b.height - a.height ||
                    a.value && b.value ? b.value - a.value : 0
                )
        } else {
            if (calc === 'equal') {
                rootHierarchy.eachBefore(n => {
                    if (n.parent) {
                        const parentValue = n.parent.data.values['equal'] || 1;
                        // const parentValue = 1000;
                        n.data.values['equal'] = parentValue / (n.parent.children?.length || 1);
                    } else {
                        n.data.values['equal'] = 1000;
                    }
                }).sum(d => {
                    if (d.removed) return 0;
                    return (d.children.length === 0 ? d.values['equal'] : 0) || 0;
                })
            } else {
                throw Error('Not implemented yet...')
            }
        }
    }

    public getDescendants(): Node[] {
        return this.renderer.root.descendants() || [];
    }

    refreshSelection() {
        this.taxonomyEditorStore.selectionIds.replace(this.taxonomyEditorStore.selectionIds)
    }

    /**
     * To ensure the correct traces we need to lookup the node in the original root
     * @param nodeId
     */
    public findNodeById(nodeId: number): Node | null {
        return this.renderer.root.find(node => node.data.id === nodeId) ?? null;
    }

    public findNodes(nodeIds: number[]): Node[] {
        return nodeIds.map(id => this.renderer.root.find(node => node.data.id === id)) as Node[]
    }

    get isFitViewMode() {
        return this.taxonomyEditorStore.isFitMode;
    }

    public findFocus() {
        const focus = this.taxonomyEditorStore.focus;
        if (!focus) return null;
        return this.findNodeById(focus.data.id)
    }

    public checkFocus() {
        const focus = this.taxonomyEditorStore.focus;
        if (focus) {
            const realFocus = this.findFocus()
            if (!realFocus) {
                console.error('[CHECK FOCUS] Focus NOT FOUND')
                return
            }
            if (focus === realFocus) {
                console.log('[CHECK FOCUS] Focus is OK')
            } else {
                console.warn('[CHECK FOCUS] Focus WRONG:', focus.data.id, focus.data.target.y1, '!=', realFocus.data.target.y1)
            }
        }
    }
}
