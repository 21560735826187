import React from "react";
import {observer} from "mobx-react-lite";
import {Stack, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {getParentSupplierChoice, ParentSupplierReviewRow, SupplierReviewRow} from "../SupplierNormalization.classes";
import {getSupplierNormalizationSupplierColumns} from "./columns";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {ParentSupplierChooser} from "../components/ParentSupplierChooser";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {useStores} from "../../../../stores";
import {environment} from "../../../../env";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";

type Props = {
    supplier: SupplierReviewRow,
    parentSupplier: ParentSupplierReviewRow,
};
export const ParentSupplierReviewSubRow: React.FC<Props> = observer(({supplier, parentSupplier}) => {
    const {p} = useStores();
    const SUPPLIER_COLUMNS = getSupplierNormalizationSupplierColumns(p);
    let i = 0;

    return <TableRow className="sub-row">
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            <Stack direction="row" className="supplier-parent-chooser-wrapper">
                <ParentSupplierChooser supplier={supplier}
                                       parentSupplierChoice={getParentSupplierChoice(parentSupplier)}
                                       label="Parent Supplier"/>
            </Stack>
        </MithraTableCell>
        {p.p.showSupplierIdInSupplierNormalization &&
            <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
                {supplier.supplier.s_id}
            </MithraTableCell>
        }
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            <GoogleLink type="supplier" objs={[supplier.supplier]}>
                {supplier.supplier.s_name}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={supplier.s_total_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {supplier.supplier.s_city || supplier.supplier.s_country}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {supplier.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        {environment.isTest &&
            <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
                {'' + supplier.review_user_id}
            </MithraTableCell>
        }
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[i++]}>*/}
        {/*    {Math.round(supplier.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
