import {makeAutoObservable, runInAction} from "mobx";
import {Opportunity} from "../ChatTypes";
import {stores} from "../../../stores";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";

class ChatOpportunityController {
    fakeOpportunityDatabase: Opportunity[] = []

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    async saveOpportunity(title: string, description: string, amount: number): Promise<boolean> {
        if (!title) {
            return false;
        }
        await new Promise(resolve => setTimeout(resolve, 500));
        if(this.fakeOpportunityDatabase.find(o => o.title === title)) {
            throw new Error('Opportunity already exists, please choose a different name');
        }
        runInAction(() => {
            this.fakeOpportunityDatabase.push({
                id: this.fakeOpportunityDatabase.length + 1,
                title,
                description,
                amount,
            });
        })
        return true;
    }
}

export default new ChatOpportunityController(stores.materializedApi);
