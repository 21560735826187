import './ApprovalPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {useStores} from "../../stores";
import {MithraTable, MithraTableHeader} from "../../components/data-table/MithraTable";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    Tab,
    TableCell,
    TablePagination,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import moment from "moment";
import {Clear, Refresh, Search} from "@mui/icons-material";
import {generatePath, useNavigate} from "react-router-dom";
import {ApprovalRouteMatch, routes} from "../../routing/routes";
import {ApprovalStore} from "../../stores/ApprovalStore";
import {ApprovalRequestFilters, ApprovalStatusEnum} from "../../services/classes/AiClasses";

const HEADERS: MithraTableHeader[] = [
    {headerText: 'Sender'},
    {headerText: 'Type of change', className: 'width-15'},
    {headerText: 'Time & Date of change', className: 'width-15'},
    {headerText: 'Status', className: 'width-10'},
    {headerText: '', className: 'width-10'},
]

export const ApprovalListPage: React.FC = observer(() => {
    const {approvalStore} = useStores();
    const navigate = useNavigate();
    const approvalRequests = approvalStore.approvalListRM;

    const [selectedTab, setSelectedTab] = React.useState<'all' | ApprovalStatusEnum>('all');
    const [searchField, setSearchField] = React.useState('');
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        // TODO: Status count does not seem to react to the changes in the selectedTab. filtered call is missing!
        if (approvalStore.statusCount.result === undefined)
            approvalStore.statusCount.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const filter: ApprovalRequestFilters = {}
        if (search) filter.search = search;
        if (selectedTab !== 'all') filter.status_q = selectedTab;
        approvalStore.approvalListRM.init(filter)
    }, [search, selectedTab]);

    return <KoiFacade
        title="Approval requests list"
        simpleAction={<IconButton
            size="small"
            aria-label="refresh"
            onClick={() => {
                approvalStore.statusCount.request();
                approvalStore.approvalListRM.reloadView();
            }}
            disabled={approvalStore.approvalListRM.isLoading}
            className="ml-2">
            <Refresh/>
        </IconButton>}
        back={routes.home}
        backClass={""}
        className="approval-page">
        <div className="approval-subtitle">
            <Tabs value={selectedTab} className="approval-tabs">
                <Tab
                    value="all"
                    label={'ALL (' + (approvalStore.getCountForStatus('all') ?? 0).toString() + ')'}
                    onClick={() => setSelectedTab('all')}
                />
                {[
                    ApprovalStatusEnum.PENDING,
                    ApprovalStatusEnum.BUSY,
                    ApprovalStatusEnum.APPROVED,
                    ApprovalStatusEnum.REJECTED,
                ].map(status =>
                    <Tab
                        key={status}
                        value={status}
                        label={
                            ApprovalStore.getApprovalStatusName(status).toUpperCase()
                            + ' (' + String(approvalStore.getCountForStatus(status) ?? 0) + ')'
                        }
                        onClick={() => setSelectedTab(status)}
                    />
                )}
            </Tabs>
            <Input
                id="approval-list-search-field"
                type="text" placeholder="Search for change or user..."
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear search"
                            onClick={() => {
                                setSearch('');
                                setSearchField('');
                            }}>
                            <Clear/>
                        </IconButton>
                        <IconButton
                            aria-label="search"
                            onClick={() => {
                                setSearch(searchField);
                            }}>
                            <Search/>
                        </IconButton>
                    </InputAdornment>
                }
                value={searchField}
                onChange={e => {
                    setSearchField(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        setSearch(searchField);
                    }
                }}
            />
        </div>
        <Grid container>
            <Grid item xs={12}>
                <MithraTable
                    headers={HEADERS} loading={approvalStore.approvalListRM.isLoading} className="mithra-table"
                    children={approvalStore.approvalListRM.data?.map(a => {
                        const created = moment(a.created_timestamp);
                        const linkParams: ApprovalRouteMatch = {approvalId: String(a.id)};
                        const link = generatePath(ApprovalStore.getBaseRoute(a.request_type), linkParams);
                        return <TableRow key={a.id}>
                            <TableCell>
                                <Typography>{a.created_by.first_name + ' ' + a.created_by.last_name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{ApprovalStore.showRequestType(a.request_type)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{created.format('L LT')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Chip size="medium"
                                      label={ApprovalStore.getApprovalStatusName(a.current_status.status)}
                                      className={a.current_status.status_name.toLowerCase()}/>
                            </TableCell>
                            <TableCell>
                                <Button className="detail-button"
                                        href={link}
                                        onClick={e => {
                                            e.preventDefault();
                                            navigate(link);
                                        }}
                                        variant='outlined'>
                                    Details
                                </Button>
                            </TableCell>
                        </TableRow>;
                    })}
                    footer={<TableRow>
                        <TablePagination
                            count={approvalRequests.count}
                            rowsPerPage={approvalRequests.pageSize}
                            page={approvalRequests.page - 1}
                            onPageChange={(e, page) => approvalRequests.changePage(page + 1)}
                            onRowsPerPageChange={(event) => approvalRequests.onRowsPerPageChange(event)}
                        />
                    </TableRow>}
                />
            </Grid>
        </Grid>
    </KoiFacade>
})
