import {observer} from "mobx-react-lite";
import {Typography} from "@mui/material";
import {VerticalModal} from "./VerticalModal"
import {styled} from "@mui/material/styles";
import {FC} from "react";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(VerticalModal)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

interface ModalDeleteBCRTableProps {
    onConfirm: () => void;
    onClose: () => void;
    name: string;
}

export const ModalDeleteBCRTable: FC<ModalDeleteBCRTableProps> = observer(({onConfirm, onClose, name}) => {

    return (
        <StyledEditorModalComponent
            titleId={"bcr-delete-table-modal"}
            className="bcr-delete-table-modal"
            title="Deleting opportunity type"
            canSave={true}
            onSave={onConfirm}
            onClose={onClose}
            saveLabel="Delete"
        >
            <Typography variant={'body1'}>
                Are you sure about deleting {name}?
            </Typography>
        </StyledEditorModalComponent>
    );
});