import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {makeAutoObservable, runInAction} from "mobx";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import TaxonomyEditorStore from "./TaxonomyEditorStore";
import {ApprovalStatusEnum} from "../services/classes/AiClasses";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";


export class TaxonomyApprovalPreviewDelegate {
    approvalIsBusy = false;
    reviewNotesIsBusy = false;

    readonly categoryNotes = new Map<number, string>();
    approvalNotes = ''

    errorMessage = ''
    isConfirmModalOpen = false;

    constructor(
        private taxonomyEditorStore: TaxonomyEditorStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    get isBusy() {
        return this.approvalIsBusy || this.reviewNotesIsBusy;
    }

    get data() {
        const tree = this.taxonomy?.diff_tree;
        if (!tree) return null;
        return m_taxonomy.processApprovals(tree)
    }

    private get taxonomy() {
        return this.taxonomyManagerStore.taxonomy;
    }

    forceSetStatus(status: ApprovalStatusEnum) {
        const approvalId = this.taxonomy?.active_approval_request?.id;
        const taxonomyId = this.taxonomy?.id;
        if (approvalId && taxonomyId) {
            this.api.overrideApprovalStatus(approvalId, status).then(() =>
                this.taxonomyManagerStore.requestTaxonomy(taxonomyId)
            );
        }
    }

    forceDeleteTaxonomyApprovalRequest() {
        const approvalId = this.taxonomy?.active_approval_request?.id;
        const taxonomyId = this.taxonomy?.id;
        if (approvalId && taxonomyId) {
            this.api.deleteApproval(approvalId).then(() =>
                this.taxonomyManagerStore.requestTaxonomy(taxonomyId)
            );
        }
    }

    setApprovalNotes(s: string) {
        this.approvalNotes = s;
    }

    sendForApproval() {
        if (!this.taxonomy || this.isBusy) return;
        this.approvalIsBusy = true
        const taxonomyId = this.taxonomy.id;
        this.api.createTaxonomyApprovalRequest(this.taxonomy.id, this.approvalNotes)
            .then(() => this.taxonomyManagerStore.requestTaxonomy(taxonomyId))
            .then(() => {
                this.taxonomyEditorStore.setPage('editor');
                this.setErrorMessage('')
            })
            .catch(e => {
                console.error(e)
                this.setErrorMessage('Failed to send for approval')
            })
            .finally(() => runInAction(() => {
                this.isConfirmModalOpen = false;
                this.approvalIsBusy = false;
            }))
    }

    setErrorMessage(s: string) {
        this.errorMessage = s
    }

    setConfirmModalOpen(b: boolean) {
        this.isConfirmModalOpen = b
    }

    setNote(nodeId: number, value: string) {
        this.categoryNotes.set(nodeId, value)
    }

    private getExportedTree(node: m_taxonomy.Tree) {
        const n = m_taxonomy.exportNode(node);
        const note = this.categoryNotes.get(n.id);
        if (note !== undefined) {
            n.values.approval_note = note;
        }
        return {
            ...n,
            children: node.children?.map(c => this.getExportedTree(c)) || [],
        }
    }

    saveNotesToTaxonomy(): Promise<void> | undefined {
        // TODO: Test concurrent edits of multiple users

        if (this.isBusy) return;
        if (!this.taxonomy || this.categoryNotes.size === 0) {
            // Already in sync
            return Promise.resolve();
        }

        this.reviewNotesIsBusy = true;
        const newState = this.getExportedTree(this.taxonomy.diff_tree);

        return this.taxonomyManagerStore.onChangeTaxonomy(m_taxonomy.Operation.Review, newState)
            ?.then(() => runInAction(() => {
                this.categoryNotes.clear()
            }))
            .finally(() => runInAction(() => {
                this.reviewNotesIsBusy = false;
            }))
    }
}