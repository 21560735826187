import './BusinessCaseGeneratorModals.scss'
import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

type Props = {
    isOpen: boolean
    onClose: () => void
}
export const ModalMasterAgreementKPI: React.FC<Props> = ({isOpen, onClose}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="kpi-modal"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Contract insights
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key KPI's captured</TableCell>
                                <TableCell align="center">Number of Unique Suppliers</TableCell>
                                <TableCell align="center">Total Spend Within Selected Timelines</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Parent Suppliers without Master Agreement</TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">10.2 million</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Child Suppliers with Local Agreements</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center">5.7 million</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Master Agreements Expiring Up to 24 Months</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">6.8 million</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions style={{padding: '0 1.5em 1.5em'}}>
                <Button onClick={onClose} variant="contained">Close</Button>
            </DialogActions>
        </Dialog>
    );
}