import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {DASHBOARD_CONSTANTS} from "../../../components/dashboard/Dashboard.utils";
import {TaxonomyMapperStore} from "../../taxonomy-mapper/store/TaxonomyMapperStore";
import {useStores} from "../../../stores";
import {environment} from "../../../env";
import {DashboardRow} from "../../../components/dashboard/DashboardRow";
import {DashBoardCard} from "../../dashboard/DashBoardCard";
import {TaxonomyMapperIcon} from "../../../components/icons/TaxonomyMapperIcon";
import {routes} from "../../../routing/routes";


export const Merge2MergeDashboard: React.FC = observer(() => {
    const stores = useStores();
    const {
        p,
        bagStore,
        cmpStore,
        categorizationReviewStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
    } = stores;
    const bagId = bagStore.bagId;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.statistics?.standalone_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.statistics?.standalone_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.statistics?.standalone_spend;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;

    const unm1N = ((stores as any).sub1_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm1O = ((stores as any).sub1_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm2N = ((stores as any).sub2_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm2O = ((stores as any).sub2_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm3N = ((stores as any).sub3_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;
    const unm3O = ((stores as any).sub3_old_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;

    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore.init(masterBagId);
            baseSupplierNormalizationStore.init(baseBagId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>Merge2MergeDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard merge-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Nutreco"
                className="suppliers-row"
            >

                {/*<DashBoardCard*/}
                {/*    cardKey="improve-cats"*/}
                {/*    avatar={<ClassificationIcon/>}*/}
                {/*    title="Improve Categorization"*/}
                {/*    value={drawOpportunityValue(unclassifiedEgoSpend)}*/}
                {/*    valueColor={unclassifiedEgoSpend*/}
                {/*        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="uncategorized"*/}
                {/*    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}

                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (old holdings)"
                    value={unm1O === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm1O}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_baseline_1, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (new proposal)"
                    value={unm1N === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm1N}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_target_1, {id: bagId})}
                    badge="Ai"
                />

            </DashboardRow>
            <DashboardRow
                title="Energy"
                className="categories-row"
            >

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-builder"*/}
                {/*    avatar={<ClassificationHierarchyIcon/>}*/}
                {/*    title="Taxonomy Viewer (baseline)"*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_viewer_baseline, {id: bagId})}*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-builder"*/}
                {/*    avatar={<ClassificationHierarchyIcon/>}*/}
                {/*    title="Taxonomy Builder"*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_builder_target, {id: bagId})}*/}
                {/*/>*/}

                {/*<DashBoardCard*/}
                {/*    cardKey="improve-cats"*/}
                {/*    avatar={<ClassificationIcon/>}*/}
                {/*    title="Improve Categorization"*/}
                {/*    value={drawOpportunityValue(unclassifiedEgoSpend)}*/}
                {/*    valueColor={unclassifiedEgoSpend*/}
                {/*        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="uncategorized"*/}
                {/*    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}

                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (old holdings)"
                    value={unm2O === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm2O}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_baseline_2, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (new proposal)"
                    value={unm2N === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm2N}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_target_2, {id: bagId})}
                    badge="Ai"
                />

            </DashboardRow>
            <DashboardRow
                title="Eriks"
                className="spend-row"
            >

                {/*<DashBoardCard*/}
                {/*    cardKey="improve-cats"*/}
                {/*    avatar={<ClassificationIcon/>}*/}
                {/*    title="Improve Categorization"*/}
                {/*    value={drawOpportunityValue(unclassifiedEgoSpend)}*/}
                {/*    valueColor={unclassifiedEgoSpend*/}
                {/*        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="uncategorized"*/}
                {/*    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*/>*/}

                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (old holdings)"
                    value={unm3O === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm3O}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_baseline_3, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper (new proposal)"
                    value={unm3N === undefined
                        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unm3N}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_2_merge_taxonomy_mapper_target_3, {id: bagId})}
                    badge="Ai"
                />

                {/*This two cards have been moved to the master dashboard*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="adv-looker-dashboard"*/}
                {/*    avatar={<QueryStats fontSize="large"/>}*/}
                {/*    title="Advanced Dashboard (Subsidairy)"*/}
                {/*    // value={drawOpportunityValue(ppvTotal)}*/}
                {/*    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="Preview"*/}
                {/*    link={generatePath(routes.merge_x_master_looker_subsidiary, {id: bagId})}*/}
                {/*    // badge="preview"*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="adv-looker-dashboard"*/}
                {/*    avatar={<QueryStats fontSize="large"/>}*/}
                {/*    title="Advanced Dashboard (Combined)"*/}
                {/*    // value={drawOpportunityValue(ppvTotal)}*/}
                {/*    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="Preview"*/}
                {/*    link={generatePath(routes.merge_x_master_looker_all, {id: bagId})}*/}
                {/*    // badge="preview"*/}
                {/*/>*/}

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Compare (UN)"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_compare_un, {id: bagId})}*/}
                {/*/>*/}
                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Compare"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    link={generatePath(routes.merge_x_master_taxonomy_compare_baseline, {id: bagId})}*/}
                {/*/>*/}


            </DashboardRow>
        </Grid>
    </>
});
