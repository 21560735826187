import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {PpvTableMainRow} from "./PpvTableMainRow";
import {getPpvTableColumns} from "./PpvColumns";

export const PpvExpandableTable: React.FC = observer(() => {
    const {p, ppvControllerStore} = useStores()
    const dataController = ppvControllerStore.ppvPageController.ppvPageDataController;
    const COLUMNS = getPpvTableColumns(p);

    const isLoading = dataController.isLoadingParts;


    const tableClass = 'mithra-table x-design ppv-table x-design-narrow' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="ppv-table-body">
                {dataController.ppvGroupPages.data ? <>
                        {dataController.ppvGroupPages.data.map(row => <React.Fragment key={row.data.p_description}>
                            <PpvTableMainRow groupedRow={row}/>
                        </React.Fragment>)}
                        {dataController.ppvGroupPages.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length}
                        count={dataController.ppvGroupPages.count}
                        rowsPerPage={dataController.ppvGroupPages.pageSize}
                        page={dataController.ppvGroupPages.page - 1}
                        onPageChange={(e, page) => dataController.ppvGroupPages.changePage(page + 1)}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
