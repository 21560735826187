import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import {useSitStores} from "../../../stores";
import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";
import {lpad} from "../../../utils/js-utils";
import {SIT_TEST} from "../../SitApit";

function init(echart: EChartContextType, root: HTMLElement, data: any[], height: number): EChartsType {
    const chart = echart.init(root, 'mithra', {
        renderer: 'svg',
        height,
    });

    var procurementSavingsChartOption = {
        tooltip: {
            trigger: 'axis'
        },
        toolbox: {
            show: true,
            feature: {
                // dataView: {show: true, readOnly: false},
                // https://echarts.apache.org/en/option.html#toolbox.feature.restore
                // restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        calculable: true,
        legend: {
            data: ['Target', 'Actual', 'Run rate'],
            left: '10%',
            padding: [5, 10, 5, 10],
            textStyle: {
                fontFamily: 'roboto',
                overFlow: 'break'
            }
        },
        color: [
            '#091344',
            '#ff3435',
            '#430944',
            '#0262f2',
            '#08a57a',
            '#a4d8c2',
            '#f3d999',
            '#d3758f',
            '#dcc392',
            '#2e4783',
            '#82b6e9',
            '#ff6347',
            '#a092f1',
            '#0a915d',
            '#eaf889',
            '#6699FF',
            '#ff6666',
            '#3cb371',
            '#d5b158',
            '#38b6b6'
        ],
        dataset: data,  // Pass the data here
        xAxis: {
            type: 'time'
        },
        yAxis: {},
        series: [
            {
                name: 'Target',
                type: 'bar',
                encode: {
                    x: 'Saving month',
                    y: 'Acc Target'
                },
                datasetId: 'Target accumulative aggregated by month',
                stack: 'saving',
                color: '#ff3435',
                emphasis: {
                    focus: 'series'
                },
                markLine: {
                    data: [
                        {
                            type: 'max',
                            name: 'Target in year',
                            label: {show: true, formatter: '{b}', position: 'end'}
                        }
                    ]
                }
            },
            {
                name: 'Actual',
                type: 'bar',
                encode: {
                    x: 'Saving month',
                    y: 'Acc Saving'
                },
                datasetId: 'Actual procurement accumulative aggregated savings by month',
                stack: 'target',
                color: '#091344',
                emphasis: {
                    focus: 'series'
                }
            },
            {
                name: 'Run rate',
                type: 'line',
                smooth: false,
                encode: {
                    x: 'Saving month',
                    y: 'Acc Saving'
                },
                lineStyle: {
                    color: '#430944',
                    width: 2,
                    type: 'dashed'
                },
                emphasis: {
                    focus: 'series'
                },
                datasetId: 'Procurement accumulative aggregated savings by month',
                color: '#430944'
            }
        ]
    };

    chart.setOption(procurementSavingsChartOption);

    return chart;
}

type Props = {
    actualData: { month: Date, value: number }[] | null
    targetData: { month: Date, value: number }[] | null
    height: number
}
export const TargetVsActualDoubleBar: React.FC<Props> = observer(({actualData, targetData, height}) => {
    const {fbStore} = useSitStores();

    if (!actualData || !targetData) {
        return <></>;
    }

    const data = [
        {
            id: 'Procurement accumulative aggregated savings by month',
            dimensions: [
                {name: 'Saving month', type: 'time'},
                {name: 'Acc Saving', type: 'number'}
            ],
            source: actualData.map(d => ([
                `${d.month.getFullYear()}-${lpad(String(d.month.getMonth() + 1), '0', 2)}`,
                d.value,
            ]))
        },
        {
            id: 'Target accumulative aggregated by month',
            dimensions: [
                {name: 'Saving month', type: 'time'},
                {name: 'Acc Target', type: 'number'}
            ],
            source: targetData.map(d => ([
                `${d.month.getFullYear()}-${lpad(String(d.month.getMonth() + 1), '0', 2)}`,
                d.value,
            ]))
        },
        {
            id: 'Actual procurement accumulative aggregated savings by month',
            dimensions: [
                {name: 'Saving month', type: 'time'},
                {name: 'Acc Saving', type: 'number'}
            ],
            source: actualData.map(d => ([
                `${d.month.getFullYear()}-${lpad(String(d.month.getMonth() + 1), '0', 2)}`,
                d.month < fbStore.month ? d.value : 0,
            ]))
        }
    ]
    return <Grid container>
        <Grid item xs={12}>
            {SIT_TEST && '<TargetVsActualDoubleBar/>'}
            {/*<p>*/}
            {/*    contributionPerMonth={JSON.stringify(actualData)}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    data={JSON.stringify(data)}*/}
            {/*</p>*/}
            <MithraEchart
                // alwaysRerender={() => {
                // }}
                initChart={(echart, root) => init(echart, root, data, height)}
                data={data}
                autoUpdateDataset
            />
        </Grid>
    </Grid>
})