import {GoldenRecordStore} from "../../../stores/GoldenRecordStore";
import {makeAutoObservable} from "mobx";
import {
    GoldenRecordFields,
    GoldenRecordGroupedData,
    GoldenRecordGroupedState
} from "../classes/GoldenRecordGroupedState";

export class GoldenRecordEditController {
    isEditModalOpen = false;

    subject: GoldenRecordGroupedState | undefined;
    prevData: GoldenRecordGroupedData | undefined;

    constructor(private goldenRecordStore: GoldenRecordStore) {
        makeAutoObservable(this);
    }

    setIsEditModalOpen(isOpen: boolean) {
        this.isEditModalOpen = isOpen;
    }

    setField<F extends GoldenRecordFields>(field: F, value: GoldenRecordGroupedData[F]) {
        if (this.subject) {
            this.subject.data[field] = value;
        }
    }

    onOpenEdit(subject: GoldenRecordGroupedState) {
        this.subject = subject;
        this.isEditModalOpen = true;
        this.prevData = {...subject.data}
    }

    onSave() {
        // TODO: Implement an API call
        this.isEditModalOpen = false;
    }

    onCancel() {
        if (this.subject && this.prevData) {
            this.subject.data = this.prevData;
        }
        this.isEditModalOpen = false;
    }
}
