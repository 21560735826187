import {Autocomplete, Box, List, ListItem, ListItemText, TextField, Typography} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {CategoryChip} from "../../../../components/category-chip/CategoryChip";
import {ApprovalHierarchyNode, TaxonomyApprovalDelegate} from "../../../../stores/approval/TaxonomyApprovalDelegate";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {Category} from "../../../../stores/TaxonomyEditorStore";

type Props = {
    node: ApprovalHierarchyNode
}
export const MoveCategoryModal: React.FC<Props> = observer(({node}) => {
    const {approvalStore} = useStores();

    const [newLocation, setNewLocation] = React.useState<null | Category>(null);
    const [locationError, setLocationError] = React.useState<string>('');

    const onSave = () => {
        if (!newLocation) {
            setLocationError('Please select a new location');
            return;
        }
        const err = approvalStore.taxonomyApproval.onSaveMoveNode(node, newLocation);
        if (err) {
            setLocationError(err);
        } else {
            setLocationError('');
        }
    }

    const changeMove = node.data.values.changes?.find(c => c.type === 'moved') as undefined | m_taxonomy.Change & {
        type: 'moved'
    };
    const locationHelperText = changeMove ? `Original parent: ${changeMove.original_location.slice(0, -1).join(' - ')}` : '';

    const labels = TaxonomyApprovalDelegate.getLocation(node);

    const saveLabel = newLocation ? `Move to L${newLocation.level + 1}` : 'Move';

    return <EditorModalComponent
        className="taxonomy-modal-component move-category-modal"
        titleId="move-category-modal-title"
        title="Move category"
        canSave={!locationError}
        onSave={onSave}
        onClose={() => approvalStore.taxonomyApproval.closeMoveModal()}
        saveLabel={saveLabel}
    >
        <section className="modal-introduction">
            Selected category:
            <List>
                <ListItem>
                    {/*<ListItemIcon>*/}
                    {/*    <ChevronRight/>*/}
                    {/*</ListItemIcon>*/}
                    <ListItemText
                        primary={<CategoryChip labels={labels}/>}
                        secondary={node.data.values.description || undefined}
                    />
                </ListItem>
            </List>
        </section>

        <Autocomplete
            // id={id}
            options={approvalStore.taxonomyApproval.moveDestinations}
            // filterOptions={filterOptions}
            autoHighlight
            getOptionLabel={(option) =>
                option.level === 0 ? 'Move to L1' : option.values.join(' > ')
            }
            isOptionEqualToValue={(option, value) => option.node_id === value.node_id}
            renderOption={(props, option) =>
                <li {...props}>
                    <Box component="div">
                        {option.level === 0
                            ? 'Move to L1'
                            : <PartCategorizationComponent cats={option.values}/>
                        }
                    </Box>
                </li>
            }
            onChange={(_, newValue: any | null) => {
                if (newValue) {
                    const category = newValue as Category
                    setNewLocation(category)
                    setLocationError('')
                } else {
                    setNewLocation(null)
                }
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Select the new parent of this category"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        tabIndex: 1,
                        // autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }
        />
        {locationHelperText && <Typography variant="caption">{locationHelperText}</Typography>}
        {locationError && <Typography color="error">{locationError}</Typography>}
    </EditorModalComponent>
})
