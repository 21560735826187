import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {MatSupplierFilter_V2} from "../services/classes/MatReviewClasses";
import {MatSupplierCategoryConcentrationStatistics} from "../services/classes/MaterializedClasses";
import {PageResponseManager} from "./managers/PageResponseManager";
import {UNCATEGORIZED_LABEL} from "../constants";
import {TaxonomyTableVizDelegate} from "./TaxonomyTableVizDelegate";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {taxonomy_health_check} from "../services/classes/TaxonomyHealthCheckClasses";
import {ApiSuggestionTreeResponse} from "../services/ApiHelpers";
import ExtendedD3HierarchyNode = taxonomy_health_check.ExtendedD3HierarchyNode;
import ReviewStatusType = taxonomy_health_check.ReviewStatusType;

const hardcodedPaginatedTableRows = 30

export default class TaxonomyHealthCheckStore {
    taxonomyHealthCheckTaxonomyId: number | undefined = undefined
    taxonomyHealthCheckApiResponse: taxonomy_health_check.HealthCheckApiResponse | undefined = undefined

    selectedCategory: ExtendedD3HierarchyNode | undefined = undefined;

    taxonomyTableController: TaxonomyTableVizDelegate = new TaxonomyTableVizDelegate()
    HEALTH_CHECK_COLUMNS: ColSpec[] = [
        // TODO: For the DEMO:
        // { cls: 'col-s_name', txt: 'Category' },
        // { cls: 'col-s_spend', txt: 'Total spend', width: 100 },
        // { cls: 'col-s_city', txt: 'Supplier', width: 100 },
        // { cls: 'col-s_l1s', txt: 'Transactions', width: 100 },
        // { cls: 'col-s_context_2', txt: 'Health Status', width: 100 },
        // { cls: 'col-s_l1s', txt: 'AI Suggestion', width: 400 },
        // { cls: 'col-s_context_3', txt: '', width: 100 },

        { cls: 'col-label', txt: 'Category' },
        { cls: 'col-spend', txt: 'Total spend', width: '8%' },
        { cls: 'col-p_count', txt: 'Suppliers', width: '8%' },
        { cls: 'col-s_count', txt: 'Transactions', width: '8%' },
        { cls: 'col-control_buttons', txt: 'Health Status', width: '10%' },
        { cls: 'col-control_buttons', txt: 'AI Suggestion', width: '25%' },
        { cls: 'col-control_buttons', txt: '', width: '10%' },
    ]
    isDeleteCategoryModalOpen: boolean = false;
    isMoveCategoryModalOpen: boolean = false;
    isMergeCategoryModalOpen: boolean = false;
    isRenameCategoryModalOpen: boolean = false;
    isModifyCategoryModalOpen: boolean = false;

    /**
     * TODO: For now we re-use an spend concentration endpoint... this should be changed to a new endpoint
     */
    readonly _spendConcentrationSupplierRequestManager = new PageResponseManager<MatSupplierFilter_V2, MatSupplierCategoryConcentrationStatistics>(
        hardcodedPaginatedTableRows,
        (page, filter) => this.api.listPaginatedSpendConcentrationSupplier(filter, page, hardcodedPaginatedTableRows),
    );

    applyButtonText = "Apply"
    disableApply = false

    setApplyButtonText(applyButtonText: string) {
        this.applyButtonText = applyButtonText
    }

    setDisableApply(disableApply: boolean) {
        this.disableApply = disableApply
    }

    // noinspection JSUnusedLocalSymbols
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async getTaxonomyHealthCheck(taxonomyId: number) {
        // const data = hc_dummy as any as taxonomy_health_check.HealthCheckApiResponse;
        // this.taxonomyHealthCheckTaxonomyId = taxonomyHealthCheckTaxonomyId;

        // this.setHealthCheckTaxonomyApiResponse(data)

        return await this.api.getTaxonomyHealthChecks(taxonomyId).then((datas) => {
            if (datas.length !== 1) {
                throw new Error(`Expected exactly one taxonomy health check, instead got ${datas.length}`)
            }
            const data = datas[0]
            console.log('Got taxonomy health checks')
            console.log(data)
            console.log(data.suggestion_state)
            // this.setTaxonomySuggestionState(data.suggestion_state)
            this.setHealthCheckTaxonomyApiResponse(data)

            console.log("setted");
            console.log(this.taxonomyHealthCheckApiResponse)

            return data
        }).catch((error) => {
            console.error('Error while getting taxonomy health checks');
            console.error(error);
            throw error;
        })
    }

    async putTaxonomyHealthCheck(data: taxonomy_health_check.HealthCheckApiResponse) {
        if(!this.taxonomyHealthCheckApiResponse?.id) return;
        const resp = await this.api.putTaxonomyHealthCheck(this.taxonomyHealthCheckApiResponse.id, data);
        this.setHealthCheckTaxonomyApiResponse(resp.data)
    }

    async applyTaxonomyHealthCheck(taxonomyHealthCheckId: number) {
        return await this.api.applyTaxonomyHealthChecks(taxonomyHealthCheckId).then(() => {
            console.log("changes applied!")
            return
        }).catch((error) => {
            console.error('Error while getting taxonomy health checks');
            console.error(error);
            throw error;
        })
    }

    get hasHealthCheck() {
        return this.taxonomyHealthCheckApiResponse !== undefined && this.taxonomyTableController.data !== undefined;
    }

    get suggestionKpis(): {
        numberOfLabelFormatting: number,
        numberOfPrefixOverlaps: number, // TODO: Update kpi names to be aligned with health check design
        numberOfCategoryOverlap: number
    } {
        let numberOfPrefixOverlaps = 0;
        let numberOfLabelFormatting = 0;
        let numberOfCategoryOverlap = 0;
        const iterateSuggestions = (suggestion: ApiSuggestionTreeResponse<taxonomy_health_check.APIValues>) => {
            if (suggestion.values.review_status?.label_formatting) {
                numberOfLabelFormatting += 1;
            }
            if (suggestion.values.review_status?.prefix_overlap) {
                numberOfPrefixOverlaps += 1;
            }
            if (suggestion.values.review_status?.category_overlap) {
                numberOfCategoryOverlap += 1;
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        if (this.taxonomyHealthCheckApiResponse?.health_check_state) {
            iterateSuggestions(this.taxonomyHealthCheckApiResponse.health_check_state);
        }
        return {numberOfPrefixOverlaps, numberOfLabelFormatting, numberOfCategoryOverlap};
    }


    async updateTaxonomySuggestionValues() {
        if (!this.taxonomyHealthCheckTaxonomyId) {
            console.error('No taxonomy health check taxonomy id set')
            return
        }
        return await this.api.updateTaxonomySuggestionValues(this.taxonomyHealthCheckTaxonomyId).then((data) => {
            console.log('Updated taxonomy suggestions')
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    /**
     * @param taxonomyApiResponse taxonomy_health_check.HealthCheckApiResponse
     */
    setHealthCheckTaxonomyApiResponse(taxonomyApiResponse: any) {
        this.taxonomyTableController.data = TaxonomyTableVizDelegate.initNodes(taxonomyApiResponse.health_check_state);
        this.taxonomyHealthCheckApiResponse = taxonomyApiResponse; // We probably do not need this...
    }

    resetHealthCheck() {
        this.taxonomyTableController.data = undefined;
        this.taxonomyHealthCheckApiResponse = undefined;
    }

    /**
     * Applies the filter, going down the tree
     */
    //FIXME: Consider refactoring this function to also show the filtered node as collapsed instead of hiding them (Consider using TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true) or CloseAll(node))
    applyFilter(node: ExtendedD3HierarchyNode, hideFilter: undefined | ((d: ExtendedD3HierarchyNode) => boolean), depth = 0, hideUncat?: boolean,): boolean {
        let hideThis = false;
        let canOpen = false;
        if (hideUncat) {
            hideThis = hideThis || (node.data.viz.label === UNCATEGORIZED_LABEL);
        }

        //Hide everything else
        if (hideFilter && !hideThis && depth > 0) {
            hideThis = hideThis || !hideFilter(node)
        }

        // Hide everything else
        node.data.viz.filtered = false;
        if (node._children) {
            let hasUnhiddenChildren = false;
            node._children.forEach(c => {
                if (!this.applyFilter(c, hideFilter, depth + 1, hideUncat)) {
                    hasUnhiddenChildren = true;
                    hideThis = false;
                } else {
                    if (hideThis) {
                        this.setHidden(node, true, depth + 1)
                        node.data.viz.filtered = true;
                    }
                }
            })
            if (hasUnhiddenChildren) {
                canOpen = true;
                node.data.viz.filtered = false;
            }
        }

        node.data.viz.canOpen = canOpen;
        return hideThis;
    }

    setHidden(d: ExtendedD3HierarchyNode, hidden: boolean, depth: number) {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }
    }

    setCategoryToModify(d: ExtendedD3HierarchyNode) {
        this.selectedCategory = d;
    }

    toggleDeleteCategoryModal(b: boolean) {
        this.isDeleteCategoryModalOpen = b;
    }

    toggleMoveCategoryModal(b: boolean) {
        this.isMoveCategoryModalOpen = b;
    }

    toggleMergeCategoryModal(b: boolean) {
        this.isMergeCategoryModalOpen = b;
    }

    toggleRenameCategoryModal(b: boolean) {
        this.isRenameCategoryModalOpen = b;
    }

    toggleModifyCategoryModal(b: boolean) {
        this.isModifyCategoryModalOpen = b;
    }

    createNewTaxonomyHealthCheck(taxonomyId: number) {
        return this.api.createNewTaxonomyHealthCheck(taxonomyId)
    }

    handleAccept(node: taxonomy_health_check.ExtendedD3HierarchyNode, reviewStatus: ReviewStatusType) {
        if (!node.data.apiValues.review_status || !this.taxonomyHealthCheckApiResponse) {
            console.warn('No review status found for node')
            return;
        }

        // We first build this function only the "rename case"

        // Apply the new label to the viz datatype
        node.data.viz.label = node.data.apiValues.review_status.newLabel;

        if (!reviewStatus.category_overlap) {
            reviewStatus.prefix_overlap = false
            reviewStatus.label_formatting = false
            reviewStatus.category_overlap = false
        }

        reviewStatus.accepted = true
        reviewStatus.ai_suggestion = ""

        if (this.taxonomyTableController.data) {
            const health_check_state = TaxonomyHealthCheckStore.exportTree(this.taxonomyTableController.data);
            const apiData: taxonomy_health_check.HealthCheckApiResponse = {
                id: this.taxonomyHealthCheckApiResponse.id,
                taxonomy: this.taxonomyHealthCheckApiResponse.taxonomy,
                create_date: this.taxonomyHealthCheckApiResponse.create_date,
                last_update: this.taxonomyHealthCheckApiResponse.last_update,
                health_check_state,
            }
            this.putTaxonomyHealthCheck(apiData).then()
        }
    }

    handleReject(node: taxonomy_health_check.ExtendedD3HierarchyNode, reviewStatus: ReviewStatusType) {
        if (!node.data.apiValues.review_status || !this.taxonomyHealthCheckApiResponse) {
            console.warn('No review status found for node')
            return;
        }

        console.log("rejected!")

        // We first build this function only the "rename case"

        // Reject the suggestion
        if (!reviewStatus.category_overlap) {
            reviewStatus.prefix_overlap = false
            reviewStatus.label_formatting = false
            reviewStatus.category_overlap = false
        }

        reviewStatus.rejected = true
        reviewStatus.ai_suggestion = ""

        if (this.taxonomyTableController.data) {
            const health_check_state = TaxonomyHealthCheckStore.exportTree(this.taxonomyTableController.data);
            const apiData: taxonomy_health_check.HealthCheckApiResponse = {
                id: this.taxonomyHealthCheckApiResponse.id,
                taxonomy: this.taxonomyHealthCheckApiResponse.taxonomy,
                create_date: this.taxonomyHealthCheckApiResponse.create_date,
                last_update: this.taxonomyHealthCheckApiResponse.last_update,
                health_check_state,
            }
            this.putTaxonomyHealthCheck(apiData).then()
        }
    }

    /**
     * The FE internal representation of the tree is converted to the JSON that needs to be send to the BE
     * @param node
     * @private
     */
    private static exportTree(node: ExtendedD3HierarchyNode): ApiSuggestionTreeResponse<taxonomy_health_check.APIValues> {
        return {
            id: node.data.id,
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => TaxonomyHealthCheckStore.exportTree(c)),
            values: node.data.apiValues,
            sources: node.data.sources,
        }
    }
}
