import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Alert, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {OpportunityTableMainRow} from "./OpportunityTableMainRow";
import {getOpportunityTableColumns} from "./OpportunityColumns";
import {OpportunityStatusEnum, PpvOpportunity} from "../../../services/classes/OpportunityClasses";
import Button from "@mui/material/Button";
import {OPPORTUNITY_STATUS_VISUAL} from "../utils/OpportunityStatusVisual";
import {generatePath, useNavigate} from "react-router-dom";
import {routes} from "../../../routing/routes";

export const OpportunityTable: React.FC = observer(() => {
    const {p, opportunityStoreBis, bagStore} = useStores()
    const navigate = useNavigate();
    const COLUMNS = getOpportunityTableColumns(p);

    const isLoading = opportunityStoreBis.isLoadingParts;
    const data: PpvOpportunity[] | undefined = opportunityStoreBis.opportunityPages.data;

    if (data?.length === 0) {
        return <Alert
            severity="warning"
            action={<Button
                color="inherit"
                size="small"
                onClick={() => navigate(generatePath(routes.job_koi_ppv, {id: bagStore.bagId}))}>
                Back
            </Button>}>
            No opportunities created yet, go to the Purchase Price Variance and put
            something {OPPORTUNITY_STATUS_VISUAL[OpportunityStatusEnum.IN_REVIEW].label}.
        </Alert>
    }

    const tableClass = 'mithra-table x-design opportunity-table x-design-narrow' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="opportunity-table-body">
                {data
                    ? data.map(row => <OpportunityTableMainRow key={row.id} opportunityRow={row}/>)
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length}
                        count={opportunityStoreBis.opportunityPages.count}
                        rowsPerPage={opportunityStoreBis.opportunityPages.pageSize}
                        page={opportunityStoreBis.opportunityPages.page - 1}
                        onPageChange={(e, page) => opportunityStoreBis.opportunityPages.changePage(page + 1)}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
