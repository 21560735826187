import {makeAutoObservable, reaction} from "mobx";
import {stores} from "../../../../stores";
import MithraMaterializedApi from "../../../../services/MithraMaterializedApi";
import {AxoisRequestManager} from "../../../../stores/managers/RequestManager";
import {
    PpvOpportunity,
    PpvStatsPerPart,
    PpvStatsPerQuarter,
    PpvStatsPerSupplier
} from "../../../../services/classes/OpportunityClasses";
import {from} from "rxjs";
import {
    PpvItemListFilter,
    PpvLevelStatisticsFilter
} from "../../../../jobs/koi/purchase-price-variance/classes/PpvApiClasses";
import {environment} from "../../../../env";
import {HashedLoadingDataWrapper} from "../../../../utils/LoadingDataWrapper";
import {BarDataPoint} from "../../../../components/visualization/bar-chart/BarChart2";

class PpvOpportunityController {

    step = environment.isTest ? 1 : 0;
    userFilter: Omit<PpvLevelStatisticsFilter, 'databag'> = {
        businessUnitId: undefined,
        quarter_date__gte: undefined,
        quarter_date__lte: undefined,
    }

    ppvOpportunityRequest = new AxoisRequestManager<{ opportunityId: number }, PpvOpportunity>(
        ({opportunityId}) => from(this.mithraApi.retrievePpvOpportunity(opportunityId)),
    );
    ppvStatsPerSuppliers = new AxoisRequestManager<{ filter: PpvItemListFilter }, PpvStatsPerSupplier[]>(
        ({filter}) => from(this.mithraApi.retrievePpvStatsPerSupplier(filter)),
    );
    ppvStatsPerPart = new AxoisRequestManager<{ filter: PpvItemListFilter }, PpvStatsPerPart[]>(
        ({filter}) => from(this.mithraApi.retrievePpvStatsPerPart(filter)),
    );
    ppvStatsPerQuarter = new AxoisRequestManager<{ filter: PpvItemListFilter }, PpvStatsPerQuarter[]>(
        ({filter}) => from(this.mithraApi.retrievePpvStatsPerQuarter(filter)),
    );

    constructor(
        public readonly mithraApi: MithraMaterializedApi,
    ) {
        makeAutoObservable(this);
        reaction(() => ([this.oppQueryFilter]), ([filter]) => {
            this.ppvStatsPerSuppliers.request({filter})
            this.ppvStatsPerPart.request({filter})
            this.ppvStatsPerQuarter.request({filter})
        })
    }

    setStep(step: number) {
        this.step = step;
    }

    get ppvOpp() {
        return this.ppvOpportunityRequest.result;
    }

    get supplierStats() {
        return this.ppvStatsPerSuppliers.result
    }

    get spendOverTimeData(): HashedLoadingDataWrapper<any> {
        const hashedLoadingDataWrapper = new HashedLoadingDataWrapper(this.ppvStatsPerQuarter.result?.map(d => ({
            group: 'PPV',
            date: new Date(d.quarter_date),
            spend: d.total_spend,
        })) || []);
        hashedLoadingDataWrapper.updateHash('' + new Date())
        return hashedLoadingDataWrapper
    }

    get spendDistributionData(): BarDataPoint[] {
        return this.ppvStatsPerPart.result?.map(d => ({
            value: Number(d.total_spend),
            category: `${d.s_name} - ${d.p_name}`,
        })) || [];
    }

    get oppQueryFilter(): PpvItemListFilter {
        return {
            databag: this.ppvOpp?.databag || 0,
            business_unit: this.userFilter.businessUnitId,
            p_description: this.ppvOpp?.ppv_group_key || '',
            quarter_date__gte: this.userFilter.quarter_date__gte,
            quarter_date__lte: this.userFilter.quarter_date__lte,
            no_page: true,
            level: 0,
            l1: '', l2: '', l3: '', l4: '', l5: '', l6: '', l7: '', l8: '',
        }
    }
}

export const ppvOpportunityController = new PpvOpportunityController(stores.materializedApi);
