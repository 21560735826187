import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {SitUserProfile} from "../ApiTypes";
import {PageResponse} from "../../services/ApiTypes";
import {initialListFilters, StringArrayFilter} from "../../services/ApiHelpers";
import {makeAutoObservable} from "mobx";

export class SitUserStore {
    readonly userListRM = new AxoisRequestManager<{ filters: string[][] }, PageResponse<SitUserProfile>>(
        ({filters}) => from(this.api.listUsers(filters)));
    userListFilter: StringArrayFilter = new StringArrayFilter(this.userListRM, initialListFilters());

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
    }
}