import './TaxApprovalTable.scss'
import {Chip, IconButton, Table, TableBody, TableContainer, TableRow, TextField} from "@mui/material";
import {ColSpec, MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import React from "react";
import {Add, ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {ApprovalChangeChip} from "../component/ApprovalChangeChip";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {useStores} from "../../../../stores";
import {observer} from "mobx-react-lite";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";
import {AuthorChip} from "../component/AuthorChip";

export const TAX_APP_PRE_COLUMNS: ColSpec[] = [
    {txt: '', cls: 'open-close'},
    {txt: 'Category', cls: 'narrow-button'},
    {txt: 'Authors', cls: ''},
    // {txt: '', cls: ''}, // Link to the editor
    {txt: 'Changes', cls: ''},
    {txt: 'Category notes', cls: ''},
]

type TableRowProps = {
    data: m_taxonomy.TaxApprovalTree
    level: number
    open: boolean
    setOpen: (open: boolean) => void
}
export const TaxApprovalPreviewTableRow: React.FC<TableRowProps> = observer(({data, level, open, setOpen}) => {
    const {taxonomyEditorStore} = useStores();
    const isOpenAble = data.values.isOpenAble;
    const nChanges = data.values.descendantChangeMap;
    const changes = data.values.changes || [];
    let i = 0;
    const clickProps = isOpenAble ? {onClick: () => setOpen(!open), className: "clickable"} : {};
    return <TableRow className="">
        {/*<MithraTableCell c={TA_COLUMNS[i++]}>*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]} {...clickProps}>
            {isOpenAble &&
                <IconButton aria-label="expand row" size="small" tabIndex={-1}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            }
        </MithraTableCell>
        {/*<MithraTableCell c={TA_COLUMNS[i++]} {...clickProps}>*/}
        {/*    L{level}*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}  {...clickProps}>
            <span style={{marginLeft: `${(level - 1) * 1.5}em`}}>
                {level > 1 && '└─'}
                {data.label}
            </span>
        </MithraTableCell>
        {/*<MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}>*/}
        {/*    {!data.removed &&*/}
        {/*        <IconButton*/}
        {/*            tabIndex={-1}*/}
        {/*            onClick={() => taxonomyEditorStore.navigateToFocus(data.id)}>*/}
        {/*            <NavigateNext/>*/}
        {/*            <span style={{fontSize: '12px'}}>(WIP)</span>*/}
        {/*        </IconButton>*/}
        {/*    }*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}>
            {data.values.authors?.map(authorId =>
                <AuthorChip key={authorId} authorId={authorId}/>
            )}
        </MithraTableCell>
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}>
            {changes.map((c, i) =>
                <ApprovalChangeChip
                    key={`${c.type}_${i}`}
                    change={c}
                    node={data}
                />
            )}
            {!open && nChanges.size > 0 &&
                <Chip
                    icon={<Add/>}
                    label={Array.from(nChanges.entries()).map(e => `${e[1]} ${e[0]}`).join(', ')}
                    color="default"
                    variant="outlined"
                    size="small"
                />
            }
        </MithraTableCell>
        {/*<MithraTableCell c={TA_COLUMNS[i++]} {...clickProps}>*/}
        {/*    {data.values.description}*/}
        {/*    <TextField*/}
        {/*        id={`taxonomy-approval-notes-node-${data.id}`}*/}
        {/*        fullWidth*/}
        {/*        multiline*/}
        {/*        placeholder="Add optional notes for the approver"*/}
        {/*        variant="outlined"*/}
        {/*        value={data.values.description}*/}
        {/*        // onChange={e => taxonomyEditorStore.setMergeCategoryName(e.target.value)}*/}
        {/*        // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}*/}
        {/*    />*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={TAX_APP_PRE_COLUMNS[i++]}>
            {changes &&
                <TextField
                    id={`taxonomy-approval-notes-node-${data.id}`}
                    className="category-notes-field"
                    fullWidth
                    placeholder="Add optional notes for the approver"
                    variant="standard"

                    value={taxonomyEditorStore.approval.categoryNotes.get(data.id) ?? (data.values.approval_note || '')}
                    onChange={e => taxonomyEditorStore.approval.setNote(data.id, e.target.value)}
                    // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
                />
            }
        </MithraTableCell>
    </TableRow>;
})

export const TaxApprovalPreviewTableRowComponent: React.FC<{
    data: m_taxonomy.TaxApprovalTree
    level: number
}> = ({data, level}) => {
    const [open, setOpen] = React.useState(!data.removed && data.children.length > 0);
    if (!data.values.show) {
        return <></>
    }
    return <>
        <TaxApprovalPreviewTableRow data={data} level={level} open={open} setOpen={setOpen}/>
        {open && data.children.map(n =>
            // Recursive call
            <TaxApprovalPreviewTableRowComponent key={n.id} data={n} level={level + 1}/>
        )}
    </>
}

export const TaxApprovalPreviewTable: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const data = taxonomyEditorStore.approval.data;
    if (!data) return <></>
    return <TableContainer className="taxonomy-approval-table taxonomy-approval-preview">
        <Table stickyHeader>
            <MithraTableHeadColumns columns={TAX_APP_PRE_COLUMNS} enableLoading
                                    isLoading={taxonomyEditorStore.approval.reviewNotesIsBusy}/>
            <TableBody>
                {data.children.map(c => <TaxApprovalPreviewTableRowComponent key={c.id} data={c} level={1}/>)}
            </TableBody>
        </Table>
    </TableContainer>
})
