import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import {ApiSuggestionTreeResponse, ApiUpdateSuggestionTreeResponse, catsToDict,} from "../services/ApiHelpers";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {toCurrency} from "../components/currency-component/CurrencyClasses";
import {MatSearch, MatSupplierFilter_V2} from "../services/classes/MatReviewClasses";
import {MatSupplierCategoryConcentrationStatistics} from "../services/classes/MaterializedClasses";
import {PageResponseManager} from "./managers/PageResponseManager";
import {SearchManager} from "./managers/SearchManager";
import {UNCATEGORIZED_LABEL} from "../constants";
import {TaxonomyTableVizDelegate} from "./TaxonomyTableVizDelegate";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {TaxSuggesterExtendedD3HierarchyNode} from "../services/classes/TaxonomySuggestorClasses";


// Volatile state in FE
export type ReviewStatusChangesType = {
    hasChangesInChildren: boolean
}

// // Persistent state in BE
export type ResultTableRows = {
    p__description: string
    d__source: string
    s__name: string
    p__spend: string | number
    p__purchase_date: string
}


export type SelectionTableRows = {
    p__spend: string | number
    p_spend_a: string | number
    p_spend_b: string | number
    p_spend_c: string | number
    s_suppliers: string | number
    p__transactions: string | number
}

export type TableColumns = {
    columnTitle: string
    getValue: (d: any, c: any) => string
    xOffset: number
}

const hardcodedPaginatedTableRows = 30

export default class TaxonomySuggestorStore {

    suggestedTaxonomyId: number = this.profileStore.p.hardcodedTaxonomySuggestionId || -1;
    taxonomyApiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data> | undefined = undefined
    taxonomySuggestionState: any | undefined = undefined
    // selectedNode: SuggestionTreeStoreType<m_taxonomy.Data> | undefined = undefined
    selectedCategory: string[] = [];
    selectedNodeLabel: string | undefined = ''
    selectedNodeData: any | undefined = undefined
    tableColumns: TableColumns[] = [
        {
            columnTitle: 'Total Spend',
            getValue: (d, c) => {
                const currencyValue = toCurrency(this.profileStore.currencySymbol, d.data.values?.p__spend__sum);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 420,
        },
        {
            columnTitle: 'Holding', // TODO: CAT-1346
            getValue: (d, c) => {
                let v = d.data.values?.p_spend_a;
                // let v = getSpendMock(d.data.id + '_a') // TODO: CAT-1346
                const currencyValue = toCurrency(this.profileStore.currencySymbol, v);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 520,
        },
        {
            columnTitle: 'Energy', // TODO: CAT-1346
            getValue: (d, c) => {
                let v = d.data.values?.p_spend_b;
                // let v = getSpendMock(d.data.id + '_b') // TODO: CAT-1346
                const currencyValue = toCurrency(this.profileStore.currencySymbol, v);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 620,
        },
        {
            columnTitle: 'Nutreco', // TODO: CAT-1346
            getValue: (d, c) => {
                let v = d.data.values?.p_spend_c;
                // let v = getSpendMock(d.data.id + '_c') // TODO: CAT-1346
                const currencyValue = toCurrency(this.profileStore.currencySymbol, v);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 720,
        },
        {
            columnTitle: 'Suppliers',
            getValue: (d, c) => {
                const ns = String(d.data.values?.s__id__nunique)
                const value = ns.replace('undefined', '0');
                return value
            },
            xOffset: 820,
        },
        {
            columnTitle: 'Transactions',
            getValue: (d, c) => {
                const ns = String(d.data.values?.p__id__count)
                const value = ns.replace('undefined', '0');
                return value
            },
            xOffset: 920,
        },
    ]
    taxonomyTableController: TaxonomyTableVizDelegate = new TaxonomyTableVizDelegate()
    SUGGESTOR_COLUMNS: ColSpec[] = [

        // TODO: Demo
        // { cls: 'col-label', txt: 'Category' },
        // { cls: 'col-spend', txt: 'Total spend', width: '8%' },
        // { cls: 'col-spend_a', txt: 'ERP 1', width: '8%' },
        // { cls: 'col-spend_b', txt: 'ERP 2', width: '8%' },
        // { cls: 'col-spend_c', txt: 'ERP 3', width: '8%' },
        // { cls: 'col-p_count', txt: 'Suppliers', width: '8%' },
        // { cls: 'col-s_count', txt: 'Transactions', width: '8%' },
        // { cls: 'col-control_buttons', txt: 'Suggestion', width: '10%' },
        // { cls: 'col-control_buttons', txt: '', width: '5%' },

        { cls: 'col-s_context_1', txt: '', width: 100 },
        { cls: 'col-s_name', txt: 'Category', width: 100 },
        { cls: 'col-s_spend', txt: 'Total spend', width: 100 },
        { cls: 'col-s_city', txt: 'Supplier', width: 100 },
        { cls: 'col-s_l1s', txt: 'Transactions', width: 100 },
        { cls: 'col-s_context_2', txt: 'Suggestions', width: 100 },
        { cls: 'col-s_context_3', txt: '', width: 100 },
        { cls: 'col-s_context_4', txt: '', width: 100 },
    ]

    readonly supplierSearch = new SearchManager('supplier', () => {
        this.requestTableData()
    })

    /**
     * TODO: For now we re-use an spend concentration endpoint... this should be changed to a new endpoint
     */
    readonly _spendConcentrationSupplierRequestManager = new PageResponseManager<MatSupplierFilter_V2, MatSupplierCategoryConcentrationStatistics>(
        hardcodedPaginatedTableRows,
        (page, filter) => this.api.listPaginatedSpendConcentrationSupplier(filter, page, hardcodedPaginatedTableRows),
    );

    // noinspection JSUnusedLocalSymbols
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async getTaxonomySuggestion(suggestedTaxonomyId: number) {
        return await this.api.getTaxonomySuggestions(suggestedTaxonomyId).then((data) => {
            console.log('Got taxonomy suggestions')
            console.log(data)
            this.setTaxonomySuggestionState(data.suggestion_state)
            this.setTaxonomyApiResponse(data.suggestion_state)
            return
        }).catch((error) => {
            console.error('Error while getting taxonomy suggestions', error);
            // throw error;
        })
    }


    async updateTaxonomySuggestionValues() {
        return await this.api.updateTaxonomySuggestionValues(this.suggestedTaxonomyId).then((data) => {
            console.log('Updated taxonomy suggestions')
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    setTaxonomySuggestionState(apiResponse: any) {
        this.taxonomySuggestionState = apiResponse
    }

    async putTaxonomySuggestion(state: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>) {

        console.log('Updating taxonomy suggestions')
        console.log(state)

        return await this.api.putTaxonomySuggestions(this.suggestedTaxonomyId, state).then((data) => {
            console.log('Updated taxonomy suggestions')
            console.log(data)
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }


    setTaxonomyApiResponse(taxonomyApiResponse: any) {
        this.taxonomyTableController.data = TaxonomyTableVizDelegate.initNodes(taxonomyApiResponse);
        this.taxonomyApiResponse = taxonomyApiResponse;
    }

    saveTaxonomySuggestion() {
        this.api.saveTaxonomySuggestions(this.suggestedTaxonomyId)
    }


    setSelectedNodeLabel(label: string | undefined) {
        this.selectedNodeLabel = label
    }

    setSelectedNodeValues(data: m_taxonomy.Data | undefined) {
        this.selectedNodeData = data
    }

    get suggestionKpis(): {
        numberOfAdditions: number,
        numberOfDeletions: number,
        numberOfRenames: number
    } {
        let numberOfAdditions = 0;
        let numberOfDeletions = 0;
        let numberOfRenames = 0;
        const iterateSuggestions = (suggestion: any) => {
            if (suggestion.values.review_status?.added) {
                numberOfAdditions += 1;
            } else if (suggestion.values.review_status?.deleted) {
                numberOfDeletions += 1;
            } else if (suggestion.values.review_status?.renamed) {
                numberOfRenames += 1;
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        if (this.taxonomyApiResponse) {
            iterateSuggestions(this.taxonomyApiResponse);
        }
        return { numberOfAdditions, numberOfDeletions, numberOfRenames };
    }

    get selectedDepth() {
        return this.selectedCategory.length;
    }

    get isCategorySelected() {
        return this.selectedCategory && this.selectedCategory.length > 0;
    }

    setSelectedCategory(categories?: string[]) {
        this.selectedCategory = categories ? categories : [];
        this.requestTableData();
    }

    requestTableData() {
        if (this.dataTableFilter) {
            this._spendConcentrationSupplierRequestManager._request(this.dataTableFilter);
        } else {
            this._spendConcentrationSupplierRequestManager.reset();
        }
    }

    /**
     * Taken from: SpendConcentrationStore.supplierRequestFilter
     */
    get dataTableFilter(): MatSupplierFilter_V2 {
        if (!this.profileStore.p.hardcodedCombinedBagId) throw new Error('Missing hardcodedCombinedBagId');
        const level = this.selectedDepth;
        const selectedCats = catsToDict(this.selectedCategory, level);

        let search: MatSearch | undefined = undefined;
        if (this.supplierSearch.activeSearchString) {
            search = {
                supplier: this.supplierSearch.activeSearchString,
            }
        }
        return {
            databag: this.profileStore.p.hardcodedCombinedBagId,
            business_unit: undefined,
            search,
            filterLevel: level + 1,
            fixLevels: level,
            ...selectedCats,
        };
    }

    get isTableDataLoading() {
        return this._spendConcentrationSupplierRequestManager.isLoading;
    }

    /**
     * TODO: MatSupplierCategoryConcentrationStatistics is not a good fit for this endpoint
     */
    get paginatedTableData() {
        return this._spendConcentrationSupplierRequestManager;
    }

    handleAccept(b: TaxSuggesterExtendedD3HierarchyNode) {
        if (!b.data.apiValues.review_status) return;

        //ACCEPT DELETE -> PROPAGATE TO CHILDREN
        if (b.data.apiValues.review_status.deleted) {
            b.descendants().forEach(function (d) {
                if (!d.data.apiValues.review_status) return;
                if (d.data.apiValues.review_status.deleted) {
                    d.data.apiValues.review_status.rejected = false;
                    d.data.apiValues.review_status.accepted = true;
                } else if (d.data.apiValues.review_status?.added) {
                    console.error('ERROR: there should not be a child ADDED with a DELETE parent')
                } else if (!d.data.apiValues.review_status?.added || !d.data.apiValues.review_status?.deleted) {
                    console.error('ERROR: if the parent is DELETED, all children should be DELETED')
                }
            });
        }

        //ACCEPT ADD -> PROPAGATE TO PARENT
        if (b.data.apiValues.review_status.added) {
            b.ancestors().forEach(function (d) {
                if (!d.data.apiValues.review_status) return;
                if (d.data.apiValues.review_status.added) {
                    d.data.apiValues.review_status.rejected = false;
                    d.data.apiValues.review_status.accepted = true;
                } else if (d.data.apiValues.review_status.deleted) {
                    d.data.apiValues.review_status.rejected = true;
                    d.data.apiValues.review_status.accepted = false;
                }
            });
        }

        //ACCEPT RENAME -> APPLY TO SINGLE NODE
        if (b.data.apiValues.review_status.renamed) {
            b.data.apiValues.review_status.rejected = false;
            b.data.apiValues.review_status.accepted = true;
        }

        if (this.taxonomyTableController.data) {
            this.putTaxonomySuggestion(TaxonomyTableVizDelegate.exportTreeForPut(this.taxonomyTableController.data))
        }
    }

    handleReject(b: TaxSuggesterExtendedD3HierarchyNode) {
        if (!b.data.apiValues.review_status) return;

        //REJECT ADD -> PROPAGATE TO CHILDREN
        if (b.data.apiValues.review_status.added) {
            b.descendants().forEach(function (d) {
                if (!d.data.apiValues.review_status) return;
                if (d.data.apiValues.review_status.added) {
                    d.data.apiValues.review_status.rejected = true;
                    d.data.apiValues.review_status.accepted = false;
                } else if (d.data.apiValues.review_status.deleted) {
                    d.data.apiValues.review_status.rejected = true;
                    d.data.apiValues.review_status.accepted = false;
                }
            });
        }

        //REJECT DELETE -> PROPAGATE TO PARENT
        if (b.data.apiValues.review_status.deleted) {
            b.ancestors().forEach(function (d) {
                if (!d.data.apiValues.review_status) return;
                if (d.data.apiValues.review_status.deleted) {
                    d.data.apiValues.review_status.rejected = true;
                    d.data.apiValues.review_status.accepted = false;
                }
            });
        }

        //REJECT RENAME -> APPLY TO SINGLE NODE
        if (b.data.apiValues.review_status.renamed) {
            b.data.apiValues.review_status.rejected = true;
            b.data.apiValues.review_status.accepted = false;
        }

        if (this.taxonomyTableController.data) {
            console.log('sending put')
            this.putTaxonomySuggestion(TaxonomyTableVizDelegate.exportTreeForPut(this.taxonomyTableController.data))
        }
    }

    /**
     * Applies the filter, going down the TaxSuggesterExtendedD3HierarchyNode
     */
    //FIXME: Consider refactoring this function to also show the filtered node as collapsed instead of hiding them (Consider using TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true) or CloseAll(node))
    applyFilter(node: TaxSuggesterExtendedD3HierarchyNode, hideFilter: undefined | ((d: TaxSuggesterExtendedD3HierarchyNode) => boolean), depth = 0, hideUncat?: boolean,): boolean {
        let hideThis = false;
        let canOpen = false;
        if (hideUncat) {
            hideThis = hideThis || (node.data.viz.label === UNCATEGORIZED_LABEL);
        }

        //Hide everything else
        if (hideFilter && !hideThis && depth > 0) {
            hideThis = hideThis || !hideFilter(node)
        }

        // Hide everything else
        node.data.viz.filtered = false;
        if (node._children) {
            let hasUnhiddenChildren = false;
            node._children.forEach(c => {
                if (!this.applyFilter(c, hideFilter, depth + 1, hideUncat)) {
                    hasUnhiddenChildren = true;
                    hideThis = false;
                } else {
                    if (hideThis) {
                        this.setHidden(node, true, depth + 1)
                        node.data.viz.filtered = true;
                    }
                }
            })
            if (hasUnhiddenChildren) {
                canOpen = true;
                node.data.viz.filtered = false;
            }
        }

        node.data.viz.canOpen = canOpen;
        return hideThis;
    }

    setHidden(d: TaxSuggesterExtendedD3HierarchyNode, hidden: boolean, depth: number) {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }
    }
}
