import {ContractData} from "../classes";

export const CONTRACT_DEMO_DATA: ContractData[] = [
    {
        id: "CON001",
        contract_id: "CON001",
        confidential: "Yes",
        contract_title: "Supply Agreement for Packaging",
        contract_type: "Supply Contract",
        date: "2023-05-15",
        delivery: "Within 30 days of order placement",
        disagreements_resolving: "Arbitration",
        effective_date: "01-01-2022",
        expiration_date: "31-12-2025",
        governing_law: "State of New York",
        invoices_and_payment: "Monthly invoicing, net 30 days",
        involved_company: "ABC Inc.",
        liability: "Limited to direct damages",
        parent_supplier: "BM Holding",
        children_supplier: ["XYZ Corp."],
        involved_supplier: ["Bright motion Ams BV"],
        performance: "Satisfactory",
        price_adjustment: "Product pricing consists of 'Added Value' and 'HDPE' components.", //show this (pricing)
        // prices: [{"cost": 100, "name": "Product A"}, {"cost": 200, "name": "Product B"}],
        prices: [],
        products: "Widgets",
        quantity: 1000,
        renewal_condition: "Not specified.", //Show this
        scope: "Procurement of Printed Packaging", //Show this
        termination: "30 days notice",
        volume_discount: 5,
        payment_terms: "Within 30 days of receipt of a correctly rendered invoice." //Show this //Missing in firestore
    },
    // {
    //     contract_id: "CON002",
    //     confidential: "No",
    //     contract_title: "Service Level Agreement",
    //     contract_type: "Service",
    //     date: "2023-07-20",
    //     delivery: null,
    //     disagreements_resolving: "Mediation",
    //     effective_date: "2023-08-01",
    //     expiration_date: "2024-07-31",
    //     governing_law: "State of California",
    //     invoices_and_payment: "Quarterly invoicing, net 60 days",
    //     involved_company: "XYZ Corp.",
    //     involved_supplier: "Service Provider Inc.",
    //     liability: "Limited to service fees",
    //     parent_supplier: null,
    //     children_supplier: null,
    //     performance: "Excellent",
    //     price_adjustment: "No",
    //     prices: [],
    //     products: null,
    //     quantity: null,
    //     renewal_condition: "Automatic renewal",
    //     scope: "Provision of IT support services",
    //     termination: "90 days notice",
    //     volume_discount: null
    // }
]
