import React from "react";
import "./CombinedReviewButtons.scss";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";

import {CombinedPartReviewStateVisual} from "../../classes/CategorizationReviewVisualStates";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";

type Props = {
    onAccept?: () => void
    state?: CombinedPartReviewStateVisual
    canEdit?: boolean
}
export const CombinedReviewButtons: React.FC<Props> = ({onAccept, state, canEdit}) => {
    if (!state) return <></>;
    const isAccept = state.reviewChoice === ReviewChoice.ACCEPT;
    let disableAcceptReason: string | undefined = undefined
    let disableEditReason: string | undefined = undefined
    if (state.approvalState === 'PENDING') {
        disableAcceptReason = "Cannot accept already pending changes"
        disableEditReason = "Cannot re-categorize pending changes"
    }
    if (state.combinedCategory === 'uncategorized') {
        disableAcceptReason = "Cannot accept uncategorized changes"
    }
    if (state.approvalState === 'APPROVED') {
        disableAcceptReason = "Cannot accept already approved changes"
        disableEditReason = "Cannot re-categorize approved changes"
    }

    let showAccRej = !state.isHidden;
    let showUncat = state.hasUnclassifiedSuggestions;

    // In aggregated view we show a much simpler version
    if (showUncat) {
        if (showAccRej) {
            showAccRej = false;
        } else {
            // The should see the button as "uncategorized", we do not need to show it here as well
        }
    } else {
        // Text simply remains empty
    }

    return <>
        <Grid container alignItems="center" justifyContent="space-evenly" className="combined-review-buttons">
            <Grid item>
                {showAccRej ? (
                    <CheckCircle
                        className={
                            'accept'
                            + (isAccept ? ' active' : ' inactive')
                            + (onAccept ? ' clickable' : '')
                        }
                        onClick={onAccept}
                    />
                ) : (
                    <Tooltip title={disableAcceptReason} arrow>
                    <span>
                        <IconButton className="buttonIcon disabled" disabled>
                            <CheckCircle/>
                        </IconButton>
                    </span>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    </>
}
