import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable} from "mobx";
import {BusinessCaseGeneratorPageController} from "./BusinessCaseGeneratorPageController";

export type Ordering = {
    orderBy: string
    direction: 'asc' | 'desc'
}

/**
 * All method related to navigating the categorization review
 */
export class BusinessCaseGeneratorPageFilterController {
    ordering?: Ordering;

    constructor(
        private businessCaseGeneratorPageController: BusinessCaseGeneratorPageController,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }
}
