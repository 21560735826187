import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {FormControl, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";

const componentId = 'taxonomy-suggestor-search-component';
const fieldId = componentId + '-field';

type Props = {};
export const TaxonomySuggestionSearchComponent: React.FC<Props> = observer(() => {
    const {taxonomySuggestorStore} = useStores();
    const searchTitle = 'Search suppliers';
    const searchManager = taxonomySuggestorStore.supplierSearch;
    return <FormControl variant="standard" fullWidth={true} className="search-field">
        <InputLabel htmlFor={fieldId}>
            {searchTitle}
        </InputLabel>
        <Input
            id={fieldId}
            type="text"
            fullWidth={true}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear search"
                        onClick={() => searchManager.clearSearch()}
                    >
                        <Clear/>
                    </IconButton>
                    <IconButton
                        aria-label="search"
                        onClick={() => searchManager.doSearch('click')}
                    >
                        <Search/>
                    </IconButton>
                </InputAdornment>
            }
            value={searchManager.searchString}
            onChange={e => searchManager.setSearch(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? searchManager.doSearch('enter') : undefined}
        />
    </FormControl>
})
