import React from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useStores} from "../../../../stores";
import {observer} from "mobx-react-lite";

type Props = {}
export const SampleOverview: React.FC<Props> = observer(() => {
    const {categorizationReviewStore} = useStores();
    const stats = categorizationReviewStore.reviewPageController.reviewPageStatisticsController.sampleStatistics;
    const colorClass = stats && stats.sample_review_progress.reviewed < stats.sample_target_size ? 'value-warning' : '';
    return <Grid container justifyContent="center">
        {/* 3-6-3 */}
        <Grid className="key-value-container narrow-margin text-center font-bigger"
              item xs={6}
              container justifyContent="space-between">
            <Grid item>
                <Typography variant="caption">
                    Reviewed
                </Typography>
                <Typography variant="h5" className={'font-weight-bold key-value ' + colorClass}>
                    {stats
                        ? stats.sample_review_progress.reviewed
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    Target
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value">
                    {stats
                        ? stats.sample_target_size
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    Maximum
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value">
                    {stats
                        ? stats.sample_review_progress.total
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
})
