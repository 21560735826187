import React from 'react';
import {MithraEchart} from "../../services/echarts/MithraEchart";
import {EChartContextType} from "../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";

function initStackedBar(echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType {
    const chart = echart.init(root, 'mithra', {
        renderer: 'svg',
        width: 800,
        height: 500.
    });
    chart.setOption({
        title: {
            text: 'Non PPV savings',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {
            bottom: 0,
            data: [
                'DOI 1',
                'DOI 2',
                'DOI 3',
                'DOI 4',
                'DOI 5',
            ],
        },
        grid: {
            left: 10,
            right: 10,
            top: 60,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['Jul 2024', 'Aug 2024'],
        },
        yAxis: {
            type: 'value',

            // splitLine: {
            //     lineStyle: {
            //         color: 'rgb(217,217,217)',
            //     },
            // },
            // minorTick: {
            //     show: false,
            //     splitNumber: 2,
            // },
            // minorSplitLine: {
            //     show: true,
            //     lineStyle: {
            //         color: 'rgb(217,217,217)',
            //     },
            // },
        },
        series: data,
    })
    return chart;
}

const labelOption = {
    show: true,
    formatter: 'CHF {c}',
    fontSize: 16,
    rich: {
        name: {}
    },
    position: 'insideTop',
};

const ppvData = [
    {
        name: 'DOI 4',
        type: 'bar',
        label: {
            ...labelOption,
        },
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: [20, 20]
    },
    {
        name: 'DOI 3',
        type: 'bar',
        label: {
            ...labelOption,
            show: false,
        },
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: [null, null]
    },
    {
        name: 'DOI 5',
        type: 'bar',
        label: {
            ...labelOption,
        },
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: [9.17, 9.17]
    },
    {
        name: 'DOI 2',
        type: 'bar',
        label: {
            ...labelOption,
        },
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: [null, 9.17]
    },
    {
        name: 'DOI 1',
        type: 'bar',
        label: {
            ...labelOption,
            show: false,
        },
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: [null, null]
    },
];

export const EchartPpvDemo1 = () => {
    // const [data, setData] = useState<Data[]>(
    //     [{name: 1, value: 1}, {name: 2, value: 2}, {name: 3, value: 3}]
    // );

    return <MithraEchart
        alwaysRerender={() => {
        }}
        initChart={initStackedBar}
        data={ppvData}
    />
}

export const SmallPpvComponentDemo: React.FC = () =>
    <MithraEchart
        initChart={initStackedBar}
        data={ppvData}
    />
