import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const DefaultMenu: React.FC = () => {
    const {p} = useStores();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: (MenuItem | undefined)[] = []

    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('data'))
        menu.push({
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, params) : routes.home,
            active: location.pathname === routes.home || location.pathname === routes.job_manage,
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('synergies'))
        menu.push({
            label: 'Synergies',
            link: generatePath(hasId1 ? routes.synergy_v2_dashboard : routes.synergy_v2, params),
            active: location.pathname === routes.synergy_v2_dashboard,
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('opportunities'))
        menu.push({
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_opportunities, params) : routes.home,
            active: !isHome && location.pathname === routes.demo_opportunities,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('opportunities')),
            className: 'menu-item-2nd-last',
        })
    if (
        p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('report')
        // Reporting should only appear when there is a data source selected
        && hasId1
    )
        menu.push({
            label: 'Report',
            link: hasId1 ? generatePath(routes.job_report, params) : routes.home,
            active: !isHome && location.pathname === routes.job_report,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('report')),
            className: 'menu-item-last',
        })

    return <MenuItems className="default-menu" items={menu}/>
}
