import {ApiService} from "../services/Http";
import {AxiosInstance, AxiosResponse} from "axios";
import {
    Attachment,
    ByApprovedRowResponse,
    Category,
    Cluster,
    Company,
    ConversionRate,
    Currency,
    Division,
    FirstPageKpis,
    Initiative,
    InitiativeKPIValue, InitiativeWriter,
    KPIType,
    Lever,
    LeverGroup,
    PerClustercumSumRowResponse,
    PerClusterRowResponse,
    PerCompanyLeverRowResponse,
    PerDoiRowResponse,
    SitUserProfile,
    Target,
    TValuePerClusterMonthRowResponse,
    TValuePerCompanyRowResponse,
    TValuePerMonthRowResponse
} from "./ApiTypes";
import {PageResponse} from "../services/ApiTypes";
import {B} from "../build";

// noinspection PointlessBooleanExpressionJS
/**
 * For debugging
 */
export const SIT_TEST: false | string = !B.APP_IS_DEV ? false : (
    // 'TEST'
    false
);

export default class SitApi extends ApiService {

    constructor(public http: AxiosInstance) {
        super(http);
    }

    // User
    listUsers(filters: string[][]): Promise<AxiosResponse<PageResponse<SitUserProfile>>> {
        const params = new URLSearchParams(filters);
        return this.http.get(`/users/`, {params});
    }

    // Company configuration
    listLeverGroups(): Promise<AxiosResponse<LeverGroup[]>> {
        return this.http.get(`/lever-groups/`, {params: {no_page: ''}});
    }

    listLevers(): Promise<AxiosResponse<Lever[]>> {
        return this.http.get(`/levers/`, {params: {no_page: ''}});
    }

    listCategories(): Promise<AxiosResponse<Category[]>> {
        return this.http.get(`/categories/`, {params: {no_page: ''}});
    }

    listCompanies(): Promise<AxiosResponse<Company[]>> {
        return this.http.get(`/companies/`, {params: {no_page: ''}});
    }

    listCurrencies(): Promise<AxiosResponse<Currency[]>> {
        return this.http.get(`/currencies/`, {params: {no_page: ''}});
    }

    listConversionRates(): Promise<AxiosResponse<ConversionRate[]>> {
        return this.http.get(`/conversion-rates/`, {params: {no_page: ''}});
    }

    addConversionRate(sourceId: number, targetId: number, rate: number): Promise<AxiosResponse<ConversionRate>> {
        return this.http.post(`/conversion-rates/`, {
            source_id: sourceId, target_id: targetId, rate: rate
        });
    }

    listClusters(): Promise<AxiosResponse<Cluster[]>> {
        return this.http.get(`/clusters/`, {params: {no_page: ''}});
    }

    listDivision(): Promise<AxiosResponse<Division[]>> {
        return this.http.get(`/divisions/`, {params: {no_page: ''}});
    }

    listKpiType(): Promise<AxiosResponse<KPIType[]>> {
        return this.http.get(`/kpi-types/`, {params: {no_page: ''}});
    }

    // Target
    listTargets(filters: string[][]): Promise<AxiosResponse<PageResponse<Target>>> {
        const params = new URLSearchParams(filters);
        return this.http.get(`/targets/`, {params});
    }

    getTarget(id: number): Promise<AxiosResponse<Target>> {
        return this.http.get(`/targets/` + id.toString() + `/`);
    }

    createTarget(instance: Partial<Target>): Promise<AxiosResponse<Target>> {
        return this.http.post(`/targets/`, instance);
    }

    updateTarget(instance: Partial<Target>): Promise<AxiosResponse<Target>> {
        return this.http.patch(`/targets/` + instance.id?.toString() + `/`, instance);
    }

    // Initiative
    listInitiatives(filters: string[][]): Promise<AxiosResponse<PageResponse<Initiative>>> {
        const params = new URLSearchParams(filters);
        return this.http.get(`/initiatives/`, {params});
    }

    listInitiativesApproval(filters: string[][]): Promise<AxiosResponse<PageResponse<Initiative>>> {
        const params = new URLSearchParams(filters);
        params.set('doi_status', '5');
        return this.http.get(`/initiatives/`, {params});
    }

    getInitiative(id: number): Promise<AxiosResponse<Initiative>> {
        return this.http.get(`/initiatives/` + id.toString() + `/`);
    }

    lockInitiative(id: number): Promise<AxiosResponse<Initiative>> {
        return this.http.patch(`/initiatives/` + id.toString() + `/lock/`);
    }

    releaseLockInitiative(id: number): Promise<AxiosResponse<Initiative>> {
        return this.http.patch(`/initiatives/` + id.toString() + `/release_lock/`);
    }

    approveInitiative(id: number, approve: boolean): Promise<AxiosResponse<Initiative>> {
        return this.http.patch(`/initiatives/` + id.toString() + `/approve/`, {approved: approve});
    }

    createInitiative(instance: Partial<InitiativeWriter>): Promise<AxiosResponse<Initiative>> {
        return this.http.post(`/initiatives/`, instance);
    }

    updateInitiative(instance: Partial<InitiativeWriter>): Promise<AxiosResponse<Initiative>> {
        return this.http.patch(`/initiatives/` + instance.id!.toString() + `/`, instance);
    }

    initiativeAttachments(id: number): Promise<AxiosResponse<Attachment[]>> {
        return this.http.get(`/initiatives/` + id.toString() + `/attachments/`);
    }

    downloadInitiativeAttachment(initiative_id: number, attachment_id: number): Promise<AxiosResponse> {
        return this.http.get(
            `/initiatives/` + initiative_id.toString() + `/attachments/` + attachment_id.toString() + `/`,
            {responseType: "blob"});
    }

    createInitiativeAttachment(initiative_id: number, file: File): Promise<AxiosResponse<never>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('initiative', initiative_id.toString());
        return this.http.post(`/initiatives/` + initiative_id.toString() + `/attachments/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    deleteInitiativeAttachment(initiative_id: number, attachment_id: number): Promise<AxiosResponse<never>> {
        return this.http.delete(`/initiatives/` + initiative_id.toString() + `/attachments/` + attachment_id.toString() + `/`);
    }

    firstPageKpis(filters: string[][]): Promise<AxiosResponse<FirstPageKpis>> {
        const params = new URLSearchParams(filters);
        return this.http.get(`/initiatives/first_page_kpis/`, {params});
    }

    // Financial dashboard
    fpTargetPerMonth(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('target_year', String(year));
        return this.http.get<TValuePerMonthRowResponse[]>(`/targets/fp_target_per_month/`, {params});
    }

    fpPerCompanyTarget(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('target_year', String(year));
        return this.http.get<TValuePerCompanyRowResponse[]>(`/targets/fp_per_company/`, {params});
    }

    perCusterTarget(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('target_year', String(year));
        return this.http.get<TValuePerClusterMonthRowResponse[]>(`/targets/per_cluster/`, {params});
    }

    initiativeContributionPerDoiCumSumRows(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('year', String(year));
        return this.http.get<PerDoiRowResponse[]>(`/initiatives-contribution/per_doi_cumsum/`, {params});
    }

    initiativeContributionPerDoiSumRows(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('year', String(year));
        return this.http.get<PerDoiRowResponse[]>(`/initiatives-contribution/per_doi_sum/`, {params});
    }

    initiativeContributionApproved(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('year', String(year));
        params.set('doi_status', '5');
        return this.http.get<ByApprovedRowResponse[]>(`/initiatives-contribution/by_approved/`, {params});
    }

    initiativeContributionPerCompanyLeverRows(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('year', String(year));
        return this.http.get<PerCompanyLeverRowResponse[]>(`/initiatives-contribution/per_company_lever/`, {params});
    }

    initiativeContributionPerClusterRows(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('target_year', String(year));
        return this.http.get<PerClusterRowResponse[]>(`/initiatives-contribution/per_doi_cluster/`, {params});
    }

    initiativeContributionPerClusterCumSumRows(year: number, filters: string[][]) {
        const params = new URLSearchParams(filters);
        params.set('target_year', String(year));
        return this.http.get<PerClustercumSumRowResponse[]>(`/initiatives-contribution/per_doi_cluster_cumsum/`, {params});
    }

    listInitiativesKpiValues(kpi_id: number): Promise<AxiosResponse<InitiativeKPIValue[]>> {
        return this.http.get(`/initiatives-kpi-values/`, {params: {initiative_kpi: kpi_id}});
    }
}