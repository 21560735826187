import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {GoldenRecordGroupedState} from "../classes/GoldenRecordGroupedState";
import {GOLDEN_RECORD_SUB_TABLE_COLUMNS} from "../classes/GoldenRecordTableColumns";
import {GoldenRecordTableSubRow} from "./GoldenRecordTableSubRow";

type Props = {
    gState: GoldenRecordGroupedState
};
export const GoldenRecordTableSubTable: React.FC<Props> = observer(({gState}) => {
    const {p} = useStores();
    const COLUMNS = GOLDEN_RECORD_SUB_TABLE_COLUMNS;
    const isLoading = gState.isLoadingRows;
    const dataCount = gState.partCount;
    const rowsPerPage = gState.pageSize;

    const tableClass = 'golden-record-sub-table' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table
            className="sub-table"
            size="small">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody>
                {gState.partStates?.map(pState =>
                    <React.Fragment key={pState.id}>
                        <GoldenRecordTableSubRow pState={pState}/>
                    </React.Fragment>
                )}
            </TableBody>
            {dataCount > rowsPerPage &&
                // It's very confusing which pagination you see at the bottom of the screen if there are only few lines
                // of data. So we hide this pagination and only show the one from the grouped rows, where possible
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            count={dataCount}
                            rowsPerPage={rowsPerPage} // Place this one in the ProfileStore (but have a default value of 100 as well) AND we can assume it does not change
                            page={gState.page - 1}
                            // SelectProps={{
                            //     inputProps: {'aria-label': 'rows per page'},
                            //     native: true,
                            // }}
                            // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                            onPageChange={(e, page) => gState.handlePageChange(page + 1)}
                            // onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={MithraTablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    </TableContainer>
})
