import React from "react";
import {
    AI_CATEGORIZATION_SCORE_DIGITS,
    AI_CATEGORIZATION_SCORE_FACTOR
} from "../../pages/categorization/controllers/CategorizationReviewPageFilterController";

type Props = {
    score: string | number
}
export const AiScore: React.FC<Props> = ({score}) => {
    const s = Number(score) * AI_CATEGORIZATION_SCORE_FACTOR;
    const sScore = s.toFixed(AI_CATEGORIZATION_SCORE_DIGITS);
    // Show the score with 2 digits
    return <span>{sScore}</span>;
}
