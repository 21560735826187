import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import './TreeMap.scss';
import {AdvancedTreeData, CategoryFilter} from "../../../services/ApiTypes";
import {observer} from "mobx-react-lite";
import {environment} from "../../../env";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {toCurrencyWithP} from "../../currency-component/CurrencyClasses";
import {useStores} from "../../../stores";
import ProfileStore from "../../../stores/ProfileStore";

type NodeData = d3.HierarchyRectangularNode<AdvancedTreeData>;
type SvgGSelection = d3.Selection<SVGGElement, null, any, null>;
type SvgSelection = d3.Selection<SVGElement, null, any, null>;

const ANIMATION_DURATION_MS = environment.isTest ? 1500 : 750;

const TOP_BAR_HEIGHT = 55;

const displayLabel = (d: NodeData) => d.data.label === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : d.data.label;
const displayPath = (d: NodeData) => d.ancestors().reverse().map(displayLabel).join(" / ");

/**
 * DOM.uid adapted from Observable stdlib
 *
 * Copyright 2018-2022 Observable, Inc.
 *
 * Permission to use, copy, modify, and/or distribute this software for any purpose
 * with or without fee is hereby granted, provided that the above copyright notice
 * and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND
 * FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS
 * OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER
 * TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF
 * THIS SOFTWARE.
 */
class Id {
    public href: string;

    constructor(public id: string) {
        // eslint-disable-next-line no-restricted-globals
        this.href = new URL(`#${id}`, location.href) + ""
    }

    toString(): string {
        return "url(" + this.href + ")";
    }
}

class DOM {
    private static count: number = 0;

    public static uid(name: string) {
        return new Id("O-" + (name == null ? "" : name + "-") + ++DOM.count);
    }
}

/***
 * renderTreeMap adapted from work Copyright 2012–2023 Mike Bostock, used under ISC License
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES
 * WITH REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR
 * ANY SPECIAL, DIRECT, INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES
 * WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN
 * ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF
 * OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * @param svgRef
 * @param width
 * @param height
 * @param data
 * @param zoomLevelChanged
 */
class TreeMapRenderer {
    private currentlyZooming = false;

    constructor(
        private svgRef: any,
        private width: number,
        private height: number,
        private profileStore: ProfileStore,
    ) {
    }

    render(data: AdvancedTreeData, zoomLevelChanged: (categories: CategoryFilter) => void, selectedCategory: CategoryFilter | null) {
        // console.log('TreeMapRenderer.render()', this.svgRef.current, this.currentlyZooming);
        const profileStore = this.profileStore;

        if (!this.svgRef.current) {
            return;
        }
        if (this.currentlyZooming) {
            // Don't re-render the treemap while we're performing a zoom operation
            return;
        }
        const svg: SvgSelection = d3.select<SVGElement, null>(this.svgRef.current)
            .attr("viewBox", `0 ${-TOP_BAR_HEIGHT} ${this.width} ${this.height + TOP_BAR_HEIGHT}`)
            .attr("width", this.width)
            .attr("height", this.height + TOP_BAR_HEIGHT)
            .attr("style", "width: 100%; height: auto;");

        // Prepare the data
        const hierarchy: d3.HierarchyNode<AdvancedTreeData> = d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value! - a.value!)
            .each(d => {
                (d as any).id = JSON.stringify(d.ancestors().map(d => d.data.label))
            })

        const tile = (node: NodeData, x0: number, y0: number, x1: number, y1: number) => {
            // TODO: use treemapSliceDice for value-based squares
            d3.treemapBinary(node, 0, 0, this.width, this.height);
            if (!node.children) return;
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / this.width * (x1 - x0);
                child.x1 = x0 + child.x1 / this.width * (x1 - x0);
                child.y0 = y0 + child.y0 / this.height * (y1 - y0);
                child.y1 = y0 + child.y1 / this.height * (y1 - y0);
            }
        };
        const root: NodeData = d3.treemap<AdvancedTreeData>().tile(tile)(hierarchy);

        // Create the scales.
        const x = d3.scaleLinear().rangeRound([0, this.width]);
        const y = d3.scaleLinear().rangeRound([0, this.height]);

        // Ensure there is a single g.main to call the render function on
        let group: SvgGSelection = svg.selectAll<SVGGElement, null>('g.main')
        const mainGroupSize = group.size();
        if (mainGroupSize === 0) {
            group = svg
                .append('g')
                .classed('main', true);
        } else if (mainGroupSize === 1) {
            // Ok
        } else {
            // Only render on the top one
            group = group
                .filter((_, i, arr) => i === arr.length - 1)
        }

        function render(group: SvgGSelection, root: NodeData) {
            // console.log(`TreeMap.render() groups: ${root?.children?.length}`);
            const children = root.children ?? [];

            const values: number[] = children.map(d => d.value || 0);
            const color = d3.scaleLinear<string>()
                .domain(d3.extent(values) as [number, number])
                .range(["#c8ced5", "#193150"]);
            const textColorScale = d3.scaleLinear()
                .domain(d3.extent(values) as [number, number])
                .range([0, 1]);
            const textColor = (value: number) => {
                if (textColorScale(value) > 0.4) {
                    return 'white';
                }
                return 'black';
            }

            const node = group
                .selectAll("g")
                .data(children.concat(root))
                .join("g")

            node.filter(d => Boolean(d === root ? d.parent : d.children))
                .attr("cursor", "pointer")
                .on("click", (_, d) => {
                    if (d === root) {
                        zoomOut(root, ANIMATION_DURATION_MS);
                        if (d.parent) communicateZoomLevelChange(d.parent);
                    } else {
                        zoomIn(d, ANIMATION_DURATION_MS);
                        communicateZoomLevelChange(d);
                    }
                });

            node.append("title")
            node.select('title')
                .text(d => {
                    const currencyValue = toCurrencyWithP(d.value || 0, profileStore.currencyFormat, profileStore.currencySymbol);
                    return `${displayPath(d)}\n${currencyValue}`;
                });

            node.append("rect")
                .attr("id", (d: any) => (d.leafUid = DOM.uid("leaf")).id)
                .attr("stroke", "#fff");
            node.select('rect')
                .attr("fill", d => d === root ? "#fff" : color(d.value || 0))

            node.append("clipPath")
                .attr("id", (d: any) => (d.clipUid = DOM.uid("clip")).id)
                .append("use")
                .attr("xlink:href", (d: any) => d.leafUid.href);

            node.append("text")
                .attr("clip-path", (d: any) => d.clipUid)
                .attr("y", "1.5em")
            node.select('text')
                .attr("font-weight", d => d === root ? "bold" : null)
                .style("fill", d => d === root ? "#000" : textColor(d.value || 0))
                .selectAll("tspan")
                .data(d => {
                    const currencyValue = toCurrencyWithP(d.value || 0, profileStore.currencyFormat, profileStore.currencySymbol);
                    if (d === root) {
                        return [displayPath(d)].concat(currencyValue);
                    } else {
                        return displayLabel(d).split(/(?=[A-Z][^A-Z]{2})/g).concat(currencyValue)
                    }
                })
                .join("tspan")
                .attr("dy", (_, i) => i > 0 ? "1.1em" : null)
                .attr("x", "0.5em")
                .attr("fill-opacity", (_, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                .attr("font-weight", (_, i, nodes) => i === nodes.length - 1 ? "normal" : null)
                .text(d => d);

            group.call(position, root);
        }

        function position(group: SvgGSelection, root: NodeData) {
            group.selectAll<SVGGElement, NodeData>("g")
                .attr("transform", d => d === root ? `translate(0,${-TOP_BAR_HEIGHT})` : `translate(${x(d.x0)},${y(d.y0)})`)
                .select("rect")
                .attr("width", d => d === root ? '100%' : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? TOP_BAR_HEIGHT : y(d.y1) - y(d.y0));
        }

        function communicateZoomLevelChange(d: NodeData) {
            const ancestors = d.ancestors();
            if (ancestors.length === 0) {
                console.warn('TreeMap.communicateZoomLevelChange() ancestors.length === 0', d.id);
                return;
            }
            const category = ancestors.reverse().slice(1).map(d => d.data.label) as CategoryFilter;
            zoomLevelChanged(category);
        }

        const zoomIn = (d: NodeData, duration: number) => {
            // When zooming in, draw the new nodes on top, and fade them in.
            this.currentlyZooming = true;

            const group0: SvgGSelection = group.attr("pointer-events", "none");
            const group1: SvgGSelection = group = svg
                .append("g")
                .classed('main', true)
                .call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(duration)
                .call(t => {
                    group0
                        .transition(t as any)
                        .remove()
                        .call(position as any, d.parent);
                })
                .call(t => {
                    let transition = group1.transition(t as any);
                    transition
                        .attrTween("opacity", () => (() => d3.interpolate(0, 1).toString()))
                        .call(position as any, d);
                })
                .on('end', () => this.currentlyZooming = false);
        };

        const zoomOut = (d: NodeData, duration: number) => {
            if (!d.parent) {
                console.warn('TreeMap.zoomOut() while d.parent is null', d.id);
                return;
            }

            // When zooming out, draw the old nodes on top, and fade them out.
            this.currentlyZooming = true;

            const group0 = group.attr("pointer-events", "none");
            const group1: SvgGSelection = group = svg
                .insert("g", "*")
                .classed('main', true)
                .call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(duration)
                .call(t => group0.transition(t as any).remove()
                    .attrTween("opacity", () => (() => d3.interpolate(0, 1).toString()))
                    .call(position as any, d))
                .call(t => group1.transition(t as any)
                    .call(position as any, d.parent))
                .on('end', () => this.currentlyZooming = false);
        };

        group = group
            .call(render, root);

        if (selectedCategory !== null) {
            const selectedCategoryArray = selectedCategory.filter(c => c);
            const selectedZoomLevel = selectedCategoryArray.length;
            if (selectedZoomLevel > 0) {

                let selectedNode: NodeData | null = null;
                let n = root;
                for (let i = 0; i < selectedCategoryArray.length; i++) {
                    const selectedCategory = selectedCategoryArray[i];
                    const selectedChild = n.children?.find(d => d.data.label === selectedCategory);
                    if (!selectedChild) {
                        break;
                    }
                    n = selectedChild;
                    if (i === selectedZoomLevel - 1) {
                        selectedNode = n;
                    }
                }
                if (!selectedNode) {
                    console.warn('TreeMap.render() selectedCategory not found', selectedCategoryArray);
                } else {
                    // Move to the selected node
                    zoomIn(selectedNode, 0)
                }
            }
        }
    }

    /**
     * Not used, but it's safer to remove everything if we can
     */
    clear() {
        d3.select(this.svgRef.current).html('');
    }
}

export const TreeMap: React.FC<{
    data: AdvancedTreeData,
    selectedCategory: CategoryFilter | null,
    categoryFilterChanged: (category: CategoryFilter) => void,
}> = observer(({data, categoryFilterChanged, selectedCategory}) => {
    const {p} = useStores();
    const margin = {top: 10, right: 10, bottom: 10, left: 10}
    const total_width = 750;
    const total_height = 400;
    const width: number = total_width - margin.left - margin.right;
    const height: number = total_height - margin.top - margin.bottom;

    const svgRef = useRef<SVGSVGElement>(null);
    const [renderer, setRenderer] = React.useState<TreeMapRenderer | null>(null);

    useEffect(() => {
        // onMount
        if (!svgRef.current) return;
        if (renderer) renderer.clear();
        setRenderer(new TreeMapRenderer(svgRef, width, height, p));
        return () => {
            if (renderer) {
                renderer.clear();
                setRenderer(null);
            }
        }
        // eslint-disable-next-line
    }, [svgRef, width, height])

    useEffect(() => {
        // We only trigger the re-render when the data changes
        if (!renderer) return;
        renderer.render(data, categoryFilterChanged, selectedCategory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, renderer]);

    return <svg
        className="tree-map"
        ref={svgRef}
        viewBox={`0 0 ${total_width} ${total_height}`}
        style={{height: total_height + 'px'}}
    />;
});
