export type DashboardCard =
    'explore-cats'
    | 'improve-cats'
    | 'taxonomy-builder'
    | 'koi-ppv'
    | 'koi-spend-concentration'
    | 'koi-spend-segmentation'
    | 'supplier-normalization'
    | 'adv-dashboard'

export const EXPERIMENTAL_DASHBOARD_CARDS = [
    'merge-improve-cats',
    'taxonomy-mapper',
    'synergy-categories',
    'synergy-suppliers',
    'synergy-spend',
    'adv-looker-dashboard',
    'data-ingestion',
    'synergy-overview',
    'taxonomy-suggester',
    'golden-record',
    'contract-insights',
] as const;

export type ExperimentalDashboardCard = typeof EXPERIMENTAL_DASHBOARD_CARDS[number];
export type AnyDashboardCard = DashboardCard | ExperimentalDashboardCard;

export function isExperimentalCard(c: AnyDashboardCard): c is ExperimentalDashboardCard {
    return EXPERIMENTAL_DASHBOARD_CARDS.includes(c as ExperimentalDashboardCard);
}
