import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {Target} from "../ApiTypes";
import {PageResponse} from "../../services/ApiTypes";
import {initialListFilters, StringArrayFilter} from "../../services/ApiHelpers";
import {makeAutoObservable} from "mobx";

export class TargetListStore {
    readonly targetListRM = new AxoisRequestManager<{ filters: string[][] }, PageResponse<Target>>(
        ({filters}) => from(this.api.listTargets(filters)));
    targetListFilter: StringArrayFilter = new StringArrayFilter(this.targetListRM, initialListFilters());

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
    }
}