import {makeAutoObservable} from "mobx";
import * as d3 from "d3";
import {ApiSuggestionTreeResponse, ApiUpdateSuggestionTreeResponse} from "../../../services/ApiHelpers";
import {taxonomy_suggestion_demo} from "../Classes";

type ExtendedD3HierarchyNode = taxonomy_suggestion_demo.ExtendedD3HierarchyNode;
type Filter = taxonomy_suggestion_demo.Filter;
type Data = taxonomy_suggestion_demo.Data;
// type InputData = taxonomy_suggestion_demo.InputData;
type InputData = any;
type TreeData = taxonomy_suggestion_demo.TreeData;

/**
 * true means we show it
 * @deprecated
 */
const SUGGESTION_DEMO_FILTERS: taxonomy_suggestion_demo.Filter[] = [
    undefined,
    d => {
        if (!d?.data.apiValues.review_status) return false;
        return d.data.apiValues.review_status.added;
    },
    d => {
        if (!d?.data.apiValues.review_status) return false;
        return d.data.apiValues.review_status.deleted;
    },
    d => {
        if (!d?.data.apiValues.review_status) return false;
        return d.data.apiValues.review_status.renamed;
    },
]


/**
 * This class should be removed after the demo.
 * The TaxonomyTableVizDelegate needs to become agnostic to the API response. Then we can re-use it for the
 * SHV suggestion version, the health check, the taxonomy suggestor v1 and suggestion demo v2.
 * @deprecated We need to update TaxonomyTableVizDelegate to not depend on the data field
 */
export class FilteredTaxonomyDemoTableController {
    data: ExtendedD3HierarchyNode | undefined; //This copy is used only for viz
    filter: number | undefined = 0

    /**
     * Hack to trigger re-render for the demo
     */
    changed = 0

    constructor() {
        makeAutoObservable(this)
    }

    /**
     * This function applies a filter to the tree structure
     * It writes the results to the viz.filtered field
     *
     * @param node The current node of the depth first search
     * @param hideUncat Whether to hide the uncategorized nodes
     * @param hideFilter The filter to apply
     * @param depth The current depth of the tree
     * @returns Whether the current node should be hidden or not
     */
    applyFilter(node: ExtendedD3HierarchyNode, hideUncat: boolean, hideFilter: undefined | ((d: ExtendedD3HierarchyNode) => boolean), depth = 0): boolean {
        let hideThis = false;
        let canOpen = false;

        if (hideFilter !== undefined && depth >= 0) {
            // We apply the filter to this node
            hideThis = hideThis || !hideFilter(node)
        }

        if (node._children) {
            let showSomeChild = false;
            node._children.forEach(c => {
                const showChild = this.applyFilter(c, hideUncat, hideFilter, depth + 1);
                if (showChild) {
                    showSomeChild = true;
                }
            })
            if (showSomeChild) {
                canOpen = true;
                hideThis = false;
            }
        }

        node.data.viz.filtered = hideThis;
        node.data.viz.canOpen = canOpen;
        return !hideThis;
    }

    setFilter(filter: number) {
        console.log('setFilter', filter);
        this.filter = filter
        if (!this.data) {
            return
        }
        if (filter && filter >= SUGGESTION_DEMO_FILTERS.length) {
            console.warn(`Cannot apply filter with index ${filter}`)
        } else {
            const hideFilter = SUGGESTION_DEMO_FILTERS[filter];
            // FilteredTaxonomyDemoTableController.applyFilter(this.data, false, hideFilter);
            this.applyFilter(this.data, false, hideFilter);
            this.changed += 1;
        }
    }

    static processTreeData(data: InputData): ExtendedD3HierarchyNode {
        let id = 1;
        const convertData: (node: InputData) => TreeData = (node) => {
            const _id = id++;
            const VISUAL_DATA: TreeData['viz'] = {
                id: _id,
                label: node.label,
                collapsed: false,
                filtered: false,
                canOpen: node.children?.length > 0,
                highlighted: false,
                selected: false,
                childSelected: false,
                hasChangesInChildren: false,
            };

            const result: TreeData = {
                apiValues: node.values ? {...node.values} : {},
                viz: VISUAL_DATA,
                id: node.id,
                sources: node.sources
            } as any;

            // Hotfix for misaligned datastructure
            if (!result.apiValues.review_status) {
                result.apiValues.review_status = (node as any).review_status
            }

            return result;
        };

        let hierarchy = d3.hierarchy(data) as any;
        hierarchy.eachBefore((node: ExtendedD3HierarchyNode) => {
            node._children = [];
            node.data = convertData(node.data as any);
        })

        return hierarchy
    }

    static initNodes(data: InputData): ExtendedD3HierarchyNode {
        if (!data) {
            throw new Error('Data is undefined')
        }
        const tree: ExtendedD3HierarchyNode = FilteredTaxonomyDemoTableController.processTreeData(data);


        tree.descendants().forEach((d: ExtendedD3HierarchyNode, i) => {
            const ed = d as unknown as ExtendedD3HierarchyNode;

            // Each node should have a unique ID
            if (ed.data.viz.id === undefined) {
                ed.data.viz.id = i;
            }

            ed.data.viz.filtered = false;

            if (ed.children) {
                ed.data.viz.collapsed = false;
                ed.data.viz.canOpen = true;
                ed._children = ed.children || [];
            } else {
                ed.data.viz.collapsed = false;
                ed.data.viz.canOpen = false;
            }
        })

        tree.eachBefore(n => {
            // TODO: Update this when the change was accepted/rejected
            const reviewStatus = n.data.apiValues?.review_status;
            if (reviewStatus) {
                n.data.viz.hasChangesInChildren = false;
                const hasChange = reviewStatus.added || reviewStatus.deleted || reviewStatus.renamed || reviewStatus.accepted || reviewStatus.rejected
                if (hasChange) {
                    n.parent?.ancestors().forEach(p => {
                        p.data.viz.hasChangesInChildren = true;
                    })
                }
            }
        })

        return tree
    }

    static setHidden(d: ExtendedD3HierarchyNode, hidden: boolean, depth: number): ExtendedD3HierarchyNode {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }

        return d;
    }

    static exportTreeForPut(node: ExtendedD3HierarchyNode): ApiUpdateSuggestionTreeResponse<Data> & {
        id: Number
    } {
        //Make sure that when updating the suggestion it will not append the review_status to the root but it will do it to the children

        return {
            id: Number(node.id),
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.apiValues,
        }
    }

    private static exportTreeForPutChildren(node: ExtendedD3HierarchyNode): ApiSuggestionTreeResponse<Data> & {
        id: Number
    } {
        return {
            id: node.data.id,
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.apiValues,
            sources: node.data.sources,
        }
    }
}
