import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {useStores} from "../../../../stores";
import {CheckCircle, Create, FilterAlt} from "@mui/icons-material";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {PartReCategorizationHandler} from "../../../../components/categorization/PartRecategorizationHandler";
import {MultiTypedSearchComponent} from "../../../../components/search/MultiTypedSearchComponent";

export const ReviewFilterBar: React.FC = observer(() => {
    const {p, categorizationReviewStore} = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const categories = categorizationReviewStore.categories;
    const viewOnly = categorizationReviewStore.viewOnly;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    const groupBy = reviewPageController.singleMode ? 'parts' : 'suppliers';
    const groupByLabel = 'Group by';

    return <Grid container className="review-filter-bar" spacing={2}>
        {!viewOnly && <Grid item xs={2} alignItems={'center'}>
            <Checkbox
                className="root-selection-component"
                checked={reviewPageSelectionController.selectionState === 'some' || reviewPageSelectionController.selectionState === 'all'}
                onClick={() => reviewPageSelectionController.onClickToggleAllSelected()}
                indeterminate={reviewPageSelectionController.selectionState === 'some'}
            />
            <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                <Button
                    disabled={viewOnly || !reviewPageSelectionController.isAnySelected}
                    onClick={() => reviewPageController.onClickAcceptRejectSelection(ReviewChoice.ACCEPT)}>
                    <CheckCircle/>
                </Button>
                <Button
                    disabled={viewOnly || !reviewPageSelectionController.isAnySelected}
                    onClick={event => {
                        if (!controller) {
                            console.error('No controller set');
                            return;
                        }
                        const anchor = event.currentTarget;
                        controller.setOnSelect(newCats => reviewPageController.onClickReCategorizeSelection(newCats))
                        controller.open(anchor)
                    }}>
                    <Create/>
                </Button>
                {controller && <PartReCategorizationHandler controller={controller} label="Change the category"/>}
            </ButtonGroup>
        </Grid>}

        <Grid item xs={1}>
            {!p.p.hideGroupBySupplier && <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{groupByLabel}</InputLabel>
                <Select
                    labelId="review-filter-bar-taxonomy-dropdown-label"
                    id="review-filter-bar-taxonomy-dropdown"
                    label={groupByLabel}
                    onChange={(event) => {
                        const singleMode = event.target.value === 'parts'
                        reviewPageController.setSingleMode(singleMode)
                    }}
                    value={groupBy}
                >
                    <MenuItem value={'suppliers'}>{p.supplierDataName}</MenuItem>
                    <MenuItem value={'parts'}>None</MenuItem>
                </Select>
            </FormControl>}
        </Grid>

        <Grid item xs={groupBy === 'parts' ? 5 : 3}>
            {groupBy === 'parts'
                ? <MultiTypedSearchComponent
                    id="part-search"
                    manager={reviewPageFilterController.partsSearchManager}
                />
                : <MultiTypedSearchComponent
                    id="supplier-search"
                    manager={reviewPageFilterController.suppliersSearchManager}
                />
            }
        </Grid>

        <Grid item xs={3}></Grid>

        {reviewPageController.singleMode && <Grid item xs={1}>
            <Button
                endIcon={<FilterAlt/>}
                variant={reviewPageFilterController.isAdvancedFilterEnabled ? 'contained' : 'outlined'}
                onClick={() => reviewPageFilterController.setAdvancedFilterDialogOpen(true)}>
                Filter
            </Button>
        </Grid>}
    </Grid>

})
