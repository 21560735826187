import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores, useStores} from "../../../../stores";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import {CURRENCY_COLUMNS} from "./columns";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {KoiFacade} from "../../../../components/koi-facade/KoiFacade";
import {useNavigate} from "react-router-dom";
import {MithraTablePaginationActions} from "../../../../components/table-pagination/MithraTablePaginationActions";

export const CurrencyTable: React.FC = observer(() => {
    const {p} = useStores();
    const {ccStore, sitApi} = useSitStores();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(25);
    const [sourceCurrencyId, setSourceCurrencyId] = useState(0);
    const [targetCurrencyId, setTargetCurrencyId] = useState(0);
    const [conversionRate, setConversionRate] = useState('1');

    useEffect(() => {
        if (p.currencySymbol) {
            if (sourceCurrencyId === 0)
                setSourceCurrencyId(ccStore.getCurrencyId(p.currencySymbol));
            if (targetCurrencyId === 0)
                setTargetCurrencyId(ccStore.getCurrencyId(p.currencySymbol));
        }
    }, []);

    return <KoiFacade
        title={<Typography variant="h1" component="h1">Conversion rates</Typography>}
        onBack={() => navigate(-1)}
        backClass={""}
        className="conversion-rates-page">
        <Grid container spacing={3} className="currency-table-component">
            <Grid item xs={6}>
                <TableContainer className={'mithra-table x-design currency-table' +
                    (ccStore.conversionRatesRM.busy ? ' loading' : '')}>
                    <Table>
                        <MithraTableHeadColumns columns={CURRENCY_COLUMNS} enableLoading
                                                isLoading={ccStore.conversionRatesRM.busy}/>
                        {ccStore.conversionRatesRM.result && <TableBody>
                            {ccStore.conversionRatesRM.result.filter(
                                (cr, index) => (index >= (page * rowPerPage)) && (index < ((page + 1) * rowPerPage))
                            ).map((row) => <TableRow key={row.id}>
                                <MithraTableCell
                                    c={CURRENCY_COLUMNS[0]}>{row.source.code + ' (' + row.source.name + ')'}</MithraTableCell>
                                <MithraTableCell
                                    c={CURRENCY_COLUMNS[1]}>{row.target.code + ' (' + row.target.name + ')'}</MithraTableCell>
                                <MithraTableCell c={CURRENCY_COLUMNS[2]}>{row.rate}</MithraTableCell>
                                <MithraTableCell
                                    c={CURRENCY_COLUMNS[2]}>{new Date(row.created).toLocaleString()}</MithraTableCell>
                            </TableRow>)}
                        </TableBody>}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={CURRENCY_COLUMNS.length}
                                    count={ccStore.conversionRatesRM.result?.length ?? 0}
                                    rowsPerPage={rowPerPage}
                                    page={page}
                                    onPageChange={(event, page: number) => setPage(page)}
                                    onRowsPerPageChange={(event) => {
                                        setRowPerPage(parseInt(event.target.value));
                                        setPage(0);
                                    }}
                                    ActionsComponent={MithraTablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={6}>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    const convRate = parseFloat(parseFloat(conversionRate).toFixed(6))
                    if (sourceCurrencyId === targetCurrencyId || convRate <= 0 || convRate >= 1e12) return;
                    sitApi.addConversionRate(sourceCurrencyId, targetCurrencyId, convRate).then(() =>
                        ccStore.conversionRatesRM.request()
                    );
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h5" className="mb-3">
                                Add or Update conversion rate
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl required fullWidth margin="dense">
                                <InputLabel id="source-currency-label">From</InputLabel>
                                <Select
                                    labelId="source-currency-label"
                                    id="source-currency"
                                    input={<OutlinedInput label="From"/>}
                                    value={sourceCurrencyId}
                                    onChange={(event) => {
                                        if (typeof event.target.value === 'number')
                                            setSourceCurrencyId(event.target.value);
                                    }}>
                                    {ccStore.currencyRM.result?.map((currency) => {
                                        return <MenuItem
                                            key={currency.id}
                                            value={currency.id}>{currency.code}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl required fullWidth margin="dense">
                                <InputLabel id="target-currency-label">To</InputLabel>
                                <Select
                                    labelId="target-currency-label"
                                    id="target-currency"
                                    input={<OutlinedInput label="To"/>}
                                    value={targetCurrencyId}
                                    onChange={(event) => {
                                        if (typeof event.target.value === 'number')
                                            setTargetCurrencyId(event.target.value);
                                    }}>
                                    {ccStore.currencyRM.result?.map((currency) => {
                                        return <MenuItem
                                            key={currency.id}
                                            value={currency.id}>{currency.code}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="rate-input"
                                    variant="outlined"
                                    label="Rate"
                                    required
                                    value={conversionRate}
                                    onChange={(event) => setConversionRate(event.target.value)}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" type="submit" className="mt-3">
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    </KoiFacade>
});
