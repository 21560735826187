import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import React from "react";
import {Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {Clear, FilterAlt, Search} from "@mui/icons-material";
import {DateRangePicker} from "../../../components/visualization/date-range-picker/DateRangePicker";

export const GoldenRecordFilterComponent = observer(() => {
    const {goldenRecordStore} = useStores();

    const searchTitle = 'Search transactions'

    const supplierSearch = goldenRecordStore.filterController.supplierSearch;
    return <section className="golden-record-filter-component">
        <Grid container justifyContent="space-between">
            <Grid item>
                <Grid container justifyContent="flex-start" spacing={4}>
                    <Grid item>
                        <FormControl variant="standard" fullWidth={true} className="search-field">
                            <InputLabel htmlFor="supplier-search-field">
                                {searchTitle}
                            </InputLabel>
                            <Input
                                // label={searchTitle}
                                id="supplier-search-field"
                                type="text"
                                fullWidth={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear search"
                                            onClick={() => supplierSearch.clearSearch()}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => supplierSearch.doSearch('click')}
                                        >
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={supplierSearch.searchString}
                                onChange={e => supplierSearch.setSearch(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' ? supplierSearch.doSearch('enter') : undefined}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <DateRangePicker dateFilterChanged={(startDate, endDate) => {
                            console.log('dateFilterChanged', startDate, endDate);
                        }}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justifyContent="flex-end" spacing={4}>
                    <Grid item>
                        <Button
                            endIcon={<FilterAlt/>}
                            variant={goldenRecordStore.filterController.isFilterEnabled ? 'contained' : 'outlined'}
                            onClick={() => goldenRecordStore.filterController.setIsFilterModalOpen(true)}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </section>
})
