import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {TextField} from "@mui/material";
import {ApprovalDialog} from "../ApprovalDialog";
import {useNavigate} from "react-router-dom";
import {runInAction} from "mobx";

export const TaxonomyApprovalAcceptDialog: React.FC = observer(() => {
    const navigate = useNavigate();
    const {approvalStore} = useStores();

    const busy = approvalStore.approvalLoader.busy || approvalStore.taxonomyApproval.isBusy;
    return <ApprovalDialog
        className="approval-dialog"
        // title="Should the request be accepted?"
        title="Accept changes to the taxonomy"
        saveTitle={'Accept'}
        onSave={() => {
            approvalStore.taxonomyApproval.acceptTaxonomyApproval(navigate);
        }}
        canSave={!busy}
        onClose={() => approvalStore.clickCancelApprovalDialog()}
        loading={busy}
    >
        {/*<Typography variant="body1" component="p">*/}
        {/*    Mithra will be notified and run the AI algorithm again, this may take a few days to complete*/}
        {/*</Typography>*/}
        <TextField
            fullWidth
            id="taxonomy-approval-feedback-notes"
            label="Reply"
            variant="outlined"
            value={approvalStore.approval?.feedback_notes || ''}
            onChange={e => runInAction(() => {
                if (approvalStore.approval) {
                    approvalStore.approval.feedback_notes = e.target.value;
                }
            })}
            disabled={approvalStore.approvalLoader.busy}
        />
    </ApprovalDialog>
})
