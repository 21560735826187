import React from "react";
import './ImageStyles.scss';

export const RocketImage: React.FC = () =>
    <svg width="230" height="230" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg" className="RocketImage mithra-image">
        <g clipPath="url(#clip0_241_3605)">
            <path
                d="M230 115.558C230.026 143.271 220.129 170.069 202.119 191.057C184.108 212.046 159.186 225.823 131.902 229.874H100.755C100.706 229.917 98.9793 230 98.9793 230C98.6896 229.96 98.3967 229.917 98.1069 229.874C95.8893 229.546 93.692 229.155 91.515 228.7C82.9906 226.927 74.6972 224.176 66.7976 220.501C66.7153 220.464 66.636 220.424 66.5536 220.388C62.1515 218.335 57.8853 215.999 53.7816 213.396C53.6779 213.331 53.5772 213.267 53.4766 213.203C53.3027 213.092 53.1319 212.985 52.961 212.872C52.9061 212.838 52.8512 212.804 52.7963 212.767C49.1275 210.393 45.5965 207.81 42.2206 205.031C42.0559 204.899 41.8912 204.761 41.7264 204.626C41.0645 204.075 40.4086 203.517 39.7589 202.953C39.7528 202.95 39.7498 202.944 39.7437 202.94C36.4236 200.049 33.2695 196.971 30.2966 193.72C30.2874 193.708 30.2752 193.699 30.2661 193.687C17.2066 179.412 7.93956 162.06 3.32191 143.234C3.2853 143.099 3.2548 142.967 3.22124 142.832C1.07556 133.901 -0.00577333 124.745 2.31805e-05 115.558C2.31805e-05 51.7374 51.4877 0 115 0C178.512 0 230 51.7374 230 115.558Z"
                fill="#091344"/>
            <path opacity="0.8"
                  d="M125.631 132.605L135.689 120.774L145.746 108.943L151.424 102.264C151.738 101.894 151.253 101.318 150.939 101.687L140.882 113.519L130.824 125.35L125.147 132.029C124.833 132.398 125.317 132.975 125.631 132.605Z"
                  fill="url(#paint0_linear_241_3605)"/>
            <path d="M128.477 102.871H118.594V229.551H128.477V102.871Z" fill="white"/>
            <path
                d="M125.051 149.652L125.029 153.447L124.776 199.504L124.746 204.994L124.679 217.365L124.612 229.874H123.94L124.008 217.365L124.072 205.809L124.105 199.391L124.358 153.456L124.38 149.649L125.051 149.652Z"
                fill="#0262F2"/>
            <path
                d="M124.883 97.4815L124.866 98.7139L124.661 113.672L124.636 115.455L124.582 119.473L124.527 123.535H123.984L124.039 119.473L124.091 115.72L124.118 113.635L124.323 98.7169L124.34 97.4805L124.883 97.4815Z"
                fill="#0262F2"/>
            <rect x="132" y="116.5" width="2" height="126" fill="#D1D6DD"/>
            <path
                d="M130.273 104.668H115.943C115.735 102.557 116.252 100.431 117.585 98.2874L117.99 95.6836H127.885L128.352 98.2544C129.738 99.9915 130.275 102.185 130.273 104.668Z"
                fill="white"/>
            <path
                d="M123.086 82.207C122.133 82.2081 121.219 82.5874 120.545 83.2615C119.871 83.9356 119.492 84.8494 119.492 85.8023V89.3945H126.68V85.8023C126.679 84.8494 126.301 83.9356 125.627 83.2615C124.953 82.5874 124.039 82.2081 123.086 82.207Z"
                fill="white"/>
            <path d="M118.594 190.02L94.3359 220.71L97.6908 221.465L116.529 197.818H118.594V190.02Z" fill="#D1D6DD"/>
            <path d="M128.477 190.02L151.836 220.71L148.605 221.465L130.465 197.818H128.477V190.02Z" fill="white"/>
            <path d="M198.087 75.1738V195.452C190.666 203.243 182.201 209.957 172.937 215.4V75.1738H198.087Z"
                  fill="#0262F2"/>
            <path d="M187.416 35.0964H183.605V78.4937H187.416V35.0964Z" fill="#0262F2"/>
            <g opacity="0.4">
                <path d="M175.072 75.1738H172.937V215.4C173.654 214.979 174.366 214.549 175.072 214.111V75.1738Z"
                      fill="white"/>
                <path d="M185.741 35.0964H183.605V75.1739H185.741V35.0964Z" fill="white"/>
            </g>
            <path d="M191.608 94.9592H177.89V102.618H191.608V94.9592Z" fill="white"/>
            <path
                d="M34.1406 95.6836C51.0112 95.6836 64.6875 81.8062 64.6875 64.6875C64.6875 47.5688 51.0112 33.6914 34.1406 33.6914C17.2701 33.6914 3.59375 47.5688 3.59375 64.6875C3.59375 81.8062 17.2701 95.6836 34.1406 95.6836Z"
                fill="#F6F6F6"/>
            <path
                d="M31.4453 47.168C33.4301 47.168 35.0391 45.559 35.0391 43.5742C35.0391 41.5894 33.4301 39.9805 31.4453 39.9805C29.4605 39.9805 27.8516 41.5894 27.8516 43.5742C27.8516 45.559 29.4605 47.168 31.4453 47.168Z"
                fill="#D1D6DD"/>
            <path
                d="M37.2852 82.207C39.0218 82.207 40.4297 80.598 40.4297 78.6133C40.4297 76.6285 39.0218 75.0195 37.2852 75.0195C35.5485 75.0195 34.1406 76.6285 34.1406 78.6133C34.1406 80.598 35.5485 82.207 37.2852 82.207Z"
                fill="#D1D6DD"/>
            <path
                d="M50.7617 61.543C52.0022 61.543 53.0078 60.3362 53.0078 58.8477C53.0078 57.3591 52.0022 56.1523 50.7617 56.1523C49.5212 56.1523 48.5156 57.3591 48.5156 58.8477C48.5156 60.3362 49.5212 61.543 50.7617 61.543Z"
                fill="#D1D6DD"/>
            <path
                d="M22.4609 75.0195C26.9267 75.0195 30.5469 71.3993 30.5469 66.9336C30.5469 62.4679 26.9267 58.8477 22.4609 58.8477C17.9952 58.8477 14.375 62.4679 14.375 66.9336C14.375 71.3993 17.9952 75.0195 22.4609 75.0195Z"
                fill="#D1D6DD"/>
            <path
                d="M76.8742 108.137C77.4767 108.137 77.9651 107.646 77.9651 107.041C77.9651 106.435 77.4767 105.944 76.8742 105.944C76.2716 105.944 75.7832 106.435 75.7832 107.041C75.7832 107.646 76.2716 108.137 76.8742 108.137Z"
                fill="white"/>
            <path
                d="M86.3299 122.943L85.7854 123.486L85.2446 122.939L84.8816 123.302L85.4224 123.849L84.8779 124.392L85.2385 124.757L85.783 124.213L86.3238 124.761L86.6868 124.398L86.146 123.851L86.6905 123.308L86.3299 122.943Z"
                fill="white"/>
            <path
                d="M90.6942 91.1511L90.1497 91.6946L89.6088 91.1475L89.2458 91.5098L89.7867 92.0569L89.2422 92.6004L89.6027 92.9651L90.1472 92.4217L90.6881 92.9688L91.0511 92.6065L90.5102 92.0594L91.0547 91.5159L90.6942 91.1511Z"
                fill="white"/>
            <path
                d="M104.408 30.8223C103.23 31.611 102.025 29.7337 103.225 28.98C104.403 28.1913 105.609 30.0686 104.408 30.8223Z"
                fill="white"/>
            <path
                d="M36.1651 112.409L35.4156 112.244L35.5797 111.491L35.0801 111.381L34.9156 112.134L34.1662 111.969L34.0566 112.471L34.8061 112.636L34.642 113.39L35.1417 113.5L35.3061 112.746L36.0556 112.911L36.1651 112.409Z"
                fill="white"/>
            <path
                d="M150.859 21.986L150.11 21.8207L150.274 21.0673L149.774 20.9573L149.61 21.7107L148.86 21.5458L148.751 22.0479L149.5 22.2131L149.336 22.9662L149.836 23.0762L150 22.3228L150.75 22.488L150.859 21.986Z"
                fill="white"/>
            <path
                d="M209.427 70.7225L208.677 70.5573L208.841 69.8039L208.342 69.6938L208.177 70.4473L207.428 70.2824L207.318 70.7844L208.068 70.9497L207.904 71.7028L208.403 71.8128L208.568 71.0594L209.317 71.2246L209.427 70.7225Z"
                fill="white"/>
            <path
                d="M67.8587 33.8724L67.1093 33.7072L67.2734 32.954L66.7737 32.844L66.6093 33.5971L65.8595 33.4322L65.75 33.9343L66.4998 34.0995L66.3357 34.8529L66.8353 34.9627L66.9997 34.2095L67.7492 34.3744L67.8587 33.8724Z"
                fill="white"/>
            <path
                d="M138.897 58.8908C139.499 58.8908 139.988 58.4 139.988 57.7945C139.988 57.1891 139.499 56.6982 138.897 56.6982C138.294 56.6982 137.806 57.1891 137.806 57.7945C137.806 58.4 138.294 58.8908 138.897 58.8908Z"
                fill="white"/>
            <path
                d="M130.727 74.4008L129.977 74.2355L130.141 73.4821L129.642 73.3721L129.477 74.1255L128.728 73.9606L128.618 74.4627L129.368 74.6279L129.204 75.381L129.703 75.491L129.868 74.7376L130.617 74.9028L130.727 74.4008Z"
                fill="white"/>
            <path
                d="M159.676 60.846L158.927 60.6808L159.091 59.9277L158.591 59.8176L158.427 60.5707L157.677 60.4058L157.567 60.9079L158.317 61.0731L158.153 61.8266L158.653 61.9363L158.817 61.1832L159.567 61.3481L159.676 60.846Z"
                fill="white"/>
            <path opacity="0.8"
                  d="M109.16 67.0101L119.217 55.1789L129.274 43.3477L134.952 36.6688C135.266 36.2993 134.781 35.7228 134.467 36.0923L124.41 47.9235L114.353 59.7547L108.675 66.4336C108.361 66.8031 108.846 67.3797 109.16 67.0101Z"
                  fill="url(#paint1_linear_241_3605)"/>
            <path
                d="M157.059 217.365V223.143C148.956 226.343 140.515 228.601 131.902 229.874H113.142H94.382C93.4211 229.7 92.4633 229.513 91.5146 229.313V217.365H157.059Z"
                fill="#FAA915"/>
            <path
                d="M124.777 199.504C124.563 199.468 124.338 199.431 124.106 199.391C122.742 199.17 121.098 198.919 119.213 198.658C119.085 198.64 118.954 198.622 118.82 198.603C117.264 198.392 115.553 198.177 113.707 197.966C103.589 196.822 89.4564 195.906 75.0341 197.166C75.3911 195.725 75.7205 194.284 76.0316 192.85L62.2194 194.999L77.1237 187.382C78.5277 179.556 79.4931 171.658 80.0154 163.724L72.5542 164.883L80.1832 160.984C80.7292 151.166 80.473 144.631 80.473 144.631C80.473 144.631 55.5177 157.668 35.562 177.591C34.8299 174.998 34.0276 172.439 33.1735 169.925L22.4544 178.937L31.2914 164.678C28.47 157.249 25.2335 149.986 21.5973 142.924L15.8076 147.791L20.3344 140.49C15.7527 131.8 12.1715 126.338 12.1715 126.338C12.1715 126.338 8.08706 133.063 3.22168 143.445C7.8102 162.439 17.1269 179.95 30.297 194.333C33.2742 197.589 36.4335 200.671 39.7593 203.566C40.4091 204.13 41.0649 204.688 41.7269 205.239C45.2525 208.172 48.949 210.89 52.7967 213.381C53.0225 213.528 53.2482 213.672 53.477 213.816C65.1232 221.243 78.01 226.494 91.5154 229.313C92.4641 229.513 93.4219 229.7 94.3828 229.874C94.6177 229.917 94.8526 229.96 95.0875 230H98.9798C98.9798 230 100.706 229.917 100.755 229.874C105.59 225.623 109.973 221.325 113.753 217.365C114.613 216.467 115.44 215.584 116.236 214.726L109.714 210.904L118.084 212.706C118.469 212.28 118.847 211.86 119.213 211.449C121.092 209.341 122.721 207.431 124.072 205.809C124.304 205.527 124.53 205.258 124.746 204.994C127.208 202.009 128.563 200.188 128.563 200.188C128.563 200.188 127.202 199.909 124.777 199.504Z"
                fill="#08A57A"/>
            <path
                d="M185.007 38.4683C186.86 38.4683 188.362 36.9587 188.362 35.0966C188.362 33.2344 186.86 31.7249 185.007 31.7249C183.154 31.7249 181.651 33.2344 181.651 35.0966C181.651 36.9587 183.154 38.4683 185.007 38.4683Z"
                fill="#FF3435"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M115.943 104.668H120.391V95.6836H117.99L117.585 98.2874C116.252 100.431 115.735 102.557 115.943 104.668Z"
                  fill="#D1D6DD"/>
            <path
                d="M127.841 96.582H118.331C118.235 96.582 118.14 96.5599 118.054 96.5172C117.967 96.4745 117.891 96.4124 117.832 96.3356C117.772 96.2589 117.731 96.1693 117.71 96.0738C117.689 95.9783 117.69 95.8793 117.713 95.7842L119.314 88.992C119.348 88.8507 119.427 88.7249 119.539 88.635C119.651 88.545 119.79 88.4961 119.933 88.4961H126.239C126.382 88.4961 126.521 88.545 126.633 88.635C126.745 88.7249 126.824 88.8507 126.857 88.992L128.459 95.7842C128.481 95.8793 128.482 95.9783 128.462 96.0738C128.441 96.1693 128.4 96.2589 128.34 96.3356C128.281 96.4124 128.205 96.4745 128.118 96.5172C128.032 96.5599 127.937 96.582 127.841 96.582Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M118.331 96.582H127.841C127.937 96.582 128.032 96.5599 128.118 96.5172C128.205 96.4745 128.281 96.4124 128.34 96.3356C128.4 96.2589 128.441 96.1693 128.462 96.0738C128.482 95.9783 128.481 95.8793 128.459 95.7842L126.857 88.992C126.824 88.8507 126.745 88.7249 126.633 88.635C126.521 88.545 126.382 88.4961 126.239 88.4961H119.933C119.79 88.4961 119.651 88.545 119.539 88.635C119.427 88.7249 119.348 88.8507 119.314 88.992L117.713 95.7842C117.69 95.8793 117.689 95.9783 117.71 96.0738C117.731 96.1693 117.772 96.2589 117.832 96.3356C117.891 96.4124 117.967 96.4745 118.054 96.5172C118.14 96.5599 118.235 96.582 118.331 96.582Z"
                  fill="#D1D6DD"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M120.545 83.2617C121.219 82.5876 122.133 82.2084 123.086 82.2073C124.039 82.2084 124.953 82.5876 125.627 83.2617C126.301 83.9358 126.679 84.8497 126.68 85.8026V89.3948H119.492V85.8026C119.492 84.8497 119.871 83.9358 120.545 83.2617Z"
                  fill="#D1D6DD"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_241_3605" x1="125.045" y1="117.146" x2="151.525" y2="117.146"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0.3"/>
            </linearGradient>
            <linearGradient id="paint1_linear_241_3605" x1="108.573" y1="51.5512" x2="135.054" y2="51.5512"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0.3"/>
            </linearGradient>
            <clipPath id="clip0_241_3605">
                <path d="M0 0H230V115C230 178.513 178.513 230 115 230C51.4873 230 0 178.513 0 115V0Z" fill="white"/>
            </clipPath>
        </defs>
    </svg>
