import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TextField
} from "@mui/material";
import {useStores} from "../../../../stores";
import {ParentSupplierChoice, SupplierReviewRow} from "../SupplierNormalization.classes";
import {Delete, Undo} from "@mui/icons-material";
import {NoAiIcon} from "../../../../components/icons/NoAiIcon";

const filter = createFilterOptions<ParentSupplierChoice>();

type Props = {
    parentSupplierChoice: ParentSupplierChoice,
    supplier?: SupplierReviewRow,
    label: string,
};
export const ParentSupplierChooser: React.FC<Props> = observer(({parentSupplierChoice, supplier, label}) => {
    const {supplierNormalizationStore, authStore} = useStores();
    const [searchTerm, setSearchTerm] = useState(parentSupplierChoice.sp_name);
    const [options, setOptions] = useState<ParentSupplierChoice[]>([]);
    const [open, setOpen] = useState(false);
    const [dialogValue, setDialogValue] = React.useState<ParentSupplierChoice>({
        id: 0, sp_id: '', sp_name: '', sp_n_suppliers: 0, ai_suggested: false
    });

    useEffect(() => {
        setOptions((supplierNormalizationStore.parentSupplierSearchRM.result?.results ?? []));
    }, [supplierNormalizationStore.parentSupplierSearchRM.result?.results])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (supplier === undefined)
            // Update parent supplier (creates a new parent supplier in BE
            supplierNormalizationStore.updateParentSupplier({
                sp_id: dialogValue.sp_id,
                sp_name: dialogValue.id === 0 ? dialogValue.sp_id : dialogValue.sp_name,
                prev_m_parent_supplier_id: parentSupplierChoice.id
            })
        else
            // Assign parent supplier to supplier
            supplierNormalizationStore.updateSupplier({
                id: supplier!.id, sp_id: dialogValue.sp_id,
                sp_name: dialogValue.id === 0 ? dialogValue.sp_id : dialogValue.sp_name,
                parent_supplier_id: dialogValue.id
            })
        handleClose();
    };

    const handleClose = () => {
        setDialogValue({id: 0, sp_id: '', sp_name: '', sp_n_suppliers: 0, ai_suggested: false});
        setOpen(false);
        setSearchTerm(parentSupplierChoice.sp_name)
    };

    return <>
        <Grid container alignItems="center">
            <Grid item flexGrow="1">
                <Autocomplete
                    selectOnFocus
                    onSelect={() => {
                        if (supplier === undefined)
                            supplierNormalizationStore.setSelectedRowId(parentSupplierChoice.id);
                    }}
                    clearOnBlur
                    handleHomeEndKeys
                    freeSolo
                    size="small"
                    disabled={authStore.viewOnly}
                    value={parentSupplierChoice}
                    options={options}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '' && !supplierNormalizationStore.parentSupplierSearchRM.busy) {
                            filtered.splice(0, 0, {
                                id: 0, sp_id: params.inputValue, sp_name: `Add "${params.inputValue}"`,
                                sp_n_suppliers: 0, ai_suggested: false,
                            });
                        }
                        return filtered;
                    }}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') return option;
                        return option.sp_name;
                    }}
                    renderOption={(props, option) =>
                        <Box {...props} component="li">
                            {!option.ai_suggested && <NoAiIcon/>}
                            {option.sp_name}
                        </Box>
                    }
                    onChange={(event, newValue, reason) => {
                        if (event.type === 'keydown' && (event as any as KeyboardEvent).code === 'Enter') return;
                        switch (reason) {
                            case 'clear':
                                supplierNormalizationStore.parentSupplierSearchFilters.setFilters([['search', '']]);
                                break;
                            case 'selectOption':
                                if (newValue !== null && typeof newValue !== 'string')
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                        setOpen(true);
                                        setDialogValue(newValue);
                                    });
                                break;
                        }
                    }}
                    inputValue={searchTerm}
                    onInputChange={(_, value, reason) => {
                        switch (reason) {
                            case 'input':
                                supplierNormalizationStore.parentSupplierSearchFilters.setFilters([['search', value]])
                                setSearchTerm(value);
                                break
                            case 'reset':
                                // Do not reset the value
                                break;
                            case 'clear':
                                supplierNormalizationStore.parentSupplierSearchFilters.setFilters([['search', '']])
                                setSearchTerm('');
                                break;
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} label={label + (authStore.viewOnly ? ' (view only)' : '')} InputProps={
                            // CAT-1984: To display a supplier with NoAiIcon, user expects to see two conditions:
                            //      1. If the parent supplier has come from AI job
                            //      2. If assignment of parent supplier to supplier is performed by a user or AI
                            (searchTerm === parentSupplierChoice.sp_name && (
                                supplier ? (!supplier.ai_suggested || supplier.review_user_id !== null) :
                                    !parentSupplierChoice.ai_suggested
                            )) ?
                                {
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <NoAiIcon/>
                                        </InputAdornment>
                                    )
                                } : {...params.InputProps}
                        }/>
                    }
                />
            </Grid>
            <Grid item>
                {parentSupplierChoice.sp_n_suppliers > 1 && <IconButton
                    aria-label="Remove" title="Remove"
                    onClick={() => {
                        if (supplier === undefined)
                            supplierNormalizationStore.deleteParentSupplier(parentSupplierChoice.id);
                        else
                            supplierNormalizationStore.deleteSupplier(supplier.id);
                    }}>
                    <Delete/>
                </IconButton>}
                {parentSupplierChoice.sp_n_suppliers === 0 && <IconButton
                    aria-label="Restore" title="Restore"
                    onClick={() => setSearchTerm(parentSupplierChoice.sp_name)}>
                    <Undo/>
                </IconButton>}
            </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Confirm Parent Supplier Change</DialogTitle>
                <DialogContent>
                    {supplier === undefined && 'Are you sure you want to change the parent supplier? ' +
                        'This action will also affect all associated child suppliers.'}
                    {supplier !== undefined && 'Are you sure you want to change parent supplier of this supplier?'}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">Change parent supplier</Button>
                </DialogActions>
            </form>
        </Dialog>
    </>;
})
