// 'request_create_review' = ai run finished, 'live' = the job is closed and can't be changed
import {User} from "./User";
import {m_taxonomy} from "./TaxonomyClasses";

export enum AiCategorizationJobStatusEnum {
    ERROR = 'error',
    INIT = 'init',
    VERTEX_AI = 'vertex_ai',
    REQUEST_CREATE_REVIEW = 'request_create_review',
    CREATING_REVIEW = 'creating_review',
    REVIEW = 'review',
    REQUEST_CREATE_LIVE = 'request_create_live',
    CREATING_LIVE = 'creating_live',
    LIVE = 'live',
}

export const CATEGORIZATION_STATUS_LIST: AiCategorizationJobStatusEnum[] = [
    AiCategorizationJobStatusEnum.INIT,
    AiCategorizationJobStatusEnum.VERTEX_AI,
    AiCategorizationJobStatusEnum.REQUEST_CREATE_REVIEW,
    AiCategorizationJobStatusEnum.CREATING_REVIEW,
    AiCategorizationJobStatusEnum.REVIEW,
    AiCategorizationJobStatusEnum.REQUEST_CREATE_LIVE,
    AiCategorizationJobStatusEnum.CREATING_LIVE,
    AiCategorizationJobStatusEnum.LIVE,
    AiCategorizationJobStatusEnum.ERROR,
]

export function getStatusIsFinal(status: AiCategorizationJobStatusEnum): boolean {
    switch (status) {
        case AiCategorizationJobStatusEnum.ERROR:
        case AiCategorizationJobStatusEnum.LIVE:
            return true;
        default:
            return false;
    }
}

export type AiCategorizationJobSerializer = {
    id: number
    src_data_bag: number
    created: string
    ai_task: string

    request_user: User

    src_mat_taxonomy: number
    current_status: {
        timestamp: string
        status: AiCategorizationJobStatusEnum
        status_name: string
    }
}
export type AiCategorizationJobSimpleReadSerializer = {
    id: number
    created: string
    current_status: AiCategorizationJobSerializer['current_status']
    taxonomy: m_taxonomy.SimpleSerializer
    gcp_vertex_link: string | '' | null // Only for staff
    gcp_cloudrun_link: string | '' | null // Only for staff
    gcp_cloudrunjob_link: string | '' | null // Only for staff
    current_revision_id: number | null // Only for staff
    current_revision_is_rejected: boolean | null // Only for staff
}

export type UserCategorizationJobRequestEnum = 'categorize_all' | 'categorize_dummy'

export type AiCategorizationJobCreateSerializer = {
    src_data_bag: number
    requested_task: UserCategorizationJobRequestEnum

    vai_pipeline_template_name?: string
    vai_region?: string

    /**
     * For testing purposes only
     */
    no_trigger?: boolean
}
