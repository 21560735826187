import React from "react";
import {observer} from "mobx-react-lite";
import {
    Alert, Autocomplete, Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography
} from "@mui/material";
import {useSitStores} from "../../../../stores";
import {Add, Delete, Info} from "@mui/icons-material";
import PercentIcon from '@mui/icons-material/Percent';
import {Category, category_readable_str, Company} from "../../../ApiTypes";

export const InitiativeSplitForm: React.FC = observer(() => {
    const {initiativeInstanceStore, ccStore} = useSitStores();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <hr/>
        </Grid>

        <Grid item xs={12}>
            <Typography variant="h3" component="h3">
                Saving Split&nbsp;
                <Tooltip
                    title="For scope of multiple companies or categories, the savings need to be distributed among the participants">
                    <Info fontSize="small"/>
                </Tooltip>
            </Typography>
        </Grid>

        {initiativeInstanceStore.w_no_split_companies && <Grid item xs={12}>
            <Alert severity="warning" color="error">
                Saving split is missing for companies:&nbsp;
                {initiativeInstanceStore.w_no_split_companies.map(c_id =>
                    ccStore.getCompanyTitle(c_id)
                ).join(', ')}.
                If not provided, mentioned companies would not be included in this initiative.
            </Alert>
        </Grid>}

        {initiativeInstanceStore.w_no_split_categories && <Grid item xs={12}>
            <Alert severity="warning" color="error">
                Saving split is missing for categories:&nbsp;
                {initiativeInstanceStore.w_no_split_categories.map(c_id =>
                    ccStore.getCategoryReadableStr(c_id)
                ).join(', ')}.
                If not provided, mentioned categories would not be included in this initiative.
            </Alert>
        </Grid>}

        {(initiativeInstanceStore.w_saving_split_sum !== undefined) && <Grid item xs={12}>
            <Alert severity="warning" color="error">
                Sum of savings percentages is not 100: {initiativeInstanceStore.w_saving_split_sum}
            </Alert>
        </Grid>}

        <Grid item xs={12}>
            {initiativeInstanceStore.f_c.filter((_f_c, i) => i > 0).map((f_c, index) =>
                <Grid container spacing={1} key={index}>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <Autocomplete
                                disabled={initiativeInstanceStore.disabled}
                                multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                id="company"
                                limitTags={1}
                                options={initiativeInstanceStore.f_c[0].companyValues ?? ([] as Company[])}
                                getOptionLabel={(option) => option.title}
                                value={f_c.companyValues}
                                disableCloseOnSelect
                                renderInput={(params) =>
                                    <TextField {...params} variant="outlined" label="Company" multiline={false}
                                               placeholder={f_c.companyValues.length > 0 ? "" : "Select Company"}/>
                                }
                                renderOption={(props, option) =>
                                    <li key={option.id} {...props}>
                                        <Checkbox
                                            checked={(f_c.instance.companies?.indexOf(option.id) ?? -1) > -1}/>
                                        {option.title}
                                    </li>}
                                onChange={(_event, value) =>
                                    f_c.companies = value.map((company) => company.id)
                                }/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <Autocomplete
                                disabled={initiativeInstanceStore.disabled}
                                multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                id="category"
                                limitTags={1}
                                options={initiativeInstanceStore.f_c[0].categoryValues ?? ([] as Category[])}
                                getOptionLabel={(option) => category_readable_str(option)}
                                value={f_c.categoryValues}
                                disableCloseOnSelect
                                renderInput={(params) =>
                                    <TextField {...params} variant="outlined" label="Category" multiline={false}
                                               placeholder={f_c.companyValues.length > 0 ? "" : "Select Categories"}/>
                                }
                                renderOption={(props, option) =>
                                    <li key={option.id} {...props}>
                                        <Checkbox
                                            checked={(f_c.instance.categories?.indexOf(option.id) ?? -1) > -1}/>
                                        {category_readable_str(option)}
                                    </li>}
                                onChange={(_event, value) =>
                                    f_c.categories = value.map((category) => category.id)
                                }/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled={initiativeInstanceStore.disabled}
                                id="saving_split_percentage"
                                variant="outlined"
                                label="Saving Percentage"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <PercentIcon/>
                                    </InputAdornment>
                                }}
                                value={f_c.percentage}
                                onChange={(event) =>
                                    f_c.percentage = parseFloat(event.target.value) || 0
                                }/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            disabled={initiativeInstanceStore.disabled}
                            className="mt-3 w-100"
                            onClick={() => initiativeInstanceStore.removeFinancial(f_c.index)}>
                            <Delete/>
                        </IconButton>
                    </Grid>
                </Grid>
            )}
        </Grid>

        <Grid item xs={12}>
            <Button
                disabled={initiativeInstanceStore.disabled}
                fullWidth
                variant="outlined"
                startIcon={<Add/>}
                onClick={() => initiativeInstanceStore.addFinancial()}
            >New Saving Split</Button>
        </Grid>
    </Grid>
});