import {ApprovalRequest, ApprovalStatusEnum} from "./AiClasses";
import {UserDisplaySerializer} from "./User";

type sTimestamp = string;

export namespace m_taxonomy {
    export type Data = {
        category_id?: string
        p__id__count: number
        p__spend__sum: number
        s__id__nunique: number
        description: string | undefined

        // For the approval process:
        // @see approval.taxonomy.views.TaxonomyApprovalRequestViewSet.apply_taxonomy_only
        // @see taxonomy.tree.TaxonomyTreeDataStructure.remove_specific_value_fields
        authors?: number[]
        changes?: Change[]
        approval_note?: string
        approval_feedback_note?: string
        approval_touched?: true
    }

    export type ApproverData = Data & {}

    export type BaseChangeType = { reverted?: boolean }
    export type Change = BaseChangeType & (
        { type: 'added' }
        | { type: 'removed' }
        | { type: 'renamed', original_label: string }
        | { type: 'moved', original_location: string[] }
        | { type: 'updated', field: string, original_value: string }
        )
    export type ChangeType = Change['type'];

    export const CHANGE_ORDER: ChangeType[] = [
        'removed',
        'added',
        'moved',
        'renamed',
        'updated',
    ];

    /**
     * Response of the API
     */
    export type Tree<D = Data> = {
        id: number
        label: string
        values: D
        /**
         * Denotes the original IDs of the source category of the parts.
         */
        sources: number[]
        removed: boolean
        children: Tree<D>[]
    }

    export type SimpleSerializer = {
        id: number
        name: string
        size: number
        current_operation_number: number
        next_node_id: number
        current_date: string
    }
    export type UpdateSerializer = {
        name: string
        size: number
    }
    export type FullSerializer = {
        id: number
        name: string
        size: number
        current_operation_number: number
        next_node_id: number
        current_date: string
        result_of_approval: null | ApprovalRequest
        active_approval_request: null | ApprovalRequest
        diff_tree: Tree
        has_taxonomy_health_check: boolean
    }
    export type SimpleTaxonomySerializer = {
        id: number
        name: string
        hidden: boolean
        size: number
        current_operation_number: number
        next_node_id: number
        created_at: string
        current_date: string
        is_submitted_for_approval: boolean
        result_of_approval: number | null
    }
    export type CreateTaxonomyOperationSerializer = {
        operation_number: number
        next_node_id: number
        operation_name: _Operation
        state: Tree
    }
    export type SimpleTaxonomyOperationSerializer = {
        author: UserDisplaySerializer | null
        operation_number: number
        next_node_id: number
        operation_name: _Operation
        date: string
    }
    export type GotoHistorySerializer = {
        goto_history_number: number
    }

    export enum Operation {
        Add,
        Move,
        Update,
        Delete,
        Merge,
        Suggestion,
        HealthCheck,
        Review,
    }

    type _Operation = number
    export type TaxonomyHistory = SimpleTaxonomyOperationSerializer

    export type ChangeLogFields = 'label' | 'description';
    export type NodeResult = {
        node_id: number
        labels: string[]
        data: {
            values: Data,
        }
    }
    export type MergeNodeResult = NodeResult & {
        sources: NodeResult[]
    }
    export type MoveNodeResult = NodeResult & {
        old_labels: string[]
    }
    export type EditNodeResult = NodeResult & {
        changes: { field: ChangeLogFields, old_value: any, new_value: any }
    }
    export type ChangeLogResult = {
        created: NodeResult[]
        merged: MergeNodeResult[]
        moved: MoveNodeResult[]
        edited: EditNodeResult[]
        deleted: NodeResult[]
    }

    export type MaterializedTaxonomyData = {
        id: number // materialized_category_id
        lifecycle_cat_id: number
        customer_cat_id: string
    }

    export type MaterializedTaxonomyTree = {
        label: string
        children: MaterializedTaxonomyTree[]
        values: MaterializedTaxonomyData
    }

    export type MaterializedTaxonomy = {
        id: number
        src_taxonomy: number
        name: string
        created: sTimestamp
        n_leafs: number
        n_nodes: number
        tree_state: MaterializedTaxonomyTree
    }

    export type CreateMaterializedCategoryMap = {
        src_lifecycle_cat_id: number,
        src_mat_taxonomy: number,
        dst_lifecycle_cat_id: number,
        dst_mat_taxonomy: number,
    }

    export type CreateMaterializedCategoryMap2 = {
        src_mat_category: number,
        dst_mat_category: number,
    }

    export type MaterializedCategoryMap = {
        id: number // not used
        ai_suggestion: boolean
        user_suggestion: boolean
        user: number | null
        created: sTimestamp
        modified: sTimestamp
        src_mat_category: number,
        source_category_lifecycle_id: number,
        src_mat_taxonomy: number,
        source_l1: string,
        source_l2: string,
        source_l3: string,
        source_l4: string,
        source_l5: string,
        source_l6: string,
        source_l7: string,
        source_l8: string,
        dst_mat_category: number,
        destination_category_lifecycle_id: number,
        dst_mat_taxonomy: number,
        destination_l1: string,
        destination_l2: string,
        destination_l3: string,
        destination_l4: string,
        destination_l5: string,
        destination_l6: string,
        destination_l7: string,
        destination_l8: string
    }

    export type SetDatabagForTaxonomy = {
        taxonomy: number
    }

    export function sortKey(m: MaterializedCategoryMap): string {
        return m.source_l1 + '|' +
            m.source_l2 + '|' +
            m.source_l6 + '|' +
            m.source_l3 + '|' +
            m.source_l4 + '|' +
            m.source_l5 + '|' +
            m.source_l7 + '|' +
            m.source_l8 + '|' +
            m.ai_suggestion + '|' +
            m.destination_l1 + '|' +
            m.destination_l2 + '|' +
            m.destination_l6 + '|' +
            m.destination_l3 + '|' +
            m.destination_l4 + '|' +
            m.destination_l5 + '|' +
            m.destination_l7 + '|' +
            m.destination_l8;
    }

    export function canEditTaxonomy(taxApprovalStatus?: ApprovalStatusEnum) {
        if (!taxApprovalStatus) {
            // There is no approval, so it's safe to edit this taxonomy
            return true;
        }
        switch (taxApprovalStatus) {
            case ApprovalStatusEnum.APPROVED:
            case ApprovalStatusEnum.REJECTED:
                // Continue editing
                return true;
            default:
                // Wait for the system or Mithra to complete
                return false;
        }
    }

    export function isReadOnly(taxApprovalStatus?: ApprovalStatusEnum): boolean {
        if (!taxApprovalStatus) {
            // There is no approval, so it's safe to edit this taxonomy
            return true;
        }
        switch (taxApprovalStatus) {
            case ApprovalStatusEnum.PENDING:
                return false;
            case ApprovalStatusEnum.APPROVED:
            case ApprovalStatusEnum.REJECTED:
            case ApprovalStatusEnum.BUSY:
            case ApprovalStatusEnum.HALTED:
            case ApprovalStatusEnum.ERROR:
                return true;
        }
    }

    /**
     * Exports taxonomy builder data to a format for the backend
     * @typeParam D - The data type of the node
     * @typeParam N - The type of the node
     * @param node
     * @returns a Node with the correct values for the BE
     */
    export function exportNode<D extends Data, N extends Omit<Tree<D>, 'children'>>(node: N): Omit<Tree<Data>, 'children'> {
        const values = {...node.values}
        delete values['changes'];
        return {
            id: node.id,
            label: node.label,
            values,
            sources: node.sources,
            removed: node.removed,
        }
    }

    export type TaxApprovalData<D> = D & Readonly<{
        hasTouched: boolean
        show: boolean
        isOpenAble: boolean
        nodeChangeMap: Map<m_taxonomy.ChangeType, number>
        descendantChangeMap: Map<m_taxonomy.ChangeType, number>
    }>
    export const EMPTY_APPROVAL_DATA: m_taxonomy.TaxApprovalData<{}> = {
        hasTouched: false,
        show: false,
        isOpenAble: false,
        nodeChangeMap: new Map(),
        descendantChangeMap: new Map(),
    }
    export type TaxApprovalTree<D = Data> = m_taxonomy.Tree<TaxApprovalData<D>>

    /**
     * Read the API data into our UI state data
     * @param tree
     */
    export function processApprovals(tree: m_taxonomy.Tree): TaxApprovalTree {
        // depth first
        const newChildren = tree.children.map(c => processApprovals(c))

        // Process the changes
        let egoChanges = tree.values.changes || [];
        if (egoChanges.length > 0) {
            // Sort and filter the changes
            egoChanges = egoChanges
                .filter(c => !c.reverted)
                .sort((a, b) => m_taxonomy.CHANGE_ORDER.indexOf(a.type) - m_taxonomy.CHANGE_ORDER.indexOf(b.type))

            tree.values.changes = egoChanges
        }

        // Count the changes in this node
        const nodeChangeMap = new Map<m_taxonomy.ChangeType, number>();
        addChangesToMap(nodeChangeMap, egoChanges);

        // Count the changes of all descendant
        const descendantChangeMap = new Map<m_taxonomy.ChangeType, number>();
        let hasTouched: boolean = tree.values.approval_touched ?? false;
        for (const child of newChildren) {
            addToMap(descendantChangeMap, child.values.nodeChangeMap);
            addToMap(descendantChangeMap, child.values.descendantChangeMap);
            hasTouched ||= child.values.hasTouched;
        }

        return {
            ...tree,
            children: newChildren,
            values: {
                ...tree.values,
                hasTouched,
                show: nodeChangeMap.size > 0 || descendantChangeMap.size > 0 || hasTouched,
                isOpenAble: descendantChangeMap.size > 0,
                nodeChangeMap,
                descendantChangeMap,
            }
        }
    }

    function addChangesToMap(map: Map<ChangeType, number>, changes: Change[]) {
        for (const c of changes) {
            map.set(c.type, (map.get(c.type) ?? 0) + 1);
        }
    }

    function addToMap(map: Map<ChangeType, number>, toAdd: Map<ChangeType, number>) {
        for (const e of Array.from(toAdd.entries())) {
            const type = e[0];
            const value = e[1];
            map.set(type, (map.get(type) ?? 0) + value);
        }
    }

    export function addAuthor(values: Data, userId: number) {
        const authors = values.authors;
        if (!authors) {
            values.authors = [userId];
        } else if (!authors.includes(userId)) {
            authors.push(userId);
        }
    }
}
