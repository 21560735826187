import MithraMaterializedApi from "../services/MithraMaterializedApi";
import AuthStore from "./AuthStore";
import ProfileStore from "./ProfileStore";
import {makeAutoObservable} from "mobx";
import {BagStore} from "./BagStore";
import {GoldenRecordDataController} from "../pages/golden-record/controllers/GoldenRecordDataController";
import {single_bar_chart} from "../components/visualization/single-barchart/SingleBarChartBuilder";
import {toCurrency} from "../components/currency-component/CurrencyClasses";
import {GoldenRecordFilterController} from "../pages/golden-record/controllers/GoldenRecordFilterController";
import {GoldenRecordEditController} from "../pages/golden-record/controllers/GoldenRecordEditController";
import {GoldenRecordPartState} from "../pages/golden-record/classes/GoldenRecordPartState";
import {GOLDEN_RECORD_DUMMY_DATA_STATS_CATS} from "../pages/golden-record/classes/GoldenRecordDummyData";


export type GoldenRecordsChartElement = {
    values: single_bar_chart.DataPoint[];
    category: string
    label: string
};

export class GoldenRecordStore {
    readonly dataController = new GoldenRecordDataController(this);
    readonly filterController = new GoldenRecordFilterController(this);
    readonly editController = new GoldenRecordEditController(this);

    isBarChartExtended = false;
    nUnreviewedGoldenRecords = 24;

    isRemoveModalOpen = false;
    removeSubject: GoldenRecordPartState | undefined;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
        private bagStore: BagStore,
    ) {
        makeAutoObservable(this);
    }

    setIsBarChartExtended(isExtended: boolean) {
        this.isBarChartExtended = isExtended;
    }

    public refreshData() {
        this.dataController.grGroups.init(undefined);
    }

    get barChart(): {
        data: GoldenRecordsChartElement[],
        max: number,
    } | undefined {
        const ds: { label: string, spend: number, parts: number }[] = Array.from(GOLDEN_RECORD_DUMMY_DATA_STATS_CATS)
        const data: GoldenRecordsChartElement[] = ds.map(d => ({
            label: d.label, category: d.label, values: [{
                label: `${toCurrency(this.profile.currencySymbol, d.spend)} | ${d.parts} parts`,
                value: d.spend,
                dataCls: '',
            }]
        }))
        const COL_WIDTH = 6 / 10 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(d => Math.max(...d.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    setRemoveModalOpen(isOpen: boolean, removeSubject?: GoldenRecordPartState) {
        this.isRemoveModalOpen = isOpen;
        this.removeSubject = removeSubject;
    }
}