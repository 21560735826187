import React from "react";
import {environment} from "../../env";
import moment from "moment/moment";

type Props = {
    colType: string
    value: any
}
export const MithraTableCellValue: React.FC<Props> = ({colType, value}) => {
    let s_val: string;
    if (colType === 'date') {
        if (value === null || value === undefined) {
            if (environment.isTest) {
                s_val = `[${value}]`;
            } else {
                s_val = '';
            }
        } else {
            s_val = moment(value).format('L')
            //    + (environment.isTest ? ` [${value}]` : '')
        }
    } else {
        s_val = String(value);
    }
    return <>{s_val}</>;
}
