import React, {useEffect, useRef, useState} from "react";
import * as d3 from "d3";

export type TopData = {
    topValue: number
    otherValue: number
}
type Props = TopData & {
}
export const TopOtherVisualization: React.FC<Props> = ({topValue, otherValue}) => {
    const svgRef = useRef<SVGSVGElement>(null)

    const [controller, setController] = useState<Controller | undefined>(undefined)

    useEffect(() => {
        if(!controller && svgRef.current) {
            const svg = d3.select(svgRef.current as SVGElement);
            const controller = new Controller(svg);
            setController(controller)
            controller.draw({topValue, otherValue})
        } else if (controller) {
            controller.update({topValue, otherValue});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topValue, otherValue, controller])

    return <svg className="top-other-visualization" ref={svgRef}/>
}

type D = {
    value: number
    label: string
    caption: string
    isUnderflow: boolean
}

class Controller {
    // private root: d3.Selection<SVGGElement, D, SVGGElement, any>;
    private x: d3.ScaleBand<string>;
    private y: d3.ScaleLinear<number, number>;
    private graphWidth = 500;
    private graphHeight = 150;
    private bottomHeight = 50;

    private minBarHeight = 33;

    private padding = .33;

    constructor(private svg) {
        svg.attr('viewBox', `0 0 ${this.graphWidth} ${this.graphHeight + this.bottomHeight}`)

        // svg.append('rect')
        //     .attr('width', this.graphWidth)
        //     .attr('height', this.graphHeight / 5);

        this.x = d3.scaleBand()
            .domain([...Array(2)].map((_, i) => `${i}`))
            .range([0, this.graphWidth])
            .padding(this.padding)
            .align(0.5)

        this.y = d3.scaleLinear()
            .domain([0, 1])
            .range([0, this.graphHeight])

    }

    parse(inputData: TopData): D[] {
        return [
            {
                value: inputData.topValue,
                label: `${Math.round(inputData.topValue * 100)}%`,
                caption: 'Top 20 suppliers',
                isUnderflow: this.y(inputData.topValue) <= this.minBarHeight,
            },
            {
                value: inputData.otherValue,
                label: `${Math.round(inputData.otherValue * 100)}%`,
                caption: 'Other suppliers',
                isUnderflow: this.y(inputData.otherValue) <= this.minBarHeight,
            }
        ];
    }

    private get selection(): d3.Selection<SVGGElement, D, SVGGElement, any> {
        return this.svg.selectAll('g.group')
    }

    draw(inputData: TopData) {
        this.update(inputData)
    }

    update(inputData: TopData) {
        const data = this.parse(inputData);
        this.selection
            .data(data, (_, i) => i)
            .join(
                enter => {
                    const g = enter.append('g')
                        .classed('group', true)
                        .attr('transform', (_, i) => `translate(${this.x(`${i}`)}, 0)`)
                    const a = g.append('g').classed('area', true)
                    a.append('rect')
                    a.append('text')
                        .classed('label', true)
                        .attr('dominant-baseline', 'middle')
                        .attr('x', this.x.bandwidth() / 2)
                    g.append('text')
                        .classed('caption', true)
                        .attr('dominant-baseline', 'middle')
                        .attr('x', this.x.bandwidth() / 2)
                        .attr('y', this.graphHeight + this.bottomHeight / 2)
                        .text(d => d.caption)
                    return g
                },
                update => {
                    update.select('g.area')
                        .select('rect')
                        // .attr('x', (_, index) => this.x(`${index}`) as number)
                        .attr('y', d => this.graphHeight - this.y(d.value))
                        .attr('width', this.x.bandwidth())
                        .attr('height', d => this.y(d.value))
                    update.select('text.label')
                        .text(d => d.label)
                        .classed('inverted', d => d.isUnderflow)
                        .attr('dominant-baseline', d => d.isUnderflow ? 'text-top' : 'middle')
                        .attr('y', d => d.isUnderflow
                            ? this.graphHeight - this.y(d.value) - 6
                            : this.graphHeight - this.y(d.value) / 2 + 2)
                    // update.select('text.caption')
                    return update
                }
            )
    }
}
