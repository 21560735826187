import './IconStyles.scss'
import {Merge} from "@mui/icons-material";
import {ClassificationHierarchyIcon} from "./ClassificationHierarchyIcon";

export const TaxonomySuggestionV2Icon = () => <>
    <div className="composite-icon-wrapper taxonomy-suggestion-v2-icon">
        <ClassificationHierarchyIcon/>
        <Merge className="rotated-second-icon hack-lighten-borders"/>
    </div>
</>
