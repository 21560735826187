import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Chip,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {ChevronRight, CompareArrowsOutlined, KeyboardArrowDown} from "@mui/icons-material";
import {getCollapseAnimationTiming} from "../../../components/table/utils";
import {useStores} from "../../../stores";
import {ContractAnalyticsTable} from "./ContractAnalyticsTable";

interface Props {
    h: { key: string; label: string; type: string }[];
    className?: string;
    data?: any[];
    isLoading?: boolean;
    compactNumbers?: boolean;
    pageSize?: number;
    pageSizeOptions?: number[];
    container?: React.ElementType;
    notEditable?: boolean;
    onSelect?: (item: any) => void;
    subTable?: React.FC<SubTableProps>;
}

interface SubTableProps {
    h: { key: string; label: string; type: string }[];
    className?: string;
    data?: any[];
    isLoading?: boolean;
    pageSize?: number;
    pageSizeOptions?: number[];
}

const contractScopeSubTableCols: { key: string; label: string; type: string }[] = [
    {key: 'p_date', label: 'Date', type: 'string'},
    {key: 'p_quantity', label: 'Quantity', type: 'number'},
    {key: 'p_unit_of_measure', label: 'Unit of measure', type: 'string'},
    {key: 'p_spend', label: 'Invoice spend', type: 'currency'},
    {key: 'move', label: '', type: 'move'},
]

const contractScopeSubTableData1 = [
    {
        p_date: '4/1/2023',
        p_quantity: 501,
        p_unit_of_measure: 'Kg',
        p_spend: 16000000,
    },
    {
        p_date: '9/1/2023',
        p_quantity: 181,
        p_unit_of_measure: 'Kg',
        p_spend: 7400000,
    },
]
const contractScopeSubTableData2 = [
    {
        p_date: '4/1/2023',
        p_quantity: 140,
        p_unit_of_measure: 'Kg',
        p_spend: 5200000 * (140 / (140 + 41)),
    },
    {
        p_date: '9/1/2023',
        p_quantity: 41,
        p_unit_of_measure: 'Kg',
        p_spend: 5200000 * (41 / (140 + 41)),
    },
];
const contractScopeSubTableData3 = [
    {
        p_date: '4/1/2023',
        p_quantity: 50,
        p_unit_of_measure: 'Kg',
        p_spend: 1500000,
    },
    {
        p_date: '9/1/2023',
        p_quantity: 41,
        p_unit_of_measure: 'Kg',
        p_spend: 2300000,
    },
];

export const ContractScopeTable: React.FC<Props> = observer(
    ({
         h,
         className,
         data,
         isLoading,
         compactNumbers = false,
         pageSize,
         pageSizeOptions,
         container,
         notEditable,
         onSelect,
         subTable: SubTableComponent,
     }) => {
        const {contractAnalyticsStore} = useStores();
        const containerComponent = container || 'div';
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(pageSize || 8);
        const [rowsPerPageOptions] = useState(pageSizeOptions || [8, 15, 25, 50]);
        // const [colorScalesMap, setColorScalesMap] = useState<{ [key: string]: { positive: any; negative: any } }>({});
        const [openRows, setOpenRows] = useState<number[]>([]);

        const headers = SubTableComponent ? [{key: 'dropdown', label: '', type: 'dropdown'}, ...h] : h;

        useEffect(() => {
            if (page === 0) return;
            if (isLoading) setPage(0);
            const maxPage = Math.floor((data?.length || 0) / rowsPerPage);
            if (page > maxPage) {
                setPage(0);
            }
        }, [data?.length, rowsPerPage, page, isLoading]);

        // useEffect(() => {
        //     if (!data) return;
        //     const newColorScalesMap: { [key: string]: { positive: any; negative: any } } = {};
        //     headers
        //         .filter((h) => h.type === 'currency')
        //         .forEach((h) => {
        //             const values: number[] = data.map((d) => d[h.key] || 0);
        //             newColorScalesMap[h.key] = {
        //                 positive: d3.scaleLinear([0, Math.max(1, ...values)], ['whitesmoke', '#193150']),
        //                 negative: d3.scaleLinear([0, Math.min(-1, ...values)], ['whitesmoke', '#E62D15']),
        //             };
        //         });
        //     setColorScalesMap(newColorScalesMap);
        // }, [data, headers]);

        const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const toggleRow = (index: number) => {
            setOpenRows(prevOpenRows => {
                if (prevOpenRows.includes(index)) {
                    // If the index is already open, remove it
                    return prevOpenRows.filter(row => row !== index);
                } else {
                    // If the index is closed, add it
                    return [...prevOpenRows, index];
                }
            });
        };

        const renderSubTable = (index: number) => {
            console.log('renderSubTable', index, openRows.includes(index))
            console.log('condition', !SubTableComponent || !openRows.includes(index))
            if (!SubTableComponent || !openRows.includes(index)) return null; // Check if index is open
            if (index === 0 && !(!SubTableComponent || !openRows.includes(index))) {
                return <ContractAnalyticsTable
                    className={'contract-scope-subtable'}
                    h={contractScopeSubTableCols}
                    data={contractScopeSubTableData1}
                    compactNumbers={true}
                />
            } else if (index === 1 && !(!SubTableComponent || !openRows.includes(index))) {
                return <ContractAnalyticsTable
                    className={'contract-scope-subtable'}
                    h={contractScopeSubTableCols}
                    data={contractScopeSubTableData2}
                    compactNumbers={true}
                />
            }
            if (index === 2 && !(!SubTableComponent || !openRows.includes(index))) {
                return <ContractAnalyticsTable
                    className={'contract-scope-subtable'}
                    h={contractScopeSubTableCols}
                    data={contractScopeSubTableData3}
                    compactNumbers={true}
                />
            }
        };

        const tableClass = `contract-analytics-table-wrapper${className ? ` ${className}` : ''}${isLoading ? ' loading' : ''}`;

        return (
            <TableContainer component={containerComponent} className={tableClass}>
                <Table cellPadding={0}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header.key}>{header.label}</TableCell>
                            ))}
                        </TableRow>
                        {/*<tr className={`progress-row${isLoading ? ' loading' : ''}`}>*/}
                        {/*    <td colSpan={headers.length}>{isLoading && <LinearProgress variant="indeterminate"/>}</td>*/}
                        {/*</tr>*/}
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [] : data || []).map((item, index) => (
                            <>
                                <TableRow
                                    key={JSON.stringify(item)}
                                    className="contract-analytics-table-row"
                                    onClick={() => toggleRow(index)}
                                >
                                    {headers.map((header) => {
                                        let value = item[header.key];
                                        let cellNumberClass = '';
                                        let style: React.CSSProperties = {};
                                        let cellContent: JSX.Element;
                                        switch (header.type) {
                                            case 'currency':
                                                cellContent = compactNumbers ? (
                                                    <CurrencyComponent v={value} nDigits={3}/>
                                                ) : (
                                                    <>{value}</>
                                                );
                                                cellNumberClass = 'fit-number';
                                                break;
                                            case '2_decimal':
                                                cellContent = <>{(Math.round(value * 100) / 100).toFixed(2)}</>;
                                                break;
                                            case 'category':
                                                cellContent = <>{value}</>;
                                                break;
                                            case 'dropdown':
                                                cellContent = openRows.includes(index) ?
                                                    <IconButton>
                                                        <KeyboardArrowDown/>
                                                    </IconButton>
                                                    :
                                                    <IconButton>
                                                        <ChevronRight/>
                                                    </IconButton>;
                                                break;
                                            case 'selection':
                                                if (notEditable) {
                                                    cellContent = <></>;
                                                } else {
                                                    cellContent = (
                                                        <Checkbox
                                                            checked={item.selected}
                                                            onChange={(e) => {
                                                                if (!onSelect) return;
                                                                onSelect(item);
                                                            }}
                                                        />
                                                    );
                                                }
                                                break;
                                            case 'chip':
                                                if (value == 'In contract') {
                                                    cellContent = <Chip label={value}
                                                                        variant="outlined" color="success"
                                                                        style={{
                                                                            width: '100%',
                                                                            border: '1px solid #08A57A',
                                                                            color: '#08A57A'
                                                                        }}/>;
                                                } else if (value == 'Out of contract') {
                                                    cellContent =
                                                        <Chip label={value}
                                                              variant="outlined" color={"error"}
                                                              style={{
                                                                  width: '100%',
                                                                  border: '1px solid #FF3435 ',
                                                                  color: '#FF3435'
                                                              }}
                                                        />;
                                                } else {
                                                    cellContent = <Chip label={value}/>;
                                                }

                                                break;
                                            case 'move':
                                                cellContent = <IconButton
                                                    onClick={() => {
                                                        // contractAnalyticsStore.setMoveTransactionData()
                                                        contractAnalyticsStore.toggleMoveModal()
                                                    }}
                                                    style={{color: '#091344'}}>
                                                    <CompareArrowsOutlined/>
                                                </IconButton>;
                                                break;
                                            default:
                                                cellContent = <>{value}</>;
                                                break;
                                        }
                                        // if (colorScalesMap[header.key]) {
                                        //     const {positive, negative} = colorScalesMap[header.key];
                                        //     const bgColor = value >= 0 ? positive(value) : negative(value);
                                        //     const fgColor = d3.hsl(bgColor).l > 0.5 ? 'black' : 'white';
                                        //     style.backgroundColor = bgColor;
                                        //     style.color = fgColor;
                                        // }
                                        return (
                                            <TableCell key={header.key}
                                                       className={`contract-analytics-table-cell ${cellNumberClass}`}
                                                       style={style}>
                                                <Typography>{cellContent}</Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {SubTableComponent && <TableRow>
                                    <TableCell colSpan={headers.length} className={'sub-table-row'}>
                                        <Collapse in={openRows.includes(index)}
                                                  timeout={getCollapseAnimationTiming([1, 2, 3, 4, 4])} // Check if index is open
                                                  unmountOnExit>
                                            {renderSubTable(index)}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>}
                            </>
                        ))}
                        {(!data || data.length === 0) && (
                            <TableRow>
                                <TableCell colSpan={headers.length} align="center" style={{height: '100px'}}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minHeight: '25em'
                                    }}>
                                        No data available
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {/*<TableFooter>*/}
                    {/*    <TableRow>*/}
                    {/*        <TablePagination*/}
                    {/*            rowsPerPageOptions={rowsPerPageOptions}*/}
                    {/*            colSpan={headers.length}*/}
                    {/*            count={data?.length || 0}*/}
                    {/*            rowsPerPage={rowsPerPage}*/}
                    {/*            page={page}*/}
                    {/*            showFirstButton*/}
                    {/*            showLastButton*/}
                    {/*            SelectProps={{*/}
                    {/*                inputProps: {'aria-label': 'rows per page'},*/}
                    {/*                native: true,*/}
                    {/*            }}*/}
                    {/*            onPageChange={handleChangePage}*/}
                    {/*            onRowsPerPageChange={handleChangeRowsPerPage}*/}
                    {/*            ActionsComponent={TablePaginationActions}*/}
                    {/*        />*/}
                    {/*    </TableRow>*/}
                    {/*</TableFooter>*/}
                </Table>
            </TableContainer>
        );
    }
);
