import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {
    Button,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {AdminPanelSettings, CloudDownload, Delete} from "@mui/icons-material";

const CAT_RUN_AI_MODAL_ID = 'categorization-run-ai-modal';
export const CAT_RUN_AI_MODAL_TITLE_ID = CAT_RUN_AI_MODAL_ID + '-title';

export const RunAiModal: React.FC = observer(() => {
    const {authStore, bagStore, categorizationReviewStore} = useStores();
    const aiJobDelegate = categorizationReviewStore.aiJobDelegate;
    const bagId = bagStore.bagId;

    const onClose = () => aiJobDelegate.closeRunAiModal();
    return (
        <EditorModalComponent
            titleId={CAT_RUN_AI_MODAL_TITLE_ID}
            className={CAT_RUN_AI_MODAL_ID}
            title="Are you sure?"

            saveLabel="Run AI"
            canSave
            isSaving={aiJobDelegate.createJobRequestManager.busy}
            onSave={() => aiJobDelegate.createJobRequestManager.request(bagId)}

            onCancel={onClose}
            onClose={onClose}
        >
            <Typography variant="body1">
                When the AI is done you will receive new suggestions for everything that is not reviewed yet.
            </Typography>
            <br/>
            <br/>
            <br/>
            {aiJobDelegate.createJobRequestManager.error && <Typography variant="body2" style={{color: 'red'}}>
                An error occurred while running AI. Please try again later.
            </Typography>}
            {/*<Typography variant="body2">*/}
            {/*    Last run: 01/01/2021 12:00:00 TODO: CHANGE THIS TO VAR*/}
            {/*</Typography>*/}

            {authStore.isMithraStaffGroup && <>
                <FormControl sx={{ m: 1, width: '40em' }} variant="outlined">
                    <InputLabel htmlFor="vertex-template-override-input">Vertex Template Override</InputLabel>
                    <OutlinedInput
                        id="vertex-template-override-input"
                        label="Vertex Template Override"
                        startAdornment={<InputAdornment position="start"><AdminPanelSettings /></InputAdornment>}
                        value={aiJobDelegate.job_pipeline_template_name}
                        onChange={(e) => aiJobDelegate.setJobPipelineTemplateName(e.target.value)}
                    />
                </FormControl>
                <br/>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="vertex-region-override-input">Vertex Region Override</InputLabel>
                    <OutlinedInput
                        id="vertex-region-override-input"
                        label="Vertex Region Override"
                        startAdornment={<InputAdornment position="start"><AdminPanelSettings /></InputAdornment>}
                        value={aiJobDelegate.job_region_override}
                        onChange={(e) => aiJobDelegate.setJobRegionOverride(e.target.value)}
                    />
                </FormControl>
                <Button
                    variant="outlined"
                    style={{marginTop: '1em', minWidth: '14em'}}
                    onClick={() => {
                        onClose();
                        categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy');
                    }}>
                    <AdminPanelSettings/>
                    Open Ai page
                </Button><br/>
                <Button
                    color="warning"
                    variant="outlined"
                    className="btn-with-circular-progress"
                    startIcon={aiJobDelegate.createDummyRequestManager.busy
                        ? <CircularProgress size={14}/>
                        : <CloudDownload/>}
                    style={{marginTop: '1em', minWidth: '14em'}}
                    disabled={aiJobDelegate.createDummyRequestManager.busy}
                    onClick={() => aiJobDelegate.createDummyRequestManager.request(bagId)}>
                    <AdminPanelSettings/>
                    Run AI Dummy
                    {aiJobDelegate.createDummyRequestManager.error && ' (Error)'}
                </Button><br/>
                <Button
                    color="warning"
                    variant="outlined"
                    className="btn-with-circular-progress"
                    startIcon={aiJobDelegate.devopsCleanupRequestManager.busy
                        ? <CircularProgress size={14}/>
                        : <Delete/>}
                    style={{marginTop: '1em', minWidth: '14em'}}
                    disabled={aiJobDelegate.devopsCleanupRequestManager.busy}
                    onClick={() => aiJobDelegate.devopsCleanupRequestManager.request(bagId)}>
                    <AdminPanelSettings/>
                    Clear Revisions and Jobs
                    {aiJobDelegate.devopsCleanupRequestManager.error && ' (Error)'}
                </Button><br/>
            </>}

        </EditorModalComponent>
    );
})
