// SupplierNormalization
export enum SupplierNormalizationState {
    START = 'start',
    RUNNING = 'running',
    REVIEW = 'review',
    APPLYING = 'applying',
    DONE = 'done'
}
export type ParentSupplier = {
    id: number
    sp_id: string
    sp_name: string
    sp_city: string
    sp_country: string
    sp_region: string
    sp_context_1: string
    sp_context_2: string
    sp_context_3: string
}
export type ParentSupplierReviewRow = {
    id: number
    parent_supplier: ParentSupplier
    sp_city: string
    sp_country: string
    sp_region: string
    sp_context_1: string
    sp_context_2: string
    sp_context_3: string
    sp_total_spend: number
    sp_total_parts: number
    sp_total_l1s: string[]
    sp_total_bus: string[]
    sp_mixed_supplier_sources: boolean
    sp_n_suppliers: number
    ai_suggested: boolean
}
export type ParentSupplierChoice = {
    id: number
    sp_id: string
    sp_name: string
    sp_n_suppliers: number
    ai_suggested: boolean
}
export type Supplier = {
    id: number
    databag_id: number
    src_databag_id: string
    s_id: string
    s_name: string
    s_city: string
    s_country: string
    s_country_code: string
    s_region: string
    s_address: string
    s_postal: string
    s_duns: string
    s_context_1: string
    s_context_2: string
    s_context_3: string
    s_extra_1: string
    s_extra_2: string
    s_extra_3: string
}
export type SupplierReviewRow = {
    id: number
    supplier: Supplier
    s_total_spend: number
    s_total_parts: number
    s_total_l1s: string[]
    s_total_bus: string[]
    parent_supplier_review_row: number
    ai_suggested: boolean
    ai_score: number
    review_user_id: number | null
    review_notes: string
}
export type ParentSupplierReviewRowState = ParentSupplierReviewRow & {
    type: 'parent_supplier'
    subRows: SupplierReviewRowState[] | null
}
export type SupplierReviewRowState = SupplierReviewRow & {
    type: 'supplier'
    parent_row: ParentSupplierReviewRow
}
export type UpdateParentSupplierReviewRowData = {
    sp_id: string,
    sp_name: string,
    prev_m_parent_supplier_id: number,
}
export type UpdateSupplierData = {
    id: number,
    sp_id: string,
    sp_name: string,
    parent_supplier_id: number | null,
}
export type ParentSupplierPreReviewStats = {
    total_reviewed_spend: number
    total_suppliers_count: number
    total_parent_suppliers_count: number
    parent_suppliers_count: number
    total_standalone_suppliers_count: number
}
export type ParentSupplierPostReviewStats = ParentSupplierPreReviewStats & {
    total_standalone_suppliers_spend: number
}
export type MatSupplierReviewStatistics = {
    databag: number,
    total_suppliers: number,
    total_spend: number,
    total_parts: number,
    parent_suppliers: number,
    parent_spend: number,
    parent_parts: number,
    standalone_suppliers: number,
    standalone_spend: number,
    standalone_parts: number,
    reviewed_spend: number
}

export function getParentSupplierChoice(parentSupplierReviewRow: ParentSupplierReviewRow): ParentSupplierChoice {
    return {
        id: parentSupplierReviewRow.id,
        sp_id: parentSupplierReviewRow.parent_supplier.sp_id,
        sp_name: parentSupplierReviewRow.parent_supplier.sp_name,
        sp_n_suppliers: parentSupplierReviewRow.sp_n_suppliers,
        ai_suggested: parentSupplierReviewRow.ai_suggested
    }
}

// CommonSupplier
export type CommonParentSupplier = {
    ids: number[]
    sp_id: string
    sp_name: string
    sp_total_spend: number
    sp_total_parts: number
    sp_n_suppliers: number
    sp_total_l1s: string[]
}