import './BusinessCaseGeneratorPage.scss'
import './BusinessCase.scss'
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup, Grid} from "@mui/material";
import {ChevronLeft, ChevronRight, Share} from "@mui/icons-material";
import React from "react";
import {useStores} from "../../stores";
import {generatePath, useNavigate} from "react-router-dom";
import {BusinessCaseGeneratorStepper} from "./components/stepper/BusinessCaseGeneratorStepper";
import {ReviewOpportunitiesComponent} from "./components/ReviewOpportunitiesComponent";
import {RiskAssessmentComponent} from "./components/RiskAssessmentComponent";
import {SuccessFactorsComponent} from "./components/SuccessFactorsComponent";
import {routes} from "../../routing/routes";
import {environment} from "../../env";
import {ReactComponent as Avatar1} from '../../Mithra.svg';
import {ShareModal} from "./components/modals/ShareModal";


type Props = {}
export const BusinessCaseGeneratorPage: React.FC<Props> = observer(() => {
    const {businessCaseGeneratorStore, bagStore} = useStores();
    const [isShareOpen, setIsShareOpen] = React.useState(false);
    const navigate = useNavigate();

    const pageStateController = businessCaseGeneratorStore.pageController.pageStateController;

    {/*CAT-1417 remove hardcoded values*/
    }
    return <section className="business-case-generator-page">
        <ShareModal isOpen={isShareOpen} onClose={() => setIsShareOpen(false)}/>
        <Grid container alignItems="center" className="header">
            <Grid item xs={2} className="text-center">
                {pageStateController.previousLabel &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            if (pageStateController.page === 'review-opportunities') {
                                navigate(generatePath(routes.job_advanced_dashboard, {
                                    id: bagStore.bagId,
                                }))
                            } else {
                                pageStateController.onClickPrevious()
                            }
                        }}>
                        {pageStateController.previousLabel}
                    </Button>
                }
            </Grid>

            <Grid item xs={8}>
                <BusinessCaseGeneratorStepper/>
            </Grid>

            <Grid item xs={2} className="align-with-stepper">
                {environment.isTest && <Button
                    onClick={() => businessCaseGeneratorStore.setInitialLoading(!businessCaseGeneratorStore.isInitialLoading)}>Loading</Button>}

                {/*<IconButton onClick={() => setIsShareOpen(true)}>*/}
                {/*    <Share/>*/}
                {/*</IconButton>*/}

                {/*{pageStateController.nextLabel && <Button*/}
                {/*    color="primary"*/}
                {/*    variant={pageStateController.page === 'success-factors' ? 'contained' : 'outlined'}*/}
                {/*    disabled={(pageStateController.page === 'review-opportunities' && !businessCaseGeneratorStore.hasLoaded) || (pageStateController.page === 'risk-assessment' && !businessCaseGeneratorStore.risksHaveLoaded)}*/}
                {/*    endIcon={pageStateController.page !== 'success-factors' && <ChevronRight/>}*/}
                {/*    startIcon={pageStateController.page === 'success-factors' && < Avatar1/>}*/}
                {/*    onClick={() => {*/}
                {/*        if (pageStateController.page === 'success-factors') {*/}
                {/*            navigate(generatePath(routes.job_business_case_report_id, {*/}
                {/*                id: bagStore.bagId,*/}
                {/*                businessCaseId: 1,*/}
                {/*            }))*/}
                {/*        } else {*/}
                {/*            pageStateController.onClickNext()*/}
                {/*        }*/}
                {/*    }}>*/}
                {/*    {pageStateController.nextLabel}*/}
                {/*</Button>}*/}
                <ButtonGroup variant="outlined" size="small" aria-label="small button group">
                    <Button onClick={() => setIsShareOpen(true)}>
                        <Share/>
                    </Button>

                    {pageStateController.nextLabel && (
                        <Button
                            variant={pageStateController.page === 'success-factors' ? 'contained' : 'outlined'}
                            disabled={
                                (pageStateController.page === 'review-opportunities' && !businessCaseGeneratorStore.hasLoaded) ||
                                (pageStateController.page === 'risk-assessment' && !businessCaseGeneratorStore.risksHaveLoaded)
                            }
                            endIcon={pageStateController.page !== 'success-factors' && <ChevronRight/>}
                            startIcon={pageStateController.page === 'success-factors' && <Avatar1/>}
                            onClick={() => {
                                if (pageStateController.page === 'success-factors') {
                                    navigate(generatePath(routes.job_business_case_report_id, {
                                        id: bagStore.bagId,
                                        businessCaseId: 1,
                                    }));
                                } else {
                                    pageStateController.onClickNext();
                                }
                            }}
                        >
                            {pageStateController.nextLabel}
                        </Button>
                    )}
                </ButtonGroup>

            </Grid>


            {pageStateController.page === 'review-opportunities' && <ReviewOpportunitiesComponent/>}
            {pageStateController.page === 'risk-assessment' && <RiskAssessmentComponent/>}
            {pageStateController.page === 'success-factors' && <SuccessFactorsComponent/>}
        </Grid>

        <div className="text-right mt-4">
            {pageStateController.nextLabel && !businessCaseGeneratorStore.isInitialLoading && <Button
                color="primary"
                variant={pageStateController.page === 'success-factors' ? 'contained' : 'outlined'}
                disabled={(pageStateController.page === 'review-opportunities' && !businessCaseGeneratorStore.hasLoaded) || (pageStateController.page === 'risk-assessment' && !businessCaseGeneratorStore.risksHaveLoaded)}
                endIcon={pageStateController.page !== 'success-factors' && <ChevronRight/>}
                startIcon={pageStateController.page === 'success-factors' && < Avatar1/>}
                onClick={() => {
                    window.scrollTo(0, 0)
                    if (pageStateController.page === 'success-factors') {
                        navigate(generatePath(routes.job_business_case_report_id, {
                            id: bagStore.bagId,
                            businessCaseId: 1,
                        }))
                    } else {
                        pageStateController.onClickNext()
                    }
                }}>
                {pageStateController.nextLabel}
            </Button>}
        </div>
    </section>
});
