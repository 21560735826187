import {observer} from "mobx-react-lite";
import {Button, TableRow} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import React, {useState} from "react";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {useStores} from "../../../stores";
import {OpportunityStatusEnum, PpvOpportunity} from "../../../services/classes/OpportunityClasses";
import {getOpportunityTableColumns} from "./OpportunityColumns";
import {useNavigate} from "react-router-dom";
import {PpvGroupFilter} from "../../../jobs/koi/purchase-price-variance/classes/PpvApiClasses";
import {OpportunityStatusDropdown} from "./OpportunityStatusDropdown";

type Props = {
    opportunityRow: PpvOpportunity
};

export const OpportunityTableMainRow: React.FC<Props> = observer(({opportunityRow}) => {
    const {p, opportunityStoreBis, ppvControllerStore} = useStores();
    const navigate = useNavigate();
    const COLUMNS = getOpportunityTableColumns(p);

    const [loading, setLoading] = useState(false);


    let filter: PpvGroupFilter = opportunityRow.scope;
    filter.search = opportunityRow.ppv_group_key;


    let i = 0;
    return <>
        <TableRow className="opportunity-table-main-row">
            <MithraTableCell c={COLUMNS[i++]}>
                {opportunityRow.ppv_group_key}
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                <CurrencyComponent v={opportunityRow.current_expected_savings} nDigits={3}/>
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{marginRight: '8px', padding: '0px'}}>
                    <OpportunityStatusDropdown
                        status={opportunityRow.status || OpportunityStatusEnum.PENDING_REVIEW}
                        setStatus={(status) => opportunityStoreBis.changeStatus(opportunityRow, status)}
                        loading={loading}
                    />
                </div>
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                <Button variant='outlined' onClick={() => {
                    ppvControllerStore.goToPpvScope(navigate, filter)
                }}>
                    Open
                </Button>
                {/*TODO: Move the new opportunity viewer to the correct location*/}
                {/*<Button variant="contained" onClick={() => {*/}
                {/*    navigate(generatePath(routes.ppv_opportunities_detail, {*/}
                {/*        id: opportunityRow.databag,*/}
                {/*        oppId: opportunityRow.id*/}
                {/*    }))*/}
                {/*}}>*/}
                {/*    Opp preview*/}
                {/*</Button>*/}
            </MithraTableCell>
        </TableRow>
    </>
})
