import {createContext, useContext} from "react";
import LoadingBarStore from "./LoadingBarStore";
import {CompareBagStore} from "../pages/synergy_v1/CompareBagStore";
import {BagStore} from "./BagStore";
import {CategorizationReviewStore} from "./categorization-review/CategorizationReviewStore";
import TaxonomyEditorStore from "./TaxonomyEditorStore";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {ApprovalStore} from "./ApprovalStore";
import {OpportunityStore} from "./OpportunityStore";
import {BagLoadingStore} from "./BagLoadingStore";
import {SpendConcentrationStore} from "../jobs/koi/spend-concentration/SpendConcentrationStore";
import {TaxonomyMapperStore} from "../pages/taxonomy-mapper/store/TaxonomyMapperStore";
import {PpvControllerStore} from "../jobs/koi/purchase-price-variance/PpvControllerStore";
import {SupplierNormalizationStore} from "./supplier-normalization/SupplierNormalizationStore";
import {RootAppStore} from "./RootAppStore";
import DataIngestionStore from "./DataIngestionStore";
import TaxonomySuggestorStore from "./TaxonomySuggestorStore";
import {SynergyStore} from "./SynergyStore";
import TaxonomyHealthCheckStore from "./TaxonomyHealthCheckStore";
import TaxonomySuggesterStoreV2 from "./TaxonomySuggesterStoreV2";
import {BusinessCaseGeneratorStore} from "./BusinessCaseGeneratorStore";
import {GoldenRecordStore} from "./GoldenRecordStore";
import TaxonomySuggestionDemoStore from "./TaxonomySuggestionDemoStore";
import {ContractInsightsStore} from "./contract/ContractInsightsStore";
import {ContractAnalyticsStore} from "./contract/ContractAnalyticsStore";
import TaxonomyIngestionStore from "./TaxonomyIngestionStore";
import {OpportunityStoreBis} from "../pages/opportunity/OpportunityStoreBis";
import {TargetListStore} from "../sit/stores/TargetListStore";
import {InitiativeListStore} from "../sit/stores/InitiativeListStore";
import {CompanyConfigurationStore} from "../sit/stores/CompanyConfigurationStore";
import {FirebaseStore} from "../services/firebase/FirebaseStore";
import {authStore, ingestionApi, materializedApi, p, sitApi} from "./init";
import {FinancialDashboardStore} from "../sit/stores/FinancialDashboardStore";
import {TargetInstanceStore} from "../sit/stores/TargetInstanceStore";
import {InitiativeInstanceStore} from "../sit/stores/InitiativeInstance/InitiativeInstanceStore";
import {SitUserStore} from "../sit/stores/SitUserStore";
import {KpiStore} from "../sit/stores/KpiStore";
import {AttachmentStore} from "../sit/stores/AttachmentStore";

const rootAppStore = new RootAppStore();

const bagStore = new BagStore(materializedApi, p, authStore);
const loadingBarStore = new LoadingBarStore();
const cmpStore = new CompareBagStore(materializedApi, p);
const bagLoadingStore = new BagLoadingStore(materializedApi, bagStore, loadingBarStore, authStore);
const approvalStore = new ApprovalStore(materializedApi, authStore, bagStore, p);
const taxonomyManagerStore = new TaxonomyManagerStore(materializedApi, bagStore, bagLoadingStore, authStore, p);
const taxonomyEditorStore = new TaxonomyEditorStore(taxonomyManagerStore, p, materializedApi);
const taxonomySuggestorStore = new TaxonomySuggestorStore(p, materializedApi, bagStore, authStore, taxonomyManagerStore, p);
const taxonomyHealthCheckStore = new TaxonomyHealthCheckStore(p, materializedApi, bagStore, authStore, taxonomyManagerStore, p);
const taxonomySuggesterStoreV2 = new TaxonomySuggesterStoreV2(p, materializedApi, bagStore, authStore, taxonomyManagerStore, p);
const taxonomySuggestionDemoStore = new TaxonomySuggestionDemoStore(p, materializedApi, bagStore, authStore, taxonomyManagerStore, p);
const opportunityStore = new OpportunityStore(p, bagStore);
const opportunityStoreBis = new OpportunityStoreBis(materializedApi, bagStore, p, authStore);
const spendConcentrationStore = new SpendConcentrationStore(materializedApi, bagStore, p);
const taxonomyMapperStore = new TaxonomyMapperStore(materializedApi, authStore, p, bagStore, taxonomyManagerStore, undefined, undefined, 'defaultTM');
const ppvControllerStore = new PpvControllerStore(materializedApi, bagStore, p, authStore);
const supplierNormalizationStore = new SupplierNormalizationStore(materializedApi, p);
const masterSupplierNormalizationStore = new SupplierNormalizationStore(materializedApi, p);
const baseSupplierNormalizationStore = new SupplierNormalizationStore(materializedApi, p);
const dataIngestionStore = new DataIngestionStore(materializedApi, ingestionApi, bagStore, authStore, p);
const taxonomyIngestionStore = new TaxonomyIngestionStore(materializedApi, ingestionApi, bagStore, authStore, p);
const synergyStore = new SynergyStore(bagStore, p, materializedApi);
const categorizationReviewStore = new CategorizationReviewStore(approvalStore, authStore, bagStore, materializedApi, p);
const businessCaseGeneratorStore = new BusinessCaseGeneratorStore(materializedApi, authStore, p);
const goldenRecordStore = new GoldenRecordStore(materializedApi, authStore, p, bagStore);
const contractInsightsStore = new ContractInsightsStore(materializedApi, p);
const contractAnalyticsStore = new ContractAnalyticsStore(contractInsightsStore, materializedApi);
const firebaseStore = new FirebaseStore(materializedApi);
export const stores = {
    rootAppStore,
    bagStore,
    synergyStore,
    cmpStore,
    authStore,
    loadingBarStore,
    bagLoadingStore,
    categorizationReviewStore,
    businessCaseGeneratorStore,
    materializedApi,
    taxonomyManagerStore,
    taxonomyEditorStore,
    taxonomySuggestorStore,
    taxonomySuggestionDemoStore,
    taxonomyHealthCheckStore,
    taxonomySuggesterStoreV2,
    approvalStore,
    p,
    opportunityStore,
    opportunityStoreBis,
    spendConcentrationStore,
    taxonomyMapperStore,
    ppvControllerStore,
    supplierNormalizationStore,
    masterSupplierNormalizationStore,
    baseSupplierNormalizationStore,
    dataIngestionStore,
    taxonomyIngestionStore,
    goldenRecordStore,
    contractInsightsStore,
    contractAnalyticsStore,
    firebaseStore,
};

/**
 * @see https://mobx.js.org/defining-data-stores.html#example-domain-store
 * Subclassing is only supported limited by MobX, so we prefer composition to inheritance for Stores
 */
export type Stores = typeof stores;

/**
 * Custom defined function to get all stores of this app
 * @see https://mobx.js.org/defining-data-stores.html
 */
export const useStores: () => Stores = () => useContext(createContext(stores))

// Sit stores
const ccStore = new CompanyConfigurationStore(sitApi);
const targetListStore = new TargetListStore(sitApi);
const targetInstanceStore = new TargetInstanceStore(sitApi, ccStore, authStore, p);
const initiativeListStore = new InitiativeListStore(sitApi, authStore);
const initiativeInstanceStore = new InitiativeInstanceStore(sitApi, ccStore, authStore, p);
const kpiStore = new KpiStore(sitApi);
const attachmentStore = new AttachmentStore(sitApi, initiativeInstanceStore);
const fbStore = new FinancialDashboardStore(sitApi, ccStore);
const sitUserStore = new SitUserStore(sitApi);
export const sitStores = {
    sitApi,
    ccStore,
    targetListStore,
    targetInstanceStore,
    initiativeListStore,
    initiativeInstanceStore,
    kpiStore,
    attachmentStore,
    fbStore,
    sitUserStore,
}
export type SitStores = typeof sitStores;
export const useSitStores: () => SitStores = () => useContext(createContext(sitStores))
