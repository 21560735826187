import React from "react";
import {TextField} from '@mui/material';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {ApprovalHierarchyNode} from "../../../../stores/approval/TaxonomyApprovalDelegate";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";


type Props = {
    node: ApprovalHierarchyNode
}
export const EditCategoryModal: React.FC<Props> = observer(({node}) => {
    const {approvalStore} = useStores();
    const [newName, setNewName] = React.useState<string>(node.data.label);
    const [newDescription, setNewDescription] = React.useState<string>(node.data.values.description || '');
    const [nameError, setNameError] = React.useState<string>('');

    const onSave = () => {
        const name = newName.trim();
        if (name.length === 0) {
            setNameError('Category name cannot be empty');
            return;
        }
        if (name !== node.data.label) {
            // See if the target already exists
            const conflicts = approvalStore.taxonomyApproval.getNameConflict(node, name)
            if (conflicts.length > 0) {
                setNameError('Category name already exists');
                return;
            }
        }
        setNameError('')

        approvalStore.taxonomyApproval.onSaveEditNode(node, name, newDescription.trim());
    }

    const changeName = node.data.values.changes?.find(c => c.type === 'renamed') as undefined | m_taxonomy.Change & {
        type: 'renamed'
    };
    const nameHelperText = changeName ? `Original name: ${changeName.original_label}` : '';

    const changeDesc = node.data.values.changes?.find(c => c.type === 'updated' && c.field === 'description') as undefined | m_taxonomy.Change & {
        type: 'updated'
    };
    const descHelperText = changeDesc ? `Original description: ${changeDesc.original_value}` : '';

    return <EditorModalComponent
        titleId="update-category-modal-title"
        className="taxonomy-modal-component update-category-modal"
        title="Edit category"
        canSave={!nameError}
        onSave={onSave}
        onClose={() => approvalStore.taxonomyApproval.closeEditModal()}
    >
        {/*<section className="modal-introduction">*/}
        {/*</section>*/}

        <TextField
            autoFocus
            fullWidth
            id="taxonomy-editor-modal-name-field"
            label="Category name"
            error={Boolean(nameError)}
            helperText={nameError || nameHelperText}
            variant="outlined"
            value={newName}
            onChange={e => {
                setNewName(e.target.value);
                setNameError('')
            }}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            multiline
            id="taxonomy-editor-modal-description-field"
            label="Description"
            // error={Boolean(taxonomyEditorStore.updateCategoryDescriptionError)}
            helperText={descHelperText}
            variant="outlined"
            value={newDescription}
            onChange={e => setNewDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />
    </EditorModalComponent>
})
