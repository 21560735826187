import {makeAutoObservable, runInAction} from "mobx";
import {catchError, forkJoin, from, map, Subscription, throwError} from "rxjs";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import ProfileStore from "./ProfileStore";

export type TaxonomyCategory = {
    values: string[]
    label: string
    search: string
}
export class TaxonomyCategoryProvider {
    taxonomy_categories: TaxonomyCategory[] | undefined | null;
    error = ''

    private retrieveSubscription?: Subscription

    constructor(private profileStore: ProfileStore, private api: MithraMaterializedApi) {
        makeAutoObservable(this)
    }

    fillCategoriesFromTaxonomy(taxonomyId: number) {
        if (this.retrieveSubscription) {
            this.retrieveSubscription.unsubscribe()
        }

        this.taxonomy_categories = undefined
        this.error = ''

        this.retrieveSubscription = forkJoin([
            from(this.api.getTaxonomyCategories(taxonomyId)).pipe(
                map(taxonomyResult => {
                    const categories = taxonomyResult.data.base_categories;
                    if (!categories) {
                        return [];
                    }
                    return categories.map<TaxonomyCategory>(cats => ({
                        label: cats.join(', '),
                        values: cats,
                        search: Array.from(new Set(cats.flatMap(l => l.split('-')))).join(' ')
                    }));
                }),
                catchError(err => {
                    // More specifically, we probably did not set a correct taxonomy for this bag yet
                    runInAction(() => this.taxonomy_categories = null);
                    return throwError(err);
                })
            ),
        ]).subscribe({
            next: ([taxonomy]) => {
                runInAction(() => {
                    this.taxonomy_categories = taxonomy
                    this.error = '';
                });
            },
            error: err => {
                console.error('Cannot load taxonomy categories', err)
                this.error = 'Cannot load categories'
            },
        })
    }

    arrest(error: string) {
        if (this.retrieveSubscription) this.retrieveSubscription.unsubscribe()
        this.taxonomy_categories = undefined
        this.error = error
    }

}
