import React, {useState} from "react";
import {initializeApp} from "firebase/app";
import {User} from "firebase/auth";
import {FirebaseApp} from "@firebase/app";
import {Firestore} from "@firebase/firestore";
import {environment} from "../../env";
import {Analytics, initializeAnalytics} from "firebase/analytics";
import git from "../../utils/git.json";
import {analyticsInitEvent} from "../analytics/AnalyticEvents";

export type MithraFirebaseClaims = {
    mithra_user_id: number
    is_staff: boolean
    is_active: boolean
}

export type FirebaseContextType = {
    app: FirebaseApp;
    ga?: Analytics;
    db?: Firestore;
    auth?: {
        user: User,
        claims: MithraFirebaseClaims
    }
}

export const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC = ({children}) => {
    const [firebase] = useState<FirebaseContextType | undefined>(
        () => {
            if (!environment.firebaseConfig.apiKey) {
                console.log('No firebase setup; not initializing app')
                return undefined;
            }
            // Initialize Firebase
            const app = initializeApp(environment.firebaseConfig);
            const firebaseAppName = app.name;

            let ga: Analytics | undefined = undefined;
            if (environment.isGA) {
                ga = initializeAnalytics(app, {config: {}});
                const hostname = window.location.hostname;
                analyticsInitEvent(ga, {
                    version: git.gitCommitHash,
                    customer: environment.customerName,
                    hostname,
                })
            }

            // let db: Firestore | undefined = undefined;
            // if (stores.p.p.hasFirestore) {
            //     db = getFirestore(app);
            // }

            console.log(`Firebase app ${app.options.appId} named "${firebaseAppName}" initialized with: ${[
                ga ? 'analytics' : '',
                // db ? 'firestore' : '',
            ].filter(v => v).join(', ')}`);

            return {app, ga};
        }
    );
    return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
}