import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import {BusinessUnit} from "../../../services/classes/MaterializedClasses";
import {ClearIcon} from "@mui/x-date-pickers";

export const PpvFilterModal: React.FC = observer(() => {
    const {ppvControllerStore} = useStores();
    const reviewPageFilterController = ppvControllerStore.ppvPageController.ppvPageFilterController;

    // Allow the user to change the filters without applying
    const [selectedBusinessUnit, setSelectedBusinessUnit] = React.useState<BusinessUnit | undefined>(reviewPageFilterController.businessUnitFilterField);

    useEffect(() => {
        if (reviewPageFilterController.isFilterDialogOpen) {
            // Reset the filter when it's opened to the active filter
            setSelectedBusinessUnit(reviewPageFilterController.businessUnitFilterField)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewPageFilterController.isFilterDialogOpen]);

    const onSave = () => {
        reviewPageFilterController.setFilterDialogOpen(false);
        reviewPageFilterController.setBusinessUnitFilterField(selectedBusinessUnit)
    };
    const onCancel = () => {
        reviewPageFilterController.setFilterDialogOpen(false);
    };
    return <GenericModalVertical
        className="ppv-filter-modal"
        titleId="ppv-filter-modal-business-unit-title"
        title="Filter"
        isOpen={reviewPageFilterController.isFilterDialogOpen}
        saveTitle="Apply"
        onSave={onSave}
        onClose={onSave}
        onCancel={onCancel}
    >
        <Grid container style={{marginTop: '1em', marginBottom: '1em'}}>
            <Grid item xs={10}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    className="business-unit-select-field-wrapper">
                    <InputLabel id="ppv-filter-modal-business-unit-label">
                        Business Unit
                    </InputLabel>
                    <Select
                        labelId="ppv-filter-modal-business-unit-label"
                        label="Business Unit"
                        value={selectedBusinessUnit?.id || ''}
                        displayEmpty
                        fullWidth
                        onChange={e => {
                            const buId = Number(e.target.value)
                            const bu = reviewPageFilterController.businessUnitFilterOptions?.find(bu => bu.id === buId)
                            setSelectedBusinessUnit(bu)
                        }}
                        endAdornment={
                            <IconButton
                                style={{marginRight: '10px'}}
                                aria-label="clear business unit"
                                onClick={() => setSelectedBusinessUnit(undefined)}>
                                <ClearIcon/>
                            </IconButton>
                        }
                    >
                        {reviewPageFilterController.businessUnitFilterOptions?.map(option =>
                            <MenuItem key={option.id} value={option.id}>
                                <Typography variant="inherit">{option.bu_name}</Typography>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </GenericModalVertical>
})
