import './TaxonomySuggesterV2.scss'
import React from 'react';
import {Button, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {getCollapseAnimationTiming} from "../../components/table/utils";
import {Cancel, CheckCircle, ExpandMore, KeyboardArrowRight, Warning} from "@mui/icons-material";
import {useStores} from "../../stores";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {taxonomy_suggester_v2} from "../../services/classes/TaxonomySuggesterClassesV2";
import ExtendedD3HierarchyNode = taxonomy_suggester_v2.ExtendedD3HierarchyNode;
import ReviewStatusType = taxonomy_suggester_v2.ReviewStatusType;
import APIValues = taxonomy_suggester_v2.APIValues;


type TaxonomyTableProps = {
    columns: ColSpec[];
};

type TaxonomyRowProps = {
    d: ExtendedD3HierarchyNode;
    columns: ColSpec[];
};

export const TaxonomySuggesterTableV2: React.FC<TaxonomyTableProps> = ({columns}) => {
    const {taxonomySuggesterStoreV2} = useStores();

    return (
        <TableContainer className="taxonomy-health-check-table">
            <Table>
                <MithraTableHeadColumns columns={columns}/>
                <TableBody>
                    {taxonomySuggesterStoreV2.taxonomyTableController.data &&
                        <TaxonomySuggesterV2TableRow
                            d={taxonomySuggesterStoreV2.taxonomyTableController.data}
                            columns={columns}
                        />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TaxonomySuggesterV2TableRow: React.FC<TaxonomyRowProps> = ({d, columns}) => {
    const {taxonomySuggesterStoreV2} = useStores();

    const {children} = d;
    const label: string = d.data.viz.label
    const values: APIValues = d.data.apiValues
    const reviewStatus: ReviewStatusType | undefined = d.data.apiValues.review_status
    const [open, setOpen] = React.useState(false);
    let i = 0;

    const renderChildren = (childrenData: ExtendedD3HierarchyNode[]) => {
        return childrenData.map((childData, i) => (
            <TaxonomySuggesterV2TableRow key={i} d={childData} columns={columns}/>
        ));
    };


    const getSuggesterV2TypeClass = (src) => {
        if (src == "ERP 1") {
            return 'PrefixOverlap';
        } else if (src == "ERP 2") {
            return 'LabelFormat';
        } else if (src == "ERP 3") {
            return 'CategoryOverlap';
        } else {
            return 'Healthy';
        } 
    }


    if (d.data.viz.filtered) {
        return <></>
    }

    const indentation = `${d.depth - 1}rem`;
    const isRootNode = d.depth === 0;
    if (isRootNode && children && children.length > 0) {
        return <React.Fragment>{renderChildren(children)}</React.Fragment>;
    }
    
    function getRandomNumber(min, max) {
        // Generate a random number between 0 and 1
        const randomNumber = Math.random();
    
        // Scale the random number to the desired interval
        const scaledNumber = randomNumber * (max - min);
    
        // Shift the scaled number to the correct range
        const result = scaledNumber + min;
    
        return Math.floor(result);
    }


    return (
        <React.Fragment key={d.data.viz.id}>
            <TableRow>
                {/*<MithraTableCell c={columns[i++]}*/}
                {/*                 onClick={() => setOpen(!open)}>*/}
                {/*    /!*LENGTH_BUG={'' + children?.length}*!/*/}
                {/*    /!*viz.id={'' + d.data.viz.id}<br/>*!/*/}
                {/*    /!*data={'' + JSON.stringify(d.data)}*!/*/}
                {/*</MithraTableCell>*/}

                <MithraTableCell c={columns[i++]} style={{maxWidth: 'initial'}} onClick={() => setOpen(!open)}>
                    <div style={{ marginLeft: indentation }}>

                        {(children ? children.length > 0 && d.data.viz.canOpen : false) ?
                            <IconButton aria-label="expand row" size="large">
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton> :
                            <IconButton aria-label="expand row" size="large" style={{visibility: 'hidden'}}>
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton>}

                        {d.data.viz.hasChangesInChildren ?
                            <IconButton> <Warning style={{color: '#FAA915'}}/> </IconButton> : null}

                        {label}
                    </div>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={d.data.apiValues?.p__spend__sum *1000000} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {d.data.apiValues?.p__id__count} 
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {d.data.apiValues?.s__id__nunique}
                </MithraTableCell>
                {/* <MithraTableCell c={columns[i++]} >
                    {reviewStatus &&
                        <Chip label={getSuggesterV2Type(reviewStatus)} size="medium"
                              className={'chip ' + getSuggesterV2TypeClass(reviewStatus)} style={{width: '100%'}}/>}
                </MithraTableCell> */}
                <MithraTableCell c={columns[i++]} >
                    {d.data.apiValues?.src &&
                            <Chip label={d.data.apiValues?.src} size="medium"
                                  className={'chip ' + getSuggesterV2TypeClass(d.data.apiValues?.src)} style={{width: '100%'}}/>}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>

                    {/*<IconButton>*/}
                    {/*    <DriveFileRenameOutline*/}
                    {/*        onClick={() => taxonomyEditorStore.setUpdateCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <CallMerge*/}
                    {/*        onClick={() => taxonomyEditorStore.setMergeCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <CompareArrows*/}
                    {/*        onClick={() => taxonomyEditorStore.setMoveCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <Delete*/}
                    {/*        onClick={() => taxonomyEditorStore.isDeleteCategoryModalOpen = true}/>*/}
                    {/*</IconButton>*/}

                    {/* <div className='hc-btn-group'>
                    {(reviewStatus?.label_formatting || reviewStatus?.prefix_overlap || reviewStatus?.category_overlap) && (!reviewStatus?.rejected && !reviewStatus?.accepted) && <Button
                        className='acc-rej-edit'
                        startIcon={<CheckCircle />}
                        disabled={reviewStatus?.category_overlap?true:false}
                        onClick={() => {}}>
                    </Button>}

                    {(reviewStatus?.label_formatting || reviewStatus?.prefix_overlap || reviewStatus?.category_overlap) && (!reviewStatus?.rejected && !reviewStatus?.accepted) && <Button
                        className='acc-rej-edit'
                        startIcon={<Cancel />}
                        disabled={reviewStatus?.category_overlap?true:false}
                        onClick={() =>  {}}>
                    </Button>}

                    </div> */}

                </MithraTableCell>
            </TableRow>
            <TableRow className={open ? 'is-open' : ''}>
                <TableCell colSpan={columns.length} style={{padding: '0'}}>
                    {children && children.length > 0 && children.length &&
                        <Collapse in={open} timeout={getCollapseAnimationTiming(children)} unmountOnExit>
                            <Table>{renderChildren(children)}</Table>
                        </Collapse>}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
