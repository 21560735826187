import {m_taxonomy} from "./TaxonomyClasses";
import * as d3 from "d3";
import {ApiSuggestionTreeResponse} from "../ApiHelpers";

/**
 * This namespace should be kept clean
 * the health check problems will be resolved when we get there
 */
export namespace taxonomy_health_check {
    /**
     * The Health Check API Response from the API
     */
    export type HealthCheckApiResponse = {
        id: number
        taxonomy: number
        create_date: string
        last_update: string
        health_check_state: ApiSuggestionTreeResponse<APIValues>
        // And maybe more...
    }

    /**
     * The tree data in the API response
     */
    export type ApiTree = ApiSuggestionTreeResponse<APIValues>;

    /**
     * The data field in the response from the API
     *
     * There is an id field in here as well, that we do not use
     */
    export type APIValues = m_taxonomy.Data & {
        review_status?: ReviewStatusType
        suggestion_state?: HealthCheckDataType
    }
    // TODO: a possible solution to fix the propagation of the `sources` and `id` fields
    // export type APIValues = {
    //     id: number
    //     sources: number[]
    //     values: m_taxonomy.Data & {
    //         review_status?: ReviewStatusType
    //         suggestion_state?: HealthCheckDataType
    //     }
    // }

    //FIXME: @Sina this is where you can add the check_type, consider making it generic and making check_status optional

    /**
     * The data related to the review of the taxonomy suggester
     */
    export type ReviewStatusType = {
        prefix_overlap: boolean;
        label_formatting: boolean;
        category_overlap: boolean;
        accepted: boolean;
        newLabel: string;
        ai_suggestion: string;
        rejected: boolean;
    }

    /**
     * This needs to be filled in later
     */
    export type HealthCheckDataType = {
        // Empty for now
    }

    /**
     * Everything that is related to the rendering of the tree
     */
    export type VisualData = {
        /**
         * Managed only in the FE. Could be used for animations etc, should not be send to the BE
         */
        id: number
        label: string
        collapsed: boolean
        /**
         * The node is filtered out
         */
        filtered: boolean
        canOpen: boolean
        highlighted: boolean
        selected: boolean
        childSelected: boolean
        hasChangesInChildren?: boolean;
    }

    /**
     * A small wrapper around the d3.HierarchyNode to do a trick of hiding/showing nodes with the _children field
     *
     * ExtendedD3HierarchyNode.id is made by d3 (we do not modify it but we might read it)
     * example:
     * {
     *    id: 0,
     *    data: {
     *      apiValues: {},
     *      viz: {}
     *    }
     *    children: [],
     *    _children: []
     * }
     */
    export interface ExtendedD3HierarchyNode extends d3.HierarchyNode<HealthCheckDataField> {
        children: this[] // The current children to show
        _children: this[] // The original complete set of children that can be shown
    }

    export type HealthCheckDataField = {
        /**
         * This field is used to read information from the API, and specifies which information we will send to the Backend
         */
        apiValues: APIValues
        /**
         * Manages behavior of the nodes in the FE (that should not be send to the backend)
         */
        viz: VisualData
        id: number
        sources: number[]
    }

    export const DEFAULT_VISUAL_DATA: VisualData = {
        id: 0,
        label: '',
        collapsed: false,
        filtered: false,
        canOpen: false,
        highlighted: false,
        selected: false,
        childSelected: false,
        hasChangesInChildren: false,
    }

    export type Filter = undefined | ((d: ExtendedD3HierarchyNode) => boolean);
}
