import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {stores} from "../../stores";
import {Message} from "./ChatTypes";

type Suggestion = { label: string, query?: string };

class ChatController {
    overlayOpened = false

    suggestions: Suggestion[] = [
        {label: 'What spend opportunities are there in my data?'},
        {label: 'Where is most of my spend?'},
        {label: 'What is the average price of a laptop?'},
        {label: 'Tell me a joke', query: 'Tell me a joke about procurement'},
    ]

    aiIsLoading = false;

    history: Message[] = [];

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    onClickSuggestion(suggestion: Suggestion): Promise<void> {
        const userMsg: Message = {
            from: 'user',
            elements: [{type: 'text', message: suggestion.label}],
        };
        this.history.push(userMsg);

        // API call to send the message
        return this._handleSendUserChatMessage(suggestion.query ?? suggestion.label);
    }

    onSendUserMessage(message: string): Promise<void> {
        const userMsg: Message = {
            from: 'user',
            elements: [{type: 'text', message: message}],
        };
        this.history.push(userMsg);

        // API call to send the message
        return this._handleSendUserChatMessage(message);
    }

    _handleSendUserChatMessage(message: string) {
        this.aiIsLoading = true;
        return this.api.putChatMessage({message: message})
            .then(r => {
                const botMessage: Message = {
                    from: 'bot',
                    elements: r.data
                };
                this.addToHistory(botMessage);
            })
            .catch(error => {
                console.error('Error sending chat message:', error);
            })
            .finally(() => this.setAiLoading(false));
    };

    setOverlayOpen(open: boolean): void {
        this.overlayOpened = open;
    }

    addToHistory(...messages: Message[]) {
        this.history.push(...messages);
    }

    setAiLoading(loading: boolean) {
        this.aiIsLoading = loading;
    }
}

export default new ChatController(stores.materializedApi);
