import React, {useEffect, useMemo, useRef, useState} from "react";
import './MatchCategoriesTreeVisualization.scss';
import Margin from "../../../utils/margin";
import * as d3 from "d3";
import {ScaleBand} from "d3";
import {D3GSelection, D3S} from "../../../utils/global";
import {
    CollapsibleIndentTreeBuilder,
    CollapsibleIndentTreeData,
    FilterSpecification,
    Options as TreeOptions,
    TreeDataType
} from "../collapsible-indent-tree/CollapsibleIndentTree";
import {Subscription} from "rxjs";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {
    TaxonomyMapTreeControllerDelegate
} from "../../../pages/taxonomy-mapper/store/TaxonomyMapTreeControllerDelegate";
import {observer} from "mobx-react-lite";

const MIN_LINE_HEIGHT = 14 + 10;
// font-size set in CSS
const HIDDEN_ROOT = true;

export type MatchDataType = {
    selected: boolean
    map?: m_taxonomy.MaterializedCategoryMap
    leftId: number
    rightId: number
    beingCreated: boolean
}
export type MatchNodeDataType = {
    isLeft: boolean
    /**
     * @deprecated Not used?
     */
    valueTitle: string
    data: m_taxonomy.MaterializedTaxonomyData
}

export type Tree = CollapsibleIndentTreeData<MatchNodeDataType>;  // d3.HierarchyNode
export type Node = TreeDataType<MatchNodeDataType>;

export type Data = {
    left: Tree,
    right: Tree,
    connections: MatchDataType[],
}
type CurveData = {
    ps: [number, number][]
    d: MatchDataType
}


export type Options = {
    width: number
    margin: Margin
    onClickData?: (d: Tree) => void
    circleSize: number
    circleMargin: number
    centralSectionPortion: number // Portion of the width used for labels
    leftFilterSpec?: FilterSpecification
    rightFilterSpec?: FilterSpecification
}

/**
 * TODO: This class is very obscure
 */
export type MatchUpdateData = {
    points: Tree[],
    connections: MatchDataType[]
};

type Props = {
    data?: Data
    options?: Partial<Options>
    matchUpdateData?: MatchUpdateData
    /**
     * Note: this parameter mixes the controller (mobx) and view (d3.js), the interface for this component is entangled
     */
    treeController: TaxonomyMapTreeControllerDelegate
}
export const MatchCategoriesTreeVisualization: React.FC<Props> = observer(({
                                                                               data,
                                                                               options,
                                                                               matchUpdateData,
                                                                               treeController
                                                                           }) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const o = useMemo<Options>(() => ({
        width: 0,
        height: 0,
        margin: {
            left: 0,
            right: 0,
            top: MIN_LINE_HEIGHT / 2,
            bottom: MIN_LINE_HEIGHT / 2,
        },
        circleSize: 15,
        circleMargin: 15,
        centralSectionPortion: 2 / 12, // The proportion of the center section
        ...options,
    }), [options]);

    const [controller, setController] = useState<MatchCategoriesTreeVisualizationController | null>(null);
    useEffect(() => {
        if (controller || !svgRef.current || !data) return;
        const svg = d3.select(svgRef.current as SVGElement)
        const root = svg.append('g')
        const newController = new MatchCategoriesTreeVisualizationController(root, o, data, svg, treeController);
        setController(newController)
        // return () => {
        //     console.log('Deconstructing MatchCategoriesTreeVisualizationController');
        //     newController.stopFiltering();
        // }
        // eslint-disable-next-line
    }, [data, svgRef, o, controller])

    useEffect(() => {
        if (!controller || !matchUpdateData) return
        controller.update(matchUpdateData);
    }, [controller, matchUpdateData])

    return <svg
        className="match-categories-viz-v2"
        ref={svgRef}
        viewBox={`0 0 ${o.width} ${700 * MIN_LINE_HEIGHT}`} // ATK Magic number
        style={{width: '100%', height: 'auto'}}
    />;
})

/**
 * TODO: Add documentation to this class
 */
export class MatchCategoriesTreeVisualizationController {
    readonly leftTree: CollapsibleIndentTreeBuilder<MatchNodeDataType>;
    readonly rightTree: CollapsibleIndentTreeBuilder<MatchNodeDataType>;

    private readonly curveGroup: D3GSelection<any>;
    private readonly labelGroup: D3GSelection<any>;

    // private curve = d3.curveBundle.beta(0.9);
    private readonly curve = d3.curveBasis;
    // private curve = d3.curveLinear;

    // TODO: This can be condensed by a lot!
    private readonly treeSectionWidth: number;
    private readonly centralSectionWidth: number;
    private readonly selectionGapWidth: number;

    private readonly lX0: number;
    private readonly lX1: number;
    private readonly rX0: number;
    private readonly rX1: number;

    private readonly lCircleX: number;
    private readonly lCircleXin: number;
    private readonly rCircleX: number;
    private readonly rCircleXin: number;
    private readonly effectiveGap: number;
    private readonly curveP0: number;
    private readonly curveP1: number;
    private readonly curveP2: number;
    private readonly curveP4: number;

    private lastUpdateData: undefined | MatchUpdateData;
    private readonly filterSubscriptions = new Subscription();

    private rightRootYOffset = 0;
    private leftRootYOffset = 0;

    /**
     * @param root
     * @param options
     * @param data
     * @param svgRoot ATK Ad hoc height hack
     * @param controller
     */
    constructor(
        private root: D3GSelection<any>,
        private options: Options,
        private data: Data,
        private svgRoot: d3.Selection<SVGElement, unknown, null, undefined>,
        private controller: TaxonomyMapTreeControllerDelegate,
    ) {
        this.root
            .classed('match-category-tree-viz', true)
            .classed('root', true)
            .attr("transform", "translate(" + this.options.margin.left + "," + this.options.margin.top + ")")

        const graphWidth = this.options.width - this.options.margin.left - this.options.margin.right;

        // The tree dimensions:
        //           |<-CENTER_PORTION->|
        //  leftTree |  |            |  | rightTree
        //             (o)~~~~~~~~~~(o)

        this.treeSectionWidth = (1 - this.options.centralSectionPortion) / 2 * graphWidth;
        this.centralSectionWidth = this.options.centralSectionPortion * graphWidth;
        this.selectionGapWidth = this.centralSectionWidth * 0.2;

        this.lX0 = 0;
        this.lX1 = this.treeSectionWidth;
        this.rX0 = this.treeSectionWidth + this.centralSectionWidth;
        this.rX1 = graphWidth;

        // this.root.append('rect')
        //     .attr('width', this.treeSectionWidth)
        //     .attr('height', 500);
        // this.root.append('rect')
        //     .attr('x', this.treeSectionWidth)
        //     .attr('width', this.centralSectionWidth)
        //     .attr('height', initGraphHeight * 2 / 5);

        this.lCircleX = this.lX1 + this.options.circleMargin + this.options.circleSize / 2;
        this.lCircleXin = this.lCircleX + this.options.circleSize / 2;
        this.rCircleX = this.rX0 - this.options.circleMargin - this.options.circleSize / 2;
        this.rCircleXin = this.rCircleX - this.options.circleSize / 2;
        this.effectiveGap = this.rCircleXin - this.lCircleXin;
        this.curveP0 = this.lCircleXin;
        this.curveP1 = this.lCircleXin + this.effectiveGap / 3;
        this.curveP2 = this.rCircleXin - this.effectiveGap / 3;
        this.curveP4 = this.rCircleXin;

        this.curveGroup = this.root.append('g')
            .classed('curved', true)

        this.labelGroup = this.root.append('g')
            .classed('labels', true)

        // LEFT
        const leftTreeGroup = this.root.append('g')
            .classed('category-tree left', true)
        const treeOptionsLeft: TreeOptions<MatchNodeDataType> = {
            height: 0,
            width: this.treeSectionWidth,
            margin: {
                left: this.lX0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            $filter: undefined, // filtering is done in this class, as it affects the lines and selection circles
            leftToRight: false,
            columns: [],
            overrideOnClick: d => this.controller.onClickNode(d as any, true),

        }
        this.leftTree = new CollapsibleIndentTreeBuilder<MatchNodeDataType>(
            leftTreeGroup, treeOptionsLeft, data.left, undefined, HIDDEN_ROOT
        );

        // RIGHT
        const rightTreeGroup = this.root.append('g')
            .classed('category-tree right', true)
        const treeOptionsRight: TreeOptions<MatchNodeDataType> = {
            height: 0,
            width: this.treeSectionWidth,
            margin: {
                left: this.rX0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            $filter: undefined, // filtering is done in this class, as it affects the lines and selection circles
            leftToRight: true,
            columns: [],
            overrideOnClick: d => this.controller.onClickNode(d as any, false),
        }
        this.rightTree = new CollapsibleIndentTreeBuilder<MatchNodeDataType>(
            rightTreeGroup, treeOptionsRight, data.right, undefined, HIDDEN_ROOT
        );

        this.resetYAxis();

        this.registerFilterSubscriptions(true)
        this.registerFilterSubscriptions(false)

        this.controller.setController(this); // TODO: Architectural design missing for this, dirty hack ...
    }

    get leftYAxis(): ScaleBand<string> {
        console.assert(this.leftTree.yAxis)
        return this.leftTree.yAxis as ScaleBand<string>;
    }

    get rightYAxis(): ScaleBand<string> {
        console.assert(this.rightTree.yAxis)
        return this.rightTree.yAxis as ScaleBand<string>;
    }

    private static getGraphHeight(n: number): number {
        return MIN_LINE_HEIGHT * n;
    }

    /**
     * ATK Ad hoc height hack
     */
    private setSvgGraphHeight(graphHeight: number) {
        const height = graphHeight + this.options.margin.top + this.options.margin.bottom
        const viewBox = this.svgRoot.attr('viewBox').split(' ')
        viewBox[3] = String(height);
        this.svgRoot.attr('viewBox', viewBox.join(' '));
    }

    /**
     * ATK Ad hoc filtering hack
     * @param left or right
     */
    registerFilterSubscriptions(left: boolean) {
        const filterSpec = left ? this.options.leftFilterSpec : this.options.rightFilterSpec;
        const tree = left ? this.leftTree : this.rightTree;
        // const otherTree = left ? this.rightTree : this.leftTree;

        // TODO: This subscription is not disposed of
        filterSpec?.byLevel?.subscribe(level => {
            tree.openToLevel(level)
            this.resetYAxis()
        })

        tree.registerOnRedraw(() => {
            this.redraw();
        })
    }

    resetGraphDimensions() {
        const leftNodes = this.leftTree.getNodesOrdered()
        const leftHeight = MatchCategoriesTreeVisualizationController.getGraphHeight(leftNodes.length)
        const rightNodes = this.rightTree.getNodesOrdered()
        const rightHeight = MatchCategoriesTreeVisualizationController.getGraphHeight(rightNodes.length)
        this.setSvgGraphHeight(Math.max(leftHeight + this.leftRootYOffset, rightHeight + this.rightRootYOffset))
    }

    resetYAxis() {
        const leftNodes = this.leftTree.getNodesOrdered();

        const leftHeight = MatchCategoriesTreeVisualizationController.getGraphHeight(leftNodes.length)
        const leftDomain = leftNodes.map(d => String(d.data.id));
        const leftYAxis = d3.scaleBand()
            .domain(leftDomain)
            .range([0, leftHeight])
        this.leftTree.setYAxis(leftYAxis)

        const rightNodes = this.rightTree.getNodesOrdered();
        const rightHeight = MatchCategoriesTreeVisualizationController.getGraphHeight(rightNodes.length)
        const rightDomain = rightNodes.map(d => String(d.data.id));
        const rightYAxis = d3.scaleBand()
            .domain(rightDomain)
            .range([0, rightHeight])
        this.rightTree.setYAxis(rightYAxis)

        this.setSvgGraphHeight(Math.max(leftHeight + this.leftRootYOffset, rightHeight + this.rightRootYOffset))

        this.leftTree.redraw();
        this.rightTree.redraw();
    }

    redraw() {
        this.update(this.lastUpdateData)
    }

    update(u?: MatchUpdateData) {
        if (u) {
            this.lastUpdateData = u;
            this.drawCurves(u.connections);
            this.drawPoints(u.points, u.connections)
        }
    }

    drawCurves(cmpConnectionData: MatchDataType[]) {
        const cData: CurveData[] = cmpConnectionData
            .map(d => [d, this.leftYAxis(String(d.leftId)), this.rightYAxis(String(d.rightId))])
            .filter(([_, l, r]) => l !== undefined && r !== undefined)  // Only draw the ones that are on the yAxis
            .map(([d, l, r]) => ({
                ps: [
                    [this.curveP0, (l as number) + this.leftRootYOffset],
                    [this.curveP1, (l as number) + this.leftRootYOffset],
                    [this.curveP2, (r as number) + this.rightRootYOffset],
                    [this.curveP4, (r as number) + this.rightRootYOffset],
                ] as [number, number][],
                d,
            } as CurveData));

        // const line = d3.line<CurveData>(d => d.ps[0], d => d.ps[1])
        const line = d3.line()
            .curve(this.curve)

        this.curveGroup
            .selectAll<SVGGElement, CurveData>('g.curve')
            // .data(cData)
            .data(cData, d => `${d.d.leftId}|${d.d.rightId}`)
            .join(
                enter => {
                    const g = enter.append('g');
                    g.append('path').attr('d', d => line(d.ps))
                    return g;
                },
                update => {
                    update.select('path').attr('d', d => line(d.ps))
                    return update
                },
                exit => exit.remove(),
            )
            .classed('curve', true)
            .classed('initial', d => Boolean(d.d.map?.ai_suggestion))
            .classed('created', d => Boolean(d.d.map?.user_suggestion))
            .classed('selected', d => d.d.selected)


        // const flows = this.root
        //     .append('g')
        //     .classed('flows', true)
        //     .selectAll('g.flow')
        //     .data(data)
        //     .join('g')
        //     .classed('flow', true);
        // const flowData: FlowData[] = [...Array(data.length - 1)].map((_, i) => {
        //     const prev: Tree = data[i];
        //     const next: Tree = data[i + 1];
        //     return {
        //         i: i as number,
        //         left: [
        //             {i: 0, v: prev.leftRest.v, dy: -overlap},
        //             {i: 0, v: prev.leftRest.v, dy: 0},
        //             {i: 0, v: prev.leftRest.v, dy: overlap},
        //             {i: 1, v: next.leftRest.v, dy: -overlap},
        //             {i: 1, v: next.leftRest.v, dy: 0},
        //             {i: 1, v: next.leftRest.v, dy: overlap},
        //         ] as any[],
        //         right: [
        //             {i: 0, v: prev.rightCommon.v, dy: -overlap},
        //             {i: 0, v: prev.rightCommon.v, dy: 0},
        //             {i: 0, v: prev.rightCommon.v, dy: overlap},
        //             {i: 1, v: next.rightCommon.v, dy: -overlap},
        //             {i: 1, v: next.rightCommon.v, dy: 0},
        //             {i: 1, v: next.rightCommon.v, dy: overlap},
        //         ] as any[],
        //     }
        // })
        //
        // const flowLeft = d3.area<any>()
        //     .curve(curve)
        //     .x0(splitLocL - LINE_WIDTH)
        //     .x1(({i, v}) => (xAxises[i].left)(v) as number + LINE_WIDTH)
        //     .y(({i, v, dy}) => (
        //         i === 0 ? yAxis(`${DATA_OFFSET + i}`) as number + yAxis.bandwidth() * (1 - BAR_PADDING) : yAxis(`${DATA_OFFSET + i}`) as number
        //     ) + dy)
        // flows.append('path')
        //     .classed('left', true)
        //     .attr('d', flowLeft(flowData[DATA_OFFSET].left) as string)
        //
        // const flowRight = d3.area<any>()
        //     .curve(curve)
        //     .x0(splitLocR + LINE_WIDTH)
        //     .x1(({i, v}) => (xAxises[i].right)(v) as number - LINE_WIDTH)
        //     .y(({i, v, dy}) => (
        //         i === 0 ? yAxis(`${DATA_OFFSET + i}`) as number + yAxis.bandwidth() * (1 - BAR_PADDING) : yAxis(`${DATA_OFFSET + i}`) as number
        //     ) + dy)
        // flows.append('path')
        //     .classed('right', true)
        //     .attr('d', flowRight(flowData[DATA_OFFSET].right) as string)
    }

    drawPoints(pointData: Tree[], connectionData: MatchDataType[]) {
        const onClick = this.options.onClickData?.bind(this);
        const outerCircleRadius = this.options.circleSize / 2;
        const innerCircleRadius = outerCircleRadius * .60;

        const data = pointData
            .map(d => [
                d,
                d.data.values.isLeft ? this.leftYAxis(String(d.data.id)) : this.rightYAxis(String(d.data.id)),
            ] as const)
            .filter(([, y]) => y !== undefined) // Only draw the ones that are on the yAxis
            .map(([d, y]) => ({
                d,
                y: (y as number) + (d.data.values.isLeft ? this.leftRootYOffset : this.rightRootYOffset),
                yBandwidth: d.data.values.isLeft ? this.leftYAxis.bandwidth() : this.rightYAxis.bandwidth(),
                key: (d.data.values.isLeft ? 'l' : 'r') + `${d.data.id}`
            }))
        type DataType = (typeof data)[number]
        this.labelGroup
            .selectAll('g.label')
            .data(data, (el: any) => (el as DataType).key)
            .join(
                enter => {
                    const g = enter.append('g')
                        .classed('selection-dot', true);

                    // For selection overlay over the labels of the tree's
                    // g.append('rect')
                    //     .classed('hidden', true)
                    //     .attr('x', el => el.d.data.values.isLeft ? this.lX0 : this.rX0 - this.centralSectionWidth / 2 + this.selectionGapWidth / 2)
                    //     .attr('y', el => el.y - el.yBandwidth / 2)
                    //     .attr('width', this.treeSectionWidth + this.centralSectionWidth / 2 - this.selectionGapWidth / 2)
                    //     .attr('height', el => el.yBandwidth)
                    // For selection overlay only on the selection boxes

                    const hoverOverlayMargin = this.options.circleMargin / 2
                    g.append('rect')
                        .classed('hidden', true)
                        .classed('selection-bg', true)
                        .attr('x', el => el.d.data.values.isLeft ? this.lX1 + hoverOverlayMargin : this.rX0 - this.centralSectionWidth / 2 + this.selectionGapWidth / 2)
                        .attr('y', el => el.y - el.yBandwidth / 2)
                        .attr('width', this.centralSectionWidth / 2 - this.selectionGapWidth / 2 - hoverOverlayMargin)
                        .attr('height', el => el.yBandwidth)


                    g.append('circle')
                        .classed('outer', true)
                        .attr('cx', el => el.d.data.values.isLeft ? this.lCircleX : this.rCircleX)
                        .attr('cy', el => el.y)
                        .attr('r', outerCircleRadius)
                    g.append('circle')
                        .classed('inner', true)
                        .attr('cx', el => el.d.data.values.isLeft ? this.lCircleX : this.rCircleX)
                        .attr('cy', el => el.y)
                        .attr('r', innerCircleRadius)

                    g.append('rect')
                        .classed('outer', true)
                        .attr('x', el => (el.d.data.values.isLeft ? this.lCircleX : this.rCircleX) - outerCircleRadius)
                        .attr('y', el => el.y - outerCircleRadius)
                        .attr('width', outerCircleRadius * 2)
                        .attr('height', outerCircleRadius * 2)
                    g.append('rect')
                        .classed('inner', true)
                        .attr('x', el => (el.d.data.values.isLeft ? this.lCircleX : this.rCircleX) - innerCircleRadius)
                        .attr('y', el => el.y - innerCircleRadius)
                        .attr('width', innerCircleRadius * 2)
                        .attr('height', innerCircleRadius * 2)

                    return g;
                },
                update => {
                    update.select('rect.selection-bg')
                        .attr('y', el => el.y - el.yBandwidth / 2)
                    update.select('circle.outer')
                        .attr('cy', el => el.y)
                    update.select('circle.inner')
                        .attr('cy', el => el.y)
                    update.select('rect.outer')
                        .attr('y', el => el.y - outerCircleRadius)
                    update.select('rect.inner')
                        .attr('y', el => el.y - innerCircleRadius)
                    return update;
                },
                exit => exit.remove(),
            )
            .classed('label', true)
            .classed('left', el => el.d.data.values.isLeft)
            .classed('right', el => !el.d.data.values.isLeft)
            .classed('selected', el => el.d.data.selected)
            .classed('child-selected', el => el.d.data.childSelected)
            .classed('connected-not-init', el => el.d.data.values.isLeft
                ? Boolean(connectionData.find(c => c.leftId === el.d.data.id && !c.map?.ai_suggestion))
                : Boolean(connectionData.find(c => c.rightId === el.d.data.id && !c.map?.ai_suggestion))
            )
            .classed('connected-as-init', el => el.d.data.values.isLeft
                ? Boolean(connectionData.find(c => c.leftId === el.d.data.id && c.map?.ai_suggestion))
                : Boolean(connectionData.find(c => c.rightId === el.d.data.id && c.map?.ai_suggestion))
            )
            .classed('not-connected', el => el.d.data.values.isLeft
                ? !Boolean(connectionData.find(c => c.leftId === el.d.data.id && c.map))
                : !Boolean(connectionData.find(c => c.rightId === el.d.data.id && c.map))
            )
            .classed('leaf', el => el.d.height === 0)
            .on('click', function () {
                const el = d3.select(this).datum() as DataType;
                if (onClick)
                    onClick(el.d)
            })

        // labelGroup.append('text')
        //     .attr('dominant-baseline', 'middle')
        //     .attr("text-anchor", el => el.d.data.values.left ? 'end' : 'start')
        //     .attr('x', el => el.d.data.values.left ? lX1 : rX0)
        //     .attr('y', el => el.d.data.values.left ? leftYAxis(String(el.d.data.id)) as number : rightYAxis(String(el.d.data.id)) as number)
        //     .text(el => el.d.data.label || '')
        // // .attr('font-family', 'FontAwesome')
        // // .text(el => '\uf118')
        // // Process the data
        // const allValues = data.reduce<number[]>((arr, d) => arr.concat(getBoxValues(d.box)), []);
        // const max = d3.max(allValues) || 1;
        //
        // const HIDE_ZEROLINE = false;
        // const min = HIDE_ZEROLINE ? 0 : (d3.min(allValues) || 0);
        //
        // // set the range and domain for the axis
        // let catAxis = d3.scaleBand()
        //     .domain(data.map(el => d.name))
        //     .range([0, graphWidth])
        // const BAR_PADDING = 0.2;
        // const BAR_SPACING = BAR_PADDING / 2 * catAxis.bandwidth();
        //
        // const valRange = [this.graphHeight, 0]
        // let valueAxis = d3.scaleLinear()
        //     .domain([min, max])
        //     .range(valRange)
        //
        // // append the rectangles for the bar chart
        // const barGroups = this.root
        //     .append('g')
        //     .classed('data', true)
        //     .selectAll('g.box-wrapper')
        //     .data(data)
        //     .join('g')
        //     .classed('box-wrapper', true);
        //
        // // Make sure the parent-group size to the full field
        // barGroups.append('rect')
        //     .attr('x', el => (catAxis(d.name) as number))
        //     .attr('y', 0)
        //     .attr('width', catAxis.bandwidth())
        //     .attr('height', this.graphHeight)
        //     .classed('hover-overlay', true)
        //
        // barGroups.on('mouseenter', function (event) {
        //     const data = d3.select(this).datum()
        //     console.log('data', data);
        //     // const [x, y] = d3.pointer(event)
        //     // var mouse = d3.mouse(this);
        //     // var x = mouse[0];
        //     // var y = mouse[1];
        //
        //     // console.log('x, y', x, y);
        //
        // })
        //
        // if (onCategoryClick) {
        //     barGroups
        //         .classed('clickable', true)
        //         .on('click', function () {
        //             const data = d3.select(this).datum() as BoxplotDataPoint;
        //             onCategoryClick(data);
        //         })
        // }
        //
        // // Draw the boxplot
        // // - Main line
        // barGroups.append('line')
        //     .classed('backbone', true)
        //     .attr("x1", el => (catAxis(d.name) as number) + catAxis.bandwidth() / 2)
        //     .attr("x2", el => (catAxis(d.name) as number) + catAxis.bandwidth() / 2)
        //     .attr("y1", el => valueAxis(d.box.q0) as number)
        //     .attr("y2", el => valueAxis(d.box.q4) as number)
        // // - Box
        // barGroups.append('rect')
        //     .classed('box', true)
        //     .attr("x", el => (catAxis(d.name) as number) + BAR_SPACING)
        //     .attr("y", el => valueAxis(d.box.q3) as number)
        //     .attr("width", catAxis.bandwidth() * (1 - BAR_PADDING))
        //     .attr("height", el => valueAxis(d.box.q1) - valueAxis(d.box.q3))
        //     .attr('fill', el => vizColor(d.name))
        // // - Horizontal lines
        // barGroups.append('line')
        //     .classed('line line-min', true)
        //     .attr("x1", el => (catAxis(d.name) as number) + BAR_SPACING)
        //     .attr("x2", el => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
        //     .attr("y1", el => valueAxis(d.box.q0) as number)
        //     .attr("y2", el => valueAxis(d.box.q0) as number)
        // barGroups.append('line')
        //     .classed('line line-med', true)
        //     .attr("x1", el => (catAxis(d.name) as number) + BAR_SPACING)
        //     .attr("x2", el => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
        //     .attr("y1", el => valueAxis(d.box.q2) as number)
        //     .attr("y2", el => valueAxis(d.box.q2) as number)
        // barGroups.append('line')
        //     .classed('line line-max', true)
        //     .attr("x1", el => (catAxis(d.name) as number) + BAR_SPACING)
        //     .attr("x2", el => (catAxis(d.name) as number) + catAxis.bandwidth() - BAR_SPACING)
        //     .attr("y1", el => valueAxis(d.box.q4) as number)
        //     .attr("y2", el => valueAxis(d.box.q4) as number)
        //
        // // Add the horizontal Axis
        // this.root.append("g")
        //     .classed('category-axis', true)
        //     .attr("transform", "translate(0," + this.graphHeight + ")")
        //     .call(d3.axisBottom(catAxis)
        //             // .tickFormat(() => '') // Hide the labels
        //             .tickSize(0) // Hide ticks
        //         // .tickValues([]) // Hide the ticks AND labels
        //     )
        //     .call(g => g.select('.domain').remove())
        // if (min !== 0) {
        //     this.root.append('g')
        //         .classed('zero-axis', true)
        //         .append('line')
        //         .attr("x1", 0)
        //         .attr("x2", graphWidth)
        //         .attr("y1", valueAxis(0) as number)
        //         .attr("y2", valueAxis(0) as number)
        // }
        //
        // // Add the vertical axis
        // // Build sensible graph for values [0-100], [0-1000], [0-10K], [0-100K], etc
        // this.root.append("g")
        //     .classed('value-axis', true)
        //     .call(
        //         d3.axisLeft(valueAxis)
        //             .ticks(5)
        //             .tickFormat(v => d3.format("~s")(v))
        //     )
    }

    findNodeSelection(nodeId: number): D3GSelection<any> | undefined {
        let result: D3GSelection<any> | undefined = undefined;
        this.labelGroup.selectAll<SVGGElement, any>('g.label').each(function (element) {
            if (result === undefined && element.d.data.id === nodeId) {
                result = d3.select(this);
            }
        })
        return result;
    }

    stopFiltering() {
        this.filterSubscriptions.unsubscribe();
    }

    /**
     * @param leftRootYOffset
     * @param rightRootYOffset
     * @param keepInView The ID of the node in the left tree
     */
    setYOffsets(leftRootYOffset: number, rightRootYOffset: number, keepInView?: number) {
        this.leftRootYOffset = leftRootYOffset;
        this.rightRootYOffset = rightRootYOffset;
        this.leftTree.setRootYOffset(leftRootYOffset);
        this.rightTree.setRootYOffset(rightRootYOffset);
        this.resetGraphDimensions()
        this.redraw();

        if (keepInView) {
            const leftNode = this.findNodeSelection(keepInView)
            if (leftNode) {
                const s = leftNode.select('rect.selection-bg') as D3S<SVGRectElement, any>;
                this.scrollIntoView(
                    Number(s.attr('y')) + Number(s.attr('height')) / 2
                )
            }
        }
    }

    scrollIntoView(leftFocusY: number) {
        const wrapper = d3.select('#taxonomy-mapper-viz-wrapper');
        // const vizHeight = wrapper.property('scrollHeight')
        const wrapperHeight = wrapper.property('clientHeight')
        const currentScrollTop = wrapper.property('scrollTop')
        const currentScrollBottom = currentScrollTop + wrapperHeight;

        // console.log('scrollIntoView:', {wrapperHeight, currentScrollTop, leftFocusY})

        if (leftFocusY < currentScrollTop || leftFocusY > currentScrollBottom) {
            const desiredScrollTop = leftFocusY - wrapperHeight / 2;

            // console.log('scrollIntoView.desiredScrollTop:', desiredScrollTop)
            wrapper.property('scrollTop', desiredScrollTop);

            // wrapper.transition()
            //     .duration(3000)
            //     .tween('scrollIntoView', function (this: any) {
            //         console.log('scrollIntoView: scrollIntoView', this.scrollTop, desiredScrollTop)
            //         const i = d3.interpolate(this.scrollTop, desiredScrollTop);
            //         return function (this: any, t) {
            //             this.scrollTop = i(t);
            //         };
            //     })
        }
    }

}
