import {makeAutoObservable} from "mobx";
import ProfileStore from "../../../stores/ProfileStore";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {environment} from "../../../env";
import {PpvGroup,} from "../../../services/classes/MaterializedClasses";
import {PpvPageController} from "./PpvPageController";
import {
    PpvGroupFilter,
    PpvItemDeletionFilter,
    PpvItemListFilter,
    PpvLevelStatisticsFilter,
} from "./classes/PpvApiClasses";
import {PpvPartRowState} from "./classes/PpvPartRowState";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";

declare type PpvGroupPageManager = PageResponseManager<PpvGroupFilter, PpvGroupRowState, {
    d: PpvGroup,
    f: PpvGroupFilter,
}>;

/**
 * This reactive controller is responsible for the state of the grouped review table.
 */
export class PpvPageDataController {

    constructor(
        private ppvPageController: PpvPageController,
        private matApi: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }


    /**
     * Keep track of the suppliers table by a single async request manager
     */
    readonly ppvGroupPages: PpvGroupPageManager = new PageResponseManager(
        this.profile.p.ppvTablePageSize ?? environment.defaultPpvTablePageSize,
        (page, f) => {
            f.page = page;
            f.page_size = this.ppvGroupPages.pageSize;
            return this.matApi.listPpvGroup(f)
                .then(r => {
                    // Inject the type to ensure we can use typescript's dynamic type resolution
                    r.data.results = r.data.results.map(d => ({d, f: f})) as any
                    return r as any;
                });
        },
        ({d}) => new PpvGroupRowState(d)
    )

    fetchGroups(f: PpvGroupFilter) {
        return this.matApi.listPpvGroup(f).then(r => {
            return r.data.results.map(d => new PpvGroupRowState(d));
        })
    }

    onOpenClosePpvGroup(groupedRow: PpvGroupRowState) {
        //TODO: Consider fetching the groups as well,
        // we might have problems with inconsistencies between ppvGroups and ppvParts being called
        // in different points of time
        groupedRow.setOpen(!groupedRow.open);
        if (groupedRow.open) {
            groupedRow.setIsLoading(true);
            this.matApi.listPpvParts(this.ppvPageController.ppvPageFilterController.selectedItemFilter(groupedRow)).then(r => {
                groupedRow.update(groupedRow.data, r.data)
            }).finally(() => {
                groupedRow.setIsLoading(false);
            })
        }
    };

    deletePpvItem(group: PpvGroupRowState, part: PpvPartRowState): Promise<void> {
        //Convert PartFilter to ItemFilter
        const pf: PpvItemListFilter = this.ppvPageController.ppvPageFilterController.selectedItemFilter(group)
        const partFilter: PpvItemDeletionFilter = {
            databag: pf.databag,
            business_unit: pf.business_unit,
            p_description: part.data.p_description,
            p_name: part.data.p_name,
            supplier_id: part.data.supplier_id,
            cc_description: part.data.cc_description,
            quarter_date__gte: pf.quarter_date__gte,// start
            quarter_date__lte: pf.quarter_date__lte, // end
            level: pf.level,
            l1: pf.l1,
            l2: pf.l2,
            l3: pf.l3,
            l4: pf.l4,
            l5: pf.l5,
            l6: pf.l6,
            l7: pf.l7,
            l8: pf.l8,
        }

        let f: PpvLevelStatisticsFilter = {
            databag: pf.databag,
            businessUnitId: pf.business_unit,
            quarter_date__gte: pf.quarter_date__gte,
            quarter_date__lte: pf.quarter_date__lte
        }

        return this.matApi.deletePpvItems(partFilter).then(() => {
            Promise.all([
                this.matApi.listPpvParts(pf),
                this.matApi.fetchPpvGroupByDescription(partFilter.databag, partFilter.p_description),
                this.ppvPageController.ppvPageStatisticsController._ppvLevelStatistics.request(f)
            ] as const)
                .then(([rParts, rGroup]) => {
                    if (!rGroup.data.length) {
                        this.ppvGroupPages.reloadView()
                    } else {
                        group.update(rGroup.data[0], rParts.data)
                    }
                })
        })
    }


    /**
     * Is loading the table
     */
    get isLoadingParts(): boolean {
        return this.ppvGroupPages.isLoading;
    }
}