import SitApi from "../../SitApit";
import {makeAutoObservable} from "mobx";
import {sitStores} from "../../../stores";
import {FinancialDashboardStore} from "../../stores/FinancialDashboardStore";
import {DimensionValue, DoiStatus} from "../../ApiTypes";
import {lpad} from "../../../utils/js-utils";

class PipelineDashboardController {

    constructor(
        private api: SitApi,
        private fb: FinancialDashboardStore,
    ) {
        makeAutoObservable(this, {});
    }

    getDoiMonthCmp(value: DimensionValue): { DOI: string, source: [string, number][] }[] | null {
        const months = [this.fb.lastMonth.getMonth(), this.fb.month.getMonth()];
        const data = this.fb.getContributionPerDoi(this.fb.contributionPerDoiSumRM.result, value);
        if (data === null) return null;
        return data.map(c => ({
            DOI: DoiStatus[c.doi - 1],
            source: c.data
                .filter(s => months.includes(s.month.getMonth()))
                .map(d => ([
                    `${d.month.getFullYear()}-${lpad(String(d.month.getMonth() + 1), '0', 2)}`,
                    Number(d.value),
                ])),
        }));
    }

    get ppvSavings() {
        return this.getDoiMonthCmp(this.fb.activeSavingsValue);
    }

    get nonPpvSavings() {
        return this.getDoiMonthCmp(this.fb.activeAvoidanceValue);
    }

    getContributionsPerClusterCmp(clusterId: number): { DOI: string, source: [string, number][] }[] | null {
        if (this.fb.contributionPerClusterSavings === null) return null;

        const clusterData = this.fb.contributionPerClusterSavings.find(c => c.clusterId === clusterId)
        if (!clusterData) return null;

        const months = [this.fb.lastMonth.getMonth(), this.fb.month.getMonth()];
        return clusterData.doiData.map(c => ({
            DOI: `DOI${c.doi}`,
            source: c.data
                .filter(s => months.includes(s.month.getMonth()))
                .map(d => ([
                    `${d.month.getFullYear()}-${lpad(String(d.month.getMonth() + 1), '0', 2)}`,
                    Number(d.value),
                ])),
        }))
    }
}

export default new PipelineDashboardController(sitStores.sitApi, sitStores.fbStore);
