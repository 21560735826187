import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {BusinessUnit} from "../../services/classes/MaterializedClasses";
import {AxoisRequestManager} from "./RequestManager";
import {from} from "rxjs";
import {Bag} from "../../services/classes/Bag";
import ProfileStore from "../ProfileStore";

type NoBusinessUnit = Pick<BusinessUnit, 'bu_name' | 'id'> & { id: -1 | -2 };

export class BusinessUnitManager {

    static readonly ALL_BU: NoBusinessUnit = {id: -2, bu_name: 'Show everything'};

    readonly _buRequestManager = new AxoisRequestManager<number, BusinessUnit[]>(bagId => from(this.api.listBusinessUnitData(bagId, {
        field: 'bu_name',
        desc: false
    })))

    selectedBusinessUnitOption: BusinessUnit | NoBusinessUnit = BusinessUnitManager.ALL_BU;

    constructor(private profileStore: ProfileStore, private api: MithraMaterializedApi) {
        makeAutoObservable(this);
    }

    init(bag: Bag) {
        this.selectedBusinessUnitOption = BusinessUnitManager.ALL_BU;
        this._buRequestManager.request(bag.id);
    }

    /**
     * Returns the selected business unit id,
     * or null if the "No business unit" option is selected,
     * or undefined if the "Show everything" option is selected
     */
    get selectedBusinessUnitId(): number | null | undefined {
        switch (this.selectedBusinessUnitOption.id) {
            case -1:
                return null;
            case -2:
                return undefined;
            default:
                return this.selectedBusinessUnitOption.id;
        }
    }

    setNoneSelected() {
        this.selectedBusinessUnitOption = BusinessUnitManager.ALL_BU;
    }

    setSelectedBusinessUnitId(bu: number) {
        this.selectedBusinessUnitOption = this.businessUnitsMap?.get(bu) || BusinessUnitManager.ALL_BU;
    }

    get businessUnitOptions(): Array<BusinessUnit | NoBusinessUnit> | undefined {
        const bus = this.realBusinessUnitOptions;
        if (!bus) return undefined;
        if (bus.length === 0) return undefined; //When there are no business units, do not show the filter options
        const r = [
            BusinessUnitManager.ALL_BU,
            ...bus,
        ]
        if (!this.profileStore.p.datasetHasMissingBu && !this.profileStore.p.hideNoBuFilterInCategorization) {
            r.push(this.NO_BU);
        }
        return r
    }

    get realBusinessUnitOptions(): Array<BusinessUnit | NoBusinessUnit> | undefined {
        const bus = this._buRequestManager.result;
        if (!bus) return undefined;
        if (bus.length === 0) return undefined; //When there are no business units, do not show the filter options

        // TODO: LG There is missing data so show the bu_id when the name is missing
        bus.forEach(bu => {
            if (bu.bu_name === '') {
                bu.bu_name = bu.bu_id.split('.')[0];
            }
        })

        let result = [...bus]
        if (this.profileStore.p.datasetHasMissingBu) {
            // Move them to the end of the list
            const missingBus = result.filter(b => (b as BusinessUnit).bu_id?.toLowerCase().includes('missing'))
            result = result.filter(b => !missingBus.includes(b))
            result.push(...missingBus)
        }

        return result;
    }

    get businessUnitsMap(): Map<number, BusinessUnit> | undefined {
        const bus = this._buRequestManager.result;
        if (!bus) return undefined;
        const result = new Map()
        if (!this.profileStore.p.datasetHasMissingBu) {
            result.set(this.NO_BU.id, this.NO_BU);
        }
        result.set(BusinessUnitManager.ALL_BU.id, BusinessUnitManager.ALL_BU);
        bus.forEach(b => result.set(b.id, b));
        return result;
    }

    get NO_BU(): NoBusinessUnit {
        return {id: -1, bu_name: `No ${this.profileStore.buDataName}`};
    }
}
