import React from "react";
import {observer} from "mobx-react-lite";
import {FormControl, InputAdornment, TextField} from "@mui/material";
import {useSitStores, useStores} from "../../stores";

interface Props {
    label: string,
    disabled: boolean,
    localCurrencyId?: number,
    value?: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    endAd?: React.ReactElement
}
export const SitCurrency: React.FC<Props> = observer(
    ({label, disabled, localCurrencyId, value, onChange, endAd}) => {
        const {p} = useStores();
        const {ccStore} = useSitStores();

        return <FormControl fullWidth margin="dense">
            <TextField
                disabled={disabled}
                variant="outlined"
                label={label}
                value={value}
                InputProps={(!p.currencySymbol ||
                    ccStore.getCurrencyCode(localCurrencyId ?? 0, '').toUpperCase() === p.currencySymbol) ? {
                    startAdornment: <InputAdornment position="start">
                        {ccStore.getCurrencyCode(localCurrencyId ?? 0)}
                    </InputAdornment>,
                    endAdornment: (endAd !== undefined) ? endAd : <></>
                } : {
                    startAdornment: <InputAdornment position="start">
                        {ccStore.getCurrencyCode(localCurrencyId ?? 0)}
                    </InputAdornment>,
                    endAdornment: <>
                        <InputAdornment position="end">
                            (k&nbsp;{p.currencySymbol}&nbsp;
                            {ccStore.convertToCurrency(value ?? 0, localCurrencyId ?? 0, p.currencySymbol, 2)})
                        </InputAdornment>
                        {(endAd !== undefined) ? endAd : <></>}
                    </>
                }}
                onChange={onChange}/>
        </FormControl>
    });