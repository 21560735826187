import React from "react";
import {Alert, Button, CircularProgress, Grid, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";

// export const VerticalModal: React.FC<{
//     titleId: string;
//     className?: string;
//     saveLabel?: string;
//     onSave: () => void;
//     canSave: boolean;
//     isSaving?: boolean;
//     hideCancel?: true;
//     cancelLabel?: string;
//     onCancel?: () => void;
//     title: string;
//     onClose: () => void;
//     isError?: boolean | string;
// }> = ({
//           titleId,
//           className,
//           saveLabel,
//           onSave,
//           canSave,
//           isSaving,
//           hideCancel,
//           cancelLabel,
//           onCancel,
//           onClose,
//           title,
//           isError,
//           children,
//       }) => (
//     <Grid container className={"edit-model-component" + (className ? ' ' + className : '')}>
//         <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3}>
//             <Grid item>
//                 <Typography id={titleId} variant="h5">
//                     {title}
//                 </Typography>
//             </Grid>
//             <Grid item>
//                 {children}
//             </Grid>
//             <Grid item container justifyContent="flex-end">
//                 <Grid item>
//                     {isError && (
//                         <Grid item>
//                             <Alert severity="error">
//                                 {typeof isError === 'string' ? isError : 'Error'}
//                             </Alert>
//                         </Grid>
//                     )}
//                 </Grid>
//                 <Grid item>
//                     {!hideCancel && (
//                         <Button
//                             startIcon={<Close/>}
//                             onClick={() => {
//                                 if (onCancel) onCancel();
//                                 else onClose();
//                             }}
//                             variant="text"
//                             style={{margin: '0 10px'}}
//                         >
//                             {cancelLabel !== undefined ? cancelLabel : 'Cancel'}
//                         </Button>
//                     )}
//                 </Grid>
//                 <Grid item>
//                     <Button
//                         disabled={!canSave || isSaving}
//                         startIcon={isSaving ? <CircularProgress size={12}/> : <Check/>}
//                         onClick={onSave}
//                         variant="contained"
//                         style={{margin: '0 10px'}}
//                     >
//                         {saveLabel || 'Save'}
//                     </Button>
//                 </Grid>
//             </Grid>
//         </Grid>
//     </Grid>
// );


// TODO: Add is open prop
// TODO: Change 'Save' to confirm
// TODO: Add prop to make confirm button danger
export const VerticalModal: React.FC<{
    titleId: string;
    className?: string;
    saveLabel?: string;
    onSave: () => void;
    canSave: boolean;
    isSaving?: boolean;
    hideCancel?: true;
    cancelLabel?: string;
    onCancel?: () => void;
    title: string;
    onClose: () => void;
    isError?: boolean | string;
}> = ({
          titleId,
          className,
          saveLabel,
          onSave,
          canSave,
          isSaving,
          hideCancel,
          cancelLabel,
          onCancel,
          onClose,
          title,
          isError,
          children,
      }) => (
    <Grid container className={"edit-model-component" + (className ? ' ' + className : '')}>
        <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3}>
            <Grid item>
                <Typography id={titleId} variant="h5">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                {children}
            </Grid>
            <Grid item container justifyContent="flex-end">
                <Grid item>
                    {isError && (
                        <Grid item>
                            <Alert severity="error">
                                {typeof isError === 'string' ? isError : 'Error'}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    {!hideCancel && (
                        <Button
                            startIcon={<Close/>}
                            onClick={() => {
                                if (onCancel) onCancel();
                                else onClose();
                            }}
                            variant="text"
                            style={{margin: '0 10px'}}
                        >
                            {cancelLabel !== undefined ? cancelLabel : 'Cancel'}
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    <Button
                        disabled={!canSave || isSaving}
                        startIcon={isSaving ? <CircularProgress size={12}/> : <Check/>}
                        onClick={onSave}
                        variant="contained"
                        style={{margin: '0 10px'}}
                    >
                        {saveLabel || 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
