import {makeAutoObservable} from "mobx";
import {
    DoiStatus,
    Initiative, InitiativeFinancial,
    InitiativeSuccessStep, InitiativeWriter,
    Lever,
    Priority, SaveErrorsResponse,
    SimpleSitUserProfile
} from "../../ApiTypes";
import SitApi from "../../SitApit";
import {CompanyConfigurationStore} from "../CompanyConfigurationStore";
import dayjs, {Dayjs} from "dayjs";
import AuthStore from "../../../stores/AuthStore";
import ProfileStore from "../../../stores/ProfileStore";
import {financial_amount_attrs, InitiativeFinancialController} from "./InitiativeFinancialController";
import {InitiativeDescriptionController} from "./InitiativeDescriptionController";
import {InitiativeStatusController} from "./InitiativeStatusController";
import {InitiativeSkuController} from "./InitiativeSkuController";
import {InitiativeKpiController} from "./InitiativeKpiController";

export class InitiativeInstanceStore {
    forApproval = false;
    instance: Partial<Initiative> = {};
    description: InitiativeDescriptionController;
    f_c: InitiativeFinancialController[] = [];
    doi_c: InitiativeStatusController[] = [];
    sku_c: InitiativeSkuController[] = [];
    kpi_c: InitiativeKpiController[] = [];
    lever_group_id?: number;
    approval_dialog_open = false;
    kpi_dialog_open = false;
    // Warnings
    w_saving_split_sum?: number;
    w_saving_split_removed_companies?: number[];
    w_saving_split_removed_categories?: number[];
    w_no_split_companies?: number[];
    w_no_split_categories?: number[];
    // BE save errors
    save_errors?: string[][];

    constructor(
        public api: SitApi,
        public ccStore: CompanyConfigurationStore,
        public authStore: AuthStore,
        public profileStore: ProfileStore,
    ) {
        makeAutoObservable(this);
        this.addDescription();
        this.addFinancial();
        this.description = new InitiativeDescriptionController(this);
    }

    setInstance(value: Partial<Initiative>, forApproval = false) {
        const profile = this.authStore.sitUserProfile;
        if (!profile) return;

        this.forApproval = forApproval;
        this.instance = {};
        this.f_c = [];
        this.doi_c = [];
        this.sku_c = [];
        this.kpi_c = [];
        this.instance = value;
        this.instance.start_date = new Date(value.start_date ?? Date.now());
        if (this.instance.description == null)
            this.addDescription();
        this.instance.financial?.forEach((_item, index) => {
            this.f_c.push(new InitiativeFinancialController(this, index));
        });
        // Add an empty financial item if not present
        if (this.instance.financial == null || this.instance.financial.length === 0)
            this.addFinancial();
        // Convert all spends from default currency
        this.f_c.forEach((f_c) => f_c.convertFromDefaultCurrency());
        // If more than one financial item is present, add a combined item at 0
        if (this.instance.financial!.length > 1) {
            // Add a combined financial based on current values
            this.addFinancial(0);
            this.f_c.forEach((f_c) => {
                if (f_c.index === 0)
                    f_c.setCombinedSplitValues();
                else
                    f_c.percentage = (this.f_c[0].instance.saving_amount > 0) ? (100 * f_c.instance.saving_amount / this.f_c[0].instance.saving_amount ) : 0;
            })
        }
        // Make sure that initiative has every required step
        if (this.instance.doi == null)
            this.instance.doi = [];
        [1, 2, 3, 4, 5].forEach((step) => {
            if (this.instance.doi?.find((value) => value.doi_stage === step) === undefined)
                this.instance.doi?.push({
                    id: 0,
                    doi_stage: step,
                    initiative: this.instance.id ?? 0,
                    due_date: new Date(),
                    created: new Date()
                });
        });
        // For each doi add status controller with ref to instance. Note that since traffic light reads doi values
        //  from instance and not status controller, the controller needs to update values directly
        this.instance.doi?.forEach((_status, index) => {
            this.doi_c.push(new InitiativeStatusController(this, index));
        });
        if (this.instance.skus === undefined)
            this.instance.skus = [];
        this.instance.skus?.forEach((_sku, index) => {
            this.sku_c.push(new InitiativeSkuController(this, index));
        });
        if (this.instance.kpi === undefined)
            this.instance.kpi = [];
        this.instance.kpi?.forEach((_kpi, index) => {
            this.kpi_c.push(new InitiativeKpiController(this, index));
        });
        this.lever_group_id = this.ccStore.leverRM.result?.find(
            (lever) => lever.id === value.lever_id
        )?.group.id;
        // Default values
        if (!this.instance.owner)
            this.instance.owner = profile;
        if (this.f_c[0].instance.companies.length === 0) {
            this.f_c[0].companies = profile.default_company ? [profile.default_company.id] : [];
        }
        if (this.f_c[0].instance.currency_id === 0) {
            this.f_c[0].currencyId = profile.default_company?.currency.id ??
                this.ccStore.getCurrencyId(this.profileStore.currencySymbol ?? '') ?? 0;
        }
        // Set warnings
        this.w_saving_split_removed_companies = undefined;
        this.w_saving_split_removed_categories = undefined;
        this.w_no_split_companies = undefined;
        this.w_no_split_categories = undefined;
        // This should never happen, but just in case
        const sum_percentages = this.f_c.filter((f_c) => f_c.index > 0).reduce((sum, item) => sum + item.percentage, 0);
        if (this.f_c.length > 1 && sum_percentages !== 100)
            this.w_saving_split_sum = sum_percentages;
        else
            this.w_saving_split_sum = undefined;
    }

    appendErrorsRecursive(value: SaveErrorsResponse) {
        for (const kv in value) {
            if ((value[kv] as Array<any>).every(i => i.constructor.name === 'String'))
                this.save_errors!.push([kv.replace('_', ' ').toUpperCase(), (value[kv] as string[]).join(', ')]);
            else
                value[kv].forEach(i => this.appendErrorsRecursive(i));
        }
    }

    setSaveErrors(value?: SaveErrorsResponse) {
        if (value === undefined) {
            this.save_errors = undefined;
            return;
        }
        this.save_errors = [];
        this.appendErrorsRecursive(value);
    }

    createOrUpdate(fromApprovalDialog = false) {
        // If item is ready for approval, should not be created/updated. Use approve method
        if (this.forApproval) return;
        if (this.instance.doi_status === 5 && !fromApprovalDialog) {
            this.approvalDialogOpen = true;
            return;
        }
        let copyInstance: Partial<InitiativeWriter> = {};
        // Note that this does not handle deep copy, but that's acceptable since we would use different writer attributes
        Object.assign(copyInstance, this.instance);
        // Create write only values
        copyInstance.description_w = this.instance.description?.description ?? '';
        copyInstance.description_w_confidential = this.instance.description?.confidential_description ?? '';
        copyInstance.approval_notes_w = this.instance.description?.approval_notes ?? '';
        // For split savings, we have to remove first financial since it is combination of the rest
        copyInstance.financial_w = [];
        if (this.instance.financial && this.instance.financial.length > 1) {
            this.f_c.filter((f_c) => f_c.index !== 0).forEach((f_c) => {
                f_c.setValuesBasedOnSplitPercentage();
                copyInstance.financial_w!.push(this.convertToDefaultCurrency(f_c.index));
            });
        } else
            copyInstance.financial_w.push(this.convertToDefaultCurrency(0));
        copyInstance.doi_w = this.instance.doi;
        copyInstance.success_step_w = this.instance.success_step ?? [];
        copyInstance.skus_w = this.instance.skus ?? [];
        copyInstance.owner_id = this.instance.owner?.id;
        copyInstance.responsible_id = this.instance.responsible?.id;
        copyInstance.approver_id = this.instance.approver?.id;
        // Format dates
        this.instance.doi?.forEach((doi, index) => {
            if (doi.due_date.constructor.name === 'Date') {
                // @ts-ignore
                copyInstance.doi_w[index].due_date = doi.due_date.toISOString().split('T')[0];
            }
        });
        if (this.instance.start_date?.constructor.name === 'Date') {
            // @ts-ignore
            copyInstance.start_date = this.instance.start_date?.toISOString().split('T')[0];
        }
        if (!this.instance.id)
            return this.api.createInitiative(copyInstance);
        else
            return this.api.updateInitiative(copyInstance);
    }

    getLock() {
        if (!(this.instance.locked_by) && this.instance.id !== undefined) {
            this.api.lockInitiative(this.instance.id).then((resp) => {
                this.setInstance(resp.data);
            });
        }
    }

    releaseLock() {
        if (this.instance.id && this.instance.locked_by?.id === this.authStore.sitUserProfile?.id) {
            this.api.releaseLockInitiative(this.instance.id).then((resp) => {
                this.setInstance(resp.data);
            });
        }
    }

    approve(approve: boolean) {
        if (!this.readyForApproval)
            return;
        return this.api.approveInitiative(this.instance.id!, approve).then((resp) => {
            this.setInstance(resp.data);
        });
    }

    get creating(): boolean {
        return (this.instance.id ?? 0) === 0;
    }

    get readyForApproval(): boolean {
        return !this.creating && this.instance.doi_status === 5 && this.forApproval &&
            this.instance.approver?.id === this.authStore.sitUserProfile?.id;
    }

    get disabled(): boolean {
        return this.instance.approved || (!this.creating &&
            (this.instance.locked_by == null || this.instance.locked_by?.id !== this.authStore.sitUserProfile?.id));
    }

    addDescription() {
        this.instance.description = {
            id: 0,
            initiative: this.instance.id ?? 0,
            description: '',
            confidential_description: '',
            approval_notes: ''
        };
    }

    addFinancial(index?: number) {
        if (this.instance.financial == null)
            this.instance.financial = [];
        // This seems like a bad idea
        this.instance.financial.splice(index ?? this.instance.financial.length, 0, {
            id: 0,
            active: true,
            affected_months: 0,
            baseline_spend: 0,
            investment: 0,
            severance: 0,
            saving_amount: 0,
            fiscal_year_saving_amount: 0,
            normal_year_saving_amount: 0,
            fiscal_carry_over_saving_amount: 0,
            normal_carry_over_saving_amount: 0,
            saving_percentage: 0,
            carry_over_saving_percentage: 0,
            avoidance_amount: 0,
            fiscal_year_avoidance_amount: 0,
            normal_year_avoidance_amount: 0,
            fiscal_carry_over_avoidance_amount: 0,
            normal_carry_over_avoidance_amount: 0,
            avoidance_percentage: 0,
            carry_over_avoidance_percentage: 0,
            initiative: 0,
            categories: [],
            companies: [],
            divisions: [],
            currency_id: 0,
            saving_method: 0,
        });
        if (index !== undefined)
            this.f_c.filter((f_c) => f_c.index >= index).forEach((f_c) => f_c.index += 1);
        this.f_c.splice(index ?? (this.instance.financial.length - 1), 0, new InitiativeFinancialController(this, index ?? (this.instance.financial.length - 1)));
    }

    removeFinancial(index: number) {
        if (index <= 0 || index > (this.instance.financial?.length ?? 0 - 1)) return;
        this.instance.financial?.splice(index, 1);
        this.f_c.splice(index, 1);
        this.f_c.filter((f_c) => f_c.index > index).forEach((f_c) => f_c.index -= 1);
        if (this.f_c.length === 1) this.w_saving_split_sum = undefined;
    }

    convertToDefaultCurrency(index: number) {
        if ((this.instance.financial?.length ?? -1) < index) throw new Error('Index out of range for initiative financial!');
        let copyInstance: InitiativeFinancial = this.instance.financial![index];
        financial_amount_attrs.forEach((attr) => {
            if (!this.profileStore.currencySymbol ||
                this.ccStore.getCurrencyCode(this.instance.financial?.at(index)?.currency_id ?? 0, '') === this.profileStore.currencySymbol) {
                copyInstance[attr] = parseFloat(((this.instance.financial!)[index][attr] * 1000).toFixed(4));
            } else {
                copyInstance[attr] = this.ccStore.convertToCurrency(
                    (this.instance.financial!)[index][attr] * 1000,
                    this.instance.financial?.at(index)?.currency_id ?? 0,
                    this.profileStore.currencySymbol!);
            }
        });
        return copyInstance;
    }

    addSuccessStep(value: InitiativeSuccessStep) {
        if (this.instance.success_step === undefined)
            this.instance.success_step = [];
        this.instance.success_step.push(value);
    }

    set approvalDialogOpen(value: boolean) {
        this.approval_dialog_open = value;
    }

    set title(value: string) {
        this.instance.title = value;
    }

    set leverGroupId(value: number) {
        this.lever_group_id = value;
        const prev_lever = this.ccStore.leverRM.result?.find((lever) => lever.group.id === this.lever_group_id);
        if (prev_lever && prev_lever.id !== this.instance.lever_id)
            this.instance.lever_id = 0;
    }

    get leverChoices(): Lever[] {
        return this.ccStore.leverRM.result?.filter(
            (lever) => this.lever_group_id !== undefined ? lever.group.id === this.lever_group_id : true
        ) ?? [];
    }

    set leverId(value: number) {
        this.lever_group_id = this.ccStore.leverRM.result?.find((lever) => lever.id === value)?.group.id ?? 0;
        this.instance.lever_id = value;
    }

    get leverDescription(): string {
        return this.ccStore.leverRM.result?.find(
            (lever) => lever.id === this.instance.lever_id
        )?.description ?? '';
    }

    set confidential(value: boolean) {
        this.instance.confidential = value;
    }

    set nda_required(value: boolean) {
        this.instance.nda_required = value;
    }

    set sustainability(value: boolean) {
        this.instance.sustainability = value;
    }

    set riskReduction(value: boolean) {
        this.instance.risk_reduction = value;
    }

    set compliance(value: boolean) {
        this.instance.compliance = value;
    }

    set local(value: boolean) {
        this.instance.local = value;
    }

    setSuccessStepAchieved(value: InitiativeSuccessStep) {
        const value_index = this.instance.success_step?.indexOf(value) ?? -1;
        if (value_index > -1) {
            this.instance.success_step!.at(value_index)!.achieved = !this.instance.success_step?.at(value_index)?.achieved;
        }
    }

    removeSuccessStep(value: InitiativeSuccessStep) {
        const value_index = this.instance.success_step?.indexOf(value) ?? -1;
        if (value_index > -1) {
            this.instance.success_step?.splice(value_index, 1);
        }
    }

    get startDate() {
        return dayjs(this.instance.start_date);
    }

    set startDate(value: Dayjs) {
        this.instance.start_date = value.toDate();
    }

    get doiStatus() {
        return DoiStatus[(this.instance.doi_status ?? 1) - 1]
    }

    set doiStatus(value: string) {
        this.instance.doi_status = DoiStatus.indexOf(value) + 1;
    }

    get priority() {
        return Priority[(this.instance.priority ?? 1) - 1]
    }

    set priority(value: string) {
        this.instance.priority = Priority.indexOf(value) + 1;
    }

    get doi5Date() {
        return this.doi_c.find(
            (doi) => doi.instance.doi_stage === 5
        )?.dueDate ?? dayjs(new Date());
    }

    set doi5Date(value: Dayjs) {
        const doi_c5 = this.doi_c.find(
            (doi_c) => doi_c.instance.doi_stage === 5
        );
        if (doi_c5 !== undefined)
            doi_c5.dueDate = value;
    }

    get doi5SecondDate() {
        const doi5_cs = this.doi_c.filter(
            (doi) => doi.instance.doi_stage === 5);
        if (doi5_cs.length < 2)
            return null;
        return doi5_cs[1].dueDate ?? dayjs(new Date());
    }

    set doi5SecondDate(value: Dayjs | null) {
        const doi5_cs = this.doi_c.filter(
            (doi) => doi.instance.doi_stage === 5);
        if (value === null) {
            if (doi5_cs.length >= 2)
                doi5_cs.forEach((_item, index) => {
                    if (index > 0)
                        this.doi_c.splice(this.doi_c.indexOf(doi5_cs[index]), 1);
                });
            return;
        }
        if (doi5_cs.length < 2) {
            this.instance.doi!.push({
                id: 0,
                doi_stage: 5,
                initiative: this.instance.id ?? 0,
                due_date: new Date(),
                created: new Date()
            })
            this.doi_c.push(new InitiativeStatusController(this, this.instance.doi!.length - 1));
        }
        const doi_c5_2 = this.doi_c.reverse().find(
            (doi_c) => doi_c.instance.doi_stage === 5
        );
        if (doi_c5_2 !== undefined)
            doi_c5_2.dueDate = value;
    }

    distributeDois() {
        if (this.doi5Date) {
            const date_diff = this.doi5Date.diff(this.startDate, 'day');
            this.doi_c.filter((doi_c) => doi_c.instance.doi_stage !== 5).forEach((doi_c) => {
                doi_c.dueDate = this.startDate.add(doi_c.instance.doi_stage * date_diff / 5, 'day');
            })
        }
    }

    addSku() {
        this.instance.skus?.push('')
        this.sku_c.push(new InitiativeSkuController(this, this.instance.skus!.length - 1))
    }

    removeSku(index: number) {
        this.sku_c.splice(index, 1);
        this.sku_c.filter((_item, i) => i >= index).forEach((sku_c) =>
            sku_c.index--
        );
    }

    set kpiDialogOpen(value: boolean) {
        this.kpi_dialog_open = value;
    }

    addKpi() {
        if (!this.instance.kpi) this.instance.kpi = [];
        this.instance.kpi?.push({
            id: 0,
            initiative: this.instance.id ?? 0,
            created: new Date(),
            values: [],
            frequency: 2,
            type_id: 0,
            title: '',
            description: ''
        });
        this.kpi_c.push(new InitiativeKpiController(this, this.instance.kpi!.length - 1));
    }

    set owner(value: SimpleSitUserProfile) {
        this.instance.owner = value;
    }

    set responsible(value: SimpleSitUserProfile) {
        this.instance.responsible = value;
    }

    set approver(value: SimpleSitUserProfile) {
        this.instance.approver = value;
    }

    setSplitWarnings() {
        let split_companies: number[] = [];
        let split_categories: number[] = [];
        this.f_c.filter((f_c) => f_c.index > 0).forEach((f_c) => {
            f_c.instance.companies.forEach((c_id) => {
                if (!split_companies.includes(c_id)) split_companies.push(c_id)
            });
            f_c.instance.categories.forEach((c_id) => {
                if (!split_categories.includes(c_id)) split_categories.push(c_id)
            });
        });
        this.w_saving_split_removed_companies = split_companies.filter(c_id => !this.f_c[0].instance.companies.includes(c_id));
        this.w_no_split_companies = this.f_c[0].instance.companies.filter(c_id => !split_companies.includes(c_id));
        this.w_saving_split_removed_categories = split_categories.filter(c_id => !this.f_c[0].instance.categories.includes(c_id));
        this.w_no_split_categories = this.f_c[0].instance.categories.filter(c_id => !split_categories.includes(c_id));
        // Set to undefined if empty
        if (this.w_saving_split_removed_companies?.length === 0) this.w_saving_split_removed_companies = undefined;
        if (this.w_no_split_companies?.length === 0) this.w_no_split_companies = undefined;
        if (this.w_saving_split_removed_categories?.length === 0) this.w_saving_split_removed_categories = undefined;
        if (this.w_no_split_categories?.length === 0) this.w_no_split_categories = undefined;
    }
}
