import '../Chat.scss';
import React, {useEffect} from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import ChatOpportunityController from "./ChatOpportunityController";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {MessageElement} from "../ChatTypes";

const checkAmount: (amount: string) => number = (amount) => {
    if (amount === '') {
        return 0
    } else {
        const parsed = parseFloat(amount);
        if (isNaN(parsed)) {
            return 0
        } else {
            return parsed;
        }
    }
}

type Props = {
    createOpportunityFrom: MessageElement | null,
    handleClose: () => void
};
export const ChatOpportunitiesModal: React.FC<Props> = observer(({createOpportunityFrom, handleClose}) => {
    const {p} = useStores();
    const isOpen = Boolean(createOpportunityFrom);

    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [amount, setAmount] = React.useState('');

    useEffect(() => {
        if(createOpportunityFrom) {
            setTitle('');
            setAmount('');
            if (createOpportunityFrom.type === 'text') {
                setDescription(createOpportunityFrom.message);
            } else {
                setDescription('');
            }
            setError('');
        }
    }, [createOpportunityFrom]);

    return <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                const nAmount = checkAmount(amount);
                setAmount(nAmount.toString());
                setBusy(true);
                ChatOpportunityController.saveOpportunity(title, description, nAmount)
                    .then((success) => {
                        setError('')
                        if (success) {
                            handleClose();
                        }
                    })
                    .catch((e) => setError(e.message))
                    .finally(() => setBusy(false))
            },
        } as any} // https://github.com/mui/material-ui/issues/27703
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>Create an Opportunity</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You can create this opportunity for follow up.
            </DialogContentText>
            <TextField
                autoFocus
                required
                margin="normal"
                id="create-opportunity-name"
                name="opportunity_name"
                label="Opportunity name"
                fullWidth
                variant="outlined"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                disabled={busy}
            />
            <TextField
                margin="normal"
                id="create-opportunity-description"
                name="opportunity_description"
                label="Description"
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                disabled={busy}
            />
            <TextField
                margin="normal"
                id="create-opportunity-amount"
                name="opportunity_amount"
                label={`Amount (${p.currencySymbol})`}
                variant="outlined"
                value={amount}
                onChange={(event) => setAmount(event.target.value)}
                onBlur={(event) => {
                    setAmount(checkAmount(event.target.value).toString());
                }}
                disabled={busy}
            />
            {error && <Alert severity="error" sx={{mt: 2}}>
                {error}
            </Alert>}
        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} disabled={busy}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit" disabled={busy}>Create</Button>
        </DialogActions>
    </Dialog>;
});
