import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {SingleBarChart} from "../../../components/visualization/single-barchart/SingleBarChart";
import {UNCATEGORIZED_VALUE} from "../../../constants";
import {NO_MARGIN} from "../../../utils/margin";
import {Grid} from "@mui/material";

export const GoldenRecordBarsComponent: React.FC = observer(() => {
    const {goldenRecordStore} = useStores();
    return <section className="golden-record-bars-component">

        {/* DISABLES the breadcrumbs */}
        {/*<Grid container justifyContent="center">*/}
        {/*    <Grid className=""*/}
        {/*          item xs={8}*/}
        {/*          container>*/}
        {/*        <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">*/}
        {/*            {goldenRecordStore.filterController.selectedCategory.map((category, index) => {*/}
        {/*                const level = index + 1;*/}
        {/*                const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;*/}
        {/*                return <Link*/}
        {/*                    key={category}*/}
        {/*                    href=""*/}
        {/*                    onClick={e => {*/}
        {/*                        e.preventDefault();*/}
        {/*                        goldenRecordStore.filterController.navigateToLevel(level - 1);*/}
        {/*                    }}>*/}
        {/*                    {categoryLabel}*/}
        {/*                </Link>*/}
        {/*            })}*/}
        {/*            {goldenRecordStore.filterController.hasRemainingSelectionLevels &&*/}
        {/*                <Typography color="textPrimary" className="">Select a*/}
        {/*                    L{goldenRecordStore.filterController.selectedLevel + 1}</Typography>*/}
        {/*            }*/}
        {/*        </Breadcrumbs>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        {goldenRecordStore.barChart &&
            <Grid container justifyContent="center">
                <Grid item xs={2}/>
                <Grid item xs={10} className="overview-bar-charts">
                    {goldenRecordStore.barChart.data.slice(0, goldenRecordStore.isBarChartExtended ? undefined : 3).map(changeChartData => (
                        <div key={changeChartData.category}
                             className={`clickable bar-chart-container`}
                             onClick={() => {
                                 if (goldenRecordStore.filterController.hasRemainingSelectionLevels) {
                                     goldenRecordStore.filterController.selectNextCategoryDown(changeChartData.category)
                                 } else {
                                     goldenRecordStore.filterController.setSelectedCategory([changeChartData.category])
                                 }
                             }}
                        >
                            <SingleBarChart
                                key={changeChartData.category}
                                data={{
                                    mainLabel: changeChartData.label,
                                    values: changeChartData.values,
                                }}
                                className={changeChartData.category === UNCATEGORIZED_VALUE ? 'uncat' : ''}
                                options={{
                                    width: 1300,
                                    height: 25, // Needs to be 45 for two rows
                                    margin: NO_MARGIN,
                                    dataMax: goldenRecordStore.barChart?.max,
                                    labelHover: true
                                }}
                            />
                        </div>
                    ))}
                </Grid>
            </Grid>
        }
    </section>
})
