import {styled} from "@mui/material/styles";
import {observer} from "mobx-react-lite";
import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import {useStores} from "../../../stores";
import React from "react";
import {ModalGenericV2} from "./ModalGenericV2";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {TaxonomyCategory} from "../../../stores/TaxonomyCategoryProvider";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(ModalGenericV2)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalMergeCategory: React.FC = observer(() => {

    const {taxonomyEditorStore, categorizationReviewStore, taxonomyHealthCheckStore} = useStores();

    const filterOptions = createFilterOptions<TaxonomyCategory>({
        matchFrom: 'any',
        stringify: option => option.search,
    });

    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="update-model-component"
            title="Merge category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={() => taxonomyEditorStore.saveMergeCategory()}
            onClose={() => taxonomyHealthCheckStore.isMergeCategoryModalOpen = false}
            saveLabel="Rename"
        >


            <Autocomplete
                id={'autoId'}
                options={categorizationReviewStore.categories.taxonomy_categories || []}
                style={{marginBottom: 30}}
                className="toolbar-search"
                filterOptions={filterOptions}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props}>
                        <PartCategorizationComponent cats={option.values}/>
                    </li>
                )}
                onChange={(_, newValue: any | null) => {
                    // taxonomyEditorStore.searchByLabels(toJS(newValue.values) as string[])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={'Search for a category'}
                        variant="outlined"
                        autoFocus
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
            />

            <TextField
                autoFocus
                fullWidth
                style={{marginBottom: 30}}
                id="taxonomy-editor-modal-new-category-label"
                label="New category name"
                error={Boolean(taxonomyEditorStore.mergeCategoryNameError)}
                helperText={taxonomyEditorStore.mergeCategoryNameError}
                variant="outlined"
                value={taxonomyEditorStore.mergeCategoryName}
                onChange={e => taxonomyEditorStore.setMergeCategoryName(e.target.value)}
                // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />

            <TextField
                fullWidth
                multiline
                id="taxonomy-editor-modal-description-field"
                label="Description"
                error={Boolean(taxonomyEditorStore.mergeCategoryDescriptionError)}
                helperText={taxonomyEditorStore.mergeCategoryDescriptionError}
                variant="outlined"
                value={taxonomyEditorStore.mergeCategoryDescription}
                onChange={e => taxonomyEditorStore.setMergeCategoryDescription(e.target.value)}
                // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />


        </StyledEditorModalComponent>
    );
})