import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {useStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";

export const TaxonomyMappingResultTable: React.FC = observer(() => {
    const {taxonomyIngestionStore} = useStores()
    const COLUMNS = taxonomyIngestionStore.taxonomyMappingHeaders;

    const isLoading = false;
    const tableClass = 'mithra-table x-design x-design-narrow' + (isLoading ? ' loading' : '');

    const data = taxonomyIngestionStore.taxonomyMappingResultTable;

    return <div className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map((row, i) => <React.Fragment key={i}>
                            <TaxonomyMappingResultRow row={row}/>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </div>
})

type Props = {
    row: any
};
export const TaxonomyMappingResultRow: React.FC<Props> = observer(({row}) => {
    const {taxonomyIngestionStore} = useStores()
    const COLUMNS = taxonomyIngestionStore.taxonomyMappingHeaders;
    return <TableRow>
        {COLUMNS.map((c, i) =>
            <MithraTableCell key={i} c={c}>
                {c.columnFieldId
                    ? row[c.columnFieldId] || ''
                    : '?'}
            </MithraTableCell>
        )}
    </TableRow>
})
