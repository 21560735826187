import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import React, {MouseEventHandler} from "react";
import {useStores} from "../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";


export const PpvBreadcrumbs = observer(() => {
    const {ppvControllerStore} = useStores();
    const reviewPageFilterController = ppvControllerStore.ppvPageController.ppvPageFilterController;

    return <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
        {reviewPageFilterController.selectedCategory.map((category, index) => {
            const level = index + 1;
            const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
            const onClick: MouseEventHandler = e => {
                e.preventDefault();
                reviewPageFilterController.navigateToLevel(level - 1);
            }
            return <Link onClick={onClick} key={category} href="">{categoryLabel}</Link>
        })}
        {reviewPageFilterController.hasRemainingSelectionLevels &&
            <Typography color="textPrimary" className="">Select a
                L{reviewPageFilterController.selectedLevel + 1}</Typography>
        }
    </Breadcrumbs>
})
