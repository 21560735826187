import React, {useEffect} from "react";
import './ManageMasterDataPage.scss';
import {Button, Grid, Typography} from "@mui/material";
import {generatePath, useLocation, useNavigate} from "react-router-dom";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {PlayCircleOutline} from "@mui/icons-material";
import {DataSetSelectComponent} from "../../manage-data/DataSetSelectComponent";
import {DataSetViewComponent} from "../../manage-data/DataSetViewComponent";
import {routes} from "../../../routing/routes";
import {generateGotoDashboardPath} from "../../../routing/routing.utils";

export const ManageMasterDataPage: React.FC = () => {
    const {bagStore, cmpStore} = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        bagStore.getAllBags();
    }, [bagStore])
    const bags = bagStore.userBagChoices;

    const [selectedBag, setSelectedBag] = React.useState<Bag | undefined>(bagStore.bag);
    const [selectedMasterBag, setSelectedMasterBag] = React.useState<Bag | undefined | null>(cmpStore.masterBag);
    useEffect(() => {
        // Change the master dataset
        if (!selectedBag) {
            setSelectedMasterBag(undefined);
            return;
        }
        const masterBagId = cmpStore.determineMasterBag(selectedBag);
        if (masterBagId === null) {
            setSelectedMasterBag(null);
            return;
        }
        const masterBag = bags?.find(b => b.id === masterBagId);
        setSelectedMasterBag(masterBag);
    }, [selectedBag, cmpStore, bags])

    const [error1, setError1] = React.useState('');
    const [error2, setError2] = React.useState('');
    const triggerError1 = () => setError1('Please select a dataset');
    const triggerError2 = () => setError2('No master dataset found for this dataset');

    const handleClickStart = () => {
        if (!selectedBag) triggerError1();
        else setError1('');
        if (!selectedMasterBag) triggerError2();
        else setError2('');
        if (!selectedBag || !selectedMasterBag) return;

        console.assert(selectedBag?.id !== -1, 'Selected bag should have an id');
        console.assert(selectedMasterBag?.id !== -1, 'Selected master bag should have an id');

        bagStore.setBag(selectedBag);
        cmpStore.setCompareBag(selectedBag);
        cmpStore.setMasterBag(selectedMasterBag);
        navigate(generateGotoDashboardPath(location, {id: String(selectedBag.id)}));
    }

    return <main className="manage-master-data-page">
        <Grid container justifyContent="center" className="page-header">
            <Grid item>
                <Typography variant="h3" component="h1" className="title">
                    Choose data sets
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-evenly" alignItems="center" className="page-content">
            {/*<Grid item lg={1} md={false}/>*/}
            <Grid item lg={3} md={4} className="input-panel right">
                <Typography variant="h4" className="text-center">
                    Company dataset
                </Typography>
                <DataSetSelectComponent
                    id="ds-1-"
                    className="dataset-select"
                    label="Select dataset"
                    centered
                    fullWidth
                    error={error1}
                    bags={bags}
                    selectedBagId={selectedBag?.id || ''}
                    onSelectBag={b => {
                        setError1('');
                        setSelectedBag(b);
                    }}/>
                <Grid container justifyContent="space-evenly">
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={!selectedBag}
                            onClick={() => {
                                if (!selectedBag) {
                                    triggerError1();
                                    return
                                }
                                // Go to single dashboard for selected bag
                                bagStore.setBag(selectedBag);
                                navigate(generatePath(routes.single_cleansing_dashboard, {id: selectedBag.id}));
                            }}>
                            Cleansing
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={!selectedBag}
                            onClick={() => {
                                if (!selectedBag) {
                                    triggerError1();
                                    return;
                                }
                                bagStore.setBag(selectedBag);
                                navigate(generatePath(routes.single_analytics_dashboard, {id: selectedBag.id}));
                            }}>
                            Analytics
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item lg={1} md={false}/>*/}
            <Grid item lg={2} md={2} xs={12} className="submit-panel text-center">
                <Button
                    startIcon={<PlayCircleOutline/>}
                    variant="outlined"
                    disabled={!selectedMasterBag || !selectedBag}
                    color={(error1 || error2) ? 'error' : 'primary'}
                    onClick={handleClickStart}>
                    Synergies<br/>
                    Dashboard
                </Button>
            </Grid>
            {/*<Grid item lg={1} md={false}/>*/}
            <Grid item lg={3} md={4} className="input-panel left">
                <Typography variant="h4" className="text-center">
                    Master dataset
                </Typography>
                <DataSetViewComponent
                    id="ds-2-"
                    className="dataset-select"
                    label="Dataset name (readonly)"
                    centered
                    fullWidth
                    error={error2}
                    bag={selectedMasterBag}
                />
                <Grid container justifyContent="space-evenly">
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={!selectedMasterBag}
                            onClick={() => {
                                if (!selectedMasterBag) {
                                    triggerError2();
                                    return;
                                }
                                bagStore.setBag(selectedMasterBag);
                                navigate(generatePath(routes.single_cleansing_dashboard, {id: selectedMasterBag.id}));
                            }}>
                            Cleansing
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={!selectedMasterBag}
                            onClick={() => {
                                if (!selectedMasterBag) {
                                    triggerError2();
                                    return;
                                }
                                bagStore.setBag(selectedMasterBag);
                                navigate(generatePath(routes.single_analytics_dashboard, {id: selectedMasterBag.id}));
                            }}>
                            Analytics
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item lg={1} md={false}/>*/}
        </Grid>
    </main>
};
