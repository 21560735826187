import {Alert, Button} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {
    CategorizationReviewPageSelectionController
} from "../../../../controllers/CategorizationReviewPageSelectionController";
import {useStores} from "../../../../../../stores";

type Props = {}
export const AllSingularSelectionAlert: React.FC<Props> = observer(() => {
    const {categorizationReviewStore} = useStores()
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;

    // You have {partRow.selectedLabel} of {s_name}
    if (!reviewPageSelectionController.isAnySelected) {
        return <></> // Do not show the label if nothing is selected
    }
    const plural = reviewPageSelectionController.nSelected > 1;
    const _parts = `${CategorizationReviewPageSelectionController.PART_NAME}${plural ? 's' : ''}`;
    const all = plural && reviewPageSelectionController.isAllSelected ? 'all ' : '';

    return <Alert
        className="tiny"
        severity="info"
        action={<>
            {reviewPageSelectionController.selectionState === 'all' &&
                <Button variant="text" size="small"
                        onClick={() => reviewPageSelectionController.onSetAllSelected(false)}>
                    Deselect
                </Button>
            }
            {reviewPageSelectionController.selectionState === 'some' &&
                <Button variant="outlined" size="small"
                        onClick={() => reviewPageSelectionController.onSetAllSelected(true)}>
                    Select All
                </Button>
            }
        </>}>
        {/*<p>*/}
        {/*    partRow.selectionState: {partRow.selectionState}<br/>*/}
        {/*    partRow.isAllSelected: {'' + partRow.isAllSelected}<br/>*/}
        {/*</p>*/}
        You have {all && <strong>{all}</strong>}
        {reviewPageSelectionController.nSelected} {_parts} selected
    </Alert>
})