import React, {useContext, useEffect, useState} from "react";
import {EChartContext} from "../../services/echarts/EChartProvider";
import {initBarChart, updateBarChart} from "./echart-bar-chart";

export type Data = { name: number, value: number };

type Props = {
    data: Data[]
    onClick: (params: any) => void
}
/**
 * Experimental component to test echart integration
 */
export const CustomEchartComponent: React.FC<Props> = ({data, onClick}) => {
    console.log('MyEchartBarChart: tick')

    const echart = useContext(EChartContext);
    const ref = React.useRef<HTMLDivElement>(null);
    const [chart, setChart] = useState<any | null>(null);

    if (chart) {
        chart.off('click')
        chart.on('click', onClick)
    }

    useEffect(() => {
        if (!ref.current || chart) return;
        console.log('MyEchartBarChart: initialize')

        const newChart = initBarChart(echart, ref.current, data);
        setChart(newChart);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, chart]);

    useEffect(() => {
        if (!data || !chart) return;
        console.log('MyEchartBarChart: data update')

        updateBarChart(chart, data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => (() => {
        console.log('MyEchartBarChart: unmount')

        if (chart) {
            chart.dispose()
            setChart(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    return <div ref={ref}/>
}