import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import {useSitStores} from "../../../stores";
import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";
import {EchartSitDefaultColor, EchartSitDefaultLegend} from "../../../services/echarts/EchartSitCommonConfig";
import {SIT_TEST} from "../../SitApit";
import {DoiStatus} from "../../ApiTypes";

function init(echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType {
    const chart = echart.init(root, 'mithra', {
        renderer: 'svg',
        height: 600,
    });
    chart.setOption({
        tooltip: {
            trigger: 'axis',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {show: true},
            },
        },
        legend: {
            data: [
                'Savings',
                'Targets',
                DoiStatus[4] + ' only',
                'Logistics',
                'Manufacturing',
                'Procurement',
            ],
            ...EchartSitDefaultLegend
        },
        color: EchartSitDefaultColor,
        xAxis: {type: 'category',},
        yAxis: {type: 'value'},
        dataset: data,
        series: [
            {
                name: 'Savings', type: 'bar',
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated saving per company',
                color: '#091344',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: 'Targets', type: 'line',
                symbol: 'triangle',
                symbolSize: 20,
                lineStyle: {
                    color: '#ff3435',
                    width: 0,
                    type: 'dashed'
                },
                itemStyle: {
                    borderColor: '#ff3435',
                    color: '#ff3435'
                },
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated target per company',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: DoiStatus[4] + ' only', type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#430944',
                    width: 0,
                    type: 'dashed'
                },
                itemStyle: {
                    borderColor: 'white',
                    color: '#430944'
                },
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated only DOI5 savings per company',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: 'Logistics', type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#0262f2',
                    width: 0,
                    type: 'dashed'
                },
                itemStyle: {
                    borderColor: 'white',
                    color: '#0262f2'
                },
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated only Logistics savings per company',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: 'Manufacturing', type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#08a57a',
                    width: 0,
                    type: 'dashed'
                },
                itemStyle: {
                    borderColor: 'white',
                    color: '#08a57a'
                },
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated only Manufacturing savings per company',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: 'Procurement', type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#b96adc',
                    width: 0,
                    type: 'dashed'
                },
                itemStyle: {
                    borderColor: 'white',
                    color: '#b96adc'
                },
                encode: {
                    // Map "amount" column to x-axis.
                    x: 'CompanyCode',
                    // Map "product" row to y-axis.
                    y: 'Amount'
                },
                datasetId: 'Total aggregated only Procurement savings per company',
                emphasis: {
                    focus: 'series',
                },
            },

        ],
    })
    return chart;
}

export const PerCompanyBar: React.FC = observer(() => {
    const {fbStore} = useSitStores();

    if (fbStore.contributionsPerCompanyLever === null) return <></>;

    const data = [
        {
            id: 'Total aggregated saving per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.total,
            ]))
        },
        {
            id: 'Total aggregated target per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.target,
            ]))
        },
        {
            id: 'Total aggregated only DOI5 savings per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.only_doi5_total,
            ]))
        },
        {
            id: 'Total aggregated only Logistics savings per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.levers.find(l => l.lever === 'Logistics')?.value ?? 0,
            ]))
        },
        {
            id: 'Total aggregated only Manufacturing savings per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.levers.find(l => l.lever === 'Manufacturing')?.value ?? 0,
            ]))
        },
        {
            id: 'Total aggregated only Procurement savings per company',
            dimensions: [
                {name: 'CompanyCode'},
                {name: 'Amount', type: 'number'},
            ],
            source: fbStore.contributionsPerCompanyLever.map(r => ([
                r.company_title,
                r.levers.find(l => l.lever === 'Procurement')?.value ?? 0,
            ]))
        },
    ]

    return <Grid container>
        <Grid item xs={12}>
            {SIT_TEST && '<PerCompanyBar/>'}
            {/*{JSON.stringify(fbStore.contributionsPerCompanyLever)}<br/>*/}
            {/*<br/>*/}
            {/*{JSON.stringify(fppcData)}*/}
            <MithraEchart
                // alwaysRerender={() => {
                // }}
                initChart={init}
                data={data}
                autoUpdateDataset
            />
        </Grid>
    </Grid>
})