import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores, useStores} from "../../../stores";
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Add, Clear, Search} from "@mui/icons-material";
import {generatePath, useNavigate} from "react-router-dom";
import {sitRoutes} from "../../routing/sitRoutes";
import {Category, category_readable_str, Company} from "../../ApiTypes";

export const TargetFilters: React.FC = observer(() => {
    const {authStore} = useStores();
    const {targetListStore, ccStore} = useSitStores();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [ownerValue, setOwnerValue] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState<Category[]>([]);
    const [companyFilter, setCompanyFilter] = useState<Company[]>([]);
    // const [leverGroupValue, setLeverGroupValue] = useState('');

    useEffect(() => {
        const categoryInitFilters =
            targetListStore.targetListFilter.filters.find((f) => f.length === 2 && f[0] === 'categories__id__in');
        if (categoryInitFilters !== undefined && categoryInitFilters.length > 0 && ccStore.categoryRM.result)
            setCategoryFilter(ccStore.categoryRM.result.filter((c) => categoryInitFilters[1].split(',').includes(c.id.toString())));
        const companyInitFilters =
            targetListStore.targetListFilter.filters.find((f) => f.length === 2 && f[0] === 'companies__id__in');
        if (companyInitFilters !== undefined && companyInitFilters.length > 0 && ccStore.companyRM.result)
            setCompanyFilter(ccStore.companyRM.result.filter((c) => companyInitFilters[1].split(',').includes(c.id.toString())));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Grid container spacing={1} columns={24} className="target-filters-component">
        <Grid item xs={4}>
            <FormControl>
                <TextField
                    id='target-search'
                    variant='standard'
                    label='Search for targets'
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    disabled={(searchValue === '')}
                                    onClick={() => {
                                        targetListStore.targetListFilter.setFilters([['search', '']]);
                                        setSearchValue('');
                                    }}>
                                    <Clear/>
                                </IconButton>
                                <IconButton
                                    aria-label="search"
                                    onClick={() => {
                                        targetListStore.targetListRM.request({filters: targetListStore.targetListFilter.filters});
                                    }}>
                                    <Search/>
                                </IconButton>
                            </InputAdornment>
                    }}
                    value={searchValue}
                    onChange={e => {
                        targetListStore.targetListFilter.setFilters([['search', e.target.value]]);
                        setSearchValue(e.target.value);
                    }}
                />
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            <FormControl fullWidth>
                <InputLabel id="show-label">View</InputLabel>
                <Select
                    labelId="show-label"
                    id="show"
                    value={ownerValue}
                    label='View'
                    onChange={(event) => {
                        if (event.target.value === 0) {
                            targetListStore.targetListFilter.setFilters([['owner', '']]);
                            setOwnerValue(0);
                        } else {
                            targetListStore.targetListFilter.setFilters([['owner', String(authStore.sitUserProfile?.id ?? '')]]);
                            setOwnerValue(1);
                        }
                    }}
                >
                    <MenuItem value={0}>All targets</MenuItem>
                    <MenuItem value={1}>My targets</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="category"
                    limitTags={1}
                    options={ccStore.categoryRM.result ?? ([] as Category[])}
                    getOptionLabel={(option) => category_readable_str(option)}
                    value={categoryFilter}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Category" placeholder="All"/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={categoryFilter.find((c) => c.id === option.id) !== undefined}/>
                            {category_readable_str(option)}
                        </li>}
                    onChange={(_event, value) => {
                        setCategoryFilter(value);
                        targetListStore.targetListFilter.setFilters([['categories__id__in', value.map((c) => c.id).toString()]])
                    }}/>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                    id="company"
                    limitTags={1}
                    options={ccStore.companyRM.result ?? ([] as Company[])}
                    getOptionLabel={(option) => option.title}
                    value={companyFilter}
                    disableCloseOnSelect
                    renderInput={(params) =>
                        <TextField {...params} variant="outlined" label="Company" placeholder="All"/>}
                    renderOption={(props, option) =>
                        <li key={option.id} {...props}>
                            <Checkbox
                                checked={companyFilter.find((c) => c.id === option.id) !== undefined}/>
                            {option.title}
                        </li>}
                    onChange={(_event, value) => {
                        setCompanyFilter(value);
                        targetListStore.targetListFilter.setFilters([['companies__id__in', value.map((c) => c.id).toString()]])
                    }}/>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
            {/*<FormControl fullWidth>*/}
            {/*    <InputLabel id="lever-group-label">Lever group</InputLabel>*/}
            {/*    <Select*/}
            {/*        labelId="lever-group-label"*/}
            {/*        id="lever-group"*/}
            {/*        value={leverGroupValue}*/}
            {/*        label="Lever group"*/}
            {/*        onChange={(event) => {*/}
            {/*            targetListStore.targetListFilter.setFilters([['lever__group_id', event.target.value]]);*/}
            {/*            setLeverGroupValue(event.target.value);*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <MenuItem value="">All</MenuItem>*/}
            {/*        {ccStore.leverGroupRM.result?.map((lg) => {*/}
            {/*            return <MenuItem*/}
            {/*                key={lg.id}*/}
            {/*                value={lg.id}>{lg.title}</MenuItem>;*/}
            {/*        })}*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
        </Grid>
        <Grid item xs={2}>
            {/*<FormControl fullWidth margin="dense">*/}
            {/*    <Button variant="text"*/}
            {/*            onClick={() => {*/}
            {/*                // TODO CAT-2025 Save filters for user*/}
            {/*            }}>*/}
            {/*        Save filters*/}
            {/*    </Button>*/}
            {/*</FormControl>*/}
        </Grid>
        <Grid item xs={1}>
            {/*<FormControl margin="dense">*/}
            {/*    <IconButton>*/}
            {/*        <FilterAlt color="primary"/>*/}
            {/*    </IconButton>*/}
            {/*</FormControl>*/}
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth margin="dense">
                <Button
                    variant="outlined"
                    startIcon={<Add/>}
                    onClick={() => {
                        navigate(generatePath(sitRoutes.sit_target, {targetId: 'new'}))
                    }}>
                    Add new target
                </Button>
            </FormControl>
        </Grid>
    </Grid>
});