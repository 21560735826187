import {Bag} from "./Bag";
import {m_taxonomy} from "./TaxonomyClasses";
import {SimpleUser} from "./User";

export enum ReviewChoice {
    ACCEPT,
    REJECT,
    FEEDBACK,
}

export type Categories = string[]

export type ReviewCategorizationChoice = {
    choice_l1: string
    choice_l2: string
    choice_l3: string
    choice_l4: string
}
export type TaxonomyCategoryResp = {
    base_categories: string[][]
    current_categories: string[][]
}

export enum ApprovalStatusEnum {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    BUSY = 'busy',
    HALTED = 'halted',
    ERROR = 'error',
}

export namespace ApprovalStatusEnum {
    export function userCanUpdate(status: ApprovalStatusEnum | undefined) {
        switch (status) {
            case ApprovalStatusEnum.PENDING:
                return true;
            default:
                return false;
        }
    }
}

export enum ApprovalTypeEnum {
    TAXONOMY = 'taxonomy',
    CATEGORIZATION = 'categorization',
}

export type ApprovalRequestFilters = {
    search?: string
    status_q?: string
}
export type ApprovalRequest = {
    id: number
    notes: string
    feedback_notes: string
    created_by: SimpleUser
    created_timestamp: string
    current_status: ApprovalRequestStatus
    request_type: ApprovalTypeEnum
    baseline_bag: Bag | null
    resulting_bag: Bag | null
}
export type ApprovalRequestStatus = {
    user: SimpleUser
    timestamp: string // date
    status: ApprovalStatusEnum
    status_name: string
}
export type ApprovalStatusCount = {
    status: string
    count: number
}
export type TaxonomyApprovalRequest = ApprovalRequest & {
    request_type: ApprovalTypeEnum.TAXONOMY
    taxonomy: number
    taxonomy_source_state: m_taxonomy.SimpleTaxonomyOperationSerializer
    taxonomy_target_state: m_taxonomy.SimpleTaxonomyOperationSerializer
    changelog: TaxonomyChangeLog // Probably OUTDATED <-
}
export type ApprovalPart = {
    part_id: number,
    part__p_id: string,
    part__p_name: string,
    part__p_description: string,
    part__p_spend: number,
    part__supplier_id: number,
    part__supplier__s_id: string,
    part__supplier__s_name: string,
    category_id: number,
    category__l1: string,
    category__l2: string,
    category__l3: string,
    category__l4: string,
    category__l5: string,
    category__l6: string,
    category__l7: string,
    category__l8: string,
    prev_cats: string[] | null,
}
type TaxonomyChangeNode = {
    node_id: number
    labels: string[]
    data: {
        values: m_taxonomy.Data
        sources: number[]
    }
}
type TaxonomyChangeMergeNode = TaxonomyChangeNode & {
    sources: TaxonomyChangeNode[]
}
type TaxonomyChangeMoveNode = TaxonomyChangeNode & {
    old_labels: string[]
}
type TaxonomyChangeEditedField = {
    field: string
    old_value: string
    new_value: string
}
type TaxonomyChangeEditedNode = TaxonomyChangeNode & {
    changes: TaxonomyChangeEditedField[]
}
export type TaxonomyChangeLog = {
    created: TaxonomyChangeNode[]
    merged: TaxonomyChangeMergeNode[]
    moved: TaxonomyChangeMoveNode[]
    edited: TaxonomyChangeEditedNode[]
    deleted: TaxonomyChangeNode[]
}

export type CategorizationApprovalRequest = ApprovalRequest & {
    request_type: ApprovalTypeEnum.CATEGORIZATION
    baseline_taxonomy: number | null
}

export type CategorizationApprovalStats = {
    [feedback_choice in (ReviewChoice | "null")]?: number
}
