import {TableCellProps} from "@mui/material/TableCell/TableCell";
import React from "react";
import {TableCell} from "@mui/material";
import {ColSpec} from "./MithraTableHeadColumns";

type Props = TableCellProps & {
    c: ColSpec
};
export const MithraTableCell: React.FC<Props> = ({c, children, ...tableCellProps}) => {
    if (!c) {
        console.error('MithraTableCell: ColSpec not found')
        return <div>ColSpec Not found</div>
    }
    let className = c.cls;
    if (tableCellProps.className) {
        className += ' ' + tableCellProps.className;
        delete tableCellProps.className;
    }
    let style = c.style || {};
    if (tableCellProps.style) {
        style = {...style, ...tableCellProps.style};
        delete tableCellProps.style;
    }
    return <TableCell className={className} align={c.align}
                      style={style} {...tableCellProps}>
        {children}
    </TableCell>;
}
