import React from "react";
import {
    Content,
    Group,
    Icon,
    Item,
    ItemIndicator,
    ItemText,
    Label,
    Portal,
    Root,
    Separator,
    Trigger,
    Value,
    Viewport
} from '@radix-ui/react-select'
import {CheckIcon, ChevronDownIcon} from '@radix-ui/react-icons'

import {cn} from '../../lib/utils'

const ShadcnSelect = Root

const SelectGroup = Group

const SelectValue = Value

const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof Trigger>,
    React.ComponentPropsWithoutRef<typeof Trigger>
>(({className, children, ...props}, ref) => (
    <Trigger
        ref={ref}
        className={cn(
            'tw-flex tw-h-10 tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background tw-placeholder:text-muted-foreground tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-ring tw-focus:ring-offset-2 tw-disabled:tw-cursor-not-allowed tw-disabled:tw-opacity-50',
            className
        )}
        {...props}
    >
        {children}
        <Icon asChild>
            <ChevronDownIcon className="tw-h-4 tw-w-4 tw-opacity-50"/>
        </Icon>
    </Trigger>
))
SelectTrigger.displayName = Trigger.displayName

const SelectContent = React.forwardRef<
    React.ElementRef<typeof Content>,
    React.ComponentPropsWithoutRef<typeof Content>
>(({className, children, position = 'popper', ...props}, ref) => (
    <Portal>
        <Content
            ref={ref}
            className={cn(
                'relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                position === 'popper' &&
                'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                className
            )}
            position={position}
            {...props}
        >
            <Viewport
                className={cn(
                    'p-1',
                    position === 'popper' &&
                    'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
                )}
            >
                {children}
            </Viewport>
        </Content>
    </Portal>
))
SelectContent.displayName = Content.displayName

const SelectLabel = React.forwardRef<
    React.ElementRef<typeof Label>,
    React.ComponentPropsWithoutRef<typeof Label>
>(({className, ...props}, ref) => (
    <Label
        ref={ref}
        className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
        {...props}
    />
))
SelectLabel.displayName = Label.displayName

const SelectItem = React.forwardRef<
    React.ElementRef<typeof Item>,
    React.ComponentPropsWithoutRef<typeof Item>
>(({className, children, ...props}, ref) => (
    <Item
        ref={ref}
        className={cn(
            'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className
        )}
        {...props}
    >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ItemIndicator>
        <CheckIcon className="h-4 w-4"/>
      </ItemIndicator>
    </span>

        <ItemText>{children}</ItemText>
    </Item>
))
SelectItem.displayName = Item.displayName

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof Separator>,
    React.ComponentPropsWithoutRef<typeof Separator>
>(({className, ...props}, ref) => (
    <Separator
        ref={ref}
        className={cn('-mx-1 my-1 h-px bg-muted', className)}
        {...props}
    />
))
SelectSeparator.displayName = Separator.displayName

export {
    ShadcnSelect,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator
}
