import React from "react";
import {TextField} from '@mui/material';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {EditorModalComponent} from "../EditorModalComponent";

export const UpdateCategoryModal: React.FC = observer(() => {
    const onSave = () => taxonomyEditorStore.saveUpdateCategory();
    const {taxonomyEditorStore} = useStores();
    return <EditorModalComponent
        titleId="update-category-modal-title"
        className="taxonomy-modal-component update-category-modal"
        title="Rename category"
        canSave={taxonomyEditorStore.canUpdateCategory}
        onSave={onSave}
        onClose={() => taxonomyEditorStore.setUpdateCategoryMode(false)}
    >
        {/*<section className="modal-introduction">*/}
        {/*</section>*/}

        <TextField
            autoFocus
            fullWidth
            id="taxonomy-editor-modal-name-field"
            label="Category name"
            error={Boolean(taxonomyEditorStore.updateCategoryNameError)}
            helperText={taxonomyEditorStore.updateCategoryNameError}
            variant="outlined"
            value={taxonomyEditorStore.updateCategoryName}
            onChange={e => taxonomyEditorStore.setUpdateCategoryName(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            multiline
            id="taxonomy-editor-modal-description-field"
            label="Description"
            error={Boolean(taxonomyEditorStore.updateCategoryDescriptionError)}
            helperText={taxonomyEditorStore.updateCategoryDescriptionError}
            variant="outlined"
            value={taxonomyEditorStore.updateCategoryDescription}
            onChange={e => taxonomyEditorStore.setUpdateCategoryDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />
    </EditorModalComponent>
})
