import './OpportunityStatusDropdown.scss';
import {OpportunityStatusEnum} from "../../../services/classes/OpportunityClasses";
import {FormControl, ListItemIcon, ListItemText} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {getOpportunityStatusName, OPPORTUNITY_STATUS_VISUAL} from "../utils/OpportunityStatusVisual";

type Props = {
    status: OpportunityStatusEnum | undefined;
    setStatus: (status: OpportunityStatusEnum) => void;
    loading: boolean;
};
export const OpportunityStatusDropdown: React.FC<Props> = ({status, setStatus, loading}) => {
    const statusOptions = Object.entries(OPPORTUNITY_STATUS_VISUAL);

    const handleChange = (e) => {
        const displayString = e.target.value;
        const enumValue = parseInt(Object.keys(OPPORTUNITY_STATUS_VISUAL).find(key => OPPORTUNITY_STATUS_VISUAL[key].label === displayString) || '', 10);
        setStatus(enumValue);
    };

    const currentStatus = (status ? getOpportunityStatusName(status) : undefined)
        || getOpportunityStatusName(OpportunityStatusEnum.PENDING_REVIEW);

    return (
        <FormControl variant="outlined" disabled={loading} className="ppv-opportunity-status-dropdown">
            <Select
                value={currentStatus}
                onChange={handleChange}
                MenuProps={{PopoverClasses: {root: 'ppv-opportunity-status-dropdown-menu'}}}
            >
                {statusOptions.map(([key, {label, color, icon, border}]) => (
                    <MenuItem key={key} value={label}>
                        <div className="menu-wrapper" style={{
                            backgroundColor: color,
                            border: border,
                        }}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={label}/>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
