import {Grid} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";
import {Analytics, Dashboard, Gavel, Help, Info, Inventory, LocalShipping, RequestPage} from "@mui/icons-material";
import {ContractViewDataBlock} from "./ContractViewDataBlock";
import {
    CONTRACT_DELIVERY_FIELDS,
    CONTRACT_FINANCE_FIELDS,
    CONTRACT_INFO_FIELDS,
    CONTRACT_INVOICE_FIELDS,
    CONTRACT_OTHER_FIELDS,
    CONTRACT_PERFORMANCE_FIELDS,
    CONTRACT_PRODUCT_FIELDS,
    CONTRACT_TERMINATION_FIELDS,
    ContractFieldSpec,
    getRemainingFields
} from "../data/ContractFields";

/**
 * Note: Since the implementation of Franke the demo does not work anymore for this view
 */
export const ModalContractDetails: React.FC = observer(() => {
    const {contractInsightsStore} = useStores();

    const record = contractInsightsStore.modalContractsDetailsData;
    const d = record?.data;
    if (!d) {
        return <></>;
    }

    const remaining: ContractFieldSpec[] = getRemainingFields(d);

    return <GenericModalVertical
        titleId={""}
        className="contract-details-modal"
        isOpen={contractInsightsStore.modalContractsDetailsData !== null}
        onSave={() => contractInsightsStore.setDetailModalData(null)}
        saveTitle={"Done"}
        onCancel={() => contractInsightsStore.setDetailModalData(null)}
        title={"Contract details"}
        maxWidth="md"
    >
        <Grid container>
            <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                <ContractViewDataBlock
                    title="Contract Info" icon={<Info/>}
                    data={d} fields={CONTRACT_INFO_FIELDS}/>
                <ContractViewDataBlock
                    title="Financial Terms" icon={<RequestPage/>}
                    data={d} fields={CONTRACT_FINANCE_FIELDS}/>
                <ContractViewDataBlock
                    title="Product Details" icon={<Inventory/>}
                    data={d} fields={CONTRACT_INVOICE_FIELDS}/>
                <ContractViewDataBlock
                    title="Delivery & Stock" icon={<LocalShipping/>}
                    data={d} fields={CONTRACT_DELIVERY_FIELDS}/>
                <ContractViewDataBlock
                    title="Termination & Legal" icon={<Gavel/>}
                    data={d} fields={CONTRACT_TERMINATION_FIELDS}/>
                <ContractViewDataBlock
                    title="Performance & Compliance" icon={<Analytics/>}
                    data={d} fields={CONTRACT_PERFORMANCE_FIELDS}/>
                <ContractViewDataBlock
                    title="Group of Products" icon={<Dashboard/>}
                    data={d} fields={CONTRACT_PRODUCT_FIELDS}/>
                <ContractViewDataBlock
                    title="Other" icon={<Help/>}
                    data={d} fields={[...CONTRACT_OTHER_FIELDS, ...remaining]}/>
            </Grid>
        </Grid>
    </GenericModalVertical>
})
