import React from "react";
import './SingleReviewButtons.scss'
import {Grid, IconButton} from "@mui/material";
import {SinglePartReviewStateVisual} from "../../classes/CategorizationReviewVisualStates";
import {Edit} from "@mui/icons-material";
import {PartRowState} from "../../classes/PartRowState";
import {Link, useSearchParams} from "react-router-dom";
import {
    CategorizationReviewSearchIntent,
    CategorizationReviewSearchParam
} from "../../classes/CategorizationReviewRouteState";
import {generateIndividualCategorizationReviewPath} from "../../../../routing/routing.utils";

type Props = {
    state: SinglePartReviewStateVisual
    part: PartRowState
}
export const SingleReviewLinkButton: React.FC<Props> = ({state, part}) => {
    // const navigate = useNavigate();
    // const location = useLocation();
    const [searchParams] = useSearchParams();

    const linkDatabag: number = part.data.part?.src_databag_id ?? 0;
    if (linkDatabag === 0) {
        return <></>
    }

    let searchIntent: CategorizationReviewSearchIntent;
    if (part.data.p_name) {
        searchIntent = {
            type: 'by_part',
            part_search: part.data.p_name,
            part_search_fields: ['p_name'],
        };
    } else if (part.data.p_description) {
        searchIntent = {
            type: 'by_part',
            part_search: part.data.p_description,
            part_search_fields: ['p_description'],
        };
    } else if (part.data.part?.p_inv_line_description) {
        searchIntent = {
            type: 'by_part',
            part_search: part.data.part.p_inv_line_description,
            part_search_fields: ['p_inv_line_description'],
        };
    } else {
        // TODO: This is a simple version of it, we have to lookup p_id instead of p_name
        // searchIntent = {
        //     type: 'by_part',
        //     part_search: part.data.p_id,
        //     part_search_fields: ['p_id'],
        // };
        return <></>;
    }
    const linkPath = generateIndividualCategorizationReviewPath(linkDatabag);

    const newSearchParams = new URLSearchParams(searchParams);
    CategorizationReviewSearchParam.fromIntent(searchIntent, newSearchParams);
    // const newRouteState: CategorizationReviewState = {...location.state};
    // newRouteState.searchIntent = searchIntent;

    return (
        <Grid container alignItems="center" justifyContent="space-evenly" className="single-review-buttons">
            <Grid item xs={12}>
                <Link
                    // Navigate via right click; Add the intent to the URL params
                    to={linkPath + '?' + newSearchParams.toString()}

                    // TODO: not sure why cannot do it in the same databag, back button does not work anymore!
                    target="_blank" rel="noopener noreferrer"

                    // // Navigate via click: Add the intent to the state, and navigate directly, not working!
                    // onClick={e => {
                    //     // bagLoadingStore.loadBag(linkDatabag); // TODO: This is not a very scaleble way of initializing databags
                    //     navigate(linkPath + '?' + searchParams.toString(), {state: newRouteState})
                    // }}
                >
                    <IconButton>
                        <Edit/>
                    </IconButton>
                </Link>
            </Grid>
        </Grid>
    );
}
