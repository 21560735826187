import React, {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import Margin from "../../../utils/margin";
import './ParetoChart.scss';
import {
    COLOR_UNCATEGORIZED,
    getColorMonotone,
    PARETO_IDEAL_COLOR,
    SUPPLIER_CUMULATIVE_COLOR
} from "../../../style/colors";
import {ParetoSpec} from "./pareto-util";
import {D3Selection} from "../../../utils/global";
import {useTheme} from "@mui/styles";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import {toCurrency} from "../../currency-component/CurrencyClasses";
import {Grid, IconButton} from "@mui/material";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@mui/icons-material";
import {useStores} from "../../../stores";
import {MatSupplierCategoryConcentrationStatistics} from "../../../services/classes/MaterializedClasses";
import {fromSimpleFieldsCategories} from "../../../services/ApiHelpers";
import {deepCopy} from "../../../utils/js-utils";

const HACK_REMOVE_SELECTION_PROPERTY = true;

type SupplierInfo = {
    /**
     * For each supplier show the combined supplier information
     * Side effect: This gets re-ordered in this chart component
     */
    allStats: MatSupplierCategoryConcentrationStatistics[]
}
export type ParetoProcessedDataType = undefined | {
    /**
     * A list for total statistics grouped by the suppliers
     */
    allSuppliers: SupplierInfo[]
}

type SubData<D = MatSupplierCategoryConcentrationStatistics> = {
    key: string
    group_value: number
    group_start_value: number
    group_cumulative_value: number
    highlighted: boolean
    parent: Data<D>
}
type Data<D = MatSupplierCategoryConcentrationStatistics> = {
    is_origin: boolean
    cat_name: string
    dataId: number
    parentLabel: string
    label: string
    total: {
        value: number
        cumulative_value: number
    },
    active?: {
        value: number
        cumulative_value: number
    }
    graph_parts: SubData<D>[];
}
export type ParetoData = Data;
export type ParetoSubData = SubData;

const DATA_ORIGIN: Data<any> = {
    is_origin: true,
    cat_name: '_',
    dataId: 0,
    parentLabel: '',
    label: '',
    total: {value: 0, cumulative_value: 0},
    active: {value: 0, cumulative_value: 0},
    graph_parts: [],
}

export const defaultParetoParam = 0.8; // A : (1-A)
export const defaultMargin: Margin = {
    left: 40,
    right: 0,
    top: 10 + 12,
    bottom: 14,
};

export const byActiveThenTotal = (a: Data, b: Data) => {
    if (b.active || a.active) {
        const va = a.active ? a.active.value : 0;
        const vb = b.active ? b.active.value : 0;
        const cmp = vb - va;
        if (cmp !== 0) {
            return cmp;
        }
    }
    return b.total.value - a.total.value;
}

const VIEW_START_INDEX = 0;
const VIEW_SIZE = 15; // Number of columns to show

const HACK_OVERFLOW = .5; // Apply a little overflow to the rects to ensure no rendering issues occur

// const rightAxisTitle = 'Cumulative spend lines (0 - 100%)';

function getReShiftedView(desiredStart: number, desiredSize: number, newTotal: number): [number, number, boolean] {
    let desiredEnd = desiredStart + desiredSize
    if (desiredEnd > newTotal) {
        let viewStart = Math.max(0, newTotal - desiredSize)
        return [viewStart, newTotal, true]
    } else {
        return [desiredStart, desiredEnd, false]
    }
}

type Props = {
    data?: ParetoProcessedDataType;
    selectedGroup?: string;
    hoveredGroup?: string;
    height: number;
    width?: number;
    labelMargin?: number;
    barPadding?: number;
    paretoParam?: number; // between ~0.6 and 1.0
    showPP?: boolean;
    hideLinePlot?: boolean
    hideCumulativePlot?: boolean
    hideLegend?: boolean
    hideRightAxis?: boolean
    catAxisTitleEnd?: string
    onClick?: (d: Data) => void;
    onSubClick?: (d: SubData) => void;
    n1?: number
    n2?: number
}

// TODO: Remove animation and add ParetoGraph, invertarize comments of gdrive
export const AnimatedCombinedParetoChart: React.FC<Props> =
    ({
         data,
         selectedGroup,
         hoveredGroup,
         height,
         width,
         labelMargin,
         barPadding,
         paretoParam,
         showPP,
         hideLinePlot,
         hideCumulativePlot,
         hideLegend,
         hideRightAxis,
         catAxisTitleEnd,
         onClick,
         onSubClick,
     }) => {
        const theme = useTheme();
        const {p} = useStores();

        // const setFill: DOP = sd => sd.highlighted
        //     ? getColorByCat(sd.key)
        //     : d3.hsl(getColorByCat(sd.key)).copy({opacity: .2});
        const setFill: DOP = sd => sd.highlighted
            ? getColorMonotone(theme, sd.key)
            : d3.hsl(getColorMonotone(theme, sd.key)).copy({opacity: .2});

        if (width === undefined) {
            width = 255;
        }
        if (paretoParam === undefined) {
            paretoParam = defaultParetoParam;
        }
        if (onSubClick === undefined && onClick) {
            // TODO: This is a strange case?
            // When no special sub data point on-click is defined, just use the same as on-click for data
            onSubClick = sd => onClick(sd.parent);
        }
        const margin = {
            left: labelMargin !== undefined ? labelMargin : defaultMargin.left,
            right: defaultMargin.right,
            top: defaultMargin.top,
            bottom: defaultMargin.bottom,
        }
        const showRightAxis = !hideRightAxis;
        if (showRightAxis) {
            margin.right += 40;
        }
        const data2 = deepCopy(data);
        const totalDataPoints = data?.allSuppliers.length || 0;
        console.log('AnimatedCombinedParetoChart.totalDataPoints:', totalDataPoints);

        // const scaleWithN = n1 !== undefined
        // const _n1 = n1 === undefined ? INIT_N1 : n1
        // const _n2 = n2 === undefined ? INIT_N2 : n2
        const [desiredViewStart, setDesiredViewStart] = useState(VIEW_START_INDEX)
        const [desiredViewSize] = useState(VIEW_SIZE)
        let viewStart = 0;
        let viewEnd = totalDataPoints;
        let viewTotal = totalDataPoints;

        let plotPercentage: number;
        let barData: Data[];
        let dataValueMax: number | undefined;
        let dataCumulativeValueMax: number | undefined;
        let showAxis: boolean;
        let dataLabel: string | undefined;
        let plotCumulativeLine;
        let [viewPortionStart, viewPortionSize] = [0, 1];
        let redLineX: number | undefined;
        let redLineY: number | undefined;
        if (data2) {
            // Get all the supplier data
            const supplierData = data2.allSuppliers;
            let filteredData: SupplierInfo[];

            // Fix the axis to the unfiltered dataset
            dataValueMax = supplierData.length === 0 ? 1 : supplierData[0].allStats[0].s_total_spend;

            // if (selectedGroup === undefined) {
            //     // No group selected
            //     viewStart = Math.max(0, desiredViewStart);
            //     viewEnd = Math.min(totalDataPoints, viewStart + desiredViewSize);
            //     filteredData = supplierData.filter((_, i) => i >= viewStart && i < viewEnd)
            //     dataCumulativeValueMax = dataValueMax;
            // } else {
            //     // Filtering can only happen afterwards
            //     filteredData = supplierData;
            // }
            // Filtering can only happen afterwards
            filteredData = supplierData;

            barData = filteredData.map<Data>(s => {
                const dataId = s.allStats[0].supplier_id
                return ({
                    is_origin: false,
                    dataId,
                    cat_name: String(dataId),
                    parentLabel: `${s.allStats[0].s_name}`,
                    label: '<empty>',
                    graph_parts: s.allStats.map<SubData>(stats => {

                        const WRONG_TAX_SIZE = 10

                        const key = fromSimpleFieldsCategories(stats, WRONG_TAX_SIZE).join('|');
                        // console.log('AnimatedCombinedParetoChart.key=', key);
                        return ({
                            key: `${key}${new Date()}`,
                            group_value: stats.s_c_spend,
                            group_start_value: 0,
                            group_cumulative_value: stats.s_c_cumulative_spend,
                            highlighted: // If something is selected, show only that group
                                selectedGroup !== undefined ? selectedGroup === key
                                    // If a hoverGroup is active, show only that group
                                    : hoveredGroup !== undefined ? hoveredGroup === key
                                        // Otherwise highlight all
                                        : true,
                            parent: undefined as any,
                        });
                    }),
                    total: {
                        value: s.allStats[0].s_total_spend,
                        cumulative_value: s.allStats[0].s_total_cumulative_spend,
                    },
                    active: {
                        value: 0,
                        cumulative_value: 0,
                    }
                });
            })
            barData.forEach(parent => parent.graph_parts.forEach(p => p.parent = parent));

            if (HACK_REMOVE_SELECTION_PROPERTY) {

                barData.forEach(d => {

                    // Combine all same suppliers into one in the frontend
                    let summedValue = d3.sum(d.graph_parts.map(p => p.group_value));
                    let cumulativeValue = d3.min(d.graph_parts.map(p => p.group_cumulative_value)) || 0;
                    const singlePart: SubData = {
                        key: d.graph_parts.map(p => p.key).join('|'),
                        group_value: summedValue,
                        group_start_value: d3.min(d.graph_parts.map(p => p.group_start_value)) || 0,
                        group_cumulative_value: cumulativeValue,
                        highlighted: true,
                        parent: d.graph_parts[0].parent,
                    }
                    d.graph_parts = [singlePart as SubData];

                    d.active = {
                        value: summedValue,
                        cumulative_value: cumulativeValue,
                    }

                    d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;


                })
                barData.sort(byActiveThenTotal);

                // barData.forEach(d => {
                //     d.active = d.total;
                //     d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;
                // });
                // barData.sort(byActiveThenTotal);

                // barData.forEach(d => {
                //     // console.assert(d.graph_parts.length === 1);
                //     if (selectedGroup !== undefined) {
                //         const i = d.graph_parts.map(d => d.key).indexOf(selectedGroup);
                //         if (i !== -1) {
                //             const selectedData = d.graph_parts.splice(i, 1)[0];
                //             d.graph_parts.unshift(selectedData);
                //             d.active = {
                //                 value: selectedData.group_value,
                //                 cumulative_value: selectedData.group_cumulative_value,
                //             }
                //             d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;
                //         } else {
                //             console.log('AnimatedCombinedParetoChart Could not find active in', d.graph_parts, selectedGroup)
                //             d.active = undefined;
                //         }
                //     } else {
                //         const selectedData = d.graph_parts[0];
                //         d.active = {
                //             value: selectedData.group_value,
                //             cumulative_value: selectedData.group_cumulative_value,
                //         }
                //         d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;
                //     }
                // })
                // barData.sort(byActiveThenTotal);

                const lastData = barData[barData.length - 1];
                dataCumulativeValueMax = lastData?.active?.cumulative_value || 1;

                const [_viewStart, _viewEnd] = getReShiftedView(desiredViewStart, desiredViewSize, barData.length)
                viewStart = _viewStart;
                viewEnd = _viewEnd;
                viewTotal = barData.length;
                console.log('AnimatedCombinedParetoChart getReShiftedView', {
                    viewEnd,
                    viewTotal,
                    l: barData.length,
                    desiredViewSize
                });

                barData = barData.filter((_, i) => i >= viewStart && i < viewEnd);

            } else if (selectedGroup !== undefined) {
                barData.forEach(d => {
                    const i = d.graph_parts.map(d => d.key).indexOf(selectedGroup);
                    console.log('AnimatedCombinedParetoChart.i=', i);
                    if (i !== -1) {
                        const selectedData = d.graph_parts.splice(i, 1)[0];
                        d.graph_parts.unshift(selectedData);
                        d.active = {
                            value: selectedData.group_value,
                            cumulative_value: selectedData.group_cumulative_value,
                        }
                        d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;
                    } else {
                        d.active = undefined;
                    }
                })
                // barData = barData.filter(d => d.active_combined_value !== 0);
                barData.sort(byActiveThenTotal);

                const lastIndex1 = barData.findIndex(d => !d.active);
                const groupedDataPoints = lastIndex1 === -1 ? 0 : (lastIndex1 + 1)
                const lastData = (lastIndex1 === -1 || lastIndex1 === 0) ? undefined : barData[lastIndex1 - 1];
                dataCumulativeValueMax = lastData?.active?.cumulative_value || 1;

                const [_viewStart, _viewEnd] = getReShiftedView(desiredViewStart, desiredViewSize, groupedDataPoints)
                viewStart = _viewStart;
                viewEnd = _viewEnd;
                viewTotal = groupedDataPoints;
                console.log('AnimatedCombinedParetoChart getReShiftedView', {
                    viewEnd,
                    viewTotal,
                    groupedDataPoints,
                    desiredViewSize
                });

                barData = barData.filter((_, i) => i >= viewStart && i < viewEnd);

            } else {
                barData.forEach(d => {
                    d.active = d.total;
                    d.label = `${d.parentLabel} | ${toCurrency(p.currencySymbol, d.active.value)}`;
                });
                barData.sort(byActiveThenTotal);
            }

            if (dataValueMax === undefined) {
                // NOTE: This will change the axis when the category selection changes
                dataValueMax = d3.max(barData.map(d => d.active?.value || 0));
                // dataValueMax = d3.max(barData.map(d => d.total.value));
            } else {
                // Get the biggest value in the current view
                dataValueMax = d3.max(barData.map(d => d.active?.value || 0));
            }

            if (!hideCumulativePlot) {
                plotCumulativeLine = {
                    valueFun: d => d.active?.cumulative_value,
                    color: SUPPLIER_CUMULATIVE_COLOR,
                    label: 'Cumulative spend'
                }
            }

            console.log('AnimatedCombinedParetoChart.barData', barData.length)
            dataCumulativeValueMax = d3.max(barData.map(d => d.active?.cumulative_value || 0));
            console.log('AnimatedCombinedParetoChart.dataCumulativeValueMax', dataCumulativeValueMax);

            barData.forEach(d => d.graph_parts.forEach((d, i, arr) => {
                if (i > 0) {
                    d.group_start_value = arr[i - 1].group_start_value + arr[i - 1].group_value;
                }
            }))

            showAxis = true;
            dataLabel = 'Cumulative spend';

            if (!showPP) {
                redLineX = undefined;
                redLineY = undefined;
            } else {
                const viewPortionEnd = viewPortionStart + viewPortionSize;
                const paretoX = 1 - paretoParam;
                if (viewPortionStart <= paretoX && viewPortionEnd >= paretoX) {
                    // In view
                    redLineX = (paretoX - viewPortionStart) / viewPortionSize;
                    redLineY = paretoParam * (dataCumulativeValueMax || 1);
                } else {
                    // Out of view
                    redLineX = undefined;
                    redLineY = undefined;
                }
            }
            if (catAxisTitleEnd === undefined) {
                // catAxisTitleEnd = `Suppliers (${Math.min(barData.length, dataPointsInView)} of ${dataPointsInView})`;
            }

        } else {
            barData = [];
            plotPercentage = 1.0;
            dataValueMax = 1_000;
            plotCumulativeLine = undefined;
            dataCumulativeValueMax = undefined;
            showAxis = false;
            dataLabel = undefined;
            redLineX = showPP ? (1 - paretoParam) / plotPercentage : undefined;
            redLineY = showPP && (redLineX && redLineX <= 1) ? paretoParam * dataValueMax : undefined;
            if (catAxisTitleEnd === undefined) {
                catAxisTitleEnd = 'Suppliers';
            }
        }
        if (catAxisTitleEnd) {
            margin.bottom += FONT_SIZE + 2;
        }

        const paretoSpec = new ParetoSpec(paretoParam);
        const paretoPercentageA = Math.round(paretoParam * 100);
        // const fitSamples = Math.round(data.length / 10);
        const fitSamples = width / 5;
        const paretoData = paretoSpec.getData(fitSamples, viewPortionStart, viewPortionSize);

        const linePlot = hideLinePlot ? undefined : {
            points: paretoData,
            label: `Pareto ${paretoPercentageA}/${100 - paretoPercentageA}`,
            color: PARETO_IDEAL_COLOR,
        }

        return <>
            {/*<div>*/}
            {/*    {[0, 10, 50, 1000, 1500].map(i => <button onClick={() => setN1(i)} key={i}>Head {i}</button>)}*/}
            {/*    <button onClick={() => setN1(_n1 + 1)}>Head ++</button>*/}
            {/*    <button onClick={() => setN1(_n1 - 1)}>Head --</button>*/}
            {/*    <br/>*/}
            {/*    {[10, 50, 100, 150, 300, 1000, 1500, 30000].map(i => <button onClick={() => setN2(i)}*/}
            {/*                                                                 key={i}>Tail {i}</button>)}*/}
            {/*    <button onClick={() => setN2(_n2 * 1.5)}>Tail *1.5</button>*/}
            {/*    <button onClick={() => setN2(_n2 / 1.5)}>Tail /1.5</button>*/}
            {/*    <button onClick={() => setN2(0.20 * totalSuppliers)}>20%</button>*/}
            {/*    <button onClick={() => setN2(0.25 * totalSuppliers)}>25%</button>*/}
            {/*    <button onClick={() => setN2(0.50 * totalSuppliers)}>50%</button>*/}
            {/*    <button onClick={() => setN2(0.75 * totalSuppliers)}>75%</button>*/}
            {/*    <button onClick={() => setN2(1.00 * totalSuppliers)}>100%</button>*/}
            {/*    <hr/>*/}
            {/*    <p style={{textAlign: 'center'}}>Top {_n1} - {_n2} suppliers only</p>*/}
            {/*</div>*/}
            <CombinedAnimatedCumulativeCurveBarChart
                width={width}
                height={height}
                margin={margin}
                data={{data: barData, label: dataLabel, max: dataValueMax}}
                selectedGroup={selectedGroup}
                // axis
                leftAxis={showAxis}
                rightAxis={showAxis && showRightAxis}
                // data plot
                plotDataLine={plotCumulativeLine}
                plotDataMax={dataCumulativeValueMax}
                // line plot
                linePlot={linePlot}
                // Lines
                redLineX={redLineX} redLineY={redLineY}

                barPadding={barPadding || 0.2}
                hideLegend={Boolean(hideLegend)}

                onClick={onClick}
                onSubClick={onSubClick}
                catAxisTitleEnd={catAxisTitleEnd}
                setFill={setFill}
                x={viewStart * 10000000 + viewTotal}
            />
            <SupplierNavigateActions
                viewStartI={viewStart}
                viewEndI={viewEnd}
                total={viewTotal}
                setDesiredStartI={setDesiredViewStart}
            />
        </>;
    };

const FONT_SIZE = 12;
const LABEL_SPACING = 4;

const D = 0.5;
// const DURATION_1 = 800;
// const DURATION_2 = 200;
const DURATION_1 = 700 * D; // Reorder stacks
const DURATION_2 = 125 * D; // Moving animation
const DURATION_3 = 250 * D; // Sorting animation

export type DOP<R = any> = (x: SubData) => R;

const hasLabels = true;

const CombinedAnimatedCumulativeCurveBarChart: React.FC<{
    width: number, height: number, margin: Margin,
    data: { data: Data[], max?: number, label?: string },
    selectedGroup?: string,
    leftAxis?: boolean, // $
    rightAxis?: boolean, // %
    barPadding: number,
    onClick?: (d: Data) => void,
    onSubClick?: (d: SubData) => void,
    plotDataLine?: { valueFun: (d: Data) => number, label?: string, color: any },
    plotDataMax?: number,
    linePlot?: { points: { x: number, y: number }[], label: string, color: any },
    redLineX?: number,
    redLineY?: number,
    catAxisTitleEnd?: string,
    setFill: DOP<string>,
    x?: number,
    hideLegend: boolean,
}> = (props) => {
    const d3Container = useRef<SVGSVGElement>(null);
    const graphWidth = props.width - props.margin.left - props.margin.right;
    const graphHeight = props.height - props.margin.top - props.margin.bottom;
    const xRange = [0, graphWidth];
    const yRange = [graphHeight, 0];

    const [oldSelectedGroup, setOldSelectedGroup] = useState<string | undefined | null>(null);

    const barMax = props.data.max !== undefined
        ? props.data.max
        // : (d3.barMax(data, d => Math.barMax(d.valueBar, d.valuePlot)) || 1)
        : 1

    const setFill = props.setFill;
    const barFill = setFill;

    // set the range and domain for the axis
    let catAxis = d3.scaleBand()
        .domain(props.data.data.map(d => d.cat_name))
        .range(xRange)
    const BAR_SIZE = catAxis.bandwidth();
    const BAR_WIDTH = BAR_SIZE * (1 - props.barPadding);
    const BAR_SPACING = BAR_SIZE * props.barPadding / 2;

    let barValueAxis = d3.scaleLinear().domain([0, barMax]).range(yRange)
    let lineValueAxis = !props.plotDataMax
        ? barValueAxis
        : d3.scaleLinear().domain([0, props.plotDataMax]).range(yRange)
    let lineFitXAxis = d3.scaleLinear().domain([0, 1]).range(xRange);
    let lineFitYAxis = d3.scaleLinear().domain([0, 1]).range(yRange);

    function fixBarLabel(this: any) {
        const d3Element = d3.select<SVGTextElement, Data>(this);
        const d = d3Element.datum();
        const x = catAxis(d.cat_name) as number + BAR_SPACING;
        d3Element.attr('x', x)
            .text(d => `${d.label}`)
            .each(function () {
                const bbox = this.getBBox()
                const overflow = x + bbox.width - graphWidth;
                if (overflow > 0) {
                    // If the text will overflow the graph, adjust the position
                    const newX = x - overflow;

                    const domain = catAxis.domain();
                    const portionX = newX / graphWidth * domain.length;
                    let leftXBarIndex = Math.floor(portionX);
                    const leftXRemainder = portionX - leftXBarIndex;
                    if (leftXRemainder > (1 - props.barPadding / 2)) {
                        leftXBarIndex += 1
                    }
                    const collideData = props.data.data[leftXBarIndex];
                    const newY = barValueAxis(collideData?.active?.value || 0);
                    d3Element.attr('x', newX);
                    d3Element.attr('y', newY - LABEL_SPACING);
                    // console.log('AnimatedCombinedParetoChart.Label collision', d.label, '->', collideData.label)
                    // d3.select(this.parentNode as any).append('rect')
                    //     .attr('x', newX - 10)
                    //     .attr('y', newY - 10)
                    //     .attr('width', 10)
                    //     .attr('height', 10);
                }
            })
    }

    function barsOnEnter(s: D3Selection<Data>) {
        const barWrapper = s.append('rect')
            .classed('hover-overlay', true)
            .attr('height', graphHeight)
            .attr('width', BAR_SIZE)
        // dataOnEnter.append('rect')
        //     .classed('bar-gb', true)
        if (props.onClick) {
            const onClick = props.onClick;
            barWrapper
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as Data;
                    onClick(data);
                })
        }
    }

    function barsOnUpdate(s: D3Selection<Data>) {
        s.select('rect.hover-overlay')
            .attr('x', d => (catAxis(d.cat_name) as number))
            .attr('y', 0)
    }

    function subBarsOnEnter(s: D3Selection<SubData>) {
        const subBar = s.append('rect')
            .classed('bar-part', true) // .attr('data-group', sd => '' + sd.key)
            .attr('width', BAR_WIDTH)
            .attr('height', sd => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value) + HACK_OVERFLOW)
            .attr('fill', barFill)
            .attr('x', sd => (catAxis(sd.parent.cat_name) as number) + BAR_SPACING)
            .attr('y', sd => barValueAxis(sd.group_start_value + sd.group_value))

        if (props.onSubClick) {
            const onSubClick = props.onSubClick;
            subBar
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as SubData;
                    onSubClick(data);
                })
        }
    }

    function subBarsOnUpdate(subDataOnUpdate: D3Selection<SubData>, dataOnUpdate: D3Selection<Data>) {
        subDataOnUpdate.classed('active', sd => sd.highlighted)
        const onReorderTransitionEnd = subDataOnUpdate
            .select<SVGRectElement>('rect')
            .attr('fill', setFill)
            .transition('t1')
            .duration(DURATION_1)
            .attr('fill', barFill)
            .attr('height', sd => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value) + HACK_OVERFLOW)
            .attr('y', sd => barValueAxis(sd.group_start_value + sd.group_value))
            .end()

        // labelAnimation
        dataOnUpdate
            .select('text')
            .transition('t2')
            .duration(DURATION_1)
            .attr("y", d => barValueAxis(d.active?.value || 0) - LABEL_SPACING)

        // const onReorderTransitionEnd = subDataOnUpdate.select<SVGRectElement>('rect').transition('t2').delay(DURATION_1 / 4).end()

        return onReorderTransitionEnd.then(() => {
            const TOP_SORT = props.data.data.findIndex(d => d.active === undefined);
            // console.log('AnimatedCombinedParetoChart.TOP_SORT = ' + TOP_SORT)
            const isSmallTop = TOP_SORT <= 3;

            let labelAnimation = dataOnUpdate.select('text').transition('t2')
            let barAnimation = subDataOnUpdate.select<SVGRectElement>('rect').transition('t2');
            if (isSmallTop) {
                if (hasLabels) {
                    labelAnimation = labelAnimation.duration(DURATION_3);
                }
                barAnimation = barAnimation.duration(DURATION_3)
            } else {
                const PARAM_SORT_TRANSITION_DELAY = DURATION_3 / (TOP_SORT + 1);
                const delaysMap = Object.fromEntries(props.data.data.map((d, i) => {
                    let delay: number;
                    if (i < TOP_SORT) {
                        // Delay to top
                        delay = (TOP_SORT - i - 1) * PARAM_SORT_TRANSITION_DELAY;
                    } else {
                        // Do not delay the tail
                        delay = 0;
                    }
                    return [d.cat_name, delay];
                }));
                if (hasLabels) {
                    labelAnimation = labelAnimation.duration(DURATION_2).delay(d => delaysMap[d.cat_name])
                }
                barAnimation = barAnimation.duration(DURATION_2).delay(sd => delaysMap[sd.parent.cat_name])
            }

            if (labelAnimation) {
                console.log('AnimatedCombinedParetoChart fixBarLabel')
                labelAnimation
                    // .attr("x", d => catAxis(d.cat_name) as number + BAR_SPACING)
                    .each(fixBarLabel)
            }
            barAnimation
                .attr('width', BAR_WIDTH)
                .attr('x', sd => (catAxis(sd.parent.cat_name) as number) + BAR_SPACING)

            return barAnimation.end();
        })
            .catch(e => {
                console.error(e);
            })
    }

    useEffect(() => {
        if (!d3Container.current) {
            return;
        }
        console.log('AnimatedCombinedParetoChart CombinedCumulativeCurveBarChart.change!');

        // // Pre-mature optimization is the root of all evil
        // const groupChanged = oldSelectedGroup !== props.selectedGroup;
        // if (!groupChanged) {
        //     if (props.selectedGroup !== undefined)
        //         return;
        // }

        const isToReset = oldSelectedGroup !== undefined && props.selectedGroup === undefined;
        console.log('AnimatedCombinedParetoChart isToReset=', isToReset);

        const rootG = d3.select(d3Container.current as SVGElement)
            .attr("transform", "translate(" + props.margin.left + "," + props.margin.top + ")");

        // append the rectangles for the bar chart
        const dataSelection = rootG
            .select('g.data-bars')
            .selectAll<SVGGElement, Data>('g.bar-wrapper')
            .data(props.data.data, (d: Data) => `${d.dataId}`)

        // Draw the bar wrappers
        const dataOnEnter = dataSelection.enter()
            .append('g')
            .classed('bar-wrapper', true)
            .call(barsOnEnter)

        // Update the bars
        const dataOnUpdate = dataSelection.merge(dataOnEnter).call(barsOnUpdate);

        // Add the sub bars
        const subDataSelection = dataOnUpdate.selectAll<SVGGElement, SubData>('g.bar-parts')
            .data<SubData>(d => d.graph_parts, sd => `${sd.parent.dataId}|${sd.key}`)
        const subDataOnEnter = subDataSelection.enter()
            .append('g')
            .classed('bar-parts', true)
            .call(subBarsOnEnter)

        // Update the sub bars
        const onReorderTransitionEnd =
            subBarsOnUpdate(subDataOnEnter.merge(subDataSelection), dataOnUpdate);

        // Remove bars out of view
        dataSelection.exit()
            .remove()

        subDataSelection.exit()
            .remove()

        // const barGroupWrapper = dataOnEnter
        //     .selectAll('rect.bar-part')
        //     .join('rect')
        //     .classed('bar-part', true)
        //     .attr('data-group', sd => '' + sd.key)
        //     .attr('x', sd => (catAxis(sd.parent.cat_name) as number))
        //     .attr('y', sd => barValueAxis(sd.group_start_value + sd.group_value))
        //     .attr('width', catAxis.bandwidth())
        //     .attr('height', sd => barValueAxis(sd.group_start_value) - barValueAxis(sd.group_start_value + sd.group_value))
        //     // .attr('fill', sd => vizColor(sd.parent.cat_name + '__' + sd.key))
        //     // .attr('fill', sd => vizColor(sd.key))
        //     .attr('fill', sd =>
        //         sd.key === selectedGroup ? 'red' : vizColor(sd.key)
        //     )

        if (hasLabels) {
            const barLabels = dataOnEnter.append('text')
                .classed('bar-label', true)
                // .attr("x", d => catAxis(d.cat_name) as number + BAR_SPACING)
                .attr("y", d => barValueAxis(d.active?.value || 0) - LABEL_SPACING)

                // dataOnUpdate.select('text')
                //     .transition()
                //     // .delay(DURATION_1)
                //     .delay((d, i) => DURATION_1 + (i < TOP_SORT ? i * 10 : 0))
                //     .duration(DURATION_2)
                //     .attr("x", d => catAxis(d.cat_name) as number + BAR_SPACING)
                //     .attr("y", d => barValueAxis(d.active.value) - LABEL_SPACING)

                // const barLabels = dataOnEnter.append('text')
                .attr('dominant-baseline', 'text-bottom')

            // dataOnUpdate.select('text')
            //     .transition('t1')
            //     // .delay(DURATION_1)
            //     .delay((d, i) => DURATION_1 + (i < TOP_SORT ? (TOP_SORT - i - 1) * PARAM_SORT_TRANSITION_DELAY : 0))
            //     .duration(DURATION_2)
            //     .attr("x", d => catAxis(d.cat_name) as number + BAR_SPACING)
            //     .attr("y", d => barValueAxis(d.active.value) - LABEL_SPACING)

            // const barLabels = dataOnEnter.append('text')

            barLabels.each(fixBarLabel)
        }

        // add the horizontal Axis
        const hAxisGroup = rootG.select<SVGGElement>("g.cat-axis");
        const hAxisLine: d3.Selection<SVGLineElement, unknown, null, undefined> = hAxisGroup.select('line').node()
            ? hAxisGroup.select('line')
            : hAxisGroup.append('line');
        hAxisLine
            .attr('x1', 0)
            .attr('y1', graphHeight)
            .attr('x2', graphWidth)
            .attr('y2', graphHeight)
        if (props.catAxisTitleEnd) {
            // Add horizontal axis at the end
            const hAxisTitleGroup = rootG.select<SVGGElement>("g.cat-axis-title");
            const hAxisTitle: d3.Selection<SVGTextElement, unknown, null, undefined> = hAxisTitleGroup.select('text').node()
                ? hAxisTitleGroup.select('text')
                : hAxisTitleGroup.append('text')
            hAxisTitle
                .text(`${props.catAxisTitleEnd}`)
                .attr("x", graphWidth)
                .style('text-anchor', 'end')
                .attr("y", props.height)
                .attr("dy", "-2em")
        }
        // rootG.append("g")
        //     .classed('category-axis', true)
        //     .attr("transform", "translate(0," + graphHeight + ")")
        //     .call(d3.axisBottom(catAxis)
        //         // .tickFormat(() => '') // Hide the labels
        //         // .tickSize(0) // Hide ticks
        //         .tickValues([]) // Hide the ticks AND labels
        //     )

        // add the vertical Axis
        // Build sensible graph for values [0-100], [0-1000], [0-10K], [0-100K], etc
        const barAxis = rootG.select<SVGGElement>("g.bar-value-axis");
        barAxis.call(props.leftAxis
            ? d3.axisLeft(barValueAxis).ticks(5).tickFormat(v => d3.format("~s")(v))
            : d3.axisLeft(barValueAxis).tickValues([]) // Hide the ticks AND labels
        )
        if (props.rightAxis) {
            const lineAxis = rootG.select<SVGGElement>("g.line-value-axis")
                .attr('transform', `translate(${graphWidth}, 0)`)
            lineAxis.call(
                d3.axisRight(lineFitYAxis).ticks(5).tickFormat(v => Math.round(Number(v) * 100) + '%')
            )
        }

        const drawLineThroughOrigin = true;
        const curveDataAnimation = false;
        const curveDataUpdateAfterAnimation = true;
        const LOWER_P = .8
        if (props.plotDataLine) {
            const plotFunc = props.plotDataLine.valueFun;
            // Add cumulative line
            const cumulativeLine = d3.line<Data>(
                d => d.is_origin ? 0 : (catAxis(d.cat_name) as number + BAR_SIZE),
                d => d.is_origin ? lineValueAxis(0) : lineValueAxis(plotFunc(d)) as number,
            )

            const lowerLine = d3.line<Data>(
                d => d.is_origin ? 0 : (catAxis(d.cat_name) as number + BAR_SIZE),
                d => d.is_origin ? lineValueAxis(0) : lineValueAxis(plotFunc(d) * LOWER_P) as number,
            )


            let curveData: Data[] = props.data.data;
            if (drawLineThroughOrigin) {
                curveData = [DATA_ORIGIN].concat(curveData);
            }
            curveData = curveData.filter(d => d.active);
            const zeroData = curveData.map<[number, number]>((d, i) => ([i * graphWidth / (curveData.length - 1), graphHeight]));

            // zeroLine
            d3.line()(zeroData);

            const curveDataSelection = rootG.select('g.data-curve')
                .selectAll<SVGPathElement, Data>('path')
                .data([curveData])

            const curveDataEnter = curveDataSelection.enter()
                .append('path')
                .attr("stroke", props.plotDataLine.color)
            const curveDataUpdate = curveDataSelection.merge(curveDataEnter)
                .attr('stroke-opacity', 1)

            if (curveDataAnimation) {
                curveDataSelection
                    .transition('t1')
                    .duration(100)
                    .attr('stroke-opacity', 0)

                onReorderTransitionEnd.then(() => {
                    curveDataUpdate
                        // .attr('d', () => zeroLine)
                        .attr('d', lowerLine)
                        .transition('t1')
                        .ease(d3.easeExpOut)
                        .duration(1000)
                        .attr('d', cumulativeLine)
                        .attr('stroke-opacity', 1)
                    console.log('AnimatedCombinedParetoChart onReorderTransitionEnd')
                })
            } else if (curveDataUpdateAfterAnimation) {
                curveDataUpdate
                    .attr('stroke-opacity', 0)
                onReorderTransitionEnd.then(() => {
                    curveDataUpdate
                        .attr('d', cumulativeLine)
                        .attr('stroke-opacity', 1)
                })
            } else {
                curveDataUpdate
                    .attr('d', cumulativeLine)
            }


            // // Draw the bar wrappers
            // const dataOnEnter = dataSelection.enter()
            //     .append('g')
            //     .classed('bar-wrapper', true)
            //     .call(barsOnEnter)

            // .join('path')
            // .attr("fill", "none")
            // .attr("stroke", props.plotDataLine.color)
            // .attr("stroke-width", 1.5)
            // .attr("d", cumulativeLine)
        }

        if (props.linePlot) {
            type XY = {
                x: number;
                y: number;
            }
            const line = d3.line<XY>(
                d => lineFitXAxis(d.x),
                d => lineFitYAxis(d.y),
            )
            const fitDataSelection = rootG.select('g.fit-curve')
                .selectAll<SVGPathElement, XY>('path')
                .data([props.linePlot.points]);

            const fitDataEnter = fitDataSelection.enter()
                .append('path')
                .attr('stroke', props.linePlot.color)
            const fitDataUpdate = fitDataSelection.merge(fitDataEnter)
                .attr('stroke-opacity', 1)

            if (curveDataAnimation) {
                fitDataSelection
                    .transition('t1')
                    .duration(100)
                    .attr('stroke-opacity', 0)
                const lowerLine = d3.line<XY>(
                    d => lineFitXAxis(d.x),
                    d => lineFitYAxis(d.y * LOWER_P),
                )
                onReorderTransitionEnd.then(() => {
                    fitDataUpdate
                        .attr('d', lowerLine)
                        .transition('t1')
                        .ease(d3.easeExpOut)
                        .duration(1000)
                        .attr('d', line)
                        .attr('stroke-opacity', 1)
                })
            } else if (curveDataUpdateAfterAnimation) {
                fitDataUpdate
                    .attr('stroke-opacity', 0)
                onReorderTransitionEnd.then(() => {
                    fitDataUpdate
                        .attr('d', line)
                        .attr('stroke-opacity', 1)
                })
            } else {
                fitDataUpdate
                    .attr('d', line)
            }
        }

        // redLineX
        const redLineXData: number[] = props.redLineX ? [props.redLineX * graphWidth] : [];
        console.log('AnimatedCombinedParetoChart redLineXData', redLineXData)
        const rlXData = rootG.select('g.reference-line-x')
            .selectAll<SVGGElement, number>('g')
            .data(redLineXData)
        const rlXEnter = rlXData.enter()
            .append('g')
        rlXEnter
            .append('line')
            .attr("y1", 0)
            .attr("y2", graphHeight)
            .attr('stroke', props.linePlot?.color || 'black')
        rlXEnter
            .append('text')
            .attr('fill', props.linePlot?.color || 'black')
            .text('20%')
            .attr('dominant-baseline', 'hanging')
            .attr('y', graphHeight + 2)
        const rlXUpdate = rlXEnter.merge(rlXData)
        rlXUpdate.select('line')
            .attr("x1", d => d)
            .attr("x2", d => d)
        rlXUpdate.select('text')
            .attr('x', d => d)
        rlXData.exit().remove()

        // redLineY
        const redLineYData: number[] = props.redLineY ? [lineValueAxis(props.redLineY)] : [];
        // console.log('AnimatedCombinedParetoChart redLineYData', redLineYData)
        const rlYData = rootG.select('g.reference-line-y')
            .selectAll<SVGGElement, number>('g')
            .data(redLineYData)
        const rlYEnter = rlYData.enter()
            .append('g')
        rlYEnter
            .append('line')
            .attr('stroke', props.linePlot?.color || 'black')
            .attr("x1", 0)
            .attr("x2", graphWidth)
        rlYEnter
            .append('text')
            .attr('fill', props.linePlot?.color || 'black')
            .text('80%')
            .attr('dominant-baseline', 'hanging')
            .attr('x', 2)
        const rlYUpdate = rlYEnter.merge(rlYData)
        rlYUpdate.select('line')
            .attr("y1", d => d)
            .attr("y2", d => d)
        rlYUpdate.select('text')
            .attr('y', d => d + 1)
        rlYData.exit().remove()

        if (curveDataAnimation) {
            // N/A
        } else if (curveDataUpdateAfterAnimation) {
            rlXUpdate.select('line, text')
                .attr('stroke-opacity', 0)
            rlYUpdate.select('line, text')
                .attr('stroke-opacity', 0)
            onReorderTransitionEnd.then(() => {
                rlXUpdate.select('line, text')
                    .attr('stroke-opacity', 1)
                rlYUpdate.select('line, text')
                    .attr('stroke-opacity', 1)
            })
        } else {
            // Keep opacity
        }

        const showLegend = !props.hideLegend;
        if (showLegend) {
            const legendData: { color: any, label: string }[] = [];
            const legendWidth = 150;

            legendData.push({color: COLOR_UNCATEGORIZED, label: UNCATEGORIZED_LABEL});
            if (props.data) {
                // legendData.push({color: COLOR_DATA_PLOT, label: props.data.label || 'Bars'});
            }
            if (props.plotDataLine) {
                legendData.push({color: props.plotDataLine.color, label: props.plotDataLine.label || 'Line'});
            }
            if (props.linePlot) {
                legendData.push({color: props.linePlot.color, label: props.linePlot.label || 'Fit'});
            }

            const legendOnEnter = rootG.select('g.legend')
                .selectAll('g.legend-entry')
                .data(legendData)
                .enter()
                .append('g')
                .classed('legend-entry', true)

            legendOnEnter
                .append('circle')
                .attr('cx', graphWidth - legendWidth)
                .attr('cy', (d, i) => graphHeight * (1 / 3) - i * FONT_SIZE * 1.5)
                .attr('r', 6)
                .style("fill", d => d.color)
                .style("font-size", FONT_SIZE)
            legendOnEnter.append('text')
                .text(d => d.label)
                .attr('x', graphWidth - legendWidth + 12)
                .attr('y', (d, i) => graphHeight * (1 / 3) + 1 - i * FONT_SIZE * 1.5)
                .attr('alignment-baseline', 'middle')
                .style("fill", d => d.color)
        }


        setOldSelectedGroup(props.selectedGroup);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, d3Container.current])

    return <svg
        className={'combined pareto-chart'}
        viewBox={`0 0 ${props.width} ${props.height}`}
        style={{width: '100%', height: 'auto'}}>
        <g ref={d3Container}>
            <g className="data-bars"/>
            <g className="data-curve"/>
            <g className="fit-curve"/>
            <g className="bar-value-axis axis"/>
            <g className="line-value-axis axis"/>
            <g className="cat-axis axis"/>
            <g className="cat-axis-title axis-title"/>
            <g className="legend"/>
            <g className="reference-line reference-line-x"/>
            <g className="reference-line reference-line-y"/>
        </g>
    </svg>;
};

type SupplierNavigateProps = {
    viewStartI: number
    viewEndI: number
    total: number
    setDesiredStartI: (d: number) => void
}
const SupplierNavigateActions: React.FC<SupplierNavigateProps> = ({viewStartI, viewEndI, total, setDesiredStartI}) => {
    const inView = viewEndI - viewStartI;
    // const classes = useStyles1();
    // const theme = useTheme();
    const handleFirstPageButtonClick = () => {
        setDesiredStartI(0)
    };

    const handleBackButtonClick = () => {
        setDesiredStartI(Math.max(0, viewStartI - inView))
    };

    const handleNextButtonClick = () => {
        setDesiredStartI(Math.min(viewStartI + inView, total - inView))
    };

    const handleLastPageButtonClick = () => {
        setDesiredStartI(Math.max(0, total - inView))
    };

    return (
        <Grid container justifyContent="flex-end">
            <Grid item>
                <p>Suppliers</p>
            </Grid>
            <Grid item>
                <IconButton onClick={handleFirstPageButtonClick}> <FirstPage/> </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={handleBackButtonClick}><KeyboardArrowLeft/></IconButton>
            </Grid>
            <Grid item>
                <p>
                    {viewStartI + 1} - {viewEndI} of {total}
                </p>
            </Grid>
            <Grid item>
                <IconButton onClick={handleNextButtonClick}><KeyboardArrowRight/></IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={handleLastPageButtonClick}> <LastPage/> </IconButton>
            </Grid>
        </Grid>
    );
}
