import "./DataMapping.scss"
import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid} from "@mui/material";
import {generateGotoDashboardPath} from "../../../routing/routing.utils";
import {useLocation, useNavigate} from "react-router-dom";
import {useStores} from "../../../stores";
import {C} from "../../../configurations/active-configuration";
import {environment} from "../../../env";


export const DatasetFinish: React.FC = observer(() => {
    const {rootAppStore, dataIngestionStore} = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    return <div className="finish-component">
        <Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <h2>Finished creating a new dataset!</h2>
            </Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        dataIngestionStore.resetIngestionStore();

                        let databagId = dataIngestionStore.newDatabagId;
                        if (environment.isDemo && C.profile.demoFakeDataIngestionTarget) {
                            databagId = C.profile.demoFakeDataIngestionTarget.databagId;
                        }
                        if (databagId)
                            navigate(generateGotoDashboardPath(location, {id: String(databagId)}, rootAppStore.app));
                    }}>
                    To the dashboard
                </Button>
            </Grid>
        </Grid>
    </div>
})