import './TaxonomyEditor.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {Add, AdminPanelSettings, LocalHospital} from "@mui/icons-material";
import {MoveCategoryModal} from "./modals/MoveCategoryModal";
import {CreateCategoryModal} from "./modals/CreateCategoryModal";
import {MergeCategoryModal} from "./modals/MergeCategoryModal";
import {TaxonomyEditor} from "../../components/visualization/taxonomy-editor/TaxonomyEditor";
import {UpdateCategoryModal} from "./modals/UpdateCategoryModal";
import {TaxonomyNodeValueMode} from "../../stores/TaxonomyEditorStore";
import {generatePath, useNavigate} from "react-router-dom";
import {routes} from "../../routing/routes";
import {TaxonomyEditorButtonBar} from "./TaxonomyEditorButtonBar";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {TaxonomyEditorBreadcrumbs} from '../../components/visualization/taxonomy-editor/TaxonomyEditorBreadcrumbs';
import {TaxonomyApprovalAlert} from "./alerts/TaxonomyApprovalAlert";
import {IS_TE_DEVELOPMENT_MODE} from "../../components/visualization/taxonomy-editor/TaxonomyEditorOptions";
import {
    TaxonomyTreeEditorController
} from "../../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyTreeEditorController";
import {range} from "d3";

type Props = {
    viewer?: boolean
}
export const TaxonomyEditorComponent: React.FC<Props> = observer(({viewer}) => {
    const {bagStore, taxonomyEditorStore, taxonomyManagerStore, p, authStore} = useStores();
    const navigate = useNavigate();

    const blocked = taxonomyManagerStore.taxonomy?.has_taxonomy_health_check;

    // TODO: Disable edit mode for the taxonomy editor when navigation happens
    //  >>> OK

    const viewMode = taxonomyEditorStore.isFullViewMode ? 'full' : 'fit';
    return <>
        <Dialog
            open={
                taxonomyEditorStore.isUpdateCategoryMode
                || taxonomyEditorStore.isCreateCategoryMode
                || taxonomyEditorStore.isMergeCategoryMode
                || taxonomyEditorStore.isMoveCategoryMode
            }
            maxWidth="lg"
        >
            {taxonomyEditorStore.isUpdateCategoryMode && <UpdateCategoryModal/>}
            {taxonomyEditorStore.isCreateCategoryMode && <CreateCategoryModal/>}
            {taxonomyEditorStore.isMergeCategoryMode && <MergeCategoryModal/>}
            {taxonomyEditorStore.isMoveCategoryMode && <MoveCategoryModal/>}
        </Dialog>


        <Grid container justifyContent="center">
            <Grid item md={9} xs={12}>
                <Grid container justifyContent="end">
                    <Grid item xs={10}>
                        <TaxonomyEditorBreadcrumbs/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={2} xs={false}/>
        </Grid>


        <Grid container alignItems="center" className="header">
            {taxonomyEditorStore.allTaxonomyValueModes.length > 1 &&
                <Grid item>
                    <Select
                        variant="standard"
                        value={taxonomyEditorStore.valueMode.key}
                        disabled={taxonomyEditorStore.isEditMode}
                        onChange={e => taxonomyEditorStore.setValueMode(e.target.value as TaxonomyNodeValueMode['key'])}>
                        {taxonomyEditorStore.allTaxonomyValueModes.map(m =>
                            <MenuItem key={m.key} value={m.key}>
                                {m.label}
                            </MenuItem>
                        )}
                    </Select>
                </Grid>
            }

            {authStore.isMithraStaffGroup && <>
                <Grid item>
                    <FormControl>
                        <InputLabel id="assigned-taxonomy-select-label">
                            <AdminPanelSettings/>
                            Linked taxonomy
                        </InputLabel>
                        <Select
                            label="Linked taxonomy"
                            labelId="assigned-taxonomy-select-label"
                            variant="standard"
                            value={taxonomyManagerStore.taxonomy?.id || ''}
                            onChange={(e) => {
                                if (e.target.value === '') return;
                                taxonomyManagerStore.setTaxonomyInDatabag(Number(e.target.value))
                            }}>
                            {taxonomyManagerStore.allTaxonomyList?.map(m =>
                                <MenuItem key={m.id} value={m.id}>
                                    [{m.id}] {m.name}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl style={{minWidth: '5em'}}>
                        <InputLabel id="taxonomy-size-select-label">
                            <AdminPanelSettings/>
                            Size
                        </InputLabel>
                        <Select
                            label="Size"
                            labelId="taxonomy-size-select-label"
                            variant="standard"
                            value={taxonomyManagerStore.taxonomy?.size || 2}
                            onChange={(e) => {
                                if (e.target.value === '') return;
                                taxonomyManagerStore.updateTaxonomySize(Number(e.target.value))
                            }}>
                            {range(8).map(i =>
                                <MenuItem key={i} value={i + 1}>
                                    {i + 1}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </>}

            {(!viewer && !blocked) &&
                <Grid item className="text-right top-toolbar">
                    <TaxonomyEditorButtonBar/>
                </Grid>
            }
            {blocked &&
                <>
                    <Grid item className="top-toolbar-blocked">
                        {p.p.useAiTaxonomyHealthCheck && <Button
                            color="primary"
                            variant="contained"
                            className="toolbar-btn large-icon-btn"
                            startIcon={<LocalHospital/>}
                            onClick={() => {
                                navigate(generatePath(routes.merge_x_taxonomy_health_check, {
                                    id: bagStore.bagId,
                                    taxonomyId: p.p.hardcodedOriginalTaxonomyId || -1,
                                }))
                            }}>
                            Continue Health
                        </Button>}
                    </Grid>
                    <Grid item/>
                </>
            }
        </Grid>

        <div className={`taxonomy-editor-body view-mode-${viewMode}`}>

            {IS_TE_DEVELOPMENT_MODE &&
                <div style={{position: 'fixed', right: 0, top: 400, width: 400, backgroundColor: 'orange'}}>
                    focus.depth: {taxonomyEditorStore.focus?.depth}<br/>
                    focus.id: {'' + taxonomyEditorStore.focus?.data.id} [{taxonomyEditorStore.focus?.data.label}]<br/>
                    focus.EQ: {'' + (taxonomyEditorStore.focus === ((taxonomyEditorStore as any).controller as TaxonomyTreeEditorController | undefined)?.findFocus())}<br/>
                    viewMode: {viewMode}<br/>
                    selectionEq: {JSON.stringify(taxonomyEditorStore.debugSelectionVals)}<br/>
                    updateCategoryName: {'' + taxonomyEditorStore.updateCategoryName}<br/>
                    updateCategoryDescription: {'' + taxonomyEditorStore.updateCategoryDescription}<br/>
                    First L2 .name (export):
                    "{'' + ((taxonomyEditorStore as any).controller as TaxonomyTreeEditorController | undefined)?.exportTree().children[0].children[0].label}"<br/>
                    First L2 .desc (export)[10]:
                    "{'' + String(((taxonomyEditorStore as any).controller as TaxonomyTreeEditorController | undefined)?.exportTree().children[0].children[0].values.description).substring(0, 10)}"<br/>
                </div>
            }


            {!viewer &&
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item flexGrow={1} flexBasis={0} className="text-right">
                        {(taxonomyEditorStore.isEditMode && taxonomyEditorStore.focus?.parent) &&
                            <Typography component="span" style={{marginRight: '2em'}}>
                                Selected: {taxonomyEditorStore.focus?.data.label || 'None'}
                            </Typography>
                        }
                    </Grid>
                    <Grid item className="">
                        {taxonomyEditorStore.isEditMode &&
                            <Button
                                disabled={!taxonomyManagerStore.canEditTaxonomy}
                                className="toolbar-btn large-icon-btn wider-button z-bg-button"
                                color="primary"
                                variant="outlined"
                                startIcon={<Add/>}
                                onClick={() => taxonomyEditorStore.setCreateCategoryMode(true)}>
                                Add L{'' + taxonomyEditorStore.focusLevel}
                            </Button>
                        }
                    </Grid>
                    <Grid item flexGrow={1}/>
                </Grid>
            }
            <Grid container justifyContent="center">
                {taxonomyManagerStore.error &&
                    <Grid item><Alert severity="error">{taxonomyManagerStore.error}</Alert></Grid>
                }
                {taxonomyManagerStore.notification &&
                    <Grid item>
                        <Alert severity={taxonomyManagerStore.notification_type}>
                            {taxonomyManagerStore.notification}
                        </Alert>
                    </Grid>
                }

                <TaxonomyApprovalAlert gridItem/>

                {taxonomyManagerStore.taxonomy &&
                    <Grid item xs={12}>
                        <TaxonomyEditor/>
                    </Grid>
                }
                {(taxonomyManagerStore.taxonomy === undefined && !taxonomyManagerStore.error) &&
                    <Grid item className="loading-mid-page"><LoadingSpinnerPie/></Grid>
                }

                {(taxonomyManagerStore.taxonomy === null && !taxonomyManagerStore.error) &&
                    <Grid item className="loading-mid-page">No taxonomy selected for this dataset</Grid>
                }
            </Grid>
        </div>
    </>
});
