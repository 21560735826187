import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import AuthStore from "../../../stores/AuthStore";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable} from "mobx";
import {AxoisRequestManager} from "../../../stores/managers/RequestManager";
import {from} from "rxjs";
import {Revision} from "../../../services/classes/Bag";


export class CategorizationReviewPageRevisionController {

    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    readonly _revisionRequestManager = new AxoisRequestManager<void, Revision[]>(
        () => from(this.api.getAllRevisions())
    );

    get revision(): undefined | number | 'customer' {
        const revisionHack = this.api.revisionHack;
        if (revisionHack === 'customer') return 'customer';
        const revisionId = Number(revisionHack)
        if (isNaN(revisionId)) return undefined
        return revisionId
    }

    get revisions() {
        return this._revisionRequestManager.result;
    }

    setRevision(value: string | 'customer') {
        console.log('Setting revision to', value)
        if (value === 'customer') {
            this.api.revisionHack = 'customer'
        } else {
            const revisionId = Number(value);
            if (revisionId && !isNaN(revisionId)) {
                this.api.revisionHack = String(revisionId)
            } else {
                this.api.revisionHack = undefined
            }
        }
        this.categorizationReviewPageController.reviewPageStateController.setPage('review');
        this.categorizationReviewPageController.loadData(true);
    }
}