import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {SingularCategorizationReviewRow} from "./SingularCategorizationReviewRow";
import {useStores} from "../../../../../stores";
import {MithraTableHeadColumns} from "../../../../../components/table/MithraTableHeadColumns";
import {getCatReviewSubColumns} from "../columns";
import {PartReCategorizationHandler} from "../../../../../components/categorization/PartRecategorizationHandler";
import {MithraTablePaginationActions} from "../../../../../components/table-pagination/MithraTablePaginationActions";
import {ChangeCategoryFooterButton} from "../../change-category/ChangeCategoryFooterButton";

export const SingularCategorizationReviewTable: React.FC = observer(() => {
    const {p, categorizationReviewStore} = useStores()
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const reviewPageDataController = categorizationReviewStore.reviewPageController.reviewPageDataController;
    const categories = categorizationReviewStore.categories;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    const isLoading = categorizationReviewStore.isLoading;
    const tableClass = 'mithra-table x-design categorization-review-table singular-categorization-review-table x-design-narrow' + (isLoading ? ' loading' : '');
    const pages = reviewPageDataController.partPages;
    const data = pages.data;
    const ordering = reviewPageFilterController.ordering;
    const viewOnly = categorizationReviewStore.viewOnly;
    const COLUMNS = getCatReviewSubColumns(p, categorizationReviewStore.bagId, true, viewOnly)
    return <TableContainer className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Table cellPadding="0">
            <MithraTableHeadColumns
                columns={COLUMNS} enableLoading isLoading={isLoading}
                orderBy={ordering?.field} orderDirection={ordering?.desc ? 'desc' : 'asc'}
                onClickOrdering={c => reviewPageFilterController.toggleOrderBy(c.columnFieldId)}
            />
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            {/*<CurrencyAbbreviationContext.Provider value={abbreviation}>*/}
                            <SingularCategorizationReviewRow part={row} controller={controller}/>
                            {/*</CurrencyAbbreviationContext.Provider>*/}
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={3}>
                        <ChangeCategoryFooterButton/>
                    </TableCell>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length - 3}
                        count={pages.count}
                        rowsPerPage={pages.pageSize}
                        page={pages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => pages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
