import React from "react";
import {Grid, TextField} from "@mui/material";
import {ContractFieldSpec, READONLY_FIELDS} from "../data/ContractFields";
import {ContractViewFieldValue} from "../modals/ContractViewFieldValue";
import {ContractDataDisplay} from "../classes";

type Props = {
    spec: ContractFieldSpec
    values: ContractDataDisplay[]
}
export const ContractEditField: React.FC<Props> = ({spec, values}) => {
    if (READONLY_FIELDS.has(spec.field) || values.length !== 1)
        return <ContractViewFieldValue spec={spec} values={values}/>;
    const value = values[0];
    const fieldId = `contract-edit-field-${spec.field}`;
    return <Grid item className="block" style={{marginTop: '1.5em', marginBottom: '1em'}}>
        {/*f={'' + spec.field}*/}
        {<TextField
            name={'' + spec.field}
            id={fieldId}
            label={spec.title}
            fullWidth
            defaultValue={value}
            multiline={value.length > 85}
        />}
    </Grid>
};
