import {makeAutoObservable} from "mobx";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {SynergyStore} from "../SynergyStore";

/**
 * The synergy store for common suppliers
 */
export class CommonSuppliersDelegate {
    readonly numberTotalSuppliersLeft: number = this.profileStore.p.hardcodedNumberTotalSuppliersLeft || -1;
    readonly numberTotalSuppliersRight: number = this.profileStore.p.hardcodedNumberTotalSuppliersRight || -1;
    readonly numberTotalCommonSuppliers: number = this.profileStore.p.hardcodedNumberTotalCommonSuppliers || -1;
    readonly numberCommonSuppliersLeft: number = this.profileStore.p.hardcodedNumberCommonSuppliersLeft || -1;
    readonly numberCommonSuppliersRight: number = this.profileStore.p.hardcodedNumberCommonSuppliersRight || -1;
    readonly totalSpendCommonSuppliers: number = this.profileStore.p.hardcodedTotalSpendCommonSuppliers || -1;
    readonly totalSuppliersSpendLeft: number = this.profileStore.p.hardcodedTotalSuppliersSpendLeft || -1;
    readonly totalSuppliersSpendRight: number = this.profileStore.p.hardcodedTotalSuppliersSpendRight || -1;
    readonly commonSuppliersSpendLeft: number = this.profileStore.p.hardcodedCommonSuppliersSpendLeft || -1;
    readonly commonSuppliersSpendRight: number = this.profileStore.p.hardcodedCommonSuppliersSpendRight || -1;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private synergyStore: SynergyStore,
        private bagStore: BagStore,
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    init() {
        const synergy = this.synergyStore.requestedSynergyId;
        console.log('CommonSuppliersDelegate.init', synergy);
    }
}
