import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {GroupedCategorizationReviewSubRow} from "./GroupedCategorizationReviewSubRow";
import {useStores} from "../../../../../stores";
import {MithraTableHeadColumns} from "../../../../../components/table/MithraTableHeadColumns";
import {getCatReviewSubColumns} from "../columns";
import {MithraTablePaginationActions} from "../../../../../components/table-pagination/MithraTablePaginationActions";
import {GroupedRowState} from "../../../classes/GroupedRowState";
import {SupplierSelectionAlert} from "./alerts/SupplierSelectionAlert";

type Props = {
    groupedRow: GroupedRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const GroupedCategorizationReviewSubTable: React.FC<Props> = observer(({groupedRow, controller}) => {
    const {p, categorizationReviewStore} = useStores();
    const parts = groupedRow.partStates
    const dataCount = groupedRow.partCount
    const rowsPerPage = groupedRow.pageSize
    const isLoading = groupedRow.isLoadingRows
    const viewOnly = categorizationReviewStore.viewOnly;
    const columns = getCatReviewSubColumns(p, categorizationReviewStore.bagId, false, viewOnly);
    const hasSelection = groupedRow.hasSelection;
    const tableClass = 'mithra-table x-design categorization-review-table grouped-categorization-review-table x-design-narrow'
        + (isLoading ? ' loading' : '')
        + (hasSelection ? ' has-selection' : '')
    const ordering = categorizationReviewStore.reviewPageController.reviewPageFilterController.ordering;
    return <TableContainer className={tableClass}>
        <Grid container justifyContent="center" className="selection-notification-container">
            <Grid item>
                <SupplierSelectionAlert groupedRow={groupedRow}/>
            </Grid>
        </Grid>
        <Table className="sub-table" size="small">
            <MithraTableHeadColumns columns={columns}
                                    enableLoading
                                    isLoading={isLoading}
                                    orderBy={ordering?.field}
                                    orderDirection={ordering?.desc ? 'desc' : 'asc'}
                                    onClickOrdering={c => {
                                        categorizationReviewStore.reviewPageController.reviewPageFilterController.toggleOrderBy(c.columnFieldId, groupedRow)
                                    }}
            />
            <TableBody>
                {parts?.map(part =>
                    <React.Fragment key={part.data.id}>
                        {/*<CurrencyAbbreviationContext.Provider value={abbreviation}>*/}
                        <GroupedCategorizationReviewSubRow part={part} controller={controller}/>
                        {/*</CurrencyAbbreviationContext.Provider>*/}
                    </React.Fragment>
                )}
            </TableBody>
            {dataCount > rowsPerPage &&
                // It's very confusing which pagination you see at the bottom of the screen if there are only few lines
                // of data. So we hide this pagination and only show the one from the grouped rows, where possible
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            count={dataCount}
                            rowsPerPage={rowsPerPage} // Place this one in the ProfileStore (but have a default value of 100 as well) AND we can assume it does not change
                            page={groupedRow.page - 1}
                            // SelectProps={{
                            //     inputProps: {'aria-label': 'rows per page'},
                            //     native: true,
                            // }}
                            // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                            onPageChange={(e, page) => groupedRow.handlePageChange(page + 1)}
                            // onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={MithraTablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    </TableContainer>
})
