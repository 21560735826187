import React from "react";
import {Chip} from "@mui/material";
import {ExpandMore, ExpandLess} from "@mui/icons-material";

export const MultiCompanyName: React.FC<{ labels: string[] }> = ({labels}) => {
    const [expand, setExpand] = React.useState(false);
    let deleteIcon = <></>
    if (labels.length > 1) {
        if (expand)
            deleteIcon=<ExpandLess />
        else
            deleteIcon=<ExpandMore/>
    }
    return <>
        {labels.length >= 1 &&
                <Chip label={labels[0] + (labels.length > 1 && !expand ? ' (+' + (labels.length - 1).toString() + ')' : '')}
                      variant="outlined" onDelete={() => setExpand(!expand)} deleteIcon={deleteIcon}/>
        }
        {expand &&
                labels.slice(1).map((label, index) => {
                    return <Chip label={labels[index + 1]} variant="outlined" onClick={() => setExpand(false)}/>
                })
        }
    </>
}
