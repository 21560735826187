import './FakeLoader.scss';
import React, {useEffect} from "react";
import {Card, CardContent, Dialog, LinearProgress, Skeleton, Typography} from "@mui/material";
import {range} from "d3";
import {environment} from "../../../env";

const nSteps = 30

const randomness = 0.75;
const randValues = range(0, nSteps).map(i => 1 + randomness * Math.random());
const randSumValues = randValues.map((v, i) => randValues.slice(0, i).reduce((a, b) => a + b, 0));
const totalSum = randSumValues[randSumValues.length - 1];
const loadingValues = randSumValues.map(v => v / totalSum * 100);

type Props = {
    step1Name: string //
    step1Size: number // on a scale 1 to 30
    step2Name: string
    step2Size: number // on a scale 1 to 30
    step3Name: string
    totalTimeMs: number
    onCompleted?: () => void
}
export const FakeLoader: React.FC<Props> = ({
                                                step1Name,
                                                step1Size,
                                                step2Name,
                                                step2Size,
                                                step3Name,
                                                totalTimeMs,
                                                onCompleted
                                            }) => {
    const [loadingStep, setLoadingStep] = React.useState(0);

    useEffect(() => {
        if (loadingStep === 0) {
            setTimeout(() => {
                setLoadingStep(loadingStep + 1);
            }, 1000);
        } else if (loadingStep < nSteps - 1) {
            setTimeout(() => {
                setLoadingStep(loadingStep + 1);
            }, (totalTimeMs - 2000) / nSteps);
        } else {
            setTimeout(() => {
                if (environment.isTest) {
                    setLoadingStep(0)
                } else {
                    if (onCompleted) onCompleted()
                    setLoadingStep(0)
                }
            }, 1000);
        }
    }, [loadingStep]);

    const stepName = loadingStep <= step1Size ? step1Name : loadingStep <= step2Size ? step2Name : step3Name;
    const bufferValue = loadingValues[(loadingStep <= step1Size ? step1Size : loadingStep <= step2Size ? step2Size : nSteps)];

    const progress = loadingValues[loadingStep];
    return <div className="fake-loader">
        <Dialog open={true} className="fake-loader-modal" fullWidth maxWidth="sm">
            <Card
                className="fake-loader-card"
                onClick={e => {
                    if (e.altKey) {
                        if (onCompleted) onCompleted()
                    }
                }}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        {stepName}
                    </Typography>
                    {/*<Typography sx={{mb: 1.5}} color="text.secondary">*/}
                    {/*    /!*{progress.toFixed(0)}%*!/*/}
                    {/*    {'loadingStep=' + loadingStep}*/}
                    {/*    {'p=' + progress}*/}
                    {/*    {'v=' + randValues.length}*/}
                    {/*</Typography>*/}
                    <LinearProgress
                        value={progress}
                        variant="buffer"
                        valueBuffer={bufferValue}
                    />
                </CardContent>
            </Card>
        </Dialog>
    </div>
}

export const SkeletonLoader: React.FC = () => {
    return <div className="fake-loader">
        <Skeleton variant="text" className="alert-skeleton"/>
        <Skeleton variant="rounded" className="table-skeleton"/>
    </div>
}