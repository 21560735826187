import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable, runInAction} from "mobx";
import {ApprovalStore} from "../ApprovalStore";
import {
    CreateTaxonomyApprovalOperationSerializer,
    TaxonomyApprovalHistoryListSerializer,
    TaxonomyApprovalRequestStateSerializer
} from "../../services/classes/TaxonomyApproval";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {hierarchy, HierarchyNode} from "d3-hierarchy";
import {addNodeInHierarchy, moveNodeInHierarchy} from "../../utils/d3-utils";
import {Category} from "../TaxonomyEditorStore";
import {deepCopy} from "../../utils/js-utils";
import AuthStore from "../AuthStore";
import {ApprovalStatusEnum, TaxonomyApprovalRequest} from "../../services/classes/AiClasses";
import {BagStore} from "../BagStore";
import {routes} from "../../routing/routes";
import {NavigateFunction} from "react-router-dom";
import Operation = m_taxonomy.Operation;

type MoveChange = m_taxonomy.Change & { type: 'moved' };
export type ApprovalHierarchyNode = HierarchyNode<m_taxonomy.TaxApprovalTree<m_taxonomy.ApproverData>>

export class TaxonomyApprovalDelegate {
    data: TaxonomyApprovalRequestStateSerializer & { active: CreateTaxonomyApprovalOperationSerializer } | null = null;
    root: ApprovalHierarchyNode | null = null;
    next_node_id = 0;

    /**
     * Hack to force updates in recursive components
     */
    tickTree = 0;

    editNode: ApprovalHierarchyNode | null = null;
    moveNode: ApprovalHierarchyNode | null = null;
    deleteNode: ApprovalHierarchyNode | null = null;
    createNode: ApprovalHierarchyNode | null = null;

    readonly categoryFeedbackNotes = new Map<number, string>();

    updateIsBusy = false;
    errorMessage = '';

    busyUpdatingHistory = false;
    busyApplyingApproval = false;

    constructor(
        private approval: ApprovalStore,
        private api: MithraMaterializedApi,
        private authStore: AuthStore,
        private bagStore: BagStore,
    ) {
        makeAutoObservable(this)
    }

    get isBusy() {
        return this.data === null || this.updateIsBusy || this.busyUpdatingHistory || this.busyApplyingApproval;
    }

    get isEditDialogOpen() {
        return this.editNode !== null
            || this.moveNode !== null
            || this.deleteNode !== null
            || this.createNode !== null
    }

    setApprovalState(data: TaxonomyApprovalRequestStateSerializer) {
        this.categoryFeedbackNotes.clear()
        this.errorMessage = ''

        if (!data.active) {
            console.error('No active state in the taxonomy approval data')
            this.data = null;
            this.next_node_id = -1;
            this.root = null;
        } else {
            this.data = data as typeof this.data;
            this.next_node_id = data.active.next_node_id;
            const tree = data.active.state;
            const treeData = m_taxonomy.processApprovals(tree)
            this.root = hierarchy(treeData);

            // const nNodes = this.root?.descendants().filter(n => !n.data.removed).length || 0;
            // console.log('setApprovalState', nNodes)
        }
    }

    setFeedbackNotes(value: string) {
        const tax = this.approval.approval as TaxonomyApprovalRequest
        if (!tax) return;
        tax.feedback_notes = value;
    }

    private exportTree(node: ApprovalHierarchyNode): m_taxonomy.Tree<m_taxonomy.ApproverData> {
        const values = deepCopy(node.data.values);
        // Remove all fields from TaxApprovalData
        // It's important that these fields do not get send to the BE. As they should be calculated each time in the FE only
        for (let key of Object.keys(m_taxonomy.EMPTY_APPROVAL_DATA)) {
            delete values[key];
        }
        const note = this.categoryFeedbackNotes.get(node.data.id);
        if (note !== undefined) {
            values.approval_feedback_note = note;
        }

        return {
            id: node.data.id,
            label: node.data.label,
            values,
            sources: deepCopy(node.data.sources),
            removed: node.data.removed,
            children: (node.children || []).map(c => this.exportTree(c)),
        }
    }

    canRevert(node: ApprovalHierarchyNode, change: m_taxonomy.Change): boolean {
        if (!this.root) return false;
        const conflicts = this.getRevertConflicts(node, change)
        if (conflicts.length > 0) {
            // The target of the reversal is not available
            return false;
        }
        // Do additional checks for some cases
        switch (change.type) {
            case 'removed':
                // Ensure that everything is a proposed removal below it as well
                return TaxonomyApprovalDelegate.isHomogenous(node, change);
            case 'renamed':
                // Only conflicts can cause a problem
                return true;
            case 'moved':
                // The parent where we want to move back to must exist
                const parentLocation = TaxonomyApprovalDelegate.getReverseMoveTarget(node, change).slice(0, -1)
                const parents = TaxonomyApprovalDelegate.find(this.root, parentLocation)
                return parents.length === 1;
            case 'added':
                // Ensure that everything below it is a simple addition as well
                return TaxonomyApprovalDelegate.isHomogenous(node, change);
            // TODO
            case 'updated':
                return true;
        }
    }

    getRevertConflicts(node: ApprovalHierarchyNode, change: m_taxonomy.Change): ApprovalHierarchyNode[] {
        if (!this.root) return [];
        switch (change.type) {
            case 'removed':
                const egoPath = TaxonomyApprovalDelegate.getLocation(node)
                return TaxonomyApprovalDelegate.find(this.root, egoPath)
                    .filter(n => n.data.id !== node.data.id)
            case 'renamed':
                return this.getNameConflict(node, change.original_label)
            case 'moved':
                // Keep the current label
                const l = TaxonomyApprovalDelegate.getReverseMoveTarget(node, change)
                return TaxonomyApprovalDelegate.find(this.root, l)
            case 'added':
                return [];
            case 'updated':
                return [];
        }
    }

    getNameConflict(ego: ApprovalHierarchyNode, newName: string): ApprovalHierarchyNode[] {
        if (!this.root) return [];
        if (!ego.parent) throw new Error('Cannot rename root of tree')
        const targetPath = TaxonomyApprovalDelegate.getLocation(ego.parent)
        targetPath.push(newName)
        return TaxonomyApprovalDelegate.find(this.root, targetPath)
    }

    private static getRevertOperationName(change: m_taxonomy.Change): m_taxonomy.Operation {
        switch (change.type) {
            case 'removed':
                return m_taxonomy.Operation.Add;
            case 'renamed':
                return m_taxonomy.Operation.Update;
            case 'moved':
                return m_taxonomy.Operation.Move;
            case 'added':
                return m_taxonomy.Operation.Delete;
            case 'updated':
                return m_taxonomy.Operation.Update;
        }
    }

    doRevert(node: ApprovalHierarchyNode, change: m_taxonomy.Change): void {
        if (!this.data || !this.root) return;

        change.reverted = true;
        switch (change.type) {
            case 'removed':
                // Add everything below this node
                // We know that the subtree is homogeneous, so we can safely apply
                node.descendants().forEach(n => {
                    n.data.removed = false;
                    n.data.values.changes?.forEach(c => c.reverted = true)
                    m_taxonomy.addAuthor(n.data.values, this.authStore.getUserId());
                    n.data.values.approval_touched = true;
                })
                // Also ensure that all ancestors are existing as well
                node.ancestors().filter(n => n.data.removed).forEach(n => TaxonomyApprovalDelegate.ensureRemoved(n, false))
                break;
            case 'renamed':
                node.data.label = change.original_label;
                m_taxonomy.addAuthor(node.data.values, this.authStore.getUserId());
                node.data.values.approval_touched = true;
                break;
            case 'moved':
                if (!node.parent) throw new Error('Cannot move root of tree')
                const targetParentLocation = TaxonomyApprovalDelegate.getReverseMoveTarget(node, change).slice(0, -1)
                const targetParent = TaxonomyApprovalDelegate.find(this.root, targetParentLocation)[0]
                moveNodeInHierarchy(node, targetParent)
                m_taxonomy.addAuthor(node.data.values, this.authStore.getUserId());
                node.data.values.approval_touched = true;
                break;
            case 'added':
                // Remove everything below this node
                node.descendants().forEach(n => {
                    n.data.removed = true;
                    n.data.values.changes?.forEach(c => {
                        // We know that c.type === 'added' as it's a homogeneous subtree
                        if (c.type !== 'added') {
                            console.warn('Unexpected change type during revert of an addition', c)
                        }
                        c.reverted = true;
                    })
                    m_taxonomy.addAuthor(n.data.values, this.authStore.getUserId());
                    n.data.values.approval_touched = true;
                })
                // TODO
                break;
            case 'updated':
                const oldValue = change.original_value;
                node.data.values[change.field] = oldValue;
                m_taxonomy.addAuthor(node.data.values, this.authStore.getUserId());
                node.data.values.approval_touched = true;
                break;
        }

        const operation = TaxonomyApprovalDelegate.getRevertOperationName(change);

        this.saveApprovalTaxonomyState(true, operation);
    }

    // public static findSome(node: ApprovalHierarchyNode, path: string[]): ApprovalHierarchyNode[] | null {
    //     if (path.length === 0) {
    //         return [node];
    //     }
    //     const next = node.children?.find(n => n.data.label === path[0]) || null
    //     if (next) {
    //         return this.findSome(next, path.slice(1))
    //     }
    //     return null;
    // }

    public static getLocation(node: ApprovalHierarchyNode): string[] {
        return node.ancestors().map(n => n.data.label).reverse().slice(1)
    }

    public static find(node: ApprovalHierarchyNode, path: string[]): ApprovalHierarchyNode[] {
        if (path.length === 0) {
            return [node];
        }
        const hits: ApprovalHierarchyNode[] = node.children?.filter(n => n.data.label === path[0]) || []
        if (hits.length === 0) {
            return [];
        }
        const subHits: ApprovalHierarchyNode[] = hits.flatMap(h => this.find(h, path.slice(1)))
        const seen = new Set<number>();
        return subHits.filter(n => {
            if (seen.has(n.data.id)) {
                return false;
            } else {
                seen.add(n.data.id)
                return true;
            }
        });
    }

    /**
     * Move the node to the previous location, but do not change the label
     */
    private static getReverseMoveTarget(node: ApprovalHierarchyNode, change: MoveChange): string[] {
        const l = change.original_location.slice(0, -1)
        const label = node.data.label;
        l.push(label)
        return l;
    }

    /**
     * Check if all nodes below this node have the same changes and states
     * @param node
     * @param change
     * @private
     */
    private static isHomogenous(node: ApprovalHierarchyNode, change: m_taxonomy.Change): boolean {
        return node.descendants().every(n =>
            n.data.values.changes?.every(c =>
                c.type === change.type
                && c.reverted === change.reverted
            ) ?? false
        )
    }

    private static ensureRemoved(node: ApprovalHierarchyNode, removed: boolean) {
        if (removed) {
            if (node.data.removed === false) {
                // Put as removed
                node.data.removed = true;
            }
        } else {
            if (node.data.removed === true) {
                // Put as not removed
                node.data.removed = false;
            }
        }
    }

    openEditModal(editNode: ApprovalHierarchyNode) {
        this.editNode = editNode
    }

    closeEditModal() {
        this.editNode = null
    }

    openMoveModal(moveNode: ApprovalHierarchyNode) {
        this.moveNode = moveNode
    }

    closeMoveModal() {
        this.moveNode = null
    }

    openDeleteModal(deleteNode: ApprovalHierarchyNode) {
        this.deleteNode = deleteNode
    }

    closeDeleteModal() {
        this.deleteNode = null
    }

    openCreateModal(createNode: ApprovalHierarchyNode) {
        this.createNode = createNode
    }

    closeCreateModal() {
        this.createNode = null
    }

    closeDialogs() {
        this.editNode = null
        this.moveNode = null
        this.deleteNode = null
        this.createNode = null
    }

    onSaveEditNode(node: ApprovalHierarchyNode, name: string, description: string) {
        if (!this.root) return;
        // const n = this.root.descendants().find(n => n.data.id === node.data.id)
        // if (!n) return;
        const nameChanged = name !== node.data.label;
        const descriptionChanged = description !== node.data.values.description;

        if (nameChanged || descriptionChanged) {
            this.executeEditNode(node, name, description);
        }
        this.closeEditModal();
    }

    private executeEditNode(node: HierarchyNode<m_taxonomy.TaxApprovalTree<m_taxonomy.ApproverData>>, name: string, description: string) {
        // Move the node is not needed as we only change the label
        node.data.label = name;
        node.data.values.description = description;
        node.data.values.changes = [];
        node.data.values.approval_touched = true;
        m_taxonomy.addAuthor(node.data.values, this.authStore.getUserId());

        this.tickTree += 1;
        this.saveApprovalTaxonomyState(null, Operation.Update)
    }

    onSaveMoveNode(ego: ApprovalHierarchyNode, newLocation: Category): string {
        if (!this.root) throw new Error()
        const egoParent = ego.parent;
        if (!egoParent) throw new Error('Cannot move the root of the tree');

        if (!newLocation) return 'Please select a new parent';

        const parent = TaxonomyApprovalDelegate.find(this.root, newLocation.values);
        if (parent.length !== 1) return 'Parent not found';
        const newParent = parent[0]

        // Check if the new parent is the same as the current parent
        if (newParent.data.id === egoParent.data.id) {
            return 'Category is already at this location';
        }

        // Check if the new parent is a descendant of the node
        if (newParent.ancestors().find(a => a.data.id === ego.data.id)) {
            return 'Cannot move a category into itself';
        }

        // See if the target already exists
        const parentLocation = TaxonomyApprovalDelegate.getLocation(newParent)
        const newEgoLocation = parentLocation.concat(ego.data.label)
        const conflicts = TaxonomyApprovalDelegate.find(this.root, newEgoLocation)
        if (conflicts.length > 0) {
            return 'This category already exists in the selected location';
        }

        // Do the move
        this.executeMoveNode(ego, newParent);

        this.closeMoveModal();
        return ''
    }

    private executeMoveNode(ego: HierarchyNode<m_taxonomy.TaxApprovalTree<m_taxonomy.ApproverData>>, newParent: HierarchyNode<m_taxonomy.TaxApprovalTree<m_taxonomy.ApproverData>>) {
        moveNodeInHierarchy(ego, newParent)
        ego.data.values.changes = [];
        ego.data.values.approval_touched = true;
        m_taxonomy.addAuthor(ego.data.values, this.authStore.getUserId());

        this.tickTree += 1;
        this.saveApprovalTaxonomyState(null, Operation.Move)
    }

    onSaveDeleteNode(node: ApprovalHierarchyNode) {
        if (!this.root) return;

        node.descendants().forEach(n => {
            n.data.removed = true;
            n.data.values.changes = [];
            n.data.values.approval_touched = true;
            m_taxonomy.addAuthor(n.data.values, this.authStore.getUserId());
        })

        this.tickTree += 1;
        this.saveApprovalTaxonomyState(null, Operation.Delete)

        this.closeDeleteModal();
    }

    onSaveRestore(node: ApprovalHierarchyNode) {
        if (!this.root) return;

        node.descendants().forEach(n => {
            n.data.removed = false;
            n.data.values.changes = [];
            n.data.values.approval_touched = true;
            m_taxonomy.addAuthor(n.data.values, this.authStore.getUserId());
        })

        this.tickTree += 1;
        this.saveApprovalTaxonomyState(null, Operation.Add)
    }

    onSaveCreateNode(parent: ApprovalHierarchyNode, name: string, description: string): string {
        if (!this.root) throw new Error('No root node');

        if (name.length === 0) {
            return 'Category name cannot be empty';
        }

        const parentLocation = TaxonomyApprovalDelegate.getLocation(parent)
        const nodeLocation = parentLocation.concat(name)
        const conflicts = TaxonomyApprovalDelegate.find(this.root, nodeLocation)
        if (conflicts.length > 0) return 'This category already exists in the selected location';

        this.executeCreateNode(parent, name, description);
        this.closeCreateModal();
        return '';
    }

    private executeCreateNode(parent: ApprovalHierarchyNode, name: string, description: string) {

        const nextNodeId = this.next_node_id;
        this.next_node_id += 1;

        const newData: m_taxonomy.Tree = {
            id: nextNodeId,
            label: name,
            values: {
                p__id__count: 0,
                p__spend__sum: 0,
                s__id__nunique: 0,
                description,
                changes: [],
                approval_note: '',
                authors: [this.authStore.getUserId()],
                approval_touched: true,
            },
            sources: [],
            removed: false,
            children: [],
        }
        const newApprovalNodeData = m_taxonomy.processApprovals(newData)
        addNodeInHierarchy(parent, newApprovalNodeData, nextNodeId)

        // TODO: Trigger re-evaluation of the heights and approval calculations
        // node.data.values.changes = [];

        // m_taxonomy.addAuthor(newData.values, this.authStore.getUserId());

        this.tickTree += 1;
        this.saveApprovalTaxonomyState(null, Operation.Add)
    }


    get moveDestinations(): Category[] {
        const allNodes = this.root?.descendants() || []
        return allNodes
            .filter(n => !n.data.removed)
            .map<Category>((n, i) => {
                if (i === 0) {
                    return ({
                        node_id: n.data.id,
                        label: 'Taxonomy root',
                        values: ['Taxonomy root'],
                        level: 0,
                    })
                }
                const labels = TaxonomyApprovalDelegate.getLocation(n);
                return ({
                    node_id: n.data.id,
                    label: labels.join(' > '),
                    values: labels,
                    level: labels.length,
                });
            })
    }

    setFeedbackNote(nodeId: number, value: string) {
        this.categoryFeedbackNotes.set(nodeId, value)
    }

    ensureApprovalNotesSaved(): Promise<void> {
        if (this.categoryFeedbackNotes.size !== 0) {
            return this.saveApprovalTaxonomyState(null, m_taxonomy.Operation.Review);
        }
        return Promise.resolve();
    }

    saveApprovalTaxonomyState(isRevert: null | boolean, operation_type: m_taxonomy.Operation): Promise<void> {
        const approvalId = this.approval.approval?.id;
        if (!this.data || !approvalId || !this.root) return Promise.reject();

        const nextState: CreateTaxonomyApprovalOperationSerializer = {
            history_number: this.data.active.history_number + 1,
            history_name: operation_type,
            history_is_revert: isRevert,
            next_node_id: this.next_node_id,
            state: this.exportTree(this.root)
        }

        this.updateIsBusy = true;
        return this.api.updateTaxonomyApprovalState(approvalId, nextState)
            .then(resp => this.setApprovalState(resp.data))
            .catch(error => {
                console.error(error)
                this.setErrorMessage('Failed to save changes')
                return Promise.reject();
            })
            .finally(() => runInAction(() => {
                this.updateIsBusy = false;
            }))
    }

    private setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    /**
     * Get a list of operations that have happened before, including the state before the operation (to revert it)
     */
    get undoHistory(): { item: TaxonomyApprovalHistoryListSerializer, undoNumber: number }[] {
        if (!this.data) return []
        const currentOperationNumber = this.data.active.history_number;
        const firstAction = this.data.history[0]
        const undoHistory = this.data.history
            .filter((item, index) =>
                // The first operation can never be undone
                // All operations should have happened before, so including the current operation_number
                index >= 1 && item.history_number <= currentOperationNumber
            )
            .map((item, index, array) => {
                // If we want to undo an action, we should goto the state before that actions
                const before = index === 0 ? firstAction : array[index - 1]
                return {
                    item,
                    undoNumber: before.history_number,
                }
            })
        // Show the most recent operation as the first
        undoHistory.reverse()
        return undoHistory
    }

    get undoContainsOtherUser() {
        return this.undoHistory.some(({item}) => item.author && item.author.id !== this.authStore.userId)
    }

    get redoContainsOtherUser() {
        return this.redoHistory.some(item => item.author && item.author.id !== this.authStore.userId)
    }

    get redoHistory() {
        if (!this.data) return []
        const currentHistoryNumber = this.data.active?.history_number || 1;
        return this.data.history.filter(h => h.history_number > currentHistoryNumber)
    }

    /**
     * Shows if the user can undo
     * Also it shows if there exists a change to actually undo
     */
    get undoAllowed() {
        return !this.isBusy && this.undoHistory.length > 0;
    }

    /**
     * Shows if the user can undo
     * Also it shows if there exists a change to actually undo
     */
    get redoAllowed() {
        return !this.isBusy && this.redoHistory.length > 0;
    }

    gotoHistoryState(number: number) {
        const approvalId = this.approval.approval?.id;
        if (!this.data || !approvalId || !this.root) return Promise.reject();

        this.busyUpdatingHistory = true;
        this.api.gotoTaxonomyApprovalHistory(approvalId, number)
            .then(r => this.setApprovalState(r.data))
            .catch(error => {
                console.error(error)
                this.setErrorMessage('Failed to retrieve history')
                return Promise.reject();
            })
            .finally(() => runInAction(() => {
                this.busyUpdatingHistory = false
            }))
    }

    acceptTaxonomyApproval(navigate: NavigateFunction) {
        // Assumes there are only changes w.r.t taxonomy, not categorization

        const approvalId = this.approval.approval?.id;
        if (!this.data || !approvalId || !this.root) return Promise.reject();

        const bagId = this.bagStore.bag?.id;

        this.busyApplyingApproval = true;
        const notes = this.approval.approval?.feedback_notes || '';
        return this.api.applyTaxonomyOnlyApprovalRequest(approvalId, notes)
            .then(() => {
                // Reload the app
                this.bagStore.$getAllBags().then(() => {
                    const b = this.bagStore.allBags?.find(b => b.id === bagId);
                    if (b) this.bagStore.setBag(b)
                });

                // Reload the approvals
                this.approval.fetchAll();

                // TODO: This is repeated logic...
                this.api.getTaxonomyApprovalState(approvalId)

                this.api.getTaxonomyApprovalRequest(approvalId).then(r => runInAction(() => {
                    this.approval.isAcceptApprovalDialogOpen = false;
                    if (!ApprovalStatusEnum.userCanUpdate(r.data.current_status.status)) {
                        // If we cannot change the approval anymore, go back to the overview page
                        navigate(routes.approval);
                    }
                }))
                return;
            })
            .catch(error => {
                console.error(error)
                this.setErrorMessage('Failed to accept the approval')
                return Promise.reject();
            })
            .finally(() => runInAction(() => {
                this.busyApplyingApproval = false;
            }))
    }

    get readOnly(): boolean {
        return m_taxonomy.isReadOnly(this.approval.approval?.current_status.status)
    }
}