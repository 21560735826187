import {D3Selection} from "./global";
import * as d3 from "d3";
import {select} from "d3";
import {HierarchyNode} from "d3-hierarchy";
import {Writeable} from "./ts-utils";

export function appendLine<D>(s: D3Selection, x1: number, y1: number, x2: number, y2: number, className?: string) {
    const line = s.append('line')
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
    if (className) {
        line.classed(className, true);
    }
    return line;
}

export function wrapText(maxWidth, getText: (n: any) => string) {
    return function _wrapText(this: any) {
        // Taken from: https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
        const self = select(this)

        const data = self.data() as any[];
        if (data.length !== 1) {
            return
        }
        let text = getText(data[0])
        self.text(text)

        let textLength = self.node().getComputedTextLength()
        // console.log('textLength', textLength, '->', maxWidth)
        while (textLength > maxWidth && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + '...');
            textLength = self.node().getComputedTextLength();
        }
    }
}


export function wrapText2<T>(maxWidth, getTextParts: (n: T) => [string, string], filler) {
    return function _wrapText(this: any) {
        // Taken from: https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
        const self = select(this)

        const data = self.data() as any[];
        if (data.length !== 1) {
            return
        }
        let [t1, t2] = getTextParts(data[0])
        self.text(t1 + t2)

        let textLength = self.node().getComputedTextLength()
        // console.log('textLength', textLength, '->', maxWidth)
        while (textLength > maxWidth && t1.length > 0) {
            t1 = t1.slice(0, -1);
            self.text(t1 + filler + t2);
            textLength = self.node().getComputedTextLength();
        }
    }
}

export function addNodeInHierarchy<D extends {
    children: D[]
}>(parent: HierarchyNode<D>, newChildData: D, newId: number): boolean {
    if (newId === -1) throw new Error('newId cannot be -1')

    let structureChanged = false;
    if (!parent.children) {
        structureChanged = true;
        parent.children = [];
    }

    const newNode = d3.hierarchy(newChildData)

    // Just append
    const index = -1;

    const _newNode = newNode as Writeable<typeof newNode>
    _newNode.depth = parent.depth + 1
    _newNode.height = 0
    _newNode.parent = parent
    if (index === -1) {
        parent.children.push(newNode)
        parent.data.children.push(newChildData)
    } else {
        parent.children = parent.children.splice(index, 0, newNode)
        parent.data.children = parent.data.children.splice(index, 0, newChildData)
    }

    if (structureChanged) {
        // TODO: Trigger re-calculating the heights
    }
    return structureChanged;
}

export function moveNodeInHierarchy<D extends {
    children: D[]
}>(node: HierarchyNode<D>, newParent: HierarchyNode<D>): boolean {
    const parent = node.parent;
    if (!parent || !parent.children) {
        console.error('Cannot move a root node')
        return false;
    }
    let structureChange = false;

    // Remove this from its own parent
    const sourceI = parent.children.indexOf(node)
    console.assert(sourceI >= 0)
    parent.children.splice(sourceI, 1)
    parent.data.children.splice(sourceI, 1) // Also update the data.children

    // Add the source to the new parent
    if (!newParent.children) {
        // Will cause heights property to be invalid, please beware!
        newParent.children = []
        structureChange = true;
    }
    newParent.children.push(node)
    newParent.data.children.push(node.data) // Also update the data.children
    node.parent = newParent

    // Height is not updated, seems to cause no problems thus far...
    const _node = node as Writeable<typeof node>
    _node.depth = newParent.depth + 1

    return structureChange;
}
