import {BusinessCaseGeneratorPageStateController} from "./BusinessCaseGeneratorPageStateController";
import {BusinessCaseGeneratorPageFilterController} from "./BusinessCaseGeneratorPageFilterController";
import {BusinessCaseGeneratorPageStatisticsController} from "./BusinessCaseGeneratorPageStatisticsController";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import AuthStore from "../../../stores/AuthStore";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable} from "mobx";
import {BusinessCaseGeneratorPageDataController} from "./BusinessCaseGeneratorPageDataController";

export type Page = 'review-opportunities'
    | 'risk-assessment'
    | 'success-factors'


export class BusinessCaseGeneratorPageController {
    readonly pageStateController = new BusinessCaseGeneratorPageStateController(this, this.profile, this.auth);
    readonly pageDataController = new BusinessCaseGeneratorPageDataController(this, this.pageStateController, this.api, this.auth, this.profile);
    readonly pageFilterController = new BusinessCaseGeneratorPageFilterController(this, this.profile)
    readonly pageStatisticsController = new BusinessCaseGeneratorPageStatisticsController(this.profile, this.api);

    constructor(
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    navigateToPage(page: Page) {
        this.pageStateController.setPage(page);
    }
}
