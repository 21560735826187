import './CategorizationPage.scss'
import React, {useEffect, useState} from "react";
import {CategorizationWelcome} from "./components/welcome/CategorizationWelcome";
import {Alert, Button, CircularProgress, Dialog, Grid} from "@mui/material";
import {ChevronLeft, ChevronRight, CloudDownload, Done} from "@mui/icons-material";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {generateHomePath} from "../../routing/routing.utils";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CategorizationReviewPage} from "./CategorizationReviewPage";
import {ConfirmSendForApproval} from "./components/modals/ConfirmSendForApproval";
import {CAT_FILTER_MODAL_TITLE_ID, CategorizationFilterModal} from "./components/modals/CategorizationFilterModal";
import {CategorizationStatisticsDashboard} from "./components/statistics/CategorizationStatisticsDashboard";
import {AI_MODEL_FAKER, DemoAiBusy} from "./components/demo-ai-busy/DemoAiBusy";
import {AiBusyComponent} from "./components/ai-busy/AiBusyComponent";
import {RunAiModal} from "./components/modals/RunAiModal";
import {RevisionSelectorDropdown} from "./components/RevisionSelectorDropdown";
import {
    CategorizationReviewSearchIntent,
    CategorizationReviewSearchParam,
    CategorizationReviewState,
} from "./classes/CategorizationReviewRouteState";

export type CategorizationPageProps = {
    readOnly?: boolean
}
export const CategorizationPage: React.FC<CategorizationPageProps> = observer(({readOnly}) => {
    const {rootAppStore, authStore, bagStore, categorizationReviewStore, p} = useStores();

    if (readOnly && categorizationReviewStore.isEditAllowed) {
        categorizationReviewStore.setIsEditAllowed(false);
    } else if (!readOnly && !categorizationReviewStore.isEditAllowed) {
        categorizationReviewStore.setIsEditAllowed(true);
    }

    const approvalController = categorizationReviewStore.reviewPageController.reviewPageApprovalController;
    const pageStateController = categorizationReviewStore.reviewPageController.reviewPageStateController;
    const reviewDataController = categorizationReviewStore.reviewPageController.reviewPageDataController;
    const reviewFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const aiJobDelegate = categorizationReviewStore.aiJobDelegate;

    const bagId = bagStore.bagId;
    const location = useLocation();
    const navigate = useNavigate();
    const back = generateHomePath(location, {id: String(bagId)}, rootAppStore.app)
    const showApprovalButton = !p.p.hideApprovalButtons && !bagStore.isSample;

    const [searchParams, setSearchParams] = useSearchParams();
    const locationStateSearchIntent = (location.state as CategorizationReviewState)?.searchIntent;
    useEffect(() => {
        let intent: CategorizationReviewSearchIntent | null;
        intent = locationStateSearchIntent ?? null;
        if (!intent) {
            intent = CategorizationReviewSearchParam.toIntent(searchParams);
        }
        if (intent) {
            categorizationReviewStore.reviewPageController.initializeFromIntent(intent);
        }
    }, [categorizationReviewStore.reviewPageController, searchParams, setSearchParams, locationStateSearchIntent]);

    const [isFakedStartingAiRun, setIsFakedStartingAiRun] = useState(false);
    useEffect(() => {
        let intervalId: string | number | NodeJS.Timeout | undefined;
        if (isFakedStartingAiRun) {
            intervalId = setInterval(() => {
                setIsFakedStartingAiRun(false);
                categorizationReviewStore.reviewPageController.reviewPageStateController.setPage("demo-ai-run")
                AI_MODEL_FAKER.start()
                // navigate(generateHomePath(location, params, rootAppStore.app));
            }, 800);
        }
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFakedStartingAiRun]);

    useEffect(() => {
        categorizationReviewStore.statusDelegate.checkStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        <Dialog
            open={approvalController.isSendForApprovalDialogOpen}
            onClose={() => approvalController.setSendForApprovalDialog(false)}
            aria-labelledby={"review-modal-title"}
            maxWidth="lg"
        >
            {approvalController.isSendForApprovalDialogOpen && <ConfirmSendForApproval/>}
        </Dialog>
        <Dialog
            open={reviewFilterController.isAdvFilterDialogOpen}
            onClose={() => reviewFilterController.setAdvancedFilterDialogOpen(false)}
            aria-labelledby={CAT_FILTER_MODAL_TITLE_ID}
            maxWidth="lg"
        >
            {reviewFilterController.isAdvFilterDialogOpen && <CategorizationFilterModal/>}
        </Dialog>
        <Dialog
            open={categorizationReviewStore.aiJobDelegate.isRunAiModalOpen}
            onClose={() => categorizationReviewStore.aiJobDelegate.closeRunAiModal()}
            maxWidth="lg"
        >
            {categorizationReviewStore.aiJobDelegate.isRunAiModalOpen && <RunAiModal/>}
        </Dialog>

        {/*A header with back button*/}
        <div className="categorization-page">

            {approvalController.isSendForApprovalWaiting &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <Alert severity="info">
                            <span className="ml-2 mr-1">
                                <CircularProgress size={12}/>
                            </span>
                            Approval is being send in the background ...
                        </Alert>
                    </Grid>
                </Grid>
            }

            {pageStateController.page === 'review-dashboard' && categorizationReviewStore.reviewPageController.reviewPageStatisticsController._reviewLevelStatistics.error &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <Alert severity="warning">
                            The categorization review is not available
                            ({categorizationReviewStore.reviewPageController.reviewPageStatisticsController._reviewLevelStatistics.error})
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Grid container alignItems="center" className="header">
                <Grid item xs={2} className="text-center align-with-stepper">
                    {pageStateController.previousLabel &&
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => pageStateController.onClickPrevious(navigate, back)}
                            className="button-spacing"
                        >
                            {pageStateController.previousLabel}
                        </Button>
                    }
                </Grid>

                <Grid item xs={8}>
                    <RevisionSelectorDropdown/>
                </Grid>

                <Grid item xs={2} className="align-with-stepper">
                    {pageStateController.page === 'review' && <>
                        {showApprovalButton &&
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={reviewDataController.anyStoreRequestBusy || categorizationReviewStore.viewOnly}
                                style={{minWidth: '14em'}}
                                onClick={() => approvalController.setSendForApprovalDialog(true)}
                            >
                                Send for approval
                                {categorizationReviewStore.viewOnly && <><br/>(Not allowed)</>}
                            </Button>}
                        {p.p.demoFakeReinforcedCategorization &&
                            <Button
                                color="primary"
                                variant="outlined"
                                className="btn-with-circular-progress button-spacing"
                                startIcon={isFakedStartingAiRun ?
                                    <CircularProgress size={14}/> : (isFakedStartingAiRun ?
                                        <Done/> :
                                        <CloudDownload/>)}
                                disabled={isFakedStartingAiRun}
                                onClick={() => {
                                    setIsFakedStartingAiRun(true);
                                }}
                            >
                                Re-Run AI
                            </Button>
                        }
                        {p.isVertexCategorizationEnabled(authStore.isMithraStaffGroup) && <>
                            <Button
                                color="primary"
                                variant="outlined"
                                className="btn-with-circular-progress"
                                style={{marginTop: '2em', minWidth: '14em'}}
                                onClick={() => aiJobDelegate.openRunAiModal()}>
                                Run AI
                            </Button>
                        </>}
                    </>}
                    {pageStateController.nextLabel &&
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={reviewDataController.anyStoreRequestBusy}
                            className="button-spacing"
                            endIcon={<ChevronRight/>}
                            onClick={() => pageStateController.onClickNext()}
                        >
                            {pageStateController.nextLabel}
                        </Button>
                    }
                </Grid>
            </Grid>

            {pageStateController.page === 'welcome' &&
                <CategorizationWelcome/>
            }
            {pageStateController.page === 'demo-ai-run' &&
                <DemoAiBusy/>
            }
            {pageStateController.page === 'ai-busy' &&
                <AiBusyComponent/>
            }
            {pageStateController.page === 'review-dashboard' &&
                <CategorizationStatisticsDashboard/>
            }
            {(pageStateController.page === 'review') &&
                <CategorizationReviewPage/>
            }
        </div>

    </div>
});
