import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {useSitStores} from "../../../../stores";
import {SitUserSelect} from "../../../components/SitUserSelect";

export const InitiativePeopleForm: React.FC = observer(() => {
    const {initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <SitUserSelect
                label="Initiative Owner"
                placeholder="Select Ownder"
                disabled={initiativeInstanceStore.disabled}
                required={true}
                value={initiativeInstanceStore.instance.owner}
                onChange={(event, value) => {
                    if (value !== null)
                        initiativeInstanceStore.owner = value
                }}/>
        </Grid>

        <Grid item xs={6}>
            <SitUserSelect
                label="Responsible"
                placeholder="Creator by default"
                disabled={initiativeInstanceStore.disabled}
                required={true}
                value={initiativeInstanceStore.instance.responsible}
                onChange={(event, value) => {
                    if (value !== null)
                        initiativeInstanceStore.responsible = value
                }}/>
        </Grid>

        <Grid item xs={6}>
            <SitUserSelect
                label="Approver"
                placeholder="Select Approver"
                disabled={initiativeInstanceStore.disabled}
                required={true}
                value={initiativeInstanceStore.instance.approver}
                onChange={(event, value) => {
                    if (value !== null)
                        initiativeInstanceStore.approver = value
                }}/>
        </Grid>
    </Grid>
});