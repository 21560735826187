/* eslint-disable react-hooks/exhaustive-deps */
// const Main: React.FC<WithStyles<typeof styles>> = ({classes}) => {
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {DataFileRouteMatch} from "../../routing/routes";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/pages/NotFound";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {HeaderWrapper} from "./HeaderWrapper";
import {toJS} from "mobx";

export const DataIngestionWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {rootAppStore, dataIngestionStore} = useStores();
    const params = useParams<DataFileRouteMatch>();
    const navigate = useNavigate();
    const isNoData = state.dataType === 'no_data';

    console.log("DataIngestionWrapper", toJS(state))

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the job
    useEffect(() => {
        if (!isNoData)
            dataIngestionStore.initDataFile(navigate, params);
    }, [isNoData])

    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        <LoadingBarComponent/>
        {
            isNoData
                ? (children)
                : (
                    dataIngestionStore.dataFileNotFound
                        ? <NotFound msg={"Could not find ingestion data on the server anymore"}/>
                        : dataIngestionStore.dataFile
                            ? children
                            : <div className="loading-spinner">
                                <LoadingSpinnerPie/>
                            </div>
                )
        }
    </MainComponent>;
});
