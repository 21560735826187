import React from "react";
import {SimpleChartBarLine, SimpleChartPie, SimpleChartScatter} from "../ChatTypes";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";

type Props = {
    msg: SimpleChartPie | SimpleChartBarLine | SimpleChartScatter
}
export const SimpleChartChatMessage: React.FC<Props> = ({msg}) => {
    // Define the `initChart` function based on the chart type
    const initChart = (echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType => {
        let option: any;

        switch (msg.chart_type) {
            case 'pie':
                option = {
                    title: {
                        text: msg.title,
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    series: [
                        {
                            name: msg.title,
                            type: 'pie',
                            radius: '50%',
                            data: (msg as SimpleChartPie).data.map((item) => ({
                                value: item.value,
                                name: item.name
                            })),
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                        },
                    ],
                };
                break;
            case 'bar':
            case 'line':
                option = {
                    title: {
                        text: msg.title,
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        data: [msg.title],
                    },
                    xAxis: {
                        type: 'category',
                        data: (msg as SimpleChartBarLine).categoryLabels,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            data: (msg as SimpleChartBarLine).data,
                            type: msg.chart_type,
                            name: msg.title,
                        },
                    ],
                };
                break;
            case 'scatter':
                option = {
                    title: {
                        text: msg.title,
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {},
                    yAxis: {},
                    series: [
                        {
                            symbolSize: 20,
                            data: (msg as SimpleChartScatter).data,
                            type: 'scatter',
                            name: msg.title,
                        },
                    ],
                };
                break;
        }

        const chart = echart.init(root, 'mithra', {
            renderer: 'svg',
            width: 800,
            height: 500,
        });
        chart.setOption(option);
        return chart;
    };

    // Extract relevant data for `initChart`
    let chartData: any[] = [];
    switch (msg.chart_type) {
        case 'pie':
            chartData = (msg as SimpleChartPie).data;
            break;
        case 'bar':
        case 'line':
            chartData = [
                {
                    data: (msg as SimpleChartBarLine).data,
                    name: (msg as SimpleChartBarLine).title,
                },
            ];
            break;
        case 'scatter':
            chartData = (msg as SimpleChartScatter).data;
            break;
    }

    return <MithraEchart
        initChart={initChart}
        data={chartData}
    />
}
