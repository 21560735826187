import "./TaxonomyMapping.scss"
import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {TaxonomyMappingResultTable} from "./TaxonomyMappingResultTable";

export const TaxonomyCheck: React.FC = observer(() => {
    return <Grid className="taxonomy-check-wrapper" justifyContent="center">
        <Grid container justifyContent="space-evenly" className="taxonomy-check-table">
            <Grid item xs={12}>
                <TaxonomyMappingResultTable/>
            </Grid>
        </Grid>
    </Grid>
})
