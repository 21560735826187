import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Grid,
    LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import {useSitStores} from "../../../stores";
import {INITIATIVE_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {generatePath, useNavigate} from "react-router-dom";
import {sitRoutes} from "../../routing/sitRoutes";
import {InitiativeFilters} from "./InitiativeFilters";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import moment from "moment";
import {DoiStatus, traffic_light} from "../../ApiTypes";
import {Circle} from "@mui/icons-material";


export const InitiativeTable: React.FC = observer(() => {
    const {ccStore, initiativeListStore} = useSitStores();
    const navigate = useNavigate();

    useEffect(() => {
        initiativeListStore.reloadData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Grid container className="initiative-table-component">
        <Grid item xs={12}>
            <InitiativeFilters/>
        </Grid>
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design initiative-table' +
                (initiativeListStore.initiativeListRM.busy ? ' loading' : '')}>
                <Table>
                    {/*<MithraTableHeadColumns*/}
                    {/*    columns={INITIATIVE_COLUMNS} enableLoading*/}
                    {/*    isLoading={initiativeListStore.initiativeListRM.busy}/>*/}
                    <TableHead>
                        <TableRow>
                            {INITIATIVE_COLUMNS.map((c, i) => {
                                return <TableCell
                                    key={i}
                                    className={c.cls + ' sortable'}
                                    align={c.align}
                                    style={{width: c.width, minWidth: c.minWidth}}>
                                    <TableSortLabel
                                        onClick={() => initiativeListStore.setOrdering((initiativeListStore.ordering.startsWith('-') ? '' : '-') + c.columnFieldId)}
                                        active={c.columnFieldId === initiativeListStore.ordering || ('-' + c.columnFieldId) === initiativeListStore.ordering}
                                        direction={initiativeListStore.ordering.startsWith('-') ? 'desc' : 'asc'}>
                                        {c.txt}
                                    </TableSortLabel>
                                </TableCell>
                            })}
                        </TableRow>
                        <tr className={'progress-row' + (initiativeListStore.initiativeListRM.busy ? ' loading' : '')}>
                            <td colSpan={INITIATIVE_COLUMNS.length}>
                                {initiativeListStore.initiativeListRM.busy && <LinearProgress variant="indeterminate"/>}
                            </td>
                        </tr>
                    </TableHead>
                    {initiativeListStore.initiativeListRM.result &&
                        <TableBody>
                            {initiativeListStore.initiativeListRM.result?.results.map((row) => {
                                let i = 0;
                                return (
                                    <TableRow key={row.id}>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            <Link onClick={() => {
                                                navigate(generatePath(sitRoutes.sit_initiative, {initiativeId: row.id}))
                                            }}>
                                                <div style={{width: '100%'}}>
                                                    {row.title}
                                                </div>
                                            </Link>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            {row.owner ? row.owner.name : 'No owner'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>{
                                            row.financial.map(f => f.companies.map(c => ccStore.getCompanyTitle(c)).join(', ')).join(', ')
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>{
                                            ccStore.getCategoryL1(row.financial?.at(0)?.categories?.at(0) ?? 0)
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            {ccStore.getLeverGroupTitle(row.lever_id)}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            <Circle sx={{color: traffic_light(row)[0]}}/>&nbsp;
                                            <Typography variant="caption" component="small">
                                                {traffic_light(row)[1]}
                                            </Typography>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            {row.doi_status + ': ' + DoiStatus[row.doi_status - 1]}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            <CurrencyComponent
                                                v={(row.financial.reduce((sum, f) => sum += parseFloat(String(f.saving_amount)), 0) || 0)}
                                                nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            <CurrencyComponent
                                                v={(row.financial?.reduce((sum, f) => sum += parseFloat(String(f.avoidance_amount)), 0) || 0)}
                                                nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            {row.updated ? moment(row.updated).format('L') : ''}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[i++]}>
                                            {row.responsible.name}
                                        </MithraTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={INITIATIVE_COLUMNS.length}
                                count={initiativeListStore.initiativeListRM.result?.count ?? 0}
                                rowsPerPage={initiativeListStore.per_page}
                                page={initiativeListStore.page}
                                onPageChange={(_e, page: number) => initiativeListStore.setPage(page)}
                                onRowsPerPageChange={(event) => initiativeListStore.setPerPage(parseInt(event.target.value) || 0)}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});