import {makeAutoObservable} from "mobx";
import {GoldenRecordGroupedState} from "./GoldenRecordGroupedState";

export type GoldenRecordPartData = {
    p_id: string
    p_name: string
    p_description: string
    s_name: string
    s_city: string
    bu_name: string
    bu_city: string
    active_l: string[]
    p_spend: number
    p_date: string
}

export class GoldenRecordPartState {
    get id(): string {
        return this.data.p_id;
    }

    constructor(
        public data: GoldenRecordPartData,
        public parentRow: GoldenRecordGroupedState | undefined,
    ) {
        makeAutoObservable(this)
    }

    public static build(data: GoldenRecordPartData, parentRow?: GoldenRecordGroupedState | undefined) {
        console.debug('Building Part row state', {data, parentRow});
        return new GoldenRecordPartState(data, parentRow);
    }

    public remove() {
        if (this.parentRow) {
            this.parentRow.partStates = this.parentRow.partStates?.filter(p => p.id !== this.id);
        }
    }
}
