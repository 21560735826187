import {observer} from "mobx-react-lite";
import {FormControlLabel,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Switch
} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";
import React, {useState} from "react";
import {useStores} from "../../../stores";

type Props = {
    isParent: boolean
}
export const SynergyCommonSupplierToolbar: React.FC<Props> = observer(({isParent, children}) => {
    const {synergyStore} = useStores();
    const searchTitle = isParent ? 'Parent Supplier search' : 'Search matches';
    const [value, setValue] = useState('');
    const [showStandalone, setShowStandalone] = useState(false);

    return <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-start"
                 className="synergy-common-supplier-toolbar">
        <Grid item>
            <FormControl variant="standard" fullWidth={true} className="search-field">
                <InputLabel htmlFor="parent-supplier-search-field">
                    {searchTitle}
                </InputLabel>
                <Input
                    id="parent-supplier-search-field"
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={() => {
                                    synergyStore.commonParentSupplierFilters.setFilters([['search', '']]);
                                    setValue('');
                                }}>
                                <Clear/>
                            </IconButton>
                            <IconButton
                                aria-label="search"
                                onClick={() => synergyStore.commonParentSupplierRM.request(
                                    {filters: synergyStore.commonParentSupplierFilters.filters})}>
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={value}
                    onChange={e => {
                        synergyStore.commonParentSupplierFilters.setFilters([['search', e.target.value]]);
                        setValue(e.target.value)
                    }}
                />
            </FormControl>
        </Grid>
        <Grid item>
            <FormControlLabel
                label="Show standalone suppliers"
                control={<Switch
                    color="primary"
                    checked={showStandalone}
                    onClick={() => {
                        synergyStore.commonParentSupplierFilters.setFilters(
                            [['sp_n_suppliers__gt', showStandalone ? '1' : '0'], ['page', '1']]);
                        setShowStandalone(!showStandalone);
                    }}
                />}/>
        </Grid>
        {children}
    </Grid>
})
