import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Checkbox, Chip, CircularProgress, Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowDown, KeyboardArrowRight,} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {GroupedCategorizationReviewSubTable} from "./GroupedCategorizationReviewSubTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../../constants";
import {GroupedRowState} from "../../../classes/GroupedRowState";
import {getCatReviewColumns} from "../columns";
import {MithraTableCell} from "../../../../../components/table/MithraTableCell";
import {getCollapseAnimationTiming} from "../../../../../components/table/utils";
import {GoogleLink} from "../../../../../components/_tiny/GoogleLink";
import {CombinedReviewButtons} from "../../approval-acc-rej/CombinedReviewButtons";
import {fromArrayCategories} from "../../../../../services/ApiHelpers";
import {ReviewChoice} from "../../../../../services/classes/MaterializedClasses";
import {environment} from "../../../../../env";
import {Categories} from "../../../../../services/classes/AiClasses";
import {PartReCategorizationComponent} from "../../../../../components/categorization/PartReCategorizationComponent";
import {getCategorizationStateClassName} from "../../../classes/CategorizationUtils";
import {MultiCompanyName} from "../../../../../components/company-name/MultiCompanyName";
import {PartCategorizationComponent} from "../../../../../components/categorization/PartCategorizationComponent";

type Props = {
    groupedRow: GroupedRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to react.context
};

export const GroupedCategorizationReviewMainRow: React.FC<Props> = observer(({groupedRow, controller}) => {
    const {p, categorizationReviewStore, bagStore} = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const selectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const filterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const open = groupedRow.open;
    const viewOnly = categorizationReviewStore.viewOnly;
    const COLUMNS = getCatReviewColumns(p, viewOnly);
    const hasPartData = groupedRow.partStates !== undefined && groupedRow.partStates.length > 0;

    // useEffect(() => {
    //     console.log('groupedRow id changed', page);
    //     // setPage(1);
    // }, [groupedRow.id])

    // noinspection PointlessBooleanExpressionJS
    const canEdit: boolean =
        // The view cannot be view only
        !viewOnly
        // We need to be in a state that allows editing
        && groupedRow.combinedStateVisual && groupedRow.combinedStateVisual.editAllowed
        // We should have a taxonomy loaded
        && (categorizationReviewStore.categories.taxonomy_categories !== null || categorizationReviewStore.categories.taxonomy_categories !== undefined)
        // It should not be inconsistent
        && !groupedRow.combinedStateLoading;

    let onAccept: undefined | (() => void) = undefined;
    let newCatsFunc: undefined | ((newCats: Categories) => void) = undefined;
    if (canEdit) {

        // toggleReviewChoice = (groupedRow: GroupedRowState) => {
        //     if (groupedRow.) {
        //         // There is no common category
        //         return;
        //     }
        // }


        onAccept = () => reviewPageController.onClickAcceptRejectSupplierParts(groupedRow, ReviewChoice.ACCEPT)
    }
    newCatsFunc = (newCats: Categories) => reviewPageController.onClickReCategorizeSupplierParts(groupedRow, newCats)


    let recatControlsComponent;
    if (groupedRow.combinedStateVisual) {
        let combinedCategory = groupedRow.combinedStateVisual.combinedCategory;
        if (combinedCategory === false || combinedCategory === 'uncategorized') {
            // There is no common category
            recatControlsComponent = <>
                <Chip
                    className={'grouped-categorization-review-main-row__suggested-categories filled'}
                    label={combinedCategory === false ? 'Mixed categories' : 'Uncategorized'}
                    disabled={!canEdit}
                    icon={<KeyboardArrowDown/>}
                    onClick={(event) => {
                        if (!newCatsFunc) return;
                        if (!controller) {
                            console.error('No controller set');
                            return;
                        }
                        // When the category component is opened, reset the selection to avoid confusion
                        reviewPageSelectionController.onDeselectAll();

                        const anchor: HTMLElement = event.currentTarget;
                        controller.setOnSelect(newCatsFunc)
                        controller.open(anchor as HTMLButtonElement)
                    }}
                />

                {/*<PartCategorizationComponent*/}
                {/*    cats={false}*/}
                {/*/>*/}
            </>
        } else {
            // There is a single common category
            const effectiveLabels = fromArrayCategories(combinedCategory, bagStore.taxonomy_size);
            if (canEdit) {
                recatControlsComponent = <PartReCategorizationComponent
                    cats={effectiveLabels}
                    handleOpenSearch={event => {
                        if (!newCatsFunc) return;
                        if (!controller) {
                            console.error('No controller set');
                            return;
                        }

                        // When the category component is opened, reset the selection to avoid confusion
                        reviewPageSelectionController.onDeselectAll();

                        const anchor = event.currentTarget;
                        controller.setOnSelect(newCatsFunc)
                        controller.open(anchor)
                        //Make the controller make that this has been recategorized
                    }}
                    selectedL1Category={filterController.selectedL1Category}
                />
            } else {
                recatControlsComponent = <PartCategorizationComponent cats={effectiveLabels} isEditAllowed={false}/>
            }
        }
    }
    const className = `supplier-row${groupedRow.hasSelection ? ' is-selected' : ''}${groupedRow.combinedStateLoading ? ' loading' : ''}`;
    let i = 0;
    return <>
        <TableRow className={className}>
            {!viewOnly && <MithraTableCell c={COLUMNS[i++]}>
                <Checkbox
                    checked={groupedRow.hasSelection}
                    indeterminate={groupedRow.selectionState === 'some'}
                    onClick={() => {
                        if (!groupedRow.hasPartsOutsideView) {
                            selectionController.onSetSelectionOfSupplierGroup(groupedRow, !groupedRow.isAllSelected);
                        } else {
                            switch (groupedRow.selectionState) {
                                case 'all':
                                    selectionController.onSetSelectionOfSupplierGroup(groupedRow, false);
                                    break;
                                case 'none':
                                case 'some':
                                    selectionController.onSetSelectionOfSupplierGroup(groupedRow, true);
                                    break;
                            }
                        }
                    }}
                />
            </MithraTableCell>}
            <MithraTableCell c={COLUMNS[i++]} onClick={() => groupedRow.setOpen(!groupedRow.open)}>
                {
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>
                }
                {groupedRow.partCount}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="supplier" objs={[
                    groupedRow.data,
                    groupedRow.partStates ? groupedRow.partStates[0]?.data : undefined,
                ]}>
                    {groupedRow.data.s_name}
                </GoogleLink>

                {environment.isTestReviewPage && <>
                    <br/>
                    {/*combined_state_v2={JSON.stringify(groupedRow.combinedStateVisual)}<br/>*/}
                    all_is_reviewed=<b>{JSON.stringify(groupedRow.data.all_is_reviewed)}</b><br/>
                    {/*data={JSON.stringify(groupedRow.data)}<br/>*/}
                </>}

            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <CurrencyComponent v={groupedRow.spend} nDigits={3}/>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {groupedRow.data.p_l1_agg?.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            {!p.p.hideBuInCategorizationTable &&
                <MithraTableCell c={COLUMNS[i++]}>
                    <MultiCompanyName labels={groupedRow.data.bu_name_agg?.map(l => l === null ? 'None' : l) ?? []}/>
                </MithraTableCell>
            }
            <MithraTableCell c={COLUMNS[i++]}>
                {recatControlsComponent}

                {environment.isTestReviewPage && <>
                    <br/>
                    supplierId=<b>{JSON.stringify(groupedRow.supplierId)}</b> |
                    mat_s_group_ids=<b>{JSON.stringify(groupedRow.data.mat_s_group_ids)}</b> |
                    exists_invalid_suggestion=<b>{JSON.stringify(groupedRow.data.exists_invalid_suggestion)}</b> |
                    all_invalid_suggestion=<b>{JSON.stringify(groupedRow.data.all_invalid_suggestion)}</b> |
                    all_is_reviewed=<b>{JSON.stringify(groupedRow.data.all_is_reviewed)}</b> |
                    exists_open_suggestion=<b>{JSON.stringify(groupedRow.data.exists_open_suggestion)}</b> |
                    loading=<b>{'' + groupedRow.combinedStateLoading}</b>
                </>}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <Chip label={(groupedRow.combinedStateVisual.approvalState)}
                      className={'chip ' + getCategorizationStateClassName(groupedRow.combinedStateVisual.approvalState)}/>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {
                    groupedRow.combinedStateLoading
                        ? <CircularProgress size={24} className="table-height"/>
                        : <CombinedReviewButtons onAccept={onAccept} state={groupedRow.combinedStateVisual}
                                                 canEdit={canEdit}/>
                }
            </MithraTableCell>
        </TableRow>
        <TableRow>
            {/*<p>*/}
            {/*    dataPage={'' + page}<br/>*/}
            {/*    dataCount={'' + groupedRow.partCount}<br/>*/}
            {/*</p>*/}
            <TableCell
                className={"cell-sub-table" + (hasPartData ? ' has-data' : '')}
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {groupedRow.partStates !== undefined && groupedRow.partCount > 0 &&
                    <Collapse in={open} timeout={getCollapseAnimationTiming(groupedRow.partStates)} unmountOnExit>
                        <GroupedCategorizationReviewSubTable groupedRow={groupedRow} controller={controller}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
