import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const SynergyV1Menu: React.FC = () => {
    const {p} = useStores();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const hasId2 = params['id2'] !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: generatePath(
                hasId2 && hasId1
                    ? (
                        routes.job_synergy_v1_2
                    )
                    : hasId1
                        ? routes.job_synergy_v1_1
                        : routes.job_synergy_v1_0,
                params
            ),
            active: (
                location.pathname === routes.job_synergy_v1_0
                || location.pathname === routes.job_synergy_v1_1
                || location.pathname === routes.job_synergy_v1_2
            ),
            className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.job_synergy_v1_opportunities, params)
                : routes.job_synergy_v1_0,
            active: !isHome && location.pathname === routes.job_synergy_v1_opportunities,
            isTrial: p.p.tease_main_menu,
            className: 'menu-item-2nd-last',
        },
    ]
    return <MenuItems className="synergy-v1-menu" items={menu}/>
}
