import './TaxonomyIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, ButtonGroup, Chip, Grid, IconButton, Typography} from "@mui/material";
import {routes} from '../../routing/routes';
import {generatePath, useNavigate} from "react-router-dom";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {ChevronRight, Refresh} from '@mui/icons-material';
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import moment from "moment";


type Props = {}
export const TaxonomyOverviewPage: React.FC<Props> = observer(() => {
    const {taxonomyIngestionStore, p} = useStores();
    const loading = taxonomyIngestionStore.taxonomyList === undefined;
    const noData = taxonomyIngestionStore.taxonomyList?.length === 0;
    const manyData = taxonomyIngestionStore.taxonomyList && taxonomyIngestionStore.taxonomyList.length > 9;
    const error = taxonomyIngestionStore.taxonomyListManager.error;

    const navigate = useNavigate();
    useEffect(() => {
        taxonomyIngestionStore.taxonomyListManager.request()
    }, [taxonomyIngestionStore, p])

    const uploadButton = <Button
        onClick={() => navigate(generatePath(routes.taxonomy_upload))}
        color="primary"
        variant="outlined"
        endIcon={<ChevronRight/>}
    >
        Upload new taxonomy
    </Button>;

    const actions = <Grid container className="dataset-overview-actions" justifyContent="center">
        <Grid item>
            {uploadButton}
        </Grid>
    </Grid>

    const table = <>
        <Grid container justifyContent="center" className="dataset-overview-table-titles" sx={{mb: 2}}>
            <Grid item xs={6}>
                <Typography variant="h6">Taxonomy name</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6">Status</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6">Upload</Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>

        <Grid container justifyContent="center" className="dataset-overview-table-content">
            {taxonomyIngestionStore.taxonomyList?.map((taxonomyFile) => (
                <Grid key={'dataset' + taxonomyFile.id}
                container justifyContent="center" alignItems="center" sx={{mb: 2}}>
                    <Grid item xs={6}>
                        <Typography variant="body1">{taxonomyFile.name}</Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent="center">
                        <Typography variant="body1">
                            {taxonomyFile.completed ?
                                <Chip label="Uploaded" color="success"
                                      className="dataset-uploaded"/> :
                                <Chip label="Pending" color="primary"
                                      className="dataset-pending"/>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1">{moment(taxonomyFile.date).format('YYYY-MM-DD')} </Typography>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'right'}}>
                        <ButtonGroup variant="outlined" color="primary">
                            {/*{!taxonomyFile.completed &&*/}
                            {/*    <Button color="error" onClick={() => setDeleteDialogSubject(taxonomyFile)}>*/}
                            {/*        <Delete/>*/}
                            {/*    </Button>*/}
                            {/*}*/}
                            <Button
                                onClick={() => navigate(generatePath(routes.taxonomy_upload_mapping, {dataFileId: String(taxonomyFile.id)}))}>
                                <ChevronRight/>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </>

    return (
        <KoiFacade
            title='Taxonomy Ingestion'
            onBack={() => navigate(routes.home)}
            className={`taxonomy-ingestion data-overview-page${noData ? ' no-data' : ''}`}
            next={manyData
                ? {type: 'component', node: uploadButton}
                : undefined
            }>
            {error
                ? <Grid container justifyContent="center" className="error-alert-container">
                    <Grid item>
                        <Alert
                            severity="error"
                            action={<IconButton
                                size="small"
                                onClick={() => taxonomyIngestionStore.taxonomyListManager.request()}
                                disabled={taxonomyIngestionStore.taxonomyListManager.busy}>
                                <Refresh/>
                            </IconButton>
                            }>
                            {taxonomyIngestionStore.taxonomyListManager.error}
                        </Alert>
                    </Grid>
                </Grid>
                : loading
                    ? <LoadingSpinnerPie/>
                    : noData
                        ? actions
                        : <>

                            {table}

                            {actions}

                        </>
            }
        </KoiFacade>
    )
});
