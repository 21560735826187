import './ParentSuppliersSubPage.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {ParentSupplierReviewMainTable} from "./ParentSupplierReviewMainTable";
import {ParentSupplierToolbar} from "./toolbar/ParentSupplierToolbar";

export const ParentSuppliersStep: React.FC = observer(() => {
    return <Grid component="section"
                 className="page-item step parent-suppliers-step"
                 container justifyContent="center">
        <Grid item xs={12}>
            <ParentSupplierToolbar isParent={true} />
            <ParentSupplierReviewMainTable/>
        </Grid>
    </Grid>
});
