export const EchartSitDefaultLegend = {
    left: '10%',
    padding: [
        5,  // up
        10, // right
        5,  // down
        10, // left
    ],
    textStyle: {
        fontFamily: 'roboto',
        overFlow: 'break',
    }
}

export const EchartSitDefaultColor = [
    "#091344",
    "#ff3435",
    "#430944",
    "#0262f2",
    "#08a57a",
    "#a4d8c2",
    "#f3d999",
    "#d3758f",
    "#dcc392",
    "#2e4783",
    "#82b6e9",
    "#ff6347",
    "#a092f1",
    "#0a915d",
    "#eaf889",
    "#6699FF",
    "#ff6666",
    "#3cb371",
    "#d5b158",
    "#38b6b6"
]