import React from "react";
import {observer} from "mobx-react-lite";
import {Alert, CircularProgress, Grid} from "@mui/material";
import {useStores} from "../../../stores";
import {environment} from "../../../env";
import {SupplierNormalizationState} from "./SupplierNormalization.classes";

export const SupplierNormalizationModelRunningStep: React.FC = observer(() => {
    const {p, supplierNormalizationStore} = useStores();
    return <section className="page-item step model-running-step">
        <Grid container justifyContent="center">
            <Grid item>
                <Alert
                    severity="info"
                    icon={false}
                    // icon={<HourglassEmpty fontSize="inherit" />}
                    onClick={() => environment.isTest && supplierNormalizationStore.goToState(SupplierNormalizationState.REVIEW)}
                >
                    <span>
                        <CircularProgress size={15}/>
                    </span>
                    {(
                        supplierNormalizationStore.jobDelegate.jobRunning
                        || p.p.skipAiSupplierNormalizationModel
                    ) ? 'Running model, please wait' : ''}
                </Alert>
            </Grid>
        </Grid>
    </section>
})
