import React from "react";
import {observer} from "mobx-react-lite";
import {Alert, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DoiStatus, Initiative, Priority, traffic_light} from "../../../ApiTypes";
import {useSitStores} from "../../../../stores";
import {Circle} from "@mui/icons-material";
import dayjs from "dayjs";

export const InitiativePlaningFrom: React.FC = observer(() => {
    const {initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <InputLabel id="doi-status-label">Stage</InputLabel>
                <Select
                    disabled={initiativeInstanceStore.disabled}
                    labelId="doi-status-label"
                    id="doi-status"
                    value={initiativeInstanceStore.doiStatus}
                    label="Stage"
                    onChange={e => initiativeInstanceStore.doiStatus = e.target.value}>
                    {DoiStatus.map((value, index) => {
                        return <MenuItem
                            key={index}
                            value={value}>
                            {index + 1}: {value}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
            {initiativeInstanceStore.doiStatus === DoiStatus[4] &&
                <Alert severity="info" color="success">
                    Setting stage to {DoiStatus[4]} requires approval.
                </Alert>}
        </Grid>

        <Grid item xs={6} className="mt-4">
            <Typography variant="body1" component="label">Traffic light&emsp;</Typography>
            <Circle sx={{color: traffic_light(initiativeInstanceStore.instance as Initiative)[0]}}/>&nbsp;
            {traffic_light(initiativeInstanceStore.instance as Initiative)[1]}
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disabled={initiativeInstanceStore.disabled}
                        label="Start Date"
                        value={initiativeInstanceStore.startDate}
                        onChange={(value) => {
                            if (value !== null)
                                initiativeInstanceStore.startDate = value;
                        }}/>
                </LocalizationProvider>
            </FormControl>
        </Grid>

        {initiativeInstanceStore.doi_c.filter(
            (sc) => [2, 3, 4].indexOf(sc.instance.doi_stage ?? 0) > -1
        ).map((sc, index) =>
            <Grid item xs={6} key={index}>
                <FormControl fullWidth margin="dense">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={initiativeInstanceStore.disabled}
                            label={"\"" + DoiStatus[sc.instance.doi_stage - 1] + "\" Project Date"}
                            value={sc.dueDate}
                            onChange={(value) => {
                                if (value !== null)
                                    sc.dueDate = value;
                            }}/>
                    </LocalizationProvider>
                </FormControl>
            </Grid>
        )}

        <Grid item xs={6}>
            <Typography variant="body1" component="label">
                Elapsed time&emsp;
                {dayjs().diff(initiativeInstanceStore.startDate, 'month')} month(s)
            </Typography>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
                <InputLabel id="priority-label">Priority</InputLabel>
                <Select
                    disabled={initiativeInstanceStore.disabled}
                    labelId="priority-label"
                    id="priority"
                    value={initiativeInstanceStore.priority}
                    label="Priority"
                    onChange={(event) => {
                        initiativeInstanceStore.priority = event.target.value;
                    }}>
                    {Priority.map((value, index) => {
                        return <MenuItem
                            key={index}
                            value={value}>
                            {index + 1}: {value}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
});