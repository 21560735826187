import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores} from "../../../stores";
import {generatePath, useNavigate} from "react-router-dom";
import {
    Chip,
    Grid,
    Link,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {INITIATIVE_APPROVAL_COLUMNS} from "./columns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {sitRoutes} from "../../routing/sitRoutes";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import moment from "moment/moment";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {Initiative} from "../../ApiTypes";

export const InitiativeApprovalDashboard: React.FC = observer(() => {
    const {ccStore, initiativeListStore} = useSitStores();
    const navigate = useNavigate();

    useEffect(() => {
        initiativeListStore.reloadApprovalData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Grid container className="initiative-approval-table-component">
        {/*<Grid item xs={12}>*/}
        {/*    <InitiativeFilters/>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design initiative-approval-table' +
                (initiativeListStore.initiativeApprovalListRM.busy ? ' loading' : '')}>
                <Table>
                    <MithraTableHeadColumns columns={INITIATIVE_APPROVAL_COLUMNS} enableLoading
                                            isLoading={initiativeListStore.initiativeApprovalListRM.busy}/>
                    {initiativeListStore.initiativeApprovalListRM.result &&
                        <TableBody>
                            {initiativeListStore.initiativeApprovalListRM.result?.results.map((row: Initiative) => {
                                let i = 0;
                                return (
                                    <TableRow key={row.id}>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            <Link onClick={() => {
                                                navigate(generatePath(sitRoutes.sit_initiative, {initiativeId: row.id, approval: 'approval'}))
                                            }}>
                                                <div style={{width: '100%'}}>
                                                    {row.title}
                                                </div>
                                            </Link>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {row.owner ? row.owner.name : 'No owner'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>{
                                            row.financial.map(f => f.companies.map(c => ccStore.getCompanyTitle(c)).join(', ')).join(', ')
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>{
                                            ccStore.getCategoryL1(row.financial?.at(0)?.categories?.at(0) ?? 0)
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {ccStore.getLeverGroupTitle(row.lever_id)}
                                        </MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_APPROVAL_COLUMNS[4]}>{row.doi_status}</MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            <CurrencyComponent
                                                v={(row.financial.reduce((sum, f) => sum += parseFloat(String(f.saving_amount)), 0) || 0)}
                                                nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            <CurrencyComponent
                                                v={(row.financial.reduce((sum, f) => sum += parseFloat(String(f.avoidance_amount)), 0) || 0)}
                                                nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {row.approver ? row.approver.name : 'No approver'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {row.approved ? <Chip label="Approved" color="primary" style={{
                                                backgroundColor: '#08A57A',
                                                width: '100%'
                                            }}/> : <Chip label="Pending" color="secondary"
                                                         style={{backgroundColor: '#FAA915', width: '100%'}}/>}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {row.updated ? moment(row.updated).format('L') : ''}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[i++]}>
                                            {row.responsible.name}
                                        </MithraTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={INITIATIVE_APPROVAL_COLUMNS.length}
                                count={initiativeListStore.initiativeApprovalListRM.result?.count ?? 0}
                                rowsPerPage={initiativeListStore.approval_per_page}
                                page={initiativeListStore.approval_page}
                                onPageChange={(_e, page: number) => initiativeListStore.setApprovalPage(page)}
                                onRowsPerPageChange={(event) => initiativeListStore.setApprovalPerPage(parseInt(event.target.value) || 0)}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});