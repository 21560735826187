import {ColSpec} from "../../../components/table/MithraTableHeadColumns";
import {stores} from "../../../stores";

export const GOLDEN_RECORD_TABLE_COLUMNS: ColSpec[] = [
    {cls: 'col-dropdown', txt: '', align: 'center', width: 70},
    {cls: 'col-gr_name', txt: 'Golden Record'},
    {cls: 'col-gr_l1s', txt: 'L1 category', width: 300},
    {cls: 'col-gr_spend', txt: 'Total spend', width: 120},
    {cls: 'col-gr_s_count', txt: `# of unique ${stores.p.supplierDataName}s`, width: 140},
    {cls: 'col-controls', txt: '', width: 70, align: 'right'},
];
export const GOLDEN_RECORD_SUB_TABLE_COLUMNS: ColSpec[] = [
    {cls: 'col-p_name', txt: 'SKU number'}, // stores.p.partDataName
    {cls: 'col-p_description', txt: stores.p.p.p_description_col_name || 'Description'},
    {cls: 'col-s_name', txt: stores.p.supplierDataName},
    {cls: 'col-s_city', txt: `${stores.p.supplierDataName} location`},
    {cls: 'col-bu_name', txt: stores.p.buDataName},
    {cls: 'col-bu_city', txt: `${stores.p.buDataName} location`}, // Missing in DB
    {cls: 'col-active_l', txt: 'Category', width: 300},
    {cls: 'col-p_spend', txt: 'Amount', width: 120},
    {cls: 'col-p_date', txt: 'Date', width: 140},
    {cls: 'col-controls', txt: '', width: 70, align: 'right'},
];