import {Autocomplete, Grid, TextField} from "@mui/material";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {CategoryFilter} from "../../services/ApiTypes";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";

type Props = {
    showL4?: boolean
}
export const AdvancedDashboardCategoryFilters: React.FC<Props> = observer((({showL4}) => {
    const {bagStore} = useStores();
    return <Grid container className="filter-container category-filter-container"
                 style={{minHeight: 'unset', marginBottom: '3em'}}>
        <Grid item>
            <Autocomplete
                options={bagStore.advanced.spendPerActiveL1DataStringArr}
                value={bagStore.advanced.filters.category?.[0] || ''}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : ' Select L1'}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Grid container>
                            <Grid item xs={6}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={6}>
                                <CurrencyComponent v={bagStore.advanced.getActiveL1Spend(option)} nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                )}
                onChange={(_, value) => {
                    if (value) {
                        const categoryL1 = [value] as CategoryFilter;
                        bagStore.advanced.categoryFilterChanged(categoryL1);
                    } else {
                        bagStore.advanced.categoryFilterChanged(null);
                    }
                }}
                style={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label=" Filter L1"/>
                )}
            />
        </Grid>

        <Grid item>
            <Autocomplete
                options={bagStore.advanced.spendPerActiveL2DataStringArr}
                disabled={bagStore.advanced.filters.category?.[0] === undefined}
                value={bagStore.advanced.filters.category?.[1] || ''}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : ' Select L2'}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Grid container>
                            <Grid item xs={6}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={6}>
                                <CurrencyComponent v={bagStore.advanced.getActiveL2Spend(option)} nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                )}
                onChange={(_, value) => {
                    if (value) {
                        const categoryL2 = [bagStore.advanced.filters.category?.[0], value] as CategoryFilter;
                        bagStore.advanced.categoryFilterChanged(categoryL2);
                    } else {
                        const categoryL1 = [bagStore.advanced.filters.category?.[0]] as CategoryFilter;
                        bagStore.advanced.categoryFilterChanged(categoryL1);
                    }

                }}
                style={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label=" Filter L2"/>
                )}
            />
        </Grid>

        <Grid item>
            <Autocomplete
                options={bagStore.advanced.spendPerActiveL3DataStringArr}
                disabled={bagStore.advanced.filters.category?.[1] === undefined}
                value={bagStore.advanced.filters.category?.[2] || ''}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : ' Select L3'}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Grid container>
                            <Grid item xs={6}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={6}>
                                <CurrencyComponent v={bagStore.advanced.getActiveL3Spend(option)} nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                )}
                onChange={(_, value) => {
                    if (value) {
                        const categoryL3 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], value] as CategoryFilter;
                        bagStore.advanced.categoryFilterChanged(categoryL3);
                    } else {
                        const categoryL2 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1]] as CategoryFilter;
                        bagStore.advanced.categoryFilterChanged(categoryL2);
                    }
                }}
                style={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label=" Filter L3"/>
                )}
            />
        </Grid>

        {showL4 &&
            <Grid item>
                <Autocomplete
                    options={bagStore.advanced.spendPerActiveL4DataStringArr}
                    disabled={bagStore.advanced.filters.category?.[2] === undefined}
                    value={bagStore.advanced.filters.category?.[3] || ''}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option ? option : ' Select L4'}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <Grid container>
                                <Grid item xs={6}>
                                    {option ? option : '-'}
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrencyComponent v={bagStore.advanced.getActiveL4Spend(option)} nDigits={3}/>
                                </Grid>
                            </Grid>
                        </li>
                    )}
                    onChange={(_, value) => {
                        if (value) {
                            const categoryL4 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], bagStore.advanced.filters.category?.[2], value] as CategoryFilter;
                            bagStore.advanced.categoryFilterChanged(categoryL4)
                        } else {
                            const categoryL3 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], bagStore.advanced.filters.category?.[2]] as CategoryFilter;
                            bagStore.advanced.categoryFilterChanged(categoryL3)
                        }
                    }}
                    style={{width: 300}}
                    renderInput={(params) => (
                        <TextField {...params} label=" Filter L4"/>
                    )}
                />
            </Grid>
        }
    </Grid>
}))