import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {SupplierNormalizationV2Icon} from "../../../../components/icons/SupplierNormalizationV2Icon";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {ArrowCollapsedIcon} from "../../../../components/icons/ArrowCollapsedIcon";
import {environment} from "../../../../env";
import {QueryStats} from "@mui/icons-material";
import {MoveIcon} from "../../../../components/icons/MoveIcon";

/**
 *
 */
export const MergeXCCombinedDashboard: React.FC = observer(() => {
    const {
        bagStore,
        categorizationReviewStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        spendConcentrationStore,
    } = useStores();

    // TODO: CAT-1140: Make a proper design for all the dashboard cards
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;

    const pendingSupplierNormalization = supplierNormalizationStore.statistics?.standalone_spend;
    const scTop = spendConcentrationStore.topValueP;
    return <>
        {environment.isTest && <h1>MergeXCCombinedDashboard</h1>}
        <Grid container
              className="dashboard merge-x-cleanup-dashboard merge-dashboard merge-combined-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={drawOpportunityValue(pendingSupplierNormalization)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.merge_xc_combined_supplier_normalization, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="adv-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Opportunity Dashboard"
                    // subtitle=""
                    link={generatePath(routes.merge_xc_combined_advanced_dashboard, {id: bagId})}
                    // badge="preview"
                />
                {/*<DashBoardCard*/}
                {/*    cardKey="contract-insights"*/}
                {/*    avatar={<ContractIcon/>}*/}
                {/*    title="Contract Insights"*/}
                {/*    subtitle="Preview"*/}
                {/*    link={generatePath(routes.cleanup_contract_insights, {id: bagId})}*/}
                {/*/>*/}
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.merge_xc_combined_taxonomy_builder, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    // value={nCategories}
                    // subtitle="categories"
                    link={generatePath(routes.merge_xc_combined_explore_categories, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_xc_combined_categorization, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.merge_xc_combined_spend_concentration, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    // value={drawOpportunityValue(ppvTotal)}
                    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.merge_xc_combined_ppv, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
