import {useStores} from "../../stores";
import {routes} from "../../routing/routes";
import {useEffect} from "react";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {observer} from "mobx-react-lite";
import {generatePath} from "react-router";
import {OpportunityTable} from "./components/OpportunityTable";
import {Grid} from "@mui/material";


export const KOIOpportunity: React.FC = observer(() => {
    const {bagStore, opportunityStoreBis} = useStores();

    // useEffect(() => {
    //     opportunityStoreBis.loadData(true)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [bagStore.bagId]);

    useEffect(() => {
        opportunityStoreBis.opportunityPages.init({databag: bagStore.bagId})
    }, []);


    return <KoiFacade
        title="Opportunities"
        intro={<></>}
        back={generatePath(routes.job_koi_ppv, {id: bagStore.bagId})}
        className="koi-opportunity-page"
    >

        <Grid container justifyContent="space-around" style={{marginTop: '3em'}}>
            <Grid item xs={11}>
                <OpportunityTable/>
            </Grid>
        </Grid>

    </KoiFacade>
});
