import {Alert} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../../stores";
import moment from "moment";

type Props = {}
export const DefaultAlert: React.FC<Props> = observer(() => {
    const {categorizationReviewStore} = useStores();
    const taxonomyStatus = categorizationReviewStore.statusDelegate.taxonomyStatus;
    if (!taxonomyStatus) return <></>;
    const taxonomy = taxonomyStatus.taxonomy;
    const approval = taxonomyStatus.approval;

    let isWarning: boolean;
    let message: string;
    if (!taxonomy) {
        isWarning = true;
        message = 'There is no taxonomy available for review';
    } else {
        const date = moment(taxonomy.created_at);
        if (approval) {
            isWarning = true;
            const approvalDate = moment(approval.created_timestamp);
            message = `You are reviewing with taxonomy "${taxonomy.name}" from ${date.format('L')}, the approval is pending since ${approvalDate.format('L')}`;
        } else if (taxonomy.current_operation_number > 1) {
            isWarning = true;
            message = `You are reviewing with taxonomy "${taxonomy.name}" from ${date.format('L')}, changes to the taxonomy are not send for approval`;
        } else {
            isWarning = false;
            message = `You are reviewing the categorization based on taxonomy "${taxonomy.name}" from ${date.format('L')}`;
        }
    }

    const severity = isWarning ? 'warning' : 'info';
    const style = isWarning ? {marginBottom: '1em'} : {background: '#0262F21A', marginBottom: '1em'};
    return <Alert severity={severity} style={style}>{message}</Alert>
})
