/**
 * Get a chunk size that equally divides the file, but is at least 8 MiB and a multiple of 256 KiB.
 *
 * Larger chunk sizes typically make uploads faster, but note that there's a tradeoff between speed and memory usage.
 * It's recommended that you use at least 8 MiB for the chunk size.
 * @param fileSize
 */
export function getChunkSize(fileSize: number): number {
    const MIN_CHUNK_SIZE = 8 * 1024 * 1024; // 8Mb
    const MAX_CHUNK_SIZE = 32 * 1024 * 1024; // 32Mb
    const kib = 256 * 1024;
    // Divide the file into 10 equal parts
    // Get the closest multiple of 256 KiB
    const div10ChunkSize = Math.ceil(fileSize / 10 / kib) * kib;
    return Math.max(MIN_CHUNK_SIZE, Math.min(div10ChunkSize, MAX_CHUNK_SIZE));
}
