import React from "react";
import {observer} from "mobx-react-lite";
import './MultiDashBoards.scss'
import {useStores} from "../../stores";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {DashBoardCard} from "../dashboard/DashBoardCard";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {DashboardHeader} from "../dashboard/DashboardHeader";
import {MoveIcon} from "../../components/icons/MoveIcon";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {SupplierSegmentationIcon} from "../../components/icons/SupplierSegmentationIcon";

export const SingleAnalyticsDashboard: React.FC = observer(() => {
    const {bagStore} = useStores();
    const bagId = bagStore.bagId;
    const totalPPVOpportunity = undefined;
    const topXSpend = undefined;
    return <>
        <DashboardHeader/>
        <Grid container
              className="dashboard single-analytics-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.single_analytics_koi_ss, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.single_analytics_koi_kdf, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(totalPPVOpportunity)}
                    valueColor={totalPPVOpportunity ? (totalPPVOpportunity >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.single_analytics_koi_ppv, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={topXSpend ? `${Math.round(topXSpend)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={topXSpend ? (topXSpend < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.single_analytics_koi_sc, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
