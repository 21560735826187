import React, {useState} from "react";
import {SmallTable} from "../ChatTypes";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";

type Props = {
    msg: SmallTable
}
export const SmallTableChatMessage: React.FC<Props> = ({msg}) => {
    const [orderBy, setOrderBy] = useState<string | null>(null);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    const handleSort = (header: string) => {
        const isAsc = orderBy === header && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    const sortedRows = [...msg.data].sort((a, b) => {
        if (!orderBy) return 0;
        const index = msg.headers.indexOf(orderBy);
        if (index === -1) return 0;
        const aValue = a[index];
        const bValue = b[index];
        return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
    });

    return <TableContainer component={Paper} sx={{marginTop: 2}}>
        <Table>
            <TableHead>
                <TableRow>
                    {msg.headers.map((header, index) => (
                        <TableCell key={index}>
                            <TableSortLabel
                                active={orderBy === header}
                                direction={orderBy === header ? order : 'asc'}
                                onClick={() => handleSort(header)}
                            >
                                {header}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedRows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}