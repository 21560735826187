import {styled} from "@mui/material/styles";
import {observer} from "mobx-react-lite";
import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import {useStores} from "../../../stores";
import React from "react";
import {ModalGenericV2} from "./ModalGenericV2";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {TaxonomyCategory} from "../../../stores/TaxonomyCategoryProvider";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(ModalGenericV2)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalMoveCategory: React.FC = observer(() => {

    const {taxonomyEditorStore, categorizationReviewStore, taxonomyHealthCheckStore} = useStores();

    const filterOptions = createFilterOptions<TaxonomyCategory>({
        matchFrom: 'any',
        stringify: option => option.search,
    });

    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="update-model-component"
            title="Move category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={() => taxonomyEditorStore.saveMergeCategory()}
            onClose={() => taxonomyHealthCheckStore.isMoveCategoryModalOpen = false}
            saveLabel="Move"
        >

            <Autocomplete
                id={'autoId'}
                options={categorizationReviewStore.categories.taxonomy_categories || []}
                style={{marginBottom: 30}}
                className="toolbar-search"
                filterOptions={filterOptions}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props}>
                        <PartCategorizationComponent cats={option.values}/>
                    </li>
                )}
                onChange={(_, newValue: any | null) => {
                    // taxonomyEditorStore.searchByLabels(toJS(newValue.values) as string[])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={'Search for a category'}
                        variant="outlined"
                        autoFocus
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
            />

        </StyledEditorModalComponent>
    );
})