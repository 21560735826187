import {makeAutoObservable} from "mobx";
import {getChunkSize} from "./GcpBucketHelper";
import axios, {AxiosError, AxiosInstance} from "axios";
import {Crc32c} from "@aws-crypto/crc32c";
import {encodeUint32ToBase64} from "../../utils/js-utils";
// The google CRC32C implementation is not compatible with React, so we use the AWS implementation
// https://cloud.google.com/nodejs/docs/reference/storage/latest/storage/crc32c#_google_cloud_storage_CRC32C_fromFile_member_1_

export type GcpBucketResponse = {
    id: string
    bucket: string
    name: string
    size: string
    crc32c: string
}

type RequestUploadResp = {
    bucket_name: string
    folder?: string
    access_token: string
}

export default class DataUploadController {
    static readonly gcpHttp = axios.create()

    /**
     * The name of the file that is currently being uploaded
     */
    fileName = ''
    /**
     * The file that has finished uploading
     * Only set when the upload is completed
     */
    uploadedFile: GcpBucketResponse | null = null;
    /**
     * The current progress [0,100]
     * When the progress 0 or 100 there are not updated in progress
     */
    progress = 0
    /**
     * Any error that occurred during the upload process
     * When this error occurs the user should simply upload the file again
     */
    errorMsg = '';
    /**
     * Indicate if the async operation should be stopped
     * (Can be replaced with takeUntil in RxJS)
     * @private
     */
    private canContinue = true
    /**
     * The received upload location from GCP
     * @private
     */
    private gcpUploadLocation = ''

    constructor(
        readonly mithraHttp: AxiosInstance,
        private readonly dropzoneEndpoint: string,
    ) {
        makeAutoObservable(this, {mithraHttp: false});
    }

    setUploadError(error: string) {
        this.errorMsg = error;
    }

    handleFileUpload(file: File) {
        if (this.gcpUploadLocation) {
            // Still uploading
            return;
        }

        this.errorMsg = ''
        this.uploadedFile = null;
        this.canContinue = true;
        this.progress = 1;
        this.fileName = file.name;

        this._fileUpload(file);
    };

    private async _fileUpload(file: File) {
        const chunkSize = getChunkSize(file.size);

        // Calculate the CRC32C checksum of the file
        // const time0 = performance.now()
        const fileBuffer = await file.arrayBuffer()
        const chunks = Math.ceil(fileBuffer.byteLength / chunkSize);

        // We assume uploading is 10x slower than local, so 10 progression steps for network step
        const totalSteps = (
            1 // await file
            + chunks // await crc32c
            + 10 // await mithra call (network)
            + (
                chunks // await gcp calls
                + 1 // last step will cause completion
            ) * 10
        );
        let progressStep = 0;
        this.setProgressStep(++progressStep, totalSteps)
        const calculator = new Crc32c();
        // Split the buffer into chunks of 64Mb
        for (let i = 0; i < chunks; i++) {
            if (!this.canContinue) return
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, fileBuffer.byteLength);
            calculator.update(new Uint8Array(fileBuffer.slice(start, end)))
            this.setProgressStep(++progressStep, totalSteps)
            await new Promise(resolve => setTimeout(resolve, 0)) // Ensure the event loop is not blocked
        }
        const crc32c: number = calculator.digest()
        // const time1 = performance.now()
        // Indication: ~250Mb / second
        // console.debug(`CRC32C calculation took ${time1 - time0}ms`)

        // Call to Mithra API to get the target location which we have access to
        // console.debug('Calling Mithra API')
        const mithraResponse = await this.mithraHttp.get<RequestUploadResp>(this.dropzoneEndpoint);
        if (!this.canContinue) return
        progressStep += 10
        this.setProgressStep(progressStep, totalSteps)

        // Call to https://cloud.google.com/storage/docs/json_api/v1/objects/insert
        // console.debug('Calling GCP')
        const bucketNameUri = encodeURIComponent(mithraResponse.data.bucket_name);
        const url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketNameUri}/o?uploadType=resumable`;

        let name: string = file.name;
        if (mithraResponse.data.folder) {
            name = `${mithraResponse.data.folder}/${name}`;
        }
        const crc32cBase64 = encodeUint32ToBase64(crc32c)
        const createData = {
            name,
            contentType: file.type,
            crc32c: crc32cBase64,
        };
        const gcpResponse = await DataUploadController.gcpHttp.post(url, createData, {
            headers: {
                'Authorization': `Bearer ${mithraResponse.data.access_token}`,
            }
        })
        if (!this.canContinue) return
        // Progress will be set on the first call to uploadNextChunk

        this.setUploadLocation(gcpResponse.headers['location'])

        let start = 0;
        let end = 0;
        const uploadNextChunk = () => {
            if (!this.canContinue || !this.gcpUploadLocation) return
            if (end < file.size) {
                // Set the next chunk
                start = end;
                end = Math.min(end + chunkSize, file.size);
                progressStep += 10
                this.setProgressStep(progressStep, totalSteps)

                const chunk = file.slice(start, end);
                const range = `bytes ${start}-${end - 1}/${file.size}`;
                // console.debug('Uploading GCP', ((end - start) / file.size * 100).toFixed(2) + '%', (end / file.size * 100).toFixed(2) + '%', range)
                return DataUploadController.gcpHttp.put(this.gcpUploadLocation, chunk, {
                    headers: {
                        'Content-Range': range,
                    },
                })
                    .then(resp => {
                        if (!this.canContinue) return
                        this.onComplete(resp.data)
                    })
                    .catch((error: AxiosError) => {
                        if (!this.canContinue) return
                        if (error.response && error.response.status === 308) {
                            // A 308 Resume Incomplete response indicates that you need to continue uploading the data
                            if (error.response.headers['range']) {
                                // Upload was successful, go to the next file
                                return uploadNextChunk()
                            } else {
                                // Start over
                                this.setUploadError('Error during upload, please try again')
                                console.error('Start over', error)
                            }
                        } else {
                            this.setUploadError('Error during upload, please try again')
                            console.error('Error during upload', error)
                        }
                        return Promise.reject(error)
                    });
            }
        };

        await uploadNextChunk();
    }

    setUploadLocation(url: string) {
        this.gcpUploadLocation = url;
    }

    async cancel() {
        // Signal that the upload should be cancelled
        this.canContinue = false;

        if (this.gcpUploadLocation) {
            // GCP object is already created, we need to delete it
            await DataUploadController.gcpHttp.delete(this.gcpUploadLocation)
                .catch((error: AxiosError) => {
                    if (error.response && error.response.status === 499) {
                        // Success
                    } else {
                        this.setUploadError('Error, please try again')
                        console.error('Error during cancelling', error);
                    }
                })
                .finally(() => this.clearUpload());
        } else {
            // Give the async processes some time to finish
            await new Promise(resolve => setTimeout(resolve, 500));
            this.clearUpload();
        }
    }

    /**
     * Set the progress of the upload (1%-99%)
     * @param step > 1 and < total
     * @param total total number of steps
     */
    setProgressStep(step: number, total: number) {
        let clampStep = Math.max(1, Math.min(step, total));
        this.progress = 1 + Math.min(99, clampStep / total * 99);
        // console.debug(`Progress: ${step} / ${total} -> ${this.progress}`)
    }

    clearUpload() {
        this.progress = 0;
        this.gcpUploadLocation = '';
        this.fileName = '';
        this.uploadedFile = null;
    }

    get inProgress() {
        return this.progress > 0 && this.progress < 100;
    }

    get isUploaded() {
        return !this.inProgress && this.uploadedFile;
    }

    get isCancelling() {
        return this.inProgress && !this.canContinue;
    }

    get isRemoving() {
        return !this.canContinue && this.gcpUploadLocation;
    }

    onComplete(file: GcpBucketResponse) {
        this.uploadedFile = file;
        this.progress = 100
        this.fileName = file.name;
    }
}
