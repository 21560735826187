import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {useStores} from "../../../stores";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {ColSpec, MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import ProfileStore from "../../../stores/ProfileStore";
import {PartReCategorizationHandler} from "../../../components/categorization/PartRecategorizationHandler";
import {
    PartReCategorizationControllerStore
} from "../../../components/categorization/PartReCategorizationControllerStore";
import {MithraTableCell} from "../../../components/table/MithraTableCell";

//this was getCatReviewSubColumns
export function getDatasetTargetColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-b_name', txt: p.buDataName, width: 125},
        {cls: 'col-s_name', txt: p.supplierDataName},
        {cls: 'col-p_name', txt: 'Part name'},
        {cls: 'col-p_description', txt: p.partDescriptionColumnName},
        {cls: 'col-p_spend', txt: 'Amount', width: 90},
    ]
    return arr.filter(c => c) as ColSpec[];
}

export const MappingResultTable: React.FC = observer(() => {
    const {p, categorizationReviewStore, dataIngestionStore} = useStores()
    const categories = categorizationReviewStore.categories;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    const isLoading = categorizationReviewStore.isLoading;
    const tableClass = 'mithra-table x-design x-design-narrow' + (isLoading ? ' loading' : '');

    const data = dataIngestionStore.dataMappingResultTable;

    const COLUMNS = getDatasetTargetColumns(p)

    return <div className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map((row, i) => <React.Fragment key={i}>
                            <SingularCategorizationReviewRow part={row} controller={controller}/>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </div>
})

type Props = {
    part: any
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const SingularCategorizationReviewRow: React.FC<Props> = observer(({part, controller}) => {
    const {p} = useStores();
    const COLUMNS = getDatasetTargetColumns(p);

    let i = 0;

    return <TableRow>
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.bu__id ? part.bu__id : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={COLUMNS[i++]}>
            {part.bu__name ? part.bu__name : 'N/A'}
        </MithraTableCell>
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.s__id ? part.s__id : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={COLUMNS[i++]}>
            {part.s__name ? part.s__name : 'N/A'}
        </MithraTableCell>
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.s__city ? part.s__city : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__id ? part.p__id : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={COLUMNS[i++]}>
            {part.p__name ? part.p__name : 'N/A'}
        </MithraTableCell>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                {part.p__description ? part.p__description : 'N/A'}
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend}></CurrencyComponent>
        </MithraTableCell>
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__context_1 ? part.p__context_1 : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__purchase_date ? part.p__purchase_date : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__quantity ? part.p__quantity : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__in_l1 ? part.p__in_l1 : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__in_l2 ? part.p__in_l2 : 'N/A'}*/}
        {/*</MithraTableCell>*/}
        {/*<MithraTableCell c={COLUMNS[i++]}>*/}
        {/*    {part.p__in_l3 ? part.p__in_l3 : 'N/A'}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})