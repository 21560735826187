import {styled} from '@mui/material/styles';
import {TextField} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {ModalGenericV2} from "./ModalGenericV2";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(ModalGenericV2)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalRenameCategory: React.FC = observer(() => {
    const onSave = () => taxonomyEditorStore.saveUpdateCategory();
    const {taxonomyEditorStore, taxonomyHealthCheckStore} = useStores();

    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="update-model-component"
            title="Rename category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={onSave}
            onClose={() => taxonomyHealthCheckStore.isRenameCategoryModalOpen = false}
            saveLabel="Rename"
        >
            <TextField
                autoFocus
                fullWidth
                style={{marginBottom: 30}}
                id="taxonomy-editor-modal-name-field"
                label="Current category name"
                error={Boolean(taxonomyEditorStore.updateCategoryNameError)}
                helperText={taxonomyEditorStore.updateCategoryNameError}
                variant="outlined"
                value={'test'} //taxonomyHealthCheckStore.selectedCategory?.label
                disabled={true}
            />

            <TextField
                autoFocus
                fullWidth
                style={{marginBottom: 30}}
                id="taxonomy-editor-modal-name-field"
                label="enter category name"
                error={Boolean(taxonomyEditorStore.updateCategoryNameError)}
                helperText={taxonomyEditorStore.updateCategoryNameError}
                variant="outlined"
                value={taxonomyEditorStore.updateCategoryName}
                onChange={e => taxonomyEditorStore.setUpdateCategoryName(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />

            <TextField
                fullWidth
                multiline
                id="taxonomy-editor-modal-description-field"
                label="Description"
                error={Boolean(taxonomyEditorStore.updateCategoryDescriptionError)}
                helperText={taxonomyEditorStore.updateCategoryDescriptionError}
                variant="outlined"
                value={taxonomyEditorStore.updateCategoryDescription}
                onChange={e => taxonomyEditorStore.setUpdateCategoryDescription(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />
        </StyledEditorModalComponent>
    );
})
