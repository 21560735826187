import React from "react";
import {Alert, AlertProps, AlertTitle, Button, Grid, Typography} from "@mui/material";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import {ApprovalStatusEnum} from "../../../services/classes/AiClasses";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import moment from "moment/moment";
import {AdminPanelSettings} from "@mui/icons-material";
import {forkJoin, from, tap} from "rxjs";

type Props = {
    gridItem?: true
}
export const TaxonomyApprovalAlert: React.FC<Props> = observer(({gridItem}) => {
    const {taxonomyManagerStore, taxonomyEditorStore, authStore, approvalStore, materializedApi} = useStores();
    const taxonomy = taxonomyManagerStore.taxonomy;

    const [loading, setLoading] = React.useState(false);

    if (!taxonomy) return <></>;

    let alertBody: JSX.Element | null = null
    let alertActions: JSX.Element | null = null
    let alertSeverity: AlertProps['severity'] = 'info'

    const activeApproval = taxonomy.active_approval_request;
    const prevApproval = taxonomy.result_of_approval;

    if (activeApproval) {
        // Do not show this message after 2 months anymore
        const created = moment(activeApproval.created_timestamp);
        const diffMonths: number = moment().diff(created, 'months');
        if (diffMonths < 2) {
            const approvalDelegate = taxonomyEditorStore.approval;
            const approvalStatus = activeApproval.current_status.status;
            if (approvalStatus === ApprovalStatusEnum.REJECTED) {
                alertSeverity = 'error'
            }
            alertBody = <Typography>
                <AlertTitle>
                    Waiting for approval
                </AlertTitle>
                Approval status: {ApprovalStore.getApprovalStatusName(approvalStatus)}
                {/*(Updated {created.fromNow()})*/}
            </Typography>
            alertActions = <>
                {authStore.isMithraStaffGroup && <div>
                    <Button color="primary"
                            startIcon={<AdminPanelSettings/>}
                            onClick={() => approvalDelegate.forceSetStatus(ApprovalStatusEnum.PENDING)}>
                        Force status to: PENDING
                    </Button>
                    <br/>
                    <Button color="primary"
                            startIcon={<AdminPanelSettings/>}
                            onClick={() => approvalDelegate.forceSetStatus(ApprovalStatusEnum.APPROVED)}>
                        Force status to: APPROVED
                    </Button>
                    <br/>
                    <Button color="warning"
                            startIcon={<AdminPanelSettings/>}
                            onClick={() => approvalDelegate.forceSetStatus(ApprovalStatusEnum.HALTED)}>
                        Force status to: HALTED
                    </Button>
                    <br/>
                    <Button color="error"
                            startIcon={<AdminPanelSettings/>}
                            onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                confirm('Are you sure you want to delete this approval?')
                                && approvalDelegate.forceDeleteTaxonomyApprovalRequest()
                            }}>
                        Destroy approval request
                    </Button>
                </div>}
            </>
        }
    }
    if (!alertBody && prevApproval) {
        // Do not show this message after 2 months anymore
        const created = moment(prevApproval.created_timestamp);
        const diffMonths: number = moment().diff(created, 'months');
        if (diffMonths < 2) {
            alertBody = <>
                Last approval: {created.fromNow()}
            </>
            alertActions = <>
                <Button
                    color="inherit" size="small"
                    // variant="outlined"
                    disabled={loading}
                    onClick={() => {
                        const approvalId = prevApproval?.id;
                        if(!approvalId) return;
                        setLoading(true)
                        forkJoin([
                            from(materializedApi.getTaxonomyApprovalRequest(approvalId)).pipe(
                                tap(r => approvalStore.setApproval(r.data))
                            ),
                            from(materializedApi.getTaxonomyApprovalState(approvalId)).pipe(
                                tap(r => approvalStore.taxonomyApproval.setApprovalState(r.data))
                            ),
                        ]).subscribe({
                            next: () => {
                                taxonomyEditorStore.setApprovalFeedbackViewOpen()
                            },
                            complete: () => {
                                setLoading(false)
                            },
                        })
                    }}>
                    See feedback
                </Button>
            </>
        }
    }

    if (alertBody || alertActions) {
        const alert = <Alert
            severity={alertSeverity}
            action={alertActions}>
            {alertBody}
        </Alert>
        return gridItem ? <Grid item>{alert}</Grid> : alert;
    } else {
        return <></>
    }
})