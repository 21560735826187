import React, {useState} from 'react';
import {arrCopyAdd, arrCopyRemoveIndex} from "../../utils/js-utils";
import {Button} from "@mui/material";
import {initBarChart, updateBarChart} from "./echart-bar-chart";
import {MithraEchart} from "../../services/echarts/MithraEchart";
import {PieCallbackDataParams} from "echarts/types/src/chart/pie/PieSeries";
import {CustomEchartComponent, Data} from "./CustomEchartComponent";

let nextId = 4;

export const EchartReactPlayground = () => {
    const [show, setShow] = useState(true);

    const [clicked, setClicked] = useState('');

    const [data, setData] = useState<Data[]>([{name: 1, value: 1}, {name: 2, value: 2}, {name: 3, value: 3}]);

    const onClick = (params: PieCallbackDataParams) => {
        console.log('clicked MyEchartBarChart', params.data, params.percent)
        setClicked(params.name)

        const toBeDuplicated = data.filter(d => d?.value === (params.data as Data)?.value)
        const newData = data.concat(...toBeDuplicated.map(d => ({name: nextId++, value: d.value})));
        console.log('clicked MyEchartBarChart newData=', newData.length)
        setData(newData)
    }

    return (
        <div>
            <p style={{background: '#ffe1bb'}}>By clicking the graph all elements with the same size are duplicated</p>

            <p style={{background: '#c0ffbb', fontWeight: 'bold'}}>MithraEchart</p>
            {show &&
                <MithraEchart
                    initChart={initBarChart}
                    updateChart={updateBarChart}
                    data={data}
                    onClick={onClick}
                />
            }<br/>

            <p style={{background: '#bbfff7', fontWeight: 'bold'}}>CustomEchartComponent</p>
            {show &&
                <CustomEchartComponent
                    data={data}
                    onClick={onClick}
                />
            }<br/>

            <section>
                <p style={{background: '#ffbbcd', fontWeight: 'bold'}}>Data</p>
                <p>data = {JSON.stringify(data)}</p>
                <Button variant="contained"
                        onClick={() => setData(arrCopyAdd(data, {name: nextId++, value: data.length + 1}))}>
                    Add value {'' + (data.length + 1)}
                </Button>
                <Button variant="contained"
                        onClick={() => setData(arrCopyAdd(data, {name: nextId++, value: 2}))}>
                    Add value 2
                </Button>
                <br/>
                {data.map((d, i) =>
                    <Button key={i}
                            variant="contained"
                            onClick={() => setData(arrCopyRemoveIndex(data, i))}>
                        Remove element {'' + d.value}
                    </Button>
                )}<br/>
            </section>

            <section>
                <p style={{background: '#c0bbff', fontWeight: 'bold'}}>Graph</p>
                <Button variant="contained" onClick={() => setShow(!show)}>
                    {show ? 'Hide' : 'Show'}
                </Button>
                <br/>
                <p>
                    Clicked: {'' + clicked}
                </p>
                <br/>
            </section>
        </div>
    )
}
