/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DatabagRouteMatch} from "../../routing/routes";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";
import {getDashboardEnv} from "../../pages/multi-dashboard/merge-x-cleanup-dashboard/constants";

/**
 * Wrapper for the mergeX product offering
 */
export const MergeXCEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        authStore,
        bagLoadingStore,
        supplierNormalizationStore,
        materializedApi,
        p,
        categorizationReviewStore
    } = useStores();
    const params = useParams<DatabagRouteMatch & { revisionHackParam: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const isNoData = state.dataType === 'no_data';

    let revision = params.revisionHackParam;
    console.warn('Categorization revision hack is being applied;', revision);
    if (revision === undefined) {
        materializedApi.revisionHack = undefined;
    } else {
        materializedApi.revisionHack = String(revision);
    }

    rootAppStore.setApp(state)
    // useEffect(() => rootAppStore.setApp(state), [])


    // The magic that loads the data
    useEffect(() => {
        console.log('MergeXC MergeXCEnvWrapper: useEffect')
        if (p.p.hardcodeMasterBagId) throw new Error('hardcodeMasterBagId should not be set');
        if (!p.p.hardcodedCombinedBagId) throw new Error('hardcodedCombinedBagId must be set');

        // Always initiating the master supplier normalization store
        supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, false)

        // [CAT-1580] These auto-resets are causing unnecessary requests to the server
        // On page load they are always with databag=-1, which is not a valid bagId
        categorizationReviewStore.reviewPageController.reviewPageFilterController.suppliersSearchManager.search.clearSearchBox();
        categorizationReviewStore.reviewPageController.reviewPageFilterController.partsSearchManager.search.clearSearchBox();
        categorizationReviewStore.reviewPageController.loadData(true)

        if (!isNoData || rootAppStore.app.dashboardEnvironment === 'approval') {
            authStore.loadUsers()
        }
        if (!isNoData) {
            const env = getDashboardEnv(rootAppStore.app);
            if (env === 'synergy') {
                bagLoadingStore.loadBag(p.p.hardcodedCombinedBagId)
            } else {
                bagLoadingStore.initBag(location, navigate, params)
            }
        }
        return () => {
        };
    }, [])

    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
