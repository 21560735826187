import {Autocomplete, Box, List, ListItem, ListItemText, TextField} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {EditorModalComponent} from "../EditorModalComponent";
import {Category} from "../../../stores/TaxonomyEditorStore";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";
import {
    TaxonomyTreeEditorController
} from "../../../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyTreeEditorController";

export const MoveCategoryModal: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const n = taxonomyEditorStore.selection.length;
    const p = n !== 1 // plural
    return <EditorModalComponent
        className="taxonomy-modal-component move-category-modal"
        titleId="move-category-modal-title"
        title={p ? `Move ${n} categories` : 'Move category'}
        canSave={taxonomyEditorStore.canMoveCategories}
        onSave={() => taxonomyEditorStore.saveMoveCategory()}
        onClose={() => taxonomyEditorStore.setMoveCategoryMode(false)}
    >
        <section className="modal-introduction">
            {p ? 'Selected categories:' : 'Selected category:'}
            <List>
                {taxonomyEditorStore.selection.map(n => {
                    const labels = TaxonomyTreeEditorController.getLabels(n).slice(1);
                    return <ListItem key={n.data.id}>
                        {/*<ListItemIcon>*/}
                        {/*    <ChevronRight/>*/}
                        {/*</ListItemIcon>*/}
                        <ListItemText
                            primary={<CategoryChip labels={labels}/>}
                            secondary={n.data.values.description || undefined}
                        />
                    </ListItem>
                })}
            </List>
        </section>
        {/*<div>*/}
        {/*    Selected category*/}
        {/*    {taxonomyEditorStore.lastSelected &&*/}
        {/*        <PartCategorizationComponent cats={taxonomyEditorStore.lastSelected.ancestors().reverse().map(a => a.data.label)}/>*/}
        {/*    }*/}
        {/*</div>*/}

        <Autocomplete
            // id={id}
            options={taxonomyEditorStore.taxonomyParentOptions}
            // filterOptions={filterOptions}
            autoHighlight
            getOptionLabel={(option) =>
                option.values.length === 0 ? 'Move to L1' : option.values.join(' > ')
            }
            isOptionEqualToValue={(option, value) => option.node_id === value.node_id}
            renderOption={(props, option) =>
                <li {...props}>
                    <Box component="div">
                        {option.values.length === 0
                            ? 'Move to L1'
                            : <PartCategorizationComponent cats={option.values}/>
                        }
                    </Box>
                </li>
            }
            onChange={(_, newValue: any | null) => {
                if (newValue) {
                    const category = newValue as Category
                    taxonomyEditorStore.setMoveCategoryDestination(category)
                }
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Select the L1 to to move this category to"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        tabIndex: 1,
                        // autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }
        />
    </EditorModalComponent>
})
