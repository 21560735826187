import './TaxonomyApprovalComponent.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Alert, Button, Dialog, Grid, TextField} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ApprovalStatusEnum, ApprovalTypeEnum} from "../../../services/classes/AiClasses";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import {APPROVAL_DIALOG_TITLE_ID} from "../ApprovalDialog";
import {TaxonomyApprovalAcceptDialog} from "./TaxonomyApprovalAcceptDialog";
import {TaxApprovalReviewTable} from "./TaxonomyApprovalReviewTable";
import {TaxonomyApprovalReviewButtonBar} from "./toolbar/TaxonomyApprovalReviewButtonBar";
import {EditCategoryModal} from "./modals/EditCategoryModal";
import {MoveCategoryModal} from "./modals/MoveCategoryModal";
import {DeleteCategoryModal} from "./modals/DeleteCategoryModal";
import {CreateCategoryModal} from "./modals/CreateCategoryModal";
import {ChevronRight} from "@mui/icons-material";
import {environment} from "../../../env";

const isDev = environment.isTest;

export const TaxonomyApprovalComponent: React.FC = observer(() => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {approvalStore} = useStores();
    const ta = approvalStore.taxonomyApproval;
    const readOnly = ta.readOnly && !isDev;

    // The magic that loads the data
    useEffect(() => approvalStore.onLoadApprovalPage(ApprovalTypeEnum.TAXONOMY, params, navigate),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const a = approvalStore.taxonomyApprovalRequest;
    const canEdit = a && ApprovalStatusEnum.userCanUpdate(a.current_status.status) || isDev;
    return <div className="taxonomy-approval-component">
        <Dialog
            open={approvalStore.isAcceptApprovalDialogOpen}
            onClose={() => approvalStore.clickAwayApprovalDialog()}
            aria-labelledby={APPROVAL_DIALOG_TITLE_ID}
            maxWidth="lg"
        >
            {approvalStore.isAcceptApprovalDialogOpen && <TaxonomyApprovalAcceptDialog/>}
        </Dialog>
        <Dialog
            open={ta.isEditDialogOpen}
            onClose={() => ta.closeDialogs()}
            maxWidth="lg"
        >
            {ta.editNode && <EditCategoryModal node={ta.editNode}/>}
            {ta.moveNode && <MoveCategoryModal node={ta.moveNode}/>}
            {ta.deleteNode && <DeleteCategoryModal node={ta.deleteNode}/>}
            {ta.createNode && <CreateCategoryModal node={ta.createNode}/>}
        </Dialog>
        <Grid component="section" className="intro" container justifyContent="center" alignItems="center">
            <Grid item md={6} xs={10}>
                {a && <>
                    <TextField
                        multiline
                        fullWidth
                        label="Notes for the reviewer"
                        variant="filled"
                        value={a.notes || ''}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextField
                        multiline
                        fullWidth
                        label="Reply"
                        variant={readOnly ? 'filled' : 'outlined'}
                        value={a.feedback_notes || ''}
                        onChange={e => ta.setFeedbackNotes(e.target.value)}
                        inputProps={{readOnly: readOnly}}
                    />
                </>}
            </Grid>
            <Grid item md={2} xs={2}
                  container direction="column" alignItems="center">
                {!readOnly &&
                    <Grid item>
                        <Button
                            variant="outlined"
                            className="m-2"
                            onClick={() => {
                                approvalStore.openSendApprovalDialog();

                                // noinspection JSIgnoredPromiseFromCall
                                approvalStore.taxonomyApproval.ensureApprovalNotesSaved(); // in background
                            }}
                            endIcon={<ChevronRight/>}
                            disabled={!canEdit || approvalStore.taxonomyApproval.isBusy}
                        >
                            Save notes and finish review
                        </Button>
                    </Grid>
                }
                {a?.current_status.status !== ApprovalStatusEnum.PENDING &&
                    <Grid item>
                        {ApprovalStore.getApprovalStatusName(a?.current_status.status)}
                    </Grid>
                }
                {ta.errorMessage &&
                    <Grid item>
                        <Alert severity="error">
                            {ta.errorMessage}
                        </Alert>
                    </Grid>
                }
            </Grid>
        </Grid>

        <TaxonomyApprovalReviewButtonBar/>

        <TaxApprovalReviewTable/>
    </div>
})
