import {ApiService, mithra_http} from "./Http";
import {AxiosResponse} from "axios";
import {
    DataFrameSerializer,
    DataIngestionKpiSerializer,
    DataMappingResultTableSerializer,
    DataMappingSerializer,
    MithraColumnSerializer,
    TaxonomyMappingSerializer
} from "./ApiTypes";
import {environment} from "../env";
import {
    CreateTaxonomyIngestionFile,
    DataIngestionFileStatus,
    IngestionFile,
    TaxonomyIngestionFile
} from "./classes/IngestionClasses";
import {GcpBucketResponse} from "./classes/DataUploadController";

export default class MithraDataIngestionApi extends ApiService {

    getDatasetList(): Promise<AxiosResponse<IngestionFile[]>> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_ingestions.json').then(r => r.data);
        return this.http.get(`/data_file/`);
    }

    getDataset(id: number): Promise<IngestionFile> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_ingestion.json').then(r => r.data);
        return this.http.get(`/data_file/${id}`).then(r => {
            return r.data;
        });
    }

    deleteIngestionFile(ingestionFileId: number) {
        if (environment.isDemo) throw new Error('Not implemented in demo mode');
        return this.http.delete<void>(`/data_file/${ingestionFileId}`);
    }

    uploadDroppedDatasetFile(gcpFile: GcpBucketResponse, name: string, startingRow: number, headersRow?: number, source_databag_id?: number) {
        return this.http.post<IngestionFile>(`/data_upload/`, {
            name: name,
            dropped_excel_file: {
                bucket_name: gcpFile.bucket,
                blob_name: gcpFile.name,
            },
            dataframe_first_row: startingRow,
            dataframe_columns_row: headersRow,
            source_databag_id: source_databag_id
        }).then(r => r.data);
    }

    uploadDatasetFileDirectly(file: File, name: string, startingRow: number, headersRow?: number, source_databag_id?: number): Promise<IngestionFile> {
        if (environment.isDemo) return mithra_http.mockApiSlow.get('/sales_demo/ingestion/data_ingestion.json').then(r => r.data);
        let formData = new FormData();
        formData.append("excel_file", file);
        formData.append("name", name);
        /*
        FOLLOW THE ROW NUMBER YOU SEE IN THE EXCEL FILE
        DO NOT ZERO INDEX IT!!!
        */
        formData.append("dataframe_first_row", startingRow.toString());
        if (headersRow) {
            formData.append("dataframe_columns_row", headersRow.toString());
        }
        if (source_databag_id) {
            formData.append("source_databag_id", source_databag_id.toString());
        }

        return this.http.post(`/data_upload/`, formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => r.data);
    }

    getAllowedOperations(): Promise<any> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/allowed_operations.json').then(r => r.data);
        return this.http.get(`/allowed_operations/`).then((r) => {
            return r.data;
        });
    }

    getAllMithraDatasetColumns(): Promise<MithraColumnSerializer[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/mithra_columns.json').then(r => r.data);
        return this.http.get(`/mithra_columns/`).then((r) => {
            return r.data;
        });
    }

    getAllClientDatasetColumns(dataFileId: number): Promise<DataFrameSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/dataframe_getter.json').then(r => r.data);
        return this.http.get(`/data_upload/${dataFileId}/dataframe_getter/`).then((r) => {
            return r.data;
        });
    }

    getDatasetMappingList(dataMappingId: number): Promise<DataMappingSerializer[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_mapping.json').then(r => r.data);
        return this.http.get(`/data_mapping/?data_file=${dataMappingId}&client_column_index=&mithra_column_key=`).then((r) => {
            return r.data;
        });
    }

    postDatasetMappingList(dataMappingList: DataMappingSerializer[]): Promise<DataMappingSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_mapping.json').then(r => r.data);
        return this.http.post(`/data_mapping/post_mappings_list/`, dataMappingList).then((r) => {
            return r.data;
        });
    }

    startDatasetMerge(dataFileId: number): Promise<AxiosResponse<{ databag_id: number }>> {
        if (environment.isDemo) {
            console.log('Mocking data ingestion merge');
        }
        return this.http.post(`/datafile_synchronous/${dataFileId}/ingest_data_to_cat_material/`);
    }

    getDataMergeStatus(dataFileId: number): Promise<AxiosResponse<DataIngestionFileStatus>> {
        return this.http.get(`/data_file/${dataFileId}/status/`);
    }

    applyDatasetMapping(dataFileId: number): Promise<AxiosResponse<void>> {
        if (environment.isDemo) return mithra_http.mockApiSlow.get('/sales_demo/ingestion/get_parsed_df_result.json').then(r => r.data);
        return this.http
            .post(`/datafile_synchronous/${dataFileId}/apply_mapping/`)
            .then((r) => {
                return r.data;
            });
    }

    getDatasetMappingResult(dataFileId: number): Promise<DataMappingResultTableSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/get_parsed_df_result.json').then(r => r.data);
        return this.http.get(`/data_file/${dataFileId}/get_parsed_df_result/`).then((r) => {
            return r.data;
        });
    }

    getDatasetKpiList(dataFileId: number): Promise<DataIngestionKpiSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/kpi_bundle.json').then(r => r.data);
        return this.http.get(`/bag/kpi_common/${dataFileId}/kpi_bundle/`).then((r) => {
            return r.data;
        });
    }

    getTaxonomyIngestionList(): Promise<AxiosResponse<TaxonomyIngestionFile[]>> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/taxonomy_ingestions.json').then(r => r.data);
        return this.http.get(`/taxonomy_ingestion/`);
    }

    getTaxonomyIngestion(id: number): Promise<TaxonomyIngestionFile> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/taxonomy_ingestion.json').then(r => r.data);
        return this.http.get(`/taxonomy_ingestion/${id}`).then(r => {
            return r.data;
        });
    }

    uploadTaxonomyIngestionFile(data: CreateTaxonomyIngestionFile): Promise<TaxonomyIngestionFile> {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('input_file', data.input_file);
        formData.append('input_df_column_row', String(data.input_df_column_row));
        formData.append('input_df_data_row', String(data.input_df_data_row));

        return this.http
            .post(`/taxonomy_upload/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((r) => {
                return r.data;
            });
    }

    getAllMithraTaxonomyColumns(): Promise<MithraColumnSerializer[]> {
        return this.http.get(`/taxonomy_mithra_columns/`).then((r) => {
            return r.data;
        });
    }

    getAllTaxonomyClientColumns(dataFileId: number): Promise<DataFrameSerializer> {
        return this.http.get(`/taxonomy_upload/${dataFileId}/dataframe_getter/`).then((r) => {
            return r.data;
        });
    }

    getTaxonomyMappingList(dataMappingId: number): Promise<TaxonomyMappingSerializer[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/taxonomy_mapping.json').then(r => r.data);
        return this.http.get(`/taxonomy_mapping/?taxonomy_file=${dataMappingId}&client_column_index=&mithra_column_key=`).then((r) => {
            return r.data;
        });
    }

    postTaxonomyMappingList(dataMappingList: TaxonomyMappingSerializer[]): Promise<TaxonomyMappingSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/taxonomy_mapping.json').then(r => r.data);
        return this.http.post(`/taxonomy_mapping/post_mappings_list/`, dataMappingList).then((r) => {
            return r.data;
        });
    }

    startTaxonomyMerge(dataFileId: number) {
        if (environment.isDemo) return Promise.resolve();
        return this.http.post(`/taxonomy_ingestion/${dataFileId}/ingest_taxonomy_to_cat_material/`);
    }

    applyTaxonomyMapping(dataFileId: number): Promise<AxiosResponse<void>> {
        if (environment.isDemo) return mithra_http.mockApiSlow.get('/sales_demo/ingestion/get_parsed_df_result.json').then(r => r.data);
        return this.http
            .post(`/taxonomy_ingestion/${dataFileId}/apply_mapping/`)
            .then((r) => {
                return r.data;
            });
    }

    getTaxonomyMappingResult(dataFileId: number): Promise<DataMappingResultTableSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/get_parsed_df_result.json').then(r => r.data);
        return this.http.get(`/taxonomy_ingestion/${dataFileId}/get_mapping_result/`).then((r) => {
            return r.data;
        });
    }
}

/**
 * Copied from the BE
 * As the upload process can be quite long, it's better to throw this error sooner than later
 */
export function canUploadFile(filename: string, size: number) {
    if (filename.endsWith('.xlsx') && size / 1024 / 1024 > 50) {
        return 'Excel file is too big, max 50Mb allowed, please upload CSV instead'
    }
}
