import {ParetoOverviewVisualization} from "../../jobs/koi/spend-concentration/viz/ParetoOverviewVisualization";
import React from "react";
import {BarDataPoint} from "../visualization/BarChart";
import {Options} from "../visualization/bar-chart/BarChart2";

type Props = {
    data: BarDataPoint[] | undefined,
    handleBarClick?: (d: BarDataPoint) => void,
}

const options: Partial<Options> = {
    vertical: false,
    width: 800,
    height: 200,
    labelMargin: 235, // Of the total width
    valueLabelPxH: 34,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    alwaysShowValues: true,
}

export const DistributionComponent: React.FC<Props> = ({data, handleBarClick}) => {

    return (
        <div className="custom-pareto-overview">
            {data && (
                <ParetoOverviewVisualization
                    data={data}
                    setSelected={(d) => handleBarClick && handleBarClick(d)}
                    options={{
                        ...options,
                    }}
                />
            )}
        </div>
    );
}


// {
//     console.log('Clicked on bar' + d.category);
//     if (d.category === "Very Low confidence(0-20)"){
//         console.log('Low confidence (0-20)')
//
//     }
//     if (d.category === "Low confidence (20-40)"){
//         console.log('Low confidence (20-40)')
//     }
//     if (d.category === "Medium confidence (40-60)"){
//         console.log('Medium confidence (40-60)')
//     }
//     if (d.category === "High confidence (60-80)"){
//         console.log('High confidence (60-80)')
//     }
//     if (d.category === "Very high confidence (80-100)"){
//         console.log('Very high confidence (80-100)')
//     }
// }