export function parseAsCurrency(s: string): number | null {
    let n = 0;
    s = s.toUpperCase();
    s = s.replace(',', '.')
    if (s.includes('M')) {
        n = parseFloat(s.replace('M', '')) * 1e6
    } else if (s.includes('K')) {
        n = parseFloat(s.replace('K', '')) * 1e3
    } else {
        n = parseFloat(s)
    }
    if (isNaN(n)) {
        return null
    }
    return n
}