import {makeAutoObservable} from 'mobx';
import {Bag} from "../services/classes/Bag";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import ProfileStore from "./ProfileStore";
import {stores} from "./index";
import {Location, NavigateFunction} from "react-router-dom";
import {BagKpiManager} from "./managers/BagKpiManager";
import {BusinessUnitManager} from "./managers/BusinessUnitManager";
import {generateGotoDashboardPath} from "../routing/routing.utils";
import {BagAdvancedInsightsManager} from "./managers/BagAdvancedInsightsManager";
import AuthStore from "./AuthStore";

export class BagStore {

    bag: Bag | undefined = undefined;
    allBags?: Bag[] = undefined;

    kpi = new BagKpiManager(this.api, this, this.profileStore);

    public readonly bu = new BusinessUnitManager(this.profileStore, this.api);

    public readonly advanced = new BagAdvancedInsightsManager(this.api, this, this.profileStore);

    constructor(private api: MithraMaterializedApi, private profileStore: ProfileStore, private authStore: AuthStore) {
        makeAutoObservable(this);
    }

    get userBagChoices(): Bag[] | undefined {
        if (!this.allBags) return undefined;
        let bags = this.allBags
        if (this.profileStore.p.hardcodedCombinedBagId) {
            bags = bags.filter(b => b.id !== this.profileStore.p.hardcodedCombinedBagId)
        }
        if (!this.authStore.isMithraStaffGroup) {
            bags = bags.filter(b => !b.hidden)
        }
        return bags;
    }

    get userBagDefaultOption(): Bag | null | undefined {
        const bags = this.userBagChoices;
        if (bags === undefined) return undefined;
        const lastHiddenDatabagI = bags.slice().reverse().findIndex(b => !b.hidden);
        const bag = lastHiddenDatabagI === -1 ? bags.at(-1) : bags.at(bags.length - lastHiddenDatabagI - 1);
        return bag ?? null;
    }

    setAllBags(allBags: Bag[]) {
        this.allBags = allBags;
    }

    get bagId(): number {
        return this.bag?.id || 0;
    }

    get isSample(): boolean {
        return Boolean(this.bag?.is_sample_of);
    }

    /**
     * Experiment: We use a sample bag to initialize the categorization review page
     */
    get sampleDatabagId(): number | null {
        const samples = this.bag?.databag_samples ?? [];
        if (samples.length > 0) {
            return samples.at(-1)!;
        }
        return null;
    }

    set(b: Bag) {
        // TODO: We do not need a set() and setBag() function here...
        this.bag = b;
        this.kpi.init(b);
        this.bu.init(b);
        if (!this.profileStore.p.disablePreloadAdvDashboard) {
            this.advanced.setDatabagId(this.bag.id);
        }
    }

    setBag(b: Bag) {
        // console.log('BagStore: setBag', toJS(b))
        this.set(b);

        // TODO: Remove hack (Before we used hardcodeTaxonomyId for SHV we use masterTaxonomyId)
        const currentTaxonomyId = this.profileStore.p.masterTaxonomyId
            ? this.profileStore.p.masterTaxonomyId
            : b.taxonomy;

        if (!currentTaxonomyId) {
            const errData = {bag: b.id, taxonomy: currentTaxonomyId};
            console.error('Cannot initialize review results as the current taxonomy id is missing', errData)

            stores.categorizationReviewStore.categories.arrest('No taxonomy exists')
        } else {
            stores.categorizationReviewStore.reInitializeWithTaxonomy(this.bagId, currentTaxonomyId, this.taxonomy_size, this.isSample)
        }

        stores.spendConcentrationStore.init(this.bagId, this.taxonomy_size);
    }

    changeBag(newBagId: any, navigate?: NavigateFunction, location?: Location) {
        const newBag = this.allBags?.find(b => b.id === newBagId) as Bag
        if (!newBag) {
            console.error('Cannot change bag to bagId:', newBagId)
            return;
        }
        if (navigate && location)
            navigate(generateGotoDashboardPath(location, {id: newBag.id}));
        this.setBag(newBag);
    }

    patch(b: Bag) {
        this.bag = b;
        return this.api.http.patch<Bag>(`/bag/${b.id}/`, {name: b.name})
    }

    construct(name: string): Bag {
        return {
            id: -1,
            name,
            baseline: null,
            hidden: false,
            timestamp: '',
            author: null,
            taxonomy_size: 3,
            taxonomy: null,
            latest_version: -1,
            is_sample_of: null,
            databag_samples: [],
        };
    }

    getAllBags() {
        this.$getAllBags();
    }

    $getAllBags() {
        return this.api.getAllBags().then(bags => this.setAllBags(bags));
    }

    get taxonomy_size() {
        return this.bag?.taxonomy_size || 3;
    }
}
