import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Switch
} from "@mui/material";
import './ParentSupplierToolbar.scss';
import {Clear, Refresh, Search} from "@mui/icons-material";
import {useStores} from "../../../../../stores";

type Props = {
    isParent: boolean
}
export const ParentSupplierToolbar: React.FC<Props> = observer(({isParent, children}) => {
    const {supplierNormalizationStore, p} = useStores();
    const searchTitle = isParent ? 'Enter parent supplier name' : 'Enter supplier name';
    const [value, setValue] = useState('');
    const [showStandalone, setShowStandalone] = useState(Boolean(p.p.defaultShowStandAloneSuppliers));

    return <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-start"
                 className="parent-suppliers-toolbar">
        <Grid item>
            <FormControl variant="standard" fullWidth={true} className="search-field">
                <InputLabel htmlFor="parent-supplier-search-field">
                    {searchTitle}
                </InputLabel>
                <Input
                    id="parent-supplier-search-field"
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                disabled={(value === '')}
                                onClick={() => {
                                    supplierNormalizationStore.parentSupplierFilters.setFilters([['search', '']]);
                                    setValue('');
                                }}>
                                <Clear/>
                            </IconButton>
                            <IconButton
                                aria-label="search"
                                onClick={() => {
                                    supplierNormalizationStore.parentSupplierRM.request({filters: supplierNormalizationStore.parentSupplierFilters.filters});
                                }}>
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={value}
                    onChange={e => {
                        supplierNormalizationStore.parentSupplierFilters.setFilters([['search', e.target.value]]);
                        setValue(e.target.value);
                    }}
                />
            </FormControl>
        </Grid>
        <Grid item>
            <IconButton aria-label="refresh" onClick={() => supplierNormalizationStore.refreshTableData()}>
                <Refresh/>
            </IconButton>
        </Grid>
        <Grid item>
            <FormControlLabel
                label="Show standalone suppliers"
                control={<Switch
                    color="primary"
                    checked={showStandalone}
                    onClick={() => {
                        // TODO: Duplicate state change... see https://iconof.com/best-practices-for-mobx-with-react/
                        supplierNormalizationStore.parentSupplierFilters.setFilters(
                            [['sp_n_suppliers__gt', showStandalone ? '1' : '0'], ['page', '1']]);
                        setShowStandalone(!showStandalone);
                    }}
                />}/>
        </Grid>
        {children}
    </Grid>
})
