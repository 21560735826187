import './GenericModal.scss'
import React from "react";
import {Button, Dialog, Grid, IconButton, PaperProps, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

export const GenericModalVertical: React.FC<{
    className?: string
    isOpen: boolean,
    paperProps?: Partial<PaperProps>,
    onSave: () => void
    saveDisabled?: boolean
    saveTitle?: string,
    onCancel: () => void
    onClose?: () => void
    isDanger?: true
    title: string
    titleId?: string
    isLoading?: boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}> = ({
          className,
          isOpen,
          onSave,
          saveDisabled,
          saveTitle,
          onCancel,
          onClose,
          isDanger,
          title,
          titleId,
          isLoading,
          maxWidth,
          children,
      }) => {
    titleId = titleId || 'generic-modal-vertical';
    return <Dialog
        open={isOpen}
        onClose={onClose ? onClose : onCancel}
        aria-labelledby={titleId}
        maxWidth={maxWidth || 'xs'}
        fullWidth
        className={'mithra-modal-vertical-root ' + (className ? ' ' + className : '')}>
        <div className="mithra-modal-vertical-container">
            <Grid container spacing={3}>
                <Grid item sx={{flexGrow: 1}}>
                    <Typography id={titleId} variant="h5">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onCancel}>
                        <Close/>
                    </IconButton>
                </Grid>
            </Grid>

            {children}

            <Grid container spacing={3} justifyContent="flex-end" style={{marginTop: '2em'}}>
                <Grid item>
                    <Button
                        fullWidth
                        disabled={saveDisabled || isLoading}
                        onClick={onSave}
                        variant="contained"
                        color={isDanger && isLoading ? 'error' : undefined}
                        style={isDanger && !isLoading ? {backgroundColor: '#FF3435', color: 'white'} : {}}
                    >
                        {saveTitle || 'Done'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    </Dialog>
}