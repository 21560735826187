import './MultiDropdownMenu.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {
    Badge,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    Grow,
    InputLabel,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    OutlinedInput,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AccountCircleOutlined} from "@mui/icons-material";
import {getAppEnvironment} from "../../../routing/routing.utils";
import {useAuth0} from '@auth0/auth0-react';
import {environment} from "../../../env";
import Select from "@mui/material/Select";
import {ALL_GROUPS} from "../../../services/ApiTypes";

export const MultiDropdownMenu: React.FC = observer(() => {
    const {p, authStore, approvalStore} = useStores();
    const {logout} = useAuth0();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.auth0Logout(logout);
        navigate(routes.login);
    };

    const menuId = 'multi-dropdown-menu';
    const appEnv = getAppEnvironment(location);
    const renderMenu = (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            TransitionComponent={Grow}
        >
            <ListItem title={(authStore.userId ? `[${authStore.userId}] ` : '') + authStore.email}>
                {authStore.displayUser}
            </ListItem>

            {authStore.viewOnly && <MenuItem disabled>
                <Typography variant="inherit">This account is view-only</Typography>
            </MenuItem>}

            <MenuItem disabled>
                <Typography variant="inherit">
                    Account type:
                    {authStore.customerRole}
                    {authStore.isMithraStaff ? ' (Staff)' : ''}
                </Typography>
            </MenuItem>

            {(environment.isTest || authStore.isMithraStaff) &&
                <>
                    <MenuItem disabled>
                        <Typography variant="inherit">
                            User ID: {authStore.userId}
                        </Typography>
                    </MenuItem>
                    <MenuItem disabled>
                        <div>
                            <Typography variant="inherit">
                                Environment: {'' + appEnv}
                            </Typography>
                            <Typography variant="inherit">
                                Staff: {authStore.isMithraStaff ? 'yes' : 'no'}
                            </Typography>
                            <Typography variant="inherit">
                                CustomerSuper: {authStore.isCustomerSuper ? 'yes' : 'no'}
                            </Typography>
                            <Typography variant="inherit">
                                ViewOnly: {authStore.viewOnly ? 'yes' : 'no'}
                            </Typography>
                            <MenuList>
                                <FormControl sx={{m: 1, width: 300}}>
                                    <InputLabel id="authorization-select-label">Authorization Groups</InputLabel>
                                    <Select
                                        labelId="authorization-select-label"
                                        id="authorization-select"
                                        multiple
                                        value={authStore.user?.groups || []}
                                        onChange={e => authStore.devopsSetGroups(e.target.value as string[])}
                                        input={<OutlinedInput label="Authorization Groups"/>}
                                        renderValue={(values) => values.join(', ')}
                                        // MenuProps={MenuProps}
                                    >
                                        {ALL_GROUPS.map(g =>
                                            <MenuItem key={g} value={g}>
                                                <Checkbox checked={authStore.user?.groups.includes(g) || false}/>
                                                <ListItemText primary={g}/>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </MenuList>
                        </div>
                    </MenuItem>
                </>
            }

            <Divider/>

            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                navigate(hasId1 ? generatePath(routes.single_analytics_dashboard, params) : routes.single_data_home);
            }}>
                Analytics
            </MenuItem>}
            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                navigate(hasId1 ? generatePath(routes.synergy_v3_dashboard, params) : routes.synergy_v3);
            }}>
                Synergy
            </MenuItem>}
            {authStore.isCustomerSuper &&
                <MenuItem onClick={() => {
                    handleMenuClose()
                    navigate(routes.approval);
                }}>
                    Awaiting approvals
                    {approvalStore.pendingRequests !== undefined &&
                        <Chip size="small" label={approvalStore.pendingRequests}
                              className="color-error"/>
                    }
                    (Leaves app)
                </MenuItem>
            }
            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                if (hasId1) {
                    navigate(generatePath(routes.single_looker_preview, params));
                }
            }}>
                Advanced Dashboard
            </MenuItem>}

            <Divider/>

            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return <>
        <IconButton
            edge="end"
            size="medium"
            aria-label="account"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            {
                authStore.isCustomerSuper && approvalStore.pendingRequests !== undefined
                    ? <Badge
                        badgeContent={approvalStore.pendingRequests}
                        color="error">
                        <AccountCircleOutlined/>
                    </Badge>
                    : <AccountCircleOutlined/>
            }
        </IconButton>
        {renderMenu}
    </>
})
