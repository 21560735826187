import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableContainer} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {PpvTableSubRow} from "./PpvTableSubRow";
import {getPpvSubTableColumns} from "./PpvColumns";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";


type Props = {
    groupedRow: PpvGroupRowState
};
export const PpvSubTable: React.FC<Props> = observer(({groupedRow}) => {
    const {p} = useStores();
    const parts = groupedRow.partStates
    const isLoading = groupedRow.isLoadingRows
    const columns = getPpvSubTableColumns(p);
    const tableClass = 'mithra-table x-design ppv-part-table x-design-narrow'
        + (isLoading ? ' loading' : '')
    return <TableContainer className={tableClass}>
        <Table className="sub-table" size="small">
            <MithraTableHeadColumns columns={columns}
                                    enableLoading
                                    isLoading={isLoading}
            />
            <TableBody>
                {parts?.map(part =>
                    <React.Fragment key={part.id}>
                        <PpvTableSubRow part={part} group={groupedRow}/>
                    </React.Fragment>
                )}
            </TableBody>
        </Table>
    </TableContainer>
})
