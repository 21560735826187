import {observer} from "mobx-react-lite";
import {
    Checkbox,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import React from "react";
import {MultiTypedSearchManager} from "./MultiTypedSearchManager";
import {Clear, Search} from "@mui/icons-material";

type Props = {
    id: string,
    className?: string,
    manager: MultiTypedSearchManager,
}
export const MultiTypedSearchComponent: React.FC<Props> = observer(({id, className, manager}) => {
    const searchTypeLabel = 'Search by';
    const singleOption = manager.options.length === 1 ? manager.options[0] : null;
    const hasOptions = manager.options.length > 1;

    const searchFieldLabel = hasOptions ? 'Search term' : singleOption ? `Search ${singleOption.label}` : 'Search';

    const searchString = manager.search.searchString;
    return <div
        className={'multi-typed-search-component' + (className ? ` ${className}` : '') + (hasOptions ? ' has-options' : ' has-no-options')}>

        {hasOptions &&
            <FormControl style={{marginRight: '1.5em', width: '20em'}}>
                <InputLabel>{searchTypeLabel}</InputLabel>
                <Select
                    id={id + '-type'}
                    multiple
                    value={manager.selectedKeys}
                    label={searchTypeLabel}
                    onChange={event => {
                        if (typeof event.target.value === 'string') {
                            manager.setSelectedKeys(event.target.value.split(','));
                        } else {
                            manager.setSelectedKeys(event.target.value);
                        }
                    }}
                    renderValue={keys => {
                        if (keys.length === 0) {
                            return '';
                        }
                        return manager.selectedOptions.map((o) => o.label).reverse().join(', ');
                    }}>
                    {manager.options.map(option =>
                        <MenuItem key={option.key} value={option.key}>
                            <Checkbox checked={Boolean(manager.selectedOptions.find(o => o.key === option.key))}/>
                            <ListItemText primary={option.label}/>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        }

        <FormControl
            className="typed-search-field"
            variant="standard">
            <InputLabel>{searchFieldLabel}</InputLabel>
            <Input
                id={id + '-field'}
                type="text"
                fullWidth={true}
                endAdornment={
                    <InputAdornment position="end">
                        {searchString &&
                            <IconButton
                                aria-label="clear search"
                                onClick={() => manager.search.clearSearch()}
                            >
                                <Clear/>
                            </IconButton>
                        }
                        <IconButton
                            aria-label="search"
                            onClick={() => manager.search.doSearch('click')}
                        >
                            <Search/>
                        </IconButton>
                    </InputAdornment>
                }
                value={searchString}
                onChange={e => manager.search.setSearch(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? manager.search.doSearch('enter') : undefined}
                // aria-describedby={id + '-error'}
            />
        </FormControl>
    </div>
})
