import React from "react";
import {EditorModalComponent} from "../EditorModalComponent";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Alert, Grid, TextField, Typography} from "@mui/material";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";

export const ConfirmSendForApproval: React.FC = observer(() => {
    const {p, taxonomyManagerStore, taxonomyEditorStore} = useStores();
    const approval = taxonomyEditorStore.approval;

    const incompleteCategoriesError: string = taxonomyManagerStore.taxonomy && taxonomyManagerStore.incompleteCategories.length > 0
        ? `Please make sure the following categories have categories until L${taxonomyManagerStore.taxonomy.size} to complete the taxonomy:`
        : '';

    const stateInSync = taxonomyManagerStore.stateInSync;
    const hackHideIncompleteCategoriesError: boolean = taxonomyManagerStore.taxonomy?.id === p.p.hackTaxonomyToHideApprovalError;
    console.log('hackHideIncompleteCategoriesError', hackHideIncompleteCategoriesError)

    return <EditorModalComponent
        titleId={"taxonomy-editor-modal-title"}
        className="taxonomy-modal-component confirm-send-for-approval"
        title="Send for approval?"
        saveLabel="Send"
        canSave={!approval.approvalIsBusy && stateInSync && (!incompleteCategoriesError || hackHideIncompleteCategoriesError)}
        onSave={() => approval.sendForApproval()}
        onClose={() => approval.setConfirmModalOpen(false)}
    >
        {stateInSync && incompleteCategoriesError && !hackHideIncompleteCategoriesError ?
            <>
                <Typography className="mt-3 mb-2">{incompleteCategoriesError}</Typography>
                {taxonomyManagerStore.incompleteCategories.map(cats =>
                    <Grid key={cats.join('|')} item className="mt-1">
                        <Alert severity="error"><CategoryChip labels={cats}/> is missing a level</Alert>
                    </Grid>
                )}
            </>
            : <>
                <Typography variant="body1">
                    The taxonomy cannot be changed anymore until the approval process is completed.
                </Typography>

                <TextField
                    multiline
                    fullWidth
                    id="taxonomy-editor-modal-approval-note-field"
                    label="Additional notes"
                    // error={false}
                    // helperText={}
                    variant="outlined"
                    value={approval.approvalNotes}
                    onChange={e => approval.setApprovalNotes(e.target.value)}
                />
            </>}
    </EditorModalComponent>
})
