import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import {useSitStores} from "../../../stores";
import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";
import {EchartSitDefaultColor, EchartSitDefaultLegend} from "../../../services/echarts/EchartSitCommonConfig";
import {SIT_TEST} from "../../SitApit";
import {DoiStatus} from "../../ApiTypes";

function init(echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType {
    const chart = echart.init(root, 'mithra', {
        renderer: 'svg',
        height: 600,
    });
    chart.setOption({
        tooltip: {
            trigger: 'axis',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {show: true},
            },
        },
        calculable: true,
        legend: {
            data: [...DoiStatus, 'Target'],
            ...EchartSitDefaultLegend
        },
        // Global palette:
        color: EchartSitDefaultColor,
        xAxis: {type: 'time'},
        yAxis: {},
        dataset: data,
        series: [
            {
                name: DoiStatus[4], type: 'bar',
                encode: {x: 'month', y: 'value'},
                datasetId: 'DOI5 accumulative aggregated savingItems by month',
                stack: 'saving',
                color: '#091344',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: DoiStatus[3], type: 'bar',
                encode: {x: 'month', y: 'value'},
                datasetId: 'DOI4 accumulative aggregated savingItems by month',
                stack: 'saving',
                color: '#384068',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: DoiStatus[2], type: 'bar',
                encode: {x: 'month', y: 'value'},
                datasetId: 'DOI3 accumulative aggregated savingItems by month',
                stack: 'saving',
                color: '#6f7592',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: DoiStatus[1], type: 'bar',
                encode: {x: 'month', y: 'value'},
                datasetId: 'DOI2 accumulative aggregated savingItems by month',
                stack: 'saving',
                color: '#9fa3b6',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: DoiStatus[0], type: 'bar',
                encode: {x: 'month', y: 'value'},
                datasetId: 'DOI1 accumulative aggregated savingItems by month',
                stack: 'saving',
                color: '#c3c5d1',
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: 'Target', type: 'line',
                encode: {x: 'month', y: 'target'},
                datasetId: 'Target accumulative aggregated savingItems by month',
                color: '#430944',
                emphasis: {
                    focus: 'series',
                },
            },
        ],
    })
    return chart;
}

export const TargetVsActualBar: React.FC = observer(() => {
    const {fbStore} = useSitStores();

    if (!fbStore.contributionPerDoi || !fbStore.cumulatedTargetData) {
        return <></>;
    }

    const doiData = fbStore.contributionPerDoi.reverse().map(({doi, data}) => ({
        id: `DOI${doi} accumulative aggregated savingItems by month`,
        dimensions: [
            {name: 'month', type: 'time'},
            {name: 'value', type: 'number'},
        ],
        source: data.map(row => ([
            row.month,
            row.value,
        ])),
    }));
    const targetLineData = {
        id: 'Target accumulative aggregated savingItems by month',
        dimensions: [
            {name: 'month', type: 'time'},
            {name: 'target', type: 'number'},
        ],
        source: fbStore.cumulatedTargetData.map(r => ([
            r.month,
            r.value,
        ])),
    };
    const data = doiData.concat([targetLineData])

    return <Grid container>
        <Grid item xs={12}>
            {SIT_TEST && '<TargetVsActualBar/>'}
            <MithraEchart
                // alwaysRerender={() => {
                // }}
                initChart={init}
                data={data}
                autoUpdateDataset
            />
        </Grid>
    </Grid>
})