import {makeAutoObservable} from "mobx";
import {CategorizationReviewStore} from "./CategorizationReviewStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {AxoisRequestManager} from "../managers/RequestManager";
import {from, tap} from "rxjs";
import {AiCategorizationJobCreateSerializer} from "../../services/classes/JobClasses";
import ProfileStore from "../ProfileStore";

export type UpdateCutoffData = {
    revision: number
    cutoff_value: number
    cutoff_value_l2: number
    cutoff_value_l3: number
    cutoff_value_l4: number
}

export type UpdateCutoffL1Only = {
    revision: number
    cutoff_value: number
}

/**
 * This class is responsible for the communication with the backend regarding the AI jobs
 */
export class AiCategorizationJobDelegate {

    // Devops overrides
    job_region_override: string = ''
    job_pipeline_template_name: string = this.profileStore.p.overrideAiCategorizationPipelineName || '';

    createJobRequestManager = new AxoisRequestManager<number, { id: number }>(
        databagId => {
            const job: AiCategorizationJobCreateSerializer = {
                src_data_bag: databagId,
                requested_task: 'categorize_all',

            }
            if (this.job_pipeline_template_name) {
                job.vai_pipeline_template_name = this.job_pipeline_template_name;
            }
            if (this.job_region_override) {
                job.vai_region = this.job_region_override;
            }

            return from(this.matApi.createAiJob(job)).pipe(
                tap(() => {
                    this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy');
                    this.closeRunAiModal();
                    this.categorizationReviewStore.statusDelegate.startCheckingStatus();
                })
            );
        },
    )
    createDummyRequestManager = new AxoisRequestManager<number, { id: number }>(
        databagId => from(this.matApi.createAiJob({
            src_data_bag: databagId,
            requested_task: 'categorize_dummy',
        })).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsCleanupRequestManager = new AxoisRequestManager<number, void>(
        databagId => from(this.matApi.http.post<void>(`/bag/${databagId}/devops_cleanup_categorization_results/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )

    /**
     * Future work: Maybe it makes sens to add the revision that we want to go live with.
     * Now we always go live with the latest revision only.
     */
    devopsGoLiveRequestManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/accept_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsRejectRevisionManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/reject_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsDestroyJobManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/clear_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsApplyCutoffManager = new AxoisRequestManager<[number, UpdateCutoffData], void>(
        ([jobId, data]) => from(this.matApi.http.patch<void>(`/ai_io/${jobId}/apply_cutoff/`, data)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsSimulateCutoffManager = new AxoisRequestManager<UpdateCutoffL1Only, {
        suppliers: number,
        spend: number,
        parts: number
    }>(
        ({
             cutoff_value,
             revision
         }) => {
            const params = new URLSearchParams({
                cutoff_value: String(cutoff_value),
            })
            return from(this.matApi.http.get(`/m_review/stats/live/${revision}/devops_simulate_cutoff/?${params}`));
        },
    )

    isRunAiModalOpen = false

    constructor(
        private profileStore: ProfileStore,
        private matApi: MithraMaterializedApi,
        private categorizationReviewStore: CategorizationReviewStore,
    ) {
        makeAutoObservable(this)
    }

    openRunAiModal() {
        this.isRunAiModalOpen = true
    }

    closeRunAiModal() {
        this.isRunAiModalOpen = false
    }

    setJobRegionOverride(region: string) {
        this.job_region_override = region;
    }

    setJobPipelineTemplateName(name: string) {
        this.job_pipeline_template_name = name;
    }
}