import './TaxonomyHealthCheck.scss'
import React from 'react';
import {Button, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {getCollapseAnimationTiming} from "../../components/table/utils";
import {Cancel, CheckCircle, ExpandMore, KeyboardArrowRight, Warning} from "@mui/icons-material";
import {useStores} from "../../stores";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {taxonomy_health_check} from "../../services/classes/TaxonomyHealthCheckClasses";
import ExtendedD3HierarchyNode = taxonomy_health_check.ExtendedD3HierarchyNode;
import ReviewStatusType = taxonomy_health_check.ReviewStatusType;
import APIValues = taxonomy_health_check.APIValues;


type TaxonomyTableProps = {
    columns: ColSpec[];
};

type TaxonomyRowProps = {
    d: ExtendedD3HierarchyNode;
    columns: ColSpec[];
};

export const TaxonomyHealthCheckTable: React.FC<TaxonomyTableProps> = ({columns}) => {
    const {taxonomyHealthCheckStore} = useStores();

    return (
        <TableContainer className="taxonomy-health-check-table">
            <Table>
                <MithraTableHeadColumns columns={columns}/>
                <TableBody>
                    {taxonomyHealthCheckStore.taxonomyTableController.data &&
                        <TaxonomyHealthCheckTableRow
                            d={taxonomyHealthCheckStore.taxonomyTableController.data}
                            columns={columns}
                        />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TaxonomyHealthCheckTableRow: React.FC<TaxonomyRowProps> = ({d, columns}) => {
    const {taxonomyHealthCheckStore} = useStores();

    const {children} = d;
    const label: string = d.data.viz.label
    const values: APIValues = d.data.apiValues
    const reviewStatus: ReviewStatusType | undefined = d.data.apiValues.review_status
    const [open, setOpen] = React.useState(false);
    let i = 0;

    const renderChildren = (childrenData: ExtendedD3HierarchyNode[]) => {
        return childrenData.map((childData, i) => (
            <TaxonomyHealthCheckTableRow key={i} d={childData} columns={columns}/>
        ));
    };

    const getHealthCheckType = (reviewStatus: ReviewStatusType) => {
        if (reviewStatus.rejected || reviewStatus.accepted) {
            return 'Healthy';
        } else if (reviewStatus.label_formatting) {
            return 'Label Format';
        } else if (reviewStatus.prefix_overlap) {
            return 'Prefix Overlap';
        } else if (reviewStatus.category_overlap) {
            return 'Category Overlap';
        } else {
            return 'Healthy';
        }
    }

    const getHealthCheckTypeClass = (reviewStatus: ReviewStatusType) => {
        if (reviewStatus.rejected || reviewStatus.accepted) {
            return 'Healthy';
        } else if (reviewStatus.label_formatting) {
            return 'LabelFormat';
        } else if (reviewStatus.prefix_overlap) {
            return 'PrefixOverlap';
        } else if (reviewStatus.category_overlap) {
            return 'CategoryOverlap';
        } else {
            return 'Healthy';
        }
    }


    if (d.data.viz.filtered) {
        return <></>
    }

    const indentation = `${d.depth - 1}rem`;
    const isRootNode = d.depth === 0;
    if (isRootNode && children && children.length > 0) {
        return <React.Fragment>{renderChildren(children)}</React.Fragment>;
    }


    return (
        <React.Fragment key={d.data.viz.id}>
            <TableRow>
                {/*<MithraTableCell c={columns[i++]}*/}
                {/*                 onClick={() => setOpen(!open)}>*/}
                {/*    /!*LENGTH_BUG={'' + children?.length}*!/*/}
                {/*    /!*viz.id={'' + d.data.viz.id}<br/>*!/*/}
                {/*    /!*data={'' + JSON.stringify(d.data)}*!/*/}
                {/*</MithraTableCell>*/}

                <MithraTableCell c={columns[i++]} style={{maxWidth: 'initial'}} onClick={() => setOpen(!open)}>
                    <div style={{ marginLeft: indentation }}>

                        {(children ? children.length > 0 && d.data.viz.canOpen : false) ?
                            <IconButton aria-label="expand row" size="small">
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton> :
                            <IconButton aria-label="expand row" size="small" style={{visibility: 'hidden'}}>
                                {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                            </IconButton>}

                        {d.data.viz.hasChangesInChildren ?
                            <IconButton> <Warning style={{color: '#FAA915'}}/> </IconButton> : null}

                        {label}
                    </div>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>
                    <CurrencyComponent v={d.data.apiValues?.p__spend__sum} nDigits={3}/>
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {d.data.apiValues?.p__id__count}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {d.data.apiValues?.s__id__nunique}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {reviewStatus &&
                        <Chip label={getHealthCheckType(reviewStatus)} size="medium"
                              className={'chip ' + getHealthCheckTypeClass(reviewStatus)} style={{width: '100%'}}/>}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]} >
                    {values.review_status?.ai_suggestion}
                </MithraTableCell>
                <MithraTableCell c={columns[i++]}>

                    {/*<IconButton>*/}
                    {/*    <DriveFileRenameOutline*/}
                    {/*        onClick={() => taxonomyEditorStore.setUpdateCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <CallMerge*/}
                    {/*        onClick={() => taxonomyEditorStore.setMergeCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <CompareArrows*/}
                    {/*        onClick={() => taxonomyEditorStore.setMoveCategoryMode(true)}/>*/}
                    {/*</IconButton>*/}

                    {/*<IconButton>*/}
                    {/*    <Delete*/}
                    {/*        onClick={() => taxonomyEditorStore.isDeleteCategoryModalOpen = true}/>*/}
                    {/*</IconButton>*/}

                    <div className='hc-btn-group'>
                    {(reviewStatus?.label_formatting || reviewStatus?.prefix_overlap || reviewStatus?.category_overlap) && (!reviewStatus?.rejected && !reviewStatus?.accepted) && <Button
                        className='acc-rej-edit'
                        startIcon={<CheckCircle />}
                        disabled={reviewStatus?.category_overlap?true:false}
                        onClick={() => {if(reviewStatus) {taxonomyHealthCheckStore.handleAccept(d, reviewStatus)}}}>
                    </Button>}

                    {(reviewStatus?.label_formatting || reviewStatus?.prefix_overlap || reviewStatus?.category_overlap) && (!reviewStatus?.rejected && !reviewStatus?.accepted) && <Button
                        className='acc-rej-edit'
                        startIcon={<Cancel />}
                        disabled={reviewStatus?.category_overlap?true:false}
                        onClick={() =>  {if(reviewStatus) {taxonomyHealthCheckStore.handleReject(d, reviewStatus)}}}>
                    </Button>}

                    {/* <Button
                        className='acc-rej-edit'
                        startIcon={<Edit />}
                        onClick={() => {
                            taxonomyHealthCheckStore.toggleModifyCategoryModal(true)
                            taxonomyHealthCheckStore.setCategoryToModify(d)
                        }}>
                    </Button> */}
                    </div>

                </MithraTableCell>
            </TableRow>
            <TableRow className={open ? 'is-open' : ''}>
                <TableCell colSpan={columns.length} style={{padding: '0'}}>
                    {children && children.length > 0 && children.length &&
                        <Collapse in={open} timeout={getCollapseAnimationTiming(children)} unmountOnExit>
                            <Table>{renderChildren(children)}</Table>
                        </Collapse>}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
