import React from "react";
import './IconStyles.scss';

export const StarIcon: React.FC = () =>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_160_862)">
            <rect width="48" height="48" rx="24" fill="#FAA915"/>
            <g clipPath="url(#clip1_160_862)">
                <path
                    d="M24 31.0267L32.24 36.0001L30.0534 26.6267L37.3334 20.3201L27.7467 19.5067L24 10.6667L20.2534 19.5067L10.6667 20.3201L17.9467 26.6267L15.76 36.0001L24 31.0267Z"
                    fill="white"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_160_862">
                <rect width="48" height="48" rx="24" fill="white"/>
            </clipPath>
            <clipPath id="clip1_160_862">
                <rect width="32" height="32" fill="white" transform="translate(8 8)"/>
            </clipPath>
        </defs>
    </svg>
