import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTablePaginationActions} from "../../../../components/table-pagination/MithraTablePaginationActions";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {ParentSupplierReviewMainRow} from "./ParentSupplierReviewMainRow";
import {MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import {PARENT_SUPPLIER_COLUMNS} from "./columns";
import {findAbbreviation} from "../../../../components/currency-component/CurrencyClasses";

export const ParentSupplierReviewMainTable: React.FC = observer(() => {
    const {supplierNormalizationStore} = useStores();

    useEffect(() => {
        if (!supplierNormalizationStore.parentSupplierRM.result && !supplierNormalizationStore.parentSupplierRM.busy)
            supplierNormalizationStore.parentSupplierRM.request({filters: supplierNormalizationStore.parentSupplierFilters.filters});
    }, [supplierNormalizationStore.parentSupplierRM, supplierNormalizationStore.parentSupplierFilters])

    return <TableContainer className={'mithra-table x-design parent-supplier-review-table' +
            (supplierNormalizationStore.parentSupplierRM.busy ? ' loading' : '')}>
        <Table>
            <MithraTableHeadColumns columns={PARENT_SUPPLIER_COLUMNS} enableLoading
                                    isLoading={supplierNormalizationStore.parentSupplierRM.busy}/>
            <TableBody>
                {supplierNormalizationStore.parentSupplierRM.result ? <>
                            {supplierNormalizationStore.parentSupplierRM.result.results.map(row =>
                                    <React.Fragment key={row.id}>
                                        <CurrencyAbbreviationContext.Provider value={findAbbreviation(row.sp_total_spend)}>
                                            <ParentSupplierReviewMainRow row={row}/>
                                        </CurrencyAbbreviationContext.Provider>
                                    </React.Fragment>)}
                            {supplierNormalizationStore.parentSupplierRM.result.results.length === 0 &&
                                    <TableRow className="no-data">
                                        <TableCell colSpan={PARENT_SUPPLIER_COLUMNS.length}>
                                            No data
                                        </TableCell>
                                    </TableRow>
                            }
                        </>
                        : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                            colSpan={PARENT_SUPPLIER_COLUMNS.length}
                            count={supplierNormalizationStore.parentSupplierRM.result?.count ?? 0}
                            rowsPerPage={parseInt(supplierNormalizationStore.parentSupplierFilters.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                            page={parseInt(supplierNormalizationStore.parentSupplierFilters.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                            onPageChange={(event, page: number) => {
                                supplierNormalizationStore.parentSupplierFilters.setFilters([['page', (page + 1).toString()]]);
                            }}
                            onRowsPerPageChange={(event) => {
                                supplierNormalizationStore.parentSupplierFilters.setFilters([['page_size', (event.target.value).toString()]], false);
                                supplierNormalizationStore.parentSupplierFilters.setFilters([['page', '1']]);
                            }}
                            ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
