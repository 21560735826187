import React from "react";
import {ContractData, showContractFieldValue} from "../classes";
import {Divider, Grid, Typography} from "@mui/material";
import {ContractFieldSpec} from "../data/ContractFields";
import {ContractViewFieldValue} from "./ContractViewFieldValue";

type Props = {
    title: string;
    icon: React.ReactNode | null;
    data: ContractData | undefined
    fields: ContractFieldSpec[]
}
export const ContractViewDataBlock: React.FC<Props> = ({title, icon, data, fields}) => {
    if (fields.length === 0) return <></>;
    const values = fields
        .map(spec => ({spec, values: showContractFieldValue(data, spec)}))
        .filter(v => v.values.length > 0);
    if (values.length === 0) return <></>;

    return <Grid
        className="modal-row"
        container
        style={{
            margin: '1em 0',
            padding: '1em',
            background: '#F6F6F6',
            borderRadius: '5px',
            border: '1px groove #D1D6DD'
        }}>
        <Grid item xs={1} className="row-icon">
            {icon}
        </Grid>
        <Grid item xs={11} className="row-title">
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className="row-content">
            {values.map(({spec, values}, i, array) =>
                <React.Fragment key={spec.field}>
                    <ContractViewFieldValue spec={spec} values={values}/>
                    {i < array.length - 1 ? <Divider/> : null}
                </React.Fragment>
            )}
        </Grid>
    </Grid>
};
