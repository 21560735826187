import {observer} from "mobx-react-lite";
import {
    Box,
    Checkbox,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import * as d3 from "d3";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {styled} from "@mui/material/styles";

type HeaderSpecification = {
    key: string;
    label: string;
    type: string;
};

type Props = {
    headers: Array<HeaderSpecification>;
    className?: string;
    data: any[] | undefined;
    isLoading?: boolean;
    pageSize?: number;
    pageSizeOptions?: Array<number>;
    container?: boolean;
    notEditable?: true;
    onSelect?: (d: any) => void;
};

export const BcrTable: React.FC<Props> = observer(
    ({
         headers,
         className,
         data,
         isLoading,
         pageSize,
         pageSizeOptions,
         container,
         notEditable,
         onSelect,
     }) => {
        const containerComponent = container ? Paper : 'div';
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 8);
        const [rowsPerPageOptions] = React.useState(pageSizeOptions || [8, 15, 25, 50]);

        // Reset the current page number, when data changes
        useEffect(() => {
            if (page === 0) return;
            if (isLoading) setPage(0);
            const maxPage = Math.floor((data?.length || 0) / rowsPerPage);
            if (page > maxPage) {
                setPage(0);
            }
        }, [data?.length, rowsPerPage, page, isLoading]);

        const colorScalesMap: { [key: string]: { positive: any; negative: any } } = {};
        headers
            .filter((h) => h.type === 'currency')
            .forEach((h) => {
                const values: number[] = data?.map((d) => d[h.key]) || [];
                colorScalesMap[h.key] = {
                    positive: d3.scaleLinear([0, Math.max(1, ...values)], ['whitesmoke', '#193150']),
                    negative: d3.scaleLinear([0, Math.min(-1, ...values)], ['whitesmoke', '#E62D15']),
                };
            });

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
        ) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const tableClass =
            'bcr-table-wrapper' + (className ? ` ${className}` : '') + (isLoading ? ' loading' : '');
        return (
            <TableContainer component={containerComponent} className={tableClass}>
                <Table cellPadding={0}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header.key}>{header.label}</TableCell>
                            ))}
                        </TableRow>
                        <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                            <td colSpan={headers.length}>
                                {isLoading && <LinearProgress variant="indeterminate"/>}
                            </td>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []
                                : data || []
                        ).map((item) => (
                            <TableRow
                                key={JSON.stringify(item)}
                                className="advanced-table-row"
                                onClick={() => {
                                    setPage(0);
                                }}
                            >
                                {headers.map((header) => {
                                    let value = item[header.key];
                                    let cellNumberClass = '';
                                    let className = '';
                                    let style = {};
                                    let cellContent: JSX.Element;
                                    switch (header.type) {
                                        case 'currency':
                                            cellContent = <CurrencyComponent v={value}/>;
                                            cellNumberClass = 'fit-number';
                                            break;
                                        case '2_decimal':
                                            cellContent = <>{(Math.round(value * 100) / 100).toFixed(2)}</>;
                                            break;
                                        case 'category':
                                            cellContent = (
                                                <>{value === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : value}</>
                                            );
                                            break;
                                        case 'selection':
                                            if (notEditable) {
                                                cellContent = <></>;
                                                break;
                                            } else {
                                                cellContent = (
                                                    <Checkbox
                                                        checked={item.selected}
                                                        onChange={(e) => {
                                                            if (!onSelect) return;
                                                            onSelect(item);
                                                        }}
                                                    />
                                                );
                                                break;
                                            }
                                        default:
                                            cellContent = <>{value}</>;
                                            break;
                                    }
                                    if (colorScalesMap[header.key]) {
                                        const {positive, negative} = colorScalesMap[header.key];
                                        const bgColor = value >= 0 ? positive(value) : negative(value);
                                        const fgColor = d3.hsl(bgColor).l > 0.5 ? 'black' : 'white';
                                        style['backgroundColor'] = bgColor;
                                        style['color'] = fgColor;
                                    }
                                    return (
                                        <td
                                            key={header.key}
                                            className={className + ' advanced-table-cell'}
                                            style={style}
                                        >
                                            <Typography className={cellNumberClass}>{cellContent}</Typography>
                                        </td>
                                    );
                                })}
                            </TableRow>
                        ))}
                        {(!data || data.length === 0) && (
                            <TableRow>
                                <TableCell colSpan={headers.length} align="center" style={{height: '100px'}}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minHeight: '25em'
                                    }}>
                                        {CustomNoRowsOverlay()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                colSpan={headers.length}
                                count={data?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                showFirstButton={true}
                                showLastButton={true}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        );
    }
);

// FIXME: Refactor to avoid styled components of PR, this is done just to deliver in time
const StyledGridOverlay = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

// FIXME: Refactor to avoid styled components of PR, this is done just to deliver in time
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"/>
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"/>
                    </g>
                </g>
            </svg>
            <Box sx={{mt: 1}}>No data</Box>
        </StyledGridOverlay>
    );
}
