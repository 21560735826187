import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {TextField} from "@mui/material";

type Props = {}
export const TaxonomyUploadForm: React.FC<Props> = observer(() => {
    const {taxonomyIngestionStore} = useStores();

    const handleHeadersRowChange = (event) => {
        const value = event.target.value;
        const headerRow = Number(value);

        if (!isNaN(headerRow) && headerRow > 0) {
            taxonomyIngestionStore.setFileHeaderRow(headerRow);

            if (headerRow >= taxonomyIngestionStore.taxonomyFileDataRow) {
                taxonomyIngestionStore.setFileDataRow(headerRow + 1);
            }
        } else {
            taxonomyIngestionStore.setFileHeaderRow(1);
        }
        taxonomyIngestionStore.setErrorMsg('');
    };
    const handleStartingRowChange = (event) => {
        const value = event.target.value;
        const numericValue = Number(value);

        if (!isNaN(numericValue) && numericValue > 0) {
            taxonomyIngestionStore.setFileDataRow(numericValue);

            if (taxonomyIngestionStore.taxonomyFileHeaderRow && taxonomyIngestionStore.taxonomyFileHeaderRow >= numericValue) {
                taxonomyIngestionStore.setErrorMsg('Headers row must be lower than the starting row.');
            } else {
                taxonomyIngestionStore.setErrorMsg('');
            }
        } else {
            taxonomyIngestionStore.setFileDataRow(1);
            taxonomyIngestionStore.setErrorMsg('');
        }
    };
    return <div className="taxonomy-upload-form">
        <TextField
            fullWidth
            className="taxonomy-name-field"
            label="Taxonomy name"
            variant="outlined"
            value={taxonomyIngestionStore.taxonomyName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                taxonomyIngestionStore.setTaxonomyName(event.target.value);
            }}
            sx={{mt: 2}}
        />
        <TextField
            fullWidth
            className="taxonomy-header-row-field"
            label="Row of the headers"
            variant="outlined"
            value={taxonomyIngestionStore.taxonomyFileHeaderRow}
            onChange={handleHeadersRowChange}
            helperText="The row number where the headers of the table are located."
            sx={{mt: 2}}
        />
        <TextField
            fullWidth
            className="taxonomy-data-row-field"
            label="First data row"
            variant="outlined"
            value={taxonomyIngestionStore.taxonomyFileDataRow}
            onChange={handleStartingRowChange}
            helperText="The row number where the first line of data is located."
            sx={{mt: 2}}
        />
    </div>
});