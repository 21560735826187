import {makeAutoObservable, reaction, runInAction} from "mobx";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {AxiosResponse, isAxiosError} from "axios";
import {catchError, EMPTY, forkJoin, from, mergeMap, Observable, of, Subscription, tap} from "rxjs";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagLoadingStore} from "./BagLoadingStore";
import {AxoisRequestManager} from "./managers/RequestManager";
import TaxonomyEditorStore from "./TaxonomyEditorStore";
import {environment} from "../env";

export type HistoryState = 'updating_history'
    | 'applying_action'
    | 'ready'

/**
 * For controlling the taxonomy
 */
export default class TaxonomyManagerStore {
    private getSubscription?: Subscription;

    /**
     * A specific taxonomy that is required, not related to the databag
     */
    desiredTaxonomyId?: number | null;

    taxonomyApiName = '';
    taxonomy: m_taxonomy.FullSerializer | undefined | null //undefied means it's loading, null means it's not found
    error: string = '';

    historyState: HistoryState = 'ready';
    history: m_taxonomy.SimpleTaxonomyOperationSerializer[] | undefined;

    notification: string = '';
    notification_type: 'info' | 'success' = 'info';
    allTaxonomyList: m_taxonomy.SimpleSerializer[] | undefined = undefined

    taxonomyEditorStore: TaxonomyEditorStore = undefined as any as TaxonomyEditorStore;

    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private bagLoadingStore: BagLoadingStore,
        private authStore: AuthStore,
        private profileStore: ProfileStore,
    ) {
        if (this.profileStore.p.hardcodeTaxonomyId) {
            this.desiredTaxonomyId = this.profileStore.p.hardcodeTaxonomyId;
        }
        makeAutoObservable(this)
        reaction(() => [bagStore.bagId, this.desiredTaxonomyId, this.bagStore.bag?.taxonomy] as const,
            ([bagId, desiredTaxonomyId, databagTaxonomy]) => {
                console.debug(`TaxonomyManagerStore: Bag or taxonomy changed`, {
                    bagId,
                    desiredTaxonomyId,
                    databagTaxonomy
                })

                //Rest the state
                if (desiredTaxonomyId) {
                    this.requestTaxonomy(desiredTaxonomyId)
                } else if (bagId && databagTaxonomy) {
                    this.requestTaxonomy(databagTaxonomy)
                } else {
                    this._setTaxonomy(null)
                    this.history = undefined
                }
            })
    }

    setDesiredTaxonomyId(taxonomyId?: number | null) {
        this.desiredTaxonomyId = taxonomyId;
    }

    requestTaxonomyList() {
        this.api.listMTaxonomy()
            .then(r => {
                    this.allTaxonomyList = r.data;
                }
            )
    }

    //this might need to be inverted
    setTaxonomyInDatabag(taxonomyId: number) {
        this.api.setDatabagForTaxonomy(this.bagStore.bagId, {taxonomy: taxonomyId}).then(() =>
            this.bagLoadingStore.loadBag(this.bagStore.bagId)
        );
    }

    requestTaxonomy(taxonomyId: number) {
        if (this.getSubscription) this.getSubscription.unsubscribe()
        this.taxonomy = undefined;
        this.taxonomyApiName = '';

        let getTaxonomyId: Observable<number>
        getTaxonomyId = of(taxonomyId)
        this.getSubscription = getTaxonomyId
            .pipe(
                mergeMap(taxonomyId => forkJoin([
                    from(this.api.getTaxonomy(taxonomyId)).pipe(tap(
                        r => this._setTaxonomy(r.data)
                    )),
                    from(this.api.listMTaxonomyHistory(taxonomyId)).pipe(tap(
                        r => runInAction(() => this.history = r.data)
                    )),
                ])),
                catchError(err => {
                    // console.error(err, {bag: bagId, taxonomy: taxonomyId})
                    runInAction(() => {
                        this._setTaxonomy(undefined)
                        this.history = undefined
                        this.error = String(err)
                    })
                    return EMPTY
                }),
            )
            .subscribe({
                next: () => this.setError(''),
            })
    }

    _setTaxonomy(taxonomy: m_taxonomy.FullSerializer | undefined | null) {
        this.taxonomy = taxonomy;
        this.taxonomyApiName = taxonomy?.name || '';
        this.notification = '';
    }

    setNotification(notification: string, type: 'info' | 'success') {
        this.notification = notification;
        this.notification_type = type;
    }

    get minHistoryNumber() {
        if (!this.history) return -1
        return Math.min(...this.history.map(h => h.operation_number))
    }

    get maxHistoryNumber() {
        if (!this.history) return -1
        return Math.max(...this.history.map(h => h.operation_number))
    }

    get stateInSync(): boolean {
        return this.historyState === 'ready';
    }

    get incompleteCategories(): string[][] {
        if (!this.profileStore.p.allowIncompleteTaxonomy && this.taxonomy) {
            return TaxonomyManagerStore.getIncompleteCategories([this.taxonomy.diff_tree], this.taxonomy.size)
                .map(path => path.slice(1).map(t => t.label))
                .sort()
        }
        return [];
    }

    private static getIncompleteCategories(path: m_taxonomy.Tree[], taxonomySize: number): m_taxonomy.Tree[][] {
        let incompleteNodes: m_taxonomy.Tree[][] = []
        const level = path.length - 1;
        if (level <= taxonomySize - 1) {
            const tree = path[path.length - 1];
            if (tree.children.length === 0) {
                return [path];
            }
            tree.children.forEach(c => {
                incompleteNodes.push(...TaxonomyManagerStore.getIncompleteCategories([...path, c], taxonomySize))
            })
        }
        return incompleteNodes;
    }

    undo() {
        if (!this.taxonomy) return
        this.gotoHistoryState(this.taxonomy.current_operation_number - 1)
    }

    /**
     * Shows if the user can undo
     * Also it shows if there exists a change to actually undo
     */
    get undoAllowed() {
        return this.hasChange;
    }

    get hasChange() {
        if (this.historyState !== 'ready') return false
        if (this.canEditTaxonomy && this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number > this.minHistoryNumber
        }
        return false
    }

    redo() {
        if (!this.taxonomy) return
        this.gotoHistoryState(this.taxonomy.current_operation_number + 1)
    }

    get redoAllowed() {
        if (this.historyState !== 'ready') return false
        if (this.canEditTaxonomy && this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number < this.maxHistoryNumber
        }
        return false
    }

    get isLatest() {
        if (this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number === this.maxHistoryNumber
        }
        return true
    }

    /**
     * Get a list of operations that have happened before, including the state before the operation (to revert it)
     */
    get undoHistory(): { operation: m_taxonomy.SimpleTaxonomyOperationSerializer, undoNumber: number }[] {
        if (!this.history || !this.taxonomy) return []
        const currentOperationNumber = this.taxonomy.current_operation_number;
        const firstAction = this.history[0]
        const undoHistory = this.history
            .filter((operation, index) =>
                // The first operation can never be undone
                // All operations should have happened before, so including the current operation_number
                index >= 1 && operation.operation_number <= currentOperationNumber
            )
            .map((operation, index, array) => {
                // If we want to undo an action, we should goto the state before that actions
                const before = index === 0 ? firstAction : array[index - 1]
                return {
                    operation,
                    undoNumber: before.operation_number,
                }
            })
        // Show the most recent operation as the first
        undoHistory.reverse()
        return undoHistory
    }

    get undoContainsOtherUser() {
        return this.undoHistory.some(({operation}) => operation.author && operation.author.id !== this.authStore.userId)
    }

    get redoContainsOtherUser() {
        return this.redoHistory.some(operation => operation.author && operation.author.id !== this.authStore.userId)
    }

    get redoHistory() {
        if (!this.history || !this.taxonomy) return []
        const currentOperationNumber = this.taxonomy?.current_operation_number || 1;
        return this.history.filter(h => h.operation_number > currentOperationNumber)
    }

    gotoHistoryState(number: number) {
        if (this.historyState !== 'ready') return;
        if (!this.taxonomy) return
        const taxonomyId = this.taxonomy.id;
        this.historyState = 'updating_history'
        this.api.gotoMTaxonomyHistory(taxonomyId, {goto_history_number: number})
            .then(r => runInAction(() => {
                this._setTaxonomy(r.data)

                // Retrieve the next history elements
                return this.api.listMTaxonomyHistory(taxonomyId)
                    .then(r => runInAction(() => this.history = r.data))
            }))
            .finally(() => runInAction(() => {
                this.historyState = 'ready'
            }))
    }

    onChangeTaxonomy(operation: m_taxonomy.Operation, newState: m_taxonomy.Tree) {
        if (!this.taxonomy) return
        console.log('Updating taxonomy to API', operation)
        const taxonomyId = this.taxonomy.id;

        let promise: Promise<AxiosResponse<m_taxonomy.FullSerializer>>;
        const nextOperation: m_taxonomy.CreateTaxonomyOperationSerializer = {
            next_node_id: this.taxonomy.next_node_id + 1,
            operation_number: this.taxonomy.current_operation_number + 1,
            operation_name: operation.valueOf(),
            state: newState,
        };
        promise = this.api.storeTaxonomyState(taxonomyId, nextOperation)

        this.historyState = 'applying_action'

        return promise
            .then(r => {
                this.error = '';
                const taxonomy = r.data;
                runInAction(() => {
                    this._setTaxonomy(taxonomy)
                    this.history = this.history?.filter(h => h.operation_number < taxonomy.current_operation_number)
                })
                return this.api.listMTaxonomyHistory(taxonomyId)
                    .then(r => runInAction(() => this.history = r.data))
            })
            .catch(err => {
                console.error(err)
                if (isAxiosError(err) && err.response) {
                    if (err.response.data.message) {
                        this.setError('Cannot change the taxonomy. ' + err.response.data.message)
                        return;
                    } else if (err.response.data.operation_number) {
                        this.setError('Cannot change the taxonomy due to a synchronization issue. Please refresh the page.')
                        return;
                    }
                }
                this.setError('Cannot change the taxonomy')
            })
            .finally(() => runInAction(() => {
                this.historyState = 'ready'
            }))
    }

    get canEditTaxonomy() {
        if (!this.taxonomy) return false;
        if (this.authStore.isMithraStaffGroup) return true;
        if (this.profileStore.p.taxonomyBuilderViewOnly) return false;
        return m_taxonomy.canEditTaxonomy(this.taxonomy.active_approval_request?.current_status.status)
    }

    get canGoToApproval() {
        return this.canEditTaxonomy
            && !this.authStore.viewOnly
            && this.stateInSync
            && (
                environment.package === 'merge_x'
                    ? false // [CAT-964] temporary disable send for approval for merge_x
                    : environment.package === 'sales_demo'
                        ? true // Sales demo always allows a send for approval
                        : this.hasChange // There must be a change to send it for approval
            )
    }

    overwriteTaxonomyHealthCheckResult(has_taxonomy_health_check: boolean) {
        if (this.taxonomy) {
            // It must become true the next time we query the backend
            this.taxonomy.has_taxonomy_health_check = has_taxonomy_health_check;
        }
    }

    readonly _updateTaxonomyRequest = new AxoisRequestManager<{
        taxonomyId: number, data: Partial<m_taxonomy.UpdateSerializer>
    }, m_taxonomy.UpdateSerializer>(({taxonomyId, data}) =>
        from(this.api.updateTaxonomy(taxonomyId, data)).pipe(
            tap(resp => runInAction(() => {
                if (!this.taxonomy) return;
                this.taxonomyApiName = resp.data.name;
                this.taxonomy.name = resp.data.name;
                this.taxonomy.size = resp.data.size;
            }))
        )
    )

    setTaxonomyName(name: string) {
        if (this.taxonomy) {
            this.taxonomy.name = name;
        }
    }

    storeTaxonomyName() {
        if (!this.taxonomy) return;
        const name = this.taxonomy.name;
        if (name !== this.taxonomyApiName) {
            this._updateTaxonomyRequest.request({taxonomyId: this.taxonomy.id, data: {name}})
        }
    }

    setError(error: string) {
        this.error = error;
    }

    updateTaxonomySize(taxonomySize: number) {
        if (!this.taxonomy?.id) return;
        this._updateTaxonomyRequest.request({taxonomyId: this.taxonomy.id, data: {size: taxonomySize}})
    }
}
