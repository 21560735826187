export function showRiskString(riskValue: number) {
    if (riskValue >= 1 && riskValue < 2) {
        return 'Low'
    }
    if (riskValue >= 2 && riskValue < 3) {
        return 'Moderate'
    }
    if (riskValue >= 3) {
        return 'High'
    }
    return 'None'
}


export function showImpactString(impactValue: number) {
    switch (impactValue) {
        default:
        case 0:
            return 'None'
        case 1:
            return 'Low'
        case 2:
            return 'Moderate'
        case 3:
            return 'High'
    }
}

export function showProbabilityString(probabilityString: number) {
    switch (probabilityString) {
        default:
        case 0:
            return 'Impossible'
        case 1:
            return 'Unlikely'
        case 2:
            return 'Likely'
        case 3:
            return 'Very likely'
    }
}

export function getRiskDegreeBackgroundColor(riskValue: number) {
    if (riskValue >= 1 && riskValue < 2) {
        // Low
        return '#bfe3d8'
    }
    if (riskValue >= 2 && riskValue < 3) {
        // Moderate
        return '#f9e6be'
    }
    if (riskValue >= 3) {
        // High
        return '#f5c4c3'
    }
    return ''; // Default background color if risk degree is not recognized
}