import React from "react";
import {List, ListItem} from "@mui/material";

type Props = {
    v: any[] | any,
    render?: (v: any) => JSX.Element,
}
export const FlexibleLists: React.FC<Props> = ({v, render}) => {
    const show = render ? render : String;
    if (!Array.isArray(v)) {
        return <>{show(v)}</>
    }
    return <List>
        {v.map(v =>
            <ListItem key={String(v)}>
                <FlexibleLists v={v} render={render}/>
            </ListItem>
        )}
    </List>
}