import ChatController from "./ChatController";
import React from "react";
import {observer} from "mobx-react-lite";
import {ChatInput} from "./ChatInput";

export const HeaderChatBoxComponent = observer(() => {
    if (ChatController.overlayOpened) return <></>;
    return <div style={{maxWidth: '400px'}}>
        <ChatInput onClick={() => ChatController.setOverlayOpen(true)}/>
    </div>
})