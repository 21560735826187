import {GroupedRowState} from "../classes/GroupedRowState";
import {makeAutoObservable, reaction} from "mobx";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import {PartRowState} from "../classes/PartRowState";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {C} from "../../../configurations/active-configuration";

export class CategorizationReviewPageSelectionController {

    /**
     * The label for "part" (plural by appending "s")
     */
    public static readonly PART_NAME = 'transaction'

    public static readonly SUPPLIER_NAME = C.profile.s_col_name || 'supplier';

    /**
     * If everything is selected
     * including the items not visible in the current page
     */
    isAllSelected = false;

    lastSelected: PartRowState | undefined = undefined;

    /**
     * The page size
     */
    pageSize = 0;
    /**
     * Total elements in the backend
     */
    nTotal: number | null = null;

    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private matApi: MithraMaterializedApi,
    ) {
        makeAutoObservable(this);

        reaction(() => ({
            singleMode: this.categorizationReviewPageController.singleMode,
            partPageSize: this.dataController.partPages.pageSize,
            partCount: this.dataController.partPages.count,
            supplierPageSize: this.dataController.supplierPages.pageSize,
            supplierCount: this.dataController.supplierPages.count,

            // Ensure we also trigger selection reset when the data changes
            _partData: this.dataController.partPages.data,
            _supplierData: this.dataController.supplierPages.data,
        }), ({singleMode, partPageSize, partCount, supplierPageSize, supplierCount}) => {
            // The data or the view has changed
            if (singleMode) {
                this.pageSize = partPageSize;
                this.nTotal = partCount;
            } else {
                this.pageSize = supplierPageSize;
                this.nTotal = supplierCount;
            }
            this.isAllSelected = false;
        })
    }

    get dataController() {
        return this.categorizationReviewPageController.reviewPageDataController;
    }

    get hasSelectableElementsOutsideView(): boolean {
        if (this.nTotal === null) return false;
        return this.nTotal > this.pageSize;
    }

    get isAllInViewSelected(): boolean {
        if (this.nSelectablesInView === 0) return false;
        return this.nInViewSelected === this.nSelectablesInView;
    }

    /**
     * The total number of (partially) selected rows
     */
    get nPartialSelected(): number {
        if (this.isAllSelected) {
            return this.nTotal || 0;
        }
        return this.nInViewSelected;
    }

    /**
     * All the elements are selected
     * or partially selected (in the grouped mode)
     */
    get nInViewSelected() {
        const singleMode = this.categorizationReviewPageController.singleMode;
        if (singleMode) {
            return this.selectedPartsInView.length;
        } else {
            return this.selectedSuppliersInView.length;
        }
    }

    get isAnySelected() {
        return this.isAllSelected || this.nSelected > 0;
    }

    get selectionState(): 'all' | 'some' | 'none' {
        if (this.isAllSelected) {
            return 'all';
        }
        if (this.nSelected === 0) {
            return 'none';
        }
        return 'some';
    }

    /**
     * The effective selection size
     */
    get nSelected(): number {
        if (this.isAllSelected) {
            if (this.nTotal === null) {
                console.warn('nTotal is not expected to be null')
                return 0;
            }
            return this.nTotal;
        }
        return this.nInViewSelected;
    }

    /**
     * Get the total number of groups that are completely selected
     */
    get nFullySelectedSuppliers(): number {
        const singleMode = this.categorizationReviewPageController.singleMode;
        if (this.isAllSelected || singleMode) {
            return this.nSelected;
        }
        return this.fullySelectedSuppliersInView.length;
    }

    /**
     * The number of parts selected individually in the grouped mode
     */
    get nSelectedParts(): number {
        const singleMode = this.categorizationReviewPageController.singleMode;
        if(singleMode) {
            return 0;
        }
        const sum = this.dataController.supplierPages.data?.reduce((acc, r) => {
            if(r.isAllSelected) {
                // Skip
            } else {
                acc += r.nInViewSelected;
            }
            return acc;
        }, 0) ?? 0;
        return sum;
    }

    /**
     * When the user changes the selection of a supplier group
     * @param groupedRow
     * @param selected
     */
    onSetSelectionOfSupplierGroup(groupedRow: GroupedRowState, selected: boolean) {
        groupedRow.isAllSelected = selected;
        groupedRow.partStates?.forEach(p => p.selected = selected);

        this.reCalcAllSelectedRoot(selected);
    }

    reCalcAllSelectedRoot(newSelection: boolean) {
        if (!newSelection) {
            // A part is deselected
            this.isAllSelected = false;
        } else {
            // A part is selected
            if (this.hasSelectableElementsOutsideView) {
                // We do not allow the user to select parts outside the view indirectly
                // This needs to be done by selecting everything explicitly
                this.isAllSelected = false;
            } else {
                this.isAllSelected = this.isAllInViewSelected;
            }
        }
    }

    /**
     * When the user changes the selection of the entire table
     * @param selected
     */
    public onSetAllSelected(selected: boolean) {
        this.isAllSelected = selected;
        const singleMode = this.categorizationReviewPageController.singleMode;
        if (singleMode) {
            this.dataController.partPages.data?.forEach(p => p.selected = selected);
        } else {
            this.dataController.supplierPages.data?.forEach(r => {
                r.isAllSelected = selected;
                r.partStates?.forEach(p => p.selected = selected);
            })
        }
    }

    onDeselectAll() {
        this.onSetAllSelected(false);
    }

    onClickToggleAllSelected() {
        switch (this.selectionState) {
            case 'none':
                this.onSetAllSelected(true)
                break;
            case 'some':
                this.onSetAllSelected(true)
                break
            case 'all':
                // Everything is already selected so simply reset the filter
                this.onSetAllSelected(false);
                break
        }
    }

    /**
     * Set or reset a selection by not interacting with the selection component
     * @param subRow
     * @param selected
     */
    setSelectionSubRow(subRow: PartRowState, selected: boolean) {
        subRow.selected = selected;
        if (subRow.parentRow) {
            subRow.parentRow.reCalcAllSelected(selected);
        }
        this.reCalcAllSelectedRoot(selected);
    }

    /**
     * The user interacts with the selection through the selection component
     *
     * It remembers the last selection and has the option to select all between the last selection and this selection
     * @param subRow
     * @param allBetween
     */
    onClickToggleSelectionSubRow(subRow: PartRowState, allBetween: boolean = false) {
        if (!allBetween) {
            this.setSelectionSubRow(subRow, !subRow.selected);
            return;
        }

        // Collect statistics on how much this is used
        console.warn('onClickToggleSelectionSubRow clicked with allBetween ', subRow.id)
        // This functionality is of the spec, as this is too complex and we do not really use it

        // Unselect all parents
        this.resetSupplierSelection();

        console.log('toggleSelectionSubRow', subRow.id);
        const prevLastSelected = this.lastSelected;

        // Check if the last selection makes sense or not
        if (!prevLastSelected) {
            console.warn('No previous selection found');
            return;
        }
        // Check if we can find the index
        const data = this.dataController.allPartRows;
        if (!data || data.length === 0) {
            console.warn('No data found');
            return;
        }
        const prevIndex = data.findIndex(p => p === prevLastSelected);
        if (prevIndex === -1) {
            console.warn('Previous selection not found in the current page');
            return;
        }
        // Check if we can find the index of this selection
        const thisIndex = data.findIndex(p => p === subRow);
        if (thisIndex === -1) {
            console.warn('This selection not found in the current page');
            return;
        }

        const parts = data.slice(Math.min(prevIndex, thisIndex), Math.max(prevIndex, thisIndex) + 1);
        this.toggleMultipleParts(parts);

        // if (prevLastSelected) {
        //     prevLastSelected.isLastSelected = false;
        // }
        // subRow.isLastSelected = true;
        this.lastSelected = subRow;
    }

    toggleMultipleParts(subRows: PartRowState[]) {
        const selected = !subRows.every(p => p.selected);
        subRows.forEach(subRow => {
            subRow.selected = selected;
        })
        this.reCalcAllSelectedRoot(selected);
        return selected;
    }

    get selectedPartsInView() {
        const singleMode = this.categorizationReviewPageController.singleMode;
        console.assert(singleMode, 'This is only implemented for single mode')
        return this.dataController.partPages.data?.filter(s => s.selected) || [];
    }

    /**
     * Get the selected suppliers
     * Also includes the partially selected suppliers
     */
    get selectedSuppliersInView(): GroupedRowState[] {
        const singleMode = this.categorizationReviewPageController.singleMode;
        console.assert(!singleMode, 'This is only implemented for grouped mode')
        return this.dataController.supplierPages.data?.filter(s => s.hasSelection) || [];
    }

    /**
     * Get the selected suppliers
     * Only include suppliers that are completely selected
     */
    get fullySelectedSuppliersInView(): GroupedRowState[] {
        const singleMode = this.categorizationReviewPageController.singleMode;
        console.assert(!singleMode, 'This is only implemented for grouped mode')
        return this.dataController.supplierPages.data?.filter(s => s.isAllSelected) || [];
    }

    get nSelectablesInView() {
        const singleMode = this.categorizationReviewPageController.singleMode;
        if (singleMode) {
            return this.dataController.partPages.data?.length || 0;
        } else {
            return this.dataController.supplierPages.data?.length || 0;
        }
    }

    reset() {
        this.isAllSelected = false;
        this.nTotal = null
        this.pageSize = 0
        this.lastSelected = undefined

        // We can potentially speed this up by resetting the data fields, instead of looping over all the rows
        this.dataController.partPages.data?.forEach(p => p.selected = false);
        this.dataController.supplierPages.data?.forEach(r => {
            r.isAllSelected = false;
            r.partStates?.forEach(p => p.selected = false);
        })
    }

    resetSupplierSelection() {
        this.isAllSelected = false;
        this.dataController.supplierPages.data?.forEach(r => {
            r.isAllSelected = false;
            r.partStates?.forEach(p => p.selected = false);
        })
    }
}