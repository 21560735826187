import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import {ApiSuggestionTreeResponse,} from "../services/ApiHelpers";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {UNCATEGORIZED_LABEL} from "../constants";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {TaxSuggesterExtendedD3HierarchyNode} from "../services/classes/TaxonomySuggestorClasses";
import {
    FilteredTaxonomyDemoTableController
} from "../pages/taxonomy-suggestion-demo/controller/FilteredTaxonomyDemoTableController";
import {taxonomy_suggestion_demo} from "../pages/taxonomy-suggestion-demo/Classes";

type Data = taxonomy_suggestion_demo.Data;

// Volatile state in FE
export type ReviewStatusChangesType = {
    hasChangesInChildren: boolean
}

// // Persistent state in BE
export type ResultTableRows = {
    p__description: string
    d__source: string
    s__name: string
    p__spend: string | number
    p__purchase_date: string
}


export type SelectionTableRows = {
    p__spend: string | number
    p_spend_a: string | number
    p_spend_b: string | number
    p_spend_c: string | number
    s_suppliers: string | number
    p__transactions: string | number
}

export type TableColumns = {
    columnTitle: string
    getValue: (d: any, c: any) => string
    xOffset: number
}

const hardcodedPaginatedTableRows = 30

export default class TaxonomySuggestionDemoStore {
    taxonomyApiResponse: {
        id: number
        suggestion_state: ApiSuggestionTreeResponse<Data>
    } | undefined = undefined
    taxonomyTableController: FilteredTaxonomyDemoTableController = new FilteredTaxonomyDemoTableController()
    COLUMNS: ColSpec[] = [
        {cls: 'col-label', txt: 'Category'},
        {cls: 'col-spend', txt: 'Spend', width: '200px'},
        {cls: 'col-suppliers', txt: 'Suppliers', width: '200px'},
        {cls: 'col-n_parts', txt: 'Transactions', width: '200px'},
        {cls: 'col-status', txt: '', width: '200px'},
        {cls: 'col-controls', txt: '', width: '200px'},
    ]

    // noinspection JSUnusedLocalSymbols
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    initDemoData() {
        const baseURL = window.location.origin;
        this.api.http.get('dpw_demo/suggestor_example.json', {baseURL}).then((r) => {
            this.setTaxonomyApiResponse(r.data)
        }).catch((error) => {
            console.error('Error while getting taxonomy suggestions', error);
            throw error;
        })
    }

    setTaxonomyApiResponse(taxonomyApiResponse: any) {
        this.taxonomyTableController.data = FilteredTaxonomyDemoTableController.initNodes(taxonomyApiResponse.suggestion_state);
        this.taxonomyApiResponse = taxonomyApiResponse;
    }

    get suggestionKpis(): {
        numberOfAdditions: number,
        numberOfDeletions: number,
        numberOfRenames: number
    } {
        let numberOfAdditions = 0;
        let numberOfDeletions = 0;
        let numberOfRenames = 0;
        const iterateSuggestions = (suggestion: any) => {
            const reviewStatus = suggestion?.review_status;
            if (reviewStatus) {
                if (reviewStatus.added) {
                    numberOfAdditions += 1;
                } else if (reviewStatus.deleted) {
                    numberOfDeletions += 1;
                } else if (reviewStatus.renamed) {
                    numberOfRenames += 1;
                }
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        if (this.taxonomyApiResponse) {
            iterateSuggestions(this.taxonomyApiResponse.suggestion_state);
        }
        return {numberOfAdditions, numberOfDeletions, numberOfRenames};
    }

    /**
     * Applies the filter, going down the hierarchy
     */
    applyFilter(node: TaxSuggesterExtendedD3HierarchyNode, hideFilter: undefined | ((d: TaxSuggesterExtendedD3HierarchyNode) => boolean), depth = 0, hideUncat?: boolean,): boolean {
        let hideThis = false;
        let canOpen = false;
        if (hideUncat) {
            hideThis = hideThis || (node.data.viz.label === UNCATEGORIZED_LABEL);
        }

        //Hide everything else
        if (hideFilter && !hideThis && depth > 0) {
            hideThis = hideThis || !hideFilter(node)
        }

        // Hide everything else
        node.data.viz.filtered = false;
        if (node._children) {
            let hasUnhiddenChildren = false;
            node._children.forEach(c => {
                if (!this.applyFilter(c, hideFilter, depth + 1, hideUncat)) {
                    hasUnhiddenChildren = true;
                    hideThis = false;
                } else {
                    if (hideThis) {
                        this.setHidden(node, true, depth + 1)
                        node.data.viz.filtered = true;
                    }
                }
            })
            if (hasUnhiddenChildren) {
                canOpen = true;
                node.data.viz.filtered = false;
            }
        }

        node.data.viz.canOpen = canOpen;
        return hideThis;
    }

    setHidden(d: TaxSuggesterExtendedD3HierarchyNode, hidden: boolean, depth: number) {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }
    }
}
