import React from "react";
import {BarDataPoint} from "../visualization/BarChart";
import {Grid} from "@mui/material";
import './CategorizedTransactions.scss';
import {BarChartV2, Options} from "../visualization/bar-chart/BarChart2";

type Props = {
    covered: BarDataPoint[] | undefined,
    uncovered: BarDataPoint[] | undefined,
}

const options: Partial<Options> = {
    vertical: false,
    width: 400,
    height: 'auto',
    labelMargin: 100, // Of the total width
    valueLabelPxH: 34,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    alwaysShowValues: true,
}
export const CategoriesCoveredByAi: React.FC<Props> = ({covered, uncovered}) => {

    return (
        <div>
            <Grid container direction="row" className="custom-pareto-overview"
                  style={{minHeight: '3em'}}>
                <Grid item xs={6}>
                    {covered && (
                        <BarChartV2 data={covered} options={{
                            ...options,
                        }}/>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {uncovered && (
                        <BarChartV2 data={uncovered} options={{
                            ...options,
                        }}/>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
