import React from "react";
import {Content, Portal, Root, Trigger} from "@radix-ui/react-popover"

import {cn} from '../../lib/utils'

const ShadcnPopover = Root

const PopoverTrigger = Trigger

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof Content>,
    React.ComponentPropsWithoutRef<typeof Content>
>(({className, align = "center", sideOffset = 4, ...props}, ref) => (
    <Portal>
        <Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cn(
                "tw-z-50 tw-w-72 tw-rounded-md tw-border tw-bg-popover tw-p-4 tw-text-popover-foreground tw-shadow-md tw-outline-none tw-data-[state=open]:tw-animate-in tw-data-[state=closed]:tw-animate-out tw-data-[state=closed]:tw-fade-out-0 tw-data-[state=open]:tw-fade-in-0 tw-data-[state=closed]:tw-zoom-out-95 tw-data-[state=open]:tw-zoom-in-95 tw-data-[side=bottom]:tw-slide-in-from-top-2 tw-data-[side=left]:tw-slide-in-from-right-2 tw-data-[side=right]:tw-slide-in-from-left-2 tw-data-[side=top]:tw-slide-in-from-bottom-2",
                className
            )}
            {...props}
        />
    </Portal>
))
PopoverContent.displayName = Content.displayName

export {ShadcnPopover, PopoverTrigger, PopoverContent}
