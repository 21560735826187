import {observer} from "mobx-react-lite";
import React from "react";
import {BcrHeader} from "./BcrHeader";
import {FakeLoader} from "./FakeLoader";
import {ReviewOpportunitiesTableSection} from "./ReviewOpportunitiesTableSection";
import {useStores} from "../../../stores";
import {Button} from "@mui/material";
import {AutoAwesome} from "@mui/icons-material";

export const ReviewOpportunitiesComponent: React.FC = observer(() => {
    const {businessCaseGeneratorStore} = useStores();

    // useEffect(() => {
    //     if (!businessCaseGeneratorStore.hasLoaded) {
    //         window.scrollTo(0, 0)
    //         businessCaseGeneratorStore.setInitialLoading(true)
    //     }
    // }, [businessCaseGeneratorStore.hasLoaded])

    // useEffect(() => {
    //     businessCaseGeneratorStore.setInitialLoading(false)
    //     businessCaseGeneratorStore.hasLoaded = false
    // }, [businessCaseGeneratorStore.hasLoaded])

    return <section className="review-opportunities-component">

        <BcrHeader/>

        {(!businessCaseGeneratorStore.isInitialLoading && !businessCaseGeneratorStore.hasLoaded) &&
            <Button
                style={{
                    textTransform: 'none',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: 'auto',
                    minWidth: '30px',
                    minHeight: '30px'
                }}
                color="primary"
                onClick={() => {
                    if (!businessCaseGeneratorStore.hasLoaded) {
                        window.scrollTo(0, 0)
                        businessCaseGeneratorStore.setInitialLoading(true)
                    }
                }}
                // startIcon={< Avatar1/>}
                startIcon={<AutoAwesome/>}
                variant="contained"
            >
                AI assistant
            </Button>}


        {businessCaseGeneratorStore.isInitialLoading &&
            <FakeLoader
                step1Name={'Collecting data'}
                step1Size={10}
                step2Name={'Generating AI insights'}
                step2Size={23}
                step3Name={'Compiling report'}
                totalTimeMs={12000}
                onCompleted={() => businessCaseGeneratorStore.setInitialLoading(false)}
            />
        }

        <ReviewOpportunitiesTableSection/>

    </section>
});
