import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable, toJS} from "mobx";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import {MatSearch, MatSupplierFilter} from "../../../services/classes/MatReviewClasses";
import {catsToDict, setLeveLFilterUrlParamsFromArray, setUrlParamOrNull} from "../../../services/ApiHelpers";
import {clam, numOrDefault} from "../../../components/table/utils";
import {ByYearFilterField} from "../../../stores/categorization-review/filters/byYear";
import {Ordering} from "../../../services/MithraMaterializedApi";
import {GroupedRowState} from "../classes/GroupedRowState";
import {MultiTypedSearchManager, Option} from "../../../components/search/MultiTypedSearchManager";

export const AI_CATEGORIZATION_SCORE_FACTOR = 100;
export const AI_CATEGORIZATION_SCORE_DIGITS = 0;
const AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION = .5 / AI_CATEGORIZATION_SCORE_FACTOR;
const AI_CATEGORIZATION_SCORE_INITIAL_MIN = 0.5;
const AI_CATEGORIZATION_SCORE_INITIAL_MAX = 1.0;

type AdvancedFilterTypes = {
    scoreLow: number | null
    scoreHigh: number | null

    byYear: number | null
}


/**
 * All method related to navigating the categorization review
 */
export class CategorizationReviewPageFilterController {
    readonly partsSearchManager = new MultiTypedSearchManager(
        this.profile.partSearchSpecification,
        (search, selected) => this.onSearch(search, selected),
    )
    readonly suppliersSearchManager = new MultiTypedSearchManager(
        this.profile.supplierSearchSpecification,
        (search, selected) => this.onSearch(search, selected),
    )

    isAdvFilterDialogOpen = false;

    advFilter: AdvancedFilterTypes = {
        scoreLow: null,
        scoreHigh: null,
        byYear: null,
    }

    scoreLowFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MIN * AI_CATEGORIZATION_SCORE_FACTOR);
    scoreLowFilterFieldError = '';
    scoreHighFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MAX * AI_CATEGORIZATION_SCORE_FACTOR);
    scoreHighFilterFieldError = '';
    scoreFilterFieldEnabled = false;

    byYearFilterField = new ByYearFilterField(this.profile)

    /**
     * The business unit that is selected,
     * null if only the missing business units are selected,
     * undefined if all business units are selected
     */
    filteredBusinessUnitId: number | null | undefined;
    filteredApproval?: number;
    selectedCategory: string[] = [];

    ordering?: Ordering;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
        // reaction(() => [this.categorizationReviewPageController.requestedBagId, this.filteredBusinessUnitId, this.singleMode] as const, ([bagId, businessUnitId, singleMode]) => {
        //     console.debug('CategorizationReviewPageFilterController: change', {bagId, businessUnitId, singleMode});
        //     this.categorizationReviewPageController.loadData()
        // })
    }

    resetFilter(approval: number | undefined) {
        this.filteredApproval = approval;
        this.selectedCategory = []
    }

    get selectedFilter(): MatSupplierFilter {
        const lCats = catsToDict(this.selectedCategory, this.selectedLevel);

        let search: MatSearch | undefined = undefined;
        if (this.categorizationReviewPageController.singleMode) {
            // The search is populated in getPartUrlSearchParams()
            // TODO: This can be refactored to simply have a single object to determines the active filter/search
        } else {
            // For the grouped mode the search is set in the filter as the getPartUrlSearchParams() is not used

            const selectedSupplierSearchFields = this.suppliersSearchManager.selectedKeys;
            if (selectedSupplierSearchFields.length !== 1 || selectedSupplierSearchFields[0] !== 's_name') {
                console.error('CategorizationReviewPageFilterController.selectedFilter: '
                    + `search type ${selectedSupplierSearchFields} is not allowed in grouped mode`)
            } else {
                if (this.suppliersSearchManager.search.activeSearchString) {
                    search = {
                        supplier: this.suppliersSearchManager.search.activeSearchString,
                    }
                }
            }
        }

        let requestedBagId = this.categorizationReviewPageController.bagId;
        if (!requestedBagId) {
            console.warn('CategorizationReviewPageController: requestedBagId is not properly refactored', requestedBagId)
        }

        return {
            databag: requestedBagId || -1,
            business_unit: this.filteredBusinessUnitId,
            search,
            level: this.selectedLevel,
            ...lCats,
            approval: this.filteredApproval,
        }
    }

    getPartUrlSearchParams(): URLSearchParams {
        // Convert search 1 to query params
        const params = new URLSearchParams();

        const partSearch = this.partsSearchManager.search.activeSearchString;
        const partSearchTypes = this.partsSearchManager.selectedKeys;
        if (partSearch && partSearchTypes) {
            params.append(`search0_query`, partSearch);
            partSearchTypes.forEach(f => params.append(`search0_fields`, f))
        }

        // Also take into account the business unit and level filtering
        setUrlParamOrNull(params, 'business_unit', this.filteredBusinessUnitId)

        const filterOnFieldsPrefix = 'p_suggested_cat'
        setLeveLFilterUrlParamsFromArray(params, this.selectedLevel, this.selectedCategory, filterOnFieldsPrefix);

        if (this.advFilter.scoreLow !== null) {
            params.set('p_suggestion_score__gte', this.advFilter.scoreLow.toString());
        }
        if (this.advFilter.scoreHigh !== null) {
            params.set('p_suggestion_score__lte', this.advFilter.scoreHigh.toString());
        }
        if (this.advFilter.byYear !== null) {
            // TODO: CAT-1141 p_ddate_1 is hardcoded for now, but should be a parameter
            params.set('p_ddate_1__year', this.advFilter.byYear.toString());
        }

        if (this.ordering) {
            params.set('ordering', (this.ordering.desc ? '-' : '') + this.ordering.field);
        }

        params.set('databag', this.categorizationReviewPageController.bagId.toString())
        params.set('group_by', 'supplier_id')

        return params;
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    setFilteredBusinessUnitId(businessUnitId: number | null | undefined) {
        this.filteredBusinessUnitId = businessUnitId
        const bagId = this.categorizationReviewPageController.bagId
        const taxonomySize = this.categorizationReviewPageController.taxonomySize;
        console.debug('CategorizationReviewPageFilterController.setFilteredBusinessUnitId',
            {bagId, businessUnitId, taxonomySize});
        if (bagId) {
            if (this.categorizationReviewPageController.singleMode) {
                this.categorizationReviewPageController.requestPartPages();
            } else {
                this.categorizationReviewPageController.resetAndRequestSupplierPage();
            }
            const reviewPageStatisticsController = this.categorizationReviewPageController.reviewPageStatisticsController;
            reviewPageStatisticsController._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}));
        }
    }

    setSelectedCategory(selectedCategory: string[]) {
        const obj = {old: `${this.selectedCategory}`, new: `${selectedCategory}`};
        console.debug('CategorizationReviewPageFilterController.setSelectedCategory', obj);

        this.selectedCategory = selectedCategory;
        if (this.categorizationReviewPageController.singleMode) {
            this.categorizationReviewPageController.requestPartPages();
        } else {
            this.categorizationReviewPageController.resetAndRequestSupplierPage();
        }
    }

    selectOneCategoryUp() {
        this.selectedCategory = this.selectedCategory.slice(0, -1);
    }


    canClickParentChart(index: number) {
        return index !== this.selectedLevel - 1;
    }

    clickParentChart(index: number) {
        if (index === this.selectedLevel - 1) {
            // The lowest element is clicked, which is already active
            console.warn('Categorization clicked lowest element for navigation which is ignored',
                `selectedCategory=${this.selectedCategory}`)
            return;
        }
        if (this.selectedCategory.length < 1) {
            console.warn('Unexpected click of navigation')
            return;
        }
        this.setSelectedCategory(this.selectedCategory.slice(0, index + 1))
    }

    get hasRemainingSelectionLevels(): boolean {
        if (this.selectedLevel >= this.categorizationReviewPageController.taxonomySize) {
            return false
        }
        // noinspection RedundantIfStatementJS
        if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
            // Do not allow to go deeper in uncategorized
            return false;
        }
        return true;
    }

    get canSelectLevelDeeper(): boolean {
        if (!this.hasRemainingSelectionLevels) {
            return false;
        }
        // // Dirty hack for filtering out endless uncategorized tree leaves,
        // // not needed when the correct taxonomy size is used
        // if (this.selectedLevel >= 1) {
        //     if (this.categorizationReviewPageController.currentSelectionStats?.length === 1 && this.categorizationReviewPageController.currentSelectionStats[0].label === UNCATEGORIZED_VALUE) {
        //         return false;
        //     }
        // }
        return true;
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    get selectedL1Category(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[0]
        }
        return undefined;
    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    onSearch(search: string, selected: Option[]) {
        const groupBy = this.categorizationReviewPageController.singleMode ? 'parts' : 'suppliers';
        console.info('CategorizationReviewPageFilterController.onSearch', toJS({search, selected, groupBy}));

        // The search is set in the filer, so simply request the new data
        this.categorizationReviewPageController.loadData()
    }

    static isScoreFieldValid(value: string, min: number, max: number): string {
        if (value === '') {
            return '';
        }
        const num = Number(value);
        if (isNaN(num)) {
            return 'Not a number';
        }
        if (num < min || num > max) {
            return `Must be between ${min} and ${max}`;
        }
        return '';
    }

    setScoreLowFilterField(value: string) {
        this.scoreLowFilterField = value;
        // this.scoreLowFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        // this.scoreHighFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreFilterFieldEnabled = true;
    }

    get scoreLowFilterFieldValue(): number {
        const num = numOrDefault(this.scoreLowFilterField, 0);
        return clam(num, 0, AI_CATEGORIZATION_SCORE_FACTOR);
    }

    get scoreHighFilterFieldValue(): number {
        const num = numOrDefault(this.scoreHighFilterField, AI_CATEGORIZATION_SCORE_FACTOR);
        return clam(num, 0, AI_CATEGORIZATION_SCORE_FACTOR);
    }

    setScoreHighFilterField(value: string) {
        this.scoreHighFilterField = value;
        // this.scoreLowFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        // this.scoreHighFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreFilterFieldEnabled = true;
    }

    setScoreFilterFieldEnabled() {
        this.scoreFilterFieldEnabled = true;
    }

    toggleScoreFilterFieldEnabled() {
        this.scoreFilterFieldEnabled = !this.scoreFilterFieldEnabled;
    }

    get isAdvancedFilterEnabled() {
        return this.advFilter.scoreLow !== null
            || this.advFilter.scoreHigh !== null
            || this.advFilter.byYear !== null
    }

    get isAnyAdvancedFilterFieldEnabled() {
        return this.scoreFilterFieldEnabled
    }

    disableAdvancedFilter() {
        this.scoreFilterFieldEnabled = false;
        this.checkAdvancedFilterIsValid()

        let change = false;

        const newScoreLow = null;
        const newScoreHigh = null;
        if (this.advFilter.scoreLow !== newScoreLow) {
            this.advFilter.scoreLow = newScoreLow;
            change = true;
        }
        if (this.advFilter.scoreHigh !== newScoreHigh) {
            this.advFilter.scoreHigh = newScoreHigh;
            change = true;
        }

        const newByYear = null;
        if (this.advFilter.byYear !== newByYear) {
            this.advFilter.byYear = newByYear;
            change = true;
        }

        if (change) {
            this.categorizationReviewPageController.loadData()
        }
    }

    checkAdvancedFilterIsValid() {
        // Check validity
        this.scoreLowFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        this.scoreHighFilterFieldError = CategorizationReviewPageFilterController.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);
        this.byYearFilterField.validate()
        return this.advancedFilterIsValid;
    }

    get advancedFilterIsValid() {
        return this.scoreLowFilterFieldError === ''
            && this.scoreHighFilterFieldError === ''
            && this.byYearFilterField.fieldError === ''
    }

    // This function will applu the advanced filter from the modal
    applyAdvancedFilter() {
        let change = false;

        if (!this.advancedFilterIsValid) {
            console.warn('CategorizationReviewPageFilterController.applyAdvancedFilter: advanced filter is not valid')
            return;
        }
        this.scoreLowFilterField = String(this.scoreLowFilterFieldValue);
        this.scoreHighFilterField = String(this.scoreHighFilterFieldValue);
        if (this.scoreLowFilterFieldValue === 0 && this.scoreHighFilterFieldValue === AI_CATEGORIZATION_SCORE_FACTOR) {
            // Both are at the max
            this.scoreFilterFieldEnabled = false;
        }

        if (this.scoreFilterFieldEnabled) {
            const newScoreLow = this.scoreLowFilterFieldValue / AI_CATEGORIZATION_SCORE_FACTOR - AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION;
            const newScoreHigh = this.scoreHighFilterFieldValue / AI_CATEGORIZATION_SCORE_FACTOR + AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION;
            if (this.advFilter.scoreLow !== newScoreLow) {
                this.advFilter.scoreLow = newScoreLow;
                change = true;
            }
            if (this.advFilter.scoreHigh !== newScoreHigh) {
                this.advFilter.scoreHigh = newScoreHigh;
                change = true;
            }
        } else {
            const newScoreLow = null;
            const newScoreHigh = null;
            if (this.advFilter.scoreLow !== newScoreLow) {
                this.advFilter.scoreLow = newScoreLow;
                change = true;
            }
            if (this.advFilter.scoreHigh !== newScoreHigh) {
                this.advFilter.scoreHigh = newScoreHigh;
                change = true;
            }
        }

        if (!this.byYearFilterField.filterFieldEnabled) {
            if (this.byYearFilterField) {
                const newByYear = this.byYearFilterField.value;
                if (this.advFilter.byYear !== newByYear) {
                    this.advFilter.byYear = newByYear;
                    change = true;
                }
            } else {
                const newByYear = null;
                if (this.advFilter.byYear !== newByYear) {
                    this.advFilter.byYear = newByYear;
                    change = true;
                }
            }
        }

        if (change) {
            this.categorizationReviewPageController.loadData()
        }

        return true;
    }

    clickAdvancedFilter(lowValue: number, highValue: number) {
        this.advFilter.scoreLow = lowValue;
        this.advFilter.scoreHigh = highValue;
        this.scoreLowFilterField = String(lowValue);
        this.scoreLowFilterFieldError = '';
        this.scoreHighFilterField = String(highValue);
        this.scoreHighFilterFieldError = '';
        this.scoreFilterFieldEnabled = true;
        this.applyAdvancedFilter()
        return true;
    }

    clearAdvancedFilter() {
        this.advFilter.scoreLow = null;
        this.advFilter.scoreHigh = null;
        this.scoreLowFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MIN * AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreLowFilterFieldError = '';
        this.scoreHighFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MAX * AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreHighFilterFieldError = '';
        this.scoreFilterFieldEnabled = false;
        this.categorizationReviewPageController.loadData()
        return true;
    }

    toggleOrderBy(columnFieldId: string | undefined, groupedRow?: GroupedRowState) {
        if (!columnFieldId) {
            this.ordering = undefined;
        } else {
            const defaultOrderingDirection = columnFieldId !== 'p__spend';
            this.ordering = {
                field: columnFieldId,
                desc: (
                    this.ordering?.field === columnFieldId
                        ? (!this.ordering.desc) // Toggle it
                        : defaultOrderingDirection
                ),
            }
        }
        if (this.categorizationReviewPageController.singleMode) {
            this.categorizationReviewPageController.loadData()
        } else {
            if (!groupedRow) {
                console.error('Expected grouped row to be set')
            }
            groupedRow?.handlePageChange(1, this.ordering);
        }
    }

    setAdvancedFilterDialogOpen(open: boolean) {
        this.isAdvFilterDialogOpen = open;
    }
}
