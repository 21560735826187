import {makeAutoObservable} from "mobx";
import {
    InitiativeKPI,
} from "../../ApiTypes";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";

export class InitiativeKpiController {
    expand = false;

    constructor(public iis: InitiativeInstanceStore, public index: number) {
        makeAutoObservable(this);
    }

    get instance(): InitiativeKPI {
        return this.iis.instance.kpi![this.index];
    }

    // set type(value: KPIType) {
    //     this.instance.type = value;
    // }

    toggleExpand() {
        this.expand = !this.expand;
    }
}
