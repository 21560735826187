export type ContractFieldSpec = {
    title: string
    field: string
    type?: 'potential_array' | 'anything'
}


export const CONTRACT_INFO_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Filename',
        field: 'file_name'
    },
    {
        title: 'Contract ID',
        field: 'contract_id'
    },
    {
        title: 'Contract Title',
        field: 'contract_title'
    },
    {
        title: 'Type',
        field: 'contract_type'
    },
    {
        title: 'Parent Supplier',
        field: 'parent_supplier'
    },
    {
        title: 'Supplier',
        field: 'involved_supplier'
    },
    {
        title: 'Entity',
        field: 'involved_company'
    },
    {
        title: 'Sister companies',
        field: 'children_supplier'
    },
    {
        title: 'Effective Date',
        field: 'effective_date'
    },
    {
        title: 'Expiration Date',
        field: 'expiration_date'
    },
    {
        title: 'Duration',
        field: 'duration'
    },
    {
        title: 'Scope',
        field: 'scope'
    },
    {
        title: 'Evergreen Contract',
        field: 'evergreen_contract'
    },
];

export const CONTRACT_FINANCE_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Pricing',
        field: 'prices',
        type: 'potential_array'
    },
    {
        title: 'Price adjustment',
        field: 'price_adjustment'
    },
    {
        title: 'Volume Discount',
        field: 'volume_discount'
    },
    {
        title: 'Payment Terms',
        field: 'payment_terms'
    },
    {
        title: 'Invoice and Payment',
        field: 'invoices_and_payment'
    },
];

export const CONTRACT_INVOICE_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Products / Services',
        field: 'products'
    },
    {
        title: 'Quantity',
        field: 'quantity'
    },
];

export const CONTRACT_DELIVERY_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Delivery',
        field: 'delivery'
    },
    {
        title: 'Incoterms',
        field: 'incoterms'
    },
    // {
    //     title: 'Quantity Thresholds',
    //     field: 'quantity_thresholds'
    // },
];

export const CONTRACT_TERMINATION_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Supplier Legal Name',
        field: 'involved_supplier_legal_name'
    },
    {
        title: 'Involved Legal Name',
        field: 'involved_company_legal_name'
    },
    {
        title: 'Renewal Conditions',
        field: 'renewal_condition'
    },
    {
        title: 'Termination',
        field: 'termination'
    },
    {
        title: 'Automatic Renewal',
        field: 'auto_renewal'
    },
    {
        title: 'Liability & Legal',
        field: 'liability'
    },
    {
        title: 'Disagreement Resolving',
        field: 'disagreements_resolving'
    },
    {
        title: 'Governing Law',
        field: 'governing_law'
    },
    {
        title: 'Confidentiality',
        field: 'confidential'
    },
];

export const CONTRACT_PERFORMANCE_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Performance Obligations',
        field: 'performance'
    },
];

export const CONTRACT_PRODUCT_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Annual Cost & Volume',
        field: 'annual_cost_volume'
    },
];

export const CONTRACT_OTHER_FIELDS: ContractFieldSpec[] = [
    {
        title: 'Additional Information',
        field: 'additional_information'
    },
];

export const ALL = [
    ...CONTRACT_INFO_FIELDS,
    ...CONTRACT_FINANCE_FIELDS,
    ...CONTRACT_INVOICE_FIELDS,
    ...CONTRACT_DELIVERY_FIELDS,
    ...CONTRACT_TERMINATION_FIELDS,
    ...CONTRACT_PERFORMANCE_FIELDS,
    ...CONTRACT_PRODUCT_FIELDS,
    ...CONTRACT_OTHER_FIELDS,
]
export const ALL_FIELDS = new Set(ALL.map(f => f.field));
export const HIDE_FIELDS = new Set([
    'id',
    'date',
]);

export const READONLY_FIELDS = new Set([
    'file_name',
]);

export function getRemainingFields(d: any): ContractFieldSpec[] {
    return Object.keys(d)
        .filter(k => !ALL_FIELDS.has(k) && !HIDE_FIELDS.has(k))
        .map<ContractFieldSpec>(k => ({
            title: k,
            field: k,
        }))
}

export const CONTRACT_TABLE_FOLD_FIELDS: ContractFieldSpec[] = [
    ALL.find(f => f.field === 'products')!,
    ALL.find(f => f.field === 'prices')!,
    ALL.find(f => f.field === 'price_adjustment')!,
    ALL.find(f => f.field === 'volume_discount')!,
    ALL.find(f => f.field === 'quantity')!,
    {
        title: 'Payment Terms',
        field: 'payment_terms_types'
    },
    {
        title: 'Payment Term Details',
        field: 'payment_terms_details'
    },
    ALL.find(f => f.field === 'delivery')!,
];
if(CONTRACT_TABLE_FOLD_FIELDS.find(c => !c)) {
    console.error('Missing field in CONTRACT_TABLE_FOLD_FIELDS');
}
