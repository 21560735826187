import './DemoAiBusy.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Bag} from "../../../../services/classes/Bag";
import {Button, Grid, LinearProgress, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {makeAutoObservable} from "mobx";
import {interval, map, Subscription, take} from "rxjs";
import {C} from "../../../../configurations/active-configuration";

const EST_AI_RUN_TIME = 3; // in seconds
const UPDATE_RATE = 1 / 4; // 1 = 1 time per second, 1/30 = 30 FPS

class AiModelFaker {
    private readonly timer = interval(1000 * UPDATE_RATE).pipe(
        take(1 + EST_AI_RUN_TIME / UPDATE_RATE),
        map(i => (i + 1) / (EST_AI_RUN_TIME / UPDATE_RATE)),
    )
    private subscription: Subscription | undefined = undefined;
    progress = 0;

    public readonly TARGET = C.profile.demoFakeReinforcedCategorizationTarget;

    constructor() {
        makeAutoObservable(this)
    }

    setProgress(progress: number) {
        this.progress = progress;
    }

    start() {
        this.progress = 1;
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = this.timer.subscribe({
            next: p => this.setProgress(p * 100),
        })
        this.subscription.add(() => this.setProgressDone())
    }

    setProgressDone() {
        this.progress = 100;
    }

    reset() {
        if (this.subscription) this.subscription.unsubscribe();
        this.progress = 0;
    }
}

export const AI_MODEL_FAKER = new AiModelFaker()

export const DemoAiBusy: React.FC = observer(() => {
    const {bagStore, categorizationReviewStore} = useStores();
    const bag = bagStore.bag as Bag;
    if (!bag) throw new Error();

    return <div className="demo-ai-busy">
        <Grid container alignItems="center" direction="column">
            <Grid item className="header">
                <Typography variant="h4" onClick={() => AI_MODEL_FAKER.start()}>
                    Categorizing ...
                </Typography>
            </Grid>
            <Grid item>
                <div className="progress-container">
                    <LinearProgress
                        variant="determinate"
                        value={AI_MODEL_FAKER.progress}/>
                </div>
            </Grid>
            {/*<Grid item>*/}
            {/*    <Typography variant="caption">*/}
            {/*        Estimated completion time: {aiManagerStore.applyTaxonomyMapping.estCompletionTime?.fromNow()}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item className="instructions-text">
                <Typography variant="body2" className="text-center">
                    Your AI model is running, please wait ...
                </Typography>
                {/*{aiManagerStore.applyTaxonomyMapping.runSync.error &&*/}
                {/*    <Alert severity="error">{aiManagerStore.applyTaxonomyMapping.runSync.error}</Alert>*/}
                {/*}*/}
            </Grid>
            <Grid item className="action-btn-container">
                <div className="button-wrapper">
                    <Button
                        id="continue-categorization-btn"
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={AI_MODEL_FAKER.progress !== 100}
                        onClick={() => {
                            AI_MODEL_FAKER.reset()
                            categorizationReviewStore.reInitializeWithTaxonomy(
                                AI_MODEL_FAKER.TARGET?.databagId || -1,
                                AI_MODEL_FAKER.TARGET?.taxonomyId || -1,
                                AI_MODEL_FAKER.TARGET?.taxonomySize || 3,
                                )
                            categorizationReviewStore.reviewPageController.navigateToPage('review')
                        }}
                    >
                        View results
                    </Button>
                </div>

            </Grid>
        </Grid>
    </div>
});
