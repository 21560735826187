import {IconButton, Tooltip, Typography} from "@mui/material";
import {InfoIcon} from "lucide-react";
import React from "react";

export const MithraTooltip: React.FC = ({children}) => {
    return <Tooltip title={
        <Typography variant="caption">
            {children}
        </Typography>
    }>
        <IconButton>
            <InfoIcon/>
        </IconButton>
    </Tooltip>
}
