import {observer} from "mobx-react-lite";
import React from "react";
import {TaxApprovalHistoryButtons} from "./TaxApprovalHistoryButtons";
import {Grid} from "@mui/material";
import {useStores} from "../../../../stores";


export const TaxonomyApprovalReviewButtonBar = observer(() => {
    const {approvalStore} = useStores();
    const readOnly = approvalStore.taxonomyApproval.readOnly;
    return <Grid container className="taxonomy-approval-review-button-bar" justifyContent="flex-end">
        <Grid item>
            {!readOnly && <TaxApprovalHistoryButtons/>}
        </Grid>
    </Grid>
});
