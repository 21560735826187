import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const TaxonomyMergeMenu: React.FC = () => {
    const {p} = useStores();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: (MenuItem | undefined)[] = []

    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('data'))
        menu.push({
            label: 'Data',
            link: generatePath(hasId1 ? routes.synergy_v2_data : routes.synergy_v2, params),
            active: (
                location.pathname === routes.synergy_v2
                || location.pathname === routes.synergy_v2_data
            ),
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('synergies'))
        menu.push({
            label: 'Synergies',
            link: generatePath(hasId1 ? routes.synergy_v2_dashboard : routes.synergy_v2, params),
            active: location.pathname === routes.synergy_v2_dashboard,
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('opportunities'))
        menu.push({
            label: 'Opportunities',
            link: generatePath(hasId1 ? routes.synergy_v2_opportunities : routes.synergy_v2, params),
            active: !isHome && location.pathname === routes.synergy_v2_opportunities,
            isTrial: p.p.tease_main_menu,
            className: 'menu-item-2nd-last',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('master-taxonomy'))
        menu.push({
            label: 'Master taxonomy',
            link: hasId1 ?
                generatePath(routes.synergy_v2_taxonomy_builder_id, {
                    id: params.id,
                    taxonomyId: p.p.masterTaxonomyId,
                })
                : routes.synergy_v2,
            active: !isHome && (
                location.pathname === routes.synergy_v2_taxonomy_builder_id
                // eslint-disable-next-line eqeqeq
                && params['taxonomyId'] == p.p.masterTaxonomyId
            ),
            isTrial: p.p.tease_main_menu,
            className: 'menu-item-last',
        })

    return <MenuItems className="taxonomy-merge-menu" items={menu}/>
}
