import 'react-resizable/css/styles.css';
import React from 'react';
import {Button, Chip, Dialog, DialogContent, DialogTitle, IconButton, Paper} from '@mui/material';
import Draggable from 'react-draggable';
import {ResizableBox} from 'react-resizable';
import CloseIcon from '@mui/icons-material/Close';
import {ChatComponent} from "./ChatComponent";
import ChatController from "./ChatController";
import {observer} from "mobx-react-lite";
import {analyticsAssistantFeedbackEvent} from "../../services/analytics/AnalyticEvents";
import git from "../../utils/git.json";
import {authStore} from "../../stores/init";

const DraggablePaperComponent = (props: any) => {
    return (
        <Draggable
            handle=".draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
};

export const ChatOverlayComponent: React.FC = observer(() => {
    const handleClose = () => ChatController.setOverlayOpen(false);

    return <Dialog
        open={ChatController.overlayOpened}
        onClose={handleClose}
        PaperComponent={DraggablePaperComponent}
        PaperProps={{
            style: {
                overflow: 'hidden', // To prevent overflow when resizing
                // position: 'fixed',
            },
        }}
        aria-labelledby="draggable-dialog-title"
        maxWidth={false}  // Prevent MUI from enforcing max width constraints
    >
        <ResizableBox
            width={800}
            height={600}
            minConstraints={[400, 400]}  // Minimum width and height
            resizeHandles={['se']}
            // In order to make it resizable with the top left fixed the position and dimension should be implemented
            // by us. @see https://github.com/react-grid-layout/react-resizable/pull/136
            // Or https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
            style={{display: 'flex', flexDirection: 'column'}}
        >
            {/*This fragment fixes a warning with the ResizableBox*/}
            <>
                <DialogTitle
                    style={{cursor: 'move'}}
                    id="draggable-dialog-title"
                    className="draggable-dialog-title">
                    Mithra AI Assistant
                    <Chip label="BETA" variant="filled" color="primary" className="mx-4"/>
                    <Button
                        variant="outlined" className="float-right mx-5"
                        href="mailto:arash@mithra-ai.com?subject=Feedback for Mithra AI Assistant"
                    onClick={() => analyticsAssistantFeedbackEvent({
                        version: git.gitCommitHash,
                        location: window.location.toString(),
                        user: authStore.email
                    })}>
                        Send us feedback
                    </Button>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent sx={{flexGrow: 1}}>
                    <ChatComponent/>
                </DialogContent>
            </>
        </ResizableBox>
    </Dialog>
});
