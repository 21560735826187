import ProfileStore from "../../../../stores/ProfileStore";
import MithraMaterializedApi from "../../../../services/MithraMaterializedApi";
import {makeAutoObservable, runInAction} from "mobx";
import {BarDataPoint} from "../../../../components/visualization/BarChart";
import {toCurrency} from "../../../../components/currency-component/CurrencyClasses";
import {CategorizationVersionedStatisticsSerializer} from "../../../../services/ApiTypes";
import {AxoisRequestManager} from "../../../../stores/managers/RequestManager";
import {from, tap} from "rxjs";

/**
 * Accessible via: `categorizationReviewStore.statisticsDashboardController`
 */
export class CategorizationStatisticsDashboardController {
    readonly _retrieveRequest = new AxoisRequestManager<{
        bagId: number
    }, CategorizationVersionedStatisticsSerializer>(
        ({bagId}) => from(this.api.getCategorizationVersionedStatistics(bagId))
    )
    readonly _updateRequest = new AxoisRequestManager<{
        bagId: number
    }, CategorizationVersionedStatisticsSerializer>(
        ({bagId}) => from(this.api.recalculateCategorizationVersionedStatistics(bagId)).pipe(tap(r => {
            runInAction(() => this._retrieveRequest.result = r.data)
        }))
    )

    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    getCategorizationVersionedStatistics(bagId: number) {
        this._updateRequest.cleanup()
        this._retrieveRequest.request({bagId})
    }

    updateCategorizationVersionedStatistics(bagId: number) {
        this._updateRequest.request({bagId})
    }

    get requestIsBusy() {
        return this._updateRequest.busy || this._retrieveRequest.busy;
    }

    get stats(): CategorizationVersionedStatisticsSerializer | undefined {
        return this._updateRequest.result ?? this._retrieveRequest.result;
    }

    get distributionBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        const veryLowConfidencePercentage = this.stats.very_low_confidence_parts === 0 ? 0
            : Math.round(this.stats.very_low_confidence_parts / this.stats.total_parts * 100);
        const lowConfidencePercentage = this.stats.low_confidence_parts === 0 ? 0
            : Math.round(this.stats.low_confidence_parts / this.stats.total_parts * 100);
        const mediumConfidencePercentage = this.stats.medium_confidence_parts === 0 ? 0
            : Math.round(this.stats.medium_confidence_parts / this.stats.total_parts * 100);
        const highConfidencePercentage = this.stats.high_confidence_parts === 0 ? 0
            : Math.round(this.stats.high_confidence_parts / this.stats.total_parts * 100);
        const veryHighConfidencePercentage = this.stats.very_high_confidence_parts === 0 ? 0
            : Math.round(this.stats.very_high_confidence_parts / this.stats.total_parts * 100);

        const veryLowConfidence = {
            value: veryLowConfidencePercentage / 60,
            valueLabel: `${this.stats.very_low_confidence_parts || 0} | ${veryLowConfidencePercentage}%`,
            category: "0_20",
            categoryLabel: "Very low confidence (0-20)",
        };

        const lowConfidence = {
            value: lowConfidencePercentage / 60,
            valueLabel: `${this.stats.low_confidence_parts || 0} | ${lowConfidencePercentage}%`,
            category: "20_40",
            categoryLabel: "Low confidence (20-40)",
        };

        const mediumConfidence = {
            value: mediumConfidencePercentage / 60,
            valueLabel: `${this.stats.medium_confidence_parts || 0} | ${mediumConfidencePercentage}%`,
            category: "40_60",
            categoryLabel: "Medium confidence (40-60)",
        };

        const highConfidence = {
            value: highConfidencePercentage / 60,
            valueLabel: `${this.stats.high_confidence_parts || 0} | ${highConfidencePercentage}%`,
            category: "60_80",
            categoryLabel: "High confidence (60-80)",
        };

        const veryHighConfidence = {
            value: veryHighConfidencePercentage / 60,
            valueLabel: `${this.stats.very_high_confidence_parts || 0} | ${veryHighConfidencePercentage}%`,
            category: "80_100",
            categoryLabel: "Very high confidence (80-100)",
        };

        return [veryLowConfidence, lowConfidence, mediumConfidence, highConfidence, veryHighConfidence];
    }

    get totalReviewedAiResultBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        const currencySymbol = this.profile.currencySymbol;
        const reviewedPercentage = this.stats.reviewed_transactions === 0 ? 0 :
            Math.round(this.stats.reviewed_transactions / this.stats.total_transactions * 100);
        const unreviewedPercentage = this.stats.unreviewed_transactions === 0 ? 0
            : Math.round(this.stats.unreviewed_transactions / this.stats.total_transactions * 100);

        const reviewedData = {
            value: reviewedPercentage / 200,
            valueLabel: `${this.stats.reviewed_transactions || 0} | ${toCurrency(currencySymbol, (this.stats.reviewed_spend || 0))}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 200,
            valueLabel: `${this.stats.unreviewed_transactions || 0} | ${toCurrency(currencySymbol, (this.stats.unreviewed_spend || 0))}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        console.log([reviewedData, unreviewedData])
        return [reviewedData, unreviewedData];
    };

    get coveredByAiSpendPercentage(): number {
        if (!this.stats) {
            return 0;
        }
        if (this.stats.covered_by_ai_total_spend === 0 || this.stats.covered_by_ai_spend === 0) {
            return 0;
        }
        return Math.round(this.stats.covered_by_ai_spend / this.stats.covered_by_ai_total_spend * 100);
    }

    get spendCoveredByAiBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        const currencySymbol = this.profile.currencySymbol;
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        if (this.stats.covered_by_ai_spend !== 0) {
            reviewedPercentage = (this.stats.covered_by_ai_reviewed_spend === 0) ? 0
                : Math.round(this.stats.covered_by_ai_reviewed_spend / this.stats.covered_by_ai_spend * 100);
            unreviewedPercentage = (this.stats.covered_by_ai_unreviewed_spend === 0) ? 0
                : Math.round(this.stats.covered_by_ai_unreviewed_spend / this.stats.covered_by_ai_spend * 100);
        }

        const reviewedData = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.covered_by_ai_reviewed_spend || 0))}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.covered_by_ai_total_spend || 0))}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        return [reviewedData, unreviewedData];
    }

    get coveredByAiSuppliersPercentage(): number {
        if (!this.stats) {
            return 0;
        }
        if (this.stats.covered_suppliers === 0 || this.stats.total_suppliers === 0) {
            return 0;
        }
        return Math.round(this.stats.covered_suppliers / this.stats.total_suppliers * 100);
    }

    get suppliersCoveredByAiBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        if (this.stats.covered_suppliers !== 0) {
            reviewedPercentage = (this.stats.covered_reviewed_suppliers === 0) ? 0 :
                Math.round(this.stats.covered_reviewed_suppliers / this.stats.covered_suppliers * 100);
            unreviewedPercentage = (this.stats.covered_unreviewed_suppliers === 0) ? 0 :
                Math.round(this.stats.covered_unreviewed_suppliers / this.stats.covered_suppliers * 100);
        }

        const reviewedData = {
            value: reviewedPercentage / 100,
            valueLabel: `${String(this.stats.covered_reviewed_suppliers || 0)}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 100,
            valueLabel: `${String(this.stats.covered_unreviewed_suppliers || 0)}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        return [reviewedData, unreviewedData];
    }

    get categoriesCoveredByAiBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        let coveredPercentage: number = 0;

        if (this.stats.total_categories !== 0) {
            coveredPercentage = this.stats.covered_categories === 0 ? 0
                : Math.round(this.stats.covered_categories / this.stats.total_categories * 100);
        }

        const covered = {
            value: coveredPercentage / 100,
            valueLabel: `${String(this.stats.covered_categories)} | ${coveredPercentage}%`,
            category: "covered",
            categoryLabel: "Covered",
        };

        return [covered]
    }

    get categoriesUncoveredByAiBarDataPoint(): BarDataPoint[] {
        if (!this.stats) return [];
        let uncoveredPercentage: number = 0

        if (this.stats.total_categories !== 0) {
            uncoveredPercentage = this.stats.uncovered_categories === 0 ? 0
                : Math.round(this.stats.uncovered_categories / this.stats.total_categories * 100);
        }

        const uncovered = {
            value: uncoveredPercentage / 100,
            valueLabel: `${String(this.stats.uncovered_categories)} | ${uncoveredPercentage}%`,
            category: "",
            categoryLabel: "Not covered",
        };
        return [uncovered];
    }

    get categorizationRecategorized(): BarDataPoint[] {
        if (!this.stats) return [];
        const currencySymbol = this.profile.currencySymbol;

        let recategorizedPercentage: number = 0
        if (this.stats.recategorized_spend !== 0 && this.stats.recategorized_total_spend !== 0) {
            recategorizedPercentage = Math.round(this.stats.recategorized_spend / this.stats.recategorized_total_spend * 100);
            console.log(recategorizedPercentage);
        }
        let recategorized_parts = this.stats.recategorized_reviewed_parts + this.stats.recategorized_unreviewed_parts
        const reviewedPercentage = this.stats.recategorized_reviewed_parts === 0 ? 0
            : Math.round(this.stats.recategorized_reviewed_parts / recategorized_parts * 100);
        const unreviewedPercentage = this.stats.recategorized_unreviewed_parts === 0 ? 0
            : Math.round(this.stats.recategorized_unreviewed_parts / recategorized_parts * 100);


        const recategorized = {
            value: recategorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.recategorized_spend || 0))}  | ${recategorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const recategorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.recategorized_reviewed_spend || 0))} | ${this.stats.recategorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const recategorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.recategorized_unreviewed_spend || 0))} | ${this.stats.recategorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [recategorized, recategorizedReviewed, recategorizedUnreviewed];
    }

    get categorizationCategorized(): BarDataPoint[] {
        if (!this.stats) return [];
        const currencySymbol = this.profile.currencySymbol;
        let categorizedPercentage: number = 0

        let categorized_parts = (this.stats.categorized_reviewed_parts || 0) + (this.stats.categorized_unreviewed_parts || 0)
        if (this.stats.categorized_spend !== 0 && this.stats.categorized_total_spend !== 0) {
            categorizedPercentage = Math.round(this.stats.categorized_spend / this.stats.categorized_total_spend * 100);
        }
        const reviewedPercentage = this.stats.categorized_reviewed_parts === 0 ? 0
            : Math.round(this.stats.categorized_reviewed_parts / categorized_parts * 100);
        const unreviewedPercentage = this.stats.categorized_unreviewed_parts === 0 ? 0
            : Math.round(this.stats.categorized_unreviewed_parts / categorized_parts * 100);


        const categorized = {
            value: categorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.categorized_spend || 0))}  | ${categorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const categorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.categorized_reviewed_spend || 0))} | ${this.stats.categorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const categorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.categorized_unreviewed_spend || 0))} | ${this.stats.categorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [categorized, categorizedReviewed, categorizedUnreviewed];
    }

    get categorizationUncategorized(): BarDataPoint[] {
        if (!this.stats) return [];
        const currencySymbol = this.profile.currencySymbol;
        let uncategorizedPercentage: number = 0
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        let uncategorized_parts = (this.stats.uncategorized_reviewed_parts || 0) + (this.stats.uncategorized_unreviewed_parts || 0)


        if (this.stats.uncategorized_spend !== 0 && this.stats.uncategorized_total_spend !== 0) {
            uncategorizedPercentage = Math.round(this.stats.uncategorized_spend / this.stats.uncategorized_total_spend * 100);
        }
        reviewedPercentage = this.stats.uncategorized_reviewed_parts === 0 ? 0
            : Math.round(this.stats.uncategorized_reviewed_parts / uncategorized_parts * 100);
        unreviewedPercentage = this.stats.uncategorized_unreviewed_parts === 0 ? 0
            : Math.round(this.stats.uncategorized_unreviewed_parts / uncategorized_parts * 100);


        const uncategorized = {
            value: uncategorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.uncategorized_spend || 0))}  | ${uncategorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const uncategorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.uncategorized_reviewed_spend || 0))} | ${this.stats.uncategorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const uncategorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.stats.uncategorized_unreviewed_spend || 0))} | ${this.stats.uncategorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [uncategorized, uncategorizedReviewed, uncategorizedUnreviewed];
    }
}