import AutoAwesome from "@mui/icons-material/AutoAwesome";
import {IconButton, InputBase, InputBaseProps, Paper} from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";

type Props = {
    onClick?: () => void;
    inputBaseProps?: InputBaseProps
    onSend?: () => void
}
export const ChatInput: React.FC<Props> = ({onClick, inputBaseProps, onSend}) => <Paper
    className="header-chat-box-component"
    component="div"
    sx={{p: '4px 6px', display: 'flex', width: '100%', alignItems: 'center', borderRadius: 6}}
    onClick={onClick}
>
    <AutoAwesome sx={{ml: '5px'}}/>
    <InputBase
        sx={{mr: 1, ml: 1, flex: 1}}
        placeholder="Ask Mithra AI ..."
        inputProps={{'aria-label': 'ask Mithra'}}
        {...inputBaseProps}
    />
    {onSend &&
        <IconButton color="primary" onClick={onSend} sx={{ml: 1}}>
            <SendIcon/>
        </IconButton>
    }
</Paper>