import {AppState, DashboardEnvironmentV3} from "../../../stores/RootAppStore";

export type MergeXCleanupEnvironments = DashboardEnvironmentV3 & ('cleanup' | 'synergy')

export function getDashboardEnv(app: AppState): MergeXCleanupEnvironments {
    if (app.dashboardEnvironment === 'cleanup') {
        return 'cleanup';
    } else {
        return 'synergy';
    }
}
