import {makeAutoObservable} from "mobx";
import {
    InitiativeStatus,
} from "../../ApiTypes";
import dayjs, {Dayjs} from "dayjs";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";

export class InitiativeStatusController {
    constructor(public iis: InitiativeInstanceStore, public index: number) {
        makeAutoObservable(this);
    }

    get instance(): InitiativeStatus {
        return this.iis.instance.doi![this.index];
    }

    get dueDate() {
        return dayjs(this.instance.due_date);
    }

    set dueDate(value: Dayjs) {
        this.instance.due_date = value.toDate();
    }
}
