import ProfileStore from "../../../stores/ProfileStore";
import AuthStore from "../../../stores/AuthStore";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {BusinessCaseGeneratorPageController, Page} from "./BusinessCaseGeneratorPageController";
import {BusinessCaseGeneratorPageStateController} from "./BusinessCaseGeneratorPageStateController";

/**
 * This reactive controller is responsible for the state of the grouped review table.
 */
export class BusinessCaseGeneratorPageDataController {

    constructor(
        private businessCaseGeneratorPageController: BusinessCaseGeneratorPageController,
        private businessCaseGeneratorPageStateController: BusinessCaseGeneratorPageStateController,
        private matApi: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    navigateToPage(page: Page) {
        this.businessCaseGeneratorPageStateController.setPage(page);
    }

}
