import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, CircularProgress, Grid} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {routes} from "../../routing/routes";
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {generatePath, useNavigate} from "react-router-dom";
import {environment} from "../../env";
import {isAxiosError} from "axios";

type Props = {
    defaultPage: "dataset_upload" | "data_mapping" | "data_check" | "data_finish"
}
export const DataIngestionStepper: React.FC<Props> = observer(({defaultPage}) => {
    const {authStore, dataIngestionStore} = useStores();
    const navigate = useNavigate();
    const page = dataIngestionStore.page || defaultPage;

    /**
     * A synchronous request is pending
     */
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Either a synchronous or asynchronous request is pending
     */
    const isBusy = dataIngestionStore.dataIngestionStatus?.status === 'ingest-busy' || isLoading;

    return <div className="data-ingestion-stepper">
        <Grid container justifyContent="space-arround" style={{marginTop: "2em"}}>

            <Grid item xs={2} className="text-center align-with-stepper">
                {page === 'dataset_upload' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            navigate(generatePath(routes.data_ingestion))
                            dataIngestionStore.resetIngestionStore();
                        }}>
                        Back
                    </Button>
                }
                {page === 'data_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            navigate(generatePath(routes.data_ingestion))
                            dataIngestionStore.resetIngestionStore();
                        }}>
                        Back
                    </Button>
                }
                {page === 'data_check' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            dataIngestionStore.navigateToPage('data_mapping')
                        }}
                        //FIXME: (FINE FOR NOW) for now we use the naviateToPage method, but we should use the navigate method with the dataFileId
                        // onClick={() =>  navigate(generatePath(routes.data_upload_mapping, {dataFileId: String(dataIngestionStore.dataFileId)}))}
                    >
                        Back
                    </Button>
                }
            </Grid>

            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={dataIngestionStore.step}>
                    <Step>
                        <StepLabel>Add file</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Data mapping</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Check</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Finish</StepLabel>
                    </Step>
                </Stepper>
            </Grid>

            <Grid item xs={2} className="text-center">
                {page === 'data_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={!dataIngestionStore.all_required_columns_mapped || isBusy}
                        onClick={async () => {
                            setIsLoading(true);
                            try {
                                dataIngestionStore.setErrorMsg('');
                                await dataIngestionStore.postDataMappingsList();
                                dataIngestionStore.navigateToPage('data_check')
                            } catch (error) {
                                // Check if we get validation errors
                                if (isAxiosError(error) && error.response?.status === 400) {
                                    const data = error.response.data;
                                    if (data.non_field_errors) {
                                        dataIngestionStore.setErrorMsg(String(data.non_field_errors));
                                    } else {
                                        dataIngestionStore.setErrorMsg(String(data));
                                    }
                                } else {
                                    dataIngestionStore.setErrorMsg('Something went wrong');
                                }
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    >
                        {isBusy ? <CircularProgress size={24}/> : 'Next'}
                    </Button>
                }

                {page === 'data_check' && <>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={(isBusy || (!authStore.isMithraStaff && dataIngestionStore.dataFile?.databag_exists === true))}
                        endIcon={<ChevronRight/>}
                        onClick={() => {
                            if (environment.isDemo) {
                                dataIngestionStore.navigateToPage('data_finish');
                                return
                            }

                            setIsLoading(true);
                            dataIngestionStore.startDataMerge()
                                .finally(() => setIsLoading(false));
                        }}
                    >
                        {isBusy ? <CircularProgress size={24}/> : 'Next'}
                    </Button>
                    {dataIngestionStore.dataFile?.databag_exists === true &&
                        <div className="">Databag already exists!</div>
                    }
                </>}
            </Grid>
        </Grid>
    </div>
});
