import '../SalesDemoDashboard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS} from "../../../../components/dashboard/Dashboard.utils";
import {DashboardHeader} from "../../../dashboard/DashboardHeader";
import {SynergySuppliersIcon} from "../../../../components/icons/SynergySuppliersIcon";
import {SynergySpendIcon} from "../../../../components/icons/SynergySpendIcon";
import {SynergyCategoriesIcon} from "../../../../components/icons/SynergyCategoriesIcon";
import {environment} from "../../../../env";

export const SalesDemoSynergyAnalyticsDashboard: React.FC = observer(() => {
    const {bagStore} = useStores();
    const bagId = bagStore.bagId;
    return <>
        {environment.isTest && <h1>SalesDemoSynergyAnalyticsDashboard</h1>}
        <DashboardHeader/>
        <Grid container
              className="dashboard synergy-analytics-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="synergy-suppliers"
                    avatar={<SynergySuppliersIcon/>}
                    title="Common Suppliers"
                    link={generatePath(routes.demo_synergy_suppliers, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="synergy-categories"
                    avatar={<SynergyCategoriesIcon/>}
                    title="Common Categories"
                    link={generatePath(routes.demo_synergy_categories, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="synergy-spend"
                    avatar={<SynergySpendIcon/>}
                    title="Common Spend"
                    link={generatePath(routes.demo_synergy_spend, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
