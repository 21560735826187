import {GoldenRecordStore} from "../../../stores/GoldenRecordStore";
import {makeAutoObservable} from "mobx";
import {GoldenRecordGroupedData, GoldenRecordGroupedState} from "../classes/GoldenRecordGroupedState";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {PageResponse} from "../../../services/ApiTypes";
import {AxiosResponse} from "axios";
import {GOLDEN_RECORD_DUMMY_DATA, GOLDEN_RECORD_DUMMY_DATA_STATS} from "../classes/GoldenRecordDummyData";
import {GoldenRecordPartState} from "../classes/GoldenRecordPartState";

type Filter = any;

export class GoldenRecordDataController {
    readonly grGroups = new PageResponseManager<Filter, GoldenRecordGroupedState, {
        d: GoldenRecordGroupedData,
        filter: Filter
    }>(
        GOLDEN_RECORD_DUMMY_DATA_STATS.golden_records_page_size,
        (page, filter) => Promise.resolve({
            data: {
                count: GOLDEN_RECORD_DUMMY_DATA_STATS.total_golden_records,
                next: 'x',
                previous: 'x',
                results: GOLDEN_RECORD_DUMMY_DATA.map(d => ({filter, d: d as any})),
            }
        } as AxiosResponse<PageResponse<{ d: GoldenRecordGroupedData, filter: Filter }>>),
        ({d, filter}) => {
            const gState: GoldenRecordGroupedState = GoldenRecordGroupedState.build(d, this.grGroups.page, filter);
            gState.setPartStates((d as any).dummy_child_data.map(d => GoldenRecordPartState.build(d, gState)), 1)
            return gState;
        },
    );

    constructor(private goldenRecordStore: GoldenRecordStore) {
        makeAutoObservable(this);
    }
}