import {observer} from "mobx-react-lite";
import {Collapse, IconButton, TableCell, TableRow, Tooltip} from "@mui/material";
import {ExpandMore, KeyboardArrowRight, Visibility} from "@mui/icons-material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import React, {useState} from "react";
import {PpvSubTable} from "./PpvSubTable";
import {getCollapseAnimationTiming} from "../../../components/table/utils";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {useStores} from "../../../stores";
import {getPpvTableColumns} from "./PpvColumns";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";
import {OpportunityStatusDropdown} from "../../../pages/opportunity/components/OpportunityStatusDropdown";
import {OpportunityStatusEnum} from "../../../services/classes/OpportunityClasses";


type Props = {
    groupedRow: PpvGroupRowState
};

export const PpvTableMainRow: React.FC<Props> = observer(({groupedRow}) => {
    const {ppvControllerStore, p} = useStores();
    const open = groupedRow.open;
    const COLUMNS = getPpvTableColumns(p);
    const hasPartData = groupedRow.partStates !== undefined && groupedRow.partStates.length > 0;

    const [loading, setLoading] = useState(false);


    let i = 0;
    return <>
        <TableRow className="ppv-table-main-row">
            <MithraTableCell c={COLUMNS[i++]}
                             onClick={() => ppvControllerStore.ppvPageController.ppvPageDataController.onOpenClosePpvGroup(groupedRow)}>
                <IconButton aria-label="expand row" size="small">
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {groupedRow.data.p_description}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {/*{groupedRow.data.variance_types && groupedRow.data?.variance_types.join(', ')}*/}
                Same description
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                <CurrencyComponent v={groupedRow.data.ppv_opportunity} nDigits={3}/>
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                <Tooltip title={groupedRow.data.opportunity?.note || ''} arrow>
                <span>
                    {groupedRow.data.opportunity?.note && groupedRow.data.opportunity?.note.length > 50 ? `${groupedRow.data.opportunity?.note.substring(0, 50)}...` : groupedRow.data.opportunity?.note}
                </span>
                </Tooltip>
            </MithraTableCell>
            {!p.p.isPpvReadOnly && (
                <MithraTableCell c={COLUMNS[i++]} style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '8px', padding: '0px'}}>
                        <OpportunityStatusDropdown
                            status={groupedRow.data.opportunity?.status || OpportunityStatusEnum.PENDING_REVIEW}
                            setStatus={(status) => ppvControllerStore.changeStatus(groupedRow, status)}
                            loading={loading}
                        />
                    </div>
                    <IconButton onClick={() => {
                        ppvControllerStore.setPpvRowToEdit(groupedRow)
                        ppvControllerStore.setEditNoteDialogOpen(true)
                    }}>
                        <Visibility/>
                    </IconButton>
                </MithraTableCell>
            )}
        </TableRow>

        <TableRow>
            <TableCell
                className={"cell-sub-table" + (hasPartData ? ' has-data' : '')}
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {groupedRow.partStates !== undefined && groupedRow.partStates.length > 0 &&
                    <Collapse in={open} timeout={getCollapseAnimationTiming(groupedRow.partStates)} unmountOnExit>
                        <PpvSubTable groupedRow={groupedRow}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
