import './TaxonomyIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Grid} from "@mui/material";
import {TaxonomyMapping} from './mapping/TaxonomyMapping';
import {TaxonomyCheck} from './mapping/TaxonomyCheck';
import {TaxonomyFinish} from './mapping/TaxonomyFinish';
import {TaxonomyIngestionStepper} from "./TaxonomyIngestionStepper";


type Props = {
    viewer?: boolean
}
export const TaxonomyMappingPage: React.FC<Props> = observer(() => {
    const {taxonomyIngestionStore, p} = useStores();

    useEffect(() => {
        if (!taxonomyIngestionStore.page || taxonomyIngestionStore.page === 'taxonomy_upload') {
            taxonomyIngestionStore.navigateToPage('taxonomy_mapping')
        }
    }, [taxonomyIngestionStore, p])

    return <div className="taxonomy-ingestion data-mapping-page">

        {taxonomyIngestionStore.errorMsg &&
            <Alert severity="error">{taxonomyIngestionStore.errorMsg}</Alert>
        }

        <TaxonomyIngestionStepper defaultPage={'taxonomy_mapping'}></TaxonomyIngestionStepper>

        <Grid container justifyContent="center" className="taxonomy-ingestion-page-content">
            <Grid item xs={10}> {taxonomyIngestionStore.page === 'taxonomy_mapping' && <TaxonomyMapping/>} </Grid>
            <Grid item xs={11}> {taxonomyIngestionStore.page === 'taxonomy_check' && <TaxonomyCheck/>} </Grid>
            <Grid item xs={10}> {taxonomyIngestionStore.page === 'taxonomy_finish' && <TaxonomyFinish/>} </Grid>
        </Grid>
    </div>
});
