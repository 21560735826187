import React from "react";
import {observer} from "mobx-react-lite";
import {List, ListItem, ListItemText, TextField} from "@mui/material";
import {useStores} from "../../../../stores";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {ApprovalHierarchyNode, TaxonomyApprovalDelegate} from "../../../../stores/approval/TaxonomyApprovalDelegate";
import {CategoryChip} from "../../../../components/category-chip/CategoryChip";

type Props = {
    node: ApprovalHierarchyNode
}
export const CreateCategoryModal: React.FC<Props> = observer(({node}) => {
    const {approvalStore} = useStores();

    const [newName, setNewName] = React.useState<string>('');
    const [newDescription, setNewDescription] = React.useState<string>('');
    const [nameError, setNameError] = React.useState<string>('');

    const onSave = () => {
        const err = approvalStore.taxonomyApproval.onSaveCreateNode(node, newName.trim(), newDescription.trim());
        if (err) {
            setNameError(err);
        } else {
            setNameError('');
        }
    }

    const error = Boolean(nameError);

    const level = node.depth + 1;

    const labels = TaxonomyApprovalDelegate.getLocation(node);
    if (newName) {
        labels.push(newName);
    }


    return <EditorModalComponent
        className="taxonomy-modal-component move-category-modal"
        titleId="move-category-modal-title"
        title={`Create a new category`}
        canSave={!error}
        onSave={onSave}
        onClose={() => approvalStore.taxonomyApproval.closeCreateModal()}
        saveLabel={`Add L${level}`}
    >
        {level > 1 && <section className="modal-introduction">
            Create category:
            <List>
                <ListItem>
                    <ListItemText
                        primary={<CategoryChip labels={labels}/>}
                        secondary={newDescription || undefined}
                    />
                </ListItem>
            </List>
        </section>}

        <TextField
            autoFocus
            fullWidth
            id="taxonomy-editor-modal-name-field"
            label="Category name"
            error={Boolean(nameError)}
            helperText={nameError}
            variant="outlined"
            value={newName}
            onChange={e => {
                setNewName(e.target.value);
                setNameError('')
            }}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            multiline
            id="taxonomy-editor-modal-description-field"
            label="Description"
            // error={Boolean(taxonomyEditorStore.updateCategoryDescriptionError)}
            // helperText={descHelperText}
            variant="outlined"
            value={newDescription}
            onChange={e => setNewDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />
    </EditorModalComponent>
})
