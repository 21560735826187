import {makeAutoObservable} from "mobx";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {ApiSuggestionTreeResponse, ApiUpdateSuggestionTreeResponse} from "../services/ApiHelpers";
import {taxonomy_health_check} from "../services/classes/TaxonomyHealthCheckClasses";
import * as d3 from "d3";
import ExtendedD3HierarchyNode = taxonomy_health_check.ExtendedD3HierarchyNode;
import InputData = taxonomy_health_check.ApiTree;
import HealthCheckDataField = taxonomy_health_check.HealthCheckDataField;

/**
 * true means we show it
 */
const HEALTH_CHECK_FILTERS: taxonomy_health_check.Filter[] = [
    undefined,
    d => {
        console.log('CALLED FILTERS label_formatting with', d);
        if(!d?.data.apiValues.review_status) return true;
        return d.data.apiValues.review_status.label_formatting;
    },
    d => {
        console.log('CALLED FILTERS prefix_overlap with', d);
        if(!d?.data.apiValues.review_status) return true;
        return d.data.apiValues.review_status.prefix_overlap;
    },
    d => {
        console.log('CALLED FILTERS category_overlap with', d);
        if(!d?.data.apiValues.review_status) return true;
        return d.data.apiValues.review_status.category_overlap;
    },
]


/**
 * This class will include all the D3 logic for the taxonomy table (JUST D3 AND VIZ LOGIC, STORE WILL HANDLE API).
 * This has been made to keep D3.js code and JS code separated in the stores.
 */
export class TaxonomyTableVizDelegate {
    data: ExtendedD3HierarchyNode | undefined; //This copy is used only for viz
    filter: number | undefined = 0

    constructor() {
        makeAutoObservable(this)
    }

    /**
     * This function applies a filter to the tree structure
     * It writes the results to the viz.filtered field
     *
     * @param node The current node of the depth first search
     * @param hideUncat Whether to hide the uncategorized nodes
     * @param hideFilter The filter to apply
     * @param depth The current depth of the tree
     * @returns Whether the current node should be hidden or not
     */
    static applyFilter(node: ExtendedD3HierarchyNode, hideUncat: boolean, hideFilter: undefined | ((d: ExtendedD3HierarchyNode) => boolean), depth = 0): boolean {
        console.log('applyFilter', node.data.viz.label);

        let hideThis = false;
        let canOpen = false;

        if (hideFilter !== undefined && depth > 0) {
            // We apply the filter to this node
            hideThis = hideThis || !hideFilter(node)
        }

        console.log('applyFilter', node.data.viz.label, 'to', hideThis);
        if (node._children) {
            let showSomeChild = false;
            node._children.forEach(c => {
                const showChild = this.applyFilter(c, hideUncat, hideFilter, depth + 1);
                if(showChild) {
                    showSomeChild = true;
                }
            })
            if(showSomeChild) {
                canOpen = true;
                hideThis = false;
            }
        }

        node.data.viz.filtered = hideThis;
        node.data.viz.canOpen = canOpen;
        return !hideThis;
    }

    setFilter(filter: number) {
        if (!this.data) {
            return
        }
        console.log('setFilter', filter);
        this.filter = filter
        if (filter && filter >= HEALTH_CHECK_FILTERS.length) {
            console.warn(`Cannot apply filter with index ${filter}`)
        } else {
            const hideFilter = HEALTH_CHECK_FILTERS[filter];
            this.data.data.viz.label = '' + new Date()
            TaxonomyTableVizDelegate.applyFilter(this.data, false, hideFilter);
        }
    }

    static processTreeData(data: InputData): ExtendedD3HierarchyNode {
        let id = 1;
        const convertData: (node: InputData) => HealthCheckDataField = (node) => {
            const _id = id++;
            const VISUAL_DATA: taxonomy_health_check.VisualData = {
                id: _id,
                label: node.label,
                collapsed: false,
                filtered: false,
                canOpen: node.children?.length > 0,
                highlighted: false,
                selected: false,
                childSelected: false,
                hasChangesInChildren: false,
            };

            const result: HealthCheckDataField = {
                apiValues: node.values ? {...node.values} : {},
                viz: VISUAL_DATA,
                id: node.id,
                sources: node.sources
            } as any;

            // Hotfix for misaligned datastructure
            if (!result.apiValues.review_status) {
                result.apiValues.review_status = (node as any).review_status
            }

            return result;
        };

        let hierarchy = d3.hierarchy(data) as any;
        hierarchy.eachBefore((node: ExtendedD3HierarchyNode) => {
            node._children = [];
            node.data = convertData(node.data as any);
        })

        return hierarchy
    }

    static initNodes(data: InputData): ExtendedD3HierarchyNode {
        if (!data) {
            throw new Error('Data is undefined')
        }
        const tree: ExtendedD3HierarchyNode = TaxonomyTableVizDelegate.processTreeData(data);


        tree.descendants().forEach((d: ExtendedD3HierarchyNode, i) => {
            const ed = d as unknown as ExtendedD3HierarchyNode;

            // Each node should have a unique ID
            if (ed.data.viz.id === undefined) {
                ed.data.viz.id = i;
            }

            ed.data.viz.filtered = false;

            if (ed.children) {
                ed.data.viz.collapsed = false;
                ed.data.viz.canOpen = true;
                ed._children = ed.children || [];
            } else {
                ed.data.viz.collapsed = false;
                ed.data.viz.canOpen = false;
            }
        })

        tree.eachBefore(n => {
            // TODO: Update this when the change was accepted/rejected
            if (n.data.apiValues?.review_status) {
                n.data.viz.hasChangesInChildren = false;
                if (n.data.apiValues.review_status?.label_formatting || n.data.apiValues.review_status?.prefix_overlap || n.data.apiValues.review_status?.category_overlap) {
                    n.parent?.ancestors().forEach(p => {
                        if (p.data.apiValues.review_status) {
                            p.data.viz.hasChangesInChildren = true;
                        }
                    })
                }
            }
        })

        return tree
    }

    static setHidden(d: ExtendedD3HierarchyNode, hidden: boolean, depth: number): ExtendedD3HierarchyNode {
        d.data.viz.filtered = hidden;
        if (d.children) {
            d.children.forEach(c => this.setHidden(c, hidden, depth + 1));
        }

        return d;
    }

    static exportTreeForPut(node: ExtendedD3HierarchyNode): ApiUpdateSuggestionTreeResponse<m_taxonomy.Data> & {
        id: Number
    } {
        //Make sure that when updating the suggestion it will not append the review_status to the root but it will do it to the children

        return {
            id: Number(node.id),
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.apiValues,
        }
    }

    private static exportTreeForPutChildren(node: ExtendedD3HierarchyNode): ApiSuggestionTreeResponse<m_taxonomy.Data> & {
        id: Number
    } {
        return {
            id: node.data.id,
            label: node.data.viz.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.apiValues,
            sources: node.data.sources,
        }
    }

    static getNodes(data: ExtendedD3HierarchyNode): ExtendedD3HierarchyNode[] {
        let nodes = data.descendants()
            .filter(d => !d.data.viz.filtered)

        // Recalculate the correct y positioning
        // Note: Only used when yAxis is not used, but the nodes are distributed equally
        let i = 0;
        data.eachBefore(n => {
            if (n.data.viz.filtered) {
                return;
            }
            n['index'] = i++;
        })
        return nodes;
    }
}
