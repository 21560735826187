import {styled} from "@mui/material/styles";
import {observer} from "mobx-react-lite";
import {Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {ModalGenericV2} from "./ModalGenericV2";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(ModalGenericV2)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalDeleteCategory: React.FC = observer(() => {

    const {taxonomyEditorStore, taxonomyHealthCheckStore} = useStores();


    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="update-model-component"
            title="Deletge category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={() => console.log('delete cat')}
            onClose={() => taxonomyHealthCheckStore.isDeleteCategoryModalOpen = false}
            saveLabel="Delete"
        >
            <Typography variant={'body1'}>
                Deleting '{taxonomyHealthCheckStore.selectedCategory?.data.viz.label}' category from the dataset. This
                action is
                irreversible.
            </Typography>

        </StyledEditorModalComponent>
    );
})