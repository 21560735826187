import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {
    AdvancedFilter,
    AdvancedFilterData,
    AdvancedGeoData,
    AdvancedOpportunityData,
    AdvancedSpendData,
    AdvancedSpendKPI,
    AdvancedSpendPerGroup,
    AdvancedSupplierBreakdownData,
    AdvancedTreeData,
    CategoryFilter,
    DonutChartSupplierSpendData,
    ParentSupplierSearchResults,
    parseAdvancedSpendPerGroup,
    SupplierSearchResponse
} from "../../services/ApiTypes";
import {Country, countryToContinent} from "../../components/visualization/bubble-map/countries";
import {HashedLoadingDataWrapper, LoadingDataWrapper} from "../../utils/LoadingDataWrapper";
import {AxoisRequestManager, RequestManager} from "./RequestManager";
import {from, Subject, switchMap, tap} from "rxjs";
import moment from "moment";
import {BusinessUnit} from "../../services/classes/MaterializedClasses";

// const parseTime = d3.timeParse("%Y-%m-%d");

declare type TreeDataFilter = Omit<AdvancedFilter, 'category'>

export type Option = {
    value: string
    label: string
    subLabel: string
    subTotalSpend?: number
}

type ParentSupplierData = ParentSupplierSearchResults & { total_spend: number };

export class BagAdvancedInsightsManager {
    public databagId?: number;

    public initialFilter: AdvancedFilter = {};
    public filters: AdvancedFilter = {}

    public opportunity_ordering: string;
    public sup_breakdown_ordering: string;

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // treeData
    public advancedSpendTreeRM = new AxoisRequestManager<[number, TreeDataFilter], AdvancedTreeData[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTree(databag_id, filters))
    )

    get treeData(): AdvancedTreeData {
        let result = this.advancedSpendTreeRM.result;
        if (!result) return {value: 0, label: '', children: []};
        return {
            value: result.reduce((sum, element) => sum + element.value, 0),
            label: 'All',
            children: result,
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // geoData
    public advancedSpendGeoRM = new AxoisRequestManager<[number, AdvancedFilter], AdvancedGeoData[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendGeo(databag_id, filters))
    )

    get geoData(): AdvancedGeoData[] {
        return this.advancedSpendGeoRM.result ?? [];
    }

    get geoDataCountryStringArr(): string[] {
        return this.geoData.map(x => x.country);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendPerSupplierData
    public advancedSpendTimePerSupplierRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendPerGroup[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTimePerSupplier(databag_id, filters)
            .then(resp => {
                const elements = resp.data.map<AdvancedSpendPerGroup>(x => ({
                        s_id: x.s_id,
                        spend: x.spend,
                        date: new Date(x.date),
                        group: x.group,
                    })
                );
                this.spendPerSupplierData.updateData(elements);
                return elements
            })
        )
    )
    /**
     * Shallow observable array of AdvancedSpendPerGroup
     */
    public readonly spendPerSupplierData = new HashedLoadingDataWrapper<AdvancedSpendPerGroup>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendDonutData
    public advancedSpendDonutDataRM = new AxoisRequestManager<[number, AdvancedFilter], DonutChartSupplierSpendData[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedDonutData(databag_id, filters))
    )

    get spendDonutData(): DonutChartSupplierSpendData[] {
        return this.advancedSpendDonutDataRM.result ?? [];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendPerActiveL1Data
    public spendPerActiveL1DataRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendPerGroup[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTimePerCategory(databag_id, filters, "active_l1")
            .then(resp => {
                const elements = resp.data.map(x => parseAdvancedSpendPerGroup(x));
                this.spendPerActiveL1Data.updateData(elements);
                return elements;
            })
        )
    )
    public spendPerActiveL1Data = new HashedLoadingDataWrapper<AdvancedSpendPerGroup>();

    get spendPerActiveL1DataStringArr(): string[] {
        if (!this.spendPerActiveL1DataRM.result) return []
        return Array.from(new Set(this.spendPerActiveL1DataRM.result.map((x) => x.group ? x.group : '-')))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendPerActiveL2Data
    public spendPerActiveL2DataRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendPerGroup[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTimePerCategory(databag_id, filters, "active_l2")
            .then(resp => resp.data.map(x => parseAdvancedSpendPerGroup(x)))
        )
    )

    get spendPerActiveL2DataStringArr(): string[] {
        if (!this.spendPerActiveL2DataRM.result) return []
        return Array.from(new Set(this.spendPerActiveL2DataRM.result.map((x) => x.group ? x.group : '-')))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendPerActiveL3Data
    public spendPerActiveL3DataRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendPerGroup[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTimePerCategory(databag_id, filters, "active_l3")
            .then(resp => resp.data.map(x => parseAdvancedSpendPerGroup(x)))
        )
    )

    get spendPerActiveL3DataStringArr(): string[] {
        if (!this.spendPerActiveL3DataRM.result) return []
        return Array.from(new Set(this.spendPerActiveL3DataRM.result.map((x) => x.group ? x.group : '-')))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendPerActiveL4Data
    public spendPerActiveL4DataRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendPerGroup[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendTimePerCategory(databag_id, filters, "active_l4")
            .then(resp => resp.data.map(x => parseAdvancedSpendPerGroup(x)))
        )
    )

    get spendPerActiveL4DataStringArr(): string[] {
        if (!this.spendPerActiveL4DataRM.result) return []
        return Array.from(new Set(this.spendPerActiveL4DataRM.result.map((x) => x.group ? x.group : '-')))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // spendConcentrationData
    public advancedSpendConcentrationDataRM = new RequestManager<[number, AdvancedFilter], AdvancedSpendData[]>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendConcentrationData(databag_id, filters)
            .then(resp => {
                const elements = resp.data;
                this.spendConcentrationData.updateData(elements);
                return elements;
            })
        )
    )
    public spendConcentrationData = new HashedLoadingDataWrapper<AdvancedSpendData>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // kpi
    public getAdvancedSpendKPIRM = new AxoisRequestManager<[number, AdvancedFilter], [AdvancedSpendKPI] | []>(([databag_id, filters]) =>
        from(this.api.getAdvancedSpendKPI(databag_id, filters))
    )

    get kpi(): AdvancedSpendKPI | null {
        return this.getAdvancedSpendKPIRM.result?.at(0) ?? null;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // opportunityData
    public advancedOpportunityDataRM = new RequestManager<[number, AdvancedFilter, string], AdvancedOpportunityData[]>(([databag_id, filters, ordering]) =>
        from(this.api.getAdvancedOpportunityData(databag_id, filters, ordering)
            .then(resp => {
                const elements = resp.data;
                this.opportunityData.updateData(elements);
                return elements;
            }))
    )
    public opportunityData = new LoadingDataWrapper<AdvancedOpportunityData>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // supplierBreakdownData
    readonly advancedSupplierBreakdownDataRM = new RequestManager<[number, AdvancedFilter, string], AdvancedSupplierBreakdownData[]>(([databag_id, filters, ordering]) =>
        from(this.api.getAdvancedSupplierBreakdownData(databag_id, filters, ordering)
            .then(data => {
                this.supplierBreakdownData.updateData(data);
                return data;
            })
        )
    )
    public supplierBreakdownData = new LoadingDataWrapper<AdvancedSupplierBreakdownData>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    public searchSupplierResult: Option[] | undefined = undefined;
    public searchSupplierSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchSupplierCachedOptions: Option[] = [];
    public readonly searchSupplierSubject: Subject<string> = new Subject();
    public readonly searchSupplierPipe = this.searchSupplierSubject.pipe(
        switchMap((searchTerm) => from(this.searchSupplier(searchTerm))),
        tap((results) => {
            this.setSearchSupplierSearchResults(results)
        }),
    )

    public searchParentSupplierResult: Option[] | undefined = undefined;
    public searchParentSupplierSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchParentSupplierCachedOptions: Option[] = [];
    private readonly searchParentSupplierSubject: Subject<string> = new Subject();
    public readonly searchParentSupplierPipe = this.searchParentSupplierSubject.pipe(
        switchMap((searchTerm) => from(this.searchParentSupplier(searchTerm))),
        tap((results) => this.setSearchParentSupplierSearchResults(results)),
    )

    public searchBusinessUnitResult: Option[] | undefined = undefined;
    public searchBusinessUnitSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchBusinessUnitCachedOptions: Option[] = [];
    public readonly searchBusinessUnitSubject: Subject<string> = new Subject();
    public readonly searchBusinessUnitPipe = this.searchBusinessUnitSubject.pipe(
        switchMap((searchTerm) => from(this.searchBusinessUnit(searchTerm))),
        tap((results) => {
            this.setSearchBusinessUnitSearchResults(results)
        }),
    )

    public searchCDescriptionResult: Option[] | undefined = undefined;
    public searchCDescriptionSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchCDescriptionCachedOptions: Option[] = [];
    public readonly searchCDescriptionSubject: Subject<string> = new Subject();
    public readonly searchCDescriptionPipe = this.searchCDescriptionSubject.pipe(
        switchMap((searchTerm) => from(this.searchCDescription(searchTerm))),
        tap((results) => {
            this.setSearchCDescriptionSearchResults(results)
        }),
    )


    // noinspection JSUnusedLocalSymbols
    constructor(private api: MithraMaterializedApi, private bagStore: BagStore, private profileStore: ProfileStore) {
        makeAutoObservable(this);

        // Fix last year case if filters
        if (profileStore.p.advDashboardInitialDateFilter === 'last_year') {
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            const end = new Date(new Date().getFullYear(), 0, 0);
            this.initialFilter.date_range = [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')];
        }

        // Set values that does not need reaction
        this.opportunity_ordering = '-spend';
        this.sup_breakdown_ordering = '';

        // Register reactions
        reaction(() => this.databagId, (databag) => {
            if (!databag) return;
            this.reloadData();
        });

        reaction(() => this.opportunity_ordering, (opportunity_ordering) => {
            if (!this.databagId) return;
            this.advancedOpportunityDataRM.request([this.databagId, this.filters, opportunity_ordering]);
        });
        reaction(() => this.sup_breakdown_ordering, (sup_breakdown_ordering) => {
            if (!this.databagId) return;
            this.advancedSupplierBreakdownDataRM.request([this.databagId, this.filters, sup_breakdown_ordering]);
        });

        // Set filters to load the data for the first time
        this.filters = {...this.initialFilter}
        this.reloadData();
    }

    reloadData(exceptData?: ('tree' | 'geo')[]) {
        const filters = this.filters;
        const treeFilters = this.treeDataFilters;
        const geoFilter = this.geoFilter;
        const databag = this.databagId;
        if (!databag) return;

        // Reload all data
        this.advancedSpendTimePerSupplierRM.request([databag, filters]);
        this.spendPerActiveL1DataRM.request([databag, filters]);
        this.spendPerActiveL2DataRM.request([databag, filters]);
        this.spendPerActiveL3DataRM.request([databag, filters]);
        this.spendPerActiveL4DataRM.request([databag, filters]);
        this.advancedSpendDonutDataRM.request([databag, filters]);
        this.advancedSpendConcentrationDataRM.request([databag, filters]);
        this.getAdvancedSpendKPIRM.request([databag, filters]);
        this.advancedOpportunityDataRM.request([databag, filters, this.opportunity_ordering]);
        this.advancedSupplierBreakdownDataRM.request([databag, filters, this.sup_breakdown_ordering]);

        if (!exceptData?.includes('geo')) this.advancedSpendGeoRM.request([databag, geoFilter]);
        if (!exceptData?.includes('tree')) this.advancedSpendTreeRM.request([databag, treeFilters]);

        this.setSearchParentSupplierSearchTerm(null);
    }

    get isLoading() {
        return this.advancedSpendTreeRM.busy;
    }

    get continentFilterData(): string[] {
        const continentData: string[] = this.geoDataCountryStringArr.map((country: string) => {
            return countryToContinent(country as Country);
        });

        return Array.from(new Set(continentData));
    }

    get groupedSpendPerActiveL1FilterDataOriginal(): AdvancedFilterData[] {
        // activeL1Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL1Data.elements);
    }

    get groupedSpendPerActiveL2FilterDataOriginal(): AdvancedFilterData[] {
        // activeL2Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL2DataRM.result ?? []);
    }

    get groupedSpendPerActiveL3FilterDataOriginal(): AdvancedFilterData[] {
        // activeL3Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL3DataRM.result ?? []);
    }

    get groupedSpendPerActiveL4FilterDataOriginal(): AdvancedFilterData[] {
        // activeL4Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL4DataRM.result ?? []);
    }

    get onlyCountryFiltered() {
        return this.filters.country !== undefined
            && this.filters.s_ids === undefined
            && this.filters.sp_ids === undefined
            && this.filters.business_unit_ids === undefined
            && this.filters.category === undefined
            && this.filters.date_range === undefined
            && this.filters.c_descriptions === undefined
    }

    get geoFilter(): AdvancedFilter {
        // When only the countries are filtered, do not hide the other countries
        return this.onlyCountryFiltered ? {} : this.filters;
    }

    get treeDataFilters(): TreeDataFilter {
        // When only the countries are filtered, do not hide the other countries
        const treeDataFilters: TreeDataFilter = {...this.filters};
        delete treeDataFilters['category'];
        return treeDataFilters;
    }

    setDatabagId(databagId: number | undefined) {
        console.log('BagAdvancedInsightsManager.setDatabagId', databagId, this.databagId);
        if (databagId !== this.databagId) {
            this.filters = {...this.initialFilter};
        }
        this.databagId = databagId;
    }

    setSearchSupplierSearchTerm(searchTerm: string) {
        this.searchSupplierSearchTerm = searchTerm;
        this.searchSupplierSubject.next(searchTerm);
    }

    setSearchParentSupplierSearchTerm(searchTerm: string | null) {
        this.searchParentSupplierSearchTerm = searchTerm ?? this.searchParentSupplierSearchTerm;
        this.searchParentSupplierSubject.next(this.searchParentSupplierSearchTerm);
    }

    setSearchBusinessUnitSearchTerm(searchTerm: string) {
        this.searchBusinessUnitSearchTerm = searchTerm;
        this.searchBusinessUnitSubject.next(searchTerm);
    }

    setSearchCDescriptionSearchTerm(searchTerm: string) {
        this.searchCDescriptionSearchTerm = searchTerm;
        this.searchCDescriptionSubject.next(searchTerm);
    }

    setSearchSupplierSearchResults(response: SupplierSearchResponse) {
        this.searchSupplierResult = response.results.map((supplier) => ({
            value: supplier.s_id,
            label: supplier.s_name,
            subLabel: supplier.s_id,
        }))
    }

    setSearchParentSupplierSearchResults(data: ParentSupplierData[]) {
        this.searchParentSupplierResult = data.map((parentSupplier) => ({
            value: parentSupplier.sp_id,
            label: parentSupplier.sp_name,
            subLabel: parentSupplier.sp_id,
            subTotalSpend: parentSupplier.total_spend,
        }))
    }

    setSearchBusinessUnitSearchResults(response: BusinessUnit[]) {
        this.searchBusinessUnitResult = response.map<Option>((bu) => ({
            value: String(bu.id),
            label: bu.bu_name,
            subLabel: '',
        }))
    }

    setSearchCDescriptionSearchResults(response: string[]) {
        this.searchCDescriptionResult = response.map<Option>((cc_description) => ({
            value: String(cc_description),
            label: cc_description || '(Empty)',
            subLabel: '',
        }))
    }

    setSearchSupplierCachedOptions(cachedOptions: Option[]) {
        this.searchSupplierCachedOptions = cachedOptions;
    }

    setSearchParentSupplierCachedOptions(cachedOptions: Option[]) {
        this.searchParentSupplierCachedOptions = cachedOptions;
    }

    setSearchBusinessUnitCachedOptions(cachedOptions: Option[]) {
        this.searchBusinessUnitCachedOptions = cachedOptions;
    }

    setSearchCDescriptionCachedOptions(cachedOptions: Option[]) {
        this.searchCDescriptionCachedOptions = cachedOptions;
    }

    setOrderingWithPrefix(ordering: string, prefix: string) {
        if (prefix === 'opportunity_')
            this.opportunity_ordering = ordering;
        else if (prefix === 'sup_breakdown_')
            this.sup_breakdown_ordering = ordering;
        return;
    }

    getOrderingWithPrefix(prefix: string) {
        if (prefix === 'opportunity_')
            return this.opportunity_ordering;
        else if (prefix === 'sup_breakdown_')
            return this.sup_breakdown_ordering;
        return '';
    }

    getActiveL1Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL1FilterDataOriginal, lookup);
    }

    getActiveL2Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL2FilterDataOriginal, lookup);
    }

    getActiveL3Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL3FilterDataOriginal, lookup);
    }

    getActiveL4Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL4FilterDataOriginal, lookup);
    }

    reset() {
        this.setSearchSupplierSearchTerm('')
        this.setSearchBusinessUnitSearchTerm('')
        this.setSearchCDescriptionSearchTerm('')
        this.filters = {...this.initialFilter};
        this.reloadData();
    }

    dateFilterChanged = (startDate: string | undefined, endDate: string | undefined) => {
        this.filters.date_range = startDate && endDate ? [startDate, endDate] : undefined;
        this.reloadData();
    }

    categoryFilterChanged = (categories: CategoryFilter | null) => {
        this.filters.category = categories && categories.length > 0 ? categories : undefined;
        this.reloadData();
    }

    onTreeMapCategoryFilterChanged = (categories: CategoryFilter | null) => {
        // Update all other data elements, except the tree map
        this.filters.category = categories && categories.length > 0 ? categories : undefined;
        this.reloadData(['tree']);
    }

    countryFilterChangedNew = (selectedCountries: string[]) => {
        this.filters.country = Array.from(new Set(selectedCountries));
        this.reloadData();
    }

    countryFilterChanged = (country?: string[]) => {
        this.filters.country = country && country.length > 0 ? country : undefined;
        this.reloadData();
    }

    supplierFilterChanged = (s_ids?: string[]) => {
        this.filters.s_ids = s_ids && s_ids.length > 0 ? s_ids : undefined;
        this.reloadData();
    }

    parentSupplierFilterChanged = (sp_ids?: string[]) => {
        this.filters.sp_ids = sp_ids && sp_ids.length > 0 ? sp_ids : undefined;
        this.reloadData();
    }

    businessUnitFilterChanged = (bu_ids?: string[]) => {
        this.filters.business_unit_ids = bu_ids && bu_ids.length > 0 ? bu_ids : undefined;
        this.reloadData();
    }

    CDescriptionFilterChanged = (c_descriptions?: string[]) => {
        this.filters.c_descriptions = c_descriptions && c_descriptions.length > 0 ? c_descriptions : undefined;
        this.reloadData();
    }

    breakdownFilterChanged = (row?: AdvancedSupplierBreakdownData) => {
        this.filters.s_ids = row?.s_id ? [row.s_id] : undefined;
        this.filters.sp_ids = row?.sp_id ? [row.sp_id] : undefined;
        this.filters.country = row?.s_country_code ? [row.s_country_code] : undefined;
        this.filters.category = (row?.active_l1 || row?.active_l2 || row?.active_l3 || row?.active_l4)
            ? [row.active_l1, row.active_l2, row.active_l3, row.active_l4]
            : undefined;
        // TODO [CAT-2098]: How to deal with click on breakdown table (w.r.t. cc_description and business_unit_ids?)
        //  BU_Id is used in filter, but not send by BE
        // TODO: Is this needed? this.filters.c_descriptions = row?.c_description ? [row.c_description] : undefined;
        this.reloadData();
    }

    async searchSupplier(supplier: string): Promise<SupplierSearchResponse> {
        if (!this.databagId) throw new Error('DatabagId is not set');
        const response = await this.api.searchSupplier(this.databagId, supplier);
        return response.data;
    }

    async searchParentSupplier(parentSupplier: string): Promise<ParentSupplierData[]> {
        const databagId = this.profileStore.p.hardcodedCombinedBagId ?? this.databagId;
        if (!databagId) throw new Error('DatabagId or hardcodedCombinedBagId is not set');
        const psFilter = {...this.filters};
        delete psFilter['sp_ids'];
        const response = await this.api.searchInsightParentSupplier(databagId, psFilter, parentSupplier);
        return response.data.map<ParentSupplierData>(d => ({
            ...d,
            total_spend: Number(d.total_spend),
        }));
    }

    async searchBusinessUnit(businessUnit: string): Promise<BusinessUnit[]> {
        if (!this.databagId) throw new Error('DatabagId is not set');
        // TODO: We do not allow search for now
        const response = await this.api.listBusinessUnitData(this.databagId);
        return response.data;
    }

    async searchCDescription(search: string): Promise<string[]> {
        if (!this.databagId) throw new Error('DatabagId is not set');
        const filters = {...this.filters}
        delete filters.c_descriptions;
        const response = await this.api.searchInsightCDescriptions(this.databagId, filters, search);
        return response.data;
    }

    private static calcGroupedSpendFilterDataOriginal(data: AdvancedSpendPerGroup[]): AdvancedFilterData[] {
        const resultMap = new Map<string, AdvancedFilterData>();

        data.forEach((current: AdvancedSpendPerGroup) => {
            if (current.group === '') {
                return;
            }
            const existing = resultMap.get(current.group);
            if (existing) {
                existing.spend += current.spend;
            } else {
                resultMap.set(current.group, {id: current.s_id, name: current.group, spend: current.spend});
            }
        });

        return Array.from(resultMap.values());
    }

    private static calcGroupedSpendFilterData(data: AdvancedSpendPerGroup[]): string[] {
        const resultMap = new Map<string, AdvancedFilterData>();

        data.forEach((current: AdvancedSpendPerGroup) => {
            if (current.group === '') {
                return;
            }
            const existing = resultMap.get(current.group);
            if (existing) {
                existing.spend += current.spend;
            } else {
                resultMap.set(current.group, {id: current.s_id, name: current.group, spend: current.spend});
            }
        });

        const supplierIds: (string | undefined)[] = Array.from(resultMap.values()).map(item => item.id ? item.id.toString() : undefined);
        return supplierIds.filter(id => id !== undefined) as string[];
    }

    public static calcActiveSpend(data: AdvancedFilterData[], lookup: string): number {
        const obj = data.find((item) => item.name === lookup);
        return obj?.spend || 0;
    }

    public static getCountrySpend(geoData: AdvancedGeoData[], countryId: string): number {
        const countryArr = geoData.map(x => ({name: x.country, spend: x.spend}))
        // eslint-disable-next-line eqeqeq
        const country = countryArr.find((country) => country.name == countryId);
        return country?.spend || 0;
    }

    public static calculateContinentSpend(geoData: AdvancedGeoData[], continent: string): number {
        const continentData: AdvancedGeoData[] = geoData.filter(
            (country) => countryToContinent(country.country) === continent
        );

        return continentData.reduce((total, country) => {
            return total + BagAdvancedInsightsManager.getCountrySpend(geoData, country.country);
        }, 0);
    };
}
