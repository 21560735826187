import React from "react";
import {observer} from "mobx-react-lite";
import {ppvOpportunityController} from "../PpvOpportunityController";
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {LineChart} from "../../../../../components/visualization/line-chart/LineChart";
import {BarChartV2} from "../../../../../components/visualization/bar-chart/BarChart2";

export const PpvOpportunityDataComponent: React.FC = observer(() => {
    const opp = ppvOpportunityController.ppvOpp;

    return <>
        <Button variant="contained">
            Filter (TODO)
        </Button>

        <Grid container className="ppv-opp-overview-cards" spacing={4}>
            <Grid item xs={6}>
                <Card className="opp-graph-card spend-over-time" variant="outlined">
                    <div className="opp-graph-header">
                        <Typography variant="h3" className="title">SPEND OVER TIME</Typography>
                        <Typography variant="caption">Last X years (TODO)</Typography>
                    </div>
                    <CardContent>
                        {/* Line chart */}
                        <h6>TODO: Simplify and polish</h6>
                        <LineChart
                            graphHeight={250}
                            graphWidth={450}
                            dataWrapper={ppvOpportunityController.spendOverTimeData}/>
                        {/*{'' + JSON.stringify(toJS(ppvOpportunityController.spendOverTimeData.elements))}*/}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className="opp-graph-card" variant="outlined">
                    <div className="opp-graph-header">
                        <Typography variant="h3" className="title">SPEND DISTRIBUTION</Typography>
                        <Typography variant="caption">2023-X (TODO)</Typography>
                    </div>
                    <CardContent>
                        <h6>TODO: Polish</h6>
                        <BarChartV2 data={ppvOpportunityController.spendDistributionData} options={{
                            height: 250,
                            width: 450,
                        }}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        <Button variant="contained">
            TODO: Original table
        </Button>
    </>
});