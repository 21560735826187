import React from "react";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../../routing/routes";
import {lookupHomePath} from "../../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../../components/main/MenuItems";

export const SalesDemoSynergyMainMenu: React.FC = () => {
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const hasId2 = params['id2'] !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, params) : routes.home,
            active: location.pathname === routes.home || location.pathname === routes.job_manage,
            className: '',
        },
        {
            label: 'Synergy Analytics',
            link: hasId1 ? generatePath(routes.demo_synergy_analytics_dashboard, params) : routes.home,
            active: (
                location.pathname === routes.demo_synergy_analytics_dashboard
                || location.pathname === routes.demo_synergy_suppliers
                || location.pathname === routes.demo_synergy_categories
                || location.pathname === routes.demo_synergy_spend
            ),
            className: '',
        },
        {
            label: 'Merge',
            link: hasId1 ? generatePath(routes.demo_merge_dashboard, params) : routes.synergy_v3,
            active: (
                location.pathname === routes.demo_merge_dashboard
                || location.pathname === routes.demo_merge_taxonomy_viewer
                || location.pathname === routes.demo_merge_taxonomy_viewer_id
                || location.pathname === routes.demo_merge_taxonomy_builder
                || location.pathname === routes.demo_merge_taxonomy_builder_id
                || location.pathname === routes.demo_merge_review
                || location.pathname === routes.demo_merge_review_result
                || location.pathname === routes.demo_merge_taxonomy_mapper
                || location.pathname === routes.demo_merge_taxonomy_mapper_review
                || location.pathname === routes.demo_merge_cat_review
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_synergy_opportunities_id, params) : routes.home,
            active: !isHome && location.pathname === routes.demo_synergy_opportunities_id,
            isTrial: false,
            className: 'menu-item-2nd-last',
        },
        // {
        //     label: 'Report',
        //     link: hasId1 ? generatePath(routes.job_report, params) : routes.home,
        //     active: !isHome && location.pathname === routes.job_report,
        //     isTrial: true,
        //     className: 'menu-item-last',
        // },
    ]
    return <MenuItems className="full-main-menu" items={menu}/>
}
