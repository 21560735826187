import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {EditorModalComponent} from "../EditorModalComponent";
import {TextField} from "@mui/material";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {
    TaxonomyTreeEditorController
} from "../../../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyTreeEditorController";

export const CreateCategoryModal: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const onSave = () => taxonomyEditorStore.saveNewCategory();
    const parent = taxonomyEditorStore.focus;
    return <EditorModalComponent
        className="taxonomy-modal-component create-category-modal"
        titleId={"taxonomy-editor-modal-title"}
        title={`Create new category at L${taxonomyEditorStore.focusLevel}`}
        canSave={taxonomyEditorStore.canSaveNewCategory}
        onSave={onSave}
        onClose={() => taxonomyEditorStore.setCreateCategoryMode(false)}
    >
        <section className="modal-introduction">
            {parent && <>
                <p>The new category will be created underneath the category:</p>
                <PartCategorizationComponent cats={TaxonomyTreeEditorController.getLabels(parent).slice(1)}/>
            </>}
            {!parent && <p>Add this category to the root of the tree</p>}
        </section>

        <TextField
            autoFocus
            fullWidth
            id="taxonomy-editor-modal-name-field"
            label="New category name"
            error={Boolean(taxonomyEditorStore.newCategoryNameError)}
            helperText={taxonomyEditorStore.newCategoryNameError}
            variant="outlined"
            value={taxonomyEditorStore.newCategoryName}
            onChange={e => taxonomyEditorStore.setNewCategoryName(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            id="taxonomy-editor-modal-description-field"
            label="Description"
            error={Boolean(taxonomyEditorStore.newCategoryDescriptionError)}
            helperText={taxonomyEditorStore.newCategoryDescriptionError}
            variant="outlined"
            value={taxonomyEditorStore.newCategoryDescription}
            onChange={e => taxonomyEditorStore.setNewCategoryDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />
    </EditorModalComponent>
})
