import {PpvPart} from "../../../../services/classes/MaterializedClasses";
import {makeAutoObservable} from "mobx";
import {PpvGroupRowState} from "./PpvGroupRowState";


export class PpvPartRowState {
    get id() {
        return this.data.id;
    }

    constructor(
        public parentRow: PpvGroupRowState | undefined,
        public data: PpvPart,
    ) {
        makeAutoObservable(this)
    }

    public static build(part: PpvPart, parentRow: PpvGroupRowState | undefined) {
        return new PpvPartRowState(
            parentRow,
            part,
        )
    }
}