import {Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";

type Props = {
    isOpen: boolean
}

export const ModalMoveTransaction: React.FC<Props> = observer(({isOpen}) => {
    const {contractAnalyticsStore} = useStores();

    const modalContractsDetailsData = contractAnalyticsStore.modalMoveTransactionData;

    if (!modalContractsDetailsData) {
        return null;
    }


    return (
        <GenericModalVertical
            titleId={""}
            className="move-transaction-modal"
            isOpen={isOpen}
            onSave={() => {
                contractAnalyticsStore.toggleMoveModal()
            }}
            saveTitle={"Move"}
            onCancel={() => {
                contractAnalyticsStore.toggleMoveModal()
            }}
            title={"Move transaction"}
        >
            <Grid container>
                <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <Typography variant={'body1'}>Are you sure about moving transaction
                        "{modalContractsDetailsData.description}" to out of
                        contract</Typography>
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
})
