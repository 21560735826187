import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {makeAutoObservable} from "mobx";
import {
    ByApprovedRowResponse,
    CategoryLabel,
    Cluster,
    Company,
    DimensionValue,
    DOI,
    DOIs,
    getAvoidanceValue,
    getSavingsValue,
    getTargetValue,
    LeverGroup,
    PerClustercumSumRowResponse,
    PerClusterRowResponse,
    PerCompanyLeverRowResponse,
    PerDoiRowResponse,
    SavingValue,
    TargetValues,
    TValuePerClusterMonthRowResponse,
    TValuePerCompanyRowResponse,
    TValuePerMonthRowResponse,
    ValueType
} from "../ApiTypes";
import {sum} from "../../utils/js-utils";
import {sitStores} from "../../stores";
import {CompanyConfigurationStore} from "./CompanyConfigurationStore";
import {AutocompleteChangeReason} from "@mui/base/AutocompleteUnstyled/useAutocomplete";

type FY = { filters: string[][], year: number };

function thisMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth());
}

export class FinancialDashboardStore {

    activeFilteredCompanies: Company[] = [];
    activeLeverGroups: LeverGroup[] = [];
    activeCategories: CategoryLabel[] = [];

    year = 2024
    month = thisMonth()

    activeValueType: ValueType = 'rolling'
    probabilityAdjusted = false
    scope: 'local' | 'global' | '' = ''
    doi: DOI | null = null
    approved: 'approved' | 'pending' | '' = ''

    // Target
    readonly fpPerCompanyTargetRM = new AxoisRequestManager<FY, TValuePerCompanyRowResponse[]>(
        ({filters, year}) => from(this.api.fpPerCompanyTarget(year, filters))
    );
    readonly perCusterTargetRM = new AxoisRequestManager<FY, TValuePerClusterMonthRowResponse[]>(
        ({filters, year}) => from(this.api.perCusterTarget(year, filters))
    );
    readonly fpTargetPerMonthRM = new AxoisRequestManager<FY, TValuePerMonthRowResponse[]>(
        ({filters, year}) => from(this.api.fpTargetPerMonth(year, filters))
    );
    readonly contributionPerCompanyLeverRM = new AxoisRequestManager<FY, PerCompanyLeverRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionPerCompanyLeverRows(year, filters))
    );
    readonly contributionPerClusterRM = new AxoisRequestManager<FY, PerClusterRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionPerClusterRows(year, filters))
    );
    readonly contributionPerClusterCumSumRM = new AxoisRequestManager<FY, PerClustercumSumRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionPerClusterCumSumRows(year, filters))
    );
    // Initiative contributions
    readonly contributionPerDoiCumSumRM = new AxoisRequestManager<FY, PerDoiRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionPerDoiCumSumRows(year, filters))
    );
    readonly contributionPerDoiSumRM = new AxoisRequestManager<FY, PerDoiRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionPerDoiSumRows(year, filters))
    );
    readonly contributionApprovedRM = new AxoisRequestManager<FY, ByApprovedRowResponse[]>(
        ({filters, year}) => from(this.api.initiativeContributionApproved(year, filters))
    );

    constructor(
        private api: SitApi,
        private ccStore: CompanyConfigurationStore,
    ) {
        makeAutoObservable(this, {});
    }

    reloadData(onlyIfEmpty = false) {
        if (onlyIfEmpty && (this.fpTargetPerMonthRM.result !== undefined || this.fpTargetPerMonthRM.busy))
            return;
        // Target
        this.fpTargetPerMonthRM.request({filters: this.targetFilters, year: this.year});
        this.fpPerCompanyTargetRM.request({filters: this.targetFilters, year: this.year});
        this.perCusterTargetRM.request({filters: this.targetFilters, year: this.year});
        // For initiative values, filters are in financial relationship
        this.contributionPerCompanyLeverRM.request({filters: this.initiativeFilters, year: this.year});
        this.contributionPerClusterRM.request({filters: this.initiativeFilters, year: this.year});
        this.contributionPerClusterCumSumRM.request({filters: this.initiativeFilters, year: this.year});
        this.contributionPerDoiCumSumRM.request({filters: this.initiativeFilters, year: this.year});
        this.contributionPerDoiSumRM.request({filters: this.initiativeFilters, year: this.year});
        this.contributionApprovedRM.request({filters: this.initiativeFilters, year: this.year});
    }

    get months(): Date[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => new Date(this.year, m - 1));
    }

    get lastMonth() {
        const lastDayPrevMonth = new Date(this.month);
        lastDayPrevMonth.setDate(0);
        return new Date(lastDayPrevMonth.getFullYear(), lastDayPrevMonth.getMonth());
    }

    get activeTargetValue(): TargetValues {
        return getTargetValue(this.activeValueType);
    }

    get activeSavingsValue(): SavingValue {
        return getSavingsValue(this.activeValueType, this.probabilityAdjusted);
    }

    get activeAvoidanceValue() {
        return getAvoidanceValue(this.activeValueType, this.probabilityAdjusted);
    }

    get activeFilteredCompanyIds(): number[] {
        return this.activeFilteredCompanies.map(c => c.id);
    }

    get approvedContribution(): number {
        const row = this.contributionApprovedRM.result?.find(r => r.approved);
        if (row) return row[this.activeSavingsValue];
        return 0;
    }

    get notApprovedContribution(): number {
        const row = this.contributionApprovedRM.result?.find(r => !r.approved);
        if (row) return row[this.activeSavingsValue];
        return 0;
    }

    get totalTargetOfYear(): number {
        return this.cumulatedTargetData.at(-1)?.value ?? 0;
    }

    get cumulatedTargetData(): { month: Date, value: number }[] {
        if (!this.fpTargetPerMonthRM.result) return [];
        return this.fpTargetPerMonthRM.result.map(r => ({
            month: new Date(r.month),
            value: Number(r[this.activeTargetValue]),
        }));
    }

    get targetDataPerCluster(): {
        clusterId: number,
        clusterTitle: string,
        data: { month: Date, value: number }[]
    }[] | null {
        const respData = this.perCusterTargetRM.result;
        if (!respData) return null;
        const clusterIds = Array.from(new Set(respData.map(r => r.id)));
        return clusterIds.map(clusterId => {
            const rows = respData.filter(r => r.id === clusterId);
            const row = rows.at(0)!;
            return ({
                clusterId,
                clusterTitle: row.title,
                data: this.months.map(month => {
                    const d = rows.find(r => new Date(r.month).getMonth() === month.getMonth());
                    return ({
                        month,
                        value: d ? Number(d[this.activeTargetValue]) : 0,
                    });
                })
            });
        });
    }

    getContributionPerCluster(value: DimensionValue): {
        clusterId: number,
        clusterTitle: string,
        doiData: {
            doi: DOI,
            data: { month: Date, value: number }[]
        }[]
    }[] | null {
        const respData = this.contributionPerClusterRM.result;
        if (!respData) return null;

        const clusterIds = Array.from(new Set(respData.map(r => r.id)));

        return clusterIds.map(clusterId => {
            const rows = respData.filter(r => r.id === clusterId)!;
            const row = rows.at(0)!;
            return ({
                clusterId: clusterId,
                clusterTitle: row.title,
                doiData: DOIs.map(doi => {
                    const doiData = rows.filter(r => r.doi === doi).map(r => ({
                        month: new Date(r.month),
                        value: Number(r[value]),
                    }));
                    if (doiData.length === 0) {
                        return ({
                            doi,
                            data: this.months.map(month => ({month, value: 0}))
                        });
                    }
                    return ({
                        doi,
                        data: doiData
                    });
                }),
            });
        })
    }

    getContributionPerClusterCumSum(value: DimensionValue): {
        clusterId: number,
        clusterTitle: string,
        data: { month: Date, value: number }[],
    }[] | null {
        const respData = this.contributionPerClusterCumSumRM.result;
        if (!respData) return null;

        const clusterIds = Array.from(new Set(respData.map(r => r.id)));

        return clusterIds.map(clusterId => {
            const rows = respData.filter(r => r.id === clusterId)!;
            const row = rows.at(0)!;
            const data = rows.map(r => ({
                month: new Date(r.month),
                value: Number(r[value]),
            }));
            return ({
                clusterId: clusterId,
                clusterTitle: row.title,
                data: data.length === 0
                    ? this.months.map(month => ({month, value: 0}))
                    : data,
            });
        })
    }

    get contributionPerClusterSavings() {
        return this.getContributionPerCluster(this.activeSavingsValue)
    }

    get contributionPerClusterAvoidance() {
        return this.getContributionPerCluster(this.activeAvoidanceValue)
    }

    get contributionPerClusterCumSumSavings() {
        return this.getContributionPerClusterCumSum(this.activeSavingsValue)
    }

    get contributionPerClusterCumSumAvoidance() {
        return this.getContributionPerClusterCumSum(this.activeSavingsValue)
    }

    get contributionPerClusterTotalSavings(): {
        clusterId: number,
        clusterTitle: string,
        data: { month: Date, value: number }[]
    }[] | null {
        if (this.contributionPerClusterSavings === null) return null;
        return this.contributionPerClusterSavings.map(c => ({
            clusterId: c.clusterId,
            clusterTitle: c.clusterTitle,
            data: this.months.map(m => ({
                month: m,
                value: sum(c.doiData.map(d => d.data.find(r => r.month.getMonth() === m.getMonth())?.value ?? 0))
            }))
        }))
    }

    getContributionPerDoi(resp: PerDoiRowResponse[] | undefined, value: DimensionValue): {
        doi: DOI,
        data: { month: Date, value: number }[]
    }[] | null {
        if (!resp) return null;

        const s_months = Array.from(new Set(resp.map(r => r.month)));
        let months: Date[];
        if (s_months.length === 0) {
            months = this.months;
        } else {
            s_months.sort()
            months = s_months.map(m => new Date(m));
        }

        return DOIs.map(doi => {
            const data = resp.filter(r => r.doi === doi);
            return ({
                doi,
                data: data.length > 0
                    ? data.map(r => ({
                        month: new Date(r.month),
                        value: Number(r[value]),
                    }))
                    : months.map(month => ({month, value: 0})),
            });
        })
    }

    get contributionPerDoi() {
        return this.getContributionPerDoi(this.contributionPerDoiCumSumRM.result, this.activeSavingsValue);
    }

    get contributionPerMonth(): { month: Date, value: number }[] | null {
        const resp = this.contributionPerDoiCumSumRM.result;
        if (!resp) return null;
        let months: Date[] = this.months;
        return months.map(month => {
            const data = resp.filter(r => new Date(r.month).getMonth() === month.getMonth());
            return {
                month,
                value: data.length > 0 ? sum(data.map(r => Number(r[this.activeSavingsValue]))) : 0
            }
        })
    }

    get contributionsPerCompanyLever(): {
        company_title: string
        total: number
        target: number
        only_doi5_total: number
        levers: { lever: string, value: number }[]
    }[] | null {
        const respData = this.contributionPerCompanyLeverRM.result;
        const companyRespData = this.fpPerCompanyTargetRM.result;
        if (!respData || !companyRespData) return null;

        const groups = Array.from(new Set(respData.map(r => r.lever_group_title)));
        const companyIds = Array.from(new Set(respData.map(r => r.id)));
        const companyTargets: Map<number, TValuePerCompanyRowResponse> = new Map(companyRespData.map(r => [r.id, r]));

        return companyIds.map(companyId => {
            const rows = respData.filter(r => r.id === companyId);
            const companyRow = rows[0];
            const companyTarget = companyTargets.get(companyId);

            return {
                company_title: companyRow.title,
                total: sum(rows.map(r => Number(r[this.activeSavingsValue]))),
                target: companyTarget ? companyTarget[this.activeTargetValue] : 0,
                only_doi5_total: sum(rows.filter(r => r.is_doi5).map(r => Number(r[this.activeSavingsValue]))),
                levers: groups.map(lever => {
                    const leverRows = rows.filter(r => r.lever_group_title === lever);
                    return {
                        lever,
                        value: sum(leverRows.map(r => Number(r[this.activeSavingsValue]))),
                    }
                }),
            }
        })
    }

    setYear(year: number) {
        this.year = year;
        this.reloadData();
    }

    setScope(scope: typeof this.scope) {
        this.scope = scope;
        this.reloadData();
    }

    setDoiStage(doi: typeof this.doi) {
        this.doi = doi;
        this.reloadData();
    }

    setActiveFilteredCompanies(companyIds: (number | string)[]) {
        const companies = sitStores.ccStore.companyRM.result;
        if (!companies) {
            this.activeFilteredCompanies = []
            return
        }
        this.activeFilteredCompanies = companyIds
            .map(id => companies!.find(c => c.id === Number(id))!)
            .filter(c => c)

        this.reloadData();
    }

    get initiativeFilters() {
        const filters: [string, string][] = [];
        if (this.activeFilteredCompanies.length > 0) {
            filters.push(['companies__id__in', this.activeFilteredCompanies.map(c => String(c.id)).join(',')])
        }
        if (this.scope !== '') {
            filters.push(['initiative__local', this.scope === 'local' ? 'true' : 'false'])
        }
        if (this.approved !== '') {
            filters.push(['initiative__approved', this.approved === 'approved' ? 'true' : 'false'])
        }
        if (this.doi !== null) {
            filters.push(['initiative__doi_status', String(this.doi)])
        }
        if (this.activeLeverGroups.length > 0) {
            filters.push(['initiative__lever__group_id__in', this.activeLeverGroups.map(c => String(c.id)).join(',')])
        }
        if (this.activeCategories.length > 0) {
            filters.push(['categories__id__in', this.activeCategories.map(c => String(c.id)).join(',')])
        }
        return filters;
    }

    get targetFilters() {
        const filters: [string, string][] = [];
        if (this.activeFilteredCompanies.length > 0) {
            filters.push(['companies__id__in', this.activeFilteredCompanies.map(c => String(c.id)).join(',')])
        }
        if (this.scope !== '') {
            filters.push(['local', this.scope === 'local' ? 'true' : 'false'])
        }

        // not needed
        // if (this.approved !== '') {
        // }
        // if (this.doi !== null) {
        // }
        // if (this.activeLeverGroups.length > 0) {
        // }

        if (this.activeCategories.length > 0) {
            filters.push(['categories__id', this.activeCategories.map(c => String(c.id)).join(',')])
        }
        return filters;
    }

    setActiveValueType(activeValueType: ValueType) {
        this.activeValueType = activeValueType;
    }

    setProbabilityAdjusted(probabilityAdjusted: boolean) {
        this.probabilityAdjusted = probabilityAdjusted;
    }

    get combinedClusters(): Cluster[] {
        return this.ccStore.companyRM.result?.filter(
            (company) => (this.activeFilteredCompanyIds.indexOf(company.id) ?? -1) > -1
        ).map(
            (company) => company.cluster
        ).filter(
            (value, index, array) => array.map((cluster) => cluster.id).indexOf(value.id) === index
        ) ?? [];
    }

    isCheckedClusters(id: number): boolean {
        const allCompanyIds = this.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.length > 0 && (allCompanyIds.every(
            (company_id) => (this.activeFilteredCompanyIds.indexOf(company_id) ?? -1) > -1
        ) ?? false);
    }

    isIndeterminateCluster(id: number): boolean {
        const allCompanyIds = this.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.filter((id) => (this.activeFilteredCompanyIds.indexOf(id) ?? -1) > -1).length > 0 &&
            allCompanyIds.filter((id) => (this.activeFilteredCompanyIds.indexOf(id) ?? -1) === -1).length > 0;
    }

    setClusters(value: Cluster[], remove: boolean): void {
        if (remove) {
            const removedClusterIds = this.combinedClusters
                .filter((cluster) => !(value.map((c) => c.id).includes(cluster.id)))
                .map((cluster) => cluster.id);
            this.ccStore.companyRM.result?.filter(company => removedClusterIds.includes(company.cluster.id))
                .forEach((item) => this.activeFilteredCompanies.splice(this.activeFilteredCompanyIds.indexOf(item.id), 1))
        } else {
            const addedClusterIds = value.filter(
                (cluster) => !(this.combinedClusters.map((c) => c.id).includes(cluster.id))
            ).map(
                (cluster) => cluster.id
            );
            this.activeFilteredCompanies = this.ccStore.companyRM.result?.filter(
                (company) => addedClusterIds.includes(company.cluster.id) || this.activeFilteredCompanyIds.includes(company.id)
            ).map(
                (company) => company.id
            ).filter(
                (companyId, index, array) => array.indexOf(companyId) === index
            ).map(companyId => this.ccStore.companyRM.result!.find(c => c.id === companyId)! as Company) || [];
        }
        this.reloadData();
    }

    setActiveLeverGroupIds(leverGroupIds: number[] | LeverGroup[]) {
        this.activeLeverGroups = leverGroupIds
            .map(value => {
                if (typeof value === 'number') {
                    return this.ccStore.leverGroupRM.result!.find(lg => lg.id === value)!;
                } else {
                    return value;
                }
            })
            .filter(l => l)
            .sort((a, b) => a.id - b.id);
        this.reloadData();
    }

    setApproved(value: typeof this.approved) {
        this.approved = value;
        this.reloadData();
    }

    onChangeActiveCategory(value: CategoryLabel[], reason: AutocompleteChangeReason) {
        if (reason === 'selectOption' || reason === 'removeOption' || reason === 'clear') {
            this.activeCategories = value;
        }
        this.reloadData();
    }
}
