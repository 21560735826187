import './TaxonomySuggestionDemoPage.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {useNavigate} from "react-router-dom";
import {generateGotoTaxonomyBuilderPath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {TaxonomySuggestionDemoToolbar} from "./components/TaxonomySuggestionDemoToolbar";
import {TaxonomySuggestionDemoTable} from "./components/TaxonomySuggestionDemoTable";

export const TaxonomySuggestionDemoPage: React.FC = observer(() => {
    const {bagStore, taxonomyManagerStore, taxonomySuggestionDemoStore} = useStores();
    const navigate = useNavigate();
    const bagId = bagStore.bagId;

    useEffect(() => {
        taxonomySuggestionDemoStore.initDemoData()
    }, []);

    return <KoiFacade
        title="Taxonomy Suggestion"
        subTitle={taxonomyManagerStore.taxonomy?.name}
        onBack={() => {
            const taxonomyId = taxonomyManagerStore.taxonomy?.id;
            navigate(generateGotoTaxonomyBuilderPath(bagId, taxonomyId));
        }}
        className="taxonomy-suggestion-page taxonomy-editor-page">

        <TaxonomySuggestionDemoToolbar/>
        <TaxonomySuggestionDemoTable
            data={taxonomySuggestionDemoStore.taxonomyTableController.data}
            changed={taxonomySuggestionDemoStore.taxonomyTableController.changed}
        />
    </KoiFacade>
});
