import './AcceptButtons.scss';
import React from "react";
import {CheckCircle, People} from '@mui/icons-material';

type Props = {
    isAccept: boolean,
    onAccept?: () => void
    disabled?: boolean
    byOther?: boolean
}
export const AcceptButtons: React.FC<Props> = ({isAccept, onAccept, disabled, byOther}) => {
    return <div className="accept-buttons">
        {byOther && <People/>}
        <CheckCircle
            className={
                'accept'
                + (isAccept ? ' active' : ' inactive')
                + (onAccept ? ' clickable' : '')
                + (disabled ? ' disabled' : '')
            }
            onClick={onAccept}
        />
    </div>;
}
