import {FC, useState} from "react";
import dayjs, {Dayjs} from 'dayjs';
import {ChevronDownIcon, ChevronUpIcon} from "@radix-ui/react-icons";
import {ShadcnPopover} from "./ShadcnPopover";
import {Button, Grid, Popover, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Clear} from "@mui/icons-material";

interface DateRange {
    from: Dayjs | null // Null means since the beginning of time
    to: Dayjs | null // Null means until the end of time
}

declare type Props = {
    /** Click handler for applying the updates from DateRangePicker. */
    onUpdate: (values: { range: DateRange }) => void
    /** Initial value for start date */
    dateFrom?: string | undefined
    /** Initial value for end date */
    dateTo?: string | undefined
    /** Do not allow to open of popover, the field will be disabled */
    disabled?: boolean
    /** Alignment of popover */
    align?: 'start' | 'center' | 'end'
}
export const ShadcnQuarterDatePicker: FC<Props> = ({
                                                       dateFrom,
                                                       dateTo,
                                                       onUpdate,
                                                       align = 'end',
                                                       disabled
                                                   }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setIsOpen(true)
    };
    const handleClose = () => {
        setAnchorEl(null)
        setIsOpen(false)
    }

    const [isOpen, setIsOpen] = useState(false)

    const [range, setRange] = useState<DateRange>({
        from: dateFrom ? dayjs(dateFrom) : null,
        to: dateTo ? dayjs(dateTo) : null
    })


    //Quarter is 0 indexed
    const onClickQuarter: (isTo: boolean, quarter: number) => void = (isTo, quarter) => {
        let date = isTo ? range.to : range.from;

        console.log('date w/o quarter', date)

        if (date) {
            date = date.month(quarter * 3); //- 3
            console.log('date w quarter', date.format('YYYY-MM-DD'))
            setRange({
                ...range,
                [isTo ? 'to' : 'from']: date
            })
        }
    }

    const showDateQuarter = (date: Dayjs) => {
        return date.format('YYYY-') + 'Q' + (date.month() / 3 + 1)
    }

    const getLabel = () => {
        if (range.from && range.to) {
            return `${showDateQuarter(range.from)} - ${showDateQuarter(range.to)}`
        } else if (range.from) {
            return `${showDateQuarter(range.from)} onwards`
        } else if (range.to) {
            return `To ${showDateQuarter(range.to)}`
        } else if (range.from == null && range.to == null) {
            return 'All dates'
        }
    }

    const isDisabled = () => {
        if (range.from !== null && range.to !== null &&
            dayjs(range.from).isAfter(range.to)) {
            return true; // Button is disabled
        } else {
            return false; // Button is enabled
        }
    }


    return (
        <>
            <button className="date-range-button" style={{cursor: disabled ? 'not-allowed' : 'pointer'}}
                    onClick={handleOpen}>
                <div className="tw-text-right">
                    <div className="tw-py-1">
                        <div>
                            {getLabel()}
                        </div>
                    </div>
                </div>
                <div className="tw-pl-1 tw-opacity-60 tw--mr-2 tw-scale-125">
                    {!disabled && (isOpen ? <ChevronUpIcon width={24}/> : <ChevronDownIcon width={24}/>)}
                </div>
            </button>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{width: '35%'}}
            >
                <div className="tw-flex tw-py-2">
                    <div className="tw-flex">
                        <div className="tw-flex tw-flex-col">
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid container item xs={10}>
                                    <Grid container item xs={12} style={{margin: '1em 0'}}>
                                        <Grid item xs={5}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={'Start year'}
                                                    views={['year']}
                                                    value={range.from}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            console.log('from date', date.format('YYYY-MM-DD'))
                                                            date = dayjs([date.year(), 1, 1] as any)
                                                            setRange((prevRange) => ({
                                                                ...prevRange,
                                                                from: date
                                                            }));
                                                            console.log('from date', date.format('YYYY-MM-DD'))
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={4}>
                                            <ToggleButtonGroup
                                                color="primary"
                                                exclusive
                                                aria-label="Platform"
                                                fullWidth={true}
                                                value={range.from ? String(range.from.month() / 3) : ''}
                                                onChange={(e, value) => {
                                                    onClickQuarter(false, Number(value))
                                                }}
                                                style={{height: '100%'}}
                                            >
                                                <ToggleButton value="0">Q1</ToggleButton>
                                                <ToggleButton value="1">Q2</ToggleButton>
                                                <ToggleButton value="2">Q3</ToggleButton>
                                                <ToggleButton value="3">Q4</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={1}>
                                            <Button
                                                variant={'outlined'}
                                                style={{width: '85%', height: '100%'}}
                                                onClick={() => {
                                                    setRange({
                                                        ...range,
                                                        from: null,
                                                    })
                                                }}
                                            >
                                                <Clear/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} justifyContent={'space-evenly'}>
                                        <Grid item xs={5}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={'End year'}
                                                    views={['year']}
                                                    value={range.to}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            console.log('date', date.format('YYYY-MM-DD'))
                                                            date = dayjs([date.year(), 10, 1] as any)
                                                            setRange((prevRange) => ({
                                                                ...prevRange,
                                                                to: date
                                                            }));
                                                            console.log('date', date.format('YYYY-MM-DD'))
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={1}></Grid>


                                        <Grid item xs={4}>
                                            <ToggleButtonGroup
                                                color="primary"
                                                exclusive
                                                aria-label="Platform"
                                                fullWidth={true}
                                                value={range.to ? String(range.to.month() / 3) : ''}
                                                onChange={(e, value) => {
                                                    onClickQuarter(true, Number(value))
                                                }}
                                                style={{height: '100%'}}
                                            >
                                                <ToggleButton value="0">Q1</ToggleButton>
                                                <ToggleButton value="1">Q2</ToggleButton>
                                                <ToggleButton value="2">Q3</ToggleButton>
                                                <ToggleButton value="3">Q4</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>

                                        <Grid item xs={1}></Grid>

                                        <Grid item xs={1}>
                                            <Button
                                                variant={'outlined'}
                                                style={{width: '85%', height: '100%'}}
                                                onClick={() => {
                                                    setRange({
                                                        ...range,
                                                        to: null,
                                                    })
                                                }}
                                            >
                                                <Clear/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
                <div className="tw-flex tw-justify-end tw-gap-2 tw-py-2 tw-pr-4">
                    <Button
                        variant="contained"
                        disabled={isDisabled()}
                        onClick={() => {
                            setIsOpen(false);
                            onUpdate?.({range});
                        }}
                    >
                        Update
                    </Button>
                </div>
            </Popover>

            <ShadcnPopover modal={true} open={isOpen} onOpenChange={(open: boolean) => {
                if (disabled) return;
                setIsOpen(open);
            }}>
            </ShadcnPopover>
        </>
    )
}
