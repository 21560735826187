import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {SynergyCommonSupplierReviewSubTable} from "./SynergyCommonSupplierReviewSubTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {CommonParentSupplier} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {SYN_COMMON_PARENT_SUPPLIER_COLUMNS} from "./columns";
import {useStores} from "../../../stores";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {GoogleLink} from "../../../components/_tiny/GoogleLink";
import {getCollapseAnimationTimingNumber} from "../../../components/table/utils";
import {runInAction} from "mobx";

type Props = {
    row: CommonParentSupplier,
    forceOpen?: boolean
};
export const SynergyCommonSupplierReviewMainRow: React.FC<Props> = observer(({row, forceOpen}) => {
    const {synergyStore} = useStores();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        // Only set filters when current row is going to open, otherwise delays of setting this filter in multiple rows
        // would result in wrong API call
        if (row.sp_id === synergyStore.openRowSpId)
            synergyStore.commonSupplierFilters.setFilters([['parent_supplier_review_row__in', row.ids.toString()]]);
        setOpen(row.sp_id === synergyStore.openRowSpId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [synergyStore.openRowSpId]);

    let i = 0;
    return <>
        <TableRow className="main-row">
            <MithraTableCell c={SYN_COMMON_PARENT_SUPPLIER_COLUMNS[i++]}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => runInAction(() => synergyStore.openRowSpId = open ? '' : row.sp_id)}
                    disabled={row.sp_n_suppliers === 0}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_PARENT_SUPPLIER_COLUMNS[i++]}>
                <GoogleLink type="parent_supplier" objs={[{parent_supplier: {sp_name: row.sp_name}}]}>
                    {row.sp_name}
                </GoogleLink>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_PARENT_SUPPLIER_COLUMNS[i++]}>
                <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                    <CurrencyComponent v={row.sp_total_spend} abbreviation={abbreviation}/>
                }</CurrencyAbbreviationContext.Consumer>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_PARENT_SUPPLIER_COLUMNS[i++]}>
                {row.sp_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            {/*{row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}*/}
        </TableRow>
        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={SYN_COMMON_PARENT_SUPPLIER_COLUMNS.length}
                style={{paddingLeft: SYN_COMMON_PARENT_SUPPLIER_COLUMNS[0].width}}>
                {row.sp_n_suppliers > 0 &&
                    <Collapse in={open || forceOpen} timeout={getCollapseAnimationTimingNumber(row.sp_n_suppliers)}
                              unmountOnExit>
                        <SynergyCommonSupplierReviewSubTable commonParentSupplier={row}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
