import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, Dialog, FormControl, Grid, InputLabel, TextField} from "@mui/material";
import {VerticalModal} from "./VerticalModal"
import {styled} from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AutoFixHigh} from "@mui/icons-material";
import React, {useState} from "react";
import {RiskTableData} from "../../../../stores/BusinessCaseGeneratorStore";
import {showImpactString, showProbabilityString} from "../../models/RiskDegree";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(VerticalModal)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const ModalNewRiskFactor: React.FC = observer(() => {
    const {businessCaseGeneratorStore} = useStores();
    const [newRiskFactor, setNewRiskFactor] = useState<RiskTableData>({
        id: businessCaseGeneratorStore.riskFactorData.length + 1,
        risk_factor: '',
        impact: 3,
        probability: 3,
        description: '',
        reference: '',
        link: '',
    });

    const handleSave = () => {
        // Validate newRiskFactor data if needed

        // Add the new risk factor to the store
        businessCaseGeneratorStore.riskFactorData.push(newRiskFactor);

        // Close the modal
        businessCaseGeneratorStore.isNewRiskFactorModalOpen = false;
    };

    const handleChange = (field: keyof RiskTableData, value: string) => {
        setNewRiskFactor({
            ...newRiskFactor,
            [field]: value
        });
    };
    return <Dialog
        open={businessCaseGeneratorStore.isNewRiskFactorModalOpen}
        onClose={() => businessCaseGeneratorStore.isNewRiskFactorModalOpen = false}
        maxWidth="md"
    >
        <StyledEditorModalComponent
            titleId="new-risk-factors-title"
            className="modal-new-risk-factor"
            title="Add a new risk factor"
            canSave={true}
            onSave={handleSave}
            onClose={() => (businessCaseGeneratorStore.isNewRiskFactorModalOpen = false)}
            saveLabel="Save"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="risk-factor"
                        label="Risk factor"
                        value={newRiskFactor.risk_factor}
                        onChange={(e) => handleChange('risk_factor', e.target.value)}
                        variant="standard"
                        style={{marginBottom: 16}}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth style={{marginBottom: 16}}>
                        <InputLabel id="demo-simple-select-label">Impact</InputLabel>
                        <Select
                            labelId="risk-factor-impact-options"
                            id="demo-simple-select"
                            value={newRiskFactor.impact}
                            onChange={(e) => handleChange('impact', e.target.value as any)}
                            label="Impact"
                        >
                            <MenuItem value={1}>{showImpactString(1)}</MenuItem>
                            <MenuItem value={2}>{showImpactString(2)}</MenuItem>
                            <MenuItem value={3}>{showImpactString(3)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth style={{marginBottom: 16}}>
                        <InputLabel id="demo-simple-select-label">Probability</InputLabel>
                        <Select
                            labelId="risk-factor-impact-options"
                            id="demo-simple-select"
                            value={newRiskFactor.probability}
                            label="Proability"
                            onChange={(e) => handleChange('probability', e.target.value as any)}
                        >
                            <MenuItem value={1}>{showProbabilityString(1)}</MenuItem>
                            <MenuItem value={2}>{showProbabilityString(2)}</MenuItem>
                            <MenuItem value={3}>{showProbabilityString(3)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item
                      container alignItems="center">
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            id="standard-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            value={newRiskFactor.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            variant="standard"
                            style={{marginBottom: 16}}
                        />
                    </Grid>
                    <Grid item xs={2} className="text-right">
                        <Button variant="outlined">
                            <AutoFixHigh/>
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="risk-factor-ref-link"
                        label="Reference link"
                        value={newRiskFactor.link || ''}
                        onChange={(e) => handleChange('link', e.target.value)}
                        variant="standard"
                        style={{marginBottom: 16}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="risk-factor-ref-ref-name"
                        label="Reference name"
                        value={newRiskFactor.reference || ''}
                        onChange={(e) => handleChange('reference', e.target.value)}
                        variant="standard"
                        style={{marginBottom: 16}}
                    />
                </Grid>
            </Grid>
        </StyledEditorModalComponent>
    </Dialog>
});