import './GcpBucketUploadComponent.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, Grid, LinearProgress, Typography} from "@mui/material";
import DataUploadController from "../../../../services/classes/DataUploadController";
import {Cancel, UploadFile} from "@mui/icons-material";
import {Trash} from "lucide-react";

type Props = {
    controller: DataUploadController
    onCancel?: () => void
    validateFile?: (file: File) => boolean
    acceptMimeType?: string
    selectFileText?: string
}

/**
 * Taken from:
 * - https://cloud.google.com/storage/docs/performing-resumable-uploads#json-api_2
 * - https://medium.com/@theyograjthakur/simplifying-large-file-uploads-with-react-and-node-js-a-step-by-step-guide-bd72967f57fe
 * - https://dev.to/akirakashihara/how-to-upload-files-to-google-cloud-storage-using-javascript-on-only-the-browser-11ei
 * - https://cloud.google.com/storage/docs/json_api/v1/objects/insert#http-request
 */
export const GcpBucketUploadComponent: React.FC<Props> = observer((
    {
        controller,
        onCancel,
        validateFile,
        acceptMimeType,
        selectFileText,
    }) => {
    const handleFileChange = (event) => {
        if (!event.target.files || event.target.files.length !== 1) {
            controller.cancel();
            if (onCancel) onCancel();
            return;
        }
        const file: File = event.target.files[0];
        if (validateFile) {
            const valid = validateFile(file);
            if (!valid) return;
        }
        controller.handleFileUpload(file);
    };

    return <Grid container alignItems="center" className="gcp-bucket-upload-component">
        <Grid item>
            <Button
                disabled={controller.isCancelling}
                startIcon={
                    controller.inProgress ? <Cancel/> : controller.isUploaded ? <Trash/> : <UploadFile/>
                }
                variant="outlined"
                component="label"
                className="upload-file-button"
                onClick={() => {
                    if (controller.errorMsg || controller.inProgress || controller.isUploaded) {
                        controller.cancel();
                        if (onCancel) onCancel();
                    }
                }}
                title={controller.isUploaded ? 'Delete file and upload another file' : ''}
            >
                {controller.inProgress
                    ? <>Cancel</>
                    : controller.isUploaded
                        ? <>Remove</>
                        : <>
                            Upload
                            <input
                                hidden
                                accept={acceptMimeType}
                                type="file"
                                onChange={handleFileChange}
                            />
                        </>}
            </Button>
        </Grid>
        <Grid item style={{flex: 1, marginLeft: '1em'}}>
            {controller.errorMsg
                ? <Alert severity="error">{controller.errorMsg}</Alert>
                : <Typography variant="caption">
                    {controller.isCancelling ? 'Cancelling ...'
                        : controller.isRemoving ? 'Removing ...'
                            : controller.inProgress ?
                                <>Uploading <span className="filename">
                                    {controller.fileName}
                                </span> ...</>
                                : controller.isUploaded ?
                                    <>Upload of <span className="filename">
                                        {controller.fileName}
                                    </span> completed</>
                                    // else
                                    : (selectFileText ?? 'Select a file to upload')
                    }
                </Typography>
            }
        </Grid>
        <Grid item xs={12} style={{marginTop: '1em'}}>
            <LinearProgress value={controller.progress} variant="determinate"/>
        </Grid>
    </Grid>
});
