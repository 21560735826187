import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Autocomplete, FormControl, TextField} from "@mui/material";
import {SimpleSitUserProfile} from "../ApiTypes";
import {useSitStores} from "../../stores";
import {runInAction} from "mobx";

interface Props {
    label: string,
    placeholder: string,
    disabled: boolean,
    value: SimpleSitUserProfile | undefined | null,
    onChange: (event: React.SyntheticEvent, value: SimpleSitUserProfile | null) => void,
    required?: boolean,
}

export const SitUserSelect: React.FC<Props> = observer(({
                                                            label,
                                                            placeholder,
                                                            disabled,
                                                            value,
                                                            onChange,
                                                            required
                                                        }: Props) => {
        const {sitUserStore} = useSitStores();

        useEffect(() => {
            if (sitUserStore.userListRM.result === undefined && !sitUserStore.userListRM.busy)
                sitUserStore.userListFilter.setFilters([['search', '']]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <FormControl fullWidth margin="dense">
            <Autocomplete
                disabled={disabled}
                selectOnFocus clearOnBlur handleHomeEndKeys
                options={(sitUserStore.userListRM.result?.results ?? []) as SimpleSitUserProfile[]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(options) => options}
                value={value ?? null}
                renderOption={(props, option, {selected}) =>
                    <li key={option.id} {...props}>
                        {option.name + ' <' + option.email + '>'}
                    </li>}
                onChange={onChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        required={required}
                        placeholder={placeholder}/>}
                onInputChange={(_, value, reason) => {
                    switch (reason) {
                        case 'input':
                            runInAction(() => {
                                sitUserStore.userListFilter.setFilters([['search', value]])
                            });
                            break;
                        case 'clear':
                            runInAction(() => {
                                sitUserStore.userListFilter.setFilters([['search', '']])
                            });
                            break;
                    }
                }}/>
        </FormControl>
    }
)
