import './SalesDemoHeader.scss'
import React from "react";
import {SelectDataset} from "../../../../components/main/header/SelectDataset";
import {observer} from "mobx-react-lite";
import {SalesDemoCleanupMainMenu} from "../cleanup/SalesDemoCleanupMainMenu";
import {SalesDemoUserMenu} from "./SalesDemoUserMenu";
import {useStores} from "../../../../stores";
import {SalesDemoSynergyMainMenu} from "../synergy/SalesDemoSynergyMainMenu";

export const SalesDemoHeader: React.FC = observer(() => {
    const {rootAppStore} = useStores();
    const dashEnv = rootAppStore.routeApp?.dashboardEnvironment;
    return <>
        {(dashEnv === 'cleanup' || dashEnv === 'analytics') && <SalesDemoCleanupMainMenu/>}
        {(dashEnv === 'synergy' || dashEnv === 'merge') && <SalesDemoSynergyMainMenu/>}
        <div style={{flexGrow: 1}}/>
        <SelectDataset/>
        <SalesDemoUserMenu/>
    </>;
})
