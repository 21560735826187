import React from "react";
import {BarDataPoint} from "../visualization/BarChart";
import {Grid} from "@mui/material";
import './CategorizedTransactions.scss';
import {Options} from "../visualization/bar-chart/BarChart2";
import Typography from "@mui/material/Typography";
import {ParetoOverviewVisualization} from "../../jobs/koi/spend-concentration/viz/ParetoOverviewVisualization";

type Props = {
    recategorized: BarDataPoint[] | undefined,
    categorized: BarDataPoint[] | undefined,
    uncategorized: BarDataPoint[] | undefined,
}

const options2: Partial<Options> = {
    vertical: false,
    width: 1200,
    height: 'auto',
    labelMargin: 100, // Of the total width
    valueLabelPxH: 34,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    alwaysShowValues: true,
    topBarExtraHeight: 50,
}
export const CategorizedTransactions: React.FC<Props> = ({categorized, recategorized, uncategorized}) => {
    return (<>
        <Grid container direction="column" alignItems="center" item xs={10} style={{marginLeft: '4em'}}>
            <Grid item container style={{marginBottom: '4em'}} direction="row" justifyContent="center"
                  alignItems="center">
                <Grid item xs={3}>
                    <Typography align="center" variant='h4'>Re-categorized</Typography>
                </Grid>
                <Grid item xs={8} container direction='column'>
                    <Grid item className={"custom-pareto-overview"} container direction='column'>
                        {recategorized && (
                            <ParetoOverviewVisualization
                                data={recategorized}
                                options={{
                                    ...options2,
                                }}
                            />
                        )}

                    </Grid>
                </Grid>
                <Grid item xs={1} container direction='column'>
                </Grid>
            </Grid>
            <Grid item container style={{marginBottom: '4em'}} direction="row" justifyContent="center"
                  alignItems="center">
                <Grid item xs={3}>
                    <Typography align="center" variant='h4'>Categorized <br/>(Previously uncategorized) </Typography>
                </Grid>
                <Grid item xs={8} container>
                    <Grid item className={"custom-pareto-overview"} container direction='column'>
                        {categorized && (
                            <ParetoOverviewVisualization
                                data={categorized}
                                options={{
                                    ...options2,
                                }}
                            />
                        )}

                    </Grid>
                </Grid>
                <Grid item xs={1} container direction='column'>
                </Grid>
            </Grid>
            <Grid item container style={{marginBottom: '4em'}} direction="row" justifyContent="center"
                  alignItems="center">
                <Grid item xs={3}>
                    <Typography align="center" variant='h4'>Uncategorized</Typography>
                </Grid>
                <Grid item xs={8} container>
                    <Grid item className={"custom-pareto-overview"} container direction='column'>
                        {uncategorized && (
                            <ParetoOverviewVisualization
                                data={uncategorized}
                                options={{
                                    ...options2,
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={1} container direction='column'>
                </Grid>
            </Grid>
        </Grid>

    </>);
}
