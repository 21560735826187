import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, Checkbox, Dialog, FormControlLabel, Grid, MenuItem, TextField} from "@mui/material";
import {Download, Edit, Redo} from "@mui/icons-material";
import {generatePath} from "react-router";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DatabagRouteMatch, routes} from "../../routing/routes";
import {ModalDeleteCategory} from "./modals/ModalDeleteCategory";
import {ModalMergeCategory} from "./modals/ModalMergeCategory";
import {ModalRenameCategory} from "./modals/ModalRenameCategory";
import {ModalModifyCategory} from "./modals/ModalModifyCategory";
import {ModalMoveCategory} from "./modals/ModalMoveCategory";
import {TaxonomySuggesterTableV2} from "./TaxonomySuggesterTableV2";
import {generateGotoTaxonomyBuilderPath, generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {environment} from "../../env";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {TaxonomySuggesterStepper} from "./TaxonomySuggesterStepper";
import {ApiSuggestionTreeResponse} from "../../services/ApiHelpers";
import {taxonomy_suggester_v2} from "../../services/classes/TaxonomySuggesterClassesV2";

type APIValues = taxonomy_suggester_v2.APIValues;

export const TaxonomySuggesterPageV2: React.FC = observer(() => {
    const {rootAppStore, bagStore, taxonomyManagerStore, taxonomySuggesterStoreV2, p} = useStores();
    const bagId = bagStore.bagId;
    const navigate = useNavigate();
    const params = useParams<DatabagRouteMatch>();
    const location = useLocation();

    // Define state variables to track the number of data in each tab
    const [labelFormatsCount, setLabelFormatsCount] = useState(0);
    const [prefixOverlapsCount, setPrefixOverlapsCount] = useState(0);
    const [categoryOverlapsCount, setCategoryOverlapsCount] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        taxonomySuggesterStoreV2.taxonomyTableController.setFilter(newValue);
    };

    function gotoTaxonomyBuilder() {
        if (environment.package === 'cleanup') {
            if ('taxonomyId' in params) {
                navigate(generatePath(routes.taxonomy_builder,
                    {id: bagId, taxonomyId: Number(params['taxonomyId'])}
                ))
            } else {
                navigate(generatePath(routes.taxonomy_builder, {id: bagId,}))
            }
        } else {
            navigate(generatePath(routes.merge_x_taxonomy_builder, {
                id: bagId,
            }))
        }
    }

    return <KoiFacade
        title="Taxonomy Suggester"
        subTitle={taxonomyManagerStore.taxonomy?.name}
        back={generateHomePath(location, {id: String(bagId)}, rootAppStore.app)}
        className="taxonomy-health-check-page taxonomy-editor-page"
    >
        {environment.isTest && <h3>TaxonomySuggesterPageV2</h3>}
        <>
            <div className='taxonomy-health-checker-page'>
                <Dialog
                    open={
                        taxonomySuggesterStoreV2.isDeleteCategoryModalOpen
                        || taxonomySuggesterStoreV2.isMoveCategoryModalOpen
                        || taxonomySuggesterStoreV2.isMergeCategoryModalOpen
                        || taxonomySuggesterStoreV2.isRenameCategoryModalOpen
                        || taxonomySuggesterStoreV2.isModifyCategoryModalOpen
                    }
                    onClose={() => {
                    }}
                    aria-labelledby={"taxonomy-editor-modal-title"}
                    maxWidth="lg"
                >
                    {taxonomySuggesterStoreV2.isDeleteCategoryModalOpen && <ModalDeleteCategory/>}
                    {taxonomySuggesterStoreV2.isMoveCategoryModalOpen && <ModalMoveCategory/>}
                    {taxonomySuggesterStoreV2.isMergeCategoryModalOpen && <ModalMergeCategory/>}
                    {taxonomySuggesterStoreV2.isRenameCategoryModalOpen && <ModalRenameCategory/>}
                    {taxonomySuggesterStoreV2.isModifyCategoryModalOpen && <ModalModifyCategory/>}
                </Dialog>

                {taxonomySuggesterStoreV2.activeStep == 2 && <div className='taxonomy-health-checker-toolbar'>
                    <div>
                        <Button
                            className="rerun-button health-btns mr-2"
                            color="primary"
                            variant="outlined"
                            startIcon={<Download/>}
                            onClick={() => taxonomySuggesterStoreV2.downloadFile()}>
                            Download
                        </Button>

                        <Button
                            className="rerun-button health-btns mr-2"
                            color="primary"
                            variant="outlined"
                            startIcon={<Edit/>}
                            onClick={() => {

                                // TODO: This is a hack to get back to the taxonomy builder with the integrated version
                                // We have to be careful as this is violating the Client Server architecture of web applications

                                const EMPTY_TAXONOMY: m_taxonomy.FullSerializer['diff_tree'] = {
                                    id: -1,
                                    label: 'Taxonomy',
                                    values: {
                                        p__id__count: -1,
                                        p__spend__sum: -1,
                                        s__id__nunique: -1,
                                        description: '',
                                        authors: [],
                                    },
                                    sources: [],
                                    removed: false,
                                    children: [],
                                }

                                let tree = taxonomySuggesterStoreV2.taxonomySuggesterV2ApiResponse?.suggester_state_v2 || EMPTY_TAXONOMY;

                                function hotfixTree(tree: ApiSuggestionTreeResponse<APIValues>): m_taxonomy.FullSerializer['diff_tree'] {
                                    const children = tree.children.map(child => hotfixTree(child));
                                    return {
                                        ...tree,
                                        children,
                                        removed: false,
                                    };
                                }

                                taxonomyManagerStore._setTaxonomy({
                                    id: -1,
                                    name: 'Suggested merged Taxonomy',
                                    size: 3,
                                    current_operation_number: -1,
                                    next_node_id: 9999,
                                    current_date: String(new Date()),
                                    result_of_approval: null,
                                    active_approval_request: null,
                                    diff_tree: hotfixTree(tree),
                                    has_taxonomy_health_check: false,
                                })

                                navigate(generateGotoTaxonomyBuilderPath(bagId));
                            }}>
                            Edit
                        </Button>

                        <Button
                            className="rerun-button health-btns"
                            color="primary"
                            variant="outlined"
                            startIcon={<Redo/>}
                            onClick={() => {
                                taxonomySuggesterStoreV2.setActiveStep(0);
                                taxonomySuggesterStoreV2.handleAddTaxonomy(`Suggested_Taxonomy (${taxonomySuggesterStoreV2.numberofSuggestions})`);
                                taxonomySuggesterStoreV2.increaseNumberofSuggestions();
                            }}>
                            Restart
                        </Button>
                    </div>
                </div>}

                {/* <div className='taxonomy-health-checker-filters'>
                    <Tabs value={taxonomySuggesterStoreV2.taxonomyTableController.filter} onChange={handleChange}
                          aria-label="Filter tabs">
                        <Tab label={`All`} value={0}/>
                        <Tab
                            label={`LABEL FORMATS (${taxonomySuggesterStoreV2.suggestionKpis.numberOfLabelFormatting})`}
                            value={1}/>
                        <Tab
                            label={`PREFIX OVERLAPS (${taxonomySuggesterStoreV2.suggestionKpis.numberOfPrefixOverlaps})`}
                            value={2}/>
                        <Tab
                            label={`CATEGORY OVERLAPS (${taxonomySuggesterStoreV2.suggestionKpis.numberOfCategoryOverlap})`}
                            value={3}/>
                    </Tabs>
                </div> */}

                <Grid item xs={8} className='stepper-box'>
                    <TaxonomySuggesterStepper/>
                </Grid>

                {taxonomySuggesterStoreV2.activeStep == 0 && (
                    <div className="input-container">
                        <div>
                            <input
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                id="contained-button-file"
                                type="file"
                                style={{display: 'none'}}
                                onChange={taxonomySuggesterStoreV2.handleFileUpload}
                            />
                            <label htmlFor="contained-button-file">
                                <Button component="span" variant="outlined" className="input-box">
                                    Upload New Taxonomy
                                </Button>
                            </label>
                        </div>

                        <div>{
                            taxonomySuggesterStoreV2.taxonomyList.map((taxonomy, i) =>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    label={`${taxonomy}`}
                                />)
                        }</div>

                        {/* <FormControl> */}
                        <TextField
                            className="input-box top-margin"
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            value={String(taxonomySuggesterStoreV2.levelsNumber)}
                            label="Number of Levels"
                            defaultValue={'3'} select
                            onChange={taxonomySuggesterStoreV2.handleLevelChange}
                        >
                            <MenuItem key={1} value={1}>1</MenuItem>
                            <MenuItem key={2} value={2}>2</MenuItem>
                            <MenuItem key={3} value={3}>3</MenuItem>
                            <MenuItem key={4} value={4}>4</MenuItem>
                        </TextField>
                        {/* </FormControl> */}
                        {/* <TextField 
  value={2}
//   onChange={(e) => setValue(e.target.value)}
  select // tell TextField to render select
  label="Label"
>
  <MenuItem key={1} value="test">
    Test 1
  </MenuItem>
  <MenuItem key={2} value="test2">
    Test 2
  </MenuItem>
</TextField> */}

                        {
                            Array.from({length: taxonomySuggesterStoreV2.levelsNumber}, (_, index) => (
                                <TextField
                                    key={index}
                                    className="input-box"
                                    defaultValue={index == 0 ? 9 : index == 1 ? 35 : index == 2 ? 100 : 300}
                                    label={`Approximate Number of Level ${index + 1} Categories`}
                                />
                            ))
                        }

                        <Button
                            className="input-box run-btn"
                            color="primary"
                            variant="contained"
                            // startIcon={<RedoRounded/>}
                            onClick={() => {
                                taxonomySuggesterStoreV2.setActiveStep(1);
                            }}>
                            Run Model
                        </Button>
                    </div>
                )}

                {taxonomySuggesterStoreV2.activeStep == 1 && (
                    <div className="loading-mid-page-container ">
                        <div>
                            <Grid item className="loading-mid-page">
                                <LoadingSpinnerPie/>
                            </Grid>
                            <div className="text-processing">
                                {taxonomySuggesterStoreV2.processingText}
                            </div>
                        </div>

                        <Button
                            className="input-box run-btn loading-mid-page"
                            color="primary"
                            variant="contained"
                            // startIcon={<RedoRounded/>}
                            onClick={() => {
                                taxonomySuggesterStoreV2.setActiveStep(2);
                            }}>
                            Skip For Demo
                        </Button>
                    </div>
                )}

                {taxonomySuggesterStoreV2.activeStep == 2 && (
                    <Grid container className='taxonomy-health-checker-main-container'>
                        <Grid item xs={12}>
                            <section className='taxonomy-container'>
                                {taxonomySuggesterStoreV2.taxonomyTableController.data && (
                                    // Display content based on the selected tab
                                    // For example, you can conditionally render different tables based on the tabValue
                                    <div>
                                        <TaxonomySuggesterTableV2
                                            columns={taxonomySuggesterStoreV2.SUGGESTER_V2_COLUMNS}/>
                                    </div>
                                )}
                            </section>
                        </Grid>
                    </Grid>
                )}

            </div>
        </>
    </KoiFacade>
});
