import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {EditorModalComponent} from "../EditorModalComponent";
import {List, ListItem, ListItemText, TextField} from "@mui/material";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";
import {
    TaxonomyTreeEditorController
} from "../../../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyTreeEditorController";

export const MergeCategoryModal: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const onSave = () => taxonomyEditorStore.saveMergeCategory();
    return <EditorModalComponent
        className="taxonomy-modal-component merge-category-modal"
        titleId={"taxonomy-editor-modal-title"}
        title="Merge categories"
        canSave={taxonomyEditorStore.canMergeCategories}
        onSave={onSave}
        onClose={() => taxonomyEditorStore.setMergeCategoryMode(false)}
    >
        <section className="modal-introduction">
            Selected categories:
            <List>
                {taxonomyEditorStore.selection.map(n => {
                    const labels = TaxonomyTreeEditorController.getLabels(n).slice(1);
                    return <ListItem key={n.data.id}>
                        {/*<ListItemIcon>*/}
                        {/*    <ChevronRight/>*/}
                        {/*</ListItemIcon>*/}
                        <ListItemText
                            primary={<CategoryChip labels={labels}/>}
                            secondary={n.data.values.description || undefined}
                        />
                    </ListItem>
                })}
            </List>
        </section>

        <TextField
            autoFocus
            fullWidth
            style={{marginBottom: 30}}
            id="taxonomy-editor-modal-new-category-label"
            label="New category name"
            error={Boolean(taxonomyEditorStore.mergeCategoryNameError)}
            helperText={taxonomyEditorStore.mergeCategoryNameError}
            variant="outlined"
            value={taxonomyEditorStore.mergeCategoryName}
            onChange={e => taxonomyEditorStore.setMergeCategoryName(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            multiline
            id="taxonomy-editor-modal-description-field"
            label="Description"
            error={Boolean(taxonomyEditorStore.mergeCategoryDescriptionError)}
            helperText={taxonomyEditorStore.mergeCategoryDescriptionError}
            variant="outlined"
            value={taxonomyEditorStore.mergeCategoryDescription}
            onChange={e => taxonomyEditorStore.setMergeCategoryDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />
    </EditorModalComponent>
})
