import React, {useEffect, useRef, useState} from 'react';
import './DateRangePicker.scss';
import {useStores} from "../../../stores";


export const DateRangePicker: React.FC<{
    dateFilterChanged: (startDate: string | undefined, endDate: string | undefined) => void,
}> = ({dateFilterChanged}) => {
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const [showDatePickers, setShowDatePickers] = useState(false);
    const dateDropdownRef = useRef<HTMLDivElement>(null);

    const {bagStore} = useStores();

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newStartDate = event.target.value;
        console.log('newStartDate', newStartDate)
        setStartDate(newStartDate);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = event.target.value;
        console.log('newEndDate', newEndDate)
        setEndDate(newEndDate);
    };

    const toggleDatePickers = () => {
        setShowDatePickers(!showDatePickers);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dateDropdownRef.current && !dateDropdownRef.current.contains(event.target as Node)) {
            setShowDatePickers(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        if (startDate && endDate) {
            dateFilterChanged?.(startDate, endDate);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [startDate, endDate]);

    useEffect(() => {
        const date = bagStore.advanced.filters.date_range
        document.addEventListener('mousedown', handleClickOutside);
        if (date) {
            setStartDate(date[0])
            setEndDate(date[1])
        } else {
            setStartDate(undefined)
            setEndDate(undefined)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [bagStore.advanced.filters.date_range, dateFilterChanged]);



    return (
        <div className="date-range-picker">
            <button className="date-range-button" onClick={toggleDatePickers}>
                {startDate && endDate && (
                    <label>{startDate} - {endDate}</label>
                )}
                {(!startDate || !endDate) && (
                    <label>Select Date Range</label>
                )}
                <p>▾</p>
            </button>

            {showDatePickers && (
                <div className="date-dropdown" ref={dateDropdownRef}>
                    <div>
                        <label htmlFor="start-date">Start Date:</label>
                        <input
                            type="date"
                            id="start-date"
                            name="start-date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            className="date-input"
                        />
                    </div>

                    <div>
                        <label htmlFor="end-date">End Date:</label>
                        <input
                            type="date"
                            id="end-date"
                            name="end-date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            className="date-input"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
