import React from 'react';
import {styled} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Bag} from "../../services/classes/Bag";
import {FormHelperText, Grid} from "@mui/material";
import {SelectDatasetMenu} from "../../components/_tiny/SelectDatasetMenu";
import {Property} from "csstype";

const PREFIX = 'DataSetSelectComponent';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        margin: theme.spacing(1),
        minWidth: 300,
        minHeight: '4em',
    },

    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    }
}));

const menuProps = {
    id: 'main-databag-menu',
};

type Props = {
    id?: string,
    className?: string,
    bags?: Bag[],
    selectedBagId?: number | '',
    label?: string,
    fullWidth?: boolean,
    centered?: boolean,
    error?: string,
    disabled?: boolean,
    onSelectBag: (b: Bag) => void,
}
export const DataSetSelectComponent: React.FC<Props> = (
    {
        id,
        className,
        bags,
        selectedBagId,
        label,
        fullWidth,
        centered,
        error,
        disabled,
        onSelectBag,
    }) => {

    const getBag = (id) => bags?.find(b => b.id === id) as Bag;
    const _label = label || 'Selected Bag'

    const onSelectionChange = (event) => {
        const bagId = event.target.value;
        const bag = getBag(bagId);
        console.log('onSelectionChange.bag=', bag);
        onSelectBag(bag);
    };

    const justifyContent: Property.JustifyContent | undefined = centered ? 'center' : undefined;
    className = "form-element dataset-select-component" + (className ? ` ${className}` : '');
    return <StyledGrid container className={className} justifyContent={justifyContent}>
        <Grid item xs={fullWidth ? 12 : undefined}>
            <FormControl
                className={classes.formControl}
                variant="standard"
                fullWidth={fullWidth}
                error={Boolean(error)}
            >
                {bags ? <>
                    <InputLabel id={id + "selected-bag-label"}>{_label}</InputLabel>
                    <SelectDatasetMenu
                        variant="standard"
                        labelId={id + "selected-bag-label"}
                        id={id + "selected-bag"}
                        fullWidth={fullWidth}
                        error={Boolean(error)}
                        value={selectedBagId || ''}
                        onChange={onSelectionChange}
                        className="select-bag-field"
                        disabled={disabled}
                        bags={bags}
                        MenuProps={menuProps}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </> : <>
                    <InputLabel id={id + "selected-bag-loading-label"}>{_label}</InputLabel>
                    <Select
                        variant="standard"
                        labelId={id + "selected-bag-loading-label"}
                        id={id + "selected-bag-loading"}
                        fullWidth={fullWidth}
                        error={Boolean(error)}
                        value="loading"
                        className={classes.selectEmpty + ' select-bag-field'}
                        disabled>
                        <MenuItem value="loading">Loading ....</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </>
                }
            </FormControl>
        </Grid>
    </StyledGrid>
}
