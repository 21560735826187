import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {ContractInsightsStore} from "./ContractInsightsStore";

export class ContractAnalyticsStore {
    //Modals
    isMoveTransactionsModalOpen: boolean = true;
    modalMoveTransactionData: any = undefined;


    constructor(
        private contractInsightsStore: ContractInsightsStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this);
    }

    moveTransactionsOnSave() {
        //Call Api to save
        console.log('moveTransactionsOnSave');
    }

    toggleMoveModal() {
        this.isMoveTransactionsModalOpen = !this.isMoveTransactionsModalOpen;
    }

    setModalMoveTransactionData(data: any) {
        this.modalMoveTransactionData = data;
    }
}
