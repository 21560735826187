import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable} from "mobx";
import {selectPpvLevelStatistics} from "../../../services/classes/MatReviewHelpers";
import {PpvLevelStatisticsTreeSerializer} from "../../../services/classes/MaterializedClasses";
import {PpvControllerStore} from "./PpvControllerStore";
import {PpvPageFilterController} from "./PpvPageFilterController";
import {PpvPageStatisticsController} from "./PpvPageStatisticsController";
import {AiChangeChart, convertToChart} from "./PpvHelper";
import {PpvPageDataController} from "./PpvPageDataController";
import {PpvLevelStatisticsFilter} from "./classes/PpvApiClasses";


export class PpvPageController {
    readonly ppvPageFilterController = new PpvPageFilterController(this, this.api)
    readonly ppvPageStatisticsController = new PpvPageStatisticsController(this.api);
    readonly ppvPageDataController = new PpvPageDataController(this, this.api, this.profile);


    constructor(
        private ppvControllerStore: PpvControllerStore,
        private api: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    get bagId(): number {
        return this.ppvControllerStore.databag_id || -1;
    }

    get taxonomySize(): number {
        return this.ppvControllerStore.maxTaxonomySize;
    }

    get _selectedStats(): PpvLevelStatisticsTreeSerializer[] | undefined {
        const s: PpvLevelStatisticsTreeSerializer | undefined = this.ppvPageStatisticsController.reviewLevelStatistics;
        console.log('_selectedStats', s)
        if (!s) {
            return undefined
        }
        return selectPpvLevelStatistics(s, this.ppvPageFilterController.selectedCategory) //this.ppvPageFilterController.startDate, this.ppvPageFilterController.endDate
    }

    get currentSelectionStats(): undefined | PpvLevelStatisticsTreeSerializer[] {
        if (!this.ppvPageFilterController.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        return this._selectedStats[this._selectedStats.length - 1].children;
    }

    get currentSelectedId(): undefined | number {
        if (!this._selectedStats) return undefined;
        return this._selectedStats[this._selectedStats.length - 1].id;
    }

    resetAndRequestSupplierPage() {
        this.ppvPageDataController.ppvGroupPages.page = 1;
        this.ppvPageDataController.ppvGroupPages.init(this.ppvPageFilterController.selectedFilter);
    }

    loadData(initialLoad = false) {
        const statisticsFilter: PpvLevelStatisticsFilter = {
            databag: this.bagId,
            businessUnitId: this.ppvPageFilterController.businessUnitFilterField?.id,
            quarter_date__gte: this.ppvPageFilterController.startDate,
            quarter_date__lte: this.ppvPageFilterController.endDate,
        }

        if (initialLoad) {
            this.ppvPageFilterController.getBUs()
        }
        if (statisticsFilter.databag === -1) {
            // Reset
            this.ppvPageDataController.ppvGroupPages.reset()
            this.ppvPageStatisticsController.reset();
        } else if (statisticsFilter.databag) {
            this.resetAndRequestSupplierPage();
            if (initialLoad) {
                this.ppvPageStatisticsController.request(statisticsFilter);
                this.resetAndRequestSupplierPage();
            }
        }
    }

    dataFilterChanged() {
        const statisticsFilter: PpvLevelStatisticsFilter = {
            databag: this.bagId,
            businessUnitId: this.ppvPageFilterController.businessUnitFilterField?.id,
            quarter_date__gte: this.ppvPageFilterController.startDate,
            quarter_date__lte: this.ppvPageFilterController.endDate,
        }
        this.resetAndRequestSupplierPage();
        this.ppvPageStatisticsController.request(statisticsFilter);
    }

    get selectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.ppvPageFilterController.canSelectLevelDeeper) return undefined;
        let d = this.currentSelectionStats;
        if (!d) return undefined;

        const data = convertToChart(d, this.profile.currencySymbol)

        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }
}
