/* eslint-disable react-hooks/exhaustive-deps */
// const Main: React.FC<WithStyles<typeof styles>> = ({classes}) => {
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DatabagRouteMatch} from "../../routing/routes";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {environment} from "../../env";
import {mithra_http} from "../../services/Http";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";

export const SimpleEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        p,
        rootAppStore,
        authStore,
        bagLoadingStore,
        taxonomySuggestorStore,
        taxonomySuggesterStoreV2,
        taxonomyHealthCheckStore,
        ppvControllerStore
    } = useStores();
    const params = useParams<DatabagRouteMatch & { revisionHackParam: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const isNoData = state.dataType === 'no_data';

    // let revision = params.revisionHackParam;
    // console.warn('Categorization revision hack is being applied;', revision);
    // if (revision === undefined) {
    //     materializedApi.revisionHack = undefined;
    // } else {
    //     materializedApi.revisionHack = String(revision);
    // }

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the data
    useEffect(() => {
        taxonomySuggestorStore.setSelectedNodeLabel(undefined)
        taxonomySuggestorStore.setSelectedNodeValues(undefined)

        if(!isNoData || rootAppStore.app.dashboardEnvironment === 'approval') {
            authStore.loadUsers()
        }

        if (!isNoData) {
            const bagId = bagLoadingStore.initBag(location, navigate, params)

            if (environment.package === 'sales_demo') {
                if (p.p.hardcodedTaxonomySuggestionId) {
                    taxonomySuggestorStore.getTaxonomySuggestion(Number(p.p.hardcodedTaxonomySuggestionId))
                    console.log("suggester-demo")
                }

                taxonomySuggesterStoreV2.getTaxonomySuggesterV2()

                switch (bagId) {
                    case 99101:
                        // // ERP 1
                        // mithra_http.mockApi.get('sales_demo/suggestor/taxonomy_health_check_99001.json')
                        //     .then((response) => taxonomyHealthCheckStore.setHealthCheckTaxonomyApiResponse(response.data))
                        taxonomyHealthCheckStore.resetHealthCheck()
                        break;
                    case 99102:
                        // // ERP 2
                        // mithra_http.mockApi.get('sales_demo/suggestor/health_check.json')
                        //     .then((response) => taxonomyHealthCheckStore.setHealthCheckTaxonomyApiResponse(response.data))
                        taxonomyHealthCheckStore.resetHealthCheck()
                        break;
                    case 99110:
                        // ERP combined
                        mithra_http.mockApi.get('sales_demo/suggestor/taxonomy_health_check_99005.json')
                            .then((response) => taxonomyHealthCheckStore.setHealthCheckTaxonomyApiResponse(response.data))
                        break;
                    default:
                        taxonomyHealthCheckStore.resetHealthCheck()
                        break;
                }
            } else {
                const taxonomySuggestorId = p.p.hardcodedTaxonomySuggestionId;
                if (taxonomySuggestorId) {
                    taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomySuggestorId)
                }
            }
        }

    }, [isNoData])

    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
