import React from "react";
import {Tab, Tabs} from "@mui/material";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";

type Props = {}
export const TaxonomySuggestionDemoToolbar: React.FC<Props> = observer(() => {
    const {taxonomySuggestionDemoStore} = useStores();
    return <div className="taxonomy-suggestion-toolbar">
        <Tabs
            value={taxonomySuggestionDemoStore.taxonomyTableController.filter}
            onChange={(_, newValue) => {
                taxonomySuggestionDemoStore.taxonomyTableController.setFilter(newValue);
            }}>
            <Tab
                label="All"
                value={0}
            />
            <Tab
                label={`To add (${taxonomySuggestionDemoStore.suggestionKpis.numberOfAdditions})`}
                value={1}
            />
            <Tab
                label={`To delete (${taxonomySuggestionDemoStore.suggestionKpis.numberOfDeletions})`}
                value={2}
            />
            <Tab
                label={`To rename (${taxonomySuggestionDemoStore.suggestionKpis.numberOfRenames})`}
                value={3}
            />
        </Tabs>
    </div>
})