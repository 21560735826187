import {makeAutoObservable} from "mobx";
import MithraMaterializedApi, {Ordering} from "../../../services/MithraMaterializedApi";
import {PpvPageController} from "./PpvPageController";
import {PpvGroupFilter, PpvItemListFilter} from "./classes/PpvApiClasses";
import {catsToDict} from "../../../services/ApiHelpers";
import {SearchManager} from "../../../stores/managers/SearchManager";
import {BusinessUnit} from "../../../services/classes/MaterializedClasses";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";

/**
 * All method related to navigating the categorization review
 */
export class PpvPageFilterController {
    selectedCategory: string[] = [];
    ppvSearchManager = new SearchManager('ppv', () => this.onSearch());

    ordering?: Ordering;

    /**
     * The business unit that is selected,
     * null if only the missing business units are selected, (Not implemented)
     * undefined if all business units are selected, e.g. there is no filter
     */
    businessUnitFilterField: BusinessUnit | undefined
    businessUnitFilterOptions: BusinessUnit[] | undefined

    isFilterDialogOpen = false;

    startDate: string | undefined = undefined;
    endDate: string | undefined = undefined;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private ppvPageController: PpvPageController,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    get hasRemainingSelectionLevels(): boolean {
        if (this.selectedLevel >= this.ppvPageController.taxonomySize) {
            return false
        }
        // noinspection RedundantIfStatementJS
        if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
            // Do not allow to go deeper in uncategorized
            return false;
        }
        return true;
    }

    get canSelectLevelDeeper(): boolean {
        return this.hasRemainingSelectionLevels;
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    setBusinessUnitFilterField(value: BusinessUnit | undefined) {
        this.businessUnitFilterField = value;
        const businessUnitId = this.businessUnitFilterField?.id;
        const databag = this.ppvPageController.bagId
        const quarter_date__gte = this.startDate;
        const quarter_date__lte = this.endDate;

        if (databag) {
            this.ppvPageController.resetAndRequestSupplierPage();
            this.ppvPageController.ppvPageStatisticsController._ppvLevelStatistics.request(({
                databag,
                businessUnitId,
                quarter_date__gte,
                quarter_date__lte
            }));
        }

    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    setSelectedCategory(selectedCategory: string[]) {
        const obj = {old: `${this.selectedCategory}`, new: `${selectedCategory}`};
        console.debug('CategorizationReviewPageFilterController.setSelectedCategory', obj);

        this.selectedCategory = selectedCategory;
        this.ppvPageController.resetAndRequestSupplierPage();
    }

    setFilterDialogOpen(open: boolean) {
        this.isFilterDialogOpen = open;
    }

    onSearch() {
        this.ppvPageController.loadData()
    }

    getBUs() {
        this.api.listBusinessUnitData(this.ppvPageController.bagId).then(r => {
            this.businessUnitFilterOptions = r.data
        })
    }

    dateFilterChanged(startDate: string | undefined, endDate: string | undefined) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.ppvPageController.dataFilterChanged();
    }

    /**
     * The state of the filter that we send to the API
     * TODO: The PpvGroupFilter class is not up to date with the latest expected fields in the API
     */
    get selectedFilter(): PpvGroupFilter {
        const lCats = catsToDict(this.selectedCategory, this.selectedLevel);

        let search: string | undefined = undefined;

        // For the grouped mode the search is set in the filter as the getPartUrlSearchParams() is not used
        if (this.ppvSearchManager.activeSearchString) {
            search = this.ppvSearchManager.activeSearchString
        }

        let requestedBagId = this.ppvPageController.bagId;
        if (!requestedBagId) {
            console.warn('ppvPageController: requestedBagId is not properly refactored', requestedBagId)
        }

        const business_unit = this.businessUnitFilterField?.id || undefined;

        return {
            quarter_date__gte: this.startDate,
            quarter_date__lte: this.endDate,
            databag: requestedBagId || -1,
            business_unit: business_unit,
            search: search,
            page: this.ppvPageController.ppvPageDataController.ppvGroupPages.page,
            page_size: this.ppvPageController.ppvPageDataController.ppvGroupPages.pageSize,
            level: this.selectedLevel,
            ...lCats
        }
    }

    selectedItemFilter(group: PpvGroupRowState): PpvItemListFilter {
        const lCats = catsToDict(this.selectedCategory, this.selectedLevel);

        let requestedBagId = this.ppvPageController.bagId;
        if (!requestedBagId) {
            console.warn('ppvPageController: requestedBagId is not properly refactored', requestedBagId)
        }

        const business_unit = this.businessUnitFilterField?.id || undefined;

        return {
            quarter_date__gte: this.startDate,
            quarter_date__lte: this.endDate,
            databag: requestedBagId || -1,
            business_unit: business_unit,
            p_description: group.data.p_description,
            no_page: true,
            level: this.selectedLevel,
            ...lCats,
        }
    }

    setActiveGroupFilter(filter: PpvGroupFilter) {
        this.startDate = filter.quarter_date__gte;
        this.endDate = filter.quarter_date__lte;
        this.businessUnitFilterField = this.businessUnitFilterOptions?.find(bu => bu.id === filter.business_unit);
        this.ppvSearchManager.activeSearchString = filter.search || '';
        this.ppvSearchManager.setSearch(filter.search || '');
        this.ppvSearchManager.doSearch('auto-reset');
        this.setSelectedCategory([
            filter.l1,
            filter.l2,
            filter.l3,
            filter.l4,
            filter.l5,
            filter.l6,
            filter.l7,
            filter.l8,
        ].filter(Boolean));
    }
}
