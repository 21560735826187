export namespace CategorizationReviewSearchParam {
    export const BY_SUPPLIER_VALUE = 'supplier';
    export const BY_PART_VALUE = 'search';
    export const BY_PART_FIELDS = 'f';

    export function toIntent(urlParams: URLSearchParams): CategorizationReviewSearchIntent | null {
        if(urlParams.has(BY_SUPPLIER_VALUE)) {
            return {
                type: 'by_supplier',
                supplier: urlParams.get(BY_SUPPLIER_VALUE)!
            }
        }
        if(urlParams.has(BY_PART_VALUE)) {
            return {
                type: 'by_part',
                part_search: urlParams.get(BY_PART_VALUE)!,
                part_search_fields: urlParams.getAll(BY_PART_FIELDS)
            }
        }
        return null;
    }

    export function fromIntent(intent: CategorizationReviewSearchIntent, params: URLSearchParams) {
        if(intent.type === 'by_supplier') {
            params.set(BY_SUPPLIER_VALUE, intent.supplier);
        } else {
            intent.part_search_fields.forEach(f => params.append(BY_PART_FIELDS, f));
            params.set(BY_PART_VALUE, intent.part_search);
        }
    }
}

export type CategorizationReviewState = {
    searchIntent: CategorizationReviewSearchIntent | null
}

export type CategorizationReviewSearchIntent = {
    type: 'by_supplier'
    supplier: string
} | {
    type: 'by_part'
    part_search: string
    part_search_fields: (Exclude<string, 'p_id'>)[] // TODO: p_id is not build yet
}
