import './TaxonomySuggestor.scss';
import React, {useMemo} from "react";
import ProfileStore from "../../stores/ProfileStore";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {PartReCategorizationControllerStore} from "../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../components/categorization/PartRecategorizationHandler";
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {SelectionTableRows} from "../../stores/TaxonomySuggestorStore";

// TODO: [CAT-1346] Hardcode subsidiary column names for taxonomy suggester
// import { getSpendMock } from './dpwMocking';

export function getDatasetTargetColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-p_spend', txt: 'Total spend', width: 300},
        {cls: 'col-p_spend_group_a', txt: 'Holding', width: 300},
        {cls: 'col-p_spend_group_b', txt: 'Energy', width: 300},
        {cls: 'col-p_spend_group_c', txt: 'Nutreco', width: 300},
        {cls: 'col-p_suppliers', txt: p.supplierDataName, width: 300},
        {cls: 'col-p_transactions', txt: 'Transactions', width: 300},
    ]
    return arr.filter(c => c) as ColSpec[];
}

type SelectionProps = {
    selectedNodeLabel: String
    selectedNodeData: any
}

export const TaxonomySuggestionSelection: React.FC<SelectionProps> = observer(({
                                                                                   selectedNodeLabel,
                                                                                   selectedNodeData
                                                                               }) => {
    const {p, categorizationReviewStore} = useStores()
    const categories = categorizationReviewStore.categories;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    const isLoading = categorizationReviewStore.isLoading;
    const tableClass = 'mithra-table selection-overview-table x-design x-design-narrow' + (isLoading ? ' loading' : '');
    // const tableClass = 'taxonomy-suggestion-selection'
    const data: SelectionTableRows[] = [{
        p__spend: selectedNodeData.p__spend__sum ? selectedNodeData.p__spend__sum : 0,
        p_spend_a: selectedNodeData.p_spend_a ? selectedNodeData.p_spend_a : 0,
        p_spend_b: selectedNodeData.p_spend_b ? selectedNodeData.p_spend_b : 0,
        p_spend_c: selectedNodeData.p_spend_c ? selectedNodeData.p_spend_c : 0,
        s_suppliers: selectedNodeData.s__id__nunique ? selectedNodeData.s__id__nunique : 0,
        p__transactions: selectedNodeData.p__id__count ? selectedNodeData.p__id__count : 0,
    }]

    const COLUMNS = getDatasetTargetColumns(p)
    //FIXME: Take out the controller
    return <>
        <Typography variant='h4' className='selection-title'>
            {selectedNodeLabel}
        </Typography>

        <div className={tableClass}>
            {controller && <PartReCategorizationHandler controller={controller}/>}
            <Table cellPadding="0">
                <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
                <TableBody>
                    {data ? <>
                            {data.map((row, i) => <React.Fragment key={i}>
                                <TaxonomySuggestionRow part={row} controller={controller}/>
                            </React.Fragment>)}
                            {data.length === 0 &&
                                <TableRow className="no-data">
                                    <TableCell colSpan={COLUMNS.length}>
                                        No data
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                        : <TableRow className="no-data-yet"/>
                    }
                </TableBody>
            </Table>
        </div>
    </>
})

type Props = {
    part: any
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};

export const TaxonomySuggestionRow: React.FC<Props> = observer(({part, controller}) => {
    const {p} = useStores();
    const COLUMNS = getDatasetTargetColumns(p);

    let i = 0;

    return <TableRow>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p_spend_a}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p_spend_b}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p_spend_c}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.s_suppliers ? part.s_suppliers : 'N/A'}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.p__transactions ? part.p__transactions : 'N/A'}
        </MithraTableCell>
    </TableRow>
})
