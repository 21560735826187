import {routes} from "./routes";
import React from "react";
import {RequireAuth} from "./RequireAuth";
import {sitRouteSpecifications} from "../sit/routing/sitRoutes";
import {routing} from "./routing";
import {RouteSpecification} from "./routeClasses";
import {Route, Routes} from "react-router-dom";
import {MainComponent, MainComponentProps} from "../components/main/MainComponent";
import {LoginPage} from "../auth/LoginPage";
import {NotFound} from "./pages/NotFound";

const loginRoute: RouteSpecification = [
    {wrapper: null},
    routes.login,
    LoginPage,
    {notAuthenticated: true},
];
const notFoundRoute: RouteSpecification<MainComponentProps> = [
    {wrapper: MainComponent, wProps: {header: null},},
    '*',
    NotFound,
    {notAuthenticated: true},
]

const rootRouteSpecifications = ([] as RouteSpecification[])
    .concat(routing as RouteSpecification[])
    .concat(sitRouteSpecifications as RouteSpecification[])
    .concat([loginRoute, notFoundRoute] as RouteSpecification[])
    .filter(r => r);

export const RootRoutes: React.FC = () => {
    const children = rootRouteSpecifications.map(([baseOptions, path, component, overrideOptions], index) => {
        const options = overrideOptions ? {...baseOptions, ...overrideOptions} : baseOptions;

        const Wrapper: React.FC | null = options.wrapper;
        const wrapperProps = options.wrapper ? options.wProps as {} : {};
        const Component: React.FC = component;
        const componentProps = options.componentProps as {};

        let result = <Component {...componentProps}/>;
        result = Wrapper ? <Wrapper {...wrapperProps}>{result}</Wrapper> : result;
        result = !options.notAuthenticated ? <RequireAuth loginUrl={routes.login}>{result}</RequireAuth> : result;
        result = <React.Fragment>
            {/*<div>Route match: "{'' + path}" {JSON.stringify(options)}</div>*/}
            {result}
        </React.Fragment>;

        // react-router-v6 can only have Route elements in a Switch
        return <Route key={index} path={path} element={result}/>
    });
    return <Routes children={children}/>;
}
