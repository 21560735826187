import './MergeXCleanupDashBoard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {DashboardHeader} from "../../dashboard/DashboardHeader";
import {MergeXCSingleDashboard} from "./single/MergeXCSingleDashboard";
import {MergeXCCombinedDashboard} from "./combined/MergeXMergeCombinedDashboard";
import {getDashboardEnv} from "./constants";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXCleanupDashboard: React.FC = observer(() => {
    const {rootAppStore} = useStores();
    const env = getDashboardEnv(rootAppStore.app);
    return <>
        <DashboardHeader/>
        {
            env === 'synergy'
                ? <MergeXCCombinedDashboard/>
                : <MergeXCSingleDashboard/>
        }
    </>
});
