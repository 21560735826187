import {EChartsType} from "echarts/types/dist/shared";
import {EChartContextType} from "../../services/echarts/EChartProvider";

// Example of simple bar chart:
// https://echarts.apache.org/examples/en/editor.html?c=pie-simple&lang=ts

export function initBarChart(echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType {
    const chart = echart.init(root, null, {
        renderer: 'svg',
        width: 600,
        height: 200.
    });
    chart.setOption({
        title: {
            text: 'Referer of a Website',
            subtext: 'Fake Data',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    })
    return chart;
}

export function updateBarChart(chart: EChartsType, data: any[]) {
    chart.setOption({
        series: [
            {
                // Find series by name
                name: 'Access From',
                data: data,
            }
        ]
    })
}