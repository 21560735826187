import React from "react";
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper} from "@mui/material";
import {ArrowDropDown, Person, Redo, Undo} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {useStores} from "../../../../stores";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";
import {TaxonomyApprovalHistoryListSerializer} from "../../../../services/classes/TaxonomyApproval";

type Props = {}
export const TaxApprovalHistoryButtons: React.FC<Props> = observer(() => {
    const {approvalStore} = useStores();

    const [openUndo, setOpenUndo] = React.useState(false);
    const [openRedo, setOpenRedo] = React.useState(false);
    const anchorRefUndo = React.useRef<HTMLDivElement>(null);
    const anchorRefRedo = React.useRef<HTMLDivElement>(null);

    const handleToggle = (undo: boolean) => {
        if (undo)
            setOpenUndo((prevOpen) => !prevOpen)
        else
            setOpenRedo((prevOpen) => !prevOpen)
    };
    const close = (undo: boolean) => {
        if (undo)
            setOpenUndo(false)
        else
            setOpenRedo(false)
    };
    const handleClick = (undo: boolean, gotoOperationNumber: number) => {
        approvalStore.taxonomyApproval.gotoHistoryState(gotoOperationNumber)
        close(undo);
    }
    // TODO: Add the single or multiple selection for dropdown

    return <>
        <ButtonGroup
            className="toolbar-composite-btn"
            color="primary"
            variant="outlined"
            disabled={!approvalStore.taxonomyApproval.undoAllowed}
            ref={anchorRefUndo}
            aria-label="split button">
            <Button
                className="large-icon-btn only-icon-btn"
                onClick={() => {
                    const firstUndo = approvalStore.taxonomyApproval.undoHistory.at(0)
                    if (!firstUndo) return;
                    handleClick(true, firstUndo.undoNumber)
                }}>
                {/*Not sure why this is needed, but when the first element is an icon an unknown margin is added*/}
                &nbsp;
                <Undo/>
            </Button>
            <Button
                // className=""
                // color="primary"
                size="small"
                // variant="outlined"
                onClick={() => handleToggle(true)}
                aria-controls={openUndo ? 'menu-list-grow' : undefined}
                aria-expanded={openUndo ? true : undefined}
                aria-haspopup="menu">
                <ArrowDropDown/>
            </Button>
        </ButtonGroup>
        <Popper
            className="history-popper"
            open={openUndo}
            anchorEl={anchorRefUndo.current}
            role={undefined}
            transition
            disablePortal
            style={{zIndex: 100}}
        >
            {({TransitionProps, placement}) => {
                const history = approvalStore.taxonomyApproval.undoHistory
                return (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            zIndex: 10,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => close(true)}>
                                <MenuList autoFocusItem={openUndo} id="menu-list-grow">
                                    {history.map(({item, undoNumber}) =>
                                        <MenuItem key={item.history_number}
                                                  onClick={() => handleClick(true, undoNumber)}>
                                            <HistoryItem item={item}
                                                         addPrefix={approvalStore.taxonomyApproval.undoContainsOtherUser}/>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                );
            }}
        </Popper>

        <ButtonGroup
            className="toolbar-composite-btn"
            color="primary"
            variant="outlined"
            disabled={!approvalStore.taxonomyApproval.redoAllowed}
            ref={anchorRefRedo}
            aria-label="split button">
            <Button
                className="large-icon-btn only-icon-btn"
                onClick={() => {
                    const firstRedo = approvalStore.taxonomyApproval.redoHistory.at(0)
                    if (!firstRedo) return;
                    handleClick(true, firstRedo.history_number)
                }}>
                &nbsp;
                <Redo/>
            </Button>
            <Button
                // className=""
                // color="primary"
                size="small"
                // variant="outlined"
                onClick={() => handleToggle(false)}
                aria-controls={openRedo ? 'menu-list-grow' : undefined}
                aria-expanded={openRedo ? true : undefined}
                aria-haspopup="menu">
                <ArrowDropDown/>
            </Button>
        </ButtonGroup>
        <Popper
            className="history-popper"
            open={openRedo}
            anchorEl={anchorRefRedo.current}
            role={undefined}
            transition
            disablePortal
            style={{zIndex: 100}}
        >
            {({TransitionProps, placement}) => {
                const history = approvalStore.taxonomyApproval.redoHistory
                return (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => close(false)}>
                                <MenuList autoFocusItem={openRedo} id="menu-list-grow">
                                    {history.map(item =>
                                        <MenuItem key={item.history_number}
                                                  onClick={() => handleClick(false, item.history_number)}>
                                            <HistoryItem item={item}
                                                         addPrefix={approvalStore.taxonomyApproval.redoContainsOtherUser}/>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                );
            }}
        </Popper>
    </>
})

const HistoryItem: React.FC<{ item: TaxonomyApprovalHistoryListSerializer, addPrefix: boolean }> = ({
                                                                                                        item,
                                                                                                        addPrefix
                                                                                                    }) => {
    const {authStore} = useStores();
    const hasOtherAuthor = item.author && item.author.id !== authStore.userId;
    const prefix = addPrefix
        ? <div className="history-popper-prefix" title={hasOtherAuthor && item.author?.email || undefined}>
            {hasOtherAuthor
                ? <Person/>
                : null
            }
        </div>
        : null

    switch (item.history_name as m_taxonomy.Operation) {
        case m_taxonomy.Operation.Add:
            return <>{prefix}Add</>
        case m_taxonomy.Operation.Move:
            return <>{prefix}Move</>
        case m_taxonomy.Operation.Update:
            return <>{prefix}Rename</>
        case m_taxonomy.Operation.Delete:
            return <>{prefix}Delete</>
        case m_taxonomy.Operation.Merge:
            return <>{prefix}Merge</>
        case m_taxonomy.Operation.Suggestion:
            return <>{prefix}Suggestion</>
        case m_taxonomy.Operation.HealthCheck:
            return <>{prefix}Health Check</>
        case m_taxonomy.Operation.Review:
            return <>{prefix}Reviewed</>
    }
    console.error('Unknown operation for HistoryItem', item.history_name, item.history_name_str)
}

