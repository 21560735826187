import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {HierarchyRectangularNode} from "d3";

export namespace taxonomy_editor {
    export type InputData = m_taxonomy.FullSerializer
    export type Values = m_taxonomy.Data
    export type VisualData = {
        labelPosition: {
            top: boolean // Align to top of the label box
            height2: number // Half of the height of the label box
        }
        labelOpacity: number
        nodeOpacity: number
        nodeHeight: number
        overlap: 'both' | 'bottom' | 'top' | ''
        chevronDirection: 'l' | 'r' | null
    }
    export type _NodeData = Omit<m_taxonomy.Tree<Values>, 'children'> & {
        viz: VisualData
        target: AnimationTarget
    }
    export type NodeData = _NodeData & {
        children: NodeData[]
    }
    export type GraphNode = Omit<HierarchyRectangularNode<NodeData>, 'id'>
    // type NodeData = {
    //     labelVerticalPosition: number;
    //     labelOpacity: number
    //     nodeOpacity: number
    //     nodeHeight: number
    // }
    export type AnimationTarget = {
        x0: number
        x1: number
        y0: number
        y1: number
    }
    // type Node = HierarchyRectangularNode<NodeData> & { target?: AnimationTarget } & ExtraProps;


    export type AddNodeData = {
        newId: number
        label: string
        description: string
        value: number
    }
    export type MergeNodeData = {
        sources: GraphNode[]
        destination: AddNodeData
    }

    export const _NO_VALUES: Omit<Values, 'authors'> = {
        description: '',
        p__id__count: 0,
        p__spend__sum: 0,
        s__id__nunique: 0,
    }

    export function buildNode(d: Omit<NodeData, 'viz' | 'target' | 'children'>, authors: number[]): NodeData {
        d.values.authors = authors
        return {
            ...d,
            viz: {
                labelPosition: {top: true, height2: 0},
                labelOpacity: 1,
                nodeOpacity: 1,
                nodeHeight: 0,
                overlap: '',
                chevronDirection: null,
            },
            target: {x0: 0, x1: 0, y0: 0, y1: 0},
            children: [],
        }
    }

    export function NO_VALUES(description = '', authors: number[]): Values {
        return {
            ..._NO_VALUES,
            description,
            authors,
        }
    }

    export function extractIntermediateValues(inputValues: Values): Values {
        // Extract intermediate values
        // TODO: Think more about whats happening here
        const partialValues = {}
        for (const k in inputValues) {
            const inputValue = inputValues[k];
            if (k === 'description' || k === 'category_id') {
                partialValues[k] = inputValue
                continue
            }
            if (typeof inputValue === 'number') {
                partialValues[k] = 0;
            } else {
                console.warn('Do not know how to extract', k, inputValues)
            }
        }
        return partialValues as any;
    }

    export function mergeValues(values: Values[], description: string): Values {
        const result: any = {}
        for (let v of values) {
            for (const k in v) {
                if (k === 'category_id') {
                    // Never merge category Ids but let the backend deduce new ones
                    continue
                }
                if (k === 'description') {
                    continue
                }
                const val = v[k];
                if (!(k in result)) {
                    result[k] = val;
                } else {
                    const prev = result[k];
                    if (typeof prev === 'number') {
                        result[k] += val
                        // TODO-PARKED: When merging nodes to sum of all suppliers is not correct
                    } else {
                        console.warn('Do not know how to merge', k, val)
                    }
                }
            }
        }

        // inv: result['category_id'] === undefined
        result['description'] = description
        return result
    }

    export function exportTree(node: GraphNode): m_taxonomy.Tree {
        const n = m_taxonomy.exportNode(node.data);
        return {
            ...n,
            children: node.children?.map(c => exportTree(c)) || [],
        }
    }
}
