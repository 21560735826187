import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Button, TableCell, TableRow} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {Add} from "@mui/icons-material";
import {EDIT_ACTIONS_INDEX, TAX_APP_REV_COLUMNS} from "./TaxonomyApprovalReviewTable";

export const TaxonomyApprovalReviewAddL1Row: React.FC = observer(() => {
    const {approvalStore} = useStores();

    return <TableRow className="add-l1-row">
        <TableCell colSpan={EDIT_ACTIONS_INDEX}/>
        <MithraTableCell c={TAX_APP_REV_COLUMNS[EDIT_ACTIONS_INDEX]}>
            <Button
                variant="text"
                startIcon={<Add/>}
                onClick={() => {
                    const root = approvalStore.taxonomyApproval.root;
                    if (!root) return;
                    approvalStore.taxonomyApproval.openCreateModal(root);
                }}
            >
                Add L1
            </Button>
        </MithraTableCell>
        <TableCell colSpan={TAX_APP_REV_COLUMNS.length - EDIT_ACTIONS_INDEX - 1}/>
    </TableRow>
})