import './ApprovalChangeChip.scss'
import {Chip, Tooltip, Typography} from "@mui/material";
import React from "react";
import {m_taxonomy} from "../../../../services/classes/TaxonomyClasses";
import {InfoOutlined, Undo} from "@mui/icons-material";
import {CategoryChip} from "../../../../components/category-chip/CategoryChip";

type Props = {
    node: m_taxonomy.TaxApprovalTree
    change: m_taxonomy.Change
    onRevert?: () => void
    readOnly?: boolean
}
export const ApprovalChangeChip: React.FC<Props> = ({change, node, onRevert, readOnly}) => {
    const rejectIcon = <span title="Reject change"><Undo color="inherit"/></span>;
    // const rejectIcon = <Cancel/>;
    const size = 'small' as const;
    // const size = 'medium' as const;

    const delAction = readOnly ? undefined : onRevert;

    // https://mui.com/material-ui/guides/composition/
    const ChipRoot = React.useMemo(() => React.forwardRef<HTMLDivElement, any>(
        (props, ref) => <div ref={ref} {...props} tabIndex="-1"/>,
    ), []);

    switch (change.type) {
        case 'removed':
            return <Chip
                label="Removed"
                color="error"
                variant="outlined"
                size={size}
                onDelete={delAction}
                deleteIcon={rejectIcon}
                component={ChipRoot}
            />
        case 'added':
            if (node.values.description) {
                return <Tooltip
                    title={<>
                        <Typography className="small-header" variant="caption">New description</Typography>
                        <Typography variant="body1">{node.values.description}</Typography>
                    </>}
                    placement="top"
                    PopperProps={{
                        className: 'taxonomy-approval-chip-popper wide',
                    }}>
                    <Chip
                        label="Added"
                        color="success"
                        variant="outlined"
                        size={size}
                        icon={<InfoOutlined/>}
                        onDelete={delAction}
                        deleteIcon={rejectIcon}
                        component={ChipRoot}
                    />
                </Tooltip>
            } else {
                return <Chip
                    label="Added"
                    color="success"
                    variant="outlined"
                    size={size}
                    onDelete={delAction}
                    deleteIcon={rejectIcon}
                    component={ChipRoot}
                />
            }
        case 'moved':
            return <Tooltip
                title={<>
                    <Typography className="small-header" variant="caption">Previous location</Typography>
                    <CategoryChip labels={change.original_location}/>
                </>}
                placement="top"
                PopperProps={{
                    className: 'taxonomy-approval-chip-popper no-width-limit',
                }}>
                <Chip
                    label="Moved"
                    color="secondary"
                    variant="outlined"
                    size={size}
                    icon={<InfoOutlined/>}
                    onDelete={delAction}
                    deleteIcon={rejectIcon}
                    component={ChipRoot}
                />
            </Tooltip>
        case 'updated':
            return <Tooltip
                title={<>
                    <Typography className="small-header" variant="caption">Previous {change.field}</Typography>
                    <Typography>{change.original_value}</Typography>

                    <hr style={{margin: '.5em 0'}}/>

                    <Typography className="small-header" variant="caption">New {change.field}</Typography>
                    <Typography>{node.values.description}</Typography>
                </>}
                placement="top"
                PopperProps={{
                    className: 'taxonomy-approval-chip-popper wide',
                }}>
                <Chip
                    label={`Updated ${change.field}`}
                    color="warning"
                    variant="outlined"
                    size={size}
                    icon={<InfoOutlined/>}
                    onDelete={delAction}
                    deleteIcon={rejectIcon}
                    component={ChipRoot}
                />
            </Tooltip>
        case 'renamed':
            return <Tooltip
                title={<>
                    <Typography className="small-header" variant="caption">Previous name</Typography>
                    <CategoryChip labels={[change.original_label]}/>
                </>}
                placement="top"
                PopperProps={{
                    className: 'taxonomy-approval-chip-popper no-width-limit',
                }}>
                <Chip
                    label="Renamed"
                    color="warning"
                    variant="outlined"
                    size={size}
                    icon={<InfoOutlined/>}
                    onDelete={delAction}
                    deleteIcon={rejectIcon}
                    component={ChipRoot}
                />
            </Tooltip>
    }
}