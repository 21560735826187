import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import React from "react";
import {observer} from "mobx-react-lite";
import {sum} from "../../../utils/js-utils";
import {SIT_TEST} from "../../SitApit";

type DoiDataRow = { DOI: string, source: [string, number][] };

function init(
    echart: EChartContextType,
    root: HTMLElement,
    data: DoiDataRow[],
    datasets: any[],
    width: number,
): EChartsType {
    const procurementPipelinesChart = echart.init(root, 'mithra', {
        renderer: 'svg',
        width: width,
        height: 500,
    });

    var procurementPipelinesChartOption = {
        tooltip: {
            trigger: 'axis',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {show: true},
            },
        },
        calculable: true,
        legend: {
            data: data.map(item => item.DOI),
            left: '10%',
            padding: [
                5,  // up
                10, // right
                5,  // down
                10, // left
            ],
            textStyle: {
                fontFamily: 'roboto',
                overFlow: 'break',
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        color: [
            "#091344",
            "#ff3435",
            "#430944",
            "#0262f2",
            "#08a57a",
            "#a4d8c2",
            "#f3d999",
            "#d3758f",
            "#dcc392",
            "#2e4783",
            "#82b6e9",
            "#ff6347",
            "#a092f1",
            "#0a915d",
            "#eaf889",
            "#6699FF",
            "#ff6666",
            "#3cb371",
            "#d5b158",
            "#38b6b6"
        ],
        dataset: datasets,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            axisLine: {show: false},
            axisLabel: {show: false},
            axisTick: {show: false},
            splitLine: {show: false},
        },
        series: getSeries(data, width),
    };

    // Display the chart using the configuration items and data just specified.
    procurementPipelinesChart.setOption(procurementPipelinesChartOption);

    return procurementPipelinesChart;
}

function getSeries(data: DoiDataRow[], width: number) {
    const newTotal = sum(data.map(d => Number(d.source.at(-1)?.at(1)) ?? 0));
    const oldTotal = sum(data.map(d => Number(d.source.at(0)?.at(1)) ?? 0));
    const percentage = oldTotal === 0 ? ' ' : ((newTotal - oldTotal) / oldTotal * 100).toFixed(0) + '%';

    return [
        ...data.map((item, index) => ({
            name: item.DOI,
            type: 'bar',
            encode: {
                x: 'Saving month',
                y: 'Acc Saving',
            },
            datasetId: `${item.DOI} accumulative aggregated savingItems by month`,
            stack: 'saving',
            color: ['#091344', '#384068', '#6f7592', '#9fa3b6', '#c3c5d1'][4 - index % 5], // Adjust color based on index
            label: {
                show: true,
                formatter: '{@Acc Saving}',
            },
            emphasis: {
                focus: 'series',
            },
        })).reverse(),
        {
            name: 'Total',
            type: 'line',
            selectorLabel: {show: false},
            legendHoverLink: false,
            lineStyle: {width: 1},
            step: true,
            data: data.reduce((acc, curr) => {
                curr.source.forEach((item, index) => {
                    if (!acc[index]) {
                        acc[index] = [item[0], 0]; // Initialize with the month and 0
                    }
                    acc[index][1] += item[1]; // Accumulate the "Acc Saving" values
                });
                return acc;
            }, [] as [string, number][]),
            endLabel: {
                show: true,
                formatter: percentage,
                position: 'middle',
                offset: [-width / 4 - 20, 0],
            },
        },
    ];
}

type Props = {
    data: DoiDataRow[] | null
    width: number
}
export const BeforeAfterChart: React.FC<Props> = observer(({data, width}) => {
    if (!data) return <></>

    const datasets = data.map(item => ({
        id: `${item.DOI} accumulative aggregated savingItems by month`,
        dimensions: [
            {name: 'Saving month', type: 'time'},
            {name: 'Acc Saving', type: 'number'},
        ],
        source: item.source,
    }))

    return <>
        {SIT_TEST && '<BeforeAfterChart/>'}
        {/*<div>*/}
        {/*    data={JSON.stringify(data)}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    Angelo={JSON.stringify(PipelineDashboardController.data)}*/}
        {/*</div>*/}
        <MithraEchart
            initChart={(echart, root) => init(echart, root, data, datasets, width)}
            data={data}
            autoUpdateDataset
            updateChart={chart => {
                chart.setOption({
                    series: getSeries(data, width)
                })
            }}
        />
    </>;
});
