import {makeAutoObservable} from "mobx";
import {environment} from "../env";
import {AnyDashboardCard, ExperimentalDashboardCard, isExperimentalCard} from "../pages/dashboard/dashboard-types";
import moment from "moment";
import {HierarchicalSankeyOptions} from "../components/visualization/sankey/HierarchicalSankeyChart";
import {CurrencyAbbreviation} from "../components/currency-component/CurrencyClasses";
import {MatKpiTreeValues} from "../services/ApiTypes";
import {SearchConfiguration, SortingConfiguration} from "../customization/SearchConfiguration";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {C} from "../configurations/active-configuration";
import {FilterConfiguration} from "../customization/FilterConfiguration";
import {Range} from "../utils/ts-utils";
import {Bag} from "../services/classes/Bag";
import {MultiTypedSearchOptionsSpecification, Option} from "../components/search/MultiTypedSearchManager";
import {deepCopy} from "../utils/js-utils";

const P_CONTEXTS = ['1', '2', '3'] as const;
type CONTEXT_TYPES = typeof P_CONTEXTS[number];

type ContextFieldName = `p_context_${CONTEXT_TYPES}` | `s_context_${CONTEXT_TYPES}`
type ContextColName = `${ContextFieldName}_col_name`
    | `${ContextFieldName}_col_type`
    | `${ContextFieldName}_col_class`

// type ContextColName = `${ContextFieldName}_col_name` | `${ContextFieldName}_col_type`;

type DATE_COLUMN_TYPES = CONTEXT_TYPES;
type DateContextFieldName = `p_ddate_${DATE_COLUMN_TYPES}` | `p_date_${DATE_COLUMN_TYPES}`
type DateContextColName = `${DateContextFieldName}_col_name` | `${DateContextFieldName}_col_type`;

export type CompanyProfile = {
    [key in (
        ContextColName |
        DateContextColName
        )]?: string;
} & {
    p_name_col_name: string
    p_description_col_name: string | false
    s_col_name?: string | false
    bu_col_name?: string | false
    ppv_group_key_col_name?: string | false
    show_bu_name_in_review_pages?: true
    /**
     * The preference of the context columns, if desired
     */
    p_context_pref?: Range<1, 4>[]
    /**
     * Other partContextColNames that should be shown
     */
    defaultPartContextColNames?: ContextColum[]
    /**
     * The preference of the d_date columns, if desired
     */
    p_ddate_pref?: number[]
    /**
     * Column width for specific attributes
     */
    s_name_col_width?: number | string
    p_name_col_width?: number | string
    p_ddate_1_col_width?: number | string
    p_previous_l_col_width?: number | string
    p_suggested_l_col_width?: number | string
    p_bu_name_col_width?: number | string
    p_description_col_width?: number | string
    sortingConfiguration?: SortingConfiguration
    /**
     * Filter capability in the categorization review screen
     */
    filterConfiguration?: FilterConfiguration
    /**
     * The type of Googler search that should be used
     */
    searchConfiguration?: SearchConfiguration

    /**
     * TODO CAT-1649: This is hard to maintain and evolve, we should make this more generic
     */
    catFilterSearchAlsoPC3?: true

    /**
     * The data keys that should be selected by default for the categorization review search screen
     */
    defaultSearchKeys?: string[]
    additionalSearchKeys?: string[]

    hide_main_menu?: boolean
    main_menu_components?: string[]
    tease_menu_components?: string[]

    show_synergies_main_menu?: boolean
    tease_main_menu?: boolean

    /**
     * When using a separate file for the taxonomy, only show the number of categories
     */
    taxonomy_builder_only_n_cats?: boolean

    /**
     * The nth-smallest number that should be visible, in the domain [0, 1]
     * i.e. for graph sizes [0, 10, 20] the 66% visibility means: number 10 should be at least visible (20px high)
     * Higher values lead to smaller diagrams
     */
    sankeyVisibilityFraction?: number

    sankeyMinHeight?: number
    sankeyMaxHeight?: number

    taxonomyBuilderHeight?: number

    /**
     * Note: This is BROKEN since move to materialized backend
     */
    allowReviewDownloadExcel?: boolean
    allowReviewDownloadCsv?: boolean

    locale: string
    currency: string

    /**
     * We are not able to link a single taxonomy to multiple bags.
     * For this reason we hardcode the taxonomy in the frontend. CAT-353
     */
    hardcodeTaxonomyId?: number

    hardcodedKPIAbbreviation?: CurrencyAbbreviation

    /**
     * Allow the user to send for approval of a taxonomy which is not filled completely to it's deepest level
     *
     * This is a specific switch because we currently do not support categorization at variable depth.
     * As well we did not test how such a categorization looks, nor did the data model evolve to support this.
     * So we specifically enable it, on customer basis.
     */
    allowIncompleteTaxonomy?: boolean

    /**
     * Hide the send for approval buttons, (For demonstration builds only)
     */
    hideApprovalButtons?: true

    /**
     * A specific master bag to be used
     */
    hardcodeMasterBagId?: number
    hardcodedBagId?: number
    hardcodedCategorizationBagId?: number
    hardcodedCombinedBagId?: number
    hardcodedBaseTaxonomyId?: number
    hardcodedSubsidiaryBagId?: number
    hardcodedTaxonomySuggestionId?: number
    hardcodedOriginalTaxonomyId?: number

    /**
     * A specific master taxonomy to be used to merge datasets
     */
    masterTaxonomyId?: number

    /**
     * Use the modified ATK synergy dashboard
     * @deprecated TODO: Replace this with different deployment configuration
     */
    new_synergy_dashboard?: boolean

    /**
     * Enable the displaying of databags in the table of the Synergy dashboard
     * Note: The names of the databag are trimmed by "(...)"
     */
    synergyDashboardShowDatabagName?: true

    /**
     * Enable the business units in the advanced dashboard
     */
    synergyDashboardShowBusinessUnits?: true

    /**
     * Enable the cc_description filter in the advanced dashboard
     */
    synergyDashboardShowCDescriptionFilter?: true

    hardcodedSynergyId?: number

    /**
     * view only option for the taxonomy builder
     *
     * For when we want the customer to not change the taxonomy anymore
     */
    taxonomyBuilderViewOnly?: boolean

    /**
     * By default, this is disabled
     */
    enableSankey?: boolean

    /**
     * Show the experimental opportunity tracker
     */
    enableOpportunityTracker?: boolean

    /**
     * Show the Business Unit Selector in the review page or not:
     * - Enabled the breadcrums and first BU filter page
     */
    disableBuFilter?: boolean

    hideBuInCategorizationTable?: boolean

    fullMenu?: true;

    ppvDemo?: true;

    disableRunningOfModels?: boolean;
    masterCompanyName?: string;
    compareCompanyName?: string;
    showResultPills?: boolean;

    categorizationShowScore?: undefined | true | false;
    categorizationShowScoreForBag?: number[];

    /**
     * CAT-968: Add a hack to disable showing uncategorized
     */
    hackHideUncategorizedInReview?: true;

    /**
     * Switch for turning data ingestion on or off
     */
    enableDataIngestion?: true;
    demoFakeDataIngestion?: true;
    demoFakeDataIngestionTarget?: { databagId: number };

    /**
     * Switch for turning taxonomy ingestion on or off
     */
    enableTaxonomyIngestion?: true;

    /**
     * Switch for turning SIT on or off
     */
    enableSavingInitiativeTracker?: boolean;

    /**
     * Switch for turning ai suggestions in taxonomy builder on or off
     */
    useAiTaxonomySuggestion?: true;
    /**
     Switch for turning Taxonomy Suggestions Demo in taxonomy builder on or off
     * (Initially for Danone)
     */
    demoFakeTaxonomySuggestion?: true
    /**
     * Switch for turning ai health check in taxonomy builder on or off
     */
    useAiTaxonomyHealthCheck?: boolean;
    /**
     * Switch for turning the parent supplier in the advanced dashboard on or off
     */
    hideParentSupplierOpportunityDashboard?: true;
    /**
     * Switch for turning the L4 category on or off in the advanced dashboard
     */
    showL4InOpportunityDashboard?: true;
    /**
     * The databag id of the dashboard to show in the routes.merge_x_merge_advanced_dashboard url
     */
    mergeXmergeOpportunityDashboardBagId?: number;
    /**
     * The databag id of the dashboard to show in the routes.merge_x_merge_advanced_dashboard url
     */
    advDashboardInitialDateFilter?: 'last_year';
    /**
     * Enable the pre-loading of the advanced dashboard
     */
    disablePreloadAdvDashboard?: true;
    /**
     * Hide some example dummy opportunities in the opportunity tracker
     */
    hideHardcodedOpportunities?: true;

    /**
     * Cards that should only be shown as trial
     */
    disabledCards?: AnyDashboardCard[]

    /**
     * Cards that should only be shown as trial
     */
    hideCards?: AnyDashboardCard[]

    /**
     * Experimental cards that should be shown in the dashboards
     */
    showExperimentalCards?: ExperimentalDashboardCard[]

    /**
     * Show the sub bars in the categorization screen with uncategorized below it
     * (Only works well in equally distributed taxonomy)
     */
    showUncategorizedSubBars?: boolean

    /**
     * If the results are created beforehand, we can disable the supplier normalization model
     * We also skip the Ai Supplier Normalization model while we are still developing the AI integration
     * This is mostly used for demonstration purposes
     */
    skipAiSupplierNormalizationModel?: boolean

    /**
     * Disable the supplier normalization AI integration
     * For clients that do not have an automatic integration with the AI model
     */
    disableSupplierNormalizationAiModel?: true

    /**
     * TODO: Ad hoc switch for CAT-969
     * Hide KPI's of supplier normalization with very low values
     */
    hideSmallStandaloneSpend?: { minStandaloneSpend: number, minStandaloneSuppliers: number }

    /**
     * Demonstrate the round trips for AI
     */
    aiRoundTripDemo?: true

    /**
     * When the results are created beforehand, and we do not allow the user to run the model from the FE
     */
    skipAiTaxonomyMapperModel?: true
    /**
     * TODO: CAT-888: Remove this hack
     */
    hackNSuppliersFromCommonSuppliers?: true
    lookerUrls?: string[]
    displaySynergyOverview?: boolean
    hardcodedDatasetNameLeft?: string
    hardcodedDatasetNameRight?: string
    hardcodedNumberTotalSuppliersLeft?: number
    hardcodedNumberTotalSuppliersRight?: number
    hardcodedNumberTotalCommonSuppliers?: number
    hardcodedTotalSpendCommonSuppliers?: number
    hardcodedNumberCommonSuppliersLeft?: number
    hardcodedNumberCommonSuppliersRight?: number
    hardcodedTotalSuppliersSpendLeft?: number
    hardcodedTotalSuppliersSpendRight?: number
    hardcodedCommonSuppliersSpendLeft?: number
    hardcodedCommonSuppliersSpendRight?: number
    hardcodedNumberTotalCategoriesLeft?: number
    hardcodedNumberTotalCategoriesRight?: number
    hardcodedNumberTotalCommonCategories?: number
    hardcodedNumberCommonCategoriesLeft?: number
    hardcodedNumberCommonCategoriesRight?: number
    hardcodedTotalSpendCommonCategories?: number
    hardcodedTotalCategoriesSpendLeft?: number
    hardcodedTotalCategoriesSpendRight?: number
    hardcodedCommonCategoriesSpendLeft?: number
    hardcodedCommonCategoriesSpendRight?: number

    // hackCategorizationUseLatestCategories?: true // > Changes to the taxonomy always need to go though the review

    // This two values are used to determine on with taxonomy to hide the error for missing L while sending for approval
    hackTaxonomyToHideApprovalError?: number
    readOnlySuggestion?: boolean
    hideLinksUserDropDownMenu?: boolean
    /**
     * Use this field to specify relation data is to be displayed in the categorization review table
     */
    categorizationReviewSubRowRelationData?: Array<CategorizationReviewSubRowRelationsData>
    /**
     * This hides the group by supplier function
     */
    hideGroupBySupplier?: true
    /**
     * Shows if the group by supplier is enabled when the user loads the page
     * true: Group By Supplier not enabled by default
     */
    initialGroupBySupplierInactive?: boolean,
    categorizationSinglePageSize?: number
    categorizationGroupedPageSize?: number
    categorizationSublistPageSize?: number

    ppvTablePageSize?: number
    ppvSubTablePageSize?: number
    /**
     * Disable the ability to add notes and remove parts
     */
    isPpvReadOnly?: true

    /**
     * Switch to turn on the option to fake demo a categorization run
     */
    demoFakeReinforcedCategorization?: true
    demoFakeReinforcedCategorizationTarget?: { databagId: number, taxonomyId: number, taxonomySize: number }
    demoFakeSendTaxonomyForApprovalTarget?: { databagId: number, taxonomyId: number, taxonomySize: number }
    /**
     * Show the supplier_id in the Supplier Normalization page
     */
    showSupplierIdInSupplierNormalization?: true
    /**
     * Hack: cutoff the first n digits of the s_id when showing it to the user
     * TODO: Introduce a customer_bu_id field in the BE
     */
    showSupplierIdPrefixCutoffHack?: number
    /**
     * Hack: cutoff the first n digits of the bu_id when showing it to the user
     * TODO: Introduce a customer_bu_id field in the BE
     */
    showBuIdPrefixCutoffHack?: number
    /**
     * Switch to turn on the business case generator demo
     */
    demoFakeBusinessCaseGenerator?: true
    /**
     * Add the s_id next to the supplier's name
     */
    showSupplierIdInAdvDashboard?: true
    /**
     * Add bu_id next to the business unit's name
     */
    showBuWithId?: true
    /**
     * Hides the 'No Entity' filter in the bu selection categorization page
     */
    hideNoBuFilterInCategorization?: true
    /**
     * When the dataset has the specially names "missing" BU, we do not need to check for NULL bu's
     */
    datasetHasMissingBu?: true
    /**
     * Hides the supplier search in the advanced dashboard
     */
    disableAdvDashboardSearchBySupplier?: true

    disablePPVforDatabag?: number[]

    /**
     * Enable the BE data demo for contract parser
     */
    useBeDemoContractData?: true

    /**
     * Makes use of the Firestore architecture for the contract parser
     */
    hasFirestore?: true

    /**
     * Us the direct upload of cat-ingestion. Otherwise, we use the chunked upload of GCP
     */
    useIngestionDirectUpload?: true

    /**
     * Show reason column in the categorization review table
     */
    showReasonColumn?: true

    /**
     * Enable the AI integration for the categorization review
     */
    enableVertexAiCategorizationIntegration?: true

    /**
     * Enable the AI integration for the categorization review for STAFF
     */
    enableVertexAiCategorizationIntegrationForStaff?: true

    /**
     * override categorizaion model name
     */
    overrideAiCategorizationPipelineName?: string

    /**
     * By default show the standalone suppliers
     * Normally we do not, as the business value is in the common matches.
     *
     * But for some customers we only find single matches, so for data to show up on the homepage we would need to
     * show the standalone suppliers by default.
     */
    defaultShowStandAloneSuppliers?: true

    /**
     * Show chat at the homepage
     * (experimental)
     */
    enableChat?: true

    /**
     * Enable the fake creation of opportunities in chat
     */
    enableChatOpportunityDemo?: true
}

export const DEFAULT_SANKEY_VISIBILITY_FRACTION = .67;

function getCompanyProfile(): CompanyProfile {
    return C.profile;
}

type ContextColum = {
    colName: string,
    colType: string,
    dataField: string,
    contextI: CONTEXT_TYPES,
    colClassName: string
};

export type CategorizationReviewSubRowRelationsData = {
    relation: string,
    columns: Array<CategorizationReviewSubRowRelationData>
}

type CategorizationReviewSubRowRelationData = {
    db_column: string
    colSpec: ColSpec | null
}

/**
 * TODO: Retrieve this from the BE or compile it to hide it from other customers
 * - We want this to be available from the start to render the right page.
 * - We want to hide it between customers
 */
export default class ProfileStore {
    public p: CompanyProfile = getCompanyProfile()

    constructor() {
        makeAutoObservable(this)

        // TODO CAT-744: Replace moment with Luxon

        // const locale = (window.navigator.userLanguage || window.navigator.language) || 'en-GB';
        // moment.locale(locale);
        // moment.locale('en-GB');

        // import 'moment/locale/nl';
        // import 'moment/locale/fa';

        moment.locale(this.p.locale);
        // console.log('moment.locales()', moment.locales())
    }

    /**
     * Show or not show a card
     */
    showCard(card: AnyDashboardCard): boolean {
        const isHidden = Boolean(this.p.hideCards?.includes(card));
        if (!isExperimentalCard(card)) {
            return !isHidden;
        } else {
            // Check if it's explicitly visible
            let visible = Boolean(this.p.showExperimentalCards?.includes(card));

            switch (card) {
                case 'data-ingestion':
                    visible ||= Boolean(this.p.demoFakeDataIngestion || this.p.enableDataIngestion);
                    break
            }

            if (visible && isHidden) {
                console.warn(`Card "${card}" is both hidden and visible, we hide it`)
                return false;
            }
            return visible;
        }
    }

    /**
     * Show a card as trial/disabled
     */
    isDisabledCard(card: AnyDashboardCard, bag: Bag | undefined): boolean {
        let isDisabled = Boolean(this.p.disabledCards?.includes(card));
        if (!isDisabled) {
            if (this.p.disablePPVforDatabag && bag) {
                isDisabled ||= Boolean(this.p.disablePPVforDatabag.includes(bag.id));
            }
        }
        return isDisabled;
    }

    /**
     * Return the context column names
     * @returns {colName, colType, dataField, contextI}[]
     */
    get partContextColNames(): ContextColum[] {
        const result: ContextColum[] = this.p.defaultPartContextColNames ? [...this.p.defaultPartContextColNames] : []
        const contexts = this.p.p_context_pref || P_CONTEXTS
        for (const iC of contexts) {
            const C = String(iC) as CONTEXT_TYPES;
            const colName = this.p[`p_context_${C}_col_name`];
            if (colName === undefined) {
                return result;
            } else {
                const dataField = `p_context_${C}`
                const colType = this.p[`p_context_${C}_col_type`] || 'any';
                const colClass = this.p[`p_context_${C}_col_class`];
                const colClassName = `col-${dataField}`
                    + (colType === 'any' ? '' : ` col-type-${colType}`)
                    + (colClass ? ` ${colClass}` : ``)
                result.push({colName, colType, dataField, contextI: C, colClassName})
            }
        }
        return result;
    }

    getColumnHeader(field: string): string {
        switch (field) {
            case 'p__name':
                return this.p.p_name_col_name
            case 'p__description':
                return this.p.p_name_col_name
            case 'p__mat_group_raw':
                // TODO: p__mat_group_raw should not be used anymore in the FE
                return this.p.p_context_1_col_name || 'Description'
        }
        console.error('Unknown field', field)
        return '?'
    }

    filterLastName(userLastName: string) {
        if (userLastName.toLowerCase() === environment.customerName.toLowerCase()) {
            // Filter last names with the same name as the company
            return ''
        }
        return userLastName;
    }

    get currencyFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.p.locale, {style: 'currency', currency: this.p.currency});
    }

    get currencySymbol(): string {
        return (0).toLocaleString(this.p.locale, {
            style: 'currency',
            currency: this.p.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).replace(/\d/g, '').trim()
    }

    getCategoryViewConfig(selectedKpi: MatKpiTreeValues, nLinks: number, topLinkValue: number): Partial<HierarchicalSankeyOptions> {
        if (nLinks === 0) {
            return {};
        }
        const options: Partial<HierarchicalSankeyOptions> = {
            minValueForLabel: 0,
        }
        if (environment.customerName === 'vion') {
            // TODO: CAT-712
            // const zoom = 1.8
            // options.width = 1920 / zoom
            // options.height = 880 / zoom
            // options.margin.top = 50
            // options.margin.bottom = 10
            // options.margin.left = 100 + options.width / 6
            // options.margin.right = 100 + options.width / 6
            if (selectedKpi === 'parts' && topLinkValue >= 95255 / 2) {
                options.minValueForLabel = 4300;
            }
            if (selectedKpi === 'spend' && topLinkValue >= 715617252 / 2) {
                options.minValueForLabel = 4300;
            }
        }
        if (environment.customerName === 'franke') {
            // TODO: CAT-712
            options.margin = {
                left: 180,
                right: 80,
                top: 5,
                bottom: 5,
            }
            if (selectedKpi === 'parts') {
                options.minValueForLabel = 800;
            }
            if (selectedKpi === 'spend') {
                options.minValueForLabel = 6000000;
            }
        }

        return options;
    }

    get partDataName() {
        let pNameColName = this.p.p_name_col_name;
        if (pNameColName && pNameColName.toLowerCase() === 'name') {
            // When it's simply called name then we have no specific name for this data type
            pNameColName = ''
        }
        return pNameColName || 'Transaction'
    }

    get supplierDataName() {
        return this.p.s_col_name || 'Supplier'
    }

    get buDataName() {
        return this.p.bu_col_name || 'Business Unit'
    }

    get partDescriptionColumnName() {
        return this.p.p_description_col_name || 'Description';
    }

    get groupKeyDataName() {
        return this.p.ppv_group_key_col_name || 'description';
    }

    get partNameAndDescriptionColumnName() {
        const pName = this.p.p_name_col_name;
        const pDesc = this.partDescriptionColumnName;
        return `${pName} + ${pDesc}`;
    }

    /**
     * TODO: CAT-1649: Reduce the complexity of this feature
     */
    get searchOptions(): Option[] {
        const pName = this.p.p_name_col_name;
        const pDesc = this.partDescriptionColumnName;

        if (environment.package === 'classic') {
            // FIXME: We need to revise this approach for franke
            throw new Error('Classic package is not supported anymore')
        }
        if (this.p.catFilterSearchAlsoPC3 && !this.p.p_context_3_col_name) {
            // FIXME: We need to revise the search field, this is case is not implemented
            throw new Error('Not implemented case: catFilterSearchAlsoPC3 and no p_context_3_col_name')
        }

        // c is the first context field from the context array that is not a date
        let c: CONTEXT_TYPES = '1';
        if (this.p.p_context_pref) {
            for (let i = 0; i < this.p.p_context_pref.length; i++) {
                const _c = this.p.p_context_pref[i];
                const type = this.p[`p_context_${_c}_col_type` as const];
                if (type !== 'date') {
                    c = String(_c) as CONTEXT_TYPES;
                    break;
                }
            }
        }

        let pC, pND, pDC, pNDC, pNDC_, pAll;
        if (pName === 'Mat ID' && pDesc === 'Mat desc' && this.p.p_context_1_col_name === 'Mat group desc') {
            // OLD AND TO BE IGNORED
            pC = 'Mat group desc'
            pND = 'Mat ID + desc';
            pDC = 'Mat desc + group desc';
            pNDC = 'Mat ID + desc + group desc';
            pNDC_ = 'Search by Mat or group';
            pAll = 'Supplier + Mat + group desc';
        } else {
            const p_context_3_col_name = this.p.p_context_3_col_name || '';
            pC = this.p[`p_context_${c}_col_name`] || '';
            pND = `${pName} + ${pDesc}`;
            pDC = `${pName} + ${pC}`;
            pNDC = `${pName} + ${pDesc} + ${pC}`;
            pNDC_ = `Search by ${pName}, ${pDesc} or ${pC}`;
            const _all = [this.supplierDataName, pName, pDesc, pC]
                .concat(C.profile.catFilterSearchAlsoPC3 && c !== '3' ? [p_context_3_col_name] : []) //  TODO: CAT-1649;
            pAll = `Search by ${_all.slice(0, -1).join(', ')} or ${_all.slice(-1)}`;
        }
        const result: (false | Option)[] = [
            {key: 's_name', label: this.supplierDataName},
            {key: 'p_name', label: pName},
            {key: 'p_description', label: pDesc},
            {key: `p_context_${c}`, label: pC},
            {key: 'p_name_description', label: pND},
            {key: `p_description_context_${c}`, label: pDC},
            {key: `p_name_description_context_${c}`, label: pNDC},
            this.p.catFilterSearchAlsoPC3 && this.p.p_context_2_col_name ? {
                key: `p_context_2`,
                label: `Search by ${this.p.p_context_2_col_name}`
            } : false,
            (this.p.catFilterSearchAlsoPC3) && this.p.p_context_3_col_name ? {
                key: `p_context_3`,
                label: `Search by ${this.p.p_context_3_col_name}`
            } : false,
            {key: `all_context_${c}`, label: pAll},
        ]
        return result.filter(s => Boolean(s)) as Option[];
    }

    get supplierSearchSpecification(): MultiTypedSearchOptionsSpecification {
        return {
            defaultIndices: [0],
            options: [
                this.searchOptions[0],
            ],
        }
    }

    /**
     * TODO: CAT-1649: Reduce the complexity of this feature
     */
    get partSearchSpecification(): MultiTypedSearchOptionsSpecification {
        // // When excluding the supplier
        // const options = this.searchOptions.slice(1)
        // const defaultIndex = this.p.catFilterSearchPC23 ? options.length - 2 : 4;

        const options = this.searchOptions.slice(0)
        const defaultIndices = [this.p.catFilterSearchAlsoPC3 ? options.length - 2 : 5];

        return {
            defaultIndices,
            options,
        }
    }

    isColumnSortable(dataField: ColSpec['columnFieldId'] | undefined): boolean {
        // CAT-722: This feature is not needed
        switch (this.p.sortingConfiguration) {
            case 'by_default':
                switch (dataField) {
                    case 'p_spend':
                    case 'p_suggestion_score':
                    case 'p_ddate_1':
                        return true;
                    default:
                        return false;
                }
            case 'by_default_and_c1':
                switch (dataField) {
                    case 'p_spend':
                    case 'p_suggestion_score':
                    case 'p_context_1':
                    case 'p_ddate_1':
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    }

    showCategorizationScoreForBag(bagId: number | undefined) {
        if (environment.overruleShowScore) return true;
        if (environment.isTestReviewPage) return true;
        if (this.p.categorizationShowScore !== undefined) {
            return Boolean(this.p.categorizationShowScore);
        }
        if (bagId === undefined) return false;
        return this.p.categorizationShowScoreForBag?.includes(bagId) || false
    }

    /**
     * Use the AI-Round-Trip flow for testing
     */
    isVertexCategorizationEnabled(isStaff: boolean) {
        return Boolean(
            // Vertex AI integration is enabled
            this.p.enableVertexAiCategorizationIntegration
            ||
            // Vertex AI integration is enabled
            (isStaff && this.p.enableVertexAiCategorizationIntegrationForStaff)
            ||
            // Or the demo is enabled
            (
                // aiRoundTripDemo is enabled
                this.p.aiRoundTripDemo
                // demoFakeReinforcedCategorization is disabled
                && !this.p.demoFakeReinforcedCategorization
                // The environment is deployed as staging, or it's running locally
                && (String(environment.environmentName).includes('staging') || !environment.production)
            )
        );
    }

    /**
     * Make a deep copy and add based on the `relation` and `db_column`
     */
    static addRelationData(data: CategorizationReviewSubRowRelationsData[] | undefined, toAdd: CategorizationReviewSubRowRelationsData): CategorizationReviewSubRowRelationsData[] {
        const result = deepCopy(data ?? []);
        const m = result.find(d => d.relation === toAdd.relation);
        if (!m) {
            result.push(toAdd);
        } else {
            toAdd.columns.forEach(col => {
                const c = m.columns.find(c => c.db_column === col.db_column);
                if (!c) {
                    m.columns.push(col);
                } else {
                    // Already exists, update it
                    Object.assign(c, col);
                }
            })
        }
        return result;
    }
}
