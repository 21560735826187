import {
    Data as MatchTreeData,
    DataConn,
    Tree,
    TreeDataType,
    UpdateData
} from "../../components/visualization/match-categories-tree/MatchCategoriesTreeVisualization";
import {makeAutoObservable, runInAction} from "mobx";
import {EMPTY, forkJoin, from, map} from "rxjs";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {AxiosResponse} from "axios";
import {HierarchyNode, stratify} from "d3-hierarchy";
import {CmpTaxonomyNode} from "../../components/visualization/match-categories-tree/CmpTaxonomyNodeType";
import {capitalize} from "@mui/material";
import {toCurrency, toCurrencyWithP} from "../../components/currency-component/CurrencyClasses";
import {hardcoded_dpw} from "./hardcoded/hardcoded_dpw";
import {
    Data as CmpBreakdownData
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {CategoryOverlapData, CommonSupplierData} from "./hardcoded/hardcoded_dpw_types";
import {CollapsibleIndentTreeData} from "../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import ProfileStore from "../../stores/ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";

export class CategoryCompareManager {

    matchTreeData?: MatchTreeData

    constructor(private api: MithraMaterializedApi, private profileStore: ProfileStore) {
        makeAutoObservable(this)
    }

    get selectedMasterLevel(): string[] | undefined {
        return nodeToCategories(this.leftSelectionIndex, this.cmpPointData);
    }

    get selectedCompareLevel(): string[] | undefined {
        return nodeToCategories(this.rightSelectionIndex, this.cmpPointData);
    }

    downloadHardcodedData() {
        if (this.matchTreeData) {
            return EMPTY.subscribe();
        }
        const baseURL = window.location.origin;
        return forkJoin([
            from(this.api.http.get<m_taxonomy.FullSerializer>('dpw_demo/hardcoded_master_taxonomy_tree.json', {baseURL})).pipe(
                this.$convert(true, true),
            ),
            from(this.api.http.get<m_taxonomy.FullSerializer>('dpw_demo/hardcoded_merger_taxonomy_tree.json', {baseURL})).pipe(
                this.$convert(false, true),
            ),
            from(this.api.http.get<any[]>('dpw_demo/harcoded_connected.json', {baseURL}))
            // of({data: []}),
        ]).subscribe({
            next: ([masterHierarchy, mergerHierarchy, connections]) => runInAction(() => {
                this.matchTreeData = {
                    left: masterHierarchy as unknown as CollapsibleIndentTreeData,
                    right: mergerHierarchy as unknown as CollapsibleIndentTreeData,
                    connections: connections.data,
                };
                this.cmpPointData = ([] as Tree[])
                    .concat(masterHierarchy.descendants())
                    .concat(mergerHierarchy.descendants())
                this.cmpConnectionData = connections.data
            })
        })
    }

    private $convert(left: boolean, limitedLevels = false) {
        return map<AxiosResponse<m_taxonomy.FullSerializer>, HierarchyNode<CmpTaxonomyNode>>(resp => {
            const nodes = this.extractCmpNodes((resp.data as any).tree, undefined, left, limitedLevels, 1);
            const converter = stratify<CmpTaxonomyNode>().id(d => d.id).parentId(d => d.parentId)
            return converter(nodes);
        })
    }

    private extractCmpNodes(tree: m_taxonomy.Tree, parentId: string | undefined, left: boolean, limitedLevels, l: number): CmpTaxonomyNode[] {
        const thisId = String(tree.id);
        const thisNode: CmpTaxonomyNode = {
            originalId: tree.id,
            id: thisId,
            parentId,
            isLeft: left,
            fixedOpen: parentId === undefined,
            selected: false,
            label: capitalize(tree.label),
            labelId: tree.label.toLowerCase(),
            originalLabel: tree.label,
            value: tree.values.p__spend__sum,
            valueTitle: toCurrencyWithP(tree.values.p__spend__sum, this.profileStore.currencyFormat, this.profileStore.currencySymbol, tree.values.p__spend__sum / 1000000),
        }
        if (limitedLevels && l > 2) {
            return [thisNode];
        }
        const childNodes = tree.children.flatMap(c => this.extractCmpNodes(c, thisId, left, limitedLevels, l + 1))
        return [thisNode, ...childNodes];
    }

    get categoryTotalCompareData(): CategoryOverlapData {
        return hardcoded_dpw.COMBINED_L1_OVERLAPS;
    }

    get categoryTotalCompareBreakdown() {
        const d = this.categoryTotalCompareData;
        // if(!d) return undefined;
        return buildCmpData(d, this.profileStore.currencySymbol);
    }

    get currentCompareCategory(): string | undefined {
        if (this.selectedMasterLevel && this.selectedCompareLevel) {
            const selectedMasterL1 = this.selectedMasterLevel[0];
            const selectedCmpL1 = this.selectedCompareLevel[0];
            if (selectedMasterL1 === selectedCmpL1) {
                return selectedMasterL1;
            }
        }
        return undefined;
    }

    get categoryCompareData(): CategoryOverlapData | undefined {
        if (this.currentCompareCategory) {
            if (hardcoded_dpw.L1_CATEGORY_OVERLAPS[this.currentCompareCategory]) {
                return hardcoded_dpw.L1_CATEGORY_OVERLAPS[this.currentCompareCategory];
            } else {
                console.warn('Cannot show compare category data for category', this.currentCompareCategory)
            }
        }
        return hardcoded_dpw.COMBINED_L1_OVERLAPS;
    }

    get categoryCompareDataBreakdown(): { masterData: CmpBreakdownData, cmpData: CmpBreakdownData } | undefined {
        const d = this.categoryCompareData;
        if (!d) return undefined;
        return buildCmpData(d, this.profileStore.currencySymbol);
    }

    _hack_supplier_data = new Map<string, CommonSupplierData[]>();

    get commonMasterSupplierData() {
        if (!this.selectedMasterLevel) return undefined;
        if (hardcoded_dpw.DO_SCRAMBLE) {
            const _hackKey = 'MASTER_' + this.selectedMasterLevel[0];
            let _scrambled = this._hack_supplier_data.get(_hackKey)
            if (!_scrambled) {
                _scrambled = hardcoded_dpw._hackscramble(hardcoded_dpw.COMMON_SUPPLIER_TABLE_DATA)
                this._hack_supplier_data.set(_hackKey, _scrambled);
            }
            return _scrambled;
        }
        return hardcoded_dpw.COMMON_SUPPLIER_TABLE_DATA;
    }

    get commonCmpSupplierData() {
        if (!this.selectedCompareLevel) return undefined;
        if (hardcoded_dpw.DO_SCRAMBLE) {
            const _hackKey = 'COMPARE_' + this.selectedCompareLevel[0];
            let _scrambled = this._hack_supplier_data.get(_hackKey)
            if (!_scrambled) {
                _scrambled = hardcoded_dpw._hackscramble(hardcoded_dpw.COMMON_SUPPLIER_TABLE_DATA)
                this._hack_supplier_data.set(_hackKey, _scrambled);
            }
            return _scrambled;
        }
        return hardcoded_dpw.COMMON_SUPPLIER_TABLE_DATA;
    }

    leftSelectionIndex?: number;
    rightSelectionIndex?: number;
    hasCreatedLink = false;
    private cmpPointData?: Tree[];
    private cmpConnectionData?: DataConn[];

    get leftSelection(): TreeDataType | undefined {
        if (this.leftSelectionIndex === undefined || !this.cmpPointData) return undefined;
        return this.cmpPointData[this.leftSelectionIndex].data;
    }

    get rightSelection(): TreeDataType | undefined {
        if (this.rightSelectionIndex === undefined || !this.cmpPointData) return undefined;
        return this.cmpPointData[this.rightSelectionIndex].data;
    }

    get connectionData(): UpdateData | undefined {
        if (!this.cmpPointData || !this.cmpConnectionData) {
            return undefined;
        }
        return {points: this.cmpPointData, connections: this.cmpConnectionData};
    }

    get canConnect() {
        return this.leftSelectionIndex !== undefined && this.rightSelectionIndex !== undefined;
    }

    get isConnected() {
        if (this.hasCreatedLink) {
            return true;
        }
        return Boolean(this.selectedCreatedConnection)
    }

    get selectedConnection() {
        if (!this.leftSelection || !this.rightSelection || !this.cmpConnectionData) {
            return undefined;
        }
        const cI = hasConnection(this.leftSelection, this.rightSelection, this.cmpConnectionData)
        if (cI === -1) {
            return undefined;
        }
        return this.cmpConnectionData[cI]
    }

    get selectedCreatedConnection() {
        const connection = this.selectedConnection
        if (!connection) return undefined;
        if (connection.created || connection.initial) {
            return connection;
        }
        return undefined
    }

    get selectedOpportunityId() {
        const c = this.selectedConnection;
        if (!c) {
            return undefined
        }
        return 'synergy-category-' + c.leftId + '-' + c.rightId;
    }

    // Think about how to design the domain model for this store.
    onClickData(clickedNode: Tree) {
        if (!this.cmpPointData || !this.cmpConnectionData) {
            console.error('Clicked non existing data')
            return;
        }
        const clickedData = clickedNode.data;

        const oldLeftId = this.leftSelection?.id;
        const oldLeftIndex = this.leftSelectionIndex;
        const oldRightId = this.rightSelection?.id;
        const oldRightIndex = this.rightSelectionIndex;

        // On the next click, unselect the other side
        const unselectOpposite = this.hasCreatedLink;
        this.hasCreatedLink = false;

        let newLeftId = oldLeftId;
        let newLeftIndex = oldLeftIndex;
        let newRightId = oldRightId;
        let newRightIndex = oldRightIndex;
        if (clickedData.isLeft) {
            if (oldLeftId === clickedData.id) {
                // Unselected left
                newLeftId = undefined;
                newLeftIndex = undefined;
                clickedData.selected = false;
            } else {
                // Selected left
                newLeftId = clickedData.id;
                newLeftIndex = this.cmpPointData.findIndex(d => d.data.id === newLeftId && d.data.isLeft)
                clickedData.selected = true;
            }
            if (unselectOpposite) {
                newRightId = undefined;
                newRightIndex = undefined;
            }
        } else {
            if (oldRightId === clickedData.id) {
                // Unselected right
                newRightId = undefined;
                newRightIndex = undefined;
                clickedData.selected = false;
            } else {
                // Selected right
                newRightId = clickedData.id;
                newRightIndex = this.cmpPointData.findIndex(d => d.data.id === newRightId && !d.data.isLeft)
                clickedData.selected = true;
            }
            if (unselectOpposite) {
                newLeftId = undefined;
                newLeftIndex = undefined;
            }
        }
        // console.log('Clicked', oldLeftId, newLeftId, ' --- ', oldRightId, newRightId);
        this.leftSelectionIndex = newLeftIndex;
        this.rightSelectionIndex = newRightIndex;

        if (oldLeftIndex !== newLeftIndex) {
            if (oldLeftIndex !== undefined) {
                this.cmpPointData[oldLeftIndex].data.selected = false;
            }
            if (newLeftIndex !== undefined) {
                this.cmpPointData[newLeftIndex].data.selected = true;
            }
        }
        if (oldRightIndex !== newRightIndex) {
            if (oldRightIndex !== undefined)
                this.cmpPointData[oldRightIndex].data.selected = false;
            if (newRightIndex !== undefined)
                this.cmpPointData[newRightIndex].data.selected = true;
        }
        this.cmpPointData = [...this.cmpPointData]

        if (oldLeftId !== undefined && oldRightId !== undefined) {
            const prevConnI = this.cmpConnectionData.findIndex(d => d.leftId === oldLeftId && d.rightId === oldRightId)
            if (prevConnI === -1) {
                // console.log('Old connection: cannot remove from view', oldLeftId, oldRightId)
            } else {
                const prevConn = this.cmpConnectionData[prevConnI]
                if (prevConn.created || prevConn.initial) {
                    // Keep it, but unselect it only
                    // console.log('Old connection: Keep it', prevConnI);
                    const c = {...prevConn}
                    c.selected = false;
                    this.cmpConnectionData.splice(prevConnI, 1, c)
                } else {
                    // Remove it
                    // console.log('Old connection: Remove it', prevConnI);
                    this.cmpConnectionData.splice(prevConnI, 1)
                }
            }
        }
        if (newLeftId !== undefined && newRightId !== undefined) {
            const newConnI = this.cmpConnectionData.findIndex(d => d.leftId === newLeftId && d.rightId === newRightId)
            // console.log('New connection index', newConnI);
            if (newConnI === -1) {
                this.cmpConnectionData.push({
                    leftId: newLeftId,
                    rightId: newRightId,
                    initial: false,
                    created: false,
                    selected: true,
                })
            } else {
                const newConn = this.cmpConnectionData[newConnI];
                const c = {...newConn}
                c.selected = true;
                this.cmpConnectionData.splice(newConnI, 1, c)
            }
        }
    }

    onClickCreateConnection() {
        if (!this.cmpConnectionData) return;
        if (!this.leftSelection || !this.rightSelection) {
            return;
        }

        const cI = hasConnection(this.leftSelection, this.rightSelection, this.cmpConnectionData);
        console.assert(cI !== -1)

        // Select a link
        const link = this.cmpConnectionData[cI]

        const createLink = !(link.created || link.initial);
        link.created = createLink;
        link.initial = false;
        this.hasCreatedLink = createLink;
        this.cmpConnectionData = [...this.cmpConnectionData]
    }
}

function buildCmpData(d: CategoryOverlapData, currencySymbol: string) {
    const mP = d.category_n_overlap / d.master_n_categories;
    const masterData: CmpBreakdownData = {
        top: {
            total: d.master_n_categories,
            value: d.category_n_overlap,
            valueTitle: Math.round(mP * 100) + '%',
            valueTitleHover: '',
        },
        bottom: {
            total: d.master_spend,
            value: d.master_spend_overlap,
            valueTitle: Math.round(d.master_spend_overlap / d.master_spend * 100) + '%',
            valueTitleHover: toCurrency(currencySymbol, d.master_spend_overlap),
        }
    }
    const cP = d.category_n_overlap / d.compare_n_categories;
    const cmpData: CmpBreakdownData = {
        top: {
            total: d.compare_n_categories,
            value: d.category_n_overlap,
            valueTitle: Math.round(cP * 100) + '%',
            valueTitleHover: '',

        },
        bottom: {
            total: d.compare_spend,
            value: d.compare_spend_overlap,
            valueTitle: Math.round(d.compare_spend_overlap / d.compare_spend * 100) + '%',
            valueTitleHover: toCurrency(currencySymbol, d.compare_spend_overlap),
        }
    }
    return {masterData, cmpData};
}

type D = { id: string, selected: boolean, isLeft: boolean };

// function updateArray<T extends D>(element: T, onArray: { data: T }[]) {
//     const newEl = {...element};
//     const index = onArray.findIndex(d => newEl.id === d.data.id && newEl.isLeft === d.data.isLeft);
//     onArray[index].data = newEl;
//     return newEl;
// }

function hasConnection<T extends D>(d1: T, d2: T, dataArray: DataConn[]) {
    if (d1.isLeft === d2.isLeft) throw Error();
    const [l, r] = d1.isLeft ? [d1, d2] : [d2, d1];
    return dataArray.findIndex(c => c.leftId === l.id && c.rightId === r.id);
}

function nodeToCategories(i: number | undefined, treeData: Tree[] | undefined) {
    if (i === undefined || !treeData) return undefined;
    const selectedNode = treeData[i];
    const path = selectedNode.ancestors().reverse().filter((_, i) => i > 0)
    return path.map(n => n.data.label);
}
