import './BusinessCaseGeneratorModals.scss'
import React from "react";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDropDown, Check, Email, Link, Public} from "@mui/icons-material";
import {blue, deepOrange, deepPurple, green} from "@mui/material/colors";

type Props = {
    isOpen: boolean
    onClose: () => void
}
export const ShareModal: React.FC<Props> = ({isOpen, onClose}) => {
    const [isLinkCopied, setIsLinkCopied] = React.useState(false);
    const [isLinkCopying, setIsLinkCopying] = React.useState(false);
    const _onClose = () => {
        onClose()
        setTimeout(() => {
            setIsLinkCopied(false)
        }, 300)
    }
    return <Dialog
        open={isOpen}
        onClose={_onClose}
        className="share-modal"
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>
            Share business care report
        </DialogTitle>
        <DialogContent>

            <TextField
                autoFocus
                // required
                margin="dense"
                id="email"
                name="email"
                label="Invite People"
                type="email"
                fullWidth
                variant="outlined"
                placeholder="Enter email address"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Email/>
                        </InputAdornment>
                    ),
                }}
            />

            <DialogContentText className="small-header">
                <Typography variant="subtitle2">People with access</Typography>
            </DialogContentText>

            <List
                // dense
                sx={{width: '100%', bgcolor: 'background.paper'}}>
                <ListItem
                    secondaryAction={
                        <Typography>
                            Owner
                        </Typography>
                        // <Checkbox
                        //     edge="end"
                        //     onChange={handleToggle(value)}
                        //     checked={checked.indexOf(value) !== -1}
                        //     inputProps={{ 'aria-labelledby': labelId }}
                        // />
                    }
                    disablePadding
                >
                    {/*<ListItemButton>*/}
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: deepPurple[500]}}>RR</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Rasa Raoufi (You)"
                        secondary="rasa@mithra-ai.com"
                    />
                    {/*</ListItemButton>*/}
                </ListItem>
                <ListItem
                    secondaryAction={
                        <Button endIcon={<ArrowDropDown/>}>
                            Editor
                        </Button>
                    }
                    disablePadding
                >
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: green[500]}}>AA</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Angelo Andrea Isola"
                        secondary="angelo@mithra-ai.com"
                    />
                </ListItem>
                <ListItem
                    secondaryAction={
                        <Button endIcon={<ArrowDropDown/>}>
                            Viewer
                        </Button>
                    }
                    disablePadding
                >
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: deepOrange[500]}}>DB</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Dennis van den Brand"
                        secondary="dennis@mithra-ai.com"
                    />
                </ListItem>
            </List>

            <DialogContentText className="small-header">
                <Typography variant="subtitle2">General access</Typography>
            </DialogContentText>

            <List
                // dense
                sx={{width: '100%', bgcolor: 'background.paper'}}>
                <ListItem
                    secondaryAction={
                        <Button endIcon={<ArrowDropDown/>}>
                            Viewer
                        </Button>
                    }
                    disablePadding
                >
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: blue[500]}}>
                            <Public/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Anyone with the link"
                        secondary="Anyone who has the link can see the report"
                    />
                </ListItem>
            </List>

        </DialogContent>
        <DialogActions style={{padding: '0 1.5em 1.5em'}}>
            <Button
                className="link-button"
                onClick={() => {
                    navigator.clipboard.writeText(String(window.location.href))
                    setIsLinkCopying(true)
                    setTimeout(() => {
                        setIsLinkCopying(false)
                        setIsLinkCopied(true)
                    }, 1000)
                }}
                variant="outlined"
                startIcon={isLinkCopying ? <CircularProgress size={12}/> : isLinkCopied ? <Check/> : <Link/>}
                style={{marginRight: 'auto'}}>
                {isLinkCopied ? 'Link copied' : 'Copy link'}
            </Button>
            <Button onClick={_onClose} variant="text" style={{marginRight: '2em'}}>Cancel</Button>
            <Button onClick={_onClose} variant="contained">Done</Button>
        </DialogActions>
    </Dialog>;
}