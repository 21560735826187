import {catchError, concat, forkJoin, from, mergeMap, NEVER, of, Subscription, takeUntil, tap, timer} from "rxjs";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";

const SENDING_APPROVAL_TIMEOUT = 8500;

/**
 *
 */
export class CategorizationReviewPageApprovalController {
    isSendForApprovalDialogOpen = false;
    isSendForApprovalError: boolean | string = false;
    isBusySendForApproval = false;

    /**
     * Show that the send for approval request is precessing in the background
     */
    isSendForApprovalWaiting = false;

    approvalNotes = ''

    private approvalSubscription?: Subscription;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private approvalStore: ApprovalStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    sendForApproval(callback?: () => void) {
        const bagId = this.categorizationReviewPageController.bagId;
        if (!bagId) return
        this.isBusySendForApproval = true;
        this.isSendForApprovalError = '';

        const approvalNotes = this.approvalNotes;

        const t0 = performance.now();

        const call = this.api.createCategorizationApprovalRequestForAll(approvalNotes, bagId);
        // // For testing:
        // const throwError = () => {throw new Error('Test error')};
        // const call = timer(100).pipe(tap(throwError));
        // const call = timer(7000).pipe(tap(throwError));
        // const call = timer(100);
        // const call = timer(7000);

        const createApproval$ = from(call).pipe(
            mergeMap(() =>
                forkJoin([
                    // TODO re-trigger download of results
                    // from(this.api.getMTaxonomy(taxonomyId)).pipe(
                    //     tap(r => runInAction(() => {
                    //         this.notification_type = 'success';
                    //         this._setTaxonomy(r.data);
                    //         this.isSendForApprovalDialogOpen = false;
                    //     }))
                    // ),
                    from(this.approvalStore.fetchAll())
                ])
            ),
            tap(() => {
                this.onApprovalSuccess()
                if (callback) callback();
            }),
            catchError(err => {
                this.onApprovalError(err)

                // Stop the timer
                return of(null);
            }),
        );

        this.approvalSubscription = concat(
            timer(SENDING_APPROVAL_TIMEOUT).pipe(tap(() => {
                this.sendApprovalDialogToBackground();
            })),
            NEVER,
        ).pipe(
            takeUntil(createApproval$),
        ).subscribe({
            complete: () => {
                const t1 = performance.now();
                console.log(`Call to sendForApproval took ${t1 - t0} milliseconds.`);
                this.approvalStore.statusCount.request();
            },
        })
    }

    setSendForApprovalDialog(open: boolean) {
        this.isSendForApprovalDialogOpen = open;
        this.isSendForApprovalError = false;
    }

    setApprovalNotes(s: string) {
        this.approvalNotes = s;
    }

    onApprovalSuccess() {
        this.isSendForApprovalWaiting = false;
        this.isBusySendForApproval = false;
        this.isSendForApprovalDialogOpen = false;
        this.isSendForApprovalError = false;
        this.approvalNotes = ''; // If successfully applied removed empty the notes field
    }

    onApprovalError(err: any) {
        this.isSendForApprovalWaiting = false;
        this.isBusySendForApproval = false;
        this.isSendForApprovalDialogOpen = true;
        const bagError: string[] | undefined = err.response?.data['databag'];
        if (bagError && bagError.includes('no_parts_in_scope')) {
            this.isSendForApprovalError = 'No parts have been reviewed ...'
        } else {
            this.isSendForApprovalError = true;
        }
    }

    sendApprovalDialogToBackground() {
        this.isSendForApprovalWaiting = true;
        this.isSendForApprovalDialogOpen = false;
    }
}
