import {Droppable} from "react-beautiful-dnd";
import {TaxonomyMappingTableItem} from "./TaxonomyMappingTableItem";
import {MappingTableItemType} from "../../data-management/data-mapping/MappingTableTypes";
import React from "react";

type Props = {
    items: MappingTableItemType[]
    droppableId: string
    key: string
    disabled: boolean
    is_required: boolean
}

export const TaxonomyMappingMithraColumn: React.FC<Props> = ({items, droppableId, disabled, is_required}) => {

    return (
        <>
            <Droppable droppableId={droppableId} isDropDisabled={disabled}>
                {(provided) => (
                    <div ref={provided.innerRef} className={disabled ? "mithra-column disabled" : "mithra-column"}>
                        {items.map((item, index) => (
                            <TaxonomyMappingTableItem name={item.name} example={item.example} index={index} id={item.id}
                                                      key={item.id}
                                                      ai_result={item.ai_result}
                                                      parentList={droppableId}
                                                      column_letter={item.column_letter}></TaxonomyMappingTableItem>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </>
    );
}
