import React from "react";
import {Grid, Typography} from "@mui/material";
import {ContractFieldSpec} from "../data/ContractFields";
import {ContractDataDisplay} from "../classes";
import {FlexibleLists} from "../components/FlexibleLists";

type Props = {
    spec: ContractFieldSpec
    values: ContractDataDisplay[]
}
export const ContractViewFieldValue: React.FC<Props> = ({spec, values}) => {
    return <Grid item className="block" style={{marginTop: '1.5em', marginBottom: '1em'}}>
        <Typography variant="caption" component="h6">
            {spec.title}
        </Typography>
        {values.length === 1 && <Typography variant="body1" component="p">{String(values[0])}</Typography>}
        {values.length !== 1 && <FlexibleLists v={values}/>}
    </Grid>
};


