import {makeAutoObservable} from "mobx";
import {environment} from "../../../env";
import ProfileStore from "../../../stores/ProfileStore";
import AuthStore from "../../../stores/AuthStore";
import {BusinessCaseGeneratorPageController, Page} from "./BusinessCaseGeneratorPageController";

export class BusinessCaseGeneratorPageStateController {

    step = 0;
    page: Page = environment.isTestReviewPage ? 'review-opportunities' : 'review-opportunities';

    constructor(
        private businessCaseGeneratorPageController: BusinessCaseGeneratorPageController,
        private profileStore: ProfileStore,
        private auth: AuthStore,
    ) {
        makeAutoObservable(this)
    }

    reset() {
        this.step = 0;
        this.page = 'review-opportunities';
    }

    setPage(page: Page) {
        switch (page) {
            case "review-opportunities":
                this.step = 0;
                break;
            case "risk-assessment":
                this.step = 1;
                break;
            case "success-factors":
                this.step = 2;
                break;
        }
        this.page = page;
    }

    get previousLabel(): string | null {
        switch (this.page) {
            case "review-opportunities":
            case "risk-assessment":
            case "success-factors":
                return 'Back'
            default:
                return null
        }
    }

    onClickPrevious() {
        switch (this.page) {
            case 'risk-assessment':
                this.businessCaseGeneratorPageController.navigateToPage('review-opportunities')
                return;
            case 'success-factors':
                this.businessCaseGeneratorPageController.navigateToPage('risk-assessment')
                return;
        }
    }

    get nextLabel(): string | null {
        switch (this.page) {
            case "review-opportunities":
            case "risk-assessment":
                return 'Next'
            case "success-factors":
                return 'Generate Report'
            default:
                return null;
        }
    }

    onClickNext() {
        switch (this.page) {
            case 'review-opportunities':
                this.businessCaseGeneratorPageController.navigateToPage('risk-assessment')
                return;
            case 'risk-assessment':
                this.businessCaseGeneratorPageController.navigateToPage('success-factors')
                return
        }
    }

    get steps(): string[] | undefined {
        return [
            'review-opportunities',
            'risk-assessment',
            'success-factors'
        ]
    }
}