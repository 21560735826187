import ProfileStore from "../../../stores/ProfileStore";
import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export function getContractInsightsColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-dropdown', txt: '', align: 'center'},
        {cls: 'col-filename', txt: 'Filename', align: 'center', width: '20%'},
        {cls: 'col-type', txt: 'Type'},
        {cls: 'col-company', txt: 'Company (Legal name)'},
        {cls: 'col-supplier', txt: 'Supplier (Legal name)'},
        {cls: 'col-payment', txt: 'Payment terms'},
        {cls: 'col-effective-date', txt: 'Effective date'},
        {cls: 'col-expiration-date', txt: 'Expiration date'},
        {cls: 'col-evergreen', txt: 'Evergreen'},
        {cls: 'col-auto-renewal', txt: 'Auto Renewal'},
        {cls: 'col-incoterms', txt: 'Incoterms'},
        {cls: 'col-button', txt: '', align: 'center', width: '100px'}
    ];

    return arr.filter(c => c) as ColSpec[];
}
