import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const FullMainMenu: React.FC = () => {
    const {p} = useStores();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    const hasId2 = params['id2'] !== undefined;
    const homeLink = lookupHomePath(location);

    // const existsHome = homeLink !== null;
    const isHome = location.pathname === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, params) : routes.home,
            active: location.pathname === routes.home || location.pathname === routes.job_manage,
            className: '',
        },
        {
            label: 'Merge',
            link: generatePath(hasId1 ? routes.synergy_v2_dashboard : routes.synergy_v2, params),
            active: (
                location.pathname === routes.synergy_v2
                || location.pathname === routes.synergy_v2_data
                || location.pathname === routes.synergy_v2_dashboard
                || location.pathname === routes.synergy_v2_taxonomy_builder_id
                || location.pathname === routes.synergy_v2_taxonomy_viewer
                || location.pathname === routes.synergy_v2_taxonomy_viewer_id
                || location.pathname === routes.synergy_v2_review
                || location.pathname === routes.synergy_v2_review_result
                || location.pathname === routes.synergy_v2_opportunities
            ),
            className: '',
        },
        {
            label: 'Synergies',
            link: generatePath(
                hasId2 && hasId1
                    ? (
                        routes.job_synergy_v1_2
                    )
                    : hasId1
                        ? routes.job_synergy_v1_1
                        : routes.job_synergy_v1_0,
                params
            ),
            active: (
                location.pathname === routes.job_synergy_v1_0
                || location.pathname === routes.job_synergy_v1_1
                || location.pathname === routes.job_synergy_v1_2
                || location.pathname === routes.job_synergy_v1_dashboard
                || location.pathname === routes.job_synergy_v1_suppliers
                || location.pathname === routes.job_synergy_v1_categories
                || location.pathname === routes.job_synergy_v1_opportunities
            ),
            className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_opportunities, params) : routes.home,
            active: !isHome && location.pathname === routes.demo_opportunities,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('opportunities')),
            className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.job_report, params) : routes.home,
            active: !isHome && location.pathname === routes.job_report,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('report')),
            className: 'menu-item-last',
        },
    ]
    return <MenuItems className="full-main-menu" items={menu}/>
}
