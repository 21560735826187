import React from "react";
import {observer} from "mobx-react-lite";
import {Checkbox, FormControl, Grid, InputLabel, MenuItem, TextField, Typography} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {useStores} from "../../../../stores";
import Select from "@mui/material/Select";

const CAT_FILTER_MODAL_ID = 'categorization-filter-modal';
export const CAT_FILTER_MODAL_TITLE_ID = CAT_FILTER_MODAL_ID + '-title';

export const CategorizationFilterModal: React.FC = observer(() => {
    const {categorizationReviewStore} = useStores();
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;

    const fieldsEnabled = reviewPageFilterController.isAnyAdvancedFilterFieldEnabled;
    const byYearFilterField = reviewPageFilterController.byYearFilterField;
    return <EditorModalComponent
        titleId={CAT_FILTER_MODAL_TITLE_ID}
        className="categorization-review-model-component"
        title="Filter"
        saveLabel={fieldsEnabled ? 'Apply' : 'Ok'}
        canSave={true}
        // hideCancel
        onSave={() => {
            if (reviewPageFilterController.checkAdvancedFilterIsValid()) {
                reviewPageFilterController.applyAdvancedFilter()
                reviewPageFilterController.setAdvancedFilterDialogOpen(false)
            }
        }}
        cancelLabel="Disable"
        onCancel={() => {
            reviewPageFilterController.disableAdvancedFilter()
            reviewPageFilterController.setAdvancedFilterDialogOpen(false)
        }}
        // isSaving={}
        onClose={() => reviewPageFilterController.setAdvancedFilterDialogOpen(false)}
        // isError={}
    >
        {/*<Typography variant="body1"></Typography>*/}
        {/*Some text*/}
        <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
            <Grid item xs="auto" className="checkbox-width">
                <Checkbox
                    className="mb-4"
                    checked={reviewPageFilterController.scoreFilterFieldEnabled}
                    onChange={() => reviewPageFilterController.toggleScoreFilterFieldEnabled()}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    fullWidth
                    id={CAT_FILTER_MODAL_ID + '-score-field-min'}
                    label="Minimal score"
                    error={reviewPageFilterController.scoreLowFilterFieldError !== ''}
                    helperText={reviewPageFilterController.scoreLowFilterFieldError || ' '}
                    variant="outlined"
                    value={reviewPageFilterController.scoreLowFilterField}
                    onChange={e => reviewPageFilterController.setScoreLowFilterField(e.target.value)}
                    onClick={() => reviewPageFilterController.setScoreFilterFieldEnabled()}
                    // disabled={isBusySendForApproval}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    fullWidth
                    id={CAT_FILTER_MODAL_ID + '-score-field-max'}
                    label="Maximal score"
                    error={reviewPageFilterController.scoreHighFilterFieldError !== ''}
                    helperText={reviewPageFilterController.scoreHighFilterFieldError || ' '}
                    variant="outlined"
                    value={reviewPageFilterController.scoreHighFilterField}
                    onChange={e => reviewPageFilterController.setScoreHighFilterField(e.target.value)}
                    onClick={() => reviewPageFilterController.setScoreFilterFieldEnabled()}
                    // disabled={}
                />
            </Grid>
        </Grid>

        {!byYearFilterField.filterFieldEnabled &&
            <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
                <Grid item xs="auto" className="checkbox-width">
                    {/*<Checkbox*/}
                    {/*    className="mb-4"*/}
                    {/*    checked={byYearFilterField.fieldEnabled}*/}
                    {/*    onChange={() => byYearFilterField.toggleEnabled()}*/}
                    {/*/>*/}
                    Year:
                </Grid>
                <Grid item xs>

                    <FormControl
                        sx={{m: 1, minWidth: 80}}
                        variant="outlined"
                        className="by-year-select-field-wrapper"
                    >
                        <InputLabel id={CAT_FILTER_MODAL_ID + "-by-year-label"}>
                        </InputLabel>
                        <Select
                            labelId={CAT_FILTER_MODAL_ID + "-by-year-label"}
                            label=""
                            id={CAT_FILTER_MODAL_ID + "-by-year-menu"}
                            value={byYearFilterField.value || ''}
                            displayEmpty
                            autoWidth
                            onChange={e => byYearFilterField.setFieldValue(e.target.value)}
                        >
                            {byYearFilterField.options.map(({value, label}) =>
                                <MenuItem key={label} value={value}>
                                    <Typography variant="inherit">{label}</Typography>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>


                    {/*<TextField*/}
                    {/*    fullWidth*/}
                    {/*    id={CAT_FILTER_MODAL_ID + '-by-year-field'}*/}
                    {/*    label="By year"*/}
                    {/*    error={byYearFilterField.fieldError !== ''}*/}
                    {/*    helperText={byYearFilterField.fieldError || ' '}*/}
                    {/*    variant="outlined"*/}
                    {/*    value={byYearFilterField.value}*/}
                    {/*    onChange={e => byYearFilterField.setFieldValue(e.target.value)}*/}
                    {/*    onClick={e => byYearFilterField.setFieldEnabled()}*/}
                    {/*    // disabled={categorizationReviewStore.isBusySendForApproval}*/}
                    {/*/>*/}
                </Grid>
            </Grid>
        }

    </EditorModalComponent>
})
