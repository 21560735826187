import {ApprovalHierarchyNode, TaxonomyApprovalDelegate} from "../../../../stores/approval/TaxonomyApprovalDelegate";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {List, ListItem, ListItemText} from "@mui/material";
import {CategoryChip} from "../../../../components/category-chip/CategoryChip";

type Props = {
    node: ApprovalHierarchyNode
}
export const DeleteCategoryModal: React.FC<Props> = observer(({node}) => {
    const {approvalStore} = useStores();

    const onSave = () => {
        approvalStore.taxonomyApproval.onSaveDeleteNode(node);
    }

    const labels = TaxonomyApprovalDelegate.getLocation(node);

    return <EditorModalComponent
        titleId="update-category-modal-title"
        className="taxonomy-modal-component update-category-modal"
        title="Delete category"
        canSave={true}
        onSave={onSave}
        saveLabel="Delete"
        onClose={() => approvalStore.taxonomyApproval.closeDeleteModal()}
    >
        <section className="modal-introduction">
            Are you sure you want to delete this category?
            <List>
                <ListItem>
                    {/*<ListItemIcon>*/}
                    {/*    <ChevronRight/>*/}
                    {/*</ListItemIcon>*/}
                    <ListItemText
                        primary={<CategoryChip labels={labels}/>}
                        secondary={node.data.values.description || undefined}
                    />
                </ListItem>
            </List>
        </section>
    </EditorModalComponent>
})
