import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {makeAutoObservable} from "mobx";
import {
    Category,
    category_readable_str,
    CategoryLabel,
    Cluster,
    Company, ConversionRate,
    Currency,
    Division, KPIType,
    Lever,
    LeverGroup
} from "../ApiTypes";

export class CompanyConfigurationStore {
    readonly leverGroupRM = new AxoisRequestManager<void, LeverGroup[]>(
        () => from(this.api.listLeverGroups()));
    readonly leverRM = new AxoisRequestManager<void, Lever[]>(
        () => from(this.api.listLevers()));
    readonly categoryRM = new AxoisRequestManager<void, Category[]>(
        () => from(this.api.listCategories()));
    readonly companyRM = new AxoisRequestManager<void, Company[]>(
        () => from(this.api.listCompanies()));
    readonly currencyRM = new AxoisRequestManager<void, Currency[]>(
        () => from(this.api.listCurrencies()));
    readonly clusterRM = new AxoisRequestManager<void, Cluster[]>(
        () => from(this.api.listClusters()));
    readonly divisionRM = new AxoisRequestManager<void, Division[]>(
        () => from(this.api.listDivision()));
    readonly kpiTypesRM = new AxoisRequestManager<void, KPIType[]>(
        () => from(this.api.listKpiType()));
    readonly conversionRatesRM = new AxoisRequestManager<void, ConversionRate[]>(
        () => from(this.api.listConversionRates()));

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
    }

    loadInitialData() {
        if (!this.leverGroupRM.result && !this.leverGroupRM.busy)
            this.leverGroupRM.request();
        if (!this.leverRM.result && !this.leverRM.busy)
            this.leverRM.request();
        if (!this.categoryRM.result && !this.categoryRM.busy)
            this.categoryRM.request();
        if (!this.companyRM.result && !this.companyRM.busy)
            this.companyRM.request();
        if (!this.currencyRM.result && !this.currencyRM.busy)
            this.currencyRM.request();
        if (!this.clusterRM.result && !this.clusterRM.busy)
            this.clusterRM.request();
        if (!this.divisionRM.result && !this.divisionRM.busy)
            this.divisionRM.request();
        if (!this.kpiTypesRM.result && !this.kpiTypesRM.busy)
            this.kpiTypesRM.request();
        if (!this.conversionRatesRM.result && !this.conversionRatesRM.busy)
            this.conversionRatesRM.request();
    }

    getCurrencyCode(id: number, prepend = 'k ') {
        return prepend + (this.currencyRM.result?.find((currency) => currency.id === id)?.code ?? '');
    }

    getCurrencyId(code: string) {
        return this.currencyRM.result?.find((currency) => currency.code === code)?.id ?? 0;
    }

    getCompanyDefaultCurrency(id: number) {
        return this.companyRM.result?.find((company) => company.id === id)?.currency;
    }

    getCompanyTitle(id: number) {
        return this.companyRM.result?.find((company) => company.id === id)?.title ?? '';
    }

    getCompanyFiscalYearStartMonth(id: number) {
        return this.companyRM.result?.find((company) => company.id === id)?.fiscal_year_start_month ?? 8;
    }

    getCategoryL1(id: number) {
        return this.categoryRM.result?.find((category) => category.id === id)?.l1 ?? '';
    }

    getCategoryReadableStr(id: number) {
        return category_readable_str(this.categoryRM.result?.find((category) => category.id === id));
    }

    getLeverGroupTitle(lever_id: number) {
        return this.leverRM.result?.find((lever) => lever.id === lever_id)?.group.title ?? '';
    }

    getKpiType(id: number) {
        return this.kpiTypesRM.result?.find((kpiType) => kpiType.id === id)?.title ?? '';
    }

    get allCategories(): CategoryLabel[] | null {
        return this.categoryRM.result?.map(c => ({
            id: c.id,
            label: category_readable_str(c),
        })) ?? null;
    }

    convertToCurrency(value: number, sourceCurrencyId: number, targetCurrencyCode: string, precision = 4): number {
        if (this.getCurrencyCode(sourceCurrencyId, '') === targetCurrencyCode) return Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision);
        const cr = this.conversionRatesRM.result?.find((cr) =>
            (cr.source.id === sourceCurrencyId && cr.target.code.toUpperCase() === targetCurrencyCode) ||
            // Or look for reverse rate
            (cr.target.id === sourceCurrencyId && cr.source.code.toUpperCase() === targetCurrencyCode));
        if (!cr) return 0;
        if (cr.source.id === sourceCurrencyId) return Math.round(Math.pow(10, precision) * value * cr.rate) / Math.pow(10, precision);
        return Math.round(Math.pow(10, precision) * value / cr.rate) / Math.pow(10, precision);
    }

    convertFromCurrency(value: number, sourceCurrencyCode: string, targetCurrencyId: number, precision = 4): number {
        if (this.getCurrencyCode(targetCurrencyId, '') === sourceCurrencyCode) return Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision);
        const cr = this.conversionRatesRM.result?.find((cr) =>
            (cr.target.id === targetCurrencyId && cr.source.code.toUpperCase() === sourceCurrencyCode) ||
            // Or look for reverse rate
            (cr.source.id === targetCurrencyId && cr.target.code.toUpperCase() === sourceCurrencyCode));
        if (!cr) return 0;
        if (cr.target.id === targetCurrencyId) return Math.round(Math.pow(10, precision) * value * cr.rate) / Math.pow(10, precision);
        return Math.round(Math.pow(10, precision) * value / cr.rate) / Math.pow(10, precision);
    }
}