import * as d3 from "d3";
import {EnterElement, PartitionLayout, ScaleOrdinal} from "d3";
import {D3GSelection, D3S, D3SvgSelection} from "../../../../utils/global";
import {HierarchyNode} from "d3-hierarchy";
import {MAIN_COLOR} from "../../../../style/colors";
import {wrapText2} from "../../../../utils/d3-utils";
import {BuilderMode, IS_TE_DEVELOPMENT_MODE, TAXONOMY_LABEL_HEIGHT} from "../TaxonomyEditorOptions";
import {taxonomy_editor} from "../TaxonomyEditorTypes";
import {TaxonomyTreeEditorController} from "./TaxonomyTreeEditorController";
import {buildChevronPoints} from "./Chevron";
import TaxonomyEditorStore from "../../../../stores/TaxonomyEditorStore";
import {runInAction} from "mobx";
import {HistoryState} from "../../../../stores/TaxonomyManagerStore";

const MIN_RECT_HEIGHT = 2
const RECT_BOTTOM_PADDING = 2;

const TEXT_MARGIN = 10;

const SELECTION_CIRCLE_SIZE = TAXONOMY_LABEL_HEIGHT - 1
const SELECTION_CIRCLE_INNER_SIZE = SELECTION_CIRCLE_SIZE * .6
const SELECTION_MARGIN = 5;

const NAVIGATION_CHEVRON_WIDTH = 4
const NAVIGATION_CHEVRON_HEIGHT = NAVIGATION_CHEVRON_WIDTH * 2
const NAVIGATION_CHEVRON_POINTS_R = buildChevronPoints('r', NAVIGATION_CHEVRON_WIDTH, NAVIGATION_CHEVRON_HEIGHT)
const NAVIGATION_CHEVRON_POINTS_L = buildChevronPoints('l', NAVIGATION_CHEVRON_WIDTH, NAVIGATION_CHEVRON_HEIGHT)

type Node = taxonomy_editor.GraphNode;
type Selection = d3.Selection<SVGGElement, Node, SVGGElement, any>;
type EnterSelection = d3.Selection<EnterElement, Node, SVGGElement, any>;
type Transition = d3.Transition<SVGGElement, Node, SVGGElement, any>;

/**
 * For drawing and rendering the visualization only
 */
export class TaxonomyRenderer {
    private readonly bgRect: D3S<SVGRectElement, any>
    private readonly overlayRect: D3S<SVGRectElement, any>
    private readonly wrapper: D3S<HTMLDivElement>
    private readonly svg: D3SvgSelection
    private readonly drawRoot: D3GSelection<any>
    private readonly nodesRoot: D3GSelection<any>
    // private readonly levelLabelsRoot: D3GSelection<any>

    private readonly color: ScaleOrdinal<string, string>
    private readonly format: (n: number | undefined) => string

    private readonly graphWidth: number
    private graphHeight: number

    private readonly partitionLayout: PartitionLayout<taxonomy_editor.NodeData>;

    /**
     * TODO: How to properly deal with this root attribute?
     * What is the right architecture for this?
     * Should this be private?
     */
    public root: Node

    constructor(
        private controller: TaxonomyTreeEditorController,
        dataHierarchy: HierarchyNode<taxonomy_editor.NodeData>,
    ) {
        console.log('[TaxonomyRenderer] init')

        const defaultIsFullViewMode = TaxonomyEditorStore.isFullViewMode(this.options.defaultBuilderMode)
        if (defaultIsFullViewMode) {
            // TODO: Set correct setSvgViewPort
            throw new Error('Not implemented yet')
        }

        this.wrapper = d3.select(controller.wrapperRef)
            .classed('taxonomy-editor-wrapper', true)
        this.setWrapperClasses(this.options.defaultBuilderMode);

        this.svg = this.wrapper.append('svg') as any
        this.svg
            .classed('taxonomy-editor-viz', true)
            .classed('debug', Boolean(IS_TE_DEVELOPMENT_MODE))

        this.setSvgViewPort(this.options.width, this.options.fitViewModeHeight)

        this.graphWidth = this.options.width - this.options.margin.left - this.options.margin.right
        const height = this.options.fitViewModeHeight;
        this.graphHeight = height - this.options.margin.top - this.options.margin.bottom

        // background
        this.bgRect = this.svg.append('rect')
            .classed('bg', true)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.options.width)
            .attr('height', height)
            .on('click', () => {
                console.log('[TaxonomyRenderer] Background clicked')
                this.controller.backgroundClicked();
            })

        // Create a drawing root node
        // const xOffset = 0;
        const xOffset = this.graphWidth / 2 - (this.options.nodeWidth + this.options.nodeDistance) / 2;
        this.drawRoot = this.svg.append('g')
            .attr('transform', `translate(${this.options.margin.left + xOffset}, ${this.options.margin.top})`)

        this.nodesRoot = this.drawRoot.append('g')
            .classed('nodes-group', true)


        // // Option1: Use a color pallet
        // let pallet: (t: number) => string;
        // let colors: string[];
        //
        // // pallet = d3.interpolateRainbow
        // // // pallet = categoryColorGradient
        // // colors = d3.quantize(pallet, (this.taxonomyEditorStore.root?.children?.length || 0) + 1);
        //
        // pallet = d3.interpolateGreys
        // colors = d3.quantize(pallet, (this.taxonomyEditorStore.root?.children?.length || 0) + 3).reverse();
        // colors.splice(0, 1)
        // colors.splice(colors.length - 2)
        //
        // this.color = d3.scaleOrdinal(colors)

        // Option2: USe a single color
        this.color = d3.scaleOrdinal([MAIN_COLOR])

        this.format = d3.format(',d') as any

        this.partitionLayout = d3.partition<taxonomy_editor.NodeData>()
            .round(false)
            .padding(this.options.siblingPadding)
            .size([
                this.graphHeight,
                1, // Ignored
            ])

        // overlay
        this.overlayRect = this.svg.append('rect')
            .classed('overlay', true)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.options.width)
            .attr('height', height)

        this.root = this.applyLayout(dataHierarchy)

        // if (this.controller.options.defaultViewMove === 'full') {
        //     const nNodes = this.root.leaves().length;
        //     const newHeight = Math.ceil(nNodes * (TAXONOMY_LABEL_HEIGHT + 1) * 1.5);
        //     const svgHeight = this.setGraphHeight(newHeight);
        //     this.controller.setSvgSize([this.options.width, svgHeight]);
        // }

        this.drawView()

        if (IS_TE_DEVELOPMENT_MODE === 'edit-bug') {
            // Click a L1 and then try to go to edit mode

            const DEV_PATH = ['Waste management'];
            const DEV_NODE_ID = TaxonomyRenderer.getNodeByPath(this.root, DEV_PATH)?.data.id
            if (!DEV_NODE_ID) {
                console.log('[DEV CLICK] Node not found by path', DEV_PATH)
            } else {
                setTimeout(() => {
                    console.log('[DEV CLICK] Click on L1: ', DEV_NODE_ID)
                    // // eslint-disable-next-line eqeqeq
                    const clickTarget = this.nodeSelection.filter(d => d.data.id == DEV_NODE_ID);
                    clickTarget.select('g.bar-group').dispatch('click')

                    setTimeout(() => {
                        console.log('[DEV CLICK] Change EDIT mode')
                        const taxonomyEditorStore: TaxonomyEditorStore = (this.controller as any).taxonomyEditorStore;
                        taxonomyEditorStore.toggleEditMode();
                    }, 1000)
                }, 1000)
                // setTimeout(() => {
                //     console.log('[DEV CLICK] Change VIEW mode')
                //     const taxonomyEditorStore: TaxonomyEditorStore = (this.controller as any).taxonomyEditorStore;
                //     taxonomyEditorStore.toggleViewMode();
                //
                //     setTimeout(() => {
                //         console.log('[DEV CLICK] Click on', DEV_NODE_ID)
                //         // // eslint-disable-next-line eqeqeq
                //         const clickTarget = this.nodeSelection.filter(d => d.data.id == DEV_NODE_ID);
                //         clickTarget.select('g.bar-group').dispatch('click')
                //
                //         // setTimeout(() => {
                //         //     console.log('[DEV CLICK] Change EDIT mode')
                //         //     runInAction(() => {
                //         //         const taxonomyEditorStore: TaxonomyEditorStore = (this.controller as any).taxonomyEditorStore;
                //         //         taxonomyEditorStore.toggleEditMode();
                //         //     })
                //         // }, 1000)
                //     }, 1000)
                // }, 1000)
            }
        }
        if (IS_TE_DEVELOPMENT_MODE === 'click-edit-bug') {
            const DEV_PATH = ['Travel', 'Accommodation (hotel) and Meals', 'Travel'];
            const DEV_NODE_ID = TaxonomyRenderer.getNodeByPath(this.root, DEV_PATH)?.data.id
            if (!DEV_NODE_ID) {
                console.log('[DEV CLICK] Node not found by path', DEV_PATH)
            } else {
                console.log('[DEV CLICK] Scheduling click on', DEV_NODE_ID)
                setTimeout(() => {
                    console.log('[DEV CLICK] Click on', DEV_NODE_ID)
                    // // eslint-disable-next-line eqeqeq
                    const clickTarget = this.nodeSelection.filter(d => d.data.id == DEV_NODE_ID);
                    clickTarget.select('g.bar-group').dispatch('click')

                    setTimeout(() => {
                        console.log('[DEV CLICK] Change EDIT mode')
                        runInAction(() => {
                            const taxonomyEditorStore: TaxonomyEditorStore = (this.controller as any).taxonomyEditorStore;
                            taxonomyEditorStore.toggleEditMode();
                        })
                    }, 1000)
                }, 1000)
            }
        }
        if (IS_TE_DEVELOPMENT_MODE === 'click') {
            const DEV_PATH = ['Warehousing'];
            const DEV_NODE_ID = TaxonomyRenderer.getNodeByPath(this.root, DEV_PATH)?.data.id
            if (!DEV_NODE_ID) {
                console.log('[DEV CLICK] Node not found by path', DEV_PATH)
            } else {
                console.log('[DEV CLICK] Scheduling click on', DEV_NODE_ID)
                setTimeout(() => {
                    console.log('[DEV CLICK] Click on', DEV_NODE_ID)
                    // // eslint-disable-next-line eqeqeq
                    const clickTarget = this.nodeSelection.filter(d => d.data.id == DEV_NODE_ID);
                    clickTarget.select('g.bar-group').dispatch('click')

                    setTimeout(() => {
                        console.log('[DEV CLICK] Change VIEW mode')
                        runInAction(() => {
                            const taxonomyEditorStore: TaxonomyEditorStore = (this.controller as any).taxonomyEditorStore;
                            taxonomyEditorStore.toggleViewMode();
                        })
                    }, 1000)
                }, 1000)
            }
        }
    }

    get options() {
        return this.controller.options;
    }

    // private initRoot(dataHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
    //     this.applyLayout(dataHierarchy)
    //
    //     // if (this.controller.options.defaultViewMove === 'full') {
    //     //     const nNodes = this.root.leaves().length;
    //     //     const newHeight = Math.ceil(nNodes * (TAXONOMY_LABEL_HEIGHT + 1) * 1.5);
    //     //     const svgHeight = this.setGraphHeight(newHeight);
    //     //     this.controller.setSvgSize([this.options.width, svgHeight]);
    //     // }
    //
    //     this.drawView();
    //
    //     return this.root;
    // }

    get taxonomySize() {
        return this.root.height;
    }

    /**
     * Set the height of the graph
     * @param newGraphHeight
     * @returns The new requested height of the SVG element
     */
    public setGraphHeight(newGraphHeight: number): number {
        const newHeight = newGraphHeight + this.options.margin.top + this.options.margin.bottom;
        this.graphHeight = newGraphHeight
        this.bgRect.attr('height', newHeight)
        this.overlayRect.attr('height', newHeight)
        this.partitionLayout.size([
            this.graphHeight,
            1, // Ignored
        ])

        // SVG viewbox height
        return this.graphHeight + this.options.margin.top + this.options.margin.bottom;
    }

    /**
     * WARNING: This sets a new root node, so the selection is not aligned any more
     * @returns new focus
     */
    public drawNewHierarchy(
        dataHierarchy: HierarchyNode<taxonomy_editor.NodeData>,
        focusId: number | null,
    ): Node {
        // const prevFocus = this.controller.taxonomyEditorStore.focus;
        console.log('[TaxonomyRenderer] drawNewHierarchy', dataHierarchy.id, focusId)
        // Move all previously existing nodes to the new location, and draw the new nodes

        // Calculate the new (unfocused) locations
        this.root = this.applyLayout(dataHierarchy)

        // Update the selection
        // TODO: Is this still needed, or can mobx computes be updated another way?
        this.controller.refreshSelection();

        let newFocus: Node | undefined = this.root.find(node => node.data.id === focusId)
        if (focusId !== undefined) {
            if (!newFocus) {
                // The new focus does not exist anymore
                // TODO-PARKED: When undo/redo the page and the current focus does not exist we focus on the root
                //  Maybe we should just go up one level
                //  >>> 1h
                // Skip to just draw root
            } else {
                const factor = TaxonomyRenderer.estDurationFactor(undefined, newFocus)
                this.animateTarget(newFocus, factor)
                    .end()
                    .then(() => {
                        console.log('[TaxonomyRenderer] Animation ended')
                        this.drawView() //  TODO: drawView is called twice?
                    })
            }
        }

        this.drawSelection()

        if (!newFocus) {
            this.drawView()
        }
        return newFocus || this.root;
    }

    /**
     * Warning: This potentially invalidates the root-based references!
     */
    public resetLayout() {
        // re-apply layout
        this.root.each((n: any) => {
            delete n.x0
            delete n.x1
            delete n.y0
            delete n.y1
        })
        this.root = this.applyLayout(this.root)
    }

    private applyLayout(dataHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
        console.log('[TaxonomyRenderer] applyLayout')

        const node00 = dataHierarchy.children?.at(0)?.children?.at(0);
        console.log('[TaxonomyRenderer] applyLayout [0,0] =', node00?.data.id, node00?.data.label)

        // TODO: Is a root update even needed here? How to verify this?
        const root = this.partitionLayout(dataHierarchy);
        const taxonomySize = root.height;
        root.each(d => {
            // Position each node in the graph

            [d.x0, d.x1, d.y0, d.y1] = [d.y0, d.y1, d.x0, d.x1]  // Invert XY
            // Overwrite custom X positions
            d.x0 = (d.depth - 1) * (this.options.nodeWidth + this.options.nodeDistance)
            d.x1 = d.x0 + this.options.nodeWidth

            this.updateTargetData(d, taxonomySize, null)
        })
        return root
    }

    private updateTargetData(d: Node, taxonomySize: number, focus: Node | null) {
        const isFitViewMode = this.controller.isFitViewMode;
        const w = this.options.nodeDistance + this.options.nodeWidth
        // const t = {
        //     x0: d.x0,
        //     x1: d.x1,
        //     y0: d.y0,
        //     y1: d.y1,
        // }
        let target: taxonomy_editor.AnimationTarget;
        const targetFocus = focus ?? this.root;
        if (!targetFocus) {
            target = {
                x0: d.x0,
                x1: d.x1,
                y0: d.y0,
                y1: d.y1,
            }
        } else {
            if (isFitViewMode) {
                target = {
                    x0: d.x0 - targetFocus.x0 - w,
                    x1: d.x1 - targetFocus.x0 - w,
                    y0: (d.y0 - targetFocus.y0) / (targetFocus.y1 - targetFocus.y0) * this.graphHeight,
                    y1: (d.y1 - targetFocus.y0) / (targetFocus.y1 - targetFocus.y0) * this.graphHeight,
                }
            } else {
                target = {
                    x0: d.x0 - targetFocus.x0 - w,
                    x1: d.x1 - targetFocus.x0 - w,
                    y0: (d.y0 - this.root.y0) / (this.root.y1 - this.root.y0) * this.graphHeight,
                    y1: (d.y1 - this.root.y0) / (this.root.y1 - this.root.y0) * this.graphHeight,
                }
            }
        }

        const focusDepth = focus?.depth ?? null;

        const nodeId = d.data.id;
        // eslint-disable-next-line eqeqeq
        if (IS_TE_DEVELOPMENT_MODE && (nodeId == 13 || nodeId == 1)) {
            console.log(`[TaxonomyRenderer] updateTargetData node=${nodeId} [${d.data.label}]`, target);
        }

        d.data.target = target;
        d.data.viz.nodeHeight = TaxonomyRenderer.getNodeHeight(target)

        // OPT2
        d.data.viz.nodeOpacity = +this.nodeVisible(d)
        this.labelPosition(d, focusDepth)
        d.data.viz.chevronDirection = TaxonomyRenderer.getChevronDirection(d, taxonomySize, focusDepth)


        // if (IS_TE_DEVELOPMENT_MODE && (nodeId == 209 || nodeId == 1)) {
        //     console.log(`[TaxonomyRenderer] applyLayout ${nodeId}, `
        //         + ` v.nodeOpacity=${d.data.viz.nodeOpacity}`
        //         + ` v.labelOpacity=${d.data.viz.labelOpacity}`
        //         // + ` nodeHeight=${nodeHeight.toFixed(1)}`
        //         // + ` targetHeight=${targetHeight.toFixed(1)}`
        //     )
        // }
    }

    private get nodeSelection(): Selection {
        // Used to be: this.drawGroups
        return this.nodesRoot.selectAll<SVGGElement, Node>('g.node-group')
    }

    public drawView() {
        // TODO: Is this enough or do we also need to animate?

        console.time('[TaxonomyRenderer] drawView')
        console.log('[TaxonomyRenderer] drawView start', this.root?.data.id)
        let data: Node[] = this.root.descendants().filter(n => !n.data.removed)

        // // Optionally add level data
        // const levelData = [...Array(root.height)].map((value, index) => index)

        if (this.options.hideRoot) {
            // Hide the first node (as it's the root)
            data = data.filter(d => d.depth !== 0)
        }

        this.nodeSelection
            .data(data, d => d.data.id)
            .join(
                this.drawEnter.bind(this),
                elem => elem, // No update
                exit => {
                    console.log('[TaxonomyRenderer] drawView.exit() ', exit.size())
                    exit.remove();
                },
            )
            .call(this.drawJoin.bind(this))
        console.timeEnd('[TaxonomyRenderer] drawView')
    }

    private drawEnter(enter: EnterSelection): Selection {
        const isEditMode = this.controller.isEditMode
        console.log('[TaxonomyRenderer] drawEnter.enter() ', enter.size(), isEditMode)

        const nodeGroup = enter.append('g')
            .classed('node-group', true)
            .classed('is-navigate', d => this.controller.isNavigateable(d))
            .attr('opacity', d => d.data.viz.nodeOpacity)

        nodeGroup.append('title')

        const barGroup = nodeGroup.append('g')
            .classed('bar-group', true)
            .on('click', (_, n) => this.controller.onClickBar(n))

        barGroup.append('rect')
            .classed('bar', true)
            .attr('x', 0)

        const barNavCenter = barGroup.append('g')
            .classed('nav-center', true)
        barNavCenter
            .append('polyline')
            .classed('chevron', true)

        const labelWrapper = nodeGroup.append('g')
            .classed('label-wrapper', true)
            .on('click', (_, n) => this.controller.onClickLabel(n))
            .on('dblclick', (_, n) => this.controller.onDoubleClickLabel(n))

        labelWrapper.append('rect')
            .classed('label-wrapper-bg bg', true)
            .attr('x', -this.options.nodeDistance)
            .attr('width', this.options.nodeDistance)

        const labelGroup = labelWrapper.append('g')
            .classed('label-group', true)

        // text
        const textX = -TEXT_MARGIN - (isEditMode ? (SELECTION_CIRCLE_SIZE + 2 * SELECTION_MARGIN) : 0);
        let text = labelGroup.append('text')
            .classed('name', true)

            //If we add an 'fill-opacity' there it will hide all the labels in the lower levels and not update when it's time to show them
            //.attr('fill-opacity', d => d.data.viz.labelOpacity)

            .attr('pointer-events', 'none')
            .attr('dominant-baseline', 'middle')
            .style('text-anchor', 'end')
            .attr('dy', '.1em') // Fine-tuning to center the text better vertically
            .attr('opacity', d => d.data.viz.labelOpacity)
            .attr('x', textX)
        text.append('tspan')
            .attr('font-size', TAXONOMY_LABEL_HEIGHT + 'px')

        // Select button
        labelGroup.append('circle')
            .classed('select outer', true)
            .attr('r', SELECTION_CIRCLE_SIZE / 2)
            // TODO: Refactor and de-duplicate
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)

        labelGroup.append('circle')
            .classed('select inner', true)
            .attr('r', SELECTION_CIRCLE_INNER_SIZE / 2)
            // TODO: Refactor and de-duplicate
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)

        return nodeGroup
    }

    private debugTxt(d: Node) {
        return d.ancestors().map(d => d.data.label).reverse().join('/')
            + `\nid = ${d.data.id}`
            + `\nauthors = ${(d.data.values.authors || []).join(',')}`
            + `\nremoved = ${d.data.removed ? 'REMOVED' : ''}`
            // + `\nvalue = ${this.format(d.value)}`
            // + `\nchevron = ${'' + d.data.viz.chevronDirection}`
            + `\nlabelVP = ${'' + JSON.stringify(d.data.viz.labelPosition)}`
            // + `\nlabelOpacity = ${'' + d.data.viz.labelOpacity}`
            // + `\nnodeOpacity = ${'' + d.data.viz.nodeOpacity}`
            // + `\nnodeHeight = ${'' + d.data.viz.nodeHeight}`
            + `\noverlap = ${'' + d.data.viz.overlap}`
            // + `\ndepth+1 = ${'' + (d.depth + 1)}`
            + `\ny0 = ${'' + d.y0} [${d.data.target.y0}]`
            + `\nx0 = ${'' + d.x0} [${d.data.target.x0}]`
    }

    /**
     * This is called after enter and animate
     */
    private drawJoin(nodeSelection: Selection) {
        console.log('[TaxonomyRenderer] join', nodeSelection.size())

        // The drawGroup is located at the top left of the bar
        nodeSelection
            .attr('transform', d => this.getTransform(d))
            .classed('label-visible', d => d.data.viz.labelOpacity > 0)

        nodeSelection.select('rect.bar')
            .attr('width', d => d.x1 - d.x0)
            .attr('y', RECT_BOTTOM_PADDING)
            .attr('height', d => Math.max(MIN_RECT_HEIGHT, d.data.viz.nodeHeight - RECT_BOTTOM_PADDING))//adding bigger number here will increase the padding between the bars
            .attr('fill', d => {
                let _d: Node | null = d;
                if (_d.depth) {
                    while (_d && _d.depth > 1) {
                        _d = _d.parent;
                    }
                    if (_d)
                        return this.color(_d.data.label);
                }
                return "#ccc";
            })
        nodeSelection.select('rect.label-wrapper-bg')
            .attr('height', d => d.data.viz.nodeHeight)

        nodeSelection.select('g.label-group')
            .attr('transform', d => {
                const y = d.data.viz.labelPosition.top
                    ? d.data.viz.labelPosition.height2
                    : d.data.viz.nodeHeight - d.data.viz.labelPosition.height2;
                return `translate(0,${y})`;
            })
            .attr('opacity', d => {
                const n = d.data.target || d
                const bigEnough = Math.abs(n.y1 - n.y0) >= TAXONOMY_LABEL_HEIGHT;
                let result = n.x1 <= this.graphWidth && n.x0 >= 0 && bigEnough
                return result ? 1 : 0
            })

        const navCenter = nodeSelection.select('g.nav-center')
            .attr('transform', d => {
                const y = d.data.viz.labelPosition.top
                    ? d.data.viz.labelPosition.height2
                    : d.data.viz.nodeHeight - d.data.viz.labelPosition.height2;
                return `translate(${this.options.nodeWidth / 2},${y})`;
            })

        navCenter.select('.chevron')
            .attr('transform', d => {
                return `translate(${-NAVIGATION_CHEVRON_WIDTH / 2}, 0)rotate(0)`;
            })
            //.attr('fill-opacity', d => d.data.viz.labelOpacity)
            .attr('opacity', d => {
                //     let _d: Node | null = d;
                //     if (_d.children) {
                //         return d.data.viz.labelOpacity
                //     }
                //     return 0
                const viz = d.data.viz;
                if (viz.chevronDirection === null) return 0
                return viz.labelOpacity
            })
            .attr('points', d =>
                d.data.viz.chevronDirection === 'l' ? NAVIGATION_CHEVRON_POINTS_L : NAVIGATION_CHEVRON_POINTS_R)
            .style('fill', 'none')
            .style('stroke-width', 3)
            .style('stroke', '#CCC')

        const textSize = this.getTextSize();
        nodeSelection.select('tspan')
            .each(this.trimTextFn(textSize))

        if (IS_TE_DEVELOPMENT_MODE) {
            nodeSelection.select('title').text(d => this.debugTxt(d))
        } else {
            nodeSelection.select('title').text(d => `${d.data.values.description ?? 'No description provided'}`)
        }
    }

    public drawSelection() {
        console.log('[TaxonomyRenderer] drawSelection', this.nodeSelection.size())
        this.nodeSelection
            .classed('selected', d => this.controller.isSelected(d))
    }

    private getTransform(d: Node) {
        const target = d.data.target;
        // console.log('[TaxonomyRenderer] getTransform()', target)
        let x: number, y: number;
        if (target) {
            x = target.x0
            y = target.y0
        } else {
            x = d.x0
            y = d.y0
        }
        x += this.options.nodeDistance / 2
        return `translate(${x},${y})`;
    }

    private nodeVisible(node: Node) {
        const focusLevel = this.controller.focusLevel
        const dd = node.depth - focusLevel
        return dd <= this.options.showNumberLevels
    }

    private labelVisible(node: Node | taxonomy_editor.AnimationTarget): boolean {
        const bigEnough = Math.abs(node.y1 - node.y0) > TAXONOMY_LABEL_HEIGHT;
        // return node.x1 <= this.graphWidth && node.x0 >= 0 && bigEnough
        return bigEnough
    }

    private static getNodeHeight(node: Node | taxonomy_editor.AnimationTarget) {
        return Math.max(MIN_RECT_HEIGHT, node.y1 - node.y0);
        // return Math.max(MIN_RECT_HEIGHT, node.y1 - node.y0 - Math.min(0, (node.y1 - node.y0) / 2));
    }

    /**
     * Sets labelPosition, labelOpacity, overlap
     */
    private labelPosition(node: Node, focusDepth: number | null) {
        const n = node.data.target

        if (n.x0 === 0 && n.x1 === 0 && n.y0 === 0 && n.y1 === 0) {
            console.warn('[TaxonomyRenderer] suspicious labelPosition', node.data.id)
        }

        const start = n.y0
        const end = n.y1
        const labelVisible = this.labelVisible(node.data.target);
        const nodeId = node.data.id;
        if (IS_TE_DEVELOPMENT_MODE && (nodeId == 209 || nodeId == 1)) {
            console.log('[TaxonomyRenderer] labelPosition ' + node.data.id + ' labelVisible=' + labelVisible)
        }

        node.data.viz.labelOpacity = labelVisible ? 1 : 0;
        node.data.viz.labelPosition = {
            top: true,
            height2: (end - start) / 2,
        };
        node.data.viz.overlap = '';

        if (focusDepth === null) {
            // Do no adjust
            return;
        }

        const depth = node.depth
        if (depth > focusDepth) {
            // Do no adjust
            return;
        }
        // It's one of the parents (to the left)

        // Check if it falls in range of the focus
        const viewportStart = -this.options.margin.top
        const viewportEnd = this.graphHeight + this.options.margin.bottom

        // // Option1: Look at coordinates
        const outOfView = start >= viewportEnd || end <= viewportStart;
        // console.log('labelPosition', node.data.id, outOfView)
        if (outOfView) {
            node.data.viz.labelOpacity = 0
        } else {
            node.data.viz.labelOpacity = 1
            const startInView = start >= viewportStart;
            const endInView = end <= viewportEnd;
            // - totally view <=> startInView && endInView
            // - completely overlapping the view <=> !startInView && !endInView

            const apparentStart = Math.max(start, viewportStart)
            const apparentEnd = Math.min(end, viewportEnd)
            let overlap: taxonomy_editor.VisualData['overlap'],
                p: taxonomy_editor.VisualData['labelPosition'];
            const apparentHeight = (apparentEnd - apparentStart) / 2;
            if (!startInView) {
                // There are parts out of view on the top of it
                // const startOffset = apparentStart - start;

                if (!endInView) {
                    // complete overlap
                    const topPosition = (viewportStart - start);
                    p = {top: true, height2: topPosition + this.graphHeight / 2}
                    overlap = 'both'
                    // console.log('[TaxonomyRenderer] labelPosition overlap=' + overlap + ', h2=' + p.height2 + ' :', start, apparentStart, apparentHeight, topPosition)
                } else {
                    p = {top: false, height2: apparentHeight}
                    overlap = 'bottom'
                }
            } else {
                // Start is in the view
                p = {top: true, height2: apparentHeight}
                if (!endInView) {
                    overlap = 'top'
                } else {
                    // Completely in view
                    overlap = ''
                }
            }
            node.data.viz.overlap = overlap
            node.data.viz.labelPosition = p
        }
    }

    private getTextSize() {
        if (this.controller.isEditMode) {
            return this.options.nodeDistance - TEXT_MARGIN * 2 - SELECTION_MARGIN * 2 - SELECTION_CIRCLE_SIZE;
        } else {
            return this.options.nodeDistance - TEXT_MARGIN * 2;
        }
    }

    private trimTextFn(textSizePx: number) {
        return wrapText2<Node>(textSizePx, n => {
            let label = String(n.data.label)
            let postfix = ''
            // if (n.height > 0) {
            //     postfix = ` (${n.leaves().length})`
            // }
            return [label, postfix];
        }, ' ...')

        // return wrapText(textSize,
        //     // n => n.data.label,
        //     // n => n.id,
        //     n => `${n.value}: ${n.data.label}`,
        // )
    }

    onChangeEditMode(): Transition {
        const isEditMode = this.controller.isEditMode;
        const textSize = this.getTextSize();

        console.log('[TaxonomyRenderer] onChangeEditMode', {isEditMode, textSize})

        const onEnd = () => {
            console.log('[TaxonomyRenderer] setEditMode.onEnd', isEditMode)
            this.drawRoot.classed('edit-mode', isEditMode)
            this.drawRoot.classed('view-mode', !isEditMode)
        }

        const t: any = this.drawRoot
            .transition()
            .duration(this.options.transitionDuration)

        const labelGroups = this.nodeSelection
            .select('g.label-group');
        const text = labelGroups
            .select('text')

        const textX = -TEXT_MARGIN - (isEditMode ? (SELECTION_CIRCLE_SIZE + 2 * SELECTION_MARGIN) : 0);
        text.transition(t)
            .attr('opacity', d => d.data.viz.labelOpacity)
            .attr('x', textX)

        labelGroups.select('circle.outer')
            // .classed('has-target', d => Boolean(d.data.target))
            // .classed('has-labelOpacity', d => Boolean(d.data.target?.labelOpacity))
            // .classed('has-targetLabelOpacity', d => Boolean(d.labelOpacity))
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)
        labelGroups.select('circle.inner')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)

        if (isEditMode) {
            // Edit is turning on, so the values might shrink
            // Adjust text before animation
            text.select('tspan')
                .each(this.trimTextFn(textSize))
            t.on('end', () => {
                onEnd()
            })
        } else {
            // Adjust text after animation
            t.on('end', () => {
                onEnd()
                text.select('tspan')
                    .each(this.trimTextFn(textSize))
            })
        }

        return t;
    }

    setFocusClass(newFocus: Node) {
        this.nodeSelection.classed('is-focus', d => d.data.id === newFocus.data.id)
    }

    animateTarget(focus: Node, animationFactor: number) {
        const isFitViewMode = this.controller.isFitViewMode;
        console.log(`[TARGET] setTarget(focus=${focus.data.id}, isFitViewMode=${isFitViewMode})`)
        this.root.each(d => {
            this.updateTargetData(d, this.taxonomySize, focus);
        })
        return this.doAnimation(animationFactor)
    }

    public doAnimation(animationFactor: number): Transition {
        console.log('[TaxonomyRenderer] animateTarget')
        const isEditMode = this.controller.isEditMode;
        const nodeSelection = this.nodeSelection
        const duration = this.options.transitionDuration * animationFactor;
        const t: any = nodeSelection
            // TODO: Nodes which are not part of the current focus are not hidden
            //  We want to make buttons to navigate to siblings and hide the current nodes
            //  >>> OK? 2h
            .classed('is-overlap-top', d => d.data.viz.overlap === 'top')
            .classed('is-overlap-bottom', d => d.data.viz.overlap === 'bottom')
            .classed('is-overlap-both', d => d.data.viz.overlap === 'both')
            .classed('is-navigate', d => this.controller.isNavigateable(d))
            .transition()
            .duration(duration)
            .attr('transform', d => this.getTransform(d))
            .attr('opacity', d => d.data.viz.nodeOpacity)

        nodeSelection.selectAll<SVGRectElement, Node>('rect')
            .call(s =>
                    s.transition(t)
                        .attr('height', d => Math.max(MIN_RECT_HEIGHT, d.data.viz.nodeHeight - RECT_BOTTOM_PADDING))
                // .attr('height', d => {
                //     if (d.data.target) {
                //         return Math.max(MIN_RECT_HEIGHT, d.data.viz.nodeHeight - RECT_BOTTOM_PADDING * 2);
                //     }
                //     return 0
                // })
            )

        const labelGroups = nodeSelection
            .select('g.label-group');

        nodeSelection.select('rect.label-wrapper-bg')
            .transition(t)
            .attr('height', d => d.data.viz.nodeHeight)

        labelGroups
            .transition(t)
            .attr('transform', d => {
                const y = d.data.viz.labelPosition.top
                    ? d.data.viz.labelPosition.height2
                    : d.data.viz.nodeHeight - d.data.viz.labelPosition.height2;
                return `translate(0,${y})`;
            })
            .attr('opacity', d => d.data.viz.labelOpacity)

        const navCenter = nodeSelection.select('g.nav-center')
            .transition(t)
            .attr('transform', d => {
                const y = d.data.viz.labelPosition.top
                    ? d.data.viz.labelPosition.height2
                    : d.data.viz.nodeHeight - d.data.viz.labelPosition.height2;
                return `translate(${this.options.nodeWidth / 2},${y})`;
            })


        navCenter.select('.chevron')
            .attr('transform', d => {
                const v = d.data.viz;
                const r = v.overlap === 'top' ? '-90' : v.overlap === 'bottom' ? '90' : 0;
                return `rotate(${r})translate(${-NAVIGATION_CHEVRON_WIDTH / 2},0)`;
            })
            .attr('opacity', d => {
                const viz = d.data.viz;
                if (viz.chevronDirection === null) return 0
                return viz.labelOpacity
            })
            .attr('points', d => d.data.viz.chevronDirection === 'l' ? NAVIGATION_CHEVRON_POINTS_L : NAVIGATION_CHEVRON_POINTS_R)


        // TODO: Refactor and de-duplicate this
        const textX = -TEXT_MARGIN - (isEditMode ? (SELECTION_CIRCLE_SIZE + 2 * SELECTION_MARGIN) : 0);

        nodeSelection.select('text')
            .transition(t)
            .attr('opacity', d => d.data.viz.labelOpacity)
            .attr('x', textX)

        labelGroups.select('circle.outer')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)
        labelGroups.select('circle.inner')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : d.data.viz.labelOpacity)
            .attr('cx', textX / 2)

        if (IS_TE_DEVELOPMENT_MODE) {
            nodeSelection.select('title').text(d => this.debugTxt(d))
        }

        return t
    }

    public static estDurationFactor(prevFocus: Node | undefined, newFocus: Node) {
        const dLevel = Math.abs((prevFocus?.depth || 0) - (newFocus?.depth || 0))
        const animationFactor = Math.max(1, 1 + Math.pow(.75, dLevel))
        console.log('[TaxonomyRenderer] estDuration', newFocus.data.id, prevFocus?.data.id)
        return animationFactor;
    }

    /**
     * TODO: Check all the places where this is called. And test that the correct data is updated
     * TODO: Replace with controller.reDrawV2?
     * @param focus
     * @deprecated
     */
    reDrawV1(focus: Node | undefined) {
        // const onlyHorizontal = this.controller.taxonomyEditorStore.viewMode === 'full';
        console.log('[TaxonomyRenderer] reDraw', focus?.data.id)

        // re-apply layout
        this.root.each((n: any) => {
            delete n.x0
            delete n.x1
            delete n.y0
            delete n.y1
        })

        this.applyLayout(this.root)
        if (focus) {
            this.animateTarget(focus, 1)
        }
        this.drawView()
    }

    /**
     * Returns the direction of the chevron
     */
    private static getChevronDirection(node: Node, taxonomySize: number, focusDepth: number | null): 'r' | 'l' | null {
        let result: 'r' | 'l' | null;
        if (node.depth >= taxonomySize) {
            // Deepest level is not zoom-able
            result = null;
        } else {
            if (focusDepth === null) {
                if (node.depth > 1) {
                    result = 'r'
                } else {
                    // Selected level
                    result = 'r';
                }
            } else {
                if (node.depth < focusDepth + 1) {
                    result = 'l'
                } else if (node.depth > focusDepth + 1) {
                    result = 'r'
                } else {
                    // Selected level
                    result = 'r';
                }
            }
        }
        // console.log(`[TaxonomyRenderer] chevronDirection ${[taxonomySize, focusDepth, node.depth, result]}`)
        return result;
    }

    private static getNodeByPath(tree: Node, labels: string[]): Node | undefined {
        let node: Node | undefined = tree;
        for (let label of labels) {
            if (!node) {
                return undefined
            }
            node = node.children?.find(c => c.data.label === label)
        }
        return node;
    }

    public setSvgViewPort(svgWidth: number, svgHeight: number) {
        console.log('setSvgViewPort', svgHeight)
        this.svg.attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
    }

    public setWrapperClasses(mode: BuilderMode) {
        console.log('setWrapperClasses', mode)
        this.wrapper
            .classed('view-mode-full', mode === 'view-full')
            .classed('view-mode-fit', mode === 'view-fit')
            .classed('view-mode-edit', mode === 'edit')
    }

    public setHistoryClasses(historyState: HistoryState) {
        // TODO-DISCUSS: We can visually show then we are saving in the background, do we still want to add a button?
        //  >>> 1/2h DISCUSS
        this.svg
            .classed('loading', historyState !== 'ready')
            .classed('blocked', historyState === 'updating_history')
    }

    /**
     * Scroll inside the wrapper to the new focus
     * @private
     */
    public scrollWrapperToTop(t: Transition) {
        const wrapperNode = this.wrapper.node()
        if (!wrapperNode) throw new Error('Wrapper node not initialized');

        const wrapperViewStart = wrapperNode.scrollTop
        return this.wrapper
            .transition()
            .duration(t.duration())
            .tween('scroll', function () {
                const i = d3.interpolateNumber(wrapperViewStart, 0);
                return function (t) {
                    this.scrollTop = i(t);
                }
            })
    }

    /**
     * Scroll inside the wrapper to the new focus
     * @private
     */
    public scrollWrapperToFocus(duration: number, focus: Node | null, newHeight: number): Promise<void> {
        const wrapperNode = this.wrapper.node()
        if (!wrapperNode) throw new Error('Wrapper node not initialized');

        if (focus?.depth === 0) {
            console.log('[SCROLL] scrollWrapperToFocus() Root is selected, do not scroll')
            // The root is selected, scroll to the start

            // this.wrapperRef.scrollTo({
            //     top: 0,
            //     behavior: 'auto',
            // })
            return Promise.resolve();
        }
        if (!focus) {
            console.warn('Cannot scroll to focus', focus)
            return Promise.resolve();
        }

        console.log(`[SCROLL] scrollWrapperToFocus(${newHeight}) focus=${focus.data.id} (${focus.data.label})`, focus.data.target.y0, focus.data.target.y1)

        // const wrapperHeight = this.wrapperRef.scrollHeight;
        // const wrapperHeight = this.wrapper.property('scrollHeight') as number;
        const wrapperHeight = wrapperNode.scrollHeight;

        const startY = (this.options.margin.top + focus.data.target.y0) / newHeight * wrapperHeight;
        const endY = (this.options.margin.top + focus.data.target.y1) / newHeight * wrapperHeight;

        const wrapperRect = wrapperNode.getBoundingClientRect();
        const wrapperViewStart = wrapperNode.scrollTop
        const wrapperViewEnd = wrapperViewStart + wrapperRect.height;

        console.log(`[SCROLL] scrollWrapperToFocus() wrapper=`, wrapperNode.scrollTop, wrapperHeight, wrapperRect.height)
        // Check if it's already completely in view
        if (startY < wrapperViewEnd && endY > wrapperViewStart) {
            console.log('[SCROLL] scrollWrapperToFocus() Already in view, no need to scroll')
            return Promise.resolve();
        } else {
            // Scroll the center of the element into view
            const avgY = (startY + endY) / 2
            const halfWindow = wrapperRect.height / 2
            const targetScrollTop = avgY - halfWindow;
            console.log('[SCROLL] scrollWrapperToFocus() Start Scrolling from to: ', wrapperViewStart, targetScrollTop)

            return this.wrapper
                .transition()
                .duration(duration)
                .tween('scroll', function () {
                    const i = d3.interpolateNumber(wrapperViewStart, targetScrollTop);
                    return function (t) {
                        this.scrollTop = i(t);
                    }
                })
                .end()
        }
    }
}
