import React from "react";
import {observer} from "mobx-react-lite";
import {
    FormControl,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {useSitStores} from "../../../../stores";
import {Edit, Delete, Check} from "@mui/icons-material";

export const InitiativeSkuForm: React.FC = observer(() => {
    const {initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        {initiativeInstanceStore.sku_c.length === 0 && <>
            <Grid item xs={12} textAlign="center">
                <ReportProblemIcon sx={{fontSize: 128, color: 'lightGray'}}/>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Typography variant="subtitle2" component="p">
                    It's all empty here...
                </Typography>
                <Typography variant="body2" component="p">
                    Start by adding some SKUs manually or upload the excel file
                </Typography>
            </Grid>
        </>}

        {initiativeInstanceStore.sku_c.length > 0 && <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableCell>SKU Number</TableCell>
                    <TableCell/>
                    <TableCell/>
                </TableHead>
                <TableBody>
                    {initiativeInstanceStore.sku_c.map((sku_c, index) => <TableRow key={index}>
                        <TableCell>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={!sku_c.editing || initiativeInstanceStore.disabled}
                                    variant="standard"
                                    placeholder="Enter SKU number"
                                    value={sku_c.p_id}
                                    onKeyDown={(ev) => {
                                        if (ev.key === 'Enter')
                                            if (sku_c.p_id === '')
                                                initiativeInstanceStore.removeSku(sku_c.index);
                                            else
                                                sku_c.toggleEdit();
                                    }}
                                    onChange={(event) =>
                                        sku_c.p_id = event.target.value
                                    }/>
                            </FormControl>
                        </TableCell>
                        <TableCell width="10">
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => sku_c.toggleEdit()}>
                                {!sku_c.editing ? <Edit/> : <Check/>}
                            </IconButton>
                        </TableCell>
                        <TableCell width="10">
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => initiativeInstanceStore.removeSku(index)}>
                                <Delete/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </Grid>}
    </Grid>
});