import './PartReCategorizationComponent.scss';
import React from "react";
import {ExpandMore} from "@mui/icons-material";
import {ButtonBase, Chip} from "@mui/material";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";

type Props = {
    cats: string[] | false
    handleOpenSearch: (event: React.MouseEvent<HTMLButtonElement>) => void
    selectedL1Category: string | undefined
}
export const PartReCategorizationComponent: React.FC<Props> = ({cats, handleOpenSearch, selectedL1Category}) => {
    const catsIsUncategorized = cats === false || cats.every(c => c === UNCATEGORIZED_VALUE); // | cats.length === 0
    const isNoCategory = cats !== false && cats.length === 0;
    return <div className="part-recat-component">
        <ButtonBase onClick={handleOpenSearch}>
            {isNoCategory
                ? <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    deleteIcon={<ExpandMore/>}
                    onDelete={handleOpenSearch}
                    label="Change all categories for supplier"
                />
                : catsIsUncategorized
                    ? <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        deleteIcon={<ExpandMore/>}
                        onDelete={handleOpenSearch}
                        label={UNCATEGORIZED_LABEL}
                    />
                    : cats.map((c, i) => {
                        let categoryLabel = c === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : c;
                        return <React.Fragment key={i}>
                            <Chip
                                variant="outlined"
                                color={selectedL1Category === c ? "primary" : "default"}
                                size="small"
                                deleteIcon={i === cats.length - 1 ? <ExpandMore/> : undefined}
                                onDelete={i === cats.length - 1 ? handleOpenSearch : undefined}
                                label={categoryLabel}
                            />
                            {(i < cats.length - 1) ? ' > ' : ''}
                        </React.Fragment>
                    })
            }
        </ButtonBase>
    </div>;
}
