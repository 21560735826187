import {ParetoOverviewVisualization} from "../../jobs/koi/spend-concentration/viz/ParetoOverviewVisualization";
import React from "react";
import {BarDataPoint} from "../visualization/BarChart";
import {Options} from "../visualization/bar-chart/BarChart2";

type Props = {
    data: BarDataPoint[] | undefined,
}

const options: Partial<Options> = {
    vertical: false,
    width: 800,
    height: 'auto',
    labelMargin: 100, // Of the total width
    valueLabelPxH: 34,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    alwaysShowValues: true,
}
export const TotalReviewedAiResult: React.FC<Props> = ({data}) => {

    return (
        <div className="custom-pareto-overview">
            {data && <ParetoOverviewVisualization
                data={data}
                options={{
                    ...options,
                }}
            />}
        </div>
    );
}
