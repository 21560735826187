import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {IconButton, TableRow} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {GoogleLink} from "../../../components/_tiny/GoogleLink";
import moment from "moment";
import {GoldenRecordPartState} from "../classes/GoldenRecordPartState";
import {GOLDEN_RECORD_SUB_TABLE_COLUMNS} from "../classes/GoldenRecordTableColumns";
import {RemoveCircle} from "@mui/icons-material";

type Props = {
    pState: GoldenRecordPartState
};
export const GoldenRecordTableSubRow: React.FC<Props> = observer(({pState}) => {
    const {goldenRecordStore} = useStores();
    const COLUMNS = GOLDEN_RECORD_SUB_TABLE_COLUMNS;
    let i = 0;
    return <TableRow className="sub-row">
        <MithraTableCell c={COLUMNS[i++]}>
            {pState.data.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <GoogleLink type="part" objs={[pState.data]}>
                {pState.data.p_description}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {pState.data.s_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {pState.data.s_city}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {pState.data.bu_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {pState.data.bu_city}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={pState.data.active_l}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={pState.data.p_spend} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {moment(pState.data.p_date).format('L')}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <IconButton color="error" onClick={() => goldenRecordStore.setRemoveModalOpen(true, pState)}>
                <RemoveCircle/>
            </IconButton>
        </MithraTableCell>
    </TableRow>
})
