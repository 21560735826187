import {IObservableArray, makeAutoObservable} from "mobx";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {taxonomy_editor} from "../components/visualization/taxonomy-editor/TaxonomyEditorTypes";
import {
    TaxonomyTreeEditorController
} from "../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyTreeEditorController";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {DEFAULT_TAXONOMY_EDITOR_OPTIONS} from "../components/visualization/taxonomy-editor/TaxonomyEditorOptions";
import ProfileStore from "./ProfileStore";
import {TaxonomyRenderer} from "../components/visualization/taxonomy-editor/taxonomy-tree/TaxonomyRenderer";
import {TaxonomyApprovalPreviewDelegate} from "./TaxonomyApprovalPreviewDelegate";
import MithraMaterializedApi from "../services/MithraMaterializedApi";

const ALL_TAXONOMY_VALUE_MODES = [
    {
        key: 'n_categories' as const,
        calc: 'leaf=1' as const,
        initCalcFromLeaf: true as const,
        label: 'Number of categories',
    },
    {
        key: 'p__spend__sum' as const,
        calc: 'avg_dist' as const,
        initCalcFromLeaf: true as const,
        label: 'Total spend',
    },
    {
        key: 'equal' as const,
        calc: 'equal' as const,
        initCalcFromLeaf: false as const,
        label: 'Equal',
    },
]
/**
 * Value mode in which all edit functionalities are supported
 */
const EDIT_VALUE_MODE = 'n_categories' as const;

export type Category = {
    node_id: number
    label: string
    values: string[]
    level: number
}
export type TaxonomyNodeValueMode = typeof ALL_TAXONOMY_VALUE_MODES[number]

type Node = taxonomy_editor.GraphNode;
type OLDNODE = any;

/**
 * For showing and interacting with the taxonomy controller
 */
export default class TaxonomyEditorStore {
    public readonly approval = new TaxonomyApprovalPreviewDelegate(this, this.taxonomyManagerStore, this.api);

    page: 'approval' | 'editor' | 'approval-feedback' = 'editor';

    allTaxonomyValueModes: TaxonomyNodeValueMode[];
    valueMode: TaxonomyNodeValueMode;

    /**
     * The focus element or undefined when nothing is focussed
     */
    focus: Node | undefined

    readonly selectionIds: IObservableArray<number> = [] as any;

    /**
     * If the taxonomy is full sized, or is fitted to the height of the screen
     * Only affects the height of the taxonomy
     * (Ensure there is one state such that the controller can knowingly switch between states)
     */
    builderMode = DEFAULT_TAXONOMY_EDITOR_OPTIONS.defaultBuilderMode;

    isUpdateCategoryMode = false;
    updateCategoryName = '';
    updateCategoryNameError = '';
    updateCategoryDescription = '';
    updateCategoryDescriptionError = '';

    isCreateCategoryMode = false;
    newCategoryName = '';
    newCategoryNameError = '';
    newCategoryDescription = '';
    newCategoryDescriptionError = '';

    isMergeCategoryMode = false;
    mergeCategoryName = '';
    mergeCategoryNameError = '';
    mergeCategoryDescription = '';
    mergeCategoryDescriptionError = '';

    isMoveCategoryMode = false;
    moveCategoryDestination?: Category;

    private controller?: TaxonomyTreeEditorController;

    healthButtonText = "Health"
    disableHealth = false

    setHealthButtonText(healthButtonText: string) {
        this.healthButtonText = healthButtonText
    }

    setDisableHealth(disableHealth: boolean) {
        this.disableHealth = disableHealth
    }

    constructor(
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
        private api: MithraMaterializedApi,
    ) {
        if (this.profileStore.p.taxonomy_builder_only_n_cats) {
            this.allTaxonomyValueModes = ALL_TAXONOMY_VALUE_MODES.slice(0, 1);
        } else {
            this.allTaxonomyValueModes = ALL_TAXONOMY_VALUE_MODES
        }
        this.valueMode = this.allTaxonomyValueModes[0];
        this.taxonomyManagerStore.taxonomyEditorStore = this;
        makeAutoObservable(this)
    }

    get selection(): Node[] {
        return this.controller?.findNodes(this.selectionIds) ?? [];
    }

    get selectionSiblings() {
        if (this.selection.length === 1) {
            const selected = this.selection[0];
            const siblings = selected.parent?.children;
            if (siblings) {
                return siblings.filter(c => c !== selected)
            }
        }
        return []
    }

    getFocusParent(): taxonomy_editor.GraphNode | undefined {
        return this.focus?.parent || undefined
    }

    setSelected(node: Node, selected: boolean): boolean {
        const selectedI = this.selectionIds.findIndex(id => id === node.data.id)
        const wasSelected = selectedI !== -1
        if (selected && !wasSelected) {
            this.selectionIds.push(node.data.id)
            return true;
        } else if (!selected && wasSelected) {
            this.selectionIds.splice(selectedI, 1)
            return true;
        }
        return false;
    }

    toggleSelection(node: Node) {
        const selectedI = this.selectionIds.findIndex(id => id === node.data.id)
        if (selectedI === -1) {
            this.selectionIds.push(node.data.id)
            return true
        } else {
            this.selectionIds.splice(selectedI, 1)
            return false
        }
    }

    get focusLevel(): number {
        return (this.focus?.depth || 0) + 1
    }

    get goBackButtonTxt(): string {
        // if (!this.focus || this.focus.depth <= 1) {
        //     return 'Go back to All'
        // }
        const backLevel = this.focus?.depth || 1;
        return `Go back to L${backLevel}`
    }

    get lastSelected() {
        if (this.selection.length === 0) {
            return undefined
        }
        return this.selection[this.selection.length - 1];
    }

    get sameLevelSelection(): OLDNODE[] {
        const depthLevel = Math.min(...this.selection.map(n => n.depth))
        return this.selection.filter(n => n.depth === depthLevel)
    }

    setCreateCategoryMode(b: boolean) {
        this.isCreateCategoryMode = b
        this.setNewCategoryName(this.newCategoryName)
    }

    setNewCategoryName(name: string) {
        this.newCategoryName = name

        const i = this.newNameExists;
        if (i >= 0) {
            this.newCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.newCategoryNameError = ''
        }
    }

    setNewCategoryDescription(name: string) {
        this.newCategoryDescription = name
        this.newCategoryDescriptionError = ''
    }

    setMergeCategoryName(name: string) {
        this.mergeCategoryName = name

        const i = this.mergeNameExists;
        if (i >= 0) {
            this.mergeCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.mergeCategoryNameError = ''
        }
    }

    setMergeCategoryDescription(description: string) {
        this.mergeCategoryDescription = description
        this.mergeCategoryDescriptionError = ''
    }

    setMoveCategoryDestination(category: Category) {
        this.moveCategoryDestination = category
    }

    get canSaveNewCategory() {
        return Boolean(this.newCategoryName)
            && !Boolean(this.newCategoryNameError)
            && !Boolean(this.newCategoryDescriptionError)
    }

    get canMergeCategories() {
        return Boolean(this.mergeCategoryName && !this.mergeCategoryNameError)
    }

    checkCanMergeCategories(): false | Node[] {
        // Executed when a merge attempt is started

        let selection = this.sameLevelSelection;
        if (selection.length !== this.selection.length) {
            // Normalize selections over different levels
            this.setSelection(selection)
            this.mergeCategoryNameError = 'Multiple levels selected'
            return false
        }
        if (selection.length <= 1) {
            console.log(`Not enough nodes are selected, selection=${selection.length}`)
            this.mergeCategoryNameError = `Select at least two categories`
            return false
        }

        // Determine the parent
        const parents = selection.map(n => n.parent)
        const uniqueParents = new Set(parents.map(n => n?.id))
        if (uniqueParents.size !== 1) {
            console.warn('Not allowed to merge nodes that do not have a common parent',
                'selection=' + JSON.stringify(this.selection.map(n => n.data.id))
            )

            // Change the selection to the last selected category
            const targetParentId = selection[selection.length - 1].parent?.id
            const newSelection = selection.filter(n => n.parent?.id === targetParentId);
            this.setSelection(newSelection)
            this.mergeCategoryNameError = 'Can only merge if the are in the same parent category'
            return false
        }
        const selectionParent = selection[0].parent;
        const parentChildren = selectionParent?.children;
        if (!selectionParent || !parentChildren) {
            console.warn('Merging of roots is not allowed',
                'selection=' + JSON.stringify(this.selection.map(n => n.data.id))
            )
            return false
        }
        return selection
    }

    get canMoveCategories() {
        return Boolean(this.moveCategoryDestination)
    }

    get taxonomyParentOptions(): Category[] {
        // return this.root.leaves().map(n => ({
        //     id: `${n.id}`,
        //     values: n.ancestors().reverse().map(a => a.data.label)
        // }))
        const descendants = this.controller?.getDescendants() || [];
        return descendants.map((n, i) => {
            if (i === 0) {
                return ({
                    node_id: n.data.id,
                    label: 'Taxonomy root',
                    values: ['Taxonomy root'],
                    level: 0,
                })
            }
            const labels = TaxonomyTreeEditorController.getLabels(n).slice(1);
            return ({
                node_id: n.data.id,
                label: labels.join(' > '),
                values: labels,
                level: labels.length,
            });
        })
    }

    get newNameExists(): number {
        let pool: Node[] | undefined;
        if (!this.focus) {
            return -1
        }
        pool = this.focus.children;
        return TaxonomyEditorStore.nameExists(pool, this.newCategoryName)
    }

    get mergeNameExists(): number {
        let pool: OLDNODE[] | undefined;

        const selection = this.sameLevelSelection;
        if (selection.length > 0) {
            const parent = selection[0].parent
            pool = parent?.children
            if (!pool) {
                console.warn('Expected parent to have children', parent)
                return -3
            }
            pool = pool.filter(n => !selection.some(s => s.id === n.id))
        }

        return TaxonomyEditorStore.nameExists(pool, this.mergeCategoryName)
    }

    private static nameExists(pool: Node[] | undefined, name: string) {
        if (!pool) return -2
        name = name.toLowerCase();
        return pool.findIndex(n =>
            n.data.label.toLowerCase() === name
        )
    }

    saveNewCategory() {
        if (!this.controller || !this.taxonomyManagerStore.taxonomy) return;
        if (!this.canSaveNewCategory) {
            console.warn('Cannot save category',
                this.newCategoryNameError,
                this.newCategoryDescriptionError,
            )
            return;
        }
        this.isCreateCategoryMode = false

        this.controller.addNode({
            newId: this.taxonomyManagerStore.taxonomy.next_node_id,
            label: this.newCategoryName.trim(),
            description: this.newCategoryDescription.trim(),
            value: 1,
        })
        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Add,
            this.controller.exportTree(),
        )

        this.newCategoryName = ''
        this.newCategoryNameError = ''
        this.newCategoryDescription = ''
        this.newCategoryDescriptionError = ''
    }

    setMergeCategoryMode(b: boolean) {
        this.isMergeCategoryMode = b
        // // Optionally: Prefill the name with the first element from the selection
        // if (b) {
        //     if (this.selection.length > 0) {
        //         this.setMergeCategoryName(this.selection[0].data.label)
        //     }
        // }
    }

    saveMergeCategory() {
        if (!this.controller || !this.taxonomyManagerStore.taxonomy) return;

        // Check if we can merge
        const source = this.checkCanMergeCategories()
        if (!source) {
            return;
        }
        if (this.mergeCategoryNameError) {
            console.warn('Cannot merge category', this.mergeCategoryNameError)
            return;
        }

        /////////////////////////
        // Do the actual merge //
        /////////////////////////
        this.isMergeCategoryMode = false
        this.controller.mergeNodes({
            sources: source,
            destination: {
                newId: this.taxonomyManagerStore.taxonomy.next_node_id,
                label: this.mergeCategoryName.trim(),
                description: this.mergeCategoryDescription.trim(),
                value: 1, // TODO
            }
        })
        this.setSelection([])

        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Merge,
            this.controller.exportTree(),
        )

        this.mergeCategoryName = ''
        this.mergeCategoryNameError = ''
        this.mergeCategoryDescription = ''
        this.mergeCategoryDescriptionError = ''
    }

    get isEditMode() {
        return this.builderMode === 'edit';
    }

    get isFullViewMode() {
        return TaxonomyEditorStore.isFullViewMode(this.builderMode);
    }

    get isFitMode() {
        return this.builderMode === 'view-fit';
    }

    public static isFullViewMode(builderMode: string) {
        return builderMode === 'edit' || builderMode === 'view-full';
    }

    setViewMove() {
        this.builderMode = 'view-fit';
    }

    toggleViewMode() {
        switch (this.builderMode) {
            case 'view-full':
                this.builderMode = 'view-fit';
                return;
            case 'view-fit':
                this.builderMode = 'view-full';
                return;
        }
    }

    setEditMode(editMode: boolean) {
        if (editMode) {
            this.setValueMode(EDIT_VALUE_MODE);
            this.builderMode = 'edit';
        } else {
            this.builderMode = 'view-fit';
        }
    }

    toggleEditMode() {
        switch (this.builderMode) {
            case 'view-full':
            case 'view-fit':
                this.setEditMode(true);
                return;
            case 'edit':
                this.setEditMode(false);
                return;
        }
    }

    setMoveCategoryMode(b: boolean) {
        this.isMoveCategoryMode = b
    }

    saveMoveCategory() {
        if (!this.controller) return;
        if (!this.canMoveCategories) {
            return
        }
        this.isMoveCategoryMode = false
        if (this.moveCategoryDestination) {
            const newParent = this.controller.moveNodes(this.selection, this.moveCategoryDestination)
            if (newParent) {
                this.setSelection([newParent])

                this.taxonomyManagerStore.onChangeTaxonomy(
                    m_taxonomy.Operation.Move,
                    this.controller.exportTree(),
                )
            }
        }
    }

    deleteSelection() {
        if (!this.controller) return;
        this.controller.deleteSelection()

        this.setSelection([])
        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Delete,
            this.controller.exportTree(),
        )
    }

    goUpLevel() {
        this.controller?.moveFocusUp()
    }

    get canGoUp() {
        const focus = this.focus;
        return focus && focus.depth > 0
    }

    clearError() {
        this.newCategoryNameError = ''
        this.mergeCategoryNameError = ''
    }

    setFocusAndClearSelection(node: Node) {
        this.focus = node
        this.selectionIds.clear()
    }

    setSelection(selection: taxonomy_editor.GraphNode[]) {
        this.selectionIds.replace(selection.map(n => n.data.id))
    }

    registerController(controller: TaxonomyTreeEditorController) {
        this.controller = controller
    }

    setFocus(focus: Node) {
        this.focus = focus
    }

    overwriteFocus(focus: Node | undefined) {
        this.focus = focus
    }

    setUpdateCategoryName(name: string) {
        this.updateCategoryName = name

        const i = TaxonomyEditorStore.nameExists(this.selectionSiblings, name);
        if (i >= 0) {
            this.updateCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.updateCategoryNameError = ''
        }
    }

    setUpdateCategoryDescription(description: string) {
        this.updateCategoryDescription = description
        this.updateCategoryDescriptionError = '' // Not used
    }


    setUpdateCategoryMode(b: boolean) {
        this.loadNodeDataToEdit()
        this.isUpdateCategoryMode = b
    }

    get canUpdateCategory() {
        return this.selection.length === 1
            && !Boolean(this.updateCategoryNameError)
            && !Boolean(this.updateCategoryDescriptionError)
    }

    saveUpdateCategory() {
        if (!this.controller) return;
        this.updateCategoryName = (this.updateCategoryName ?? '').trim()
        this.updateCategoryDescription = (this.updateCategoryDescription ?? '').trim()
        if (!this.canUpdateCategory) {
            return
        }
        this.isUpdateCategoryMode = false
        this.controller.updateNodeFields(this.selection[0], this.updateCategoryName, this.updateCategoryDescription)

        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Update,
            this.controller.exportTree(),
        )

        this.updateCategoryName = ''
        this.updateCategoryNameError = ''
        this.updateCategoryDescription = ''
        this.updateCategoryDescriptionError = ''
    }

    setValueMode(valueModeKey: TaxonomyNodeValueMode['key']) {
        if (!this.controller) return;
        const newValueMode = this.allTaxonomyValueModes.find(m => m.key === valueModeKey)
        if (!newValueMode) return
        if (this.valueMode === newValueMode) return
        this.valueMode = newValueMode
        this.controller.updateValueMode()
    }

    getFocusedAncestors(inFocus?: Node) {
        let f: string[] = []
        if (inFocus === undefined) return
        if (!inFocus.parent) return
        do {
            f.push(inFocus.data.label)
            inFocus = inFocus?.parent
        } while (inFocus && inFocus.parent) //inFocus?.parent !== null || inFocus?.parent !== undefined
        return f.reverse()
    }

    loadNodeDataToEdit() {
        this.updateCategoryNameError = ''
        this.updateCategoryDescriptionError = ''
        if (this.selection[0]) {
            this.updateCategoryName = this.selection[0].data.label || ''
            this.updateCategoryDescription = this.selection[0].data.values.description || ''
        }
    }

    get debugSelectionVals() {
        return this.selection.map(n => {
            const renderer = (this.controller as any).renderer as TaxonomyRenderer;
            const matches = renderer.root.descendants().filter(d => d.data.id === n.data.id)
            return {
                n: matches.length,
                incl: matches.includes(n),
            }
        })
    }

    setPage(page: typeof this.page) {
        this.page = page;
    }

    public navigateToFocus(nodeId: number) {
        // TODO: BUGS EXISTS HERE
        const controller = this.controller;
        if (!controller) return;

        this.setPage('editor');
        this.setEditMode(true);

        setTimeout(() => {
            controller.changeFocusAndSetSelection(nodeId)
        }, 100)
    }

    setApprovalFeedbackViewOpen() {
        this.setPage('approval-feedback')
    }
}
