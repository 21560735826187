import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import React from "react";
import {TextMessage} from "../ChatTypes";

type Props = {
    msg: TextMessage
}
export const TextChatMessage: React.FC<Props> = ({msg}) =>
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.message}</ReactMarkdown>
