import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {SynergyCommonSupplierReviewMainRow} from "./SynergyCommonSupplierReviewMainRow";
import {SYN_COMMON_PARENT_SUPPLIER_COLUMNS} from "./columns";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {findAbbreviation} from "../../../components/currency-component/CurrencyClasses";

export const SynergyCommonSupplierReviewMainTable: React.FC = observer(() => {
    const {synergyStore} = useStores();
    const tableClass = 'mithra-table x-design synergy-common-supplier-table' + (synergyStore.commonParentSupplierRM.busy ? ' loading' : '');

    useEffect(() => {
        if (!synergyStore.commonParentSupplierRM.result && !synergyStore.commonParentSupplierRM.busy)
            synergyStore.commonParentSupplierRM.request({filters: synergyStore.commonParentSupplierFilters.filters});
    }, [synergyStore.commonParentSupplierRM, synergyStore.commonParentSupplierFilters])

    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={SYN_COMMON_PARENT_SUPPLIER_COLUMNS} enableLoading
                                    isLoading={synergyStore.commonParentSupplierRM.busy}/>
            <TableBody>
                {synergyStore.commonParentSupplierRM.result ? <>
                        {synergyStore.commonParentSupplierRM.result.results.map(row =>
                            <React.Fragment key={row.sp_id}>
                                <CurrencyAbbreviationContext.Provider value={findAbbreviation(row.sp_total_spend)}>
                                    <SynergyCommonSupplierReviewMainRow row={row}/>
                                </CurrencyAbbreviationContext.Provider>
                            </React.Fragment>)}
                        {synergyStore.commonParentSupplierRM.result.count === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={SYN_COMMON_PARENT_SUPPLIER_COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        colSpan={SYN_COMMON_PARENT_SUPPLIER_COLUMNS.length}
                        count={synergyStore.commonParentSupplierRM.result?.count ?? 0}
                        rowsPerPage={parseInt(synergyStore.commonParentSupplierFilters.filters.find(i => i[0] === 'page_size')?.at(1) ?? '25')}
                        page={parseInt(synergyStore.commonParentSupplierFilters.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                        onPageChange={(event, page: number) => {
                            synergyStore.commonParentSupplierFilters.setFilters([['page', (page + 1).toString()]]);
                        }}
                        onRowsPerPageChange={(event) => {
                            synergyStore.commonParentSupplierFilters.setFilters([['page_size', (event.target.value).toString()]], false);
                            synergyStore.commonParentSupplierFilters.setFilters([['page', '1']]);
                        }}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
