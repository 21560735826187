import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export const TARGET_COLUMNS: Array<ColSpec> = [
    {cls: '', txt: 'Title', minWidth: 240},
    {cls: '', txt: 'Owner'},
    {cls: '', txt: 'L1 Category'},
    // {cls: '', txt: 'Lever Group'},
    {cls: '', txt: 'Company'},
    {cls: '', txt: 'Start date'},
    {cls: '', txt: 'Target year'},
    {cls: '', txt: 'Cost saving'},
]
