import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {HelpComponent} from "../../../components/help-component/HelpComponent";
import SupplierNormalizationSSHelp from "../SupplierNormalizationSSHelp";
import {AdminPanelSettings, ChevronRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import SupplierNormalizationPSHelp from "../SupplierNormalizationPSHelp";
import {SupplierNormalizationState} from "./SupplierNormalization.classes";

export const SupplierNormalizationWelcomeStep: React.FC = observer(() => {
    const {p, bagStore, supplierNormalizationStore, authStore, materializedApi} = useStores();
    const [showDialog, setShowDialog] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            supplierNormalizationStore.goToState(SupplierNormalizationState.RUNNING);
        } catch (err) {
            if ((err as Error).cause !== 'API Error')
                throw err;
            setError((err as Error).message);
        } finally {
            setShowDialog(false);
        }
    }

    /**
     * CAT-969
     * When threshold is lower than hideSmallStandaloneSpend, do not show standalone KPI's
     */
    const hideStandaloneOverview = p.p.hideSmallStandaloneSpend ? supplierNormalizationStore.statistics && (
        supplierNormalizationStore.statistics.standalone_suppliers <= p.p.hideSmallStandaloneSpend.minStandaloneSuppliers
    ) : false;

    return <>
        <section className="page-item step">
            <Grid className="key-value-container text-center font-bigger" container
                  style={{justifyContent: "space-around"}}>
                <Grid item>
                    <Typography variant="caption">
                        Review
                        <br/>spend
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {supplierNormalizationStore.statistics
                            ? <CurrencyComponent v={supplierNormalizationStore.statistics.total_spend}/>
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        Total
                        <br/>suppliers
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {supplierNormalizationStore.statistics
                            ? supplierNormalizationStore.statistics.total_suppliers
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <div style={{display: "inline-grid"}}>
                        <Typography variant="caption">
                            Parent
                            <br/>suppliers
                        </Typography>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {supplierNormalizationStore.statistics
                                ? supplierNormalizationStore.statistics.parent_suppliers
                                : <>&nbsp;</>}
                        </Typography>
                    </div>
                    <div style={{display: "inline-grid", marginLeft: "8px"}}>
                        <HelpComponent helpContent={<SupplierNormalizationPSHelp/>}/>
                    </div>
                </Grid>
                {!hideStandaloneOverview &&
                    <Grid item>
                        <div style={{display: "inline-grid"}}>
                            <Typography variant="caption">
                                Standalone
                                <br/>suppliers *
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {supplierNormalizationStore.statistics
                                    ? supplierNormalizationStore.statistics.standalone_suppliers
                                    : <>&nbsp;</>}
                            </Typography>
                        </div>
                        <div style={{display: "inline-grid", marginLeft: "8px"}}>
                            <HelpComponent helpContent={<SupplierNormalizationSSHelp/>}/>
                        </div>
                    </Grid>
                }
            </Grid>
            <div className="text-center page-item-actions">
                <Button variant="outlined"
                        color="primary"
                        endIcon={<ChevronRight/>}
                        onClick={() => {
                            try {
                                supplierNormalizationStore.goToState(SupplierNormalizationState.REVIEW)
                            } catch (err) {
                                if ((err as Error).cause !== 'API Error')
                                    throw err;
                                setError((err as Error).message);
                            }
                        }}>
                    {p.p.disableSupplierNormalizationAiModel
                        ? 'Next' // Not sure how the flow should be here, so we simply call it "Next"
                        : 'Review'
                    }
                </Button>
                {!p.p.disableSupplierNormalizationAiModel &&
                    <Button variant="contained"
                            color="secondary"
                            onClick={() => {
                                if (p.p.skipAiSupplierNormalizationModel) {
                                    supplierNormalizationStore.goToState(SupplierNormalizationState.RUNNING);
                                } else {
                                    setShowDialog(true)
                                }
                            }}>
                        Run AI
                    </Button>
                }
            </div>
        </section>
        {error !== '' && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
        <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth={authStore.isMithraStaff ? 'sm' : 'xs'}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {supplierNormalizationStore.jobDelegate.jobRunning && "Can't run AI"}
                    {!supplierNormalizationStore.jobDelegate.jobRunning && 'Confirm AI run'}
                </DialogTitle>
                <DialogContent>
                    {supplierNormalizationStore.jobDelegate.jobRunning &&
                        "Last AI job is not finished yet. You can't start another job until last one is completed."}
                    {!supplierNormalizationStore.jobDelegate.jobRunning &&
                        "Are you sure you want to run AI on current dataset? You will not be able to do any more reviews while AI is running"}
                </DialogContent>
                <DialogActions>
                    {authStore.isMithraStaff && <>
                        <Button
                            color="error"
                            onClick={() => materializedApi.http.post(`/bag/${bagStore.bagId}/devops_cleanup_supplier_normalization_ai_results/`)
                                .then(() => window.location.reload())}
                            startIcon={<AdminPanelSettings/>}>
                            Clear AI & Reviews
                        </Button>
                        <Button
                            color="warning"
                            onClick={() => materializedApi.http.post(`/bag/${bagStore.bagId}/devops_cleanup_supplier_normalization/`)
                                .then(() => window.location.reload())}
                            startIcon={<AdminPanelSettings/>}
                            style={{marginRight: '2em'}}>
                            Clear AI
                        </Button>
                    </>}
                    {supplierNormalizationStore.jobDelegate.jobRunning &&
                        <Button variant="contained" color="error" onClick={() => setShowDialog(false)}>OK</Button>}
                    {!supplierNormalizationStore.jobDelegate.jobRunning && <>
                        <Button onClick={() => setShowDialog(false)}>Cancel</Button>
                        <Button type="submit" variant="contained" color="primary">Run AI</Button>
                    </>}
                </DialogActions>
            </form>
        </Dialog>
    </>
})
