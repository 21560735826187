import React from "react";
import {Typography} from "@mui/material";
import {contractFileIsValid, contractFileMimeTypes, ContractUploadController} from "../ContractUploadController";
import {GcpBucketUploadComponent} from "../../data-management/upload/gcp-bucket-upload/GcpBucketUploadComponent";

export const ContractUploadComponent: React.FC = () => <>
    <GcpBucketUploadComponent
        controller={ContractUploadController}
        acceptMimeType={contractFileMimeTypes}
        validateFile={contractFileIsValid}
        selectFileText="Select a contract PDF file, up to 50 mb"
    />
    <Typography variant="body1" component="p" sx={{mt: 2}}>
        Note that files that are already uploaded will not be replaced.
        If you want to replace a contract, please remove the contract first and then upload again
    </Typography>
    <Typography variant="body1" component="p" sx={{mt: 2}}>
        It can take a few minutes for the contract to appear in the list
    </Typography>

    {/*TODO: CAT-2147 CAT-2126*/}
    {/*<Alert severity="warning" sx={{mt: 2}}>*/}
    {/*    This is a trial version. There is maximal upload limit of 5 files.*/}
    {/*</Alert>*/}
</>
