import React from "react";
import {SingleHeader} from "../../pages/default-menus/SingleHeader";
import {MultiHeader} from "../../pages/multi-dashboard/menu/MultiHeader";
import {MainComponentBaseProps} from "../../components/main/MainComponent";
import {useStores} from "../../stores";
import {environment} from "../../env";
import {MergeXHeader} from "../../pages/multi-dashboard/merge-x-dashboard/MergeXHeader";
import {Merge2Header} from "../../pages/multi-dashboard/lg-dashboard/Merge2Header";
import {SalesDemoHeader} from "../../pages/multi-dashboard/sales-demo-dashboard/menu/SalesDemoHeader";
import {ChatOverlayComponent} from "../../pages/chat/ChatOverlayComponent";
import {HeaderChatBoxComponent} from "../../pages/chat/HeaderChatBoxComponent";
import {MergeXCleanupHeader} from "../../pages/multi-dashboard/merge-x-cleanup-dashboard/MergeXCleanupHeader";
import {observer} from "mobx-react-lite";

export const HeaderWrapper: React.FC<MainComponentBaseProps> = ({state}) => {
    const {p} = useStores();
    // console.debug('Re-rendering HeaderWrapper', state?.dashEnv);

    let headerElement: JSX.Element | undefined;
    if (environment.package === 'sales_demo') {
        headerElement = <SalesDemoHeader/>
    } else {
        if (environment.package === 'merge_x') {
            headerElement = <MergeXHeader/>
        } else if (environment.package === 'merge_x_cleanup') {
            headerElement = <MergeXCleanupHeader/>
        } else if (environment.package === 'merge_2') {
            headerElement = <Merge2Header/>
        } else {
            switch (state?.dashEnv) {
                case 'simple':
                    headerElement = <SingleHeader/>;
                    break;
                case 'dual':
                    headerElement = <SingleHeader/>;
                    break
                case 'multi':
                    headerElement = <MultiHeader/>;
                    break
            }
        }
    }

    return <>
        {p.p.enableChat && <>
            <ChatOverlayComponent/>
            <HeaderChatBoxComponent/>
        </>}

        {environment.showDebugInfoInMenu ? <DebugComponent/> : <></>}
        {headerElement}
    </>
}

const DebugComponent: React.FC<MainComponentBaseProps> = observer(({state}) => {
    const {
        bagStore,
    } = useStores();
    return <div style={{background: '#c6ffb0', padding: '2px'}}>
        <span style={{background: '#df7bfb'}}>bag: {'' + bagStore.bag?.id}</span>,&nbsp;
        <span style={{textDecoration: "line-through"}}>dashEnv: {'' + state?.dashEnv}</span>,&nbsp;
        <span>dashEnv: {'' + state?.dashboardEnvironment}</span>,&nbsp;
        <span>pack: {'' + environment.package}.{'' + environment.dashboardVariant}</span>
    </div>
})
