import {makeAutoObservable} from "mobx";
import {SearchManager} from "../../stores/managers/SearchManager";

export type Option = {
    key: string
    label: string
}

export type MultiTypedSearchOptionsSpecification = {
    defaultIndices: number[]
    options: Option[]
}

/**
 * A manager for a search element, with a type dropdown next to it
 */
export class MultiTypedSearchManager {
    search: SearchManager

    selectedOptions: Option[]

    constructor(
        private readonly spec: MultiTypedSearchOptionsSpecification,
        onSearch: (search: string, selected: Option[]) => void,
    ) {
        this.selectedOptions = spec.defaultIndices.map((i) => spec.options[i]);
        this.search = new SearchManager(this.analyticsTag, (searchString) => {
            onSearch(searchString, this.selectedOptions);
        })
        makeAutoObservable(this)
    }

    get analyticsTag() {
        return this.selectedOptions.map((o) => o.key).sort().join(',');
    }

    get options() {
        return this.spec.options;
    }

    get optionLabels() {
        return this.selectedOptions.map((o) => o.label).reverse().join(', ');
    }

    get selectedKeys() {
        return this.selectedOptions.map((o) => o.key);
    }

    setSelected(options: Option[]) {
        if (!Array.isArray(options)) {
            throw new Error('Expected an array of options');
        }
        this.selectedOptions = options;
    }

    setSelectedKeys(keys: string[]) {
        if (!Array.isArray(keys)) {
            throw new Error('Expected an array of options');
        }
        this.selectedOptions = keys.map((key) => this.options.find((o) => o.key === key)).filter((o) => o !== undefined) as Option[];
    }
}