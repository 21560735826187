import React from "react";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {Button, Grid, Typography} from "@mui/material";
import {MenuItem} from "../../../components/main/MenuItems";

export const MainMenuSynergyComponent: React.FC = () => {
    // const {p} = useStores();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    // const hasId2 = params['id2'] !== undefined;
    let menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.synergy_v3_data, params) : routes.synergy_v3,
            active: (
                location.pathname === routes.synergy_v3
                || location.pathname === routes.synergy_v3_data
            ),
            // className: '',
        },
        {
            label: 'Synergy Analytics',
            link: hasId1 ? generatePath(routes.synergy_v3_dashboard, params) : routes.synergy_v3,
            active: (
                location.pathname === routes.synergy_v3_dashboard
                || location.pathname === routes.synergy_v3_suppliers
                || location.pathname === routes.synergy_v3_categories
                || location.pathname === routes.synergy_v3_spend
            ),
            // className: '',
        },
        {
            label: 'Merge',
            link: hasId1 ? generatePath(routes.synergy_merge_dashboard, params) : routes.synergy_v3,
            active: (
                location.pathname === routes.synergy_merge_dashboard
                || location.pathname === routes.synergy_merge_taxonomy_viewer
                || location.pathname === routes.synergy_merge_taxonomy_viewer_id
                || location.pathname === routes.synergy_merge_taxonomy_builder
                || location.pathname === routes.synergy_merge_taxonomy_builder_id
                || location.pathname === routes.synergy_merge_review
                || location.pathname === routes.synergy_merge_review_result
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.synergy_v3_opportunities_id, params) : routes.synergy_v3_opportunities,
            active: (
                location.pathname === routes.synergy_v3_opportunities
                || location.pathname === routes.synergy_v3_opportunities_id
            ),
            // className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.synergy_v3_report, params) : routes.synergy_v3,
            active: (
                location.pathname === routes.synergy_v3_report
            ),
            // className: 'menu-item-last',
        },
    ];
    return <Grid container className="main-menu-buttons">
        {menu.map(({label, link, active, isTrial, className}, i) =>
            <Grid item key={i} className={className}>
                <Button
                    variant="text"
                    color="inherit"
                    onClick={() => navigate(link)}
                    className={'main-menu-button' + (active ? ' active' : '')}
                    disabled={Boolean(isTrial)}
                >
                    <Typography variant="h5" className="button-text">
                        {label}
                    </Typography>
                </Button>
            </Grid>
        )}
    </Grid>
}
