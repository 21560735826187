import {SitRouteWrapper, SitRouteWrapperProps} from "./SitRouteWrapper";
import {TargetTable} from "../pages/target/targetTable";
import {InitiativeTable} from "../pages/initiative/InitiativeTable";
import {InitiativeForm} from "../pages/initiative/forms/InitiativeForm";
import {TargetForm} from "../pages/target/forms/targetForm";
import {FinancialDashboard} from "../pages/financial-dashboard/financialDashboard";
import {PipelineDashboard} from "../pages/pipeline-dashboard/PipelineDashboard";
import {InitiativeApprovalDashboard} from "../pages/initiative/InitiativeApprovalDashboard";
import {CurrencyTable} from "../pages/settings/currency/CurrencyTable";
import {RouteOptions, RouteSpecification} from "../../routing/routeClasses";

type S = RouteSpecification<SitRouteWrapperProps>; //shortcut
type O = RouteOptions<SitRouteWrapperProps, unknown>; //shortcut

const sit: O = {wrapper: SitRouteWrapper, wProps: {}};

/**
 * All the URL names
 */
const r = {
    sit_home: '/savings-tracker',
    sit_target: '/savings-tracker/targets/:targetId',
    sit_targets: '/savings-tracker/targets',
    sit_initiative: '/savings-tracker/initiatives/:initiativeId/:approval?',
    sit_initiatives: '/savings-tracker/initiatives',
    sit_financial: '/savings-tracker/financial-view',
    sit_pipeline_dashboard: '/savings-tracker/pipeline-view',
    sit_approval: '/savings-tracker/approval',
    sit_settings_conversion_rates: '/savings-tracker/settings/currency',
}

/**
 * The pages that link to the URLS
 */
export const sitRouteSpecifications: S[] = [
    [sit, r.sit_target, TargetForm],
    [sit, r.sit_targets, TargetTable],
    [sit, r.sit_initiative, InitiativeForm],
    [sit, r.sit_initiatives, InitiativeTable],
    [sit, r.sit_financial, FinancialDashboard],
    [sit, r.sit_pipeline_dashboard, PipelineDashboard],
    [sit, r.sit_approval, InitiativeApprovalDashboard],
    [sit, r.sit_settings_conversion_rates, CurrencyTable],
];

export const sitRoutes = r;
