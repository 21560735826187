export function getCategorizationStateClassName(state: 'N/A' | 'NOT_SENT' | 'PENDING' | 'APPROVED' | 'REJECTED' | 'MIXED'): string {
    switch (state) {
        case "APPROVED":
            return 'approved';
        case "REJECTED":
            return 'rejected';
        case "PENDING":
            return 'pending';
        case "NOT_SENT":
            return 'not-send';
        case 'MIXED':
            return 'mixed';
        case "N/A":
            return 'n-a';
    }
}