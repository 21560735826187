import React from "react";
import {generatePath} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const MainMenuSingleComponent: React.FC = () => {
    const params = useParams();
    const location = useLocation();
    const hasId1 = params.id !== undefined;
    // const hasId2 = params['id2'] !== undefined;
    let menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.single_data_manage, params) : routes.single_data_home,
            active: (
                location.pathname === routes.single_data_home
                || location.pathname === routes.single_data_manage
            ),
            // className: '',
        },
        {
            label: 'Cleanup',
            link: hasId1 ? generatePath(routes.single_cleansing_dashboard, params) : routes.single_data_home,
            active: (
                location.pathname === routes.single_cleansing_dashboard
                || location.pathname === routes.single_cleansing_sup_nor
                || location.pathname === routes.single_cleansing_cat_welcome
                || location.pathname === routes.single_cleansing_cat_busy
                || location.pathname === routes.single_cleansing_cat_review
                || location.pathname === routes.single_cleansing_cat_result
                || location.pathname === routes.single_cleansing_taxonomy_builder
                || location.pathname === routes.single_cleansing_taxonomy_builder_id
            ),
            // className: '',
        },
        {
            label: 'Analytics',
            link: hasId1 ? generatePath(routes.single_analytics_dashboard, params) : routes.single_data_home,
            active: (
                location.pathname === routes.single_analytics_dashboard
                || location.pathname === routes.single_analytics_koi_ss
                || location.pathname === routes.single_analytics_koi_kdf
                || location.pathname === routes.single_analytics_koi_ppv
                || location.pathname === routes.single_analytics_koi_sc
                || location.pathname === routes.single_looker_preview
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.single_data_opportunities_id, params) : routes.single_data_opportunities,
            active: (
                location.pathname === routes.single_data_opportunities
                || location.pathname === routes.single_data_opportunities_id
            ),
            // className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.single_data_report, params) : routes.single_data_home,
            active: (
                location.pathname === routes.single_data_report
            ),
            // className: 'menu-item-last',
        },
    ];
    return <MenuItems className="single-main-menu" items={menu}/>
}
