export const B = {
    BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST,
    INGESTION_HOST: process.env.REACT_APP_INGESTION_HOST,
    MATERIALIZED_HOST: process.env.REACT_APP_MATERIALIZED_HOST,
    PREVIEW_ID: process.env.REACT_APP_PREVIEW_ID || '',
    DJANGO_GCP_STATIC_BUCKET: process.env.REACT_APP_DJANGO_GCP_STATIC_BUCKET,
    APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || '?',
    APP_CUSTOMER: process.env.REACT_APP_CUSTOMER || '?',
    APP_URL: process.env.REACT_APP_URL || 'http://localhost:3008',
    APP_IS_DEV: Boolean(process.env.REACT_APP_DEV),

    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
    AUTH0_ORGANIZATION: process.env.REACT_APP_AUTH0_ORGANIZATION || '',

    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY || '',
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID || '',
    FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
}

/**
 * staging or dev environment
 */
export const ONLY_FOR_US: true | undefined = B.APP_ENVIRONMENT === 'staging' || B.APP_IS_DEV || undefined;

export const ONLY_PRODUCTION: true | undefined = B.APP_ENVIRONMENT === 'production' ? true : undefined;
