import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {InitiativeKPIValue} from "../ApiTypes";
import {makeAutoObservable} from "mobx";

export class KpiStore {
    readonly valuesRM = new AxoisRequestManager<{ kpi_id: number }, InitiativeKPIValue[]>(
        ({kpi_id}) => from(this.api.listInitiativesKpiValues(kpi_id)));

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
    }
}