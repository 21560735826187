import Toolbar from "@mui/material/Toolbar";
import {AppBar, Button, Typography} from "@mui/material";
import React from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {generateHomePath, lookupHomePath} from "../../routing/routing.utils";
import {useStores} from "../../stores";

export const MainHeader: React.FC = ({children}) => {
    const {rootAppStore} = useStores();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const isHome = location.pathname === lookupHomePath(location, rootAppStore.app);
    return <AppBar
        id="main-header"
        position="static"
        elevation={0}>
        <Toolbar>
            {/*<img src="/logo_180x40/Mithra_logo.png" alt="Mithra" className="mithra-logo"/>*/}
            <Button className={'logo-wrapper' + (isHome ? ' current' : '')}
                    variant="text" color="inherit"
                    onClick={() => navigate(generateHomePath(location, {...params, logo: true}, rootAppStore.app))}>
                <img src="/logo_135x30/Mithra_logo.png" alt="Mithra Logo" className="mithra-logo"/>
                <Typography className="logo-subtitle" variant="subtitle1">
                    {/*TODO: Come up with a good company slogan / application name*/}

                    <span className="logo-subtitle-word1">
                        Cognitive
                    </span>
                    <span className="logo-subtitle-word2">
                        Spend
                    </span>
                    <span className="logo-subtitle-word3">
                        Intelligence
                    </span>
                </Typography>
            </Button>
            {children}
        </Toolbar>
    </AppBar>;
}
