import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";

const CAT_FILTER_MODAL_ID = 'ppv-edit-modal';
export const CAT_FILTER_MODAL_TITLE_ID = CAT_FILTER_MODAL_ID + '-title';


type Props = {
    onNoteUpdate: (note: string) => void
}

export const PpvEditNoteModal: React.FC<Props> = observer(({onNoteUpdate}) => {
    const {ppvControllerStore} = useStores();

    const [note, setNote] = useState('');

    useEffect(() => {
        setNote(ppvControllerStore.ppvRowToEdit?.data.opportunity?.note || '');
    }, [ppvControllerStore.ppvRowToEdit]);

    return (
        <GenericModalVertical
            className={'ppv-overview-model-component'}
            title="Edit Note"
            isOpen={ppvControllerStore.isEditNoteDialogOpen}
            onSave={() => {
                ppvControllerStore.setEditNoteDialogOpen(false);
                ppvControllerStore.saveNote(note, onNoteUpdate);
            }}
            saveDisabled={false}
            saveTitle="Save"
            onCancel={() => ppvControllerStore.setEditNoteDialogOpen(false)}
            onClose={() => ppvControllerStore.setEditNoteDialogOpen(false)}
        >
            <Grid container spacing={2} alignContent="center" alignItems="center" direction="row" xs={12}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Description"
                        id={CAT_FILTER_MODAL_ID + "-description"}
                        value={ppvControllerStore.ppvRowToEdit?.data.p_description}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Variance Type"
                        id={CAT_FILTER_MODAL_ID + "-variance-type"}
                        value={ppvControllerStore.ppvRowToEdit?.data.variance_types}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Total PPV Opportunity"
                        id={CAT_FILTER_MODAL_ID + "-total-ppv-opportunity"}
                        value={ppvControllerStore.ppvRowToEdit?.data.ppv_opportunity}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Notes"
                        id={CAT_FILTER_MODAL_ID + "-notes"}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        multiline
                        rows={6}
                    />
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
});