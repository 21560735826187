import {PpvGroup, PpvPart} from "../../../../services/classes/MaterializedClasses";
import {makeAutoObservable} from "mobx";
import {PpvPartRowState} from "./PpvPartRowState";


export class PpvGroupRowState {
    open = false;
    isLoadingRows = false;
    partStates: PpvPartRowState[] | undefined

    constructor(
        public data: PpvGroup,
    ) {
        makeAutoObservable(this)
    }

    get key(): string {
        return this.data.p_description;
    }

    public setOpen(open: boolean) {
        this.open = open;
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoadingRows = isLoading;
    }

    update(data: PpvGroup, items: PpvPart[]) {
        this.data = data;
        this.partStates = items.map(item => new PpvPartRowState(this, item));
    }

}

