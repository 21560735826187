import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from "@mui/material";
import {useSitStores} from "../../../../stores";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {Article, Download, Delete} from "@mui/icons-material";

export const InitiativeAttachmentForm: React.FC = observer(() => {
    const {initiativeInstanceStore, attachmentStore, sitApi} = useSitStores();
    const [isDownloading, setIsDownloading] = React.useState(false);

    useEffect(() => {
        if (!initiativeInstanceStore.creating && (initiativeInstanceStore.instance.id ?? 0) !== 0) {
            attachmentStore.initiativeAttachmentsRM.request({id: initiativeInstanceStore.instance.id!});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiativeInstanceStore.instance.id]);

    return <Grid container spacing={3}>
        {!attachmentStore.initiativeAttachmentsRM.busy && attachmentStore.initiativeAttachmentsRM.result !== undefined &&
            attachmentStore.initiativeAttachmentsRM.result.length === 0 && <>
                <Grid item xs={12} textAlign="center">
                    <ReportProblemIcon sx={{fontSize: 128, color: 'lightGray'}}/>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="subtitle2" component="p">
                        It's all empty here...
                    </Typography>
                    <Typography variant="body2" component="p">
                        You attachments will list here once you upload one!
                    </Typography>
                </Grid>
            </>}
        {attachmentStore.initiativeAttachmentsRM.result && attachmentStore.initiativeAttachmentsRM.result.length > 0 &&
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableHead>
                    <TableBody>
                        {attachmentStore.initiativeAttachmentsRM.result?.map((attachment, index) =>
                            <TableRow key={index}>
                                <TableCell>
                                    <Article/>
                                    {attachmentStore.fileName(attachment)}
                                </TableCell>
                                <TableCell>{attachment.created.toLocaleString()}</TableCell>
                                <TableCell width="10">
                                    <IconButton onClick={() => {
                                        if (!isDownloading) {
                                            setIsDownloading(true);
                                            sitApi.downloadInitiativeAttachment(
                                                initiativeInstanceStore.instance.id!, attachment.id
                                            ).then((resp) => {
                                                const url = URL.createObjectURL(new Blob([resp.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', attachmentStore.fileName(attachment));
                                                document.body.appendChild(link);
                                                link.click();
                                                link.parentNode?.removeChild(link);
                                            }).finally(() => setIsDownloading(false))
                                        }
                                    }}>
                                        <Download/>
                                    </IconButton>
                                </TableCell>
                                <TableCell width="10">
                                    <IconButton
                                        disabled={initiativeInstanceStore.disabled}
                                        onClick={() => {
                                            attachmentStore.delete(attachment.id);
                                        }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </Grid>}
    </Grid>
});