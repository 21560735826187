import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {BagStore} from "../../stores/BagStore";
import ProfileStore from "../../stores/ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {
    OpportunityStatusEnum,
    PpvOpportunity,
    PpvOpportunityListFilter
} from "../../services/classes/OpportunityClasses";
import {PageResponseManager} from "../../stores/managers/PageResponseManager";
import {environment} from "../../env";
import {analyticsOpportunityEvent} from "../../services/analytics/AnalyticEvents";
import {getOpportunityStatusName} from "./utils/OpportunityStatusVisual";
import AuthStore from "../../stores/AuthStore";


declare type OpportunityPageManager = PageResponseManager<PpvOpportunityListFilter, PpvOpportunity, {
    d: PpvOpportunity,
    f: PpvOpportunityListFilter,
}>;


export class OpportunityStoreBis {
    opportunityRowToEdit: PpvOpportunity | undefined = undefined


    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profile: ProfileStore,
        private authStore: AuthStore,
    ) {
        makeAutoObservable(this)


        reaction(() => [
            this.bagId,
        ] as const, () => {
            this.loadData()
        })

    }


    get bagId(): number {
        return this.bagStore.bagId;
    }

    setOpportunityRowToEdit(row: PpvOpportunity) {
        this.opportunityRowToEdit = row;
    }

    changeStatus(row: PpvOpportunity, status: OpportunityStatusEnum) {
        this.setOpportunityRowToEdit(row);
        if (this.opportunityRowToEdit && this.opportunityRowToEdit.id) {
            analyticsOpportunityEvent({
                type: 'ppv',
                key: this.opportunityRowToEdit.ppv_group_key,
                status: getOpportunityStatusName(this.opportunityRowToEdit.status),
                expected_savings: this.opportunityRowToEdit.current_expected_savings,
                user: this.authStore.email,
            })
            this.api.updatePpvOpportunity(this.opportunityRowToEdit.id, {status: status})
                .then(() => this.loadData())
        }
    }

    loadData(initialLoad = false) {
        if (this.bagId === 0) {
            // Reset
            this.opportunityPages.reset()
        } else {
            this.resetAndRequestOpportunityPage();
            if (initialLoad) {
                this.resetAndRequestOpportunityPage();
            }
        }
    }


    resetAndRequestOpportunityPage() {
        this.opportunityPages.page = 1;
    }

    readonly opportunityPages: OpportunityPageManager = new PageResponseManager(
        this.profile.p.ppvTablePageSize ?? environment.defaultPpvTablePageSize,
        (page, f) => {
            f.page = page;
            f.page_size = this.opportunityPages.pageSize;
            return this.api.listPpvOpportunity(f)
                .then(r => {
                    // Inject the type to ensure we can use typescript's dynamic type resolution
                    r.data.results = r.data.results.map(d => ({d, f: f})) as any
                    return r as any;
                });
        },
        ({d}) => d
    )

    get isLoadingParts(): boolean {
        return this.opportunityPages.isLoading;
    }
}