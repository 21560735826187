import {makeAutoObservable} from "mobx";
import {
    InitiativeDescriptions,
} from "../../ApiTypes";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";

export class InitiativeDescriptionController {
    constructor(public iis: InitiativeInstanceStore) {
        makeAutoObservable(this);
    }

    get instance(): InitiativeDescriptions {
        return this.iis.instance.description!;
    }

    set description(value: string) {
        this.instance.description = value;
    }

    set confidential_description(value: string) {
        this.instance.confidential_description = value;
    }

    set approval_notes(value: string) {
        this.instance.approval_notes = value;
    }
}
