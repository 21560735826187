import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import React, {MouseEventHandler} from "react";
import {useStores} from "../../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {getBusinessUnitIdHack} from "../../../../stores/hacks/display";


export const ReviewBreadcrumbs = observer(() => {
    const {bagStore, categorizationReviewStore, p} = useStores();
    const pageController = categorizationReviewStore.reviewPageController;
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;

    const onClickBu: MouseEventHandler = e => {
        e.preventDefault();
        pageController.navigateToPage('welcome');
    }

    // const buEl = bagStore.bu.selectedBusinessUnit && (
    //     reviewPageFilterController.selectedLevel > 0 ?
    //         <Link onClick={onClickBu} href="">
    //             {bagStore.bu.selectedBusinessUnit.bu_name}
    //         </Link>
    //         : <Typography color="textPrimary" className="">
    //             {bagStore.bu.selectedBusinessUnit.bu_name}
    //         </Typography>
    // )
    const buEl = !p.p.disableBuFilter && bagStore.bu.selectedBusinessUnitOption && (
        <Link onClick={onClickBu} href="">
            {bagStore.bu.selectedBusinessUnitOption.bu_name +
                (p.p.showBuWithId && bagStore.bu.selectedBusinessUnitOption.id > 0 ? ` (${getBusinessUnitIdHack(bagStore.bu.selectedBusinessUnitOption)})` : '')}
        </Link>
    )

    return <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
        {buEl}
        {reviewPageFilterController.selectedCategory.map((category, index) => {
            const level = index + 1;
            const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
            // const isLast = index === array.length - 1;
            // if (isLast) {
            //     return <Typography key={category} color="textPrimary" className="">{categoryLabel}</Typography>
            // }
            const onClick: MouseEventHandler = e => {
                e.preventDefault();
                reviewPageFilterController.navigateToLevel(level - 1);
            }
            return <Link onClick={onClick} key={category} href="">{categoryLabel}</Link>
        })}
        {reviewPageFilterController.hasRemainingSelectionLevels &&
            <Typography color="textPrimary" className="">Select a
                L{reviewPageFilterController.selectedLevel + 1}</Typography>
        }
        {/*<Link color="inherit" href="/" onClick={handleClick}>*/}
        {/*    Material-UI*/}
        {/*</Link>*/}
        {/*<Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>*/}
        {/*    Core*/}
        {/*</Link>*/}
        {/*<Typography color="textPrimary">Breadcrumb</Typography>*/}
    </Breadcrumbs>
})
