import React, {useMemo} from "react";
import {Create} from "@mui/icons-material";
import {Button} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../../../components/categorization/PartRecategorizationHandler";

type Type = {}
export const ChangeCategoryFooterButton: React.FC<Type> = observer(() => {
    const {categorizationReviewStore} = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const categories = categorizationReviewStore.categories;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    if (categorizationReviewStore.viewOnly) return <></>;

    return <>
        {controller && <PartReCategorizationHandler controller={controller} label="Change the category"/>}
        {reviewPageSelectionController.isAnySelected && <div id="review-filter-footer">
            <Button
                variant="contained"
                onClick={event => {
                    if (!controller) {
                        console.error('No controller set');
                        return;
                    }
                    const anchor = event.currentTarget;
                    controller.setOnSelect(newCats => reviewPageController.onClickReCategorizeSelection(newCats))
                    controller.open(anchor)
                }}>
                <Create/>
            </Button>
        </div>}
    </>
})
