import "./ContractInsightsPage.scss";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {useLocation, useParams} from "react-router-dom";
import {DatabagRouteMatch} from "../../../routing/routes";
import {generateHomePath} from "../../../routing/routing.utils";
import React, {useContext, useEffect} from "react";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {ContractInsightsComponent} from "../components/ContractInsightsComponent";
import {ContractEmptyComponent} from "../components/ContractEmptyComponent";
import {ModalContractDetails} from "../modals/ModalContractDetails";
import {ModalContractEdit} from "../edit/ModalContractEdit";
import {environment} from "../../../env";
import {ContractUploadModal} from "../modals/ContractUploadModal";
import {Alert, Button, IconButton} from "@mui/material";
import {FirebaseContext} from "../../../services/firebase/FirebaseProvider";
import {AdminPanelSettings, Refresh} from "@mui/icons-material";
import {authStore} from "../../../stores/init";
import {noop} from "rxjs";

type Props = {}
export const ContractInsightsPage: React.FC<Props> = observer(() => {
    const {rootAppStore, contractInsightsStore, p, firebaseStore} = useStores();
    const params = useParams<DatabagRouteMatch>();
    const location = useLocation();
    const firebase = useContext(FirebaseContext);
    const isDemo = environment.isDemo;

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [monitorStarted, setMonitorStarted] = React.useState<boolean>(false);

    function init() {
        if (!firebase) {
            setError('Environment error');
            console.warn('Cannot initialize ContractInsightsPage, Firebase is not initialized')
            return;
        }
        setError('');
        setIsLoading(true);
        firebaseStore.init(firebase)
            .then(() => contractInsightsStore.init(firebase.db!))
            .then(() => setMonitorStarted(true))
            .catch(e => {
                console.warn(e);
                setError(String(e));
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (isDemo) {
            if (p.p.useBeDemoContractData) {
                contractInsightsStore.contractsDemoDataManager.request()
            }
        } else {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebase]);

    useEffect(() => {
        if (!monitorStarted) return noop;

        const int = setInterval(() => contractInsightsStore.updateContractsData(), 60 * 1000);
        return () => clearInterval(int);
    }, [contractInsightsStore, monitorStarted]);

    return <KoiFacade
        title="Contract Insights"
        intro={<></>}
        back={generateHomePath(location, params, rootAppStore.app)}
        className="contract-insights-page"
        simpleAction={
            <IconButton
                size="small"
                aria-label="refresh"
                onClick={init}
                disabled={isLoading}
                className="ml-2">
                <Refresh/>
            </IconButton>
        }>
        {error &&
            <Alert severity="error">{error}</Alert>
        }
        {authStore.isMithraStaffGroup && <>
            <p>
                TODO: Test logout and what happens when refresh time of 5 minutes expires
            </p>
            <Button startIcon={<AdminPanelSettings/>} onClick={() => {
                setError('');
                setIsLoading(true);
                contractInsightsStore.deleteAllContracts()
                    .catch(e => {
                        console.warn(e);
                        setError(String(e));
                    })
                    .finally(() => setIsLoading(false));
            }}>
                Delete all
            </Button>
        </>}


        <ContractUploadModal/>
        <ModalContractDetails/>
        <ModalContractEdit/>

        {/*{!isDemo && contractInsightsStore.contractsDemoDataManager.busy*/}
        {/*    ? <></> // Loading screen missing*/}
        {/*    : undefined*/}
        {/*}*/}
        {contractInsightsStore.contracts?.length === 0 &&
            <ContractEmptyComponent/>
        }

        {(contractInsightsStore.contracts != null && contractInsightsStore.contracts.length > 0) &&
            <ContractInsightsComponent/>
        }
    </KoiFacade>
});
